import { useQuery } from '@tanstack/react-query';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import Big from 'big.js';
import { isNumber } from 'lodash';
import { duration } from 'moment';

export function useCurrencyConvert() {
  const { data: prices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    refetchInterval: duration('1', 'minute').asMilliseconds(),
  });

  return (value: number | undefined, pair: string) => {
    if (!value) return undefined;
    if (pair === 'ETH/USD') {
      return isNumber(prices?.eth_price)
        ? Big(value).times(prices!.eth_price).toNumber()
        : undefined;
    } else if (pair === 'BNB/USD') {
      return isNumber(prices?.bnb_price)
        ? Big(value).times(prices!.bnb_price).toNumber()
        : undefined;
    }

    return undefined;
  };
}
