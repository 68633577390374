/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IChartingLibraryWidget } from 'modules/tw-chart/charting_library.d';
import { useEffect } from 'react';

export function useChartEffect(
  widget: IChartingLibraryWidget | null,
  fn: (wigdet: IChartingLibraryWidget) => (() => void) | void,
  deps: any[]
) {
  useEffect(() => {
    let isCanceled = false;
    let cleanup: void | (() => void) = () => {};
    if (widget) {
      widget.onChartReady(() => {
        if (isCanceled || widget.__removed) {
          return;
        }
        cleanup = fn(widget);
      });
    }
    return () => {
      isCanceled = true;

      if (widget && !widget.__removed) {
        cleanup?.();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, ...deps]);
}
