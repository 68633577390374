import { ReactComponent as MizarLogo } from 'assets/img/logo/mizar-typo.svg';
import Horizontal from 'assets/img/share-trade/horizontal.png';
import Vertical from 'assets/img/share-trade/vertical.png';
import { twMerge } from 'tailwind-merge';
import Big from 'big.js';
import { ForwardedRef, forwardRef } from 'react';
import QRCode from 'react-qr-code';
import { OpenPositionListItem } from 'api/dex-trade';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { ExchangeIconStretch } from 'modules/shared-components/exchange/exchange-icon';
import { ChainIconStretch } from 'modules/shared-components/asset/chain-icon';
import { formatTokenAmount } from 'utils/FormatNumber';
import { formatHoldingTime } from 'modules/dTrade/utils';
import {
  SCALE_CLASSES_HORIZONTAL,
  SCALE_CLASSES_VERICAL,
} from 'modules/marketplace-performance-page/components/ShareImage';
import { chainAsset, chainAssetImg, dexName } from 'api/d-wallets';

interface Props {
  position: OpenPositionListItem;
  horizontal?: boolean;
  referralCode?: string;
  shareUrl?: string;
}

const ShareImage = forwardRef(
  (
    { position, horizontal = false, referralCode, shareUrl }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className="overflow-visible xxs:rounded relative flex justify-center xxs:max-h-[400px] sm:max-h-none">
        <div
          ref={ref}
          className={twMerge(
            'flex flex-col xxs:origin-top sm:origin-center',
            horizontal ? SCALE_CLASSES_HORIZONTAL : SCALE_CLASSES_VERICAL,
            horizontal
              ? 'xxs:w-[800px] xxs:min-w-[800px]'
              : 'xxs:w-[450px] xxs:min-w-[450px]'
          )}
        >
          <div
            className={twMerge(
              'xxs:p-10 flex flex-col',
              !horizontal && 'xxs:px-8'
            )}
            style={{
              backgroundImage: `url(${horizontal ? Horizontal : Vertical})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
            }}
          >
            {position.protocol ? (
              <ShareSniped position={position} horizontal={horizontal} />
            ) : (
              <ShareSnipeSetup position={position} horizontal={horizontal} />
            )}
          </div>
          <div className="xxs:px-6 xxs:py-3 xxs:bg-black-900 flex justify-between xxs:gap-5 items-center">
            <span className="xxs:text-black-100 xxs:text-sm">
              Sign Up with referral code {referralCode}.
            </span>
            {shareUrl && (
              <div className="min-w-[76px] xxs:p-0.5 xxs:bg-white">
                <QRCode value={shareUrl} size={72} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

ShareImage.displayName = 'ShareImage';

export default ShareImage;

function ShareSniped({
  position,
  horizontal,
}: {
  position: OpenPositionListItem;
  horizontal: boolean;
}) {
  const pnl = position.totalProfitAndLoss || {
    pnlAmountInEth: '0',
    pnlAmountInUsd: '0',
    pnlPct: '0',
  };

  return (
    <>
      <div
        className={twMerge(
          'flex',
          horizontal && 'xxs:gap-2 xxs:mb-8',
          !horizontal && 'xxs:gap-6 flex-col-reverse'
        )}
      >
        <div
          className={twMerge(
            ' xxs:gap-4 flex-grow items-center truncate',
            horizontal ? 'flex' : ''
          )}
        >
          <div className="flex xxs:gap-2 items-center">
            <TokenIconWithChain
              protocol={position.protocol?.protocol}
              chain={position.chain}
              tokenName={position.tokenName}
              symbol={position.tokenSymbol}
              className="w-[25px] h-[25px]"
              address={position.tokenAddress}
            />
            <span className="xxs:text-3xl max-w-[250px] truncate font-semibold">
              {position.tokenName}
            </span>
          </div>
          {position.protocol && (
            <div className="text-lg flex xxs:gap-2 items-center">
              on{' '}
              <ExchangeIconStretch
                className="h-[25px]"
                imgName={position.protocol.protocol}
              />{' '}
              {dexName(position.protocol.protocol)}
            </div>
          )}
        </div>
        <MizarLogo width={horizontal ? 200 : 112} className="flex-shrink-0" />
      </div>
      <div className="flex flex-col gap-8 my-7">
        <div
          className={twMerge(
            Big(pnl.pnlPct).gt(0) && 'text-green-500',
            Big(pnl.pnlPct).lt(0) && 'text-red-500'
          )}
        >
          {Big(pnl.pnlPct).gt(1) && (
            <span className="xxs:text-6xl">
              +{Big(pnl.pnlPct).plus(1).toFixed(1, Big.roundDown)}X{' '}
            </span>
          )}
          {Big(pnl.pnlPct).gt(1) ? (
            <span className={'xxs:text-2xl'}>
              (+
              {new Intl.NumberFormat(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(Big(pnl.pnlPct).times(100).toNumber())}
              %)
            </span>
          ) : (
            <span className={'xxs:text-6xl'}>
              {Big(pnl.pnlPct).gt(0) ? '+' : ''}
              {new Intl.NumberFormat(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(Big(pnl.pnlPct).times(100).toNumber())}
              %
            </span>
          )}
        </div>
        <div className="xxs:text-black-50">
          <div className="text-xs xxs:mb-1">INVESTMENT</div>
          <div
            className={twMerge(
              'flex font-semibold ',
              horizontal && 'items-center xxs:gap-8',
              !horizontal && 'flex-col xxs:gap-4'
            )}
          >
            <div className="flex xxs:gap-1 items-center ">
              <div className="xxs:text-sm">IN</div>
              <img
                alt="coin logo"
                src={chainAssetImg(position.chain)}
                className="h-5 w-5"
              />
              <div className="xxs:text-xl">
                {formatTokenAmount(
                  Big(position.totalBuyAmountInEth ?? 0).toNumber()
                )}{' '}
                {chainAsset(position.chain)}
              </div>
            </div>
            {position.totalProfitAndLoss?.valueInEth && (
              <div className="flex xxs:gap-1 items-center">
                <div className="xxs:text-sm">OUT</div>
                <img
                  alt="coin logo"
                  src={chainAssetImg(position.chain)}
                  className="h-5 w-5"
                />
                <div className="xxs:text-xl">
                  {formatTokenAmount(
                    Big(position.totalProfitAndLoss.valueInEth).toNumber()
                  )}{' '}
                  {chainAsset(position.chain)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="xxs:text-black-50">
          <div className="text-xs xxs:mb-1">HOLDING TIME</div>
          <div className="flex xxs:gap-2 items-center">
            <span className="text-[30px] leading-none">⏰</span>
            <span className="font-semibold">{formatHoldingTime(position)}</span>
          </div>
        </div>
      </div>
    </>
  );
}

function ShareSnipeSetup({
  position,
  horizontal,
}: {
  position: OpenPositionListItem;
  horizontal: boolean;
}) {
  return (
    <>
      <div className={twMerge('flex flex-col xxs:text-black-50')}>
        <div
          className={twMerge(
            'flex  flex-grow',
            !horizontal && 'flex-col-reverse xxs:gap-6',
            horizontal && 'items-center xxs:gap-4 xxs:mb-8 justify-between'
          )}
        >
          <div className="flex flex-col xxs:gap-1">
            <div className="flex xxs:gap-2 items-center">
              <span className="text-[18px] leading-none">🔫</span>
              <span className="xxs:text-black-50 xxs:text-lg">
                SNIPING OPPORTUNITY
              </span>
            </div>
            <div>I’m sniping {position.tokenName} with Mizar</div>
          </div>
          <MizarLogo
            className={twMerge(
              '',
              horizontal ? 'w-[200px] h-[54px]' : 'w-[140px] h-[40px]'
            )}
          />
        </div>
        <div className="flex flex-col gap-2 my-28">
          <div className="flex xxs:gap-2 items-center">
            <TokenIconWithChain
              chain={position.chain}
              tokenName={position.tokenName}
              symbol={position.tokenSymbol}
              className="w-[25px] h-[25px]"
              address={position.tokenAddress}
            />
            <span className="xxs:text-3xl font-semibold">
              {position.tokenName}
            </span>
          </div>
          <div className="text-lg flex xxs:gap-2 items-center">
            on{' '}
            <ChainIconStretch
              className="h-[25px] w-[25px]"
              imgName={position.chain}
            />{' '}
            Ethereum
          </div>
        </div>
      </div>
    </>
  );
}
