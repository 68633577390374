/* eslint-disable react/no-unescaped-entities */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'modules/shared-components/button/button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  DWalletKeyService,
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/DWalletService';
import {
  chainName,
  CHAINS,
  Chains,
  CreatedWalletResponseDetail,
} from 'api/d-wallets';
import { useEffect, useRef, useState } from 'react';
import Checkbox from 'modules/shared-components/input/Checkbox';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';

import {
  ChainsIcon,
  ExchangeIconStretch,
} from 'modules/shared-components/exchange/exchange-icon';
import { NotificationDex } from '../components/alerts/notification';
import CustomModal from '../components/modal/CustomModal';
import TextInput from '../components/inputs/text-input/text-input';
import config from '../../../config';
import { validateLabel } from '../components/inputs/text-input/text-validation';

export const schema = yup.object({
  label: yup
    .string()
    .typeError('Required')
    .required('Required')
    .test(
      'is-valid-label',
      'Invalid label. Text can contain only letters, spaces, numbers, and -,.. It cannot be longer than 127 characters.',
      (value) => {
        try {
          return validateLabel(value || '');
        } catch {
          return false;
        }
      }
    ),
  tosAccepted: yup
    .boolean()
    .required('Please accept the terms of use')
    .oneOf([true], 'Please accept the terms of use'),
});

export type FormState = yup.InferType<typeof schema>;

type Props = {
  handleClose: () => void;
  onCreated?: (id: string) => void;
  selectedChain: Chains;
};

export function CreateDWalletModal({
  handleClose,
  onCreated,
  selectedChain,
}: Props) {
  const queryClient = useQueryClient();

  const { mutate, error, isLoading, data } = useMutation({
    mutationFn: MultiChainDWalletService.createDWallet,
    onSuccess: async () => {
      await queryClient.invalidateQueries(multiChainDWalletServiceKeys.all());
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormState>({
    resolver: yupResolver(schema),
  });

  return (
    <CustomModal
      title="New Wallet"
      showModal
      handleClose={data ? () => {} : handleClose}
    >
      {data && data?.length > 0 ? (
        <DWalletCreated
          wallet={data.filter((item) => item.chain === selectedChain)?.[0]}
          handleClose={() => {
            onCreated?.(
              data.filter((item) => item.chain === selectedChain)?.[0]?.id
            );
            handleClose();
          }}
        />
      ) : (
        <form
          autoComplete="off"
          className="contents"
          onSubmit={(e) => {
            void handleSubmit((state) => {
              mutate({ ...state, chain_type: 'EVM' });
            })(e);
          }}
        >
          <CustomModal.Body className="xxs:p-4 text-left max-w-none">
            <CustomModal.Title className="hidden lg:flex">
              Create a new wallet
            </CustomModal.Title>
            <CustomModal.Content className="xxs:flex xxs:flex-col xxs:gap-3">
              <TextInput
                {...register('label')}
                type="text"
                label="Give a name to your wallet"
                error={errors?.label?.message}
              />

              <div className="space-y-1 xxs:text-dex-white-secondary">
                <div>Supported Chains:</div>
                <div className="flex space-x-1 items-center">
                  {CHAINS.map((c, index) => (
                    <div
                      className="rounded xxs:px-2 xxs:py-1 xxs:border xxs:border-solid border-dex-black-700"
                      key={index}
                    >
                      <div className="flex space-x-1 items-center xxs:text-dex-white-secondary">
                        <ChainsIcon imgName={c} className="w-4 h-4" />
                        <div>{chainName(c as Chains)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="space-y-1">
                <div>Supported DEXs:</div>
                <div className="flex flex-wrap xxs:gap-1 items-center">
                  <div className="rounded xxs:px-2 xxs:py-1 xxs:border xxs:border-solid border-dex-black-700">
                    <div className="flex space-x-1 items-center">
                      <ExchangeIconStretch
                        imgName="uniswap_v2"
                        className="w-4 h-4"
                      />
                      <div>Uniswap V2/V3</div>
                    </div>
                  </div>
                  <div className="rounded xxs:px-2 xxs:py-1 xxs:border xxs:border-solid border-dex-black-700">
                    <div className="flex space-x-1 items-center">
                      <ExchangeIconStretch
                        imgName="pancakeswap_v2"
                        className="w-4 h-4"
                      />
                      <div>Pancakeswap V2/V3</div>
                    </div>
                  </div>
                  <div className="rounded xxs:px-2 xxs:py-1 xxs:border xxs:border-solid border-dex-black-700">
                    <div className="flex space-x-1 items-center">
                      <ExchangeIconStretch
                        imgName="sushiswap_v2"
                        className="w-4 h-4"
                      />
                      <div>Sushiswap V2</div>
                    </div>
                  </div>
                </div>
              </div>

              <Controller
                name="tosAccepted"
                control={control}
                render={({ field, fieldState: { error: fieldError } }) => (
                  <div>
                    <div className="flex items-center xxs:gap-2">
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        className="self-baseline xxs:mt-0.5"
                        id="tos"
                      />
                      <Checkbox.Label
                        htmlFor="tos"
                        className="whitespace-normal"
                      >
                        I hereby acknowledge and fully understand the risks
                        associated with bot trading and I accept and agree to
                        abide by the{' '}
                        <a
                          href="https://mizar.com/terms-of-use"
                          target="_blank"
                          rel="noreferrer"
                        >
                          terms and conditions
                        </a>{' '}
                        governing the Mizar bot.
                      </Checkbox.Label>
                    </div>
                    {fieldError && (
                      <p className="xxs:mt-2 text-sm xxs:text-red-500">
                        {fieldError?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </CustomModal.Content>

            {error && (
              <NotificationDex
                className="xxs:my-2"
                type="error"
                errorMessage={error}
              >
                An error occurred.
              </NotificationDex>
            )}
          </CustomModal.Body>
          <CustomModal.Footer>
            <PrimaryButton
              loading={isLoading}
              type="submit"
              className="min-w-[80px] xxs:text-base lg:text-xs"
            >
              Create wallet
            </PrimaryButton>
            <Button
              className="xxs:text-base lg:text-xs"
              type="button"
              variant="dexNeutral"
              onClick={handleClose}
            >
              Close
            </Button>
          </CustomModal.Footer>
        </form>
      )}
    </CustomModal>
  );
}

interface WalletKeyProps {
  text: string;
  encryptedKey: ArrayBuffer;
  iv: Uint8Array;
  setIsClicked: (arg0: boolean) => void;
}

function WalletKeyDisplay({
  encryptedKey,
  iv,
  text,
  setIsClicked,
}: WalletKeyProps) {
  const keyRef = useRef<string | null>(null);

  const decryptKey = async () => {
    try {
      const key = await DWalletKeyService.getWalletCryptoKey();

      const decryptedKey = await crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        key,
        encryptedKey
      );
      const decodedKey = new TextDecoder().decode(decryptedKey);
      keyRef.current = decodedKey;
      setIsClicked(true);
      if (config.isTelegramBrowser) {
        window.Telegram?.WebApp?.showPopup?.({
          message: `${text}:\n${decodedKey}`,
          buttons: [{ type: 'close' }],
        });
      } else {
        alert(text + ':\n' + decodedKey);
      }
    } catch (error) {
      console.error('Decryption failed:', error);
      alert('Decryption failed. Check console for error.');
    } finally {
      keyRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      if (keyRef.current) keyRef.current = null;
    };
  }, []);

  return (
    <Button
      type="button"
      variant="dexNeutral"
      onClick={() => {
        decryptKey().catch((error) => {
          console.log('Error:', error);
        });
      }}
    >
      Show {text}
    </Button>
  );
}

function DWalletCreated({
  wallet,
  handleClose,
}: {
  wallet: CreatedWalletResponseDetail;
  handleClose: () => void;
}) {
  const [isSaved, setIsSaved] = useState(false);
  const [isPrivateKeyClicked, setIsPrivateKeyClicked] = useState(false);
  const [isMnemonicClicked, setIsMnemonicClicked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  if (isSaved) {
    return (
      <HeadsUp handleClose={handleClose} showKeys={() => setIsSaved(false)} />
    );
  }

  return (
    <>
      <CustomModal.Body className="xxs:p-4 text-left max-w-none">
        <CustomModal.Title disableClose>Wallet Created</CustomModal.Title>
        <CustomModal.Content>
          <p className="xxs:text-dex-white-secondary">
            Congratulations! A new Wallet has been successfully created. To
            ensure access to your wallet and funds through other third-party
            wallet providers, securely store your private key and mnemonic key
            in a safe location.
          </p>

          <NotificationDex type="warning" className="xxs:my-2">
            Avoid copying your keys to the clipboard. Opt for the traditional
            method of using pen and paper.
          </NotificationDex>

          <NotificationDex type="warning" className="xxs:my-2">
            For your security, never share your private keys or mnemonic phrases
            with anyone. The Mizar team will never request them.
          </NotificationDex>

          <p className="xxs:mb-1 xxs:text-dex-white-secondary">Private Key</p>
          <WalletKeyDisplay
            setIsClicked={setIsPrivateKeyClicked}
            text="Private Key"
            encryptedKey={wallet.encryptedPrivateKey}
            iv={wallet.privateKeyIV}
          />

          <p className="xxs:mb-1 xxs:text-dex-white-secondary">Mnemonic Key</p>
          <WalletKeyDisplay
            text="Mnemonic Key"
            setIsClicked={setIsMnemonicClicked}
            encryptedKey={wallet.encryptedMnemonic}
            iv={wallet.mnemonicIV}
          />
          {showAlert && (
            <NotificationDex type="error">
              Please save your private and mnemonic key by clicking on the 2
              buttons above
            </NotificationDex>
          )}
        </CustomModal.Content>
      </CustomModal.Body>
      <CustomModal.Footer>
        <PrimaryButton
          className="xxs:text-base lg:text-xs"
          type="button"
          onClick={() => {
            if (
              (isPrivateKeyClicked && isMnemonicClicked) ||
              config.isTelegramBrowser
            ) {
              setShowAlert(false);
              setIsSaved(true);
            } else {
              setShowAlert(true);
            }
          }}
        >
          I SAVED MY KEYS
        </PrimaryButton>
      </CustomModal.Footer>
    </>
  );
}

function HeadsUp({
  handleClose,
  showKeys,
}: {
  handleClose: () => void;
  showKeys: () => void;
}) {
  return (
    <>
      <CustomModal.Body className="xxs:p-4 text-left max-w-none">
        <CustomModal.Title disableClose>Heads Up!</CustomModal.Title>
        <CustomModal.Content>
          <p className="xxs:text-dex-white-secondary">
            Retaining your private keys and mnemonic keys is the sole means of
            accessing your wallet and the funds stored in it. By clicking on
            "Understood," you acknowledge that once clicked, your keys will no
            longer be visible, and you confirm that you have successfully copied
            and securely stored your keys.
          </p>
        </CustomModal.Content>
      </CustomModal.Body>
      <CustomModal.Footer>
        <PrimaryButton
          className="xxs:text-base lg:text-xs"
          type="button"
          onClick={handleClose}
        >
          UNDERSTOOD
        </PrimaryButton>
        <Button
          className="xxs:text-base lg:text-xs"
          type="button"
          variant="dexNeutral"
          onClick={showKeys}
        >
          BACK TO MY KEYS
        </Button>
      </CustomModal.Footer>
    </>
  );
}
