/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import { HotTokensRowInfo, HotTokensSortKeys } from 'api/contracts';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import Tooltip from 'modules/shared-components/tooltip';
import { Chains } from 'api/d-wallets';
import { PercentageChangeWithMaxValue } from '../TelegramCalls/columns';
import { HotTokensPeriods } from '../Wallets/usePageParams';
import { PERIOD_FILTERS } from './HotTokensFilters';
import { twMerge } from 'tailwind-merge';
import Big from 'big.js';
import { performanceStyle } from '../Wallets/columns';
import moment from 'moment';
import {
  isTokenSuspicious,
  isTokenSuspiciousCheck,
  SuspiciousLabelAlert,
} from '../Wallets/labels';
import { Actions } from '../SnipingOpportunitiesCard/columns';

export const desktopColumns = (
  period: HotTokensPeriods
): TableColumn<HotTokensRowInfo>[] => {
  const selectedPeriod =
    PERIOD_FILTERS.find(
      (filter) => filter.value === period
    )?.label?.toLowerCase() ?? '';

  return [
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The Token address, chain, and DEX.">
            <div>Token</div>
          </Tooltip>
        </div>
      ),
      key: 'PAIR',
      component: ({ data }) => (
        <span
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary'
              : ''
          }
        >
          <div className="flex items-center xxs:gap-2">
            <div className=" ">
              <TokenIconWithChain
                tokenName={data.base}
                chain={data.chain ?? Chains.Ethereum}
                protocol={data.dex}
                symbol={data.base}
                className="w-6 h-6"
                address={data.tokenAddress}
              />
            </div>
            <div className="max-w-[120px] overflow-hidden truncate">
              <span>
                <span className="font-bold text-sm">{data.base}</span>/
                <span className="xxs:text-dex-white-secondary xxs:text-xs">
                  {data.quote}
                </span>
              </span>
              <div>
                <EthAddress
                  address={data.tokenAddress}
                  precision={3}
                  kind="token"
                  className="text-xs"
                  chain={data.chain ?? Chains.Ethereum}
                />
              </div>
            </div>
            <SuspiciousLabelAlert
              period={period}
              alertItems={isTokenSuspicious({
                suspiciousBuy: data.suspiciousBuy,
                rugAddictedBuy: data.rugAddictedBuy,
                scammerBuy: data.scammerBuy,
                freshBuy: data.freshBuy,
                totalBuy: data.totalBuy,
              })}
            />
          </div>
        </span>
      ),
    },

    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip
            text={`The current price and the price change in the ${selectedPeriod}.`}
          >
            <div>Price</div>
          </Tooltip>
        </div>
      ),
      sortableKey: HotTokensSortKeys.PriceChange,
      key: 'PRICE',
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary '
              : ''
          }
        >
          {data.currentPrice ? (
            <div className="flex space-x-1 items-center">
              <div className=" text-xs ">
                ${formatNumberWithSuffix(data.currentPrice)}
              </div>{' '}
              {data.priceChange && data.status !== 'RUG_PULL' && (
                <PercentageChangeWithMaxValue
                  maxValue={999}
                  className="xxs:text-xs"
                  change={data.priceChange}
                />
              )}
            </div>
          ) : (
            <div className="text-xs">--</div>
          )}
        </div>
      ),
    },

    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip
            text={`The net amount of money in and out from smart wallets and all wallets in the ${selectedPeriod}. Hover to get more information about buy and sell from smart wallets / all wallets.`}
          >
            <div>Smart Money</div>
          </Tooltip>
        </div>
      ),
      key: 'NET_FLOW',
      sortableKey: HotTokensSortKeys.SmartFlow,
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary '
              : ''
          }
        >
          {data.netInflow ? (
            <Tooltip
              text={
                <div className="space-y-1">
                  {data.buyVolumeTop ? (
                    <div className="flex items-center space-x-1">
                      <div
                        className={twMerge(
                          ' text-xs font-bold',
                          Big(data.buyVolumeTop).gt(0) && 'text-green-500 '
                        )}
                      >
                        <span className="xxs:text-white">Buy Volume:</span> $
                        {formatNumberWithSuffix(data.buyVolumeTop, {
                          precision: 2,
                        })}
                      </div>
                      <div className=" text-[10px]">
                        /$
                        {formatNumberWithSuffix(data.buyVolume, {
                          precision: 2,
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="text-xs">Buy Volume: --</div>
                  )}
                  {data.sellVolumeTop ? (
                    <div className="flex items-center space-x-1">
                      <div
                        className={twMerge(
                          ' text-xs font-bold',
                          Big(data.sellVolumeTop).gt(0) && 'text-red-500 '
                        )}
                      >
                        <span className="xxs:text-white">Sell Volume:</span> $
                        {formatNumberWithSuffix(data.sellVolumeTop, {
                          precision: 2,
                        })}
                      </div>
                      <div className=" text-[10px]">
                        /$
                        {formatNumberWithSuffix(data.sellVolume, {
                          precision: 2,
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="text-xs">Sell Volume: --</div>
                  )}
                  {data.totalBuy ? (
                    <div className="flex items-center space-x-1">
                      <div
                        className={twMerge(
                          ' text-xs font-bold',
                          data.totalBuyTop && 'text-green-500 '
                        )}
                      >
                        <span className="xxs:text-white">Buy Wallets:</span>{' '}
                        {data.totalBuyTop}
                      </div>
                      <div className=" text-[10px]">/{data.totalBuy}</div>
                    </div>
                  ) : (
                    <div className="text-xs">Buy Wallets: --</div>
                  )}
                  {data.totalSells ? (
                    <div className="flex items-center space-x-1">
                      <div
                        className={twMerge(
                          ' text-xs font-bold',
                          data.totalSellsTop && 'text-red-500 '
                        )}
                      >
                        <span className="xxs:text-white">Sell Wallets:</span>{' '}
                        {data.totalSellsTop}
                      </div>
                      <div className=" text-[10px]">/{data.totalSells}</div>
                    </div>
                  ) : (
                    <div className="text-xs">Sell Wallets: --</div>
                  )}
                </div>
              }
            >
              <div className="flex items-center space-x-1">
                <div className=" text-xs font-bold">
                  {performanceStyle(data.netInflowTop)}
                </div>
                <div>/</div>
                <div>{performanceStyle(data.netInflow, 'xxs:text-[10px]')}</div>
              </div>
            </Tooltip>
          ) : (
            <div className="text-xs">--</div>
          )}
        </div>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip
            text={`The volatility index is calculated using the coefficient of variation, which is the ratio of the standard deviation of returns to the average returns over a given period. A higher volatility index indicates greater price fluctuations. This index can be used to compare volatility across different tokens, making it a useful metric for evaluating relative price volatility.`}
          >
            <div>Volatility Index</div>
          </Tooltip>
        </div>
      ),
      key: 'VOLATILITY_INDEX',
      sortableKey: HotTokensSortKeys.VolatilityIndex,
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary '
              : ''
          }
        >
          {data.volatilityIndex ? (
            <div className="flex">
              <div className="flex items-center space-x-1 text-xs xxs:px-2 xxs:py-0.5 bg-dex-black-700 rounded">
                <div>
                  {Big(data.volatilityIndex ?? 0)
                    .mul(100)
                    .toFixed(2)}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-xs">--</div>
          )}
        </div>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text={`The total trading volume in the ${selectedPeriod}.`}>
            <div>Volume</div>
          </Tooltip>
        </div>
      ),
      key: 'VOLUME',
      sortableKey: HotTokensSortKeys.Volume,
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary '
              : ''
          }
        >
          {data.volume ? (
            <div className="flex items-center space-x-1 text-xs">
              <div>
                ${formatNumberWithSuffix(data.volume, { precision: 2 })}
              </div>
            </div>
          ) : (
            <div className="text-xs">--</div>
          )}
        </div>
      ),
    },

    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The current fully diluted Market Capitalization.">
            <div>Market Cap</div>
          </Tooltip>
        </div>
      ),
      key: 'MARKET_CAP',
      sortableKey: HotTokensSortKeys.MarketCap,
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary '
              : ''
          }
        >
          {data.currentMarketCap ? (
            <Tooltip text="Fully diluted Market Cap.">
              <div className=" text-xs ">
                $
                {formatNumberWithSuffix(data.currentMarketCap, {
                  precision: 2,
                })}
              </div>
            </Tooltip>
          ) : (
            <div className="text-xs">--</div>
          )}
        </div>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The current liquidity in the biggest pool.">
            <div>Liquidity</div>
          </Tooltip>
        </div>
      ),
      key: 'LIQUIDITY',
      sortableKey: HotTokensSortKeys.Liquidity,
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary '
              : ''
          }
        >
          {data.currentLiquidity ? (
            <Tooltip text="Liquidity in the biggest pool.">
              <div className="text-xs ">
                $
                {formatNumberWithSuffix(data.currentLiquidity, {
                  precision: 2,
                })}
              </div>
            </Tooltip>
          ) : (
            <div className="text-xs">--</div>
          )}
        </div>
      ),
    },

    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text={`The date when the token was launched.`}>
            <div>Launch</div>
          </Tooltip>
        </div>
      ),
      key: 'CREATION_DATETIME',
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary '
              : ''
          }
        >
          {data.poolCreatedAt ? (
            <div className="flex items-center space-x-1 xxs:text-xs">
              {moment(data.poolCreatedAt).format('DD/MM/YYYY HH:mm')}
            </div>
          ) : (
            <div className="text-xs">--</div>
          )}
        </div>
      ),
    },

    {
      label: <div className="xxs:text-dex-white-secondary" />,
      key: 'ACTIONS',
      component: ({ data }) => (
        <Actions
          tokenAddress={data.tokenAddress}
          chain={data.chain}
          symbol={data.base}
          poolAddress={data.poolAddress}
        />
      ),
    },
  ];
};

export const mobileColumns = (): TableColumn<HotTokensRowInfo>[] => {
  return [
    {
      key: 'PAIR',
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary'
              : ''
          }
        >
          <div className="flex justify-between items-center ">
            <div className="">
              <div className="flex items-center xxs:gap-2">
                <div className="relative">
                  <TokenIconWithChain
                    tokenName={data.base}
                    chain={data.chain ?? Chains.Ethereum}
                    protocol={data.dex}
                    symbol={data.base}
                    className="w-6 h-6"
                    address={data.tokenAddress}
                  />
                </div>
                <div className="max-w-[120px] overflow-hidden truncate">
                  <div>
                    <span className=" text-sm">{data.base}</span>/
                    <span className="xxs:text-dex-white-secondary xxs:text-xs">
                      {data.quote}
                    </span>
                  </div>
                  <div>
                    <EthAddress
                      className="text-xs"
                      address={data.tokenAddress}
                      precision={3}
                      kind="token"
                      chain={data.chain ?? Chains.Ethereum}
                    />
                  </div>
                </div>
                <SuspiciousLabelAlert
                  alertItems={isTokenSuspicious({
                    suspiciousBuy: data.suspiciousBuy,
                    rugAddictedBuy: data.rugAddictedBuy,
                    scammerBuy: data.scammerBuy,
                    freshBuy: data.freshBuy,
                    totalBuy: data.totalBuy,
                  })}
                />
              </div>
              {data.poolCreatedAt && (
                <div className="flex items-center space-x-1 xxs:text-dex-white-secondary xxs:text-xs">
                  Launch:{' '}
                  {moment(data.poolCreatedAt).format('DD/MM/YYYY HH:mm')}
                </div>
              )}
            </div>

            <div className="xxs:space-y-0.5">
              <div className="justify-end flex flex-col items-end">
                {data.currentPrice ? (
                  <div className="flex space-x-1 items-center">
                    <div className=" text-base ">
                      ${formatNumberWithSuffix(data.currentPrice)}
                    </div>
                    {data.priceChange && data.status !== 'RUG_PULL' && (
                      <PercentageChangeWithMaxValue
                        maxValue={999}
                        className="xxs:text-xs pt-1"
                        change={data.priceChange}
                      />
                    )}
                  </div>
                ) : (
                  <div className="text-xs">--</div>
                )}
              </div>

              <div className="xxs:space-y-1">
                <div className="justify-end flex items-end space-x-1 ">
                  <div className="flex space-x-0.5 items-center">
                    <div>🧠</div>
                    <div className=" text-xs font-bold">
                      {performanceStyle(data.netInflowTop)}
                    </div>
                    <div>/</div>
                    <div>
                      {performanceStyle(data.netInflow, 'xxs:text-[10px]')}
                    </div>
                  </div>

                  {data.volatilityIndex ? (
                    <div className="flex items-center space-x-1 text-xs xxs:px-1.5 xxs:py-0.5 xxs:bg-dex-black-700 lg:bg-dex-black-800 rounded">
                      <div>
                        {Big(data.volatilityIndex ?? 0)
                          .mul(100)
                          .toFixed(2)}
                      </div>
                    </div>
                  ) : (
                    <div className="text-xs">--</div>
                  )}
                </div>
                <div className="space-x-1 flex items-center text-xs justify-end ">
                  {data.currentMarketCap && (
                    <div>
                      🧢 $
                      {formatNumberWithSuffix(data.currentMarketCap, {
                        precision: 2,
                      })}
                    </div>
                  )}
                  <div>|</div>
                  {data.currentLiquidity && (
                    <div>
                      💧$
                      {formatNumberWithSuffix(data.currentLiquidity, {
                        precision: 2,
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
};
