import { MobileOption } from './select-option';
import { Props } from './select';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { BaseButton } from 'modules/shared-components/button/base-button';
import {
  SelectionModal,
  SelectionModalOptions,
} from 'modules/shared-components/modal/SelectionModal';
import { twMerge } from 'tailwind-merge';
import React, { forwardRef } from 'react';

function InnerMobileSelect<T extends string | number>(
  {
    label,
    onChange,
    options,
    prefix,
    value,
    extraLabel,
    isLoading,
    isError,
    placeholder,
    modalLabel,
    className,
    inputSize = 'large',
    error,
    disabled,
  }: Props<T> & { modalLabel?: React.ReactNode },
  ref: React.ForwardedRef<HTMLElement>
) {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <>
      <SelectionModal
        label={modalLabel}
        trigger={({ open }) => (
          <div className="w-full">
            <div className="xxs:flex justify-between">
              {label && (
                <label className="block text-sm font-medium text-white-800 dark:text-black-50 xxs:mb-1">
                  {label}
                </label>
              )}
              {extraLabel}
            </div>
            <BaseButton
              type="button"
              ref={ref as any}
              disabled={disabled}
              onClick={open}
              className={twMerge(
                'xxs:flex xxs:gap-2 xxs:items-center xxs:bg-white font-normal xxs:border xxs:border-solid text-left text-white-900 xxs:text-sm block xxs:w-full xxs:rounded-md xxs:border-solid xxs:border-black-600 focus:border-blue-500 focus:ring-blue-500 disabled:cursor-not-allowed disabled:border-black-600 disabled:bg-white-300 disabled:text-gray-500 dark:bg-inherit dark:text-white dark:disabled:bg-black-700 dark:disabled:text-white-700 sm:text-sm',
                inputSize === 'large'
                  ? 'xxs:py-2 xxs:px-3'
                  : 'xxs:py-1 xxs:px-2',
                className
              )}
            >
              {prefix}
              {isLoading ? (
                <div className="spinner-border text-blue-500 h-4 w-4 border-2" />
              ) : selectedOption?.label ? (
                <span className="overflow-hidden flex space-x-1 items-center flex-nowrap">
                  <div>{selectedOption?.icon}</div>{' '}
                  <div>{selectedOption?.label}</div>
                </span>
              ) : options.length ? (
                <span className="text-xs xxs:py-0.5 xxs:text-black-100 ">
                  {placeholder ? placeholder : 'Select...'}
                </span>
              ) : isError ? (
                <span className="text-red-warning text-xs xxs:py-0.5 ">
                  An error occurred...
                </span>
              ) : (
                <span className="text-xs xxs:py-0.5 xxs:text-black-100">
                  {placeholder ? placeholder : 'Select...'}
                </span>
              )}

              <ChevronUpDownIcon className="w-4 ml-auto" />
            </BaseButton>
            {error && (
              <p className="xxs:mb-0 xxs:mt-1 text-sm text-red-warning">
                {error}
              </p>
            )}
          </div>
        )}
      >
        <SelectionModalOptions>
          {options.map((option) => (
            <MobileOption
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              icon={option.icon}
              label={option.label}
              onClick={() => {
                onChange(option);
              }}
            />
          ))}
        </SelectionModalOptions>
      </SelectionModal>
    </>
  );
}

export const MobileSelect = forwardRef(
  InnerMobileSelect
) as typeof InnerMobileSelect;
