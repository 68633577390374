import { ChevronUpIcon } from '@heroicons/react/24/outline';
import styled from '@slicknode/stylemapper';
import { useToggle } from 'modules/utils/useToggle';
import {
  ComponentProps,
  JSXElementConstructor,
  createContext,
  useContext,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { BaseButton } from 'modules/shared-components/button/base-button';

export const Title = (props: ComponentProps<'h5'>) => {
  return (
    <h5
      {...props}
      className={twMerge(
        'xxs:pb-0 xxs:mb-1 dark:text-white-50',
        props.className
      )}
    />
  );
};
export const SubTitle = (props: ComponentProps<'p'>) => {
  return (
    <p
      {...props}
      className={twMerge(
        'xxs:text-xs dark:text-white-600 xxs:pb-0 xxs:mb-0',
        props.className
      )}
    />
  );
};

export const DexCard = ({
  md,
  as = 'div',
  size = 'base',
  ...props
}: ComponentProps<'div'> & {
  md?: boolean;
  as?: 'section' | 'div';
  size?: 'base' | 'xs';
  disabled?: boolean;
}) => {
  const Component = as;
  return (
    <Component
      {...props}
      className={twMerge(
        'xxs:flex xxs:flex-col xxs:rounded-md',
        md
          ? twMerge(
              'xxs:bg-inherit  md:shadow-md',
              size === 'xs' ? 'md:p-2' : 'md:p-4'
            )
          : twMerge(
              'lg:bg-dex-black-800 xxs:shadow-md',
              size === 'xs' ? 'xxs:p-2' : 'xxs:p-4'
            ),
        size === 'xs' ? 'space-y-1' : 'space-y-2',
        props.className,
        props.disabled && 'bg-dex-black-700 cursor-not-allowed'
      )}
    />
  );
};

export type SubCardProps<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = {
  md?: boolean;
  as?: T;
  size?: 'base' | 'xs';
} & ComponentProps<T>;

export const SubCard = <
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
>({
  md,
  as,
  size = 'base',
  ...props
}: SubCardProps<T>) => {
  const Component = as || ('div' as any);

  return (
    <Component
      {...props}
      className={twMerge(
        'xxs:flex xxs:flex-col xxs:w-full lg:rounded-md space-y-2 min-w-0 xxs:bg-dex-black-800',
        size === 'xs' ? 'space-y-1 xxs:p-2' : 'space-y-4 xxs:p-4',
        props.className
      )}
    />
  );
};

export const SubCardBox = <
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
>({
  md,
  as,
  size = 'base',
  ...props
}: SubCardProps<T>) => {
  const Component = as || ('div' as any);

  return (
    <Component
      {...props}
      className={twMerge(
        'xxs:flex xxs:flex-col xxs:w-full lg:rounded-md space-y-2 min-w-0 xxs:bg-dex-black-800 border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-dex-black-700',
        size === 'xs' ? 'space-y-1 xxs:p-2' : 'space-y-4 xxs:p-4',
        props.className
      )}
    />
  );
};

export const SubSubCard = (props: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge(
        'xxs:shadow xxs:bg-black-700 md:dark:bg-black-600 xxs:p-2 sm:p-4 xxs:rounded xxs:text-center xxs:flex-1',
        props.className
      )}
    />
  );
};

export const SubCardTitle = styled(
  'div',
  'xxs:font-normal xxs:text-dex-white-secondary',
  {
    variants: {
      size: {
        base: 'xxs:text-base',
        sm: 'xxs:text-sm',
        xl: 'xxs:text-xl',
      },
    },
    defaultVariants: {
      size: 'xl',
    },
  }
);

const ExpandableCardContext = createContext<{
  expandable?: boolean;
  isOpen: boolean;
  toggle: () => void;
}>({} as any);

export const ExpandableCard = ({
  as: Component = SubCardBox,
  expandable = true,
  defaultExpanded = true,
  ...props
}: ComponentProps<'div'> & {
  as: typeof SubCard | typeof SubSubCard | 'div';
  expandable?: boolean;
  defaultExpanded?: boolean;
  size?: 'base' | 'xs';
}) => {
  const [isOpen, control] = useToggle(defaultExpanded);
  return (
    <ExpandableCardContext.Provider value={{ isOpen, expandable, ...control }}>
      <Component {...props} />
    </ExpandableCardContext.Provider>
  );
};

export const ExpandableCardTitle = ({
  as: Component = SubCardTitle,
  ...props
}: ComponentProps<typeof SubCardTitle> & { as: typeof SubCardTitle }) => {
  const { toggle, isOpen, expandable } = useContext(ExpandableCardContext);

  return (
    <BaseButton type="button" onClick={toggle}>
      <Component
        {...props}
        className={twMerge(
          'xxs:flex items-center xxs:justify-between',
          props.className
        )}
      >
        {props.children}
        {expandable && (
          <ChevronUpIcon
            className={twMerge(
              'w-6 h-6 transition ransform xxs:text-dex-white-secondary',
              !isOpen && '-rotate-180'
            )}
          />
        )}
      </Component>
    </BaseButton>
  );
};

export const ExpandableCardContent = <T extends keyof JSX.IntrinsicElements>({
  as,
  ...props
}: ComponentProps<T> & { as?: T }) => {
  const { isOpen, expandable } = useContext(ExpandableCardContext);
  const Component = as || ('div' as any);

  return isOpen || !expandable ? <Component {...props} /> : null;
};
