/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  GetCandleSticksParams,
  GetMarketDataParams,
  OHLCResponse,
} from 'api/candle-sticks';
import type { Market } from 'ccxt';
import { api } from 'helpers/api/apiCore';
import { type Dictionary } from 'lodash';
import { toQueryString } from 'modules/routing/query-string';
import { RateLimiter } from 'limiter';
import { Chains } from 'api/d-wallets';

const serviceKey = 'candle-sticks';

const coingeckoApiLimitter = new RateLimiter({
  tokensPerInterval: 24,
  interval: 'minute',
});

export const candleStickKeys = {
  getPoolCandleSticks: (params: GetPoolCandleSticks) => [serviceKey, params],
  getPoolStats: (chain: Chains, poolAddress: string) => [
    serviceKey,
    'pool-stats',
    chain,
    poolAddress,
  ],
};

export const CandleStickService = {
  getCandleSticks: async (params: GetCandleSticksParams) => {
    return api.get<OHLCResponse>(
      `/api/v1/candle-sticks?${toQueryString({
        ...params,
        exchange: toApiExchangeName(params.exchange),
      })}`
    );
  },
  getMarketData: async (params: GetMarketDataParams) => {
    return api.get<Dictionary<Market>>(
      `/api/v1/candle-sticks/markets?${toQueryString({
        ...params,
        exchange: toApiExchangeName(params.exchange),
      })}`
    );
  },

  getPoolCandleSticks: async ({
    poolAddress,
    timeframe,
    chain,
    ...params
  }: GetPoolCandleSticks) => {
    await coingeckoApiLimitter.removeTokens(1);
    const result = await api.get<{
      data: { attributes: { ohlcv_list: OHLCResponse } };
    }>(
      `https://api.geckoterminal.com/api/v2/networks/${chain}/pools/${poolAddress}/ohlcv/${timeframe}?${toQueryString(
        params
      )}`
    );

    return result.data.data.attributes.ohlcv_list;
  },

  getPoolStats: async (
    chain: Chains,
    poolAddress: string
  ): Promise<PoolPriceData> => {
    await coingeckoApiLimitter.removeTokens(1);
    const result = await api.get<{
      data: {
        attributes: {
          price_change_percentage: { h1: string; h24: string };
          volume_usd: { h24: string | null };
        };
      };
    }>(
      `https://api.geckoterminal.com/api/v2/networks/${chain}/pools/${poolAddress}?include=dex`
    );

    return {
      volume24h: result.data.data.attributes.volume_usd.h24 || '0',
      change24hPct: isFinite(
        +result.data.data.attributes.price_change_percentage.h24
      )
        ? result.data.data.attributes.price_change_percentage.h24
        : '0',
    };
  },
};

export type PoolPriceData = {
  volume24h: string;
  change24hPct: string;
};

// Available values (day): 1
// Available values (hour): 1, 4, 12
// Available values (minute): 1, 5, 15

export type GetPoolCandleSticks = {
  poolAddress: string;
  timeframe: 'day' | 'hour' | 'minute';
  before_timestamp?: number;
  aggregate?: number;
  limit?: number;
  currency?: string;
  chain: Chains;
};

function toApiExchangeName(name: string) {
  return name === 'binance_paper_trading' ? 'binance' : name;
}
