import { Button } from 'modules/shared-components/button/button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  dWalletServiceKeys,
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/DWalletService';
import { DWalletDetails } from 'api/d-wallets';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { useHistory } from 'react-router-dom';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { usePageParams } from '../usePageParams';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { NotificationDex } from '../../components/alerts/notification';
import CustomModal from '../../components/modal/CustomModal';
import TextInput from '../../components/inputs/text-input/text-input';

function getSchema(wallet: DWalletDetails) {
  return yup.object({
    label: yup
      .string()
      .required('Label is required')
      .test(
        'name-type',
        'Label does not match wallet label',
        (value) => wallet.label === value
      ),
  });
}

type FormValues = yup.InferType<ReturnType<typeof getSchema>>;

export function DeleteDWalletModal({
  wallet,
  handleClose,
}: {
  wallet: DWalletDetails;
  handleClose: () => void;
}) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { handleSubmit, register, formState } = useForm<FormValues>({
    resolver: yupResolver(getSchema(wallet)),
  });
  const [, setAddress] = usePageParams();
  const isDesktop = useMediaQuery(screenGte.small);
  const { addNewMessage } = useSnackBar();
  const { mutate, error, isLoading } = useMutation({
    mutationFn: MultiChainDWalletService.delete,
    onSuccess: async () => {
      await queryClient.invalidateQueries(multiChainDWalletServiceKeys.all());
      await queryClient.invalidateQueries(dWalletServiceKeys.all());

      if (isDesktop) {
        setAddress({});
      } else {
        history.push('/dex/wallets');
      }

      handleClose();
      addNewMessage({
        type: 'success',
        title: 'Success',
        message: 'Wallet has been deleted.',
      });
    },
  });

  const onSubmit = handleSubmit(() => {
    mutate(wallet.address);
  });

  return (
    <CustomModal title="Delete Wallet" showModal handleClose={handleClose}>
      <form
        className="contents"
        onSubmit={(e) => {
          void onSubmit(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4">
          <CustomModal.Title className="hidden lg:flex">
            Delete your Wallet
          </CustomModal.Title>

          <CustomModal.Content>
            <p className="xxs:mb-4 xxs:text-dex-white-secondary ">
              By deleting your wallet, you will stop tracking all your open
              positions and you will not be able to access this wallet from
              Mizar anymore.
            </p>
            <NotificationDex type="warning" className="xxs:my-2 xxs:mx-0 ">
              Please be aware that this action cannot be undone. Once you delete
              your wallet, to manage your wallet and access your funds, it is
              crucial to have your private keys securely stored. Ensure you have
              them available before proceeding.
            </NotificationDex>
            <NotificationDex type="warning" className="xxs:my-2 xxs:mx-0">
              Mizar does not have access to your private keys and cannot recover
              them if they are lost. Please ensure you store them securely.{' '}
            </NotificationDex>

            <p className="xxs:mb-4 xxs:text-dex-white-secondary">
              For your safety, you need to type in your wallet label to proceed.
            </p>

            <div className="flex flex-col xxs:gap-4">
              <TextInput
                label="Your wallet label"
                autoComplete="off"
                {...register('label')}
                error={formState.errors.label?.message}
              />
            </div>

            {error && (
              <NotificationDex
                className="xxs:my-2 xxs:mx-0"
                type="error"
                errorMessage={error}
              >
                An error occurred.
              </NotificationDex>
            )}
          </CustomModal.Content>
        </CustomModal.Body>
        <CustomModal.Footer>
          <PrimaryButton
            className="xxs:text-base lg:text-xs"
            type="submit"
            loading={isLoading}
          >
            DELETE
          </PrimaryButton>
          <Button
            type="button"
            variant="dexNeutral"
            className="xxs:text-base lg:text-xs"
            onClick={() => handleClose?.()}
          >
            Cancel
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
}
