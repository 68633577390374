import {
  ArrowLeftIcon,
  ChevronRightIcon,
  HomeIcon,
} from '@heroicons/react/24/outline';
import config from 'config';
import { BackButton } from 'modules/telegram/BackButton';
import { useUserState } from 'modules/user/UserContext';
import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  DexSwitchMode,
  useExchangeMode,
} from 'modules/DexSwitch/DexSwitchContext';
import { twMerge } from 'tailwind-merge';

export type Page = { name: string; href: string };

export function Breadcrumbs({ pages }: { pages: Page[] }) {
  const { user } = useUserState();
  const { state } = useLocation<{ from?: undefined }>();
  const [previousRoute] = useState(state?.from);
  const history = useHistory();
  const { mode } = useExchangeMode();

  return (
    <>
      <div
        className={twMerge(
          mode === DexSwitchMode.Cex ? 'hidden lg:block' : 'hidden'
        )}
      >
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4 px-0 xxs:mb-0">
            {!!user && (
              <li className="list-none">
                <div>
                  <Link
                    to="/"
                    className="text-white-700 hover:text-white-800 dark:text-white-600 dark:hover:text-white-700"
                  >
                    <HomeIcon
                      className="flex-shrink-0 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
            )}
            {pages.map((page, key) => (
              <li key={page.name} className="list-none">
                <div className="flex items-center">
                  {(key !== 0 || !!user) && (
                    <ChevronRightIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  {key !== pages.length - 1 ? (
                    <Link
                      to={page.href}
                      className="xxs:ml-4 text-sm font-medium text-white-700 hover:text-white-800 dark:text-white-600 dark:hover:text-white-700"
                    >
                      {page.name}
                    </Link>
                  ) : (
                    <div className="xxs:ml-4 text-sm font-medium text-blue-500">
                      {page.name}
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>

      {pages?.length !== 1 ? (
        config.isTelegramBrowser ? (
          <div className="lg:hidden  xxs:pt-2 lg:pt-0">
            <BackButton
              onClick={() => {
                history.push(previousRoute || pages[pages.length - 2].href);
              }}
            />
          </div>
        ) : (
          <div className="lg:hidden  xxs:pt-4 lg:pt-0">
            <Link
              to={previousRoute || pages[pages.length - 2].href}
              className="text-sm text-white-700 hover:text-white-800 flex"
            >
              {' '}
              <ArrowLeftIcon
                className="flex-shrink-0 h-5 w-5 xxs:mr-1 align-center"
                aria-hidden="true"
              />
              <span className="align-center">Back</span>
            </Link>
          </div>
        )
      ) : (
        ''
      )}
    </>
  );
}
