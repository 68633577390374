/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  IDatafeedChartApi,
  IExternalDatafeed,
  ResolutionString,
  Timezone,
} from 'modules/tw-chart/datafeed-api';
import * as Sentry from '@sentry/react';
import {
  CandleStickService,
  GetPoolCandleSticks,
} from 'api/services/CandleSticksService';
import Big from 'big.js';
import { buildSubscriptionStore } from 'modules/tw-chart/subscriptionStore';
import { sleep } from 'utils/sleep';
import { duration } from 'moment';
import { Chains } from 'api/d-wallets';

const configurationData = {
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: false,
  supported_resolutions: [
    '1',
    '5',
    '15',
    '60',
    '240',
    '1D',
  ] as ResolutionString[],
};

export function calculatePriceScale(currentPrice: number): number {
  let orderOfMagnitude: number = Math.floor(Math.log10(currentPrice)) - 3;
  orderOfMagnitude = Math.max(orderOfMagnitude, -14); // Minimum order of magnitude to handle small values

  return Big(10).pow(-orderOfMagnitude).toNumber();
}

export const subscriptions = buildSubscriptionStore();

export const createDatafeed = (
  chain: Chains,
  referencePrice?: number
): IDatafeedChartApi & IExternalDatafeed => {
  return {
    onReady: (callback) => {
      setTimeout(() => callback(configurationData)); // callback must be called asynchronously
    },
    searchSymbols: async () => {},
    resolveSymbol: (symbolFullName, onSymbolResolvedCallback) => {
      const [symbol] = symbolFullName.split(':');

      setTimeout(() => {
        onSymbolResolvedCallback({
          session: '24x7',
          minmov: 1,
          pricescale: referencePrice
            ? calculatePriceScale(referencePrice)
            : 100000000,
          has_intraday: true,
          has_daily: true,
          has_weekly_and_monthly: true,
          description: '',
          exchange: 'Uniswap',
          format: 'price',
          full_name: symbol,
          listed_exchange: 'Uniswap',
          supported_resolutions: configurationData.supported_resolutions,
          timezone: Intl.DateTimeFormat().resolvedOptions()
            .timeZone as Timezone,
          name: symbol,
          ticker: symbolFullName,
          type: 'crypto',
        });
      }, 0);
    },
    getBars: async (
      symbolInfo,
      _resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback
    ) => {
      try {
        const [symbol, tokenAddress, poolAddress] =
          symbolInfo.ticker!.split(':');

        const result = await CandleStickService.getPoolCandleSticks({
          poolAddress,
          chain,
          limit: periodParams.countBack,
          before_timestamp: periodParams.to,
          ...resolutionToTimeframe(_resolution),
        });

        const bars = result
          .map(([time, open, high, low, close, volume]) => ({
            time: time * 1000,
            open,
            high,
            low,
            close,
            volume,
          }))
          .reverse();

        onHistoryCallback(bars, {
          noData: bars.length === 0,
        });
      } catch (e) {
        Sentry.captureException(e);
        onErrorCallback('Error');
      }
    },

    // subscription to real-time updates
    subscribeBars: async (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID
    ) => {
      subscriptions.subscribe(subscribeUID);
      const [symbol, tokenAddress, poolAddress] = symbolInfo.ticker!.split(':');

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const result = await CandleStickService.getPoolCandleSticks({
          poolAddress,
          chain,
          limit: 2,
          ...resolutionToTimeframe(resolution),
        });

        if (!subscriptions.isSubscribed(subscribeUID)) {
          break;
        }

        result.forEach(([time, open, high, low, close, volume]) => {
          onRealtimeCallback({
            time: time * 1000,
            open,
            high,
            low,
            close,
            volume,
          });
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await sleep(duration(12, 'seconds').asMilliseconds());
      }
    },
    unsubscribeBars: (subscriberUID) => {
      subscriptions.unsubscribe(subscriberUID);
    },
  };
};

function resolutionToTimeframe(
  resolution: ResolutionString
): Pick<GetPoolCandleSticks, 'timeframe' | 'aggregate'> {
  switch (resolution) {
    case '1':
      return { timeframe: 'minute', aggregate: 1 };
    case '5':
      return { timeframe: 'minute', aggregate: 5 };
    case '15':
      return { timeframe: 'minute', aggregate: 15 };
    case '60':
      return { timeframe: 'hour', aggregate: 1 };
    case '240':
      return { timeframe: 'hour', aggregate: 4 };
    case '1D':
      return { timeframe: 'day' };
    default:
      throw new Error(`Unsupported resolution: ${resolution}`);
  }
}
