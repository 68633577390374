import {
  DexSwitchMode,
  useExchangeMode,
} from 'modules/DexSwitch/DexSwitchContext';
import { twMerge } from 'tailwind-merge';

const Footer = () => {
  const { mode } = useExchangeMode();
  return (
    <footer
      className={twMerge(
        mode === DexSwitchMode.Cex
          ? 'xxs:bg-black-800'
          : 'bg-dex-black-900 border-tailwind xxs:border-x-0 xxs:border-b-0 xxs:border-dex-black-700',
        'xxs:px-6 xxs:py-4 hidden lg:flex justify-end items-center shadow-t'
      )}
    >
      <div className="flex xxs:space-x-4 items-center">
        <a
          href={
            mode === DexSwitchMode.Cex
              ? 'https://docs.mizar.com/c-mizar-manual/'
              : 'https://docs.mizar.com/sniper-bot-manual'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="xxs:text-dex-white-secondary hover:text-dex-white xxs:text-sm">
            Docs
          </span>
        </a>
        <a
          href="https://discord.com/invite/gM4mAYMeWG"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="xxs:text-dex-white-secondary hover:text-dex-white xxs:text-sm">
            Support
          </span>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.com/invite/gM4mAYMeWG"
        >
          <span className="xxs:text-dex-white-secondary hover:text-dex-white xxs:text-sm">
            Contact Us
          </span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
