import Button from 'modules/shared-components/button/button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { valueAsSafeNumber } from 'utils/FormatNumber';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { useTelegramChannelsSearchFilters } from '../usePageParams';
import {
  PresetButton,
  PresetButtonDescription,
  PresetButtonTitle,
} from '../../../research/components/TelegramCalls/PresetButton';
import NumberInput from '../../../components/inputs/number-input';
import CustomModal from '../../../components/modal/CustomModal';

type Props = {
  handleClose: () => void;
};
export const schema = yup.object({
  min: yup.number().min(0).max(100),
  max: yup.number().min(0).max(100),
});

type FormState = yup.InferType<typeof schema>;

export function WinnersVsLosersFiltersModal({ handleClose }: Props) {
  const { filtersState, setFilters } = useTelegramChannelsSearchFilters();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      min: filtersState?.minWinnersVsLosers
        ? filtersState.minWinnersVsLosers * 100
        : undefined,
      max: filtersState?.maxWinnersVsLosers
        ? filtersState.maxWinnersVsLosers * 100
        : undefined,
    },
  });

  return (
    <CustomModal title="Hit Ratio" handleClose={handleClose} showModal>
      <form
        autoComplete="off"
        className="contents"
        onSubmit={(e) => {
          void handleSubmit((form) => {
            setFilters({
              ...filtersState,
              minWinnersVsLosers: form.min ? form.min / 100 : undefined,
              maxWinnersVsLosers: form.max ? form.max / 100 : undefined,
            });
            handleClose();
          })(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4">
          <CustomModal.Title className="hidden lg:flex">
            Hit Ratio
          </CustomModal.Title>

          <CustomModal.Content>
            <div className="xxs:space-y-3">
              <p className="xxs:text-sm xxs:mb-0">
                The Hit Ratio filter enables you to focus on channels with a
                specific hit ratio—the number of calls resulting in wins
                (returns above 25% after the call is made) compared to the total
                number of calls over the period. This feature is useful for
                finding channels that achieve higher success rates on average.
              </p>
              <div className="xxs:space-y-1">
                <p className="xxs:text-sm xxs:mb-0">Set a Range</p>
                <div className="grid grid-cols-3 xxs:gap-1 xxs:mb-4">
                  <PresetButton
                    onClick={() => {
                      setValue('min', 0);
                      setValue('max', 50);
                    }}
                  >
                    <PresetButtonTitle>LOW</PresetButtonTitle>
                    <PresetButtonDescription>0% to 50%</PresetButtonDescription>
                  </PresetButton>
                  <PresetButton
                    onClick={() => {
                      setValue('min', 50);
                      setValue('max', 80);
                    }}
                  >
                    <PresetButtonTitle>MEDIUM</PresetButtonTitle>
                    <PresetButtonDescription>
                      50% to 80%
                    </PresetButtonDescription>
                  </PresetButton>
                  <PresetButton
                    onClick={() => {
                      setValue('min', 80);
                      setValue('max', undefined);
                    }}
                  >
                    <PresetButtonTitle>HIGH</PresetButtonTitle>
                    <PresetButtonDescription>Above 80%</PresetButtonDescription>
                  </PresetButton>
                </div>
                <p className="xxs:mb-1">Custom</p>
                <div className="flex xxs:gap-1">
                  <NumberInput
                    label="Min"
                    suffix="%"
                    {...register('min', {
                      setValueAs: valueAsSafeNumber,
                    })}
                    error={errors.min?.message}
                  />
                  <NumberInput
                    label="Max"
                    suffix="%"
                    {...register('max', {
                      setValueAs: valueAsSafeNumber,
                    })}
                    error={errors.max?.message}
                  />
                </div>
              </div>
            </div>
          </CustomModal.Content>
        </CustomModal.Body>
        <CustomModal.Footer>
          <PrimaryButton type="submit" className="xxs:text-base lg:text-xs">
            SAVE
          </PrimaryButton>
          <Button
            type="button"
            className="xxs:text-base lg:text-xs"
            variant="dexNeutral"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
}
