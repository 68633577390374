/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import Big from 'big.js';
import { useToggle } from 'modules/utils/useToggle';
import {
  ContractInfo,
  GetHotTokensParams,
  HotTokensSortKeys,
  PoolInfo,
} from 'api/contracts';
import { useUserState } from 'modules/user/UserContext';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { ChevronUpIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { ExchangeIconStretch } from 'modules/shared-components/exchange/exchange-icon';
import { AddToFavouritesButton } from './index';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import { isNotNil } from 'modules/utils/isNotNil';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { HotTokensPeriods } from '../research/components/Wallets/usePageParams';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import { duration } from 'moment';
import {
  candleStickKeys,
  CandleStickService,
} from 'api/services/CandleSticksService';
import {
  isTokenSuspicious,
  isTokenSuspiciousCheck,
  SuspiciousLabelAlert,
} from '../research/components/Wallets/labels';
import Button from 'modules/shared-components/button/button';
import { WhitelistButton } from '../research/components/SnipingOpportunitiesCard/columns';

export type TokenTradeInfoHeaderProps = {
  contract: ContractInfo;
  pool: PoolInfo | undefined;
  expandable?: boolean;
};

interface LimitsProps {
  totalSupply: number | null;
  max: number | null;
}

export function calculateLimits({ totalSupply, max }: LimitsProps) {
  const ratio =
    max && totalSupply ? (max / totalSupply > 1 ? 1 : max / totalSupply) : 0;
  return ratio ? (
    <span>{Big(ratio).times(100).toFixed(1)}%</span>
  ) : (
    <span>--</span>
  );
}

export function TokenTradeInfoHeader({
  contract,
  pool,
  expandable = false,
}: TokenTradeInfoHeaderProps) {
  const [isOpen, { toggle }] = useToggle();
  const [openModal, { open, close }] = useToggle();

  const { user } = useUserState();
  const params: GetHotTokensParams = {
    sort: HotTokensSortKeys.TotalBuyTop,
    address: contract.generalInfo.address,
    chain: contract.generalInfo.chain.key,
    period: HotTokensPeriods.TwentyFourHour,
  };

  const { data: poolsInfoFromBe, error: poolsInfoErrorFromBe } = useQuery({
    queryKey: contractServiceKeys.getHotTokens(params),
    queryFn: () => ContractService.getHotTokens(params),
    keepPreviousData: true,
    refetchInterval: duration(12, 'seconds').asMilliseconds(),
  });

  const { data: poolsInfoFromCg } = useQuery({
    queryFn: () =>
      CandleStickService.getPoolStats(
        contract.generalInfo.chain.key,
        pool?.poolAddress!
      ),
    queryKey: candleStickKeys.getPoolStats(
      contract.generalInfo.chain.key,
      pool?.poolAddress!
    ),
    enabled: !!pool && !!poolsInfoErrorFromBe,
  });

  const poolPriceData =
    poolsInfoFromBe && poolsInfoFromBe.length > 0
      ? {
          change24hPct: Big(poolsInfoFromBe[0].priceChange ?? 0).mul(100),
          volume24h: poolsInfoFromBe[0].volume,
        }
      : poolsInfoFromCg
      ? {
          change24hPct: poolsInfoFromCg.change24hPct,
          volume24h: poolsInfoFromCg.volume24h,
        }
      : undefined;

  return (
    <div className="space-y-2 xxs:p-2">
      {poolsInfoFromBe?.[0] && isTokenSuspiciousCheck(poolsInfoFromBe?.[0]) && (
        <div className=" xxs:p-2 rounded xxs:bg-red-600 text-red-500">
          <div>Warning: the wallets trading this token look suspicious!</div>
          <ul className="xxs:mb-0 xxs:pl-4">
            {isTokenSuspicious(poolsInfoFromBe?.[0])?.map((item, index) => (
              <li className="" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
      <BaseButton
        className="xxs:flex xxs:space-x-1 items-center"
        type="button"
        onClick={() => {
          if (expandable) {
            toggle();
          }
        }}
      >
        <div className="xxs:flex relative items-center xxs:space-x-1">
          {poolsInfoFromBe && poolsInfoFromBe?.[0] && (
            <SuspiciousLabelAlert
              alertItems={isTokenSuspicious({
                suspiciousBuy: poolsInfoFromBe[0].suspiciousBuy,
                rugAddictedBuy: poolsInfoFromBe[0].rugAddictedBuy,
                scammerBuy: poolsInfoFromBe[0].scammerBuy,
                freshBuy: poolsInfoFromBe[0].freshBuy,
                totalBuy: poolsInfoFromBe[0].totalBuy,
              })}
            />
          )}
          <TokenIconWithChain
            protocol={pool?.dex.key}
            chain={contract.generalInfo.chain.key}
            tokenName={contract.generalInfo.name ?? undefined}
            className="h-[25px] w-[25px] "
            address={contract.generalInfo.address}
            symbol={contract.generalInfo.symbol || undefined}
          />
          {pool && (
            <ExchangeIconStretch
              imgName={pool.dex.key}
              className="w-2.5 h-2.5 absolute -top-0.5 -right-0.5"
            />
          )}
        </div>
        <div className="truncate max-w-[250px]">
          {' '}
          <span className=" whitespace-nowrap max-w-md xxs:text-3xl xxs:text-dex-white">
            {contract.generalInfo.name}{' '}
          </span>
          <span className="whitespace-nowrap xxs:w-[120px] max-w-xs md:w-auto  xxs:text-xl xxs:text-dex-white-secondary">
            {contract.generalInfo.symbol}
          </span>
        </div>
        {user && (
          <AddToFavouritesButton
            chain={contract.generalInfo.chain.key}
            contractAddress={contract.generalInfo.address}
            className="xxs:w-8 xxs:h-8 flex-shrink-0"
          />
        )}
        {user && (
          <Button
            type="button"
            onClick={open}
            className="xxs:p-1"
            variant="dexNeutral"
          >
            <ChartBarSquareIcon className="xxs:w-5 xxs:h-5" />
          </Button>
        )}
        {openModal && (
          <WhitelistButton
            close={close}
            symbol={contract.generalInfo.symbol ?? ''}
            tokenAddress={contract.generalInfo.address}
          />
        )}
        {expandable && (
          <IconButton className="ml-auto">
            <ChevronUpIcon
              className={twMerge(
                'w-6 h-6 transition ransform xxs:text-dex-white-secondary',
                !isOpen && '-rotate-180'
              )}
            />
          </IconButton>
        )}
      </BaseButton>
      {(!expandable || isOpen) && (
        <>
          <div className="flex xxs:gap-2 items-center xxs:text-base !mt-0">
            {pool?.priceDollar && (
              <>
                <span className=" xxs:font-semibold">
                  ${formatNumberWithSuffix(pool.priceDollar)}{' '}
                  {poolPriceData && (
                    <span
                      className={twMerge(
                        'xxs:text-xs xxs:font-normal',
                        Big(poolPriceData.change24hPct).gt(0)
                          ? 'text-green-500'
                          : 'text-red-500'
                      )}
                    >
                      {Big(poolPriceData.change24hPct).gt(0) ? '+' : ''}
                      {Big(poolPriceData.change24hPct).toFixed(2)}%
                    </span>
                  )}
                </span>
              </>
            )}{' '}
            {contract.generalInfo.address && (
              <div className="xxs:text-sm xxs:text-dex-white-secondary xxs:font-normal flex items-center xxs:gap-1">
                CA:{' '}
                <EthAddress
                  address={contract.generalInfo.address}
                  kind="token"
                  className="text-xsm"
                />
              </div>
            )}
          </div>

          <div className="flex xxs:gap-2 items-center xxs:text-xs">
            {poolPriceData?.volume24h && (
              <div>
                <span className="xxs:text-xs  xxs:text-dex-white-secondary ">
                  24h vol:{' '}
                </span>
                <span className="xxs:text-base xxs:font-semibold">
                  $
                  {formatNumberWithSuffix(poolPriceData?.volume24h, {
                    precision: 0,
                    suffixes: allSuffixes,
                  })}
                </span>
              </div>
            )}
            {pool?.poolLiquidity && (
              <div>
                <span className="xxs:text-xs  xxs:text-dex-white-secondary ">
                  Liq:{' '}
                </span>
                <span className="xxs:text-base xxs:font-semibold">
                  $
                  {formatNumberWithSuffix(pool?.poolLiquidity, {
                    precision: 0,
                    suffixes: allSuffixes,
                  })}
                </span>
              </div>
            )}
            {contract.generalInfo.totalMarketCap && (
              <div>
                <span className="xxs:text-xs  xxs:text-dex-white-secondary ">
                  M.Cap:{' '}
                </span>
                <span className="xxs:text-base xxs:font-semibold">
                  {formatNumberWithSuffix(contract.generalInfo.totalMarketCap, {
                    precision: 0,
                    suffixes: allSuffixes,
                  })}
                </span>
              </div>
            )}
          </div>
          <div className="flex items-center xxs:text-sm xxs:space-x-2">
            <div className=" xxs:text-dex-white-secondary ">Taxes:</div>
            <div className="xxs:font-semibold">
              {isNotNil(contract.tradingInfo.buyTax) ? (
                <span
                  className={
                    contract.tradingInfo.buyTax > 10 ? 'text-red-500' : ''
                  }
                >
                  B {Big(contract.tradingInfo.buyTax).mul(100).toNumber()}%
                </span>
              ) : (
                '--'
              )}{' '}
              |{' '}
              {isNotNil(contract.tradingInfo.sellTax) ? (
                <span
                  className={
                    contract.tradingInfo.sellTax > 10 ? 'text-red-500' : ''
                  }
                >
                  S {Big(contract.tradingInfo.sellTax).mul(100).toNumber()}%
                </span>
              ) : (
                '--'
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
