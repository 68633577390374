import { ContractInfo } from 'api/contracts';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import { MainStatsComponent } from '../../../../trade/stats/main-stats';
import { ExpandableCardContent } from '../../../../components/cards';

type Props = { contract: ContractInfo };

export function MainStats({ contract }: Props) {
  return (
    <MobileExpandableCard
      mobileLabel="Main Stats"
      trigger={
        <MobileExpandCardTrigger>
          <div className="flex xxs:gap-1 items-center">Main Stats</div>
        </MobileExpandCardTrigger>
      }
    >
      <div className="xxs:px-2">
        <ExpandableCardContent className="xxs:space-y-2">
          <MainStatsComponent contract={contract} />
        </ExpandableCardContent>
      </div>
    </MobileExpandableCard>
  );
}
