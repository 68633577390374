export const LoadingComponent = () => (
  <div className="w-full">
    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
      return (
        <div
          key={index}
          className=" py-2 animate-pulse xxs:bg-transparent lg:bg-dex-black-800 hover:text-black-800 xxs:p-2 w-full xxs:text-xs flex xxs:gap-2 items-center"
        >
          <div className="grow ">
            <div className="h-2.5 rounded-full xxs:bg-black-700 w-12 mb-2.5" />
            <div className="h-1.5 rounded-full xxs:bg-black-700 w-12 mb-2.5" />
          </div>
          <div className="shrink-0">
            <div className="h-2.5  rounded-full xxs:bg-black-700 w-12 mb-2.5" />
            <div className="h-1.5 rounded-full xxs:bg-black-700 w-12 mb-2.5" />
          </div>
        </div>
      );
    })}
  </div>
);
