import { FC, forwardRef, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface LabelProps {
  disabled?: boolean;
}

type ILabel = FC<
  LabelProps &
    React.DetailedHTMLProps<
      React.LabelHTMLAttributes<HTMLLabelElement>,
      HTMLLabelElement
    >
>;

type ICheckbox = React.ForwardRefExoticComponent<
  CheckboxProps & React.RefAttributes<HTMLInputElement>
> & {
  Label: ILabel;
};

type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, ...props }, ref) => (
    <input
      ref={ref}
      type="checkbox"
      className={twMerge(
        'xxs:w-5 xxs:h-5 text-blue-600 xxs:bg-white-100 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-black-800 focus:ring-2 dark:bg-black-300 dark:hover:bg-black-100 dark:hover:checked:bg-mizar-blue-500 checked:xxs:bg-mizar-blue-600 dark:border-black-600 border-black-300 border-solid',
        'disabled:checked:bg-black-300 disabled:checked:dark:bg-black-300 disabled:pointer-events-none',
        className
      )}
      {...props}
    />
  )
) as ICheckbox;

const Label: ILabel = ({ children, disabled, className, ...rest }) => {
  return (
    <label
      style={{ opacity: '1' }}
      className={twMerge(
        'font-medium ml-1 truncate',
        disabled
          ? 'text-black-700 dark:text-white-800'
          : 'text-black-900 dark:text-white',
        className
      )}
      {...rest}
    >
      {children}
    </label>
  );
};

Checkbox.Label = Label;

export default Checkbox;
