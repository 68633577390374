import { ResponsiveTableColumn } from 'modules/shared-components/data-display/table/types';
import { HotTokensRowInfo } from 'api/contracts';
import { FC } from 'react';
import ErrorBoundary from 'components/error-boundaries';
import Table from 'modules/shared-components/data-display/table';
import { useHistory } from 'react-router-dom';
import { thDynamicClass } from './index';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';

interface Props {
  columnsDef: ResponsiveTableColumn<HotTokensRowInfo>[];
  data: HotTokensRowInfo;
}

export const TableRow: FC<Props> = ({ data, columnsDef }) => {
  const history = useHistory();
  const isDesktop = useMediaQuery(screenGte.small);

  return (
    <tr
      onClick={() =>
        history.push({
          pathname: !isDesktop
            ? `/dex/research/${data.chain}/${data.tokenAddress}`
            : `/dex/snipe/new/${data.chain}/${data.tokenAddress}`,
          state: {
            from: `${history.location.pathname}${history.location.search}`,
          },
        })
      }
      className="border-t border-dex-black-700 lg:dark:bg-dex-black-800 cursor-pointer items-center hover:dark:lg:bg-dex-black-700 lg:grid lg:grid-cols-9"
    >
      <ErrorBoundary>
        {columnsDef.map((column) => {
          const Column = column.component;
          if (!Column) return;
          return (
            <Table.Td className={thDynamicClass(column.key)} key={column.key}>
              <Column data={data} />
            </Table.Td>
          );
        })}
      </ErrorBoundary>
    </tr>
  );
};
