import { Placement } from '@popperjs/core';
import React, { useState, useEffect, ReactHTMLElement } from 'react';
import { usePopper } from 'react-popper';
import { TooltipContent } from './tooltip-content';

type Props = {
  text?: string;
  children?: React.ReactNode;
  placement?: Placement;
};

export const CopyContent = ({ children, text, placement = 'top' }: Props) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [display, setDisplay] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    if (display) {
      const timeout = setTimeout(() => {
        setDisplay(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [display]);

  return React.isValidElement(children) ? (
    <>
      {React.cloneElement(children as ReactHTMLElement<HTMLSpanElement>, {
        ref: setReferenceElement,
        onClick: (e) => {
          e.stopPropagation();
          void window.navigator.clipboard.writeText(text || '');
          setDisplay(true);
          children.props.onClick?.(e);
        },
      })}

      {display && text && (
        <TooltipContent
          className="xxs:bg-white p-2 rounded drop-shadow dark:bg-black-700 xxs:max-w-lg"
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 1031 }}
          {...attributes.popper}
        >
          Copied!
          <div ref={setArrowElement} style={styles.arrow} />
        </TooltipContent>
      )}
    </>
  ) : null;
};
