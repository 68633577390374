import { Combobox } from '@headlessui/react';
import { Options, useDropdownPosition } from '.';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

export type FilterDropdownProps = {
  trigger: React.ReactNode;
  value: string;
  onChange: (value: string) => void;
  icon?: React.ReactNode;
  isError?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export function BaseDropdown({
  trigger,
  onChange,
  value,
  isError,
  loading,
  children,
  className,
}: FilterDropdownProps) {
  const { setReferenceElement, setPopperElement, attributes, styles } =
    useDropdownPosition();

  return (
    <Combobox
      as="div"
      value={value}
      onChange={(newValue: string) => {
        onChange(newValue);
      }}
      ref={setReferenceElement}
      className={twMerge('relative xxs:flex-shrink-0', className)}
    >
      {trigger}

      <Options
        as={Combobox.Options}
        {...attributes.popper}
        ref={setPopperElement}
        style={styles.popper}
      >
        {loading && (
          <Combobox.Option
            value={null}
            disabled
            className="xxs:py-2 xxs:px-2 flex justify-center"
          >
            <ArrowPathIcon
              className={twMerge('h-5 w-5 text-gray-400 animate-spin')}
              aria-hidden="true"
            />
          </Combobox.Option>
        )}
        {isError && (
          <Combobox.Option
            value={null}
            disabled
            className="xxs:py-2 xxs:pl-3 xxs:pr-10 text-red-warning"
          >
            Could not load options
          </Combobox.Option>
        )}
        {children}
      </Options>
    </Combobox>
  );
}

export const NoResultsFound = () => {
  return (
    <Combobox.Option
      value={null}
      disabled
      className="xxs:py-2 xxs:pl-3 xxs:pr-10 text-white-800"
    >
      No results found
    </Combobox.Option>
  );
};
