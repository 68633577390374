import { twMerge } from 'tailwind-merge';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { formatApiError } from 'helpers/api/apiErrors';

export type NotificationProps = {
  children: React.ReactNode;
  errorMessage?: unknown;
  title?: string;
  onClose?: () => void;
  type: 'error' | 'success' | 'warning' | 'info';
  variant?: 'neutral';
  size?: 'default' | 'small' | 'xSmall' | 'xxSmall';
} & React.ComponentProps<'div'>;

export enum AlertColors {
  error = 'red-500',
  success = 'green-500',
  warning = 'yellow-500',
  info = 'dex-black-600',
}

export enum AlertBgs {
  error = 'red-600',
  success = 'green-600',
  warning = 'yellow-600',
  info = 'dex-black-700',
}

export function NotificationDex({
  children,
  onClose,
  type,
  variant,
  errorMessage,
  size = 'default',
  ...props
}: NotificationProps) {
  return (
    <div
      {...props}
      className={twMerge(
        'xxs:rounded flex items-center lg:mx-0',
        size === 'default'
          ? 'xxs:px-2 xxs:py-1 xxs:gap-2'
          : size === 'small'
          ? 'xxs:px-2 xxs:py-2 xxs:gap-1'
          : size === 'xSmall'
          ? 'xxs:py-0.5 xxs:px-2 xxs:gap-1 xxs:text-xs'
          : size === 'xxSmall'
          ? 'xxs:px-2 xxs:py-0.5 xxs:gap-1'
          : '',
        variant !== 'neutral'
          ? `bg-${AlertBgs[type]} xxs:text-${AlertColors[type]} `
          : 'bg-dex-black-700',
        props.className
      )}
      role="alert"
    >
      <div
        className={twMerge(
          'w-2 h-2 rounded-full xxs:p-1',
          `xxs:bg-${AlertColors[type]}`
        )}
      />

      <div className="xxs:font-normal xxs:overflow-hidden w-full">
        {errorMessage ? <span>{formatApiError(errorMessage)}</span> : children}
      </div>
      {onClose && (
        <div className="ml-auto flex items-center">
          <svg
            className={twMerge(
              'fill-current text-black-100',
              size === 'xxSmall' ? 'h-4 w-4' : 'h-6 w-6'
            )}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            onClick={onClose}
          >
            <title>Dismiss Notification</title>
            <XMarkIcon className="w-4 h-e" />
          </svg>
        </div>
      )}
    </div>
  );
}
