import { ContractInfo, PoolInfo } from 'api/contracts';
import Tooltip from 'modules/shared-components/tooltip';
import {
  StatsCard,
  StatsCardTitle,
  StatsCardValue,
} from '../../research/components/StatsCard';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import moment from 'moment';
import { useState } from 'react';
import { HotTokensPeriods } from '../../research/components/Wallets/usePageParams';
import { twMerge } from 'tailwind-merge';
import Big from 'big.js';
import { PERIOD_FILTERS } from '../../research/components/HotTokens/HotTokensFilters';
import Button from 'modules/shared-components/button/button';
import {
  formatNumberWithSuffix,
  formatWithoutDecimals,
} from 'utils/FormatNumber';

interface Props {
  contract: ContractInfo;
}

type StatsDataItem = {
  key: string;
  title: string;
  tooltip: string;
  value: React.ReactNode;
};

export const MainStatsComponent = ({ contract }: Props) => {
  const [period, setPeriod] = useState<HotTokensPeriods>(
    HotTokensPeriods.OneHour
  );

  const primaryPool = contract?.liquidityInfo?.activePools?.[0] as
    | PoolInfo
    | undefined;

  const params = {
    chain: contract.generalInfo.chain.key,
    tokenAddress: contract.generalInfo.address,
  };
  const { data: stats } = useQuery({
    queryKey: contractServiceKeys.getMainTokenStats(params),
    queryFn: () => ContractService.getMainTokenStats(params),
    keepPreviousData: true,
  });

  const statsData = [
    {
      key: 'priceChange',
      title: 'Price Change',
      tooltip: `The price change in the last ${period}.`,
      value: stats?.priceChange?.[period] ? (
        <div className="flex items-center space-x-1">
          <div className="font-bold">
            <div
              className={twMerge(
                Big(stats.priceChange[period] ?? 0).gte(0)
                  ? 'xxs:text-green-500'
                  : 'xxs:text-red-500'
              )}
            >
              {Big(stats.priceChange[period] ?? 0).gt(0) ? '+' : ''}
              {Big(stats.priceChange[period] ?? 0)
                .times(100)
                .toFixed(1, Big.roundDown)}
              %
            </div>
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'volume',
      title: 'Volume',
      tooltip: `The amount of volume traded in the last ${period}.`,
      value: stats?.volume?.[period] ? (
        <div className="flex items-center space-x-1">
          <div className="font-bold">
            ${formatNumberWithSuffix(Big(stats.volume[period] ?? 0).toNumber())}
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'totalBuyTx',
      title: 'Buy Tx',
      tooltip: `The total number of buy transactions in the last ${period}.`,
      value: stats?.buyTx?.[period] ? (
        <div className="flex items-center space-x-1">
          <div className="font-bold flex space-x-1 items-center">
            <div
              className={twMerge(
                'w-2 h-2 rounded-full xxs:p-1',
                `xxs:bg-green-500`
              )}
            />
            <div>
              {formatWithoutDecimals(Big(stats.buyTx[period] ?? 0).toNumber())}
            </div>
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'totalSellTx',
      title: 'Sell Tx',
      tooltip: `The total number of sell transactions in the last ${period}.`,
      value: stats?.sellTx?.[period] ? (
        <div className="flex items-center space-x-1">
          <div className="font-bold flex space-x-1 items-center">
            <div
              className={twMerge(
                'w-2 h-2 rounded-full xxs:p-1',
                `xxs:bg-red-500`
              )}
            />
            <div>
              {formatWithoutDecimals(Big(stats.sellTx[period] ?? 0).toNumber())}
            </div>
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
  ];

  const mainStatsData = [
    {
      key: '24HVolume',
      title: '24H Volume',
      tooltip: `The total volume traded in the last 24h.`,
      value: stats?.volume?.[HotTokensPeriods.TwentyFourHour] ? (
        <div className="flex items-center space-x-1">
          <div className="font-bold">
            $
            {formatNumberWithSuffix(
              Big(
                stats?.volume?.[HotTokensPeriods.TwentyFourHour] ?? 0
              ).toNumber()
            )}
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'liquidity',
      title: 'Liquidity',
      tooltip: `The total liquidity in the biggest pool.`,
      value: primaryPool ? (
        <div className="flex items-center space-x-1">
          <div className="font-bold">
            $
            {formatNumberWithSuffix(
              Big(primaryPool.poolLiquidity ?? 0).toNumber()
            )}
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'marketCap',
      title: 'Market Cap',
      tooltip: `The total fully diluted market cap`,
      value: contract.generalInfo.totalMarketCap ? (
        <div className="flex items-center space-x-1">
          <div className="font-bold">
            $
            {formatNumberWithSuffix(
              Big(contract.generalInfo.totalMarketCap ?? 0).toNumber()
            )}
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'launchDate',
      title: 'Launch Date',
      tooltip: `The day in which the token has been launched`,
      value: stats?.main?.launchDate ? (
        <div className="flex items-center space-x-1">
          <div className="font-bold">
            {moment(stats.main.launchDate).format('DD/MM/YYYY HH:mm')}
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
  ];

  if (!statsData) {
    return (
      <div className="xxs:p-2 overflow-y-scroll mt-1 mb-0 ">
        <div className="flex flex-col w-full lg:w-full h-full xxs:gap-0 lg:gap-1 ">
          <div className="xxs:bg-black-600 rounded-md xxs:p-2 w-full grid grid-cols-3 xxs:gap-1">
            <></>
          </div>
        </div>
      </div>
    );
  }

  const StatCard = ({ stat }: { stat: StatsDataItem }) => {
    return (
      <StatsCard key={stat.key} className="xxs:rounded-md ">
        <StatsCardValue className="xxs:text-lg xxs:font-semibold">
          <div className="">{stat.value}</div>
        </StatsCardValue>
        <StatsCardTitle>
          <div className="flex space-x-1 items-center xxs:text-dex-white-secondary hover:text-dex-white">
            <Tooltip text={stat.tooltip}>
              <div>{stat.title}</div>
            </Tooltip>
          </div>
        </StatsCardTitle>
      </StatsCard>
    );
  };

  return (
    <div className="xxs:p-2 overflow-y-scroll mt-1 mb-0 ">
      <div className="w-full lg:w-full h-full xxs:space-y-2 lg:space-y-1">
        <div className=" lg:grid lg:grid-cols-4 xxs:space-y-2 lg:space-y-0 lg:space-x-1">
          {mainStatsData.map((stat, index) => (
            <StatCard stat={stat} key={index} />
          ))}
        </div>

        <div className="grid grid-cols-6 space-x-1 items-center lg:max-w-[50%] lg:pt-2">
          {PERIOD_FILTERS.map((p, index) => {
            return (
              <Button
                type="button"
                className={twMerge(
                  'xxs:p-1 rounded',
                  period === p.value
                    ? 'bg-dex-black-600'
                    : 'bg-dex-black-700 hover:bg-dex-black-600 xxs:text-dex-white-secondary hover:text-dex-white'
                )}
                key={index}
                onClick={() => setPeriod(p.value)}
              >
                <div className="xxs:text-xs">{p.label}</div>
              </Button>
            );
          })}
        </div>
        <div className=" lg:grid lg:grid-cols-4 xxs:space-y-2 lg:space-y-0 lg:space-x-1">
          {statsData.map((stat, index) => (
            <StatCard stat={stat} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
