import moment, { duration } from 'moment';

export const formatDuration = (
  date: string,
  options: FormatDurationOptions = {}
) => {
  const finish = options.finishDate
    ? moment(options.finishDate) // Use provided finishDate
    : moment(); // Default to current time

  const dur = duration(finish.diff(moment(date))); // Calculate the duration

  return formatMomentDuration(dur, options);
};

type FormatDurationOptions = {
  startWith?: 'm' | 's' | 'd';
  finishDate?: string;
  maxBlocks?: number;
};

export function formatMomentDuration(
  dur: moment.Duration,
  { startWith = 's', maxBlocks = 3 }: FormatDurationOptions = {}
) {
  const years = dur.years();
  const months = dur.months();
  const days = dur.days();
  const hours = dur.hours();
  const minutes = dur.minutes();
  const seconds = dur.seconds();

  const differenceString = [];

  if (years > 0 && differenceString.length < maxBlocks) {
    startWith = 'm';
    differenceString.push(`${Math.abs(years)}Y`);
  }

  if (months > 0 && differenceString.length < maxBlocks) {
    startWith = 'm';
    differenceString.push(`${Math.abs(months)}M`);
  }

  if (days > 0 && differenceString.length < maxBlocks) {
    startWith = 'm';
    differenceString.push(`${Math.abs(days)}d`);
  }

  if (hours > 0 && differenceString.length < maxBlocks) {
    if (hours > 12) {
      startWith = 'd';
    }
    differenceString.push(`${Math.abs(hours)}h`);
  }

  if (
    minutes > 0 &&
    (startWith === 'm' || startWith === 's') &&
    differenceString.length < maxBlocks
  ) {
    if (minutes > 10) {
      startWith = 'm';
    }
    differenceString.push(`${Math.abs(minutes)}m`);
  }

  if (seconds > 0 && startWith === 's' && differenceString.length < maxBlocks) {
    differenceString.push(`${Math.abs(seconds)}s`);
  } else if (differenceString.length < maxBlocks && minutes === 0) {
    differenceString.push(`0s`);
  }

  return differenceString.join(' ');
}
