import ErrorBoundary from 'components/error-boundaries';
import { ComponentProps, FC, forwardRef, TdHTMLAttributes } from 'react';
import Header from './header';
import { TableColumn } from './types';
import { twMerge } from 'tailwind-merge';

type ITable = FC<React.ComponentProps<'table'>> & {
  Header: FC<ComponentProps<typeof Header>>;
  Td: FC<TdHTMLAttributes<HTMLTableCellElement>>;
  LoadingRow: FC<ComponentProps<typeof LoadingRow>>;
  RowGroup: FC<ComponentProps<typeof RowGroup>>;
};

const Table: ITable = (props) => (
  <ErrorBoundary>
    <div className="overflow-auto custom-scrollbar w-full ring-1 ring-black ring-opacity-5 md:rounded-md">
      <table
        {...props}
        className={twMerge(
          'min-w-full [&_td:first-of-type:not(.details)]:pl-6 [&_th:first-of-type]:pl-6',
          props.className
        )}
      />
    </div>
  </ErrorBoundary>
);

const Td: FC<React.ComponentProps<'td'>> = ({
  children,
  className,
  ...rest
}) => (
  <td
    {...rest}
    className={twMerge(
      'whitespace-nowrap xxs:py-2 md:py-2 xxs:px-2 text-sm font-medium xxs:text-gray-900 dark:text-white',
      className
    )}
  >
    {children}
  </td>
);
type LoadingRowProps = {
  columns: TableColumn<any>[];
  className?: string;
  cellClassName?: string;
  elementClassName?: string;
  thDynamicClass?: (arg0: string) => string;
};

const LoadingRow = forwardRef<HTMLTableRowElement, LoadingRowProps>(
  (
    { columns, className, cellClassName, thDynamicClass, elementClassName },
    ref
  ) => (
    <tr ref={ref || undefined} className={className}>
      {columns.map((col, key) => (
        <td
          key={key}
          className={twMerge(
            'whitespace-nowrap border-tailwind xxs:border-dex-black-700 border-bottom-new xxs:border-solid xxs:py-6 text-sm font-medium animate-pulse xxs:px-3',
            cellClassName,
            col.className,
            thDynamicClass ? thDynamicClass(col.key) : ''
          )}
        >
          <div
            className={twMerge(
              'xxs:h-2 my-1 rounded-full xxs:dark:bg-black-500 w-full',
              elementClassName
            )}
            style={{ minWidth: 12, maxWidth: '64px' }}
          >
            &nbsp;
          </div>
        </td>
      ))}
    </tr>
  )
);

interface BodyProps<T> {
  data?: T[];
  columns: TableColumn<T>[];
  className?: string;
  cellClassName?: string;
  elementClassName?: string;
}

export function Body<T extends { id: string }>({
  data,
  columns,
  className,
  cellClassName,
  elementClassName,
}: BodyProps<T>) {
  if (!data)
    return (
      <>
        {[...Array(10).keys()]
          .map((i) => i + 1)
          .map((_, index) => (
            <LoadingRow
              key={index}
              columns={columns}
              className={className}
              cellClassName={cellClassName}
              elementClassName={elementClassName}
            />
          ))}
      </>
    );
  return (
    <>
      {data.map((row, index) => (
        <tr
          key={row.id}
          className={twMerge(
            index === 0
              ? 'xxs:border-gray-300 dark:border-black-900'
              : 'xxs:border-gray-200 dark:border-black-800',
            'border-t dark:bg-black-700',
            className
          )}
        >
          {columns.map((column) => {
            const Column = column.component;
            if (!Column) return;
            return (
              <Table.Td
                className={twMerge(cellClassName, column.className)}
                key={column.key}
              >
                <Column data={row} />
              </Table.Td>
            );
          })}
        </tr>
      ))}
    </>
  );
}

const RowGroup: FC<{
  colSpan: React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >['colSpan'];
  className?: string;
}> = ({ children, colSpan, className }) => (
  <tr className="border-t border-black-900">
    <th
      colSpan={colSpan}
      scope="colgroup"
      className={twMerge(
        'bg-black-600 xxs:px-2 xxs:py-2 text-left text-sm font-semibold text-gray-900 dark:text-white sm:px-2',
        className
      )}
    >
      {children}
    </th>
  </tr>
);

Table.Header = Header;
Table.Td = Td;
Table.LoadingRow = LoadingRow;
Table.RowGroup = RowGroup;

export default Table;
