import { IconButton } from 'modules/shared-components/button/IconButton';
import { formatAddress } from '../utils';
import {
  ChartBarSquareIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { CopyContent } from 'modules/shared-components/tooltip/copy-content';
import { twMerge } from 'tailwind-merge';
import { Popup } from 'modules/shared-components/popup';
import { Link, useHistory } from 'react-router-dom';
import { Chains, chainScan, chainScanImg } from 'api/d-wallets';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import Button from 'modules/shared-components/button/button';
import { Tabs } from '../../../app/dex/components/layout/SIDEBAR_CONST';

export function EthAddress({
  address,
  kind,
  precision = 2,
  className,
  classNameColor,
  chain,
}: {
  address: string;
  kind?: 'token' | 'pool' | 'account' | 'tx';
  precision?: number;
  className?: string;
  classNameColor?: string;
  chain?: Chains;
}) {
  const chainType = chain ?? Chains.Ethereum;
  const isDesktop = useMediaQuery(screenGte.small);
  const history = useHistory();
  const scanUrl =
    kind === 'tx'
      ? `https://${chainScan(chainType)}/tx/${address}`
      : `https://${chainScan(chainType)}/address/${address}`;

  const color =
    classNameColor ?? 'xxs:text-dex-white-secondary hover:text-dex-white';

  if (!isDesktop) {
    return (
      <CopyContent text={address}>
        <button
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={twMerge('xxs:m-0 xxs:p-0', color, className)}
        >
          {formatAddress(address, precision)}
        </button>
      </CopyContent>
    );
  }

  return (
    <span
      className={className}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popup
        placement="top-start"
        trigger={
          kind === 'token' ? (
            <Link
              to={
                !isDesktop
                  ? `/dex/research/${chain}/${address}`
                  : `/dex/snipe/new/${chain}/${address}`
              }
              className={color}
            >
              {formatAddress(address, precision)}
            </Link>
          ) : (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={scanUrl}
              className={color}
            >
              {formatAddress(address, precision)}
            </a>
          )
        }
      >
        <div className="xxs:px-2 xxs:py-1 rounded dark:text-white-50 break-words xxs:text-xs items-center xxs:gap-1">
          <span>{address}</span>
          <div className="flex items-center gap-1">
            <CopyContent text={address}>
              <IconButton
                as="button"
                className={twMerge(
                  'xxs:p-1 bg-white-400 hover:bg-white-600 dark:hover:bg-black-600 dark:bg-black-700 xxs:rounded'
                )}
              >
                <DocumentDuplicateIcon className="xxs:text-white-900 dark:text-white-300 w-[20px] h-[20px]" />
              </IconButton>
            </CopyContent>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href={scanUrl}
              className="xxs:p-1 bg-white-400 hover:bg-white-600 dark:hover:bg-black-600 dark:bg-black-700 xxs:rounded"
            >
              <img
                src={chainScanImg(chain)}
                alt="scan img"
                className="w-[20px] h-[20px] "
              />
            </a>
            {kind === 'account' && (
              <Button
                to={{
                  pathname: `/dex/research/${Tabs.Wallets}/${chain}/${address}/details`,
                  state: {
                    from: `${history.location.pathname}${history.location.search}`,
                  },
                }}
                className="xxs:p-1 bg-white-400 hover:bg-white-600 dark:hover:bg-black-600 dark:bg-black-700 xxs:rounded"
                as={Link}
              >
                <ChartBarSquareIcon className="w-5 h-5 text-white" />
              </Button>
            )}
          </div>
        </div>
      </Popup>
    </span>
  );
}

export function CopyAddressButton({
  address,
  ...props
}: { address: string } & React.ComponentProps<typeof IconButton>) {
  return (
    <CopyContent text={address}>
      <IconButton
        {...props}
        as="button"
        className={twMerge('w-4 h-4', props.className)}
      >
        <DocumentDuplicateIcon className="xxs:text-white-900 dark:text-white-600 w-full" />
      </IconButton>
    </CopyContent>
  );
}
