import { ContractInfo } from 'api/contracts';
import { DexChart } from '../../../../components/Chart/DexChart';
import {
  MobileExpandCardTrigger,
  MobileExpandableCard,
} from 'modules/shared-components/MobileExpandableCard';

type Props = { contract: ContractInfo };

export function Chart({ contract }: Props) {
  const selectedPool = contract.liquidityInfo.activePools[0];
  return (
    <MobileExpandableCard
      mobileLabel="Chart"
      trigger={
        <MobileExpandCardTrigger>
          <div className="flex xxs:gap-1 items-center xxs:font-semibold">
            Chart
          </div>
        </MobileExpandCardTrigger>
      }
    >
      <div className="xxs:h-[450px]">
        <DexChart
          chartHeight={450}
          contract={contract}
          poolAddress={selectedPool.poolAddress}
        />
      </div>
    </MobileExpandableCard>
  );
}
