import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ManualSwapParams, OpenPositionListItem } from 'api/dex-trade';
import {
  blockchainInfoKeys,
  BlockchainInfoService,
} from 'api/services/BlockchainInfoService';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/DWalletService';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import Big from 'big.js';
import {
  GasEstimation,
  useCalculateGasCostInEther,
} from 'modules/ethereum/EstimatedGasFee';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { Button } from 'modules/shared-components/button/button';
import { duration } from 'moment';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  formatNumberWithSuffix,
  formatTokenAmount,
  formatUSDAmount,
} from 'utils/FormatNumber';
import { parseGwei } from 'viem';
import * as yup from 'yup';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import { calculateLimits } from '../components/TokenTradeInfoHeader';
import { WarningLowBalanceMessage } from 'modules/shared-components/notification/walletLowBalance';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import {
  chainAsset,
  ChainAssets,
  chainHasMemPool,
  Chains,
  chainScan,
  minimumSafeAmount,
} from 'api/d-wallets';
import { useRefreshIntervalPersistedState } from '../side-panels/SnipesPanel';
import { NotificationDex } from '../components/alerts/notification';
import NumberInput from '../components/inputs/number-input';
import Checkbox from '../components/inputs/Checkbox';
import CustomModal from '../components/modal/CustomModal';

interface CancelPositionsProps {
  onCancel: () => void;
  position: OpenPositionListItem;
}

const gasFeeSchema = yup
  .number()
  .required('Required')
  .min(0.01, 'Must be above 0.01 gwei')
  .typeError('Must be above 0.01 gwei');
const percentageSchema = yup
  .number()
  .min(1, 'Must be above 1%')
  .max(100, 'Must be below 100%.')
  .required('Required')
  .typeError('Must be above 1%');

const schema = yup.object({
  maxSlippage: yup
    .number()
    .max(100, 'Must be below 100%')
    .required('Required')
    .typeError('Must be above 0%'),
  maxPriorityFeePerGasWei: gasFeeSchema,
  percentage: percentageSchema,
  antiMev: yup.boolean().required('Required'),
});

type FormValues = yup.InferType<typeof schema>;

export const ClosePosition: FC<CancelPositionsProps> = ({
  onCancel,
  position,
}) => {
  const { addNewMessage } = useSnackBar();
  const queryClient = useQueryClient();
  const [, setRefetchInterval] = useRefreshIntervalPersistedState();
  const {
    data: contractData,
    isLoading: contractDataLoading,
    isError: contractDataError,
  } = useQuery({
    queryFn: () =>
      ContractService.getContractInfo(position.chain, position.tokenAddress),
    queryKey: contractServiceKeys.getContractInfo(
      position.chain,
      position.tokenAddress
    ),
  });

  const { data: wallet, isLoading: isLoadingWallets } = useQuery({
    queryFn: () => DWalletService.getWalletWithoutTokens(position.vaultId),
    queryKey: dWalletServiceKeys.getWalletWithoutTokens(position.vaultId),
    staleTime: duration('15', 'seconds').asMilliseconds(),
    cacheTime: duration('15', 'seconds').asMilliseconds(),
  });

  const { data: tokenBalance } = useQuery({
    queryFn: () =>
      DWalletService.getTokenBalance(position.vaultId, position.tokenAddress),
    queryKey: dWalletServiceKeys.getTokenBalance(
      position.vaultId,
      position.tokenAddress
    ),
  });

  const { data: prices, isLoading: isLoadingPrices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    staleTime: duration('15', 'seconds').asMilliseconds(),
    cacheTime: duration('15', 'seconds').asMilliseconds(),
  });

  const { data: gasInfo, isLoading: isLoadingGasInfo } = useQuery({
    queryKey: blockchainInfoKeys.getGasInfoWithChain(position.chain),
    queryFn: () => BlockchainInfoService.getGasInfoWithChain(position.chain),
    staleTime: duration('15', 'seconds').asMilliseconds(),
    cacheTime: duration('15', 'seconds').asMilliseconds(),
  });

  const { handleSubmit, control, watch } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      maxPriorityFeePerGasWei: position.chain === Chains.Ethereum ? 1 : 0.01,
      maxSlippage: 5,
      percentage: 100,
      antiMev: chainHasMemPool(position.chain),
    },
  });

  const {
    mutate,
    isLoading,
    error: mutationError,
  } = useMutation({
    mutationFn: (values: ManualSwapParams) =>
      DexTradeService.manualSwap(position.id, values),

    onSuccess: () => {
      addNewMessage({
        type: 'success',
        title: 'Position Sell Requested',
        message: 'Position sell request has been accepted and in progress.',
      });
      setRefetchInterval(new Date().toISOString());
      void queryClient.invalidateQueries(dexTradeKeys.all());
      onCancel();
    },
  });

  const onSubmit = handleSubmit((values) => {
    mutate({
      amount: Big(values.percentage ?? 0)
        .times(position.totalAmountTokenRemainingInWei!)
        .div(100)
        .toFixed(0),
      maxPriorityFeePerGasWei: Number(
        parseGwei(values.maxPriorityFeePerGasWei.toString())
      ),
      type: 'market',
      side: 'SELL',
      antiMev: chainHasMemPool(position.chain) ? values.antiMev : false,
      maxSlippage: Big(values.maxSlippage)
        .div(100)
        .round(10, Big.roundDown)
        .toNumber(),
    });
  });

  const priorityFeeValue = watch('maxPriorityFeePerGasWei', 0);
  const gasPrice = gasFeeSchema.isValidSync(priorityFeeValue)
    ? gasFeeSchema.cast(priorityFeeValue)
    : 0;

  const gasCostInEther = useCalculateGasCostInEther(gasInfo, gasPrice ?? 0);
  const isInsufficientGas =
    wallet &&
    gasInfo &&
    prices &&
    Big(wallet.chainBalance ?? 0).lt(gasCostInEther);

  const percentage = watch('percentage') ?? 0;

  return (
    <CustomModal title="Sell Tokens" showModal handleClose={onCancel}>
      <form
        className="contents"
        onSubmit={(e) => {
          void onSubmit(e);
        }}
      >
        <CustomModal.Body>
          <CustomModal.Title className="hidden lg:flex">
            {' '}
            Sell Tokens
          </CustomModal.Title>
          <CustomModal.Content>
            <div className="flex flex-col xxs:gap-4">
              <Controller
                name="percentage"
                control={control}
                defaultValue={'' as any}
                render={({ field, fieldState: { error } }) => (
                  <NumberInput
                    suffix={<>%</>}
                    label="Percentage of current holdings"
                    autoComplete="off"
                    {...field}
                    error={error?.message}
                    extraLabel={
                      <>
                        Current:{' '}
                        {formatNumberWithSuffix(
                          position.totalAmountTokenRemaining!
                        )}{' '}
                        {position.tokenSymbol}
                      </>
                    }
                    description={
                      percentageSchema.isValidSync(field.value)
                        ? `${formatNumberWithSuffix(
                            Big(field.value)
                              .times(position.totalAmountTokenRemaining!)
                              .div(100)
                          )} ${position.tokenSymbol}`
                        : ''
                    }
                  />
                )}
              />

              <Controller
                name="maxSlippage"
                control={control}
                defaultValue={'' as any}
                render={({ field, fieldState: { error } }) => (
                  <NumberInput
                    suffix={<>%</>}
                    label="Max slippage"
                    autoComplete="off"
                    {...field}
                    error={error?.message}
                    onChange={(event) =>
                      field.onChange(event.currentTarget.value)
                    }
                  />
                )}
              />

              <Controller
                name="maxPriorityFeePerGasWei"
                control={control}
                defaultValue={'' as any}
                render={({ field, fieldState: { error } }) => (
                  <NumberInput
                    suffix={<>gwei</>}
                    label="Max Priority Fee"
                    autoComplete="off"
                    {...field}
                    error={error?.message}
                    onChange={(event) =>
                      field.onChange(event.currentTarget.value)
                    }
                  />
                )}
              />

              {chainHasMemPool(position.chain) && (
                <Controller
                  name="antiMev"
                  control={control}
                  defaultValue={'' as any}
                  render={({ field }) => (
                    <div className="flex items-center justify-between ">
                      <p className="mb-0">MEV Protection</p>
                      <div className="flex space-x-2 items-center">
                        <Checkbox
                          checked={field.value as boolean}
                          onChange={field.onChange}
                          id="antiMev"
                        />
                        <Checkbox.Label htmlFor="anti-mev">
                          Enabled
                        </Checkbox.Label>
                      </div>
                    </div>
                  )}
                />
              )}
              {!contractDataError && !contractDataLoading && (
                <div className="flex space-x-4">
                  <div>
                    Sell Tax:{' '}
                    {contractData.taxSell ? (
                      <span>
                        {Big(contractData.taxSell).mul(100).toFixed(1)}%
                      </span>
                    ) : (
                      '--'
                    )}
                  </div>
                  <div>
                    Max Sell:{' '}
                    {contractData.maxSell ? (
                      <span>
                        {calculateLimits({
                          totalSupply: Big(
                            contractData.totalSupply ?? 0
                          ).toNumber(),
                          max: Big(contractData.maxSell ?? 0).toNumber(),
                        })}
                      </span>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              )}
              {!contractDataError &&
              !contractDataLoading &&
              contractData.maxSell &&
              percentageSchema.isValidSync(percentage) &&
              Big(contractData.maxSell).lt(
                Big(percentage ?? 0)
                  .times(position.totalAmountTokenRemaining! ?? 0)
                  .div(100)
                  .toNumber()
              ) ? (
                <NotificationDex type="warning" className="xxs:mx-0">
                  Warning! Due to contract sell limits, you can sell only{' '}
                  {formatNumberWithSuffix(contractData.maxSell)}{' '}
                  {position.tokenName}. Your sell transaction might fail as
                  you're aiming to sell{' '}
                  {formatNumberWithSuffix(
                    Big(percentage ?? 0)
                      .times(position.totalAmountTokenRemaining!)
                      .div(100)
                  )}{' '}
                  {position.tokenName}.
                </NotificationDex>
              ) : (
                ''
              )}

              {gasPrice ? (
                <GasEstimation chain={position.chain} priorityFee={gasPrice} />
              ) : (
                ''
              )}
              {watch('maxSlippage') > 25 &&
                !watch('antiMev') &&
                chainHasMemPool(position.chain) && (
                  <div className="flex space-x-1 items-center">
                    <ExclamationCircleIcon className="xxs:text-red-500 w-4 h-4" />
                    <p className="text-red-500 mb-0">
                      High slippage, enable MEV protection to avoid bots.
                    </p>
                  </div>
                )}

              {tokenBalance &&
                Big(tokenBalance.balance ?? 0).lt(
                  Big(percentage ?? 0)
                    .times(position.totalAmountTokenRemaining ?? 0)
                    .div(100)
                    .toNumber()
                ) && (
                  <NotificationDex className="xxs:mx-0" type="error">
                    Warning! It seems like you don't have{' '}
                    {formatTokenAmount(
                      Big(percentage ?? 0)
                        .times(position.totalAmountTokenRemaining ?? 0)
                        .div(100)
                        .toNumber()
                    )}{' '}
                    {position.tokenSymbol}.{' '}
                    {wallet ? (
                      <span>
                        Verify your wallet balance{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://${chainScan(position.chain)}/address/${
                            wallet?.address
                          }`}
                          className="xxs:text-blue-500 hover:text-blue-600"
                        >
                          here
                        </a>
                        .
                      </span>
                    ) : (
                      ''
                    )}
                  </NotificationDex>
                )}

              {!contractDataError &&
              !contractDataLoading &&
              contractData.status === 'RUG_PULL' ? (
                <NotificationDex className="xxs:mx-0" type="error">
                  Warning! It looks like this token performed a rug-pull. The
                  sell transaction might fail and you could lose the gas fees.
                </NotificationDex>
              ) : (
                ''
              )}
              {isInsufficientGas && (
                <NotificationDex className="xxs:mx-0" type="error">
                  Your wallet balance{' '}
                  {formatNumberWithSuffix(wallet?.chainBalance)}{' '}
                  {chainAsset(position.chain)} ($
                  {formatUSDAmount(
                    Big(wallet?.chainBalance)
                      .times(
                        chainAsset(position.chain) === ChainAssets.EthereumBase
                          ? prices?.eth_price
                          : prices?.bnb_price
                      )
                      .toNumber()
                  )}
                  ) is not enough to cover the gas fee. Top up your wallet and
                  try again.
                </NotificationDex>
              )}
              {Big(wallet?.chainBalance ?? 0).lt(
                minimumSafeAmount(position.chain)
              ) ? (
                <NotificationDex className="xxs:mx-0" type="warning">
                  <WarningLowBalanceMessage chain={position.chain} />
                </NotificationDex>
              ) : (
                ''
              )}
            </div>
          </CustomModal.Content>
          {mutationError && (
            <NotificationDex
              type="error"
              errorMessage={mutationError}
              className="xxs:my-2 xxs:mx-0"
            >
              An error occurred.
            </NotificationDex>
          )}
        </CustomModal.Body>
        <CustomModal.Footer>
          <PrimaryButton
            className="xxs:text-base lg:text-xs"
            type="submit"
            loading={isLoading}
            disabled={
              isLoadingWallets ||
              isLoadingGasInfo ||
              isLoadingPrices ||
              isInsufficientGas
            }
          >
            Confirm
          </PrimaryButton>
          <Button
            className="xxs:text-base lg:text-xs"
            type="button"
            variant="dexNeutral"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
};
