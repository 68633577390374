import classNames from 'classnames';
import React, { useState } from 'react';

export function MizarHexagonImage({
  size,
  ...props
}: { size: number } & React.ComponentProps<'img'>) {
  // TODO: we should use a hexagon mask instead of this hack
  const width = (size * 15) / 17;
  const [imageError, setImageError] = useState(false); // Track image load state

  return (
    <div
      className="relative flex-shrink-0 after:content-['_'] after:inset-0 after:absolute after:clip-mizar-hexagon after:hexagon-border-black-50"
      style={{
        width,
        height: size,
        marginLeft: (size - width) / 2,
        marginRight: (size - width) / 2,
      }}
    >
      {!imageError ? (
        <img
          {...props}
          height={size - 2}
          width={width - 2}
          onError={() => setImageError(true)} // Set error state on failure
          className={classNames(
            props.className,
            'clip-mizar-hexagon absolute top-px left-px'
          )}
        />
      ) : (
        <div className="text-black-100 w-full h-full inset-0 m-auto flex justify-center items-center text-xl">
          ?
        </div>
      )}
    </div>
  );
}
