import { ContractInfo, PoolInfo } from 'api/contracts';
import {
  StatsCard,
  StatsCardTitle,
  StatsCardValue,
} from '../../research/components/StatsCard';
import Big, { BigSource } from 'big.js';
import { isNotNil } from 'modules/utils/isNotNil';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import { twMerge } from 'tailwind-merge';
import { isNil } from 'lodash/fp';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { calculateLimits } from '../../components/TokenTradeInfoHeader';
import { chainAsset } from 'api/d-wallets';

export function StatsCards({ contract }: { contract: ContractInfo }) {
  return (
    <div className="xxs:grid xxs:grid-cols-2 lg:grid-cols-6 xxs:gap-1">
      <Stats contract={contract} />
    </div>
  );
}

function formatPercentCompact(number: BigSource, dp: number = 2) {
  if (Big(number).mod(1).eq(0)) {
    return Big(number).toFixed(0);
  }

  return Big(number).toFixed(dp);
}

export function Stats({ contract }: { contract: ContractInfo }) {
  const primaryPool = contract.liquidityInfo.activePools[0] as
    | PoolInfo
    | undefined;
  const isLarge = useMediaQuery(screenGte.large);
  return (
    <>
      <StatsCard
        className={twMerge(
          'w-full',
          'xxs:bg-dex-black-800',
          isNil(contract.tradingInfo.buyTax) && 'xxs:hidden lg:flex'
        )}
      >
        <StatsCardValue
          className={
            Big(contract.tradingInfo.buyTax || 0).gt(0.1) ? 'text-red-500' : ''
          }
        >
          {isNotNil(contract.tradingInfo.buyTax) ? (
            <>
              {formatPercentCompact(
                Big(contract.tradingInfo.buyTax).times(100)
              )}
              %
            </>
          ) : (
            '--'
          )}
        </StatsCardValue>
        <StatsCardTitle>Buy Tax</StatsCardTitle>
      </StatsCard>
      <StatsCard
        className={twMerge(
          'w-full',
          'xxs:bg-dex-black-800',
          isNil(contract.tradingInfo.sellTax) && 'xxs:hidden lg:flex'
        )}
      >
        <StatsCardValue
          className={twMerge(
            Big(contract.tradingInfo.sellTax || 0).gt(0.1) ? 'text-red-500' : ''
          )}
        >
          {isNotNil(contract.tradingInfo.sellTax) ? (
            <>
              {formatPercentCompact(
                Big(contract.tradingInfo.sellTax).times(100)
              )}
              %
            </>
          ) : (
            '--'
          )}
        </StatsCardValue>
        <StatsCardTitle>Sell Tax</StatsCardTitle>
      </StatsCard>
      <StatsCard
        className={twMerge(
          'w-full',
          'xxs:bg-dex-black-800',
          isNil(contract.tradingInfo.maxBuy) && 'xxs:hidden lg:flex'
        )}
      >
        <StatsCardValue
          className={
            contract.generalInfo.totalSupply &&
            contract.tradingInfo.maxBuy &&
            Big(contract.tradingInfo.maxBuy)
              .div(contract.generalInfo.totalSupply)
              .lte(0.1)
              ? 'text-red-500'
              : ''
          }
        >
          {calculateLimits({
            totalSupply: contract.generalInfo.totalSupply,
            max: contract.tradingInfo.maxBuy,
          })}{' '}
        </StatsCardValue>
        <StatsCardTitle>Max buy</StatsCardTitle>
      </StatsCard>
      <StatsCard
        className={twMerge(
          'w-full',
          'xxs:bg-dex-black-800',
          isNil(contract.tradingInfo.maxSell) && 'xxs:hidden lg:flex'
        )}
      >
        <StatsCardValue
          className={
            contract.generalInfo.totalSupply &&
            contract.tradingInfo.maxSell &&
            Big(contract.tradingInfo.maxSell)
              .div(contract.generalInfo.totalSupply)
              .lte(0.1)
              ? 'text-red-500'
              : ''
          }
        >
          {calculateLimits({
            totalSupply: contract.generalInfo.totalSupply,
            max: contract.tradingInfo.maxSell,
          })}{' '}
        </StatsCardValue>
        <StatsCardTitle>Max sell</StatsCardTitle>
      </StatsCard>

      <StatsCard
        className={twMerge(
          'w-full',
          !isLarge && 'xxs:hidden ',
          'xxs:bg-dex-black-800',
          isNil(primaryPool?.tokenQuoteReserves) &&
            isLarge &&
            'xxs:hidden lg:flex'
        )}
      >
        <StatsCardValue>
          {primaryPool?.tokenQuoteReserves ? (
            <>
              {formatNumberWithSuffix(primaryPool.tokenQuoteReserves, {
                precision: 0,
                suffixes: allSuffixes,
              })}{' '}
              {chainAsset(contract.generalInfo.chain.key)}
            </>
          ) : (
            '--'
          )}
        </StatsCardValue>
        <StatsCardTitle>Pool</StatsCardTitle>
      </StatsCard>
    </>
  );
}
