import { Button } from 'modules/shared-components/button/button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatTokenAmount } from 'utils/FormatNumber';
import { parseEther, parseGwei } from 'viem';
import Big from 'big.js';
import { useHistory } from 'react-router-dom';
import { ReactNode } from 'react';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrow-right.svg';
import { twMerge } from 'tailwind-merge';
import { percentageToTimesIncrease } from 'modules/utils/number';
import { percentToRatio } from 'utils/number';
import { dexBotKeys, DexBotService } from 'api/services/DexBotService';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { VolatilityBotInput } from 'api/dex-bot';
import { isNotNil } from 'modules/utils/isNotNil';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import config from '../../../../config';
import { TgConfirmationDialog } from 'modules/telegram/ConfirmationDailog';
import { chainAsset, chainHasMemPool, chainName, Chains } from 'api/d-wallets';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { Divider } from '../../trade/TradeConfirmationModal';
import { TradingSettingsSchema, VolatilityBotFormValues } from './schema';
import { LimitOrderOperator, LimitOrderSide } from 'api/dex-trade';
import { NotificationDex } from '../../components/alerts/notification';
import CustomModal from '../../components/modal/CustomModal';

interface Props {
  bot: VolatilityBotFormValues;
  handleClose: () => void;
}

export const DataRow = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div className="flex justify-between items-center xxs:gap-5">
    <div className="xxs:mb-0 xxs:text-dex-white-secondary xxs:text-sm">
      {label}
    </div>
    <div className="dark:text-dex-white xxs:text-sm truncate">{children}</div>
  </div>
);

export const BotConfirmationModal = ({ bot, handleClose }: Props) => {
  const { addNewMessage } = useSnackBar();
  const queryClient = useQueryClient();

  const history = useHistory();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: () =>
      DexBotService.createVolatilityBot(formValuesToDexBot(bot)),
    onSuccess: () => {
      void queryClient.invalidateQueries(dexBotKeys.all());
      addNewMessage({
        title: 'Success',
        message: 'Bot created successfully',
        type: 'success',
      });
      history.push('/dex/bots?activeTab=volatility-bot');
    },
  });

  if (config.isTelegramBrowser) {
    return (
      <TgConfirmationDialog onClose={handleClose} onConfirmed={() => mutate()}>
        Do you want to create this bot?
      </TgConfirmationDialog>
    );
  }

  return (
    <CustomModal
      title="Confirm New Bot"
      size="large"
      showModal
      handleClose={handleClose}
    >
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Confirm New Bot
        </CustomModal.Title>
        <CustomModal.Content>
          <BotSummary bot={bot} />
          <NotificationDex type="info" className="xxs:my-2">
            <span>
              By confirming, you will automatically trade all the white-listed
              tokens addresses when the price targets are hit.
            </span>
          </NotificationDex>
          {error && (
            <NotificationDex type="error" errorMessage={error}>
              An error occurred.
            </NotificationDex>
          )}
        </CustomModal.Content>
      </CustomModal.Body>

      <CustomModal.Footer>
        <PrimaryButton
          type="button"
          className="xxs:text-base lg:text-xs"
          loading={isLoading}
          onClick={() => mutate()}
        >
          save
        </PrimaryButton>
        <Button
          className="xxs:text-base lg:text-xs"
          type="button"
          variant="dexNeutral"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

function buyAmountWallet(
  newTradingSettings: TradingSettingsSchema,
  previousTradingSettings?: TradingSettingsSchema
) {
  return (
    <>
      <DataRow label="Gas Priority">
        <PositionChange
          before={
            previousTradingSettings &&
            !Big(newTradingSettings.maxBuyGasPriority).eq(
              previousTradingSettings.maxBuyGasPriority
            ) && <>{previousTradingSettings.maxBuyGasPriority} gwei</>
          }
          after={<>{newTradingSettings.maxBuyGasPriority} gwei</>}
        />
      </DataRow>
      <DataRow label="Slippage">
        <PositionChange
          before={
            previousTradingSettings &&
            !Big(newTradingSettings.slippage).eq(
              previousTradingSettings.slippage
            ) && <>{previousTradingSettings.slippage}%</>
          }
          after={<>{newTradingSettings.slippage}%</>}
        />
      </DataRow>
      {chainHasMemPool(newTradingSettings.chain) && (
        <DataRow label="MEV Protection">
          <PositionChange
            before={
              previousTradingSettings &&
              !(
                newTradingSettings.antiMev === previousTradingSettings.antiMev
              ) && (
                <>{previousTradingSettings.antiMev ? 'Enabled' : 'Disabled'}</>
              )
            }
            after={<>{newTradingSettings.antiMev ? 'Enabled' : 'Disabled'}</>}
          />
        </DataRow>
      )}
    </>
  );
}

export function BotSummary({
  bot,
  previous,
}: {
  bot: VolatilityBotFormValues;
  previous?: VolatilityBotFormValues;
}) {
  return (
    <div className="flex flex-col xxs:gap-2">
      <p className="xxs:text-sm xxs:font-semibold dark:text-dex-white-secondary xxs:mb-0">
        Main Settings
      </p>

      <DataRow label="Chain">
        <PositionChange
          before={
            previous &&
            previous.tradingSettings?.chain &&
            previous.tradingSettings?.chain !== bot.tradingSettings?.chain && (
              <>
                <div className="flex space-x-1 items-center">
                  <ChainsIcon
                    imgName={previous.tradingSettings?.chain as Chains}
                  />
                  <div>
                    {chainName(previous.tradingSettings?.chain as Chains)}
                  </div>
                </div>
              </>
            )
          }
          after={
            <>
              {bot.tradingSettings?.chain && (
                <>
                  <div className="flex space-x-1 items-center">
                    {bot.tradingSettings?.chain && (
                      <ChainsIcon
                        imgName={bot.tradingSettings?.chain as Chains}
                      />
                    )}
                    <div>{chainName(bot.tradingSettings?.chain as Chains)}</div>
                  </div>
                </>
              )}
            </>
          }
        />
      </DataRow>

      <Divider />
      <p className="xxs:text-sm xxs:font-semibold dark:text-dex-white-secondary  xxs:mb-0">
        Entry Settings
      </p>

      <DataRow label="Price Change">
        <PositionChange
          before={
            previous?.botSettings.entries.priceChange &&
            !Big(bot.botSettings.entries.priceChange ?? 0).eq(
              previous.botSettings.entries.priceChange ?? 0
            ) && (
              <>
                -{Big(previous.botSettings.entries.priceChange ?? 0).toFixed(2)}
                %
              </>
            )
          }
          after={
            <>-{Big(bot.botSettings.entries.priceChange ?? 0).toFixed(2)}%</>
          }
        />
      </DataRow>
      <DataRow label="Price Change Period">
        <PositionChange
          before={
            previous?.botSettings.entries.periodInMinutes &&
            !Big(bot.botSettings.entries.periodInMinutes).eq(
              previous.botSettings.entries.periodInMinutes ?? 0
            ) && (
              <>
                {Big(previous.botSettings.entries.periodInMinutes ?? 0).toFixed(
                  0
                )}
                min
              </>
            )
          }
          after={
            <>
              {Big(bot.botSettings.entries.periodInMinutes ?? 0).toFixed(0)}min
            </>
          }
        />
      </DataRow>

      {!bot.botSettings.entries.isDcaActive ? (
        <DataRow label="Dollar Cost Averaging (DCA)">Disabled</DataRow>
      ) : (
        ''
      )}

      {bot.botSettings.entries.isDcaActive &&
        bot.botSettings.entries.limitOrders?.map((order, index: number) => {
          const previousOrder = previous?.botSettings.entries.limitOrders?.find(
            (x) => x.id === order.id
          );

          return (
            <DataRow
              label={index === 0 ? `Dollar Cost Averaging (DCA)` : ''}
              key={index}
            >
              <PositionChange
                before={
                  previous && !previousOrder ? (
                    <>--</>
                  ) : (
                    previousOrder &&
                    !(
                      Big(order.priceDeviationPercentage).eq(
                        previousOrder.priceDeviationPercentage
                      ) && Big(order.amount).eq(previousOrder.amount)
                    ) && (
                      <>
                        -{previousOrder.priceDeviationPercentage}% |{' '}
                        {previousOrder.amount}{' '}
                        {chainAsset(bot.tradingSettings.chain)}
                      </>
                    )
                  )
                }
                after={
                  <>
                    -{order.priceDeviationPercentage}% | {order.amount}{' '}
                    {chainAsset(bot.tradingSettings.chain)}
                  </>
                }
              />
            </DataRow>
          );
        })}

      {previous?.botSettings.entries.limitOrders
        ?.filter(
          (x) =>
            !bot.botSettings.entries.limitOrders?.find(
              (curr) => curr.id === x.id
            )
        )
        .map((order, index: number) => {
          return (
            <DataRow label={index === 0 ? `Removed DCA order` : ''} key={index}>
              <span className="xxs:text-yellow-500 line-through">
                -{order.priceDeviationPercentage}% | {order.amount}{' '}
                {chainAsset(bot.tradingSettings.chain)}
              </span>
            </DataRow>
          );
        })}

      <DataRow label="N. of tokens whitelisted">
        <PositionChange
          before={
            previous?.botSettings.entries.addresses?.length &&
            !Big(bot.botSettings.entries.addresses?.length ?? 0).eq(
              previous.botSettings.entries.addresses?.length ?? 0
            ) && (
              <>
                {Big(
                  previous.botSettings.entries.addresses?.length ?? 0
                ).toFixed(0)}{' '}
                token(s)
              </>
            )
          }
          after={
            <>
              {Big(bot.botSettings.entries.addresses?.length ?? 0).toFixed(0)}{' '}
              token(s)
            </>
          }
        />
      </DataRow>
      {bot.botSettings.entries.autoRetryEnabled ? (
        <DataRow label="Auto-retry">Enabled</DataRow>
      ) : (
        ''
      )}
      <Divider />
      <p className="xxs:text-sm xxs:font-semibold dark:text-dex-white-secondary xxs:mb-0">
        Buy Settings
      </p>
      <DataRow label="Buy amount">
        <PositionChange
          before={
            previous?.tradingSettings &&
            !Big(bot.tradingSettings.buyAmount).eq(
              previous.tradingSettings.buyAmount
            ) && (
              <>
                {formatTokenAmount(previous.tradingSettings.buyAmount)}{' '}
                {chainAsset(previous.tradingSettings.chain)}
              </>
            )
          }
          after={
            <>
              {' '}
              {formatTokenAmount(bot.tradingSettings.buyAmount)}{' '}
              {chainAsset(bot.tradingSettings.chain)}
            </>
          }
        />
      </DataRow>
      {buyAmountWallet(bot.tradingSettings, previous?.tradingSettings)}

      <Divider />

      <p className="xxs:text-sm xxs:font-semibold dark:text-dex-white-secondary  xxs:mb-0">
        Sell Settings
      </p>

      {!bot.tradingSettings.takeProfit?.length ? (
        <DataRow label="Take Profit">Disabled</DataRow>
      ) : (
        ''
      )}

      {bot.tradingSettings.takeProfit?.map((takeProfit, index: number) => {
        const previousTp = previous?.tradingSettings.takeProfit?.find(
          (x) => x.id === takeProfit.id
        );

        return (
          <DataRow label={index === 0 ? `Take Profit` : ''} key={index}>
            <PositionChange
              before={
                previous && !previousTp ? (
                  <>--</>
                ) : (
                  previousTp &&
                  !(
                    Big(takeProfit.threshold).eq(previousTp.threshold) &&
                    Big(takeProfit.weight).eq(previousTp.weight)
                  ) && (
                    <>
                      +{percentageToTimesIncrease(previousTp.threshold)}X (
                      {previousTp.weight}%)
                    </>
                  )
                )
              }
              after={
                <>
                  +{percentageToTimesIncrease(takeProfit.threshold)}X (
                  {takeProfit.weight}%)
                </>
              }
            />
          </DataRow>
        );
      })}

      {previous?.tradingSettings.takeProfit
        .filter(
          (x) =>
            !bot.tradingSettings.takeProfit.find((curr) => curr.id === x.id)
        )
        .map((takeProfit, index: number) => {
          return (
            <DataRow label={index === 0 ? `Removed TP` : ''} key={index}>
              <span className="xxs:text-yellow-500 line-through">
                +{percentageToTimesIncrease(takeProfit.threshold)}X (
                {takeProfit.weight}%)
              </span>
            </DataRow>
          );
        })}

      {!bot.tradingSettings.stopLoss ? (
        <DataRow label="Stop Loss">Disabled</DataRow>
      ) : (
        ''
      )}
      {(!!bot.tradingSettings.stopLoss ||
        !!previous?.tradingSettings.stopLoss) && (
        <>
          <p className="xxs:text-sm xxs:text-dex-white-secondary xxs:mb-0">
            Stop loss
          </p>

          <DataRow label="Stop Loss Threshold">
            <PositionChange
              before={
                previous &&
                !Big(previous.tradingSettings.stopLoss?.threshold || 1).eq(
                  bot.tradingSettings.stopLoss?.threshold || 1
                ) &&
                (isNotNil(previous.tradingSettings.stopLoss?.threshold) ? (
                  <>{previous.tradingSettings.stopLoss?.threshold}%</>
                ) : (
                  <>--</>
                ))
              }
              after={
                bot.tradingSettings.stopLoss?.threshold ? (
                  <>{bot.tradingSettings.stopLoss.threshold}%</>
                ) : (
                  <>--</>
                )
              }
            />
          </DataRow>

          {(bot.tradingSettings.stopLoss?.deviation ||
            previous?.tradingSettings.stopLoss?.deviation) && (
            <DataRow label="Trailing Deviation">
              <PositionChange
                before={
                  previous &&
                  !Big(bot.tradingSettings.stopLoss?.deviation || 1).eq(
                    previous.tradingSettings.stopLoss?.deviation || 1
                  ) &&
                  (previous.tradingSettings.stopLoss?.deviation ? (
                    <>{previous.tradingSettings.stopLoss.deviation}%</>
                  ) : (
                    <>--</>
                  ))
                }
                after={
                  bot.tradingSettings.stopLoss?.deviation ? (
                    <>{bot.tradingSettings.stopLoss.deviation}%</>
                  ) : (
                    <>--</>
                  )
                }
              />
            </DataRow>
          )}
        </>
      )}

      {(!!bot.tradingSettings.takeProfit.length ||
        !!previous?.tradingSettings.takeProfit.length ||
        !!bot.tradingSettings.stopLoss ||
        !!previous?.tradingSettings.stopLoss) && (
        <>
          <DataRow label="Slippage">
            <PositionChange
              before={
                previous &&
                !Big(bot.tradingSettings.takeProfitConfig.slippage).eq(
                  previous.tradingSettings.takeProfitConfig.slippage
                ) && <>{previous.tradingSettings.takeProfitConfig.slippage}%</>
              }
              after={<>{bot.tradingSettings.takeProfitConfig.slippage}%</>}
            />
          </DataRow>

          <DataRow label="Gas Priority">
            <PositionChange
              before={
                previous &&
                !Big(
                  bot.tradingSettings.takeProfitConfig.maxSellGasPriority
                ).eq(
                  previous.tradingSettings.takeProfitConfig.maxSellGasPriority
                ) && (
                  <>
                    {
                      previous.tradingSettings.takeProfitConfig
                        .maxSellGasPriority
                    }{' '}
                    gwei
                  </>
                )
              }
              after={
                <>
                  {bot.tradingSettings.takeProfitConfig.maxSellGasPriority} gwei
                </>
              }
            />
          </DataRow>
          {chainHasMemPool(bot.tradingSettings.chain) && (
            <DataRow label="MEV Protection">
              <PositionChange
                before={
                  previous &&
                  !(
                    bot.tradingSettings.takeProfitConfig.antiMev ===
                    previous.tradingSettings.takeProfitConfig.antiMev
                  ) && (
                    <>
                      {previous.tradingSettings.takeProfitConfig.antiMev
                        ? 'Enabled'
                        : 'Disabled'}
                    </>
                  )
                }
                after={
                  <>
                    {bot.tradingSettings.takeProfitConfig.antiMev
                      ? 'Enabled'
                      : 'Disabled'}
                  </>
                }
              />
            </DataRow>
          )}
        </>
      )}
    </div>
  );
}

export function PositionChange({
  before,
  after,
}: {
  before: ReactNode;
  after: ReactNode;
}) {
  return (
    <div
      className={twMerge(
        'ml-auto whitespace-nowrap flex items-center gap-x-2',
        before && 'xxs:text-yellow-500'
      )}
    >
      {before} {before && <ArrowRight className="align-baseline" />} {after}
    </div>
  );
}

export function formValuesToDexBot(
  values: VolatilityBotFormValues
): VolatilityBotInput {
  return {
    name: values.name,
    vaultId: values.tradingSettings.walletId,
    addresses: values.botSettings.entries.addresses
      ? values.botSettings.entries.addresses.map((a) => a.address)
      : [],
    periodInMinutes: values.botSettings.entries.periodInMinutes,
    priceChange: values.botSettings.entries.priceChange
      ? -values.botSettings.entries.priceChange / 100
      : 0,
    position: {
      maxPriorityFeePerGasWei: parseGwei(
        values.tradingSettings.maxBuyGasPriority?.toString() ?? '0'
      ).toString(),
      vaultId: values.tradingSettings.walletId,
      buyAmountInWei: parseEther(
        values.tradingSettings.buyAmount.toString()
      ).toString(),
      chain: values.tradingSettings?.chain,
      antiMev: chainHasMemPool(values.tradingSettings?.chain)
        ? (values.tradingSettings.antiMev as boolean) ?? false
        : false,

      maxSlippage: percentToRatio(
        (values.tradingSettings.slippage as number) ?? 0
      ),
      takeProfits: values.tradingSettings.takeProfit.map(
        ({ id, threshold, weight }) => ({
          id,
          antiMev: chainHasMemPool(values.tradingSettings?.chain)
            ? (values.tradingSettings?.takeProfitConfig.antiMev as boolean) ??
              false
            : false,
          priceIncreasePercentage: percentToRatio(threshold).toString(),
          retry: values.botSettings.entries.autoRetryEnabled
            ? values.botSettings.entries.autoRetry.takeProfit
            : false,
          amountToSellPercentage: percentToRatio(weight).toString(),
          maxPriorityFeePerGasWei: parseGwei(
            values.tradingSettings.takeProfitConfig.maxSellGasPriority.toString()
          ).toString(),
          maxSlippage: percentToRatio(
            values.tradingSettings.takeProfitConfig.slippage
          ).toString(),
        })
      ),
      ...(!values.botSettings.entries.isDcaActive
        ? {}
        : {
            limitOrders: values.botSettings.entries.limitOrders.map((order) => {
              return {
                retry: values.botSettings.entries.autoRetryEnabled
                  ? values.botSettings.entries.autoRetry.buy
                  : false,
                priceDeviationPercentage: Big(
                  order.priceDeviationPercentage ?? 0
                ).div(-100),
                amount: parseEther(order.amount.toString()).toString(),
                maxPriorityFeePerGasWei: parseGwei(
                  values.tradingSettings.maxBuyGasPriority?.toString() ?? '0'
                ).toString(),
                maxSlippage: Big(order.slippage ?? 0).div(100),
                expirationInHours: values.botSettings.entries.expirationInHours,
                side: LimitOrderSide.Buy,
                comparisonOperator: LimitOrderOperator.LessThan,
                bribe: 0,
                antiMev: chainHasMemPool(values.tradingSettings?.chain)
                  ? (values.tradingSettings.antiMev as boolean) ?? false
                  : false,
              };
            }),
          }),
      trailingStopLoss: values.tradingSettings.stopLoss
        ? {
            amountToSellPercentage: '1',
            retry: values.botSettings.entries.autoRetryEnabled
              ? values.botSettings.entries.autoRetry.stopLoss
              : false,
            antiMev: chainHasMemPool(values.tradingSettings?.chain)
              ? (values.tradingSettings?.takeProfitConfig.antiMev as boolean) ??
                false
              : false,
            initialStopLossPercentage: (-percentToRatio(
              values.tradingSettings.stopLoss.threshold
            )).toString(),
            trailingDeviationPercentage: percentToRatio(
              values.tradingSettings.stopLoss.deviation || 100
            ).toString(),
            maxPriorityFeePerGasWei: parseGwei(
              values.tradingSettings.takeProfitConfig.maxSellGasPriority.toString()
            ).toString(),
            maxSlippage: percentToRatio(
              values.tradingSettings.takeProfitConfig.slippage
            ).toString(),
          }
        : null,
    },
  };
}
