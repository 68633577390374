import * as yup from 'yup';
import Big from 'big.js';
import { Chains } from 'api/d-wallets';

export function getSchema() {
  return yup.object({
    maxBalance: yup.string(),
    srcChain: yup
      .string()
      .oneOf([...Object.values(Chains)])
      .required('Required'),
    dstChain: yup
      .string()
      .oneOf([...Object.values(Chains)])
      .required('Required'),
    address: yup.string().required('Required'),
    srcChainTokenAmount: yup
      .number()
      .min(0.00101, 'Should be greater than 0.001')
      .test(
        'isGreaterThanAvailable',
        'Cannot be greater than available',
        (value, context) => {
          const maxBalance = context.parent.maxBalance as string | undefined;
          return !Big(value ?? 0).gt(maxBalance ?? 0);
        }
      )
      .typeError('Number type accepted only')
      .required('Required'),
  });
}

export type FormValues = yup.InferType<ReturnType<typeof getSchema>>;
