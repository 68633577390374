import CustomModal from '../../components/modal/CustomModal';

type Props = {
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
};

export function ExplainerModal({ handleClose, title, children }: Props) {
  return (
    <CustomModal title={title} handleClose={handleClose} showModal>
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          {title}
        </CustomModal.Title>

        <CustomModal.Content>{children}</CustomModal.Content>
      </CustomModal.Body>
    </CustomModal>
  );
}
