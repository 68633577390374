import React, { FC, useCallback, useRef, useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from 'react-share';
import { ReactComponent as TwitterLogo } from 'assets/img/news/twitter.svg';
import { ReactComponent as TelegramLogo } from 'assets/img/news/telegram.svg';
import { ReactComponent as FacebookLogo } from 'assets/img/news/facebook.svg';
import { useQuery } from '@tanstack/react-query';
import ReferralService, {
  referralServiceKeys,
} from 'api/services/ReferralService';

import { ArrowDownTrayIcon, LinkIcon } from '@heroicons/react/24/outline';
import { copyContent } from 'utils/copyContent';
import ShareImage from './ShareImage';
import Toggle from 'modules/shared-components/toggle';
import html2canvas from 'html2canvas';
import { LoadingWithMessage } from 'utils/Notifications/LoadingWithMessage';
import { OpenPositionListItem } from 'api/dex-trade';
import {
  SCALE_CLASSES_VERICAL,
  SCALE_CLASSES_HORIZONTAL,
} from 'modules/marketplace-performance-page/components/ShareImage';
import CustomModal from '../modal/CustomModal';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';

const ShareButton: FC<
  React.HTMLAttributes<HTMLDivElement> & { loading?: boolean }
> = ({ children, loading, ...rest }) => (
  <div
    className="flex flex-col items-center xxs:gap-1 xxs:cursor-pointer xxs:text-dex-white-secondary hover:text-dex-white"
    {...rest}
  >
    {loading && <LoadingWithMessage className="" />}
    {!loading && children}
  </div>
);

const CopyButtonDescription: FC = ({ children }) => (
  <p className="xxs:mb-0 text-xs ">{children}</p>
);

interface Props {
  position: OpenPositionListItem;
  onClose: () => void;
}

export const ShareTradeModal = ({ position, onClose }: Props) => {
  const isDesktop = useMediaQuery(screenGte.small);

  const [imageVertical, setImageVertical] = useState(!isDesktop);
  const [elementCopied, setElementCopied] = useState(false);
  const imageRef = useRef<HTMLDivElement | null>(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { data } = useQuery({
    queryFn: ReferralService.getReferralLink,
    queryKey: referralServiceKeys.getReferralLink(),
  });

  const saveImage = useCallback(() => {
    if (imageRef.current === null) {
      return;
    }
    const SCALE_CLASSES = imageVertical
      ? SCALE_CLASSES_VERICAL
      : SCALE_CLASSES_HORIZONTAL;

    setIsImageLoading(true);
    imageRef.current.classList.remove(...SCALE_CLASSES);
    imageRef.current?.parentElement?.classList.remove('xxs:max-h-[400px]');

    void html2canvas(imageRef.current, {
      scale: 2,
      allowTaint: true,
      useCORS: true,
    })
      .then((result) => {
        const link = document.createElement('a');
        link.href = result.toDataURL('image/jpeg');
        link.download = 'share-image.jpeg';
        link.click();
        link.remove();
      })
      .finally(() => {
        imageRef.current?.parentElement?.classList.add('xxs:max-h-[400px]');

        imageRef.current?.classList.add(...SCALE_CLASSES);
        setIsImageLoading(false);
      });
  }, [imageRef]);

  const referralCode = data?.referral_code;
  const hostName = window.location.hostname;
  const shareUrl = `https://${hostName}/dex/snipe/new/${position.chain}/${
    position.tokenAddress
  }?referrer_id=${referralCode || ''}`;

  return (
    <CustomModal title="Share" size="xLarge" showModal handleClose={onClose}>
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Share this trade with your community
        </CustomModal.Title>

        <div className="xxs:space-y-4">
          <div className="form-check form-switch flex items-center lg:justify-end xxs:px-0 lg:px-8">
            <label
              className="xxs:mr-3 inline-block text-xs font-regular xxs:text-black-900 dark:text-black-100"
              htmlFor="flexSwitchCheckDefault"
            >
              Horizontal
            </label>
            <Toggle
              value={imageVertical}
              onChange={(value) => setImageVertical(value)}
            />
            <label
              className=" xxs:ml-3 inline-block text-xs font-regular xxs:text-black-900 dark:text-black-100"
              htmlFor="flexSwitchCheckDefault"
            >
              Vertical
            </label>
          </div>

          <div className="flex flex-col text-sm text-black-500 dark:text-white-100 text-left">
            <ShareImage
              horizontal={!imageVertical}
              shareUrl={shareUrl}
              referralCode={referralCode}
              ref={imageRef}
              position={position}
            />
          </div>
        </div>
      </CustomModal.Body>
      <CustomModal.Footer>
        <div className="xxs:w-full flex justify-center items-end xxs:gap-6 ">
          <ShareButton
            loading={isImageLoading}
            onClick={() => {
              saveImage();
            }}
          >
            <ArrowDownTrayIcon width={24} />
            <CopyButtonDescription>Save Image</CopyButtonDescription>
          </ShareButton>
          <ShareButton
            onClick={() => {
              void copyContent(setElementCopied, shareUrl);
            }}
          >
            <LinkIcon width={24} />
            <CopyButtonDescription>
              {elementCopied ? 'Copied' : 'Copy'}
            </CopyButtonDescription>
          </ShareButton>
          <div className="xxs:text-dex-white-secondary hover:text-dex-white fill-dex-white-secondary hover:fill-dex-white">
            <FacebookShareButton
              url={shareUrl}
              className="flex flex-col items-center xxs:gap-1 "
            >
              <FacebookLogo width={24} height={24} className="" />
              <CopyButtonDescription>Facebook</CopyButtonDescription>
            </FacebookShareButton>
          </div>

          <div className="xxs:text-dex-white-secondary hover:text-dex-white fill-dex-white-secondary hover:fill-dex-white">
            <TwitterShareButton
              url={shareUrl}
              className="flex flex-col items-center xxs:gap-1"
            >
              <TwitterLogo width={24} height={24} />
              <CopyButtonDescription>Twitter</CopyButtonDescription>
            </TwitterShareButton>
          </div>
          <div className="xxs:text-dex-white-secondary hover:text-dex-white fill-dex-white-secondary hover:fill-dex-white">
            <TelegramShareButton
              url={shareUrl}
              className="flex flex-col items-center xxs:gap-1"
            >
              <TelegramLogo width={24} height={24} />
              <CopyButtonDescription>Telegram</CopyButtonDescription>
            </TelegramShareButton>
          </div>
        </div>
      </CustomModal.Footer>
    </CustomModal>
  );
};
