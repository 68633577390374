import { DexRoute } from 'modules/DexSwitch/DexRoute';
import { PrivateRoute, lazyWithRetry } from 'modules/routing';
import { Redirect, Route } from 'react-router-dom';
import TradeSuccesful from './TradeSuccess';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import config from '../../config';
import CreateNewBotContainer from './bots/createNewBotContainer';
import EditBotContainer from './bots/editBotContainer';
import DuplicateBotContainer from './bots/duplicateBotContainer';
import { Tabs } from './components/layout/SIDEBAR_CONST';
import AlphaProgram from './alpha-leaderboard';

const Research = lazyWithRetry(() => import('./research'));
const MarketplaceDetails = lazyWithRetry(
  () => import('./research/components/TelegramChannels/[traderId]/index')
);

const WalletDetails = lazyWithRetry(
  () => import('./research/components/Wallets/[address]/index')
);
const TokenDetails = lazyWithRetry(() => import('./research/[address]/index'));
const TradeDetails = lazyWithRetry(() => import('./trade-monitor/[id]/index'));
const Trade = lazyWithRetry(() => import('./trade'));
const EditTrade = lazyWithRetry(() => import('./trade/Edit/Edit'));
const Wallet = lazyWithRetry(() => import('./wallets'));
const SnipeContainer = lazyWithRetry(() => import('./trade/snipeContainer'));
const DashboardContainer = lazyWithRetry(() => import('./dashboard/index'));
const TradeMonitor = lazyWithRetry(() => import('./trade-monitor'));
const Rewards = lazyWithRetry(() => import('./rewards'));
const MobileDexNavPage = lazyWithRetry(
  () => import('./components/layout/MobileDexNavPage')
);
const DexBots = lazyWithRetry(() => import('./bots/dashboard'));
const DuplicatePosition = lazyWithRetry(() => import('./duplicate-position'));
const Search = lazyWithRetry(() => import('./search/searchPage'));

export const dexRoutes = [
  {
    path: '/dex/navigation',
    name: 'Dex Navigation',
    exact: true,
    component: MobileDexNavPage,
    route: Route,
  },

  {
    path: `/dex/research/${Tabs.Wallets}/:chain/:address/details`,
    name: 'Wallet Research Details',
    component: WalletDetails,
    exact: true,
    route: Route,
  },

  {
    path: `/dex/research/${Tabs.TelegramChannels}/:traderId/details`,
    name: 'Dex Marketplace Details',
    component: MarketplaceDetails,
    route: Route,
  },

  {
    path: '/dex/research/:chain/:address',
    name: 'Dex Research',
    component: TokenDetails,
    exact: true,
    route: Route,
  },

  {
    path: '/dex/research',
    name: 'Research',
    exact: true,
    component: function Component() {
      return <Redirect to={`/dex/research/${Tabs.HotTokens}`} />;
    },
    route: Route,
  },

  {
    path: '/dex/research/:tab',
    name: 'Dex Research',
    component: Research,
    exact: true,
    route: Route,
  },

  {
    path: '/dex/wallets',
    name: 'Wallets',
    component: Wallet,
    exact: true,
    route: Route,
  },

  {
    path: '/dex/dashboard',
    name: 'Dashboard',
    exact: true,
    component: function Component() {
      return <Redirect to={'/dex/dashboard/performance'} />;
    },
    route: Route,
  },

  {
    path: '/dex/dashboard/:tab',
    name: 'Dashboard',
    component: DashboardContainer,
    route: Route,
    exact: true,
  },
  {
    path: '/dex/snipe',
    name: 'Snipe',
    component: SnipeContainer,
    route: Route,
    exact: true,
  },
  {
    path: '/dex/trade-monitoring/:tab',
    name: 'Snipe',
    component: TradeMonitor,
    route: Route,
    exact: true,
  },
  {
    path: '/dex/trade-monitoring/:tab/:id',
    name: 'Trade details',
    component: TradeDetails,
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/dex/snipe/new/:chain/:address',
    name: 'Trade',
    component: Trade,
    exact: true,
    route: Route,
  },
  {
    path: '/dex/snipe/success',
    name: 'Trade Success',
    component: TradeSuccesful,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/snipe/:chain/:positionId/edit',
    name: 'Trade',
    component: EditTrade,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/snipe/:chain/:positionId/copy',
    name: 'Trade',
    component: DuplicatePosition,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/bots',
    name: 'Bots',
    exact: true,
    component: function Component() {
      return <Redirect to={`/dex/bots/${Tabs.VolatilityBot}`} />;
    },
    route: Route,
  },

  {
    path: '/dex/bots/:tab',
    name: 'Bots',
    exact: true,
    component: DexBots,
    route: Route,
  },

  {
    path: '/dex/bots/:tab/new',
    name: 'Create Bot',
    component: CreateNewBotContainer,
    route: PrivateRoute,
    exact: true,
  },

  {
    path: '/dex/bots/:tab/:id/edit',
    name: 'Edit bot',
    component: EditBotContainer,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/dex/bots/:tab/:id/duplicate',
    name: 'Duplicate bot',
    component: DuplicateBotContainer,
    exact: true,
    route: PrivateRoute,
  },

  {
    path: '/dex/search',
    name: 'Search',
    component: Search,
    exact: true,
    route: Route,
  },
  {
    path: '/dex/rewards',
    name: 'Rewards',
    component: Rewards,
    exact: true,
    route: PrivateRoute,
  },

  {
    path: '/dex/alpha-program/:tab',
    name: 'Leaderboard',
    component: AlphaProgram,
    route: Route,
    exact: true,
  },
  // It might be never visible
  {
    path: '/dex',
    name: 'Dex',
    exact: true,
    component: function Component() {
      const isDesktop = useMediaQuery(screenGte.large);

      return (
        <Redirect
          to={
            isDesktop
              ? '/dex/snipe'
              : config.isTelegramBrowser
              ? '/dex/navigation'
              : 'dex/wallets'
          }
        />
      );
    },
    route: Route,
  },
].map((route) => ({ ...route, route: DexRoute(route.route) }));
