import { twMerge } from 'tailwind-merge';
import { Loader } from '../Loader';
import React from 'react';

export const LoadingWithMessage = (props) => {
  let name;
  if (props.test_id_name) {
    name = '-' + props.test_id_name;
  } else {
    name = '';
  }
  return (
    <div
      className={twMerge(
        ' flex justify-content-center align-content-center align-items-center',
        props.className
      )}
      style={{ height: props.height }}
      data-testid={'loading-element' + name}
    >
      <div className="text-center">
        <Loader />

        {props.message && (
          <div className={props.class}>
            <p className="pt-2 pb-3">{props.message}</p>
          </div>
        )}
      </div>
    </div>
  );
};
