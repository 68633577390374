import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import Big from 'big.js';
import { fromWei, weiToGwei } from 'modules/ethereum/utils';
import {
  formatTokenAmount,
  formatNumberWithSuffix,
  formatWithoutDecimals,
} from 'utils/FormatNumber';
import { Wrapper } from './PositionDetails';
import { DetailsTopInfo } from './TopInfo';
import { OpenPositionListItem } from 'api/dex-trade';
import { percentageToTimesIncrease } from 'modules/utils/number';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/DWalletService';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { chainAsset, chainHasMemPool, chainName } from 'api/d-wallets';

interface Props {
  position: OpenPositionListItem;
}

export function PendingSnipeDetails({ position }: Props) {
  const { data } = useQuery({
    queryFn: () =>
      ContractService.getContract(position.chain, position.tokenAddress),
    queryKey: contractServiceKeys.getContract(
      position.chain,
      position.tokenAddress
    ),
  });
  const { data: wallet } = useQuery({
    queryFn: () => DWalletService.getWallet(position.vaultId),
    queryKey: dWalletServiceKeys.getWallet(position.vaultId),
    enabled: !!position,
  });

  const sellMaxPriorityFeePerGasWei =
    position.takeProfits?.length > 0
      ? position.takeProfits[0].maxPriorityFeePerGasWei
      : position.trailingStopLoss
      ? position.trailingStopLoss.maxPriorityFeePerGasWei
      : null;
  const sellSlippage =
    position.takeProfits?.length > 0
      ? position.takeProfits[0].maxSlippage
      : position.trailingStopLoss
      ? position.trailingStopLoss.maxSlippage
      : null;
  const sellAntiMev =
    position.takeProfits?.length > 0
      ? position.takeProfits[0].antiMev
      : position.trailingStopLoss
      ? position.trailingStopLoss.antiMev
      : null;

  const isSniping = data?.tradingInfo?.isSnipeable;

  return (
    <Wrapper className="xxs:gap-6 lg:gap-2">
      <DetailsTopInfo position={position} contract={data} />
      <div className="xxs:text-dex-white">
        <div className="grow ">
          <div className="xxs:p-3 hidden lg:flex lg:bg-dex-black-600 xxs:rounded-t justify-between items-center">
            <div className="xxs:text-sm">Settings</div>
          </div>
          <div className="flex flex-col xxs:gap-2 lg:bg-dex-black-700 lg:p-4">
            <DataRow label="Token">
              {position.tokenName} ({position.tokenSymbol})
            </DataRow>
            <DataRow label="Chain">
              <div className="flex space-x-1 items-center">
                <ChainsIcon imgName={position.chain} />
                <div>{chainName(position.chain)}</div>
              </div>
            </DataRow>
            <DataRow label="Wallet">
              {wallet ? wallet.label : 'Loading'}
            </DataRow>

            <Divider />
            <p className="xxs:text-sm xxs:font-semibold xxs:text-dex-white xxs:mb-0">
              Buy settings
            </p>
            {position.buyAmountInWei !== '0' ? (
              <DataRow label="Amount">
                {formatTokenAmount(fromWei(position.buyAmountInWei))}{' '}
                {chainAsset(position.chain)}
              </DataRow>
            ) : (
              position.limitOrders.map((order, index) => (
                <DataRow key={order.id} label={`Buy Limit Order ${index + 1}`}>
                  ${formatNumberWithSuffix(order.price)} |{' '}
                  {formatTokenAmount(fromWei(order.amount))}{' '}
                  {chainAsset(position.chain)}
                </DataRow>
              ))
            )}
            {Big(position.bribe).gt(0) ? (
              <>
                <DataRow label="Bribing Fee">
                  {formatTokenAmount(fromWei(position.bribe))}{' '}
                  {chainAsset(position.chain)}
                </DataRow>
                <DataRow label="Block 1 Snipe Retry">
                  {position.blockOneRetry ? 'Yes' : 'No'}
                </DataRow>
                {position.blockOneRetry && (
                  <>
                    <DataRow label="Backup Bribing Fee">
                      {formatTokenAmount(fromWei(position.backupBribe))}{' '}
                      {chainAsset(position.chain)}
                    </DataRow>
                    <DataRow label="Slippage">
                      {Big(
                        position.limitOrders[0]?.maxSlippage ||
                          position.maxSlippage
                      )
                        .times(100)
                        .toNumber()}
                      %
                    </DataRow>
                  </>
                )}
              </>
            ) : (
              <>
                <DataRow label="Gas Priority">
                  {weiToGwei(
                    position.limitOrders[0]?.maxPriorityFeePerGasWei ||
                      position.maxPriorityFeePerGasWei
                  )}{' '}
                  gwei
                </DataRow>
                <DataRow label="Slippage">
                  {Big(
                    position.limitOrders[0]?.maxSlippage || position.maxSlippage
                  )
                    .times(100)
                    .toNumber()}
                  %
                </DataRow>
                {chainHasMemPool(position.chain) && (
                  <DataRow label="MEV Protection">
                    {position.antiMev ? 'Yes' : 'No'}
                  </DataRow>
                )}
              </>
            )}

            <Divider />
            <p className="xxs:text-sm xxs:font-semibold xxs:text-dex-white xxs:mb-0">
              Sell settings
            </p>
            {position.takeProfits.length ? (
              position.takeProfits.map((takeProfit, index) => (
                <DataRow key={takeProfit.id} label={`Take Profit ${index + 1}`}>
                  +
                  {percentageToTimesIncrease(
                    Big(takeProfit.priceIncreasePercentage)
                      .times(100)
                      .toNumber()
                  )}
                  X (
                  {Big(takeProfit.amountToSellPercentage).times(100).toNumber()}
                  %)
                </DataRow>
              ))
            ) : (
              <DataRow label="Take Profit">Disabled</DataRow>
            )}

            {position.trailingStopLoss ? (
              <>
                <DataRow label="Stop Loss Threshold">
                  {position.trailingStopLoss?.initialStopLossPercentage ? (
                    <>
                      {Big(position.trailingStopLoss.initialStopLossPercentage)
                        .times(100)
                        .toNumber()}
                      %
                    </>
                  ) : (
                    <>--</>
                  )}
                </DataRow>
                <DataRow label="Stop Loss Trailing Deviation">
                  {!Big(
                    position.trailingStopLoss?.trailingDeviationPercentage
                  ).eq(1) ? (
                    <>
                      {Big(
                        position.trailingStopLoss.trailingDeviationPercentage
                      )
                        .times(100)
                        .toNumber()}
                      %
                    </>
                  ) : (
                    <>--</>
                  )}
                </DataRow>
              </>
            ) : (
              <DataRow label="Stop Loss">Disabled</DataRow>
            )}

            {sellMaxPriorityFeePerGasWei !== null ? (
              <DataRow label="Gas Priority">
                {weiToGwei(sellMaxPriorityFeePerGasWei)} gwei
              </DataRow>
            ) : (
              ''
            )}
            {sellSlippage !== null ? (
              <DataRow label="Slippage">
                {Big(sellSlippage).times(100).toNumber()}%
              </DataRow>
            ) : (
              ''
            )}
            {chainHasMemPool(position.chain) &&
              (sellAntiMev !== null ? (
                <DataRow label="MEV Protection">
                  {sellAntiMev ? 'Yes' : 'No'}
                </DataRow>
              ) : (
                ''
              ))}

            <Divider />
            <p className="xxs:text-xs lg:text-sm xxs:font-semibold xxs:text-dex-white xxs:mb-0">
              Safety Measures
            </p>
            {isSniping ? (
              <>
                <DataRow label="Tax Protection">
                  {Big(position.maxBuyTax).lt(1) ||
                  Big(position.maxSellTax).lt(1) ? (
                    <>
                      Buy: {Big(position.maxBuyTax).times(100).toNumber()}% -
                      Sell: {Big(position.maxSellTax).times(100).toNumber()}%
                    </>
                  ) : (
                    'Disabled'
                  )}
                </DataRow>
                <DataRow label="Min-Max Liquidity">
                  {Big(position.minLiquidityInUsd).gt(0) ||
                  Big(position.maxLiquidityInUsd).lt(
                    Number.MAX_SAFE_INTEGER
                  ) ? (
                    <>
                      ${formatNumberWithSuffix(position.minLiquidityInUsd)} - ${' '}
                      {formatNumberWithSuffix(position.maxLiquidityInUsd)}
                    </>
                  ) : (
                    'Disabled'
                  )}
                </DataRow>
                <DataRow label="Min-Max Market Cap">
                  {Big(position.minMarketcapInUsd).gt(0) ||
                  Big(position.maxMarketcapInUsd).lt(
                    Number.MAX_SAFE_INTEGER
                  ) ? (
                    <>
                      ${formatNumberWithSuffix(position.minMarketcapInUsd)} - ${' '}
                      {formatNumberWithSuffix(position.maxMarketcapInUsd)}
                    </>
                  ) : (
                    'Disabled'
                  )}
                </DataRow>
              </>
            ) : (
              ''
            )}
            <DataRow label="Gas Limit">
              {position?.gasLimit ? (
                <>{formatWithoutDecimals(position.gasLimit)} wei</>
              ) : (
                'Disabled'
              )}
            </DataRow>
            {chainHasMemPool(position.chain) && (
              <DataRow label="Anti-rug Protection">Enabled</DataRow>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const DataRow = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div className="flex justify-between items-center">
    <div className="xxs:mb-0 xxs:text-dex-white-secondary xxs:text-xs lg:text-sm">
      {label}
    </div>
    <div className="xxs:text-dex-white xxs:text-xs lg:text-sm">{children}</div>
  </div>
);

function Divider() {
  return <div className="h-[1px] xxs:bg-dex-black-600 xxs:my-2" />;
}
