/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import { PoolRowInfo, TelegramCallsTableSortKeys } from 'api/contracts';
import { formatDuration } from 'modules/utils/formatDuration';
import { useEffect, useState } from 'react';
import {
  BeakerIcon,
  ClockIcon,
  LockClosedIcon,
  PlayCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/24/outline';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { ReactComponent as HoneypotIcon } from 'assets/img/icons/honeypot.svg';
import { twMerge } from 'tailwind-merge';
import Tooltip from 'modules/shared-components/tooltip';
import Big, { BigSource } from 'big.js';
import { toPercentageChange } from 'modules/utils/number';
import { CallsDetailsModal } from './CallsModal';
import Button from 'modules/shared-components/button/button';
import { useToggle } from 'modules/utils/useToggle';
import { chainAsset, Chains } from 'api/d-wallets';
import { Actions } from '../SnipingOpportunitiesCard/columns';
import Tag from '../../../components/alerts/Tags';

export const desktopColumns: TableColumn<PoolRowInfo>[] = [
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The Token address, chain, and DEX.">
          <div>Token</div>
        </Tooltip>
      </div>
    ),
    key: 'PAIR',
    component: ({ data }) => (
      <span
        className={
          data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
        }
      >
        <div className="flex items-center xxs:gap-2">
          <div className="relative xxs:hidden md:block">
            <TokenIconWithChain
              tokenName={data.token0Symbol}
              chain={data.chain?.key ?? Chains.Ethereum}
              protocol={data.dex?.key}
              symbol={data.token0Symbol}
              className="w-6 h-6"
              address={data.tokenAddress}
            />
          </div>
          <div className="max-w-[120px] overflow-hidden truncate">
            <span>
              <span className="font-bold text-sm">{data.token0Symbol}</span>/
              <span className="xxs:text-dex-white-secondary xxs:text-xs">
                {data.token1Symbol}
              </span>
            </span>
            <div>
              <EthAddress
                address={data.tokenAddress}
                precision={3}
                kind="token"
                className="text-xs"
                chain={data.chain?.key ?? Chains.Ethereum}
              />
            </div>
          </div>
        </div>
      </span>
    ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The number of Telegram channels and groups calling this token. Click for more info.">
          <div>Calls</div>
        </Tooltip>
      </div>
    ),
    key: 'CALLS',
    sortableKey: TelegramCallsTableSortKeys.NumberOfCalls,
    component: ({ data }) => (
      <div
        className={
          data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
        }
      >
        <div className="">
          <div className="font-bold">
            <CallsDetailed
              className="text-xs"
              contract={data.tokenAddress}
              calls={data.numberOfCalls}
              chain={data.chain.key}
            />
          </div>
          {data.lastCall && (
            <div className="text-xs">
              Last: <DurationCounter start={data.lastCall} /> ago
            </div>
          )}
        </div>
      </div>
    ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The current price and fully diluted market cap.">
          <div>Price/MC</div>
        </Tooltip>
      </div>
    ),
    key: 'PRICE/MC',
    sortableKey: TelegramCallsTableSortKeys.Marketcap,
    component: ({ data }) => (
      <div
        className={
          data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary ' : ''
        }
      >
        {data.price && (
          <div>
            <span className="xxs:text-dex-white-secondary text-xs">P: </span>
            <span className=" text-xs xxs:text-dex-white ">
              ${formatNumberWithSuffix(data.price)}
            </span>{' '}
            {data.currentPrice &&
              data.listingPrice &&
              data.status !== 'RUG_PULL' && (
                <PercentageChangeWithMaxValue
                  maxValue={999}
                  className="xxs:text-xs"
                  change={toPercentageChange(
                    data.listingPrice,
                    data.currentPrice
                  )}
                />
              )}
          </div>
        )}
        {data.marketcap && (
          <div>
            <span className="xxs:text-dex-white-secondary text-xs">MC: </span>
            <span className="xxs:text-dex-white text-xs">
              ${formatNumberWithSuffix(data.marketcap, { precision: 1 })}
            </span>
          </div>
        )}
        {!data.price && !data.marketcap && '--'}
      </div>
    ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The total liquidity (as sum of token and quote asset in USD) at launch and now, on the major pool.">
          <div>Liquidity</div>
        </Tooltip>
      </div>
    ),
    key: 'TOTAL LIQUIDITY',
    sortableKey: TelegramCallsTableSortKeys.Liquidity,
    component: ({ data }) => {
      return (
        <div
          className={
            data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
          }
        >
          {data.totalLiquidityUsd ? (
            <div className="space-y-1">
              <div className="flex space-x-1 items-center">
                <div className="xxs:text-dex-white-secondary text-xs">
                  <Tooltip text="Liquidity at launch">
                    <PlayCircleIcon className="h-4 w-4" />
                  </Tooltip>
                </div>
                {data.totalLiquidityUsdAtLaunch ? (
                  <div className="xxs:text-dex-white text-xs">
                    $
                    {formatNumberWithSuffix(data.totalLiquidityUsdAtLaunch, {
                      precision: 1,
                    })}
                  </div>
                ) : (
                  <div>--</div>
                )}
              </div>
              <div className="flex space-x-1 items-center">
                <div className="xxs:text-dex-white-secondary">
                  <Tooltip text="Liquidity now">
                    <ClockIcon className="h-4 w-4" />
                  </Tooltip>
                </div>
                <div className="xxs:text-dex-white text-xs">
                  $
                  {formatNumberWithSuffix(data.totalLiquidityUsd, {
                    precision: 1,
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div>--</div>
          )}
        </div>
      );
    },
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The current pool liquidity expressed in quote asset.">
          <div>Pool Amount</div>
        </Tooltip>
      </div>
    ),
    key: 'POOL AMOUNT',
    component: ({ data }) =>
      data.ethLiquidity ? (
        <div
          className={
            data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
          }
        >
          <div className="space-y-1.5 text-xs">
            <div className="flex space-x-1 items-center">
              <div className="">
                {formatNumberWithSuffix(data.ethLiquidity, { precision: 1 })}
              </div>
              <div className="">
                {chainAsset(data.chain?.key ?? Chains.Ethereum)}
              </div>
            </div>

            <div className="flex space-x-1 text-xs items-center xxs:text-dex-white-secondary">
              <ClockIcon className="w-4 h-4" />
              <div className="">
                <DurationCounter start={data.creationDatetime} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>--</>
      ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="Important security checks such as honeypot, low liquidity, and others.">
          <div>Security</div>
        </Tooltip>
      </div>
    ),
    key: 'SECURITY',
    component: ({ data }) => <SecurityChecks data={data} />,
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The current status of the token, which could be launched, not launched, or rug-pull in the event of a scam.">
          <div>Status</div>
        </Tooltip>
      </div>
    ),
    key: 'STATUS',
    component: ({ data }) => (
      <div className="text-xs">
        {data.status === 'SNIPEABLE' ? (
          <Tag condensed type="info">
            Not Launched
          </Tag>
        ) : data.status === 'NOT_SNIPEABLE' ? (
          <Tag condensed type="info">
            Launched
          </Tag>
        ) : (
          <Tag condensed type="error">
            Rug-pull
          </Tag>
        )}
      </div>
    ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        Actions
      </div>
    ),
    key: 'ACTIONS',
    component: ({ data }) => (
      <Actions
        tokenAddress={data.tokenAddress}
        chain={data.chain.key}
        symbol={data.token0Symbol}
        poolAddress={data.poolAddress}
      />
    ),
  },
];

export const mobileColumns: TableColumn<PoolRowInfo>[] = [
  {
    key: 'PAIR',
    component: ({ data }) => (
      <div
        className={
          data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
        }
      >
        <div className="flex justify-between items-center ">
          <div className="flex items-center xxs:gap-2">
            <div className="relative">
              <TokenIconWithChain
                tokenName={data.token0Symbol}
                chain={data.chain?.key ?? Chains.Ethereum}
                protocol={data.dex?.key}
                symbol={data.token0Symbol}
                className="w-6 h-6"
                address={data.tokenAddress}
              />
            </div>
            <div className="max-w-[120px] overflow-hidden truncate">
              <div>
                <span className="font-bold text-sm">{data.token0Symbol}</span>/
                <span className="xxs:text-dex-white-secondary xxs:text-xs">
                  {data.token1Symbol}
                </span>
              </div>
              <div>
                <EthAddress
                  className="text-xs"
                  address={data.tokenAddress}
                  precision={3}
                  kind="token"
                  chain={data.chain?.key ?? Chains.Ethereum}
                />
              </div>
            </div>
          </div>
          <div className="justify-end flex flex-col items-end">
            {data.price ? (
              <div className="flex space-x-1 items-end">
                <div className="font-bold text-lg">
                  $
                  {formatNumberWithSuffix(data.price, {
                    precision: 1,
                  })}
                </div>
                {data.marketcap && (
                  <div className="text-xs pb-[3px]">
                    MC: $
                    {formatNumberWithSuffix(data.marketcap, { precision: 1 })}
                  </div>
                )}
              </div>
            ) : (
              <>--</>
            )}
            <div className="flex space-x-1 items-center">
              {data.ethLiquidity ? (
                <div className="flex space-x-1 items-end">
                  <div className="text-xs">Calls: {data.numberOfCalls}</div>
                </div>
              ) : (
                ''
              )}
              <SecurityChecksMobile data={data} />
              <div className="text-xs">
                {data.status === 'SNIPEABLE' ? (
                  <Tag condensed type="info">
                    Not Launched
                  </Tag>
                ) : data.status === 'NOT_SNIPEABLE' ? (
                  <Tag condensed type="info">
                    Launched
                  </Tag>
                ) : (
                  <Tag condensed type="error" className="text-white">
                    Rug-pull
                  </Tag>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  },
];

interface CallsDetailsProps {
  contract: string;
  calls: number | null | undefined;
  className?: string;
  chain: Chains;
}

export function CallsDetailed({
  contract,
  calls,
  className,
  chain,
}: CallsDetailsProps) {
  const [isOpen, { close, open }] = useToggle();
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className="flex gap-1">
        <Button type="button" className="p-0" onClick={open}>
          {calls ? (
            <div
              className={twMerge(
                'rounded-sm py-0.5 px-1 bg-white-400 hover:bg-white-300 dark:bg-black-700 dark:hover:bg-black-500',
                className
              )}
            >
              {calls}
            </div>
          ) : (
            '0'
          )}
        </Button>
      </div>

      {isOpen && (
        <CallsDetailsModal close={close} contract={contract} chain={chain} />
      )}
    </div>
  );
}

export function SecurityChecks({
  data: { contractSecurity },
}: {
  data: PoolRowInfo;
}) {
  return (
    <div className="flex justify-center lg:justify-start">
      <div className="flex items-center xxs:gap-0.5">
        <Tooltip
          text={
            contractSecurity.honeypot === true
              ? 'Token is Honeypot'
              : contractSecurity.honeypot === false
              ? 'No Honeypot'
              : 'Honeypot Unknown'
          }
        >
          <HoneypotIcon
            className={twMerge(
              'xxs:w-4 xxs:h-4 sm:w-5 sm:h-5 p-0.5',
              contractSecurity.honeypot === true
                ? 'text-red-500'
                : contractSecurity.honeypot === false
                ? 'text-green-500'
                : 'dark:text-white-800'
            )}
          />
        </Tooltip>
        <Tooltip
          text={
            contractSecurity.buyTax !== null ||
            contractSecurity.sellTax !== null
              ? `${contractSecurity.highTaxes ? 'High Taxes. ' : ''}Buy Tax: ${
                  contractSecurity.buyTax
                    ? Big(contractSecurity.buyTax).times(100).toFixed(2)
                    : '--'
                }% | Sell Tax: ${
                  contractSecurity.sellTax
                    ? Big(contractSecurity.sellTax).times(100).toFixed(2)
                    : '--'
                }%`
              : 'Taxes Unknown'
          }
        >
          <ScaleIcon
            className={twMerge(
              'xxs:w-4 xxs:h-4 sm:w-5 sm:h-5',
              contractSecurity.buyTax === null &&
                contractSecurity.sellTax === null
                ? 'dark:text-white-800'
                : contractSecurity.highTaxes === true
                ? 'text-red-500'
                : 'text-green-500'
            )}
          />
        </Tooltip>
        <Tooltip
          text={
            contractSecurity.contractVerified === true
              ? 'Contract Verified'
              : contractSecurity.contractVerified === false
              ? 'Contract Not Verified'
              : 'Contract Verification Unknown'
          }
        >
          {contractSecurity.contractVerified === true ? (
            <ShieldCheckIcon
              className={'xxs:w-4 xxs:h-4 sm:w-5 sm:h-5 text-green-500'}
            />
          ) : (
            <ShieldExclamationIcon
              className={'xxs:w-4 xxs:h-4 sm:w-5 sm:h-5 text-yellow-500'}
            />
          )}
        </Tooltip>
        {contractSecurity.contractRenounced && (
          <Tooltip text={'Contract Renounced'}>
            <LockClosedIcon
              className={'xxs:w-4 xxs:h-4 sm:w-5 sm:h-5 text-green-500'}
            />
          </Tooltip>
        )}
        {contractSecurity.lowLiquidity && (
          <Tooltip text={'Low Liquidity'}>
            <BeakerIcon
              className={'xxs:w-4 xxs:h-4 sm:w-5 sm:h-5 text-red-500'}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export function SecurityChecksMobile({
  data: { contractSecurity },
}: {
  data: PoolRowInfo;
}) {
  return (
    <div className="flex justify-center lg:justify-start">
      <div className="flex items-center xxs:gap-0.5">
        {contractSecurity.honeypot && (
          <HoneypotIcon className="xxs:w-4 xxs:h-4 p-0.5 text-red-500" />
        )}
        {contractSecurity.highTaxes ||
          (contractSecurity.sellTax && (
            <ScaleIcon className="xxs:w-4 xxs:h-4 text-red-500" />
          ))}
        {contractSecurity.lowLiquidity && (
          <BeakerIcon className="xxs:w-4 xxs:h-4 text-red-500" />
        )}
      </div>
    </div>
  );
}

export function DurationCounter({
  start,
  finishDate,
  maxBlocks,
}: {
  start: string;
  maxBlocks?: number;
  finishDate?: string; // Optional finishDate
}) {
  const [time, setTime] = useState(
    formatDuration(start, {
      finishDate,
      startWith: 's',
      maxBlocks: maxBlocks ?? 2,
    })
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(
        formatDuration(start, {
          finishDate,
          startWith: 's',
          maxBlocks: maxBlocks ?? 2,
        })
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [start, finishDate]); // Depend on both start and finishDate

  return <>{time}</>;
}

export function PercentageChange({
  change,
  ...rest
}: { change: BigSource } & React.ComponentProps<'span'>) {
  const changeBig = Big(change);

  if (changeBig.eq(0)) return <span>--</span>;

  return (
    <span
      {...rest}
      className={twMerge(
        changeBig.gt(0) ? 'text-green-500' : 'text-red-500',
        rest.className
      )}
    >
      {changeBig.gt(0) ? '+' : ''}
      {changeBig.gte(100) ? (
        <>{changeBig.div(100).plus(1).toFixed(1)}X</>
      ) : (
        <>{formatNumberWithSuffix(changeBig, { precision: 1 })}%</>
      )}
    </span>
  );
}

export function PercentageChangeWithMaxValue({
  change,
  maxValue,
  ...rest
}: { change: BigSource } & {
  maxValue: number;
} & React.ComponentProps<'span'>) {
  const changeBig = Big(change ?? 0);

  if (changeBig.eq(0)) return <span>--</span>;
  const changeBigCapped = changeBig.gt(maxValue) ? Big(maxValue) : changeBig;

  return (
    <span
      {...rest}
      className={twMerge(
        changeBigCapped.gt(0) ? 'text-green-500' : 'text-red-500',
        rest.className
      )}
    >
      {changeBigCapped.gt(0) ? '+' : ''}
      {changeBigCapped.gte(1) ? (
        <>{changeBigCapped.plus(1).toFixed(1)}X</>
      ) : (
        <>
          {formatNumberWithSuffix(changeBigCapped.mul(100), { precision: 1 })}%
        </>
      )}
      {changeBigCapped.eq(Big(maxValue)) ? '+' : ''}
    </span>
  );
}
