import { TelegramChannelsTableSortKeys } from 'api/contracts';
import {
  Periods,
  TELEGRAM_CHANNELS_DEFAULT_FILTERS,
  TelegramChannelsFilterState,
  useTelegramChannelsSearchFilters,
} from '../../../../marketplace/components/usePageParams';
import {
  NumberOfCallsFilter,
  NumberOfRugPullsFilter,
  WinnersVsLosersFilter,
} from '../../../../marketplace/components/filters/TelegramChannelsFilters';
import { isNotNil } from 'modules/utils/isNotNil';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Button from 'modules/shared-components/button/button';
import { isEqual } from 'lodash';
import { twMerge } from 'tailwind-merge';
import {
  SortDropdown,
  SortDropdownProps,
} from '../../../../components/dropdown/sort-dropdown';
import {
  ActivteFiltersBar,
  FilterTag,
  SelectFiltersBar,
} from '../../../../components/filters';
import TextInput from '../../../../components/inputs/text-input/text-input';

export const SORT_OPTIONS = [
  {
    label: 'Number of Calls',
    value: `-${TelegramChannelsTableSortKeys.Calls}`,
  },
  {
    label: 'Hit Ratio',
    value: `-${TelegramChannelsTableSortKeys.WinsVsLoss}`,
  },
  {
    label: 'Highest Max Returns',
    value: `-${TelegramChannelsTableSortKeys.HighestWins}`,
  },
  {
    label: 'Number of Wins',
    value: `-${TelegramChannelsTableSortKeys.Wins}`,
  },
  {
    label: 'Number of Losses',
    value: `-${TelegramChannelsTableSortKeys.Loss}`,
  },
  {
    label: 'Number of Rug-Pulls',
    value: `-${TelegramChannelsTableSortKeys.RugPulls}`,
  },
];

export const PERIOD_OPTIONS = [
  {
    value: '1' as Periods,
    label: 'Last 24 Hours',
  },
  {
    value: '3' as Periods,
    label: 'Last 3 Days',
  },
  {
    value: '7' as Periods,
    label: 'Last Week',
  },
  {
    value: '30' as Periods,
    label: 'Last Month',
  },
];

function checkIfFiltersAreDefault(
  filtersState: TelegramChannelsFilterState
): boolean {
  return isEqual(filtersState, TELEGRAM_CHANNELS_DEFAULT_FILTERS);
}

export const TelegramChannelsFiltersBar = ({
  isCondensed,
}: {
  isCondensed?: boolean;
}) => {
  const { filtersState: filters, setFilters } =
    useTelegramChannelsSearchFilters();

  const updateFilters = (update: Partial<TelegramChannelsFilterState>) => {
    setFilters({ ...filters, offset: 0, ...update });
  };

  const [query, setQuery] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setQuery(inputValue);

    setTimeout(() => {
      if (inputValue) {
        updateFilters({
          search: inputValue,
        });
      } else {
        updateFilters({
          search: undefined,
        });
      }
    }, 250);
  };

  const nOfFiltersActive = [
    filters.search,
    filters.minRugPulls,
    filters.maxRugPulls,
    filters.minNumberOfCalls,
    filters.maxNumberOfCalls,
    filters.minWinnersVsLosers,
    filters.maxWinnersVsLosers,
  ].filter((value) => value !== undefined && value).length;

  const Filters = () => {
    return (
      <ActivteFiltersBar className={isCondensed ? 'xxs:bg-black-700 my-1' : ''}>
        {isNotNil(filters.minNumberOfCalls) ? (
          <FilterTag
            onRemove={() =>
              updateFilters({
                minNumberOfCalls: undefined,
              })
            }
            label={`Calls > ${formatNumberWithSuffix(filters.minNumberOfCalls, {
              suffixes: allSuffixes,
              precision: 0,
            })}`}
          />
        ) : null}
        {isNotNil(filters.search) ? (
          <FilterTag
            onRemove={() =>
              updateFilters({
                search: undefined,
              })
            }
            label={`Search: ${filters.search}`}
          />
        ) : null}
        {isNotNil(filters.maxNumberOfCalls) ? (
          <FilterTag
            onRemove={() =>
              updateFilters({
                maxNumberOfCalls: undefined,
              })
            }
            label={`Calls < ${formatNumberWithSuffix(filters.maxNumberOfCalls, {
              suffixes: allSuffixes,
              precision: 0,
            })}`}
          />
        ) : null}
        {isNotNil(filters.minWinnersVsLosers) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                minWinnersVsLosers: undefined,
              })
            }
            label={`Hit Ratio > ${formatNumberWithSuffix(
              filters.minWinnersVsLosers * 100,
              {
                suffixes: allSuffixes,
                precision: 0,
              }
            )}%`}
          />
        )}
        {isNotNil(filters.maxWinnersVsLosers) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                maxWinnersVsLosers: undefined,
              })
            }
            label={`Hit Ratio < ${formatNumberWithSuffix(
              filters.maxWinnersVsLosers * 100,
              {
                suffixes: allSuffixes,
                precision: 0,
              }
            )}%`}
          />
        )}
        {isNotNil(filters.minRugPulls) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                minRugPulls: undefined,
              })
            }
            label={`Rugs > ${formatNumberWithSuffix(filters.minRugPulls * 100, {
              suffixes: allSuffixes,
              precision: 0,
            })}%`}
          />
        )}
        {isNotNil(filters.maxRugPulls) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                maxRugPulls: undefined,
              })
            }
            label={`Rugs < ${formatNumberWithSuffix(filters.maxRugPulls * 100, {
              suffixes: allSuffixes,
              precision: 0,
            })}%`}
          />
        )}
        {!checkIfFiltersAreDefault(filters) && (
          <Button
            className="py-0 inline-flex items-center xxs:px-1 xxs:rounded-sm xxs:gap-2"
            type="button"
            variant="dexNeutral"
            onClick={() =>
              setFilters(
                TELEGRAM_CHANNELS_DEFAULT_FILTERS as unknown as TelegramChannelsFilterState
              )
            }
          >
            <span className="xxs:text-xs xxs:font-medium xxs:text-dex-white-secondary py-0.5 normal-case">
              Default filters
            </span>
          </Button>
        )}
      </ActivteFiltersBar>
    );
  };
  return (
    <>
      <SelectFiltersBar
        icon={
          <div
            className={twMerge(
              'xxs:p-1 flex xxs:space-x-1 items-center',
              nOfFiltersActive
                ? 'xxs:hover:text-dex-white'
                : ' xxs:hover:text-dex-white xxs:text-dex-white-secondary'
            )}
          >
            <FunnelIcon aria-hidden="true" width={16} height={16} />
            {nOfFiltersActive ? <div>{nOfFiltersActive}</div> : ''}
          </div>
        }
        isCondensed={isCondensed}
        sort={
          <div className="flex gap-x-1">
            <div className="lg:hidden">
              <CallsSort
                value={filters.sort}
                onChange={(value) => {
                  updateFilters({
                    sort: value,
                  });
                }}
              />
            </div>

            <div className="hidden lg:block">
              <TextInput
                name="channelName"
                value={query}
                prefix={
                  <MagnifyingGlassIcon className="w-4 text-dex-white-secondary" />
                }
                onChange={handleInputChange}
                placeholder="Search Channel"
              />
            </div>
          </div>
        }
      >
        <div className="lg:hidden">
          <TextInput
            name="channelName"
            className="w-full"
            value={query}
            prefix={
              <MagnifyingGlassIcon className="w-4 text-dex-white-secondary" />
            }
            onChange={handleInputChange}
            placeholder="Search Channel"
          />
        </div>

        <div
          className={twMerge(
            isCondensed
              ? 'xxs:space-y-2'
              : 'lg:flex lg:space-x-2 xxs:space-y-2 lg:space-y-0'
          )}
        >
          <PeriodFilters
            value={filters.timePeriodInDays?.toString()}
            onChange={(value) => {
              updateFilters({
                timePeriodInDays: value?.toString() as Periods,
              });
            }}
          />
          <NumberOfCallsFilter />
          <NumberOfRugPullsFilter />
          <WinnersVsLosersFilter />
        </div>
      </SelectFiltersBar>
      <div className="">
        <Filters />
      </div>
    </>
  );
};

export function CallsSort(props: Omit<SortDropdownProps<string>, 'options'>) {
  return (
    <SortDropdown
      size="xs"
      className="xxs:py-1.5 xxs:px-2 h-auto"
      {...props}
      options={SORT_OPTIONS}
    />
  );
}

export function PeriodFilters(
  props: Omit<SortDropdownProps<string>, 'options'>
) {
  return (
    <SortDropdown mainLabel={'Period'} {...props} options={PERIOD_OPTIONS} />
  );
}
