import { FC, Fragment, ComponentProps } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { ModalContextProvider, useModal } from './ModalContext';
import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from 'modules/shared-components/button/IconButton';
import config from 'config';
import { BackButton } from 'modules/telegram/BackButton';

interface CustomModalProps {
  size?: keyof typeof SIZE;
  showModal: boolean;
  handleClose: () => void;
  className?: string;
}

const SIZE = {
  normal: 'sm:max-w-lg',
  large: 'sm:max-w-2xl',
  xLarge: 'sm:max-w-4xl',
  xxLarge: 'sm:max-w-6xl',
};

type IModal = FC<CustomModalProps> & {
  Title: FC<ComponentProps<typeof Title>>;
  Body: FC<ComponentProps<'div'>>;
  Content: FC<ComponentProps<'div'>>;
  Footer: FC;
};

const CustomModal: IModal = ({
  showModal,
  size = 'normal',
  handleClose,
  children,
  className,
}) => {
  return (
    <Transition.Root appear show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[50]"
        onClose={() => {
          handleClose?.();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[50] overflow-y-auto">
          <div className="flex xxs:min-h-full xxs:flex-col lg:flex-row justify-center xxs:p-0 sm:p-4 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  'relative transform overflow-hidden rounded-lg xxs:bg-white dark:bg-black-700 text-left shadow-xl transition-all sm:my-8 flex-grow xxs:w-full xxs:h-full xxs:flex xxs:flex-col lg:h-auto',
                  SIZE[size],
                  className
                )}
              >
                <ModalContextProvider close={handleClose}>
                  {children}
                </ModalContextProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface TitleProps {
  textColor?: string;
  bgColor?: string;
  className?: string;
  Icon?: typeof ExclamationCircleIcon | null;
  disableClose?: boolean;
}

const Title: FC<TitleProps> = ({
  textColor,
  bgColor,
  children,
  className,
  Icon,
  disableClose,
}) => {
  const { close } = useModal();
  return (
    <Dialog.Title
      as="h3"
      className={twMerge(
        'text-lg font-medium leading-6 text-black-900 dark:text-white text-left flex justify-start items-center xxs:gap-2',
        className
      )}
    >
      {Icon && (
        <div
          className={classNames(
            'flex h-9 w-9 flex-shrink-0 items-center justify-center rounded-full sm:mx-0',
            bgColor
          )}
        >
          <Icon
            className={classNames('h-8 w-8', textColor)}
            aria-hidden="true"
          />
        </div>
      )}

      {children}
      {!disableClose &&
        (config.isTelegramBrowser ? (
          <BackButton onClick={close} />
        ) : (
          <IconButton onClick={close} className="shrink-0 xxs:p-1 ml-auto">
            <XMarkIcon className="w-6" />
          </IconButton>
        ))}
    </Dialog.Title>
  );
};

const Body: FC<ComponentProps<'div'>> = ({ children, ...props }) => (
  <div
    {...props}
    className={twMerge(
      'xxs:bg-white dark:bg-black-800 xxs:p-4 xxs:pb-4 flex-grow',
      props.className
    )}
  >
    <div className="sm:flex sm:items-start">
      <div className="mt-1 text-center sm:mt-0 sm:text-left xxs:flex-1 w-full">
        {children}
      </div>
    </div>
  </div>
);

const Content: FC<ComponentProps<'div'>> = (props) => (
  <div
    {...props}
    className={twMerge(
      'xxs:mt-2 xxs:text-sm xxs:text-black-500 dark:text-white-100 xxs:text-left space-y-2',
      props.className
    )}
  />
);

const Footer: FC = ({ children }) => {
  return (
    <div
      className={twMerge(
        'bg-black-50 dark:bg-black-700 xxs:px-4 xxs:py-4 flex xxs:flex-col sm:flex-row-reverse xxs:gap-3 sm:px-6',
        config.isTelegramBrowser && 'hidden'
      )}
    >
      {children}
    </div>
  );
};

CustomModal.Title = Title;
CustomModal.Body = Body;
CustomModal.Content = Content;
CustomModal.Footer = Footer;

export default CustomModal;
