import { Children, cloneElement, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export function Marquee({ children }: { children: JSX.Element }) {
  const [isPaused, setIsPaused] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsPaused(false);
    }, 1500);
  }, []);

  return (
    <div className="relative flex overflow-x-hidden whitespace-no-wrap -ml-3.5">
      {Children.toArray(children).map((c: any) => {
        return cloneElement(c, {
          key: 0,
          className: twMerge(
            c.props.className,
            'whitespace-nowrap',
            !isPaused && 'animate-marquee'
          ),
        });
      })}
      {Children.toArray(children).map((c: any) => {
        return cloneElement(c, {
          key: 1,
          className: twMerge(
            c.props.className,
            'whitespace-nowrap absolute top-0',
            !isPaused && 'animate-marquee2'
          ),
        });
      })}
    </div>
  );
}
