import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { formatWithoutDecimals, formatWithPrecision } from 'utils/FormatNumber';
import Tooltip from 'modules/shared-components/tooltip';
import { chainAsset, Chains } from 'api/d-wallets';
import { UserResults } from 'api/leaderboard';
import { performanceStyle } from '../../../research/components/Wallets/columns';
import { DiscordImgComponent } from '../discordImgComponent';
import goldImg from '../../../../../assets/img/trader-levels/gold.png';
import silverImg from '../../../../../assets/img/trader-levels/silver.png';
import bronzeImg from '../../../../../assets/img/trader-levels/bronze.png';
import woodImg from '../../../../../assets/img/trader-levels/wood.png';
import { ChartBarSquareIcon, TrophyIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import Big from 'big.js';

const positionImages: { [key: number]: string } = {
  1: goldImg,
  2: silverImg,
  3: bronzeImg,
  4: woodImg,
  5: woodImg,
};

export const desktopColumns = (chain: Chains): TableColumn<UserResults>[] => {
  function colorFunction(position: number) {
    if (position === 1) {
      return 'text-yellow-500';
    } else if (position === 2) {
      return 'text-black-300';
    } else if (position === 3) {
      return 'text-red-500';
    } else {
      return 'text-dex-white';
    }
  }

  return [
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The user's ranking in the Alpha Program based on realized PnL from Mizar community on their alpha calls.">
            <div>Ranking</div>
          </Tooltip>
        </div>
      ),
      key: 'RANKING',
      component: ({ data }) => (
        <div className="flex items-center">
          <img
            alt={`Ranking ${data.position}`}
            src={positionImages[data.position] || woodImg}
            className="h-[30px] w-[30px]"
          />
        </div>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="Details of the caller, including their username and badges for achievements.">
            <div>Caller</div>
          </Tooltip>
        </div>
      ),
      key: 'USER',
      component: ({ data }) =>
        data.user ? (
          <div className="flex xxs:gap-2 items-center">
            <DiscordImgComponent
              isAlphaGrandMaster={data.user.isAlphaGrandMaster}
              isAlphaInternationalMaster={data.user.isAlphaInternationalMaster}
              isAlphaMaster={data.user.isAlphaMaster}
              imageUrl={data.user.img}
              name={data.user.userName}
            />
            <div className="flex space-x-1 items-center">
              <div
                className={twMerge(
                  'text-sm max-w-[150px] truncate',
                  colorFunction(data.position)
                )}
              >
                {data.user.userName}
              </div>
            </div>
          </div>
        ) : (
          <span>??</span>
        ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The total realized profit in quote currency from the user's alpha calls.">
            <div>Realized PnL ({chainAsset(chain)})</div>
          </Tooltip>
        </div>
      ),
      key: 'REALIZED_PNL_QUOTE',
      component: ({ data }) => (
        <span>
          {performanceStyle(

            data.realizedPnlQuote ?? 0,

            'xxs:text-sm',
            chainAsset(chain)
          )}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The total realized profit in USD from the user's alpha calls.">
            <div>Realized PnL (USD)</div>
          </Tooltip>
        </div>
      ),
      key: 'REALIZED_PNL_USD',
      component: ({ data }) => (

        <span>{performanceStyle(data.realizedPnlUSD ?? 0, 'xxs:text-sm')}</span>

      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The prize awarded to the user in ETH.">
            <div>Prize ({chainAsset(chain)})</div>
          </Tooltip>
        </div>
      ),
      key: 'PRIZE_QUOTE',
      component: ({ data }) => (
        <span>
          {formatWithPrecision(2, Big(data.prizeInQuote ?? 0).toNumber()) ??
            '--'}{' '}
          {chainAsset(chain)}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The prize awarded to the user in USD.">
            <div>Prize (USD)</div>
          </Tooltip>
        </div>
      ),
      key: 'PRIZE_USD',
      component: ({ data }) => (
        <span>
          $
          {formatWithPrecision(2, Big(data.prizeInUSD ?? 0).toNumber()) ?? '--'}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The number of alpha calls made by the user during the program.">
            <div>Number of Alpha</div>
          </Tooltip>
        </div>
      ),
      key: 'NUMBER_OF_ALPHA',
      component: ({ data }) => <span>{data.numberOfAlpha ?? '--'}</span>,
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The realized profit per alpha call made by the user.">
            <div>Realized PnL per Alpha</div>
          </Tooltip>
        </div>
      ),
      key: 'REALIZED_PNL_PER_ALPHA',
      component: ({ data }) => (
        <span>
          {performanceStyle(

            Big(data.realizedPnlPerAlpha ?? 0).toString(),

            'xxs:text-sm',
            chainAsset(chain)
          )}
        </span>
      ),
    },
  ];
};

export const mobileColumns = (chain: Chains): TableColumn<UserResults>[] => {
  return [
    {
      key: 'CALLER',
      component: ({ data }) => (
        <div>
          <div className="flex xxs:space-x-6 items-center ">
            <div className="">
              <img
                alt={`Ranking ${data.position}`}
                src={positionImages[data.position] || woodImg}
                className="h-[35px] w-[35px]"
              />
            </div>
            <div className="xxs:space-y-2 xxs:w-full">
              <div className="xxs:flex justify-between items-center">
                {data.user && (
                  <div className="flex xxs:gap-2 items-center">
                    <DiscordImgComponent
                      isAlphaGrandMaster={data.user.isAlphaGrandMaster}
                      isAlphaInternationalMaster={
                        data.user.isAlphaInternationalMaster
                      }
                      isAlphaMaster={data.user.isAlphaMaster}
                      imageUrl={data.user.img}
                      name={data.user.userName}
                    />
                    <div className="flex space-x-1 items-center">
                      <div className="font-bold text-sm max-w-[150px] truncate">
                        {data.user.userName}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="xxs:text-sm flex space-x-4 items-center">
                <div className="xxs:text-sm flex space-x-1 items-center">
                  <TrophyIcon className="h-4 w-4" />
                  <div className="">
                    {formatWithPrecision(
                      2,
                      Big(data.prizeInQuote ?? 0).toNumber()
                    ) ?? '--'}{' '}
                    {chainAsset(chain)}
                  </div>
                  <div className="xxs:text-xs">
                    $
                    {formatWithoutDecimals(
                      Big(data.prizeInUSD ?? 0).toNumber()
                    ) ?? '--'}
                  </div>
                </div>
                <div className=" flex space-x-1 items-center">
                  <ChartBarSquareIcon className="h-4 w-4" />
                  <div className="">
                    {performanceStyle(

                      data.realizedPnlQuote,

                      'xxs:text-sm',
                      chainAsset(chain)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
};
