/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import type {
  LimitOrder,
  OpenPositionListItem,
  StopLossEntry,
  TakeProfitEntry,
} from 'api/dex-trade';
import moment, { duration } from 'moment';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import { TxStatus } from '../columns';
import Big from 'big.js';
import classNames from 'classnames';
import { PlannedOrderProps } from './PositionDetailsTable';
import Button from 'modules/shared-components/button/button';
import {
  ClockIcon,
  PlayCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import config from '../../../../config';
import { formatApiError } from 'helpers/api/apiErrors';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { chainAsset, Chains } from 'api/d-wallets';
import { isOrderWithRetryFailing, LimitOrderSide } from 'api/dex-trade';
import Tooltip from 'modules/shared-components/tooltip';
import { formatMomentDuration } from 'modules/utils/formatDuration';
import Tag from '../../components/alerts/Tags';

interface PositionDetailsTableProps {
  position: OpenPositionListItem;
}

export const PositionTransactionsList: FC<PositionDetailsTableProps> = ({
  position,
}) => {
  const buyTxs = position.transactions?.filter((tx) => tx.side === 'BUY');
  const sellTxs = position.transactions?.filter((tx) => tx.side === 'SELL');
  const buyLimitOrders = position.limitOrders?.filter(
    (tx) => tx.side === LimitOrderSide.Buy
  );
  const sellLimitOrders = position.limitOrders?.filter(
    (tx) => tx.side === LimitOrderSide.Sell
  );

  const closeTxs = sellTxs.filter(
    (x) => !position.takeProfits.some((tp) => tp.transactionId === x.id)
  );
  const slTx = sellTxs.find(
    (x) => x.id === position.trailingStopLoss?.transactionId
  );

  return (
    <div className="space-y-2">
      <div className="max-w-full grow h-full ">
        <div className="xxs:text-xs xxs:mb-2  xxs:text-dex-white-secondary">
          Buy Order(s)
        </div>
        <div className="xxs:flex xxs:flex-col xxs:gap-2">
          {!!buyTxs && (
            <>
              {buyTxs?.map((level, index) => {
                const limitLevel = buyLimitOrders.find(
                  (x) => x.id === level.id
                );
                const isBuyWithRetryFailing = limitLevel
                  ? isOrderWithRetryFailing(level.status) && limitLevel.retry
                  : false;

                return isBuyWithRetryFailing && limitLevel ? (
                  <PlannedLimitOrderRowMobile
                    key={limitLevel.id}
                    chain={position.chain}
                    level={limitLevel}
                    name="Buy Limit"
                    retry={limitLevel.retry}
                    tokenSymbol={position.tokenSymbol}
                  />
                ) : (
                  <LevelRowMobile
                    tokenSymbol={position.tokenSymbol}
                    name="Buy"
                    level={level}
                    key={index}
                  />
                );
              })}
              {buyLimitOrders.length
                ? buyLimitOrders.map((level, index) => {
                    const limitLevel = buyTxs.find(
                      (x) => x.id === level.transactionId
                    );
                    return !limitLevel ? (
                      <PlannedLimitOrderRowMobile
                        key={level.id}
                        chain={position.chain}
                        level={level}
                        name="Buy Limit"
                        retry={level.retry}
                        tokenSymbol={position.tokenSymbol}
                      />
                    ) : (
                      ''
                    );
                  })
                : ''}
            </>
          )}
        </div>
      </div>
      {!!closeTxs.length && (
        <div className="max-w-full grow h-full">
          <div className="xxs:text-xs xxs:mb-2  xxs:text-dex-white-secondary">
            Sell Order(s)
          </div>
          <div className="xxs:flex xxs:flex-col xxs:gap-2">
            {closeTxs?.map((level, index) => (
              <LevelRowMobile
                tokenSymbol={position.tokenSymbol}
                name="Sell"
                level={level}
                key={index}
              />
            ))}
            {sellLimitOrders.length
              ? sellLimitOrders.map((level) => {
                  const limitLevel = sellTxs.find(
                    (x) => x.id === level.transactionId
                  );
                  return !limitLevel ? '' : '';
                })
              : ''}
          </div>
        </div>
      )}
      {!!position.takeProfits.length && (
        <div className="max-w-full grow h-full ">
          <div className="xxs:text-xs xxs:mb-2  xxs:text-dex-white-secondary">
            Take Profit
          </div>
          <div className="xxs:flex xxs:flex-col xxs:gap-2">
            {position.takeProfits.map((level, index) => {
              const tpLevel = sellTxs.find((x) => x.id === level.transactionId);
              const isTpWithRetryFailing = tpLevel
                ? isOrderWithRetryFailing(tpLevel.status) && level.retry
                : false;

              if (tpLevel && !isTpWithRetryFailing) {
                return (
                  <LevelRowMobile
                    key={level.id}
                    tokenSymbol={position.tokenSymbol}
                    name="Take Profit"
                    level={tpLevel}
                  />
                );
              }
              return (
                <PlannedTakeProfitOrderRowMobile
                  key={level.id}
                  level={level}
                  name="Take Profit"
                  chain={position.chain}
                  retry={level.retry}
                  tokenSymbol={position.tokenSymbol}
                />
              );
            })}
          </div>
        </div>
      )}
      {!!position.trailingStopLoss && (
        <div className="max-w-full grow h-full ">
          <div className="xxs:text-xs xxs:mb-2  xxs:text-dex-white-secondary">
            Stop Loss
          </div>
          {slTx ? (
            <LevelRowMobile
              tokenSymbol={position.tokenSymbol}
              name="Stop Loss"
              level={slTx}
            />
          ) : (
            <PlannedStopLossOrderRowMobile
              name="Stop Loss"
              chain={position.chain}
              tokenSymbol={position.tokenSymbol}
              retry={position.trailingStopLoss.retry}
              level={position.trailingStopLoss}
            />
          )}
        </div>
      )}
    </div>
  );
};

const PlannedOrder = ({
  amountIn,
  amountOut,
  type,
  tokenSymbol,
  priceInUsd,
  marketCap,
  remainingBeforeExpirationInSeconds,
  deviation,
  retry,
  name,
  chain,
  id,
}: PlannedOrderProps) => {
  const { addNewMessage } = useSnackBar();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: DexTradeService.cancelLimitOrder,
    onSuccess: () => {
      addNewMessage({
        type: 'success',
        title: 'Success',
        message: 'Your limit order has been successfully cancelled.',
      });
      void queryClient.invalidateQueries(dexTradeKeys.all());
    },
    onError: (err) => {
      if (config.isTelegramBrowser) {
        addNewMessage({
          message: formatApiError(
            err,
            'There was an error while cancelling your order. Try again.'
          ),
          title: 'Error',
          type: 'error',
        });
      } else {
        addNewMessage({
          type: 'error',
          title: 'Error',
          message: 'There was an error while cancelling your order. Try again.',
        });
      }
    },
  });
  return (
    <div className="flex justify-between items-center">
      <div className="">
        <div className="space-x-1 flex items-center">
          <div className="text-xs">{name}</div>
          {retry ? (
            <Tooltip text="Auto-retry ON">
              <PlayCircleIcon className="text-green-500 xxs:w-4 xxs:h-4" />
            </Tooltip>
          ) : (
            <div />
          )}
          <div className="flex space-x-1">
            <Tag type="warning">Planned</Tag>
            {remainingBeforeExpirationInSeconds && (
              <Tooltip
                text={`This limit order will expire and get cancelled in ${formatMomentDuration(
                  duration(remainingBeforeExpirationInSeconds, 'seconds')
                )}`}
              >
                <div className="bg-dex-black-600 rounded xxs:p-1 xxs:text-xs flex space-x-0.5 items-center">
                  <ClockIcon className="w-3 h-3" />
                  <div>
                    {formatMomentDuration(
                      duration(remainingBeforeExpirationInSeconds, 'seconds')
                    )}
                  </div>
                </div>
              </Tooltip>
            )}
            {id && (
              <Button
                loading={isLoading}
                onClick={() => {
                  mutate(id);
                }}
                className="bg-red-500 text-white xxs:p-0.5"
              >
                <TrashIcon className="w-4 h-4" />
              </Button>
            )}
          </div>
        </div>
        <div className="flex space-x-1 items-center">
          <div className="text-xs">
            {!amountIn
              ? '--'
              : type === 'buy'
              ? `${formatNumberWithSuffix(amountIn || 0)} ${chainAsset(chain)}`
              : `${formatNumberWithSuffix(amountIn || 0)} ${tokenSymbol}`}{' '}
          </div>
          <div>|</div>
          <div className="text-xs">
            {!amountOut
              ? '--'
              : type === 'buy'
              ? `${formatNumberWithSuffix(amountOut || 0)} ${tokenSymbol}`
              : `${formatNumberWithSuffix(amountOut || 0)} ${chainAsset(
                  chain
                )}`}{' '}
          </div>
        </div>
      </div>
      <div className="">
        <div className="space-y-1 text-right">
          {priceInUsd || marketCap ? (
            <span>
              {' '}
              {priceInUsd ? (
                <div>
                  P: ${formatNumberWithSuffix(priceInUsd)}{' '}
                  <span
                    className={classNames(
                      'text-xs',
                      deviation && Big(deviation ?? 0).gt(0)
                        ? 'text-green-500'
                        : 'text-red-500'
                    )}
                  >
                    {deviation ? (
                      <span>
                        {deviation && Big(deviation).gt(0) ? '+' : ''}
                        {Big(deviation).times(100).toFixed(1)}%
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              ) : (
                '--'
              )}
              {marketCap ? (
                <div>MC: ${formatNumberWithSuffix(marketCap)}</div>
              ) : (
                '--'
              )}
            </span>
          ) : (
            '--'
          )}
        </div>
      </div>
    </div>
  );
};

const PlannedLimitOrderRowMobile: FC<{
  name: string;
  chain: Chains;
  level: LimitOrder;
  tokenSymbol: string;
  retry: boolean;
}> = ({ name, level, tokenSymbol, chain, retry }) => {
  return (
    <div
      key={level.id}
      className="xxs:bg-dex-black-800 flex flex-col xxs:gap-1 xxs:p-2"
    >
      <PlannedOrder
        tokenSymbol={tokenSymbol}
        chain={chain}
        retry={retry}
        amountOut={level.amountOut}
        amountIn={level.amountIn}
        priceInUsd={level.priceInUsd}
        marketCap={level.marketCap}
        remainingBeforeExpirationInSeconds={
          level.remainingBeforeExpirationInSeconds
        }
        id={level.id}
        deviation={
          level.priceInUsd ? undefined : level.priceDeviationPercentage
        }
        name={name}
        type="buy"
      />
    </div>
  );
};

const PlannedStopLossOrderRowMobile: FC<{
  name: string;
  level: StopLossEntry;
  tokenSymbol: string;
  chain: Chains;
  retry: boolean;
}> = ({ level, name, tokenSymbol, chain, retry }) => {
  return (
    <div
      key={level.id}
      className="xxs:bg-dex-black-800 flex flex-col xxs:gap-1 xxs:p-2"
    >
      <PlannedOrder
        tokenSymbol={tokenSymbol}
        amountOut={level.amountOut}
        retry={retry}
        amountIn={level.amountIn}
        priceInUsd={level.priceInUsd}
        chain={chain}
        marketCap={level.marketCap}
        deviation={level.stopLossTriggerDeviation}
        name={name}
        type="sell"
      />
    </div>
  );
};

const PlannedTakeProfitOrderRowMobile: FC<{
  name: string;
  level: TakeProfitEntry;
  tokenSymbol: string;
  chain: Chains;
  retry: boolean;
}> = ({ name, level, tokenSymbol, chain, retry }) => {
  return (
    <div
      key={level.id}
      className="xxs:bg-dex-black-800 flex flex-col xxs:gap-1 xxs:p-2"
    >
      <PlannedOrder
        tokenSymbol={tokenSymbol}
        amountOut={level.amountOut}
        retry={retry}
        amountIn={level.amountIn}
        priceInUsd={level.priceInUsd}
        marketCap={level.marketCap}
        chain={chain}
        deviation={level.priceIncreasePercentage}
        name={name}
        type="sell"
      />
    </div>
  );
};

interface LevelRowMobileProps {
  level: OpenPositionListItem['transactions'][number];
  name?: string;
  tokenSymbol: string;
}

const LevelRowMobile: FC<LevelRowMobileProps> = ({
  level,
  name,
  tokenSymbol,
}) => {
  if (level.failureReason) {
    return (
      <div
        key={level.id}
        className="xxs:bg-dex-black-800 flex flex-col xxs:gap-1 xxs:p-2"
      >
        <div className="flex justify-between">
          <div className="xxs:text-sm space-y-1">
            <div className="space-x-2">
              <span className="text-xs">{name}</span>
              <TxStatus transaction={level} />
            </div>
          </div>
          {level.transactionHash && (
            <div className="flex xxs:gap-0.5">
              Tx:{' '}
              <EthAddress
                address={level.transactionHash}
                chain={level.chain as Chains}
                kind="tx"
                precision={2}
              />
            </div>
          )}
        </div>
        <div className="text-red-500 overflow-x-scroll">
          Error: {level.failureReason}
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        key={level.id}
        className="xxs:bg-dex-black-800 flex flex-col xxs:gap-1 xxs:p-2"
      >
        <div className="flex justify-between items-center">
          <div className="">
            <div className="xxs:text-sm space-y-1">
              <div className="space-x-2">
                <span className="text-xs">{name}</span>
                <TxStatus transaction={level} />
              </div>
            </div>
            <div className="flex space-x-1 items-center">
              <div className="text-xs">
                {!level.amountIn
                  ? '--'
                  : level.side === 'BUY'
                  ? `${formatNumberWithSuffix(level.amountIn || 0)} ETH`
                  : `${formatNumberWithSuffix(
                      level.amountIn || 0
                    )} ${tokenSymbol}`}{' '}
              </div>
              <div>|</div>
              <div className="text-xs">
                {!level.amountOut
                  ? '--'
                  : level.side === 'BUY'
                  ? `${formatNumberWithSuffix(
                      level.amountOut || 0
                    )} ${tokenSymbol}`
                  : `${formatNumberWithSuffix(level.amountOut || 0)} ETH`}{' '}
              </div>
            </div>
          </div>
          <div className="">
            <div className="space-y-1">
              <div className="text-xs">
                {level.transactionHash && (
                  <div className="flex xxs:gap-0.5 justify-end xxs:text-dex-white-secondary">
                    Tx:{' '}
                    <EthAddress
                      address={level.transactionHash}
                      kind="tx"
                      chain={level.chain as Chains}
                      precision={2}
                    />
                  </div>
                )}
              </div>
              <div>
                {level.blockDatetime ? (
                  <div className=" xxs:text-dex-white-secondary">
                    <span>
                      {moment(level.blockDatetime).format('DD/MM/YY HH:mm:ss')}{' '}
                    </span>{' '}
                  </div>
                ) : (
                  <>--</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
