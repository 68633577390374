import { Children, cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';

export function StackedIcons(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={twMerge('xxs:flex -space-x-2  isolate', props.className)}
    >
      {Children.toArray(props.children).map((c: any, index) => {
        return cloneElement(c, {
          className: twMerge(
            'xxs:relative xxs:inline-block xxs:bg-white xxs:ring-1 xxs:ring-white-600 dark:ring-black-600 xxs:rounded-full',
            c.props.className
          ),
          style: {
            zIndex: 40 - index,
          },
        });
      })}
    </div>
  );
}
