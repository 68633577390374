import { ContractInfo } from 'api/contracts';
import Big from 'big.js';
import { groupBy, isNil, sortBy } from 'lodash/fp';

import {
  NotificationDex,
  NotificationProps,
} from '../../../../components/alerts/notification';
import { maxBig } from 'modules/utils/big';
import {
  ExpandableCardContent,
  SubCard,
  SubCardTitle,
} from '../../../../components/cards';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type Props = {
  contract: ContractInfo;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
} & React.ComponentProps<typeof SubCard>;

const notificationPriority = {
  error: 0,
  warning: 1,
  success: 2,
  info: 3,
};

export function getNotifications(contract: ContractInfo) {
  const notifications = sortBy(
    (x) => notificationPriority[x.type],
    toSecurityAlerts(contract)
  );

  const {
    error: errorNotifications = [],
    warning: warningNotifications = [],
    success: successNotifications = [],
    info: infoNotifications = [],
  } = groupBy((x) => x.type, notifications);

  return {
    notifications,
    errorNotifications,
    warningNotifications,
    successNotifications,
    infoNotifications,
  };
}

export function SecurityAlerts({ contract }: Props) {
  const { notifications } = getNotifications(contract);

  return (
    <MobileExpandableCard
      mobileLabel="Security Checks"
      trigger={
        <MobileExpandCardTrigger>
          <div className="flex xxs:space-x-2 items-center">
            <div>Security Checks</div>
            <SecurityChecksSummary contract={contract} />
          </div>
        </MobileExpandCardTrigger>
      }
    >
      <div className="xxs:py-2 lg:py-0 xxs:px-2 xxs:space-y-2">
        <SubCardTitle
          size="sm"
          className="flex xxs:space-x-2 xxs:text-base xxs:font-normal items-center"
        >
          <div className="xxs:text-dex-white-secondary">Security Checks</div>{' '}
          <SecurityChecksSummary contract={contract} />
        </SubCardTitle>
        <ExpandableCardContent className="flex flex-col xxs:gap-2">
          {notifications.map(({ type, message }) => (
            <NotificationDex
              className="xxs:text-base xxs:p-2"
              variant="neutral"
              type={type}
              key={message}
            >
              <span className="">{message}</span>
            </NotificationDex>
          ))}
        </ExpandableCardContent>
      </div>
    </MobileExpandableCard>
  );
}

function SecurityChecksSummary({ contract }: { contract: ContractInfo }) {
  const {
    errorNotifications,
    infoNotifications,
    successNotifications,
    warningNotifications,
  } = getNotifications(contract);
  return (
    <div className="flex xxs:gap-1 mr-auto xxs:mx-2">
      {!!errorNotifications.length && (
        <NotificationDex className="xxs:m-0" size="xSmall" type="error">
          {errorNotifications.length}
        </NotificationDex>
      )}
      {!!warningNotifications.length && (
        <NotificationDex className="xxs:m-0" size="xSmall" type="warning">
          {warningNotifications.length}
        </NotificationDex>
      )}
      {!!successNotifications.length && (
        <NotificationDex className="xxs:m-0" size="xSmall" type="success">
          {successNotifications.length}
        </NotificationDex>
      )}
      {!!infoNotifications.length && (
        <NotificationDex className="xxs:m-0" size="xSmall" type="info">
          {infoNotifications.length}
        </NotificationDex>
      )}
    </div>
  );
}

export function toSecurityAlerts(
  contract: ContractInfo
): { type: Exclude<NotificationProps['type'], 'flat'>; message: string }[] {
  const totalLiquidity =
    maxBig(
      contract.liquidityInfo.activePools.map((x) => Big(x.poolLiquidity || 0))
    ) || Big(0);

  return [
    ...(contract.tradingInfo.honeypot === true
      ? [{ type: 'error' as const, message: 'Honeypot' }]
      : contract.tradingInfo.honeypot === false
      ? [{ type: 'success' as const, message: 'No Honeypot' }]
      : [{ type: 'warning' as const, message: 'Honeypot Unknown' }]),
    isNil(contract.tradingInfo.sellTax) || isNil(contract.tradingInfo.buyTax)
      ? { type: 'warning', message: 'Unknown Taxes' }
      : Big(contract.tradingInfo.sellTax).eq(0) &&
        Big(contract.tradingInfo.buyTax).eq(0)
      ? { type: 'success', message: 'Tax Free' }
      : Big(contract.tradingInfo.sellTax).times(100).gt(10) ||
        Big(contract.tradingInfo.buyTax).times(100).gt(10)
      ? { type: 'error', message: 'High Taxes' }
      : { type: 'success', message: 'Low Taxes' },
    ...(Big(contract.ownerInfo.ownerPercent || 0)
      .times(100)
      .gt(10) ||
    Big(contract.ownerInfo.creatorPercent || 0)
      .times(100)
      .gt(10)
      ? [{ type: 'error' as const, message: 'Token Controlled' }]
      : []),
    ...(contract.liquidityInfo.activePools.length === 0
      ? [{ type: 'info' as const, message: 'No Liquidity' }]
      : totalLiquidity.lt(25000)
      ? [{ type: 'error' as const, message: 'Low Liquidity' }]
      : totalLiquidity.gt(1000000)
      ? [{ type: 'success' as const, message: 'High Liquidity' }]
      : []),
    contract.ownerInfo.ownerAddress === emptyAddress
      ? { type: 'success' as const, message: 'Renounced Contract' }
      : { type: 'error' as const, message: 'Contract Not Renounced' },
    ...(isNil(contract.ownerInfo.ownerAddress)
      ? [{ type: 'info' as const, message: 'No Owner Contract' }]
      : []),
    ...(contract.methodsInfo.methods.length
      ? contract.methodsInfo.methods.find((x) => x.name === 'mint')
        ? [{ type: 'info' as const, message: 'Mintable' }]
        : [{ type: 'info' as const, message: 'Not Mintable' }]
      : []),
    contract.generalInfo.isVerified
      ? { type: 'success' as const, message: 'Contract Verified' }
      : { type: 'error' as const, message: 'Contract Not Verified' },
  ];
}

const emptyAddress = '0x'.padEnd(42, '0');
