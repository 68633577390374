import ErrorBoundary from 'components/error-boundaries';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { chainName, Chains, dexName, Protocols } from 'api/d-wallets';
import {
  ChainsIcon,
  ExchangeIconStretch,
} from 'modules/shared-components/exchange/exchange-icon';
import Tooltip from 'modules/shared-components/tooltip';

type TokenIconProps = {
  className?: string;
  address: string;
  symbol?: string | undefined;
  chain: Chains | undefined;
  protocol?: Protocols | undefined;
  tokenName: string | undefined;
  size?: 'lg' | 'sm';
};

export function TokenIconWithChain({
  address,
  symbol,
  chain,
  protocol,
  size,
  tokenName,
  ...rest
}: TokenIconProps & React.ComponentProps<'img'>) {
  const [imageExists, setImageExists] = useState(false);

  const checkImage = (url: string) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  const baseUrl = 'https://cdnassets.mizar.com/blockchains/';
  const chainPath =
    chain === Chains.Ethereum
      ? 'ethereum'
      : chain === Chains.Base
      ? 'base'
      : 'bsc';
  const imgUrl = `${baseUrl}${chainPath}/assets/${address}/logo.png`;

  useEffect(() => {
    void checkImage(imgUrl).then((exists) => {
      if (exists) {
        setImageExists(true);
      } else {
        setImageExists(false);
      }
    });
  }, [address, chain]);

  const text = `${symbol ?? tokenName ?? '?'}${
    protocol ? ` on ${dexName(protocol)} | ` : ' | '
  }${chainName(chain ?? Chains.Ethereum)}`;

  return (
    <ErrorBoundary>
      <Tooltip text={text}>
        <div className="relative ">
          {!imageExists ? (
            <div
              className={twMerge(
                'xxs:rounded-full dark:bg-dex-black-700 xxs:border-solid xxs:border dark:border-dex-black-600 p-[1px] flex items-center justify-center text-[14px] leading-none',
                rest.className
              )}
            >
              {!symbol ? '?' : symbol?.trim()?.[0]}
            </div>
          ) : (
            <img
              {...rest}
              alt={`token logo ${address}`}
              className={twMerge(
                'xxs:rounded-full dark:bg-white p-[1px] ',
                rest.className
              )}
              src={`https://cdnassets.mizar.com/blockchains/${chainPath}/assets/${address}/logo.png`}
            />
          )}
          {protocol && (
            <ExchangeIconStretch
              imgName={protocol}
              className={twMerge(
                size === 'lg'
                  ? 'w-4 h-4  -top-1 -right-1'
                  : 'w-3 h-3  -top-0.5 -right-0.5',
                'absolute'
              )}
            />
          )}
          {chain && (
            <ChainsIcon
              imgName={chain}
              className={twMerge(
                size === 'lg'
                  ? 'w-4 h-4 -bottom-1 -right-1'
                  : 'w-3 h-3 -bottom-0.5 -right-0.5',
                'absolute'
              )}
            />
          )}
        </div>
      </Tooltip>
    </ErrorBoundary>
  );
}
