import { RadioGroup, RadioGroupProps } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { BaseButton } from '../button/base-button';
import { DexSwitchMode } from 'modules/DexSwitch/DexSwitchContext';

type Props = RadioGroupProps<'div', string> & {
  options: { name: string; value: string }[];
  mode: DexSwitchMode.Cex | DexSwitchMode.Dex;
};

export function ToggleButton({
  onChange,
  value,
  options,
  mode,
  ...rest
}: Props) {
  const mainClass =
    mode === DexSwitchMode.Cex
      ? 'flex flex-row xxs:border-black-100 dark:border-black-600 xxs:border-solid xxs:border xxs:rounded'
      : 'flex flex-row ';
  const checkedClass =
    mode === DexSwitchMode.Cex
      ? 'xxs:bg-white-500 dark:bg-black-600 xxs:font-semibold'
      : 'xxs:bg-dex-black-700 xxs:font-normal !text-dex-white';
  const uncheckedClass =
    mode === DexSwitchMode.Cex
      ? 'xxs:font-normal'
      : 'xxs:bg-dex-black-800 xxs:font-semibold xxs:text-dex-white-secondary hover:text-dex-white';

  return (
    <RadioGroup value={value} onChange={onChange} {...rest}>
      <div className={mainClass}>
        {options.map((option, i) => (
          <RadioGroup.Option
            key={i}
            value={option.value || null}
            as={BaseButton}
            className={({ active, checked }) =>
              twMerge(
                'xxs:px-3 xxs:py-2 xxs:text-sm',
                active ? '' : '',
                mode === DexSwitchMode.Dex
                  ? i === 0
                    ? 'xxs:rounded-l-md'
                    : 'xxs:rounded-r-md'
                  : '',
                checked ? checkedClass : uncheckedClass
              )
            }
          >
            <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
