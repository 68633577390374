import { CheckIcon } from '@heroicons/react/24/solid';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { BaseButton } from 'modules/shared-components/button/base-button';

export type Option<T extends string | number> = {
  value: T;
  label: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
};

export function SelectListOption<T extends number | string>({
  value,
  disabled,
  icon,
  label,
}: Option<T>) {
  return (
    <Listbox.Option
      value={value}
      disabled={disabled}
      className={({ active, disabled: listboxDisabled }) =>
        classNames(
          'relative select-none xxs:py-2 xxs:pl-3 xxs:pr-9',
          active
            ? 'bg-mizar-blue-600 text-white'
            : 'text-gray-900 xs:dark:text-white-600 ',
          listboxDisabled ? 'opacity-50 cursor-default' : 'cursor-pointer'
        )
      }
    >
      {({ active, selected }) => (
        <>
          <div className="flex items-center">
            {icon}
            <span
              className={classNames(
                'xxs:ml-3 truncate dark:text-white',
                selected && 'font-semibold'
              )}
            >
              {label}
            </span>
          </div>

          {selected && (
            <span
              className={classNames(
                'absolute inset-y-0 right-0 flex items-center xxs:pr-4 transition-colors duration-100 ease-in-out',
                active ? 'text-white' : 'text-mizar-blue-600'
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Listbox.Option>
  );
}

export function MobileOption<T extends string | number>(
  props: React.ComponentProps<typeof BaseButton> & Option<T>
) {
  return (
    <BaseButton
      {...props}
      className={twMerge(
        'flex items-center xxs:gap-2 xxs:p-4 dark:text-white-50 w-full xxs:border-0 truncate',
        props.className
      )}
    >
      {props.icon}
      {props.label}
    </BaseButton>
  );
}
