import { getAssetsUrl } from '../../../utils/urlUtils';
import ErrorBoundary from '../../../components/error-boundaries';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';

type AssetIconProps = { className?: string; imgName: string };

export default function AssetIcon({ className, imgName }: AssetIconProps) {
  return (
    <ErrorBoundary>
      <span
        className={twMerge(
          'h-6 w-6 flex-shrink-0 xxs:rounded-sm flex justify-center',
          className
        )}
      >
        <img
          alt={`coin_logo_${imgName}`}
          src={
            getAssetsUrl() +
            '/static/img/cryptocurrencies/icons/' +
            imgName?.toLowerCase?.() +
            '.png'
          }
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = require('assets/img/cryptocurrencies/icons/generic.png');
          }}
          className="h-6 p-0.5"
        />
      </span>
    </ErrorBoundary>
  );
}

export function AssetIconNew({ className, imgName }: AssetIconProps) {
  return (
    <ErrorBoundary>
      <img
        alt={`coin_logo_${imgName}`}
        src={
          getAssetsUrl() +
          '/static/img/cryptocurrencies/icons/' +
          imgName?.toLowerCase?.() +
          '.png'
        }
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = require('assets/img/cryptocurrencies/icons/generic.png');
        }}
        className={classNames(
          className,
          'h-4 w-4 flex-shrink-0 xxs:rounded-sm flex justify-center'
        )}
      />
    </ErrorBoundary>
  );
}

export function AssetIconStretch({
  imgName,
  ...rest
}: AssetIconProps & React.ComponentProps<'img'>) {
  return (
    <ErrorBoundary>
      <img
        alt={`coin_logo_${imgName}`}
        src={
          getAssetsUrl() +
          '/static/img/cryptocurrencies/icons/' +
          imgName?.toLowerCase?.() +
          '.png'
        }
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = require('assets/img/cryptocurrencies/icons/generic.png');
        }}
        {...rest}
      />
    </ErrorBoundary>
  );
}
