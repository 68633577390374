import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from 'modules/shared-components/tooltip';
import { twMerge } from 'tailwind-merge';

interface StyledLabelProps {
  name?: string;
  label: string;
  className?: string;
  tooltip?: string;
  isLabelHidden?: boolean;
  inputSize?: string;
  isError?: boolean | undefined;
}

const StyledLabel = ({
  name,
  label,
  className,
  tooltip,
  isLabelHidden,
  inputSize,
  isError,
  ...props
}: StyledLabelProps) => (
  <label
    htmlFor={name}
    className={twMerge(
      'block flex items-center ',
      isLabelHidden && 'sr-only',
      isError ? 'xxs:text-red-500' : 'xxs:text-dex-white-secondary',
      inputSize === 'xs'
        ? 'xxs:text-sm lg:text-xs space-x-0.5 font-normal'
        : 'xxs:text-sm font-medium space-x-1',
      className
    )}
    {...props}
  >
    {isError && (
      <ExclamationCircleIcon className="xxs:text-red-500 xxs:w-4 h-4" />
    )}

    {tooltip ? (
      <Tooltip text={tooltip}>
        <div
          className={
            inputSize === 'xs'
              ? 'flex space-x-1 lg:space-x-0.5 items-center'
              : 'flex space-x-1 items-center'
          }
        >
          <div
            className={
              inputSize === 'xs'
                ? 'xxs:text-sm lg:text-xs hover:text-dex-white'
                : 'xxs:text-sm hover:text-dex-white'
            }
          >
            {label}
          </div>
        </div>
      </Tooltip>
    ) : (
      <div
        className={
          inputSize === 'xs' ? 'xxs:text-sm lg:text-xs' : 'xxs:text-sm'
        }
      >
        {label}
      </div>
    )}
  </label>
);

export default StyledLabel;
