import { PropsWithChildren, useState } from 'react';
import { Menu as HMenu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { COLORS_DICTIONARY } from '../../../../../modules/shared-components/colorsDictionary';
import { BaseButton } from '../../../../../modules/shared-components/button/base-button';
import { twMerge } from 'tailwind-merge';

export interface MenuItemProps {
  href?: string;
  onClick?: () => void;
}

export interface MenuProps {
  dropdownPlacement?: Placement;
  color?: keyof typeof COLORS_DICTIONARY;
  className?: string;
  size?: 'medium' | 'large';
}

export default function Menu({
  children,
  dropdownPlacement = 'bottom',
  color,
  className,
  size = 'medium',
}: PropsWithChildren<MenuProps>) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { update, styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: dropdownPlacement,
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -8],
          },
        },
      ],
    }
  );

  return (
    <HMenu as="div" className="relative inline-block text-left">
      <div>
        <HMenu.Button
          ref={setReferenceElement}
          onClick={() => {
            void update?.();
          }}
          className={twMerge(
            'xxs:flex xxs:items-center xxs:rounded xxs:border-none focus:outline-none focus:ring-0 xxs:p-1',
            color && COLORS_DICTIONARY[color],
            className
          )}
        >
          <span className="sr-only">Expand</span>
          <EllipsisVerticalIcon
            className={classNames(
              'h-5 w-5',
              color && COLORS_DICTIONARY[color],
              size === 'large' && 'h-6 w-6'
            )}
            aria-hidden="true"
          />
        </HMenu.Button>
      </div>

      <HMenu.Items
        style={styles.popper}
        {...attributes.popper}
        ref={setPopperElement}
        className={classNames(
          'z-10 mt-2 w-56 rounded-md border-tailwind border-solid xxs:border-dex-black-700 xxs:bg-dex-black-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        )}
      >
        <div className="py-1">{children}</div>
      </HMenu.Items>
    </HMenu>
  );
}

Menu.Item = ({ href, onClick, children }: PropsWithChildren<MenuItemProps>) => (
  <HMenu.Item>
    <Link
      to={href || '#'}
      onClick={onClick}
      className={classNames(
        'xxs:bg-dex-black-700 hover:bg-dex-black-600 xxs:text-dex-white-secondary hover:text-dex-white',
        'xxs:px-4 xxs:py-2 text-sm xxs:flex xxs:gap-2 items-center'
      )}
    >
      {children}
    </Link>
  </HMenu.Item>
);

Menu.ButtonItem = ({ onClick, children }: PropsWithChildren<MenuItemProps>) => (
  <HMenu.Item>
    <BaseButton
      onClick={onClick}
      type="button"
      className={classNames(
        'xxs:bg-dex-black-700 hover:bg-dex-black-600 xxs:text-dex-white-secondary hover:text-dex-white',
        'xxs:px-4 xxs:py-2 text-sm xxs:flex xxs:gap-2 items-center w-full'
      )}
    >
      {children}
    </BaseButton>
  </HMenu.Item>
);
