import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ManualSwapParams, OpenPositionListItem } from 'api/dex-trade';
import {
  blockchainInfoKeys,
  BlockchainInfoService,
} from 'api/services/BlockchainInfoService';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/DWalletService';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import Big from 'big.js';
import {
  GasEstimation,
  useCalculateGasCostInEther,
} from 'modules/ethereum/EstimatedGasFee';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { Button } from 'modules/shared-components/button/button';
import { duration } from 'moment';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  formatNumberWithSuffix,
  formatUSDAmount,
  formatWithAutoPrecision,
} from 'utils/FormatNumber';
import { parseEther, parseGwei } from 'viem';
import * as yup from 'yup';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { WarningLowBalanceMessage } from 'modules/shared-components/notification/walletLowBalance';
import {
  chainAsset,
  ChainAssets,
  chainHasMemPool,
  Chains,
  minimumSafeAmount,
} from 'api/d-wallets';
import { useRefreshIntervalPersistedState } from '../side-panels/SnipesPanel';
import { NotificationDex } from '../components/alerts/notification';
import NumberInput from '../components/inputs/number-input';
import Checkbox from '../components/inputs/Checkbox';
import CustomModal from '../components/modal/CustomModal';
import { DexPanelButton } from '../components/tabs';

interface AddFundsProps {
  onCancel: () => void;
  position: OpenPositionListItem;
}

const gasFeeSchema = yup
  .number()
  .required('Required')
  .min(0.01, 'Must be above 0.01 gwei');

const amountSchema = yup
  .number()
  .min(0.0001, 'Must be above 0.0001')
  .required('Required');

const schema = yup.object({
  type: yup.string().oneOf(['market', 'limit']).required(),
  currentPrice: yup.string().required('Required').typeError('Required'),
  price: yup
    .mixed()
    .when(['type', 'currentPrice'], ([type, currentPrice], s) => {
      if (type === 'limit') {
        return yup
          .string()
          .required('Required')
          .typeError('Required')
          .test(
            'price-lt-currentPrice',
            'Price must be less than current price',
            function (value) {
              return value && currentPrice
                ? parseFloat(value) <= parseFloat(currentPrice)
                : true;
            }
          );
      }
      return s.nullable().notRequired();
    }),
  expirationInHours: yup
    .mixed()
    .when(['type'], ([type], s) => {
      if (type === 'limit') {
        return yup
          .number()
          .required('Required')
          .typeError('Required')
          .min(0.01, 'Expiration time must be at least 0.01 hours')
          .max(720, 'Expiration time must be at most 720 hours');
      }
      return s.nullable().notRequired();
    })
    .default(12),
  maxSlippage: yup.number().required('Required').typeError('Required'),
  antiMev: yup.boolean().default(true),
  maxPriorityFeePerGasWei: yup
    .number()
    .typeError('Required')
    .min(0.01, 'Must be at least 0.01 gwei')
    .required('Required'),
  amount: amountSchema,
});

type FormValues = yup.InferType<typeof schema>;

export const AddFunds: FC<AddFundsProps> = ({ onCancel, position }) => {
  const { addNewMessage } = useSnackBar();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState('market');
  const [, setRefetchInterval] = useRefreshIntervalPersistedState();
  const currentPrice = position.currentTokenPriceInUsd;
  const { data: wallet, isLoading: isLoadingWallets } = useQuery({
    queryFn: () => DWalletService.getWalletWithoutTokens(position.vaultId),
    queryKey: dWalletServiceKeys.getWalletWithoutTokens(position.vaultId),
    staleTime: duration('15', 'seconds').asMilliseconds(),
    cacheTime: duration('15', 'seconds').asMilliseconds(),
  });
  const { data: prices, isLoading: isLoadingPrices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    staleTime: duration('15', 'seconds').asMilliseconds(),
    cacheTime: duration('15', 'seconds').asMilliseconds(),
  });

  const { data: gasInfo, isLoading: isLoadingGasInfo } = useQuery({
    queryKey: blockchainInfoKeys.getGasInfoWithChain(position.chain),
    queryFn: () => BlockchainInfoService.getGasInfoWithChain(position.chain),
    staleTime: duration('15', 'seconds').asMilliseconds(),
    cacheTime: duration('15', 'seconds').asMilliseconds(),
  });

  const { handleSubmit, register, formState, control, watch, setValue } =
    useForm<FormValues>({
      resolver: yupResolver(schema),
      defaultValues: {
        maxPriorityFeePerGasWei: position.chain === Chains.Ethereum ? 1 : 0.01,
        maxSlippage: 5,
        type: 'market',
        expirationInHours: 12,
        currentPrice: currentPrice ?? '0',
        antiMev: chainHasMemPool(position.chain),
      },
    });

  const price =
    chainAsset(position.chain) === ChainAssets.EthereumBase
      ? prices?.eth_price
      : prices?.bnb_price;

  const {
    mutate,
    isLoading,
    error: mutationError,
  } = useMutation({
    mutationFn: (values: ManualSwapParams) =>
      DexTradeService.manualSwap(position.id, values),

    onSuccess: () => {
      addNewMessage({
        type: 'success',
        title: 'Buy More',
        message: 'Buy more request has been sent successfully.',
      });
      setRefetchInterval(new Date().toISOString());
      void queryClient.invalidateQueries(dexTradeKeys.all());
      onCancel();
    },
  });

  const onSubmit = handleSubmit((values) => {
    mutate({
      type: values.type,
      ...(values.type === 'limit' ? { price: values.price as string } : {}),
      ...(values.type === 'limit'
        ? { expirationInHours: values.expirationInHours }
        : {}),
      amount: parseEther((values.amount ?? '0').toString()).toString(),
      maxPriorityFeePerGasWei: Number(
        parseGwei(values.maxPriorityFeePerGasWei.toString())
      ),
      side: 'BUY',
      maxSlippage: Big(values.maxSlippage ?? 0)
        .div(100)
        .round(10, Big.roundDown)
        .toNumber(),
      antiMev: chainHasMemPool(position.chain) ? values.antiMev : false,
    });
  });

  const priorityFeeValue = watch('maxPriorityFeePerGasWei', 0);
  const gasPrice = gasFeeSchema.isValidSync(priorityFeeValue ?? 0)
    ? gasFeeSchema.cast(priorityFeeValue ?? 0)
    : 0;

  const amount =
    watch('amount')?.toString() === '' ? 0 : watch('amount', 0) ?? 0;

  const gasCostInEther = useCalculateGasCostInEther(
    gasInfo,
    gasPrice ?? 0
  ).plus(amount ?? 0);

  const isInsufficientBalance =
    wallet &&
    gasInfo &&
    prices &&
    amountSchema.isValidSync(amount ?? 0) &&
    Big(wallet.chainBalance ?? 0).lt(gasCostInEther ?? 0);

  const positionPrice = watch('price') as string | undefined;

  const priceDeviation =
    positionPrice && currentPrice
      ? Big(positionPrice)
          .minus(Big(currentPrice))
          .div(Big(currentPrice))
          .times(100)
          .toNumber()
      : undefined;

  return (
    <CustomModal title="Buy More" showModal handleClose={onCancel}>
      <form
        className="contents"
        onSubmit={(e) => {
          void onSubmit(e);
        }}
      >
        <CustomModal.Body>
          <CustomModal.Title className="hidden lg:flex">
            Buy More
          </CustomModal.Title>
          <CustomModal.Content>
            <div className="">
              <div className="grid grid-flow-col xxs:gap-1 xxs:pb-2">
                <DexPanelButton
                  className="xxs:p-2 xxs:rounded-none"
                  onClick={() => {
                    setValue('type', 'market');
                    setActiveTab('market');
                  }}
                  isActive={activeTab === 'market'}
                >
                  <div> Market Order</div>
                </DexPanelButton>
                <DexPanelButton
                  className="xxs:p-2 xxs:rounded-none"
                  onClick={() => {
                    setValue('type', 'limit');
                    setActiveTab('limit');
                  }}
                  isActive={activeTab === 'limit'}
                >
                  <div> Limit Order</div>
                </DexPanelButton>
              </div>
              <div className="flex flex-col xxs:space-y-2">
                <Controller
                  name="amount"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <NumberInput
                      suffix={<>{chainAsset(position.chain)}</>}
                      label="Amount"
                      autoComplete="off"
                      extraLabel={
                        wallet ? (
                          <>
                            Wallet balance:{' '}
                            {formatNumberWithSuffix(wallet.chainBalance)}{' '}
                            {chainAsset(position.chain)}
                          </>
                        ) : (
                          ''
                        )
                      }
                      {...field}
                      error={error?.message}
                      onChange={(event) => {
                        field.onChange(event.currentTarget.value);
                      }}
                    />
                  )}
                />
                {activeTab === 'limit' && (
                  <div className="xxs:space-y-2">
                    <Controller
                      name={'price'}
                      defaultValue={'' as any}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <NumberInput
                            extraLabel={
                              currentPrice ? (
                                <div className="">
                                  Current Price: $
                                  {formatWithAutoPrecision(
                                    Big(currentPrice || 0).toNumber()
                                  )}{' '}
                                  {priceDeviation && priceDeviation < 0 && (
                                    <span className="text-red-500 text-xs">
                                      {priceDeviation.toFixed(1)}%
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )
                            }
                            name="Price"
                            value={field.value as number}
                            onChange={(event) =>
                              field.onChange(event.currentTarget.value)
                            }
                            prefix={<>$</>}
                            label="Price"
                            error={error?.message}
                          />
                        );
                      }}
                    />
                    <Controller
                      name={'expirationInHours'}
                      defaultValue={'' as any}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <NumberInput
                            name="Expiration"
                            tooltip="The expiration time (in hours) after which the limit order will be considered expired"
                            value={field.value as number}
                            onChange={(event) =>
                              field.onChange(event.currentTarget.value)
                            }
                            suffix={<>hr</>}
                            label="Expiration time"
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </div>
                )}

                {watch('maxSlippage') > 25 &&
                  !watch('antiMev') &&
                  chainHasMemPool(position.chain) && (
                    <div className="flex space-x-1 items-center">
                      <ExclamationCircleIcon className="xxs:text-red-500 w-4 h-4" />
                      <p className="text-red-500 mb-0">
                        High slippage, enable MEV protection to avoid bots.
                      </p>
                    </div>
                  )}

                <NumberInput
                  label="Max slippage"
                  autoComplete="off"
                  {...register('maxSlippage')}
                  error={formState.errors.maxSlippage?.message}
                  suffix={<>%</>}
                />

                <Controller
                  name="maxPriorityFeePerGasWei"
                  control={control}
                  defaultValue={'' as any}
                  render={({ field, fieldState: { error } }) => (
                    <NumberInput
                      suffix={<>gwei</>}
                      label="Max priority fee per gas"
                      autoComplete="off"
                      {...field}
                      error={error?.message}
                      onChange={(event) =>
                        field.onChange(event.currentTarget.value)
                      }
                    />
                  )}
                />
                {chainHasMemPool(position.chain) && (
                  <Controller
                    name="antiMev"
                    control={control}
                    defaultValue={'' as any}
                    render={({ field }) => (
                      <div className="flex items-center justify-between ">
                        <p className="mb-0">MEV Protection</p>
                        <div className="flex space-x-2 items-center">
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            id="antiMev"
                          />
                          <Checkbox.Label htmlFor="anti-mev">
                            Enabled
                          </Checkbox.Label>
                        </div>
                      </div>
                    )}
                  />
                )}
                {gasPrice ? (
                  <GasEstimation
                    chain={position.chain}
                    priorityFee={gasPrice}
                  />
                ) : (
                  ''
                )}
                {isInsufficientBalance && (
                  <NotificationDex className="xxs:mx-0" type="error">
                    Your balance {formatNumberWithSuffix(wallet.chainBalance)}{' '}
                    {chainAsset(position.chain)}
                    ($
                    {formatUSDAmount(
                      Big(wallet.chainBalance ?? 0)
                        .times(price ?? 0)
                        .toNumber()
                    )}
                    ) is not enough to cover the transaction plus gas fees. Top
                    up your wallet and try again.
                  </NotificationDex>
                )}
                {wallet?.chainBalance &&
                Big(wallet.chainBalance ?? 0).lt(
                  minimumSafeAmount(position.chain)
                ) ? (
                  <NotificationDex className="xxs:mx-0" type="warning">
                    <WarningLowBalanceMessage chain={position.chain} />
                  </NotificationDex>
                ) : (
                  ''
                )}
              </div>
            </div>
          </CustomModal.Content>
          {mutationError && (
            <NotificationDex
              className="xxs:mx-0"
              type="error"
              errorMessage={mutationError}
            >
              An error occurred.
            </NotificationDex>
          )}
        </CustomModal.Body>
        <CustomModal.Footer>
          <PrimaryButton
            className="xxs:text-base lg:text-xs"
            type="submit"
            loading={isLoading}
            disabled={
              isLoadingWallets ||
              isLoadingGasInfo ||
              isLoadingPrices ||
              isInsufficientBalance
            }
          >
            Confirm
          </PrimaryButton>
          <Button
            className="xxs:text-base lg:text-xs"
            type="button"
            variant="dexNeutral"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
};
