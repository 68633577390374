import { Controller, useFormContext } from 'react-hook-form';
import { buyAmountSchema, FormValues, OrderType } from '../schema';
import {
  RowLabel,
  RowLabelledInput,
} from '../../../components/RowLabelledInput';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { chainAsset } from 'api/d-wallets';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import Big from 'big.js';
import TextInput from '../../../components/inputs/text-input/text-input';
import NumberInput from '../../../components/inputs/number-input';
import Checkbox from '../../../components/inputs/Checkbox';
import { DexCard } from '../../../components/cards';
import { SortDropdown } from '../../../components/dropdown/sort-dropdown';

export function WalletToCopy() {
  const { control } = useFormContext<FormValues>();
  return (
    <div>
      <Controller
        name={'botSettings.entries.walletAddressToCopy'}
        defaultValue={'' as any}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <TextInput
              {...field}
              className="xxs:mb-1"
              label="Copy Trading Wallet"
              tooltip="This is the ERC-20 wallet address you'll be copy-trading. By default, all buy swaps will be replicated."
              prefix={<CreditCardIcon className="h-4 w-4" />}
              error={error?.message}
            />
          );
        }}
      />
      <div className="lg:hidden">
        <WalletCopyAdvancedSettings />
      </div>
    </div>
  );
}

export const WalletCopyAdvancedSettings = () => {
  const { control, watch, setValue } = useFormContext<FormValues>();
  return (
    <div className="flex flex-col xxs:gap-1">
      <RowLabelledInput
        label={
          <RowLabel
            label="Order Type"
            tooltip="The order type can be set to either market or limit. With market orders, you'll buy and sell at the current market price, as soon as the buy or sell is triggered. With limit orders, you'll buy and sell at the same price as the trader you're copying. Limit orders help avoid slippage but may not execute if the price does not reach the target."
          />
        }
        className="xxs:py-1"
      >
        <Controller
          name="botSettings.entries.orderType"
          control={control}
          render={({ field }) => (
            <SortDropdown
              {...field}
              className="min-w-[100px]"
              mainLabel="Select a Market"
              onChange={(value) => {
                if (
                  value === OrderType.Limit &&
                  !watch('botSettings.entries.copyLimitExpirationInHours')
                ) {
                  setValue(
                    'botSettings.entries.copyLimitExpirationInHours',
                    0.5
                  );
                }
                field.onChange(value);
              }}
              options={[
                {
                  value: OrderType.Market,
                  label: 'Market',
                },
                {
                  value: OrderType.Limit,
                  label: 'Limit',
                },
              ]}
            />
          )}
        />
      </RowLabelledInput>
      {watch('botSettings.entries.orderType') === OrderType.Limit && (
        <Controller
          name="botSettings.entries.copyLimitExpirationInHours"
          defaultValue={'' as any}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <NumberInput
                {...field}
                label="Expiration"
                tooltip="The expiration time (in hours) after which the limit order will be considered expired."
                suffix={<>hours</>}
                error={error?.message}
              />
            );
          }}
        />
      )}

      <RowLabelledInput
        label={
          <RowLabel
            label="First-Time Buy Only"
            tooltip="Enable this option to copy trades only when the trader is buying a token that isn't already in their wallet. This prevents entering in the middle of a DCA (Dollar-Cost Averaging) strategy."
          />
        }
        className="xxs:py-1"
      >
        <Controller
          name="botSettings.entries.isFirstBuy"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <div className="flex items-center xxs:w-[108px] xxs:gap-2">
              <Checkbox
                checked={field.value || false}
                onChange={field.onChange}
                id="botSettings.entries.isFirstBuy"
              />
              <Checkbox.Label htmlFor="anti-honeypot">Enabled</Checkbox.Label>
            </div>
          )}
        />
      </RowLabelledInput>
      <RowLabelledInput
        label={
          <RowLabel
            label="Copy Buy Amount"
            tooltip={`Enable this option to buy a percentage (from 5% to 1,000%) of the amount bought by the wallet you’re copying, with a maximum limit equal to the input amount of ${
              watch('tradingSettings.buyAmount') ?? 0
            } ${chainAsset(watch('tradingSettings.chain'))}`}
          />
        }
        className="xxs:py-1"
      >
        <Controller
          name="botSettings.entries.copyBuyAmount"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <div className="flex items-center xxs:w-[108px] xxs:gap-2">
              <Checkbox
                checked={field.value || false}
                onChange={field.onChange}
                id="botSettings.entries.copyBuyAmount"
              />
              <Checkbox.Label htmlFor="anti-honeypot">Enabled</Checkbox.Label>
            </div>
          )}
        />
      </RowLabelledInput>
      {watch('botSettings.entries.copyBuyAmount') && (
        <Controller
          name="botSettings.entries.copyBuyAmountPercentage"
          defaultValue={'' as any}
          control={control}
          render={({ field, fieldState: { error } }) => {
            const buyAmountFormValue = watch('tradingSettings.buyAmount');
            return (
              <NumberInput
                {...field}
                label="Copy Buy Amount Percentage"
                tooltip={`Set a percentage of the buy amount you want to copy. For example, if the wallet is buying 0.1 ${chainAsset(
                  watch('tradingSettings.chain')
                )} and you set 200%, you'll buy 0.2 ${chainAsset(
                  watch('tradingSettings.chain')
                )}`}
                suffix={<>%</>}
                extraLabel={
                  buyAmountFormValue ? (
                    <>
                      Max buy amount :{' '}
                      {formatNumberWithSuffix(Big(buyAmountFormValue))}{' '}
                      {chainAsset(watch('tradingSettings.chain'))}
                    </>
                  ) : undefined
                }
                error={error?.message}
              />
            );
          }}
        />
      )}
      <RowLabelledInput
        label={
          <RowLabel
            label="Copy Buy More"
            tooltip="Enable this option to buy more if the wallet you're copying is buying more. This strategy helps you DCA to reduce the entry price if the price goes down, or to keep following the trend if the price goes up. The size of each additional buy is the same as the initial buy amount."
          />
        }
        className="xxs:py-1"
      >
        <Controller
          name="botSettings.entries.copyBuyMore"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <div className="flex items-center xxs:w-[108px] xxs:gap-2">
              <Checkbox
                checked={field.value || false}
                onChange={field.onChange}
                id="botSettings.entries.copyBuyMore"
              />
              <Checkbox.Label htmlFor="anti-honeypot">Enabled</Checkbox.Label>
            </div>
          )}
        />
      </RowLabelledInput>
      {watch('botSettings.entries.copyBuyMore') && (
        <Controller
          name="botSettings.entries.maxBuyMore"
          defaultValue={'' as any}
          control={control}
          render={({ field, fieldState: { error } }) => {
            const buyAmountFormValue = watch('tradingSettings.buyAmount');
            const maxBuyMore = watch('botSettings.entries.maxBuyMore');
            const buyAmount = buyAmountSchema.isValidSync(buyAmountFormValue)
              ? buyAmountSchema.cast(buyAmountFormValue)
              : 0;

            return (
              <NumberInput
                {...field}
                label="Max n. of buys"
                tooltip="Set the maximum number of times you'll continue buying if the wallet you're copying keeps buying. This number is important to limit the maximum investment."
                suffix={<>buys</>}
                extraLabel={
                  buyAmount && maxBuyMore ? (
                    <>
                      Max trade size:{' '}
                      {formatNumberWithSuffix(Big(buyAmount).mul(maxBuyMore))}{' '}
                      {chainAsset(watch('tradingSettings.chain'))}
                    </>
                  ) : undefined
                }
                error={error?.message}
              />
            );
          }}
        />
      )}
      <RowLabelledInput
        label={
          <RowLabel
            label="Auto Copy Sell"
            tooltip="Enabling this option will result in the copy trade of sell swaps. If any of your trading settings conflict with the sell swaps, such as your take profit or stop loss settings, the swap will be carried out based on the lower threshold. "
          />
        }
        className="xxs:py-1"
      >
        <Controller
          name="botSettings.entries.copySellSwaps"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <div className="flex items-center xxs:w-[108px] xxs:gap-2">
              <Checkbox
                checked={field.value || false}
                onChange={field.onChange}
                id="tradingSettings.copySellSwaps"
              />
              <Checkbox.Label htmlFor="anti-honeypot">Enabled</Checkbox.Label>
            </div>
          )}
        />
      </RowLabelledInput>
    </div>
  );
};

export function WalletCopyTradingSettings() {
  return (
    <DexCard>
      <div className="hidden lg:block font-bold text-sm xxs:text-dex-white-secondary">
        Advanced Settings
      </div>

      <WalletCopyAdvancedSettings />
    </DexCard>
  );
}
