import { ClockIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import React, { useState } from 'react';
import CheckboxInput from 'modules/shared-components/forms/checkbox-input/checkbox-input';
import Button from 'modules/shared-components/button/button';
import { useInfiniteQuery } from '@tanstack/react-query';
import { duration } from 'moment';
import { useInView } from 'react-intersection-observer';
import { useTelegramChannelsSearchFilters } from '../../../marketplace/components/usePageParams';
import {
  GetTelegramChannelsParams,
  TelegramChannelsRowInfo,
} from 'api/contracts';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import { TelegramChannelsFiltersBar } from '../../../research/components/TelegramChannels/components/TelegramChannelsFilters';
import { getAssetsUrl } from 'utils/urlUtils';
import { MizarHexagonImage } from 'modules/shared-components/hex-logo';
import { DurationCounter } from '../../../research/components/TelegramCalls/columns';
import { AlertRiskyChannel } from '../../../components/alerts/riskyChannelAlert';
import { FullScreenModal } from '../../../components/modal/FullScreenModal';
import { LoaderDex } from '../../../components/alerts/Loader';
import CustomModal from '../../../components/modal/CustomModal';

type ChannelsModalProps = {
  close: () => void;
  value: string[];
  onChange: (channels: string[]) => void;
};

export function ChannelsModal({ close, value, onChange }: ChannelsModalProps) {
  const [maxLength, setMaxLength] = useState('');
  const { filtersState: f } = useTelegramChannelsSearchFilters();

  const params: GetTelegramChannelsParams = {
    sort: f.sort,
    timePeriodInDays: f.timePeriodInDays,
    minNumberOfCalls: f.minNumberOfCalls,
    maxNumberOfCalls: f.maxNumberOfCalls,
    minRugPulls: f.minRugPulls,
    maxRugPulls: f.maxRugPulls,
    minWinnersVsLosers: f.minWinnersVsLosers,
    maxWinnersVsLosers: f.maxWinnersVsLosers,
    search: f.search,
  };

  const initialLimit = 10;

  const {
    data: options,
    error,
    isInitialLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryFn: ({ pageParam = { limit: initialLimit, offset: 0 } }) => {
      return ContractService.getTelegramChannels({
        ...params,
        limit: pageParam.limit,
        offset: pageParam.offset,
      });
    },
    queryKey: contractServiceKeys.getTelegramChannels(params),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => ({
      offset: lastPage.pagination.offset + lastPage.pagination.limit,
      limit: initialLimit * 2,
    }),
    refetchInterval: duration(12, 'seconds').asMilliseconds(),
  });

  const items = options?.pages?.flatMap((page) => page.data) || [];
  const pagination = options?.pages?.[0]?.pagination;

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        void fetchNextPage();
      }
    },
  });

  const selectChannel = (selectedChannel: string) => {
    const updatedChannels = [...value];
    const isChannelFound = updatedChannels.includes(selectedChannel);

    if (!isChannelFound) {
      if (updatedChannels.length < 100) {
        updatedChannels.push(selectedChannel);
        onChange(updatedChannels);
        setMaxLength('');
      } else {
        setMaxLength('You can select up to 100 channels');
      }
    } else {
      const selectedChannelIndex = updatedChannels.indexOf(selectedChannel);
      updatedChannels.splice(selectedChannelIndex, 1);
      onChange(updatedChannels);
      setMaxLength('');
    }
  };

  return (
    <FullScreenModal close={close}>
      <div className="flex flex-col xxs:pt-3 lg:py-0 grow">
        {value && (
          <div className="flex space-x-2">
            <div className="dark:text-black-50 xxs:text-xs flex items-center xxs:gap-1 xxs:mx-3 lg:mx-0">
              {value.length} Channel(s) Selected
            </div>
            {value.length > 0 && (
              <Button className="p-0 " onClick={() => onChange([])}>
                <div className="text-red-500 text-xs hover:text-red-600 normal-case">
                  Cancel all
                </div>
              </Button>
            )}
          </div>
        )}

        <div className="xxs:m-3 lg:m-0">
          <TelegramChannelsFiltersBar isCondensed={true} />
        </div>

        {error && (
          <div className="xxs:py-1 xxs:mb-3 xxs:pl-3 xxs:text-red-500">
            Could not load options
          </div>
        )}

        {!error && isInitialLoading ? (
          <div className="flex justify-center">
            <LoaderDex className="w-4 h-4" />
          </div>
        ) : (
          <div className="grow">
            {maxLength && (
              <div className="xxs:py-1 xxs:mb-3 xxs:text-red-500">
                {maxLength}
              </div>
            )}

            {items.length > 0 ? (
              items?.map((option) => (
                <CallsResultItem
                  key={option.id}
                  option={option}
                  selectChannel={selectChannel}
                  channels={value}
                />
              ))
            ) : (
              <div className="xxs:py-1 xxs:mb-3 xxs:pl-3 xxs:text-red-500">
                No channels found
              </div>
            )}

            {pagination && pagination.total > items.length && (
              <div className="flex justify-center xxs:py-2">
                <LoaderDex ref={ref} className="w-4 h-4 mx-auto" />
              </div>
            )}
          </div>
        )}
        <div className="lg:hidden">
          <CustomModal.Footer>
            <Button type="button" variant="dexNeutral" onClick={close}>
              close
            </Button>
          </CustomModal.Footer>
        </div>
      </div>
    </FullScreenModal>
  );
}

interface CallsResultInfo {
  option: TelegramChannelsRowInfo;
  selectChannel: (channel: string) => void;
  channels: string[] | [];
}

export function CallsResultItem({
  option,
  selectChannel,
  channels,
}: CallsResultInfo) {
  return (
    <button className=" p-0 w-full" onClick={() => selectChannel(option.id)}>
      <div className="flex items-center dark:text-white-50 w-full dark:hover:bg-black-600 hover:bg-white-200">
        <OptionColumn>
          <div className="flex items-center gap-2">
            <div className="flex justify-center px-2">
              <CheckboxInput
                onChange={() => selectChannel(option.id)}
                label={null}
                name="channelsFilter"
                checked={channels.some((channel) => channel === option.id)}
              />
            </div>

            <div className="flex space-x-2 items-center">
              <MizarHexagonImage
                size={50}
                alt="Trader logo"
                src={`${getAssetsUrl()}/static/img/telegram/${option.id}.png`}
              />

              <div className="space-y-1">
                <div className="flex space-x-1 items-center">
                  <div className="text-lg font-medium">
                    {option.telegramTradingChannelName}
                  </div>
                  <div className="">{AlertRiskyChannel(option)}</div>
                </div>

                <a
                  href={option.telegramTradingChannelLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 text-xs hover:Text-blue-600"
                >
                  {option.telegramTradingChannelLink}
                </a>
              </div>
            </div>
          </div>
        </OptionColumn>

        <OptionColumn>
          <div className="text-center">
            <div className="text-lg">
              {option.totalNumberOfCalls && option.totalNumberOfCalls > 0 ? (
                <span className="font-bold">{option.totalNumberOfCalls}</span>
              ) : (
                'No'
              )}{' '}
              <span className="font-normal">Calls</span>
            </div>
            {option.updatedAt && (
              <div className="gap-1 flex items-center justify-center">
                <ClockIcon className="w-4 h-4" />
                <DurationCounter start={option.updatedAt} /> ago
              </div>
            )}
          </div>
        </OptionColumn>
      </div>
    </button>
  );
}

const OptionColumn = (props: React.ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge(
        'xxs:p-4 xxs:px-3 lg:pr-4 lg:pl-0 overflow-hidden w-full',
        props.className
      )}
    />
  );
};
