import { Button } from 'modules/shared-components/button/button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/DWalletService';
import { Chains, DWalletDetails, InitiateBridge } from 'api/d-wallets';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import TxInfo from './SendFundTxInfo';
import { FormValues, getSchema } from './schema';
import { valueAsSafeNumber } from 'utils/FormatNumber';
import BridgeFundsContent from './BridgeFundsSettings';
import Big from 'big.js';
import CustomModal from '../../components/modal/CustomModal';
import { NotificationDex } from '../../components/alerts/notification';

export function BridgeModal({
  wallet,
  handleClose,
  chain,
}: {
  wallet: DWalletDetails;
  handleClose: () => void;
  chain: Chains;
}) {
  const schema = getSchema();
  const [initiateBridgeData, setInitiateBridgeData] = useState<
    InitiateBridge | undefined
  >();

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      address: wallet.address,
      srcChain: chain,
      dstChain: chain === Chains.Ethereum ? Chains.Base : Chains.Ethereum,
      srcChainTokenAmount: 0,
    },
  });

  const { handleSubmit } = form;
  const queryClient = useQueryClient();
  const { addNewMessage } = useSnackBar();

  const { mutate, error, isLoading } = useMutation({
    mutationFn: DWalletService.initiateBridge,
    onError: (err: string) => {
      addNewMessage({
        type: 'error',
        title: 'Error',
        message: `An Error Occurred: ${err ?? 'Unknown'}`,
      });
    },
    onSuccess: (data) => {
      setInitiateBridgeData(data);
      void queryClient.invalidateQueries(dWalletServiceKeys.all());
    },
  });

  const onSubmit = handleSubmit((value) => {
    mutate({
      params: {
        srcChain: value.srcChain?.toLowerCase(),
        dstChain: value.dstChain?.toLowerCase(),
        address: value.address ?? '',
        srcChainTokenAmount:
          valueAsSafeNumber(Big(value.srcChainTokenAmount ?? 0).toString()) ??
          0,
      },
    });
  });

  return (
    <CustomModal title="Bridge" showModal handleClose={handleClose}>
      <form
        className="contents"
        onSubmit={(e) => {
          e.preventDefault();
          void onSubmit(e);
        }}
      >
        <FormProvider {...form}>
          <CustomModal.Body className="xxs:p-4 min-h-[350px]">
            <CustomModal.Title className="hidden lg:flex">
              Bridge Funds
            </CustomModal.Title>

            <CustomModal.Content>
              <>
                {!initiateBridgeData && !error ? (
                  <BridgeFundsContent />
                ) : initiateBridgeData && !error ? (
                  <TxInfo data={initiateBridgeData} />
                ) : (
                  <div className="text-red-500">Something went wrong</div>
                )}
              </>
              {error && (
                <NotificationDex
                  type="error"
                  errorMessage={error}
                  className="xxs:my-2"
                >
                  An error occurred.
                </NotificationDex>
              )}{' '}
            </CustomModal.Content>
          </CustomModal.Body>

          <CustomModal.Footer>
            {!initiateBridgeData && (
              <PrimaryButton
                className="xxs:text-base lg:text-xs"
                type="submit"
                variant="primary"
                loading={isLoading}
              >
                BRIDGE
              </PrimaryButton>
            )}
            <Button
              type="button"
              className="xxs:text-base lg:text-xs"
              variant="dexNeutral"
              onClick={() => handleClose?.()}
            >
              CLOSE
            </Button>
          </CustomModal.Footer>
        </FormProvider>
      </form>
    </CustomModal>
  );
}
