import { ContractInfo } from 'api/contracts';
import { NotificationDex } from './alerts/notification';

type Props = {
  contract: ContractInfo;
};

export function ContractAlerts({ contract }: Props) {
  if (contract.status === 'RUG_PULL') {
    return (
      <NotificationDex type="error" size="xSmall">
        RUG-PULL
      </NotificationDex>
    );
  }
  if (contract.tradingInfo.honeypot) {
    return (
      <NotificationDex type="error" size="xSmall">
        Careful: this token looks like an honeypot!
      </NotificationDex>
    );
  }
  if (contract.tradingInfo.isSnipeable) {
    return (
      <NotificationDex type="success" size="xSmall">
        Sniping Opportunity
      </NotificationDex>
    );
  }

  return null;
}
