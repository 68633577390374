import {
  blockchainInfoKeys,
  BlockchainInfoService,
  GasInfo,
} from 'api/services/BlockchainInfoService';
import { priceServiceKeys, PriceService } from 'api/services/PriceService';
import Big from 'big.js';
import { duration } from 'moment';
import { weiToGwei, gweiToEther, etherToGwei } from './utils';
import { useQuery } from '@tanstack/react-query';
import { chainAsset, ChainAssets, Chains } from 'api/d-wallets';

export function GasEstimation({
  priorityFee,
  bribe,
  chain,
}: {
  priorityFee?: number;
  bribe?: number;
  chain: Chains;
}) {
  const { data: gasInfo } = useQuery({
    queryKey: blockchainInfoKeys.getGasInfoWithChain(chain),
    queryFn: () => BlockchainInfoService.getGasInfoWithChain(chain),
    refetchInterval: 1000 * 15,
    staleTime: 1000 * 15,
    cacheTime: 1000 * 15,
    enabled: !!chain,
  });

  const { data: prices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    refetchInterval: duration('1', 'minute').asMilliseconds(),
  });

  const price =
    chain && prices
      ? chainAsset(chain) === ChainAssets.EthereumBase
        ? prices?.eth_price
        : prices?.bnb_price
      : 0;

  const estimatedGasAmountGwei = useEstimatedGasAmountGwei();

  if (!gasInfo || !price) {
    return null;
  }

  return priorityFee ? (
    <div className="dark:bg-dex-black-700 xxs:px-2 xxs:py-1  lg:mt-auto flex justify-between rounded-sm">
      <div className="xxs:text-sm lg:text-xs xxs:text-dex-white-secondary">
        Estimated Gas
      </div>

      <div className="xxs:text-sm lg:text-xs xxs:font-semibold">
        {Big(weiToGwei(gasInfo.gasPrice)).plus(+priorityFee).toFixed(1)} gwei ($
        {Big(
          gweiToEther(weiToGwei(gasInfo.gasPrice) + +priorityFee).times(
            estimatedGasAmountGwei
          )
        )
          .times(price)
          .toFixed(2)}
        )
      </div>
    </div>
  ) : bribe ? (
    <div className="dark:bg-dex-black-700 xxs:px-2 xxs:py-1  lg:mt-auto flex justify-between rounded-sm">
      <div className="xxs:text-sm lg:text-xs xxs:text-dex-white-secondary">
        Estimated Gas
      </div>
      <div className="xxs:text-sm lg:text-xs xxs:font-semibold">
        {gweiToEther(Big(weiToGwei(gasInfo.gasPrice)).plus(etherToGwei(bribe)))
          .toFixed(2)
          .toString()}{' '}
        {chainAsset(chain)} ($
        {Big(
          gweiToEther(weiToGwei(gasInfo.gasPrice))
            .times(estimatedGasAmountGwei)
            .plus(bribe)
        )
          .times(price)
          .toFixed(2)}
        )
      </div>
    </div>
  ) : (
    <></>
  );
}

export function useCalculateGasCostInEther(
  gasInfo: GasInfo | undefined,
  priorityFee: number
) {
  const estimatedGasAmountGwei = useEstimatedGasAmountGwei();
  if (!gasInfo) {
    return Big(0);
  } else
    return Big(
      gweiToEther(weiToGwei(gasInfo.gasPrice) + +priorityFee).times(
        estimatedGasAmountGwei
      )
    );
}

export function useEstimatedGasAmountGwei() {
  const { data } = useQuery({
    queryKey: priceServiceKeys.getGasAmount(),
    queryFn: PriceService.getGasAmount,
    refetchInterval: duration('1', 'minute').asMilliseconds(),
  });
  return data ?? 0;
}
