import {
  PauseCircleIcon,
  PlayCircleIcon,
  TrashIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as TelegramIcon } from 'assets/img/news/telegram.svg';
import Button from 'modules/shared-components/button/button';
import { useQuery } from '@tanstack/react-query';
import { dexBotKeys, DexBotService } from 'api/services/DexBotService';
import { BotSettings, TradingSettingsForm } from '../CreateBot';
import { BotType, DexSniperBot } from 'api/dex-bot';
import { BotKind, FormValues, OrderType, schema } from '../schema';
import Big from 'big.js';
import { fromWei, weiToGwei } from 'modules/ethereum/utils';
import { useToggle } from 'modules/utils/useToggle';
import { DeleteDexBotModal } from '../../../components/Bots/DeleteBotModal';
import { PauseDexBotModal } from '../../../components/Bots/PauseBotModal';
import { ResumeDexBotModal } from '../../../components/Bots/ResumeBotModal';
import { EditBotConfirmationModal } from './EditConfirmationModal';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { chainName, Chains } from 'api/d-wallets';
import { BotConfirmationModal } from '../BotConfirmationModal';
import { NotificationDex } from '../../../components/alerts/notification';
import Tag from 'modules/shared-components/data-display/tag';
import PageTemplate from '../../../components/layout';
import { BOT_SIDEBAR, Tabs } from '../../../components/layout/SIDEBAR_CONST';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { LoaderDex } from '../../../components/alerts/Loader';

function botToFromValues(bot: DexSniperBot, isEdit: boolean): any {
  const trade = bot.position;
  const tp0 = trade.takeProfits[0] || trade.trailingStopLoss;
  const isWalletCopyTrading = bot.walletAddressToCopy !== undefined;

  const autoRetryEnabled = !!(
    trade.takeProfits?.[0]?.retry ||
    trade.trailingStopLoss?.retry ||
    trade.limitOrders?.[0]?.retry
  );
  const autoRetry = {
    buy: false,
    takeProfit: !!trade.takeProfits?.[0]?.retry,
    stopLoss: !!trade.trailingStopLoss?.retry,
  };

  const tradingSettings = {
    walletId: trade.vaultId,
    buyAmount: fromWei(trade.buyAmountInWei),
    maxBuyGasPriority: weiToGwei(trade.maxPriorityFeePerGasWei),
    slippage: Big(trade.maxSlippage).times(100).toNumber(),
    antiMev: trade.antiMev,
    chain: bot.chain ?? Chains.Ethereum,
    safetyMeasures: {
      autoRetryEnabled,
      autoRetry,
      marketCapRange:
        Big(trade.minMarketcapInUsd).gt(0) ||
        Big(trade.maxMarketcapInUsd).lt(Number.MAX_SAFE_INTEGER),
      gasLimit: trade.gasLimit ? Big(trade.gasLimit).toNumber() : 300000,
      gasLimitEnabled: !!trade.gasLimit,
      liquidityRange:
        Big(trade.minLiquidityInUsd).gt(0) ||
        Big(trade.maxLiquidityInUsd).lt(Number.MAX_SAFE_INTEGER),
      taxProtectionEnabled:
        Big(trade.maxBuyTax).lt(1) || Big(trade.maxSellTax).lt(1),
      honeypotProtection: trade.honeypotProtection,
      liquidity: {
        min: +trade.minLiquidityInUsd,
        max: +trade.maxLiquidityInUsd,
      },
      marketCap: {
        min: +trade.minMarketcapInUsd,
        max: +trade.maxMarketcapInUsd,
      },
      taxProtection: {
        buy: Big(trade.maxBuyTax).times(100).toNumber(),
        sell: Big(trade.maxSellTax).times(100).toNumber(),
      },
    },
    takeProfit: trade.takeProfits.map((tp) => ({
      id: tp.id,
      status: tp.status,
      threshold: Big(tp.priceIncreasePercentage).times(100).toNumber(),
      weight: Big(tp.amountToSellPercentage).times(100).toNumber(),
    })),
    takeProfitConfig: {
      maxSellGasPriority: tp0
        ? weiToGwei(tp0?.maxPriorityFeePerGasWei || '0')
        : 5,
      slippage: tp0 ? Big(tp0?.maxSlippage).times(100).toNumber() : 15,
      antiMev: tp0 ? tp0?.antiMev : false,
    },
    stopLoss: trade.trailingStopLoss
      ? {
          threshold: Big(trade.trailingStopLoss.initialStopLossPercentage)
            .abs()
            .times(100)
            .toNumber(),
          isTrailingEnabled:
            +trade.trailingStopLoss.trailingDeviationPercentage !== 1,
          deviation:
            +trade.trailingStopLoss.trailingDeviationPercentage === 1
              ? null
              : Big(trade.trailingStopLoss.trailingDeviationPercentage)
                  .times(100)
                  .toNumber(),
        }
      : null,
  };

  const telegramTradingSettings = {
    ...tradingSettings,
    launchedFilter: bot.isSnipingPostLaunch,
    notLaunchedFilter: bot.isSnipingPreLaunch,
    bribe: fromWei(trade.bribe ?? 0),
    blockOneRetry: trade.blockOneRetry || false,
    ...(trade.blockOneRetry
      ? {
          slippage: Big(trade.maxSlippage ?? 0)
            .times(100)
            .toNumber(),
          backupBribe: fromWei(trade.backupBribe ?? 0),
        }
      : {}),
  };

  if (isWalletCopyTrading) {
    return {
      name: isEdit ? bot.name : bot.name + ' - copy',
      tradingSettings,
      type: BotKind.WalletCopyTrading,
      botSettings: {
        entries: {
          walletAddressToCopy: bot.walletAddressToCopy!,
          copySellSwaps: !!bot.copySellSwaps,
          orderType: bot.copyLimit ? OrderType.Limit : OrderType.Market,
          copyLimitExpirationInHours: bot.copyLimit
            ? Big(bot.copyLimitExpirationInHours ?? 0).toFixed(2)
            : 0,
          copyBuyMore: !!bot.copyBuyMore,
          copyBuyAmount: !!bot.copyBuyAmountPercentage,
          copyBuyAmountPercentage: Big(bot.copyBuyAmountPercentage ?? 0.5).mul(
            100
          ),
          maxBuyMore: bot.copyBuyMore ? bot.maxBuyMore ?? 0 : 0,
          isFirstBuy: bot.openOnlyIfFirst,
        },
        maxNOfSnipes: bot.maxNumActivePositions,
      },
    };
  } else {
    return {
      name: bot.name,
      tradingSettings: telegramTradingSettings,
      type: BotKind.TelegramCopyTrading,
      botSettings: {
        entries: {
          callsFilter: bot.telegramCallsPerTimePeriodFilter
            ? {
                minNumberOfCalls:
                  bot.telegramCallsPerTimePeriodFilter.numberOfCalls,
                timePeriod:
                  bot.telegramCallsPerTimePeriodFilter.timePeriodWindowInDays,
              }
            : null,
          cooldownPeriod: bot.cooldownInHours
            ? {
                periodInHours: bot.cooldownInHours!,
              }
            : null,
          channels: bot.telegramChannelFilter?.subscribedChannelsIds ?? [],
        },
        maxNOfSnipes: bot.maxNumActivePositions,
      },
    };
  }
}

interface Props {
  id: string;
  isEdit: boolean;
  isWalletCopyTrading: boolean;
}

export default function ManageBot({ id, isEdit, isWalletCopyTrading }: Props) {
  const {
    data: bot,
    isLoading,
    error,
    isFetching,
  } = useQuery({
    queryFn: () =>
      DexBotService.getBot(
        id,
        isWalletCopyTrading ? BotType.Wallet : BotType.Telegram
      ),
    queryKey: dexBotKeys.getBot(
      id,
      isWalletCopyTrading ? BotType.Wallet : BotType.Telegram
    ),
  });

  if (isLoading || isFetching) {
    return (
      <PageTemplate
        classNameSidebarContainer="hidden lg:block"
        sidebar={BOT_SIDEBAR}
      >
        <div className="flex justify-center">
          <LoaderDex className="xxs:my-4" />
        </div>
      </PageTemplate>
    );
  }
  if (error) {
    return (
      <PageTemplate
        classNameSidebarContainer="hidden lg:block"
        sidebar={BOT_SIDEBAR}
      >
        {error && (
          <NotificationDex
            type="error"
            errorMessage={error}
            className="xxs:my-2"
          >
            An error occurred.
          </NotificationDex>
        )}{' '}
      </PageTemplate>
    );
  }

  return (
    <Edit
      isEdit={isEdit}
      bot={bot!}
      isWalletCopyTrading={isWalletCopyTrading}
    />
  );
}

function Edit({
  bot,
  isWalletCopyTrading,
  isEdit,
}: {
  bot: DexSniperBot;
  isWalletCopyTrading: boolean;
  isEdit: boolean;
}) {
  const [isOpen, { open: openConfirmation, close: closeConfirmation }] =
    useToggle();
  const defaultValues = botToFromValues(bot, isEdit);
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, getValues, watch } = form;

  const onSubmit = handleSubmit(() => {
    openConfirmation();
  });

  const isLarge = useMediaQuery(screenGte.large);

  return (
    <PageTemplate
      sidebar={BOT_SIDEBAR}
      classNameSidebarContainer="hidden lg:block"
      pages={
        isLarge
          ? [
              {
                href: !isWalletCopyTrading
                  ? `/dex/bots/${Tabs.TgCopyTrading}`
                  : `/dex/bots/${Tabs.WalletCopyTrading}`,
                name: 'Bots',
              },
              {
                href: `/dex/bots/${bot.id}/${isEdit ? 'edit' : 'duplicate'}`,
                name: `${isEdit ? 'Edit' : 'New'} ${watch('name')}`,
              },
            ]
          : [
              {
                href: !isWalletCopyTrading
                  ? `/dex/bots/${Tabs.TgCopyTrading}`
                  : `/dex/bots/${Tabs.WalletCopyTrading}`,
                name: 'Bots',
              },
              {
                href: !isWalletCopyTrading
                  ? `/dex/bots/${Tabs.TgCopyTrading}`
                  : `/dex/bots/${Tabs.WalletCopyTrading}`,
                name: 'Bots',
              },
            ]
      }
    >
      <div className="space-y-0 pb-16 lg:pb-0">
        <form
          autoComplete="off"
          onSubmit={(e) => {
            void onSubmit(e);
          }}
        >
          <FormProvider {...form}>
            <div className="xxs:space-y-2">
              <div className="xxs:space-y-2 xxs:p-2 lg:p-0">
                {isEdit && bot.insufficientBalance && (
                  <NotificationDex type="error">
                    Your wallet balance is running low! Please top up soon to
                    prevent your bot from being automatically paused.
                  </NotificationDex>
                )}
                <div className="xxs:flex xxs:space-x-2 items-center justify-between py-2 md:pt-0 md:pb-2">
                  <div className=" text-2xl lg:text-3xl xxs:font-semibold xxs:flex xxs:items-center space-x-2 overflow-hidden">
                    {isWalletCopyTrading ? (
                      <WalletIcon className="w-6 h-6 xxs:text-dex-white-secondary flex-shrink-0" />
                    ) : (
                      <TelegramIcon className="w-6 h-6 xxs:text-dex-white-secondary flex-shrink-0" />
                    )}
                    <div className="truncate xxs:max-w-[160px] lg:max-w-none">
                      {watch('name')}
                    </div>
                    <Tag color="dexNeutral" className="h-auto">
                      <div className="flex space-x-1 items-center xxs:text-sm">
                        <div>
                          <ChainsIcon
                            imgName={
                              watch('tradingSettings.chain') ?? Chains.Base
                            }
                          />
                        </div>
                        <div>
                          {chainName(
                            watch('tradingSettings.chain') ?? Chains.Base
                          )}
                        </div>
                      </div>
                    </Tag>
                    {isEdit &&
                      (bot.status === 'ACTIVE' ? (
                        <div className="xxs:px-1 xxs:py-0.5 xxs:bg-green-500 xxs:rounded-sm font-normal xxs:text-sm max-w-max flex items-center xxs:gap-1">
                          <PlayCircleIcon className="w-5 h-5 text-white" />
                        </div>
                      ) : (
                        <div className="xxs:px-1 xxs:py-0.5 xxs:bg-yellow-500 xxs:text-sm font-normal xxs:rounded-sm max-w-max flex items-center xxs:gap-1">
                          <PauseCircleIcon className="w-5 h-5 text-white" />
                        </div>
                      ))}
                    <a
                      className="lg:flex xxs:text-dex-white hover:text-dex-white hidden"
                      target="_blank"
                      rel="noreferrer"
                      href={
                        !isWalletCopyTrading
                          ? 'https://docs.mizar.com/sniper-bot-manual/bots/telegram-bot'
                          : 'https://docs.mizar.com/sniper-bot-manual/bots/wallet-bot'
                      }
                    >
                      <Tag color="blue" className="h-auto">
                        Help Me
                      </Tag>
                    </a>
                  </div>

                  {isEdit && (
                    <div className="flex space-x-2 flex-shrink-0">
                      {bot.status === 'PAUSED' ? (
                        <ResumeBotButton
                          id={bot.id}
                          botType={
                            isWalletCopyTrading
                              ? BotType.Wallet
                              : BotType.Telegram
                          }
                        />
                      ) : (
                        <PauseBotButton
                          id={bot.id}
                          botType={
                            isWalletCopyTrading
                              ? BotType.Wallet
                              : BotType.Telegram
                          }
                        />
                      )}
                      <DeleteBotButton
                        bot={bot}
                        botType={
                          isWalletCopyTrading
                            ? BotType.Wallet
                            : BotType.Telegram
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="xxs:space-y-0 lg:space-y-2">
                <BotSettings isWalletCopyTrading={isWalletCopyTrading} />

                <TradingSettingsForm
                  isWalletCopyTrading={isWalletCopyTrading}
                />
              </div>

              <div className="xxs:px-2 lg:px-0">
                <PrimaryButton
                  className="xxs:col-span-2 lg:col-span-1 xxs:text-base lg:text-xs"
                  type="submit"
                  variant="primary"
                  size="large"
                >
                  SAVE
                </PrimaryButton>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
      {isOpen &&
        (isEdit ? (
          <EditBotConfirmationModal
            id={bot.id}
            bot={getValues()}
            previous={defaultValues}
            handleClose={closeConfirmation}
          />
        ) : (
          <BotConfirmationModal
            bot={getValues()}
            handleClose={closeConfirmation}
          />
        ))}
    </PageTemplate>
  );
}

export function DeleteBotButton({
  bot,
  botType,
}: {
  bot: DexSniperBot;
  botType: BotType;
}) {
  const [isOpen, { open, close }] = useToggle();
  return (
    <>
      <Button
        onClick={() => open()}
        type="button"
        variant="dexNeutral"
        className="w-max h-max"
      >
        <TrashIcon className="w-6 h-6" />
      </Button>
      {isOpen && (
        <DeleteDexBotModal botType={botType} bot={bot} handleClose={close} />
      )}
    </>
  );
}

export function PauseBotButton({
  id,
  botType,
}: {
  id: string;
  botType: BotType;
}) {
  const [isOpen, { open, close }] = useToggle();
  return (
    <>
      <Button
        onClick={() => open()}
        type="button"
        variant="dexNeutral"
        className="w-max h-max"
      >
        <PauseCircleIcon className="w-6 h-6" />
      </Button>
      {isOpen && (
        <PauseDexBotModal id={id} handleClose={close} botType={botType} />
      )}
    </>
  );
}

export function ResumeBotButton({
  id,
  botType,
}: {
  id: string;
  botType: BotType;
}) {
  const [isOpen, { open, close }] = useToggle();
  return (
    <>
      <Button
        onClick={() => open()}
        type="button"
        variant="dexNeutral"
        className="w-max h-max"
      >
        <PlayCircleIcon className="w-6 h-6" />
      </Button>
      {isOpen && (
        <ResumeDexBotModal id={id} handleClose={close} botType={botType} />
      )}
    </>
  );
}
