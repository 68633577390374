import { useQueryParams } from 'modules/routing/useQueryParams';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { isEmpty, omit } from 'lodash/fp';
import { toQueryObject } from 'modules/routing/query-string';
import { HotTokensSortKeys } from 'api/contracts';
import { HotTokensPeriods, TokenLaunchPeriods } from '../Wallets/usePageParams';

const schema = {
  sort: yup.string().default(`-${HotTokensSortKeys.TotalBuyTop}`).required(),
  poolCreatedAt: yup
    .number()
    .oneOf(Object.values(TokenLaunchPeriods) as number[]),
  period: yup
    .string()
    .oneOf(Object.values(HotTokensPeriods))
    .required()
    .default(HotTokensPeriods.OneHour),
  hotTokenAddress: yup.string(),
  minMc: yup.number(),
  maxMc: yup.number(),
  minVolume: yup.number(),
  maxVolume: yup.number(),
  minLiquidity: yup.number(),
  maxLiquidity: yup.number(),
};

export function usePageParams() {
  return useQueryParams(schema);
}

export type FilterState = yup.TypeFromShape<typeof schema, any>;

export const CACHE_KEY = 'hotTokensFilters-v4';

export function useHotTokensSearchFilters() {
  const [filtersState, setFilters] = usePageParams();

  const setFiltersWithCache = useFiltersWithCache(
    setFilters,
    {
      period: HotTokensPeriods.OneHour,
      sort: `-${HotTokensSortKeys.SmartFlow}`,
    },
    (queryObj) => isEmpty(omit(['searchDefaultOpen'], queryObj))
  );

  const setSort = (key: string) => {
    let newSortKey;
    if (filtersState.sort?.includes(key)) {
      if (filtersState.sort?.charAt(0) === '-') {
        newSortKey = key;
      } else {
        newSortKey = `-${key}`;
      }
    }
    setFiltersWithCache({
      ...filtersState,
      sort: newSortKey || key,
    });
  };

  return { filtersState, setFilters: setFiltersWithCache, setSort };
}

function useFiltersWithCache(
  setFilters: (filters: FilterState) => void,
  defaults: Partial<FilterState> = {},
  shouldApplyCache: (filters: FilterState) => boolean = () => true
) {
  const location = useLocation();

  useEffect(() => {
    if (
      shouldApplyCache(toQueryObject(location.search) as unknown as FilterState)
    ) {
      const fromCache = window.localStorage.getItem(CACHE_KEY);

      setFilters(fromCache ? { ...JSON.parse(fromCache) } : defaults);
    }
  }, [location.search]);

  return function cacheAndSetFilters(filters: FilterState) {
    window.localStorage.setItem(CACHE_KEY, JSON.stringify(filters));
    setFilters(filters);
  };
}
