import { Big, BigSource } from 'big.js';

export function toPercentageChange(from: BigSource, to: BigSource) {
  if (Big(from).eq(0)) return Big(0);
  return Big(to).minus(from).div(from).times(100);
}

export function percentageToTimesIncrease(pct: number) {
  return Big(pct).div(100).plus(1).toNumber();
}
