/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useState } from 'react';
import { ContractInfo } from 'api/contracts';
import { SecurityAlerts } from './Sections/SecurityAlerts';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { TokenTradeInfoHeader } from '../../../components/TokenTradeInfoHeader';
import { Chart } from './Sections/Chart';
import { MobileExpandCardLink } from 'modules/shared-components/MobileExpandableCard';
import dexScreenerImg from 'assets/img/brands/dexscreener.svg';
import { ReactComponent as TwitterIcon } from 'assets/img/brands/twitter.svg';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { Chains } from 'api/d-wallets';
import { NotificationDex } from '../../../components/alerts/notification';
import { ContractSettings } from './Sections/ContractSettings';
import { MainStats } from './Sections/MainStats';
import { SmartStats } from './Sections/SmartStats';

type Props = {
  contract: ContractInfo;
  hideChart?: boolean;
};

export const TokenInfoMobileBody = ({ contract, hideChart }: Props) => {
  const [selectedPoolAddress] = useState(
    contract.liquidityInfo.activePools[0]?.poolAddress
  );

  const selectedPool = contract.liquidityInfo.activePools.find(
    (p) => p.poolAddress === selectedPoolAddress
  );

  return (
    <div className="xxs:space-y-3 lg:space-y-1">
      <div className="xxs:gap-2 w-full">
        <div className="w-full lg:w-[250px] shrink-0">
          <SecurityAlerts className="h-full" contract={contract} />
          <MainStats contract={contract} />
          <SmartStats contract={contract} />
          <ContractSettings contract={contract} />
        </div>
        <div className="flex flex-col w-full lg:w-full h-full xxs:gap-0 lg:gap-2">
          <div className="flex flex-col lg:flex-row w-full xxs:gap-0 lg:gap-2 h-full " />
        </div>
      </div>

      <div className="lg:hidden xxs:flex flex-col xxs:gap-0 lg:gap-2 w-full">
        {selectedPool && !hideChart && <Chart contract={contract} />}
        {selectedPool && (
          <MobileExpandCardLink
            rel="noopener noreferrer"
            target="_blank"
            href={`https://dexscreener.com/${
              contract.generalInfo.chain.key === Chains.Ethereum
                ? 'ethereum'
                : contract.generalInfo.chain.key
            }/${selectedPool.poolAddress}`}
          >
            <div className="flex xxs:gap-2 items-center">
              <img
                src={dexScreenerImg}
                alt="geckoterminal logo"
                className="w-6 h-6"
              />{' '}
              DexScreener
            </div>
          </MobileExpandCardLink>
        )}

        <MobileExpandCardLink
          rel="noopener noreferrer"
          target="_blank"
          href={`https://twitter.com/search?q=$${contract.generalInfo.address}`}
        >
          <div className="flex xxs:gap-2 items-center">
            <TwitterIcon className="w-6 h-6 xxs:bg-black dark:bg-white rounded-full p-[3px] dark:fill-black fill-white" />
            X (former Twitter)
          </div>
        </MobileExpandCardLink>
      </div>
    </div>
  );
};

export function TokenCard({ contract }: Props) {
  const history = useHistory();
  const params = useParams<{ address: string }>();
  const isDesktop = useMediaQuery(screenGte.large);
  const location = useLocation();

  const [selectedPoolAddress] = useState(
    contract.liquidityInfo.activePools[0]?.poolAddress
  );

  const selectedPool = contract.liquidityInfo.activePools.find(
    (p) => p.poolAddress === selectedPoolAddress
  );

  return (
    <div className="xxs:space-y-3 lg:space-y-1">
      {!isDesktop && (
        <div className="xxs:space-y-2">
          <TokenTradeInfoHeader contract={contract} pool={selectedPool} />
          {contract.status === 'RUG_PULL' && (
            <NotificationDex type="error">
              This token performed a rug-pull and can't be traded.
            </NotificationDex>
          )}
        </div>
      )}

      {params.address && contract.status !== 'RUG_PULL' && !isDesktop && (
        <div className="xxs:mx-2">
          <PrimaryButton
            type="button"
            className="xxs:text-base lg:text-xs"
            size="large"
            onClick={() => {
              history.push({
                pathname: `/dex/snipe/new/${
                  contract.generalInfo.chain.key ?? Chains.Ethereum
                }/${params.address}`,
                state: {
                  from: location.pathname,
                },
              });
            }}
          >
            trade
          </PrimaryButton>
        </div>
      )}
      <TokenInfoMobileBody contract={contract} />
    </div>
  );
}
