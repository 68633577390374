import {
  ContractInfo,
  GetHotTokensParams,
  HotTokensSortKeys,
} from 'api/contracts';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import { ExpandableCardContent } from '../../../../components/cards';
import { SmartStatsComponent } from '../../../../trade/stats/smart-stats';
import { HotTokensPeriods } from '../../Wallets/usePageParams';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import { duration } from 'moment';

type Props = { contract: ContractInfo };

export function SmartStats({ contract }: Props) {
  const params: GetHotTokensParams = {
    sort: HotTokensSortKeys.TotalBuyTop,
    address: contract.generalInfo.address,
    chain: contract.generalInfo.chain.key,
    period: HotTokensPeriods.TwentyFourHour,
  };

  const { data: poolsInfoFromBe } = useQuery({
    queryKey: contractServiceKeys.getHotTokens(params),
    queryFn: () => ContractService.getHotTokens(params),
    keepPreviousData: true,
    refetchInterval: duration(12, 'seconds').asMilliseconds(),
  });

  return (
    <MobileExpandableCard
      mobileLabel="Smart Stats"
      trigger={
        <MobileExpandCardTrigger>
          <div className="flex xxs:gap-1 items-center">Smart Stats</div>
        </MobileExpandCardTrigger>
      }
    >
      <div className="xxs:px-2">
        <ExpandableCardContent className="xxs:space-y-2">
          <SmartStatsComponent stats={poolsInfoFromBe?.[0]} />
        </ExpandableCardContent>
      </div>
    </MobileExpandableCard>
  );
}
