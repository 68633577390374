import Button from 'modules/shared-components/button/button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { valueAsSafeNumber } from 'utils/FormatNumber';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { useTelegramChannelsSearchFilters } from '../usePageParams';
import {
  PresetButton,
  PresetButtonDescription,
  PresetButtonTitle,
} from '../../../research/components/TelegramCalls/PresetButton';
import NumberInput from '../../../components/inputs/number-input';
import CustomModal from '../../../components/modal/CustomModal';

type Props = {
  handleClose: () => void;
};
export const schema = yup.object({
  min: yup.number().min(0).max(100),
  max: yup.number().min(0).max(100),
});

type FormState = yup.InferType<typeof schema>;

export function NumberOfRugPullsFiltersModal({ handleClose }: Props) {
  const { filtersState, setFilters } = useTelegramChannelsSearchFilters();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      min: filtersState?.minRugPulls
        ? filtersState.minRugPulls * 100
        : undefined,
      max: filtersState?.maxRugPulls
        ? filtersState.maxRugPulls * 100
        : undefined,
    },
  });

  return (
    <CustomModal
      title="Rug-pulls vs Total Calls"
      handleClose={handleClose}
      showModal
    >
      <form
        autoComplete="off"
        className="contents"
        onSubmit={(e) => {
          void handleSubmit((form) => {
            setFilters({
              ...filtersState,
              minRugPulls: form.min ? form.min / 100 : undefined,
              maxRugPulls: form.max ? form.max / 100 : undefined,
            });
            handleClose();
          })(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4">
          <CustomModal.Title className="hidden lg:flex">
            Rug-pulls vs Total Calls Ratio
          </CustomModal.Title>

          <CustomModal.Content>
            <div className="xxs:space-y-3">
              <p className="xxs:text-sm xxs:mb-0">
                The Rug-Pull to Total Calls Ratio filter provides insights into
                the percentage of token mentions that resulted in rug-pulls. By
                filtering channels with a lower ratio (e.g., below 5%), you can
                avoid riskier channels, helping to reduce potential exposure to
                scams.
              </p>
              <div className="xxs:space-y-1">
                <p className="xxs:text-sm xxs:mb-0">Set a Range</p>
                <div className="grid grid-cols-3 xxs:gap-1 xxs:mb-4">
                  <PresetButton
                    onClick={() => {
                      setValue('min', 0);
                      setValue('max', 5);
                    }}
                  >
                    <PresetButtonTitle>LOW</PresetButtonTitle>
                    <PresetButtonDescription>0% to 5%</PresetButtonDescription>
                  </PresetButton>
                  <PresetButton
                    onClick={() => {
                      setValue('min', 5);
                      setValue('max', 15);
                    }}
                  >
                    <PresetButtonTitle>MEDIUM</PresetButtonTitle>
                    <PresetButtonDescription>5% to 15%</PresetButtonDescription>
                  </PresetButton>
                  <PresetButton
                    onClick={() => {
                      setValue('min', 15);
                      setValue('max', undefined);
                    }}
                  >
                    <PresetButtonTitle>HIGH</PresetButtonTitle>
                    <PresetButtonDescription>Above 15%</PresetButtonDescription>
                  </PresetButton>
                </div>
                <p className="xxs:mb-0">Custom</p>
                <div className="flex xxs:gap-1">
                  <NumberInput
                    label="Min"
                    suffix="%"
                    {...register('min', {
                      setValueAs: valueAsSafeNumber,
                    })}
                    error={errors.min?.message}
                  />
                  <NumberInput
                    label="Max"
                    suffix="%"
                    {...register('max', {
                      setValueAs: valueAsSafeNumber,
                    })}
                    error={errors.max?.message}
                  />
                </div>
              </div>
            </div>
          </CustomModal.Content>
        </CustomModal.Body>
        <CustomModal.Footer>
          <PrimaryButton className="xxs:text-base lg:text-xs" type="submit">
            SAVE
          </PrimaryButton>
          <Button
            className="xxs:text-base lg:text-xs"
            type="button"
            variant="dexNeutral"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
}
