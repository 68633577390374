import config from 'config';
import * as React from 'react';
import { useTheme } from '../media/theme';
import { screenGte, useMediaQuery } from '../media/use-media-query';
import type {
  ChartingLibraryFeatureset,
  ChartingLibraryWidgetConstructor,
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ThemeName,
} from './charting_library';
import { twMerge } from 'tailwind-merge';

declare module './charting_library' {
  // We need this to check if the widget is removed from the DOM
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface IChartingLibraryWidget {
    __removed: boolean;
  }
}

declare global {
  interface Window {
    TradingView: { widget: ChartingLibraryWidgetConstructor };
  }
}

export function useTvChart() {
  return React.useState<IChartingLibraryWidget | null>(null);
}

export const commonOptions = {
  interval: '60',
  charts_storage_url: 'https://saveload.tradingview.com',
  charts_storage_api_version: '1.1',
  client_id: 'tradingview.com',
  user_id: 'public_user_id',
  fullscreen: false,
  autosize: true,
  locale: 'en',
  library_path: '/charting_library@26.003/',
  disabled_features: [
    'use_localstorage_for_settings',
    'header_symbol_search',
    'symbol_search_hot_key',
    'header_compare',
    'go_to_date',
    'header_saveload',
  ],
  enabled_features: ['snapshot_trading_drawings', 'chart_crosshair_menu'],
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  custom_font_family: "'Open Sans'",
};

type Props = Pick<ChartingLibraryWidgetOptions, 'symbol'> & {
  onWidgetChange?: (widget: IChartingLibraryWidget | null) => void;
  className?: string;
  optionsFactory: (
    theme: ThemeName,
    defaults: Partial<ChartingLibraryWidgetOptions>
  ) => ChartingLibraryWidgetOptions;
};

const desktopOnlyFeatures: ChartingLibraryFeatureset[] = [
  'left_toolbar',
  'header_widget',
  'timeframes_toolbar',
  'edit_buttons_in_legend',
  'context_menus',
  'control_bar',
  'border_around_the_chart',
];

export function TVChartContainer({
  symbol,
  onWidgetChange,
  className,
  optionsFactory,
}: Props) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(screenGte.small);

  React.useEffect(() => {
    if (
      !containerRef.current ||
      process.env.NODE_ENV === 'test' ||
      config.IS_E2E
    ) {
      return;
    }

    const widgetOptions = optionsFactory(theme, {
      container: containerRef.current,
      symbol,
    });
    const tvWidget = new window.TradingView.widget({
      ...widgetOptions,
      disabled_features: [
        ...(widgetOptions.disabled_features || []),
        ...(isDesktop ? [] : desktopOnlyFeatures),
      ],
    });
    onWidgetChange?.(tvWidget);

    return () => {
      if (tvWidget !== null) {
        tvWidget.__removed = true;
        onWidgetChange?.(null);
        tvWidget.remove();
      }
    };
  }, [isDesktop, theme, symbol, onWidgetChange]);

  return (
    <div
      ref={containerRef}
      className={twMerge('xxs:w-full xxs:h-full', className)}
    />
  );
}
