import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DexTradeService, dexTradeKeys } from 'api/services/DexTradeService';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { Button } from 'modules/shared-components/button/button';
import { FC } from 'react';
import { useRefreshIntervalPersistedState } from '../side-panels/SnipesPanel';
import CustomModal from '../components/modal/CustomModal';
import { NotificationDex } from '../components/alerts/notification';

interface ReactivatePositionsProps {
  onCancel: () => void;
  positionId: string;
}

export const ReactivatePosition: FC<ReactivatePositionsProps> = ({
  onCancel,
  positionId,
}) => {
  const { addNewMessage } = useSnackBar();
  const queryClient = useQueryClient();
  const [, setRefetchInterval] = useRefreshIntervalPersistedState();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: () => DexTradeService.reactivate(positionId),
    onSuccess: () => {
      addNewMessage({
        type: 'success',
        title: 'Position Reactivation Success',
        message:
          'Position reactivation request has been accepted and in progress.',
      });
      setRefetchInterval(new Date().toISOString());
      void queryClient.invalidateQueries(dexTradeKeys.all());
      onCancel();
    },
  });

  return (
    <CustomModal title="Reactivate Trade" showModal handleClose={onCancel}>
      <CustomModal.Body>
        <CustomModal.Title>Reactivate Trade</CustomModal.Title>
        <CustomModal.Content>
          <div className="flex flex-col xxs:gap-2 xxs:text-dex-white-secondary">
            <div>
              <p>By confirming you will:</p>
              <ul>
                <li>Reactivate the trade on Mizar</li>
                <li>Be able to manage the position manually from Mizar</li>
              </ul>
            </div>
          </div>
        </CustomModal.Content>
        {error && (
          <NotificationDex
            className="xxs:mx-0"
            type="error"
            errorMessage={error}
          >
            An error occurred.
          </NotificationDex>
        )}
      </CustomModal.Body>
      <CustomModal.Footer>
        <PrimaryButton
          className="xxs:text-base lg:text-xs"
          type="button"
          loading={isLoading}
          onClick={() => {
            mutate();
          }}
        >
          Confirm
        </PrimaryButton>
        <Button
          className="xxs:text-base lg:text-xs"
          type="button"
          variant="dexNeutral"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};
