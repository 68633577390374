import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import Menu, { MenuItemProps, MenuProps } from './index';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { Link } from 'react-router-dom';
import { SelectionModal } from '../../modal/SelectionModal';
import React from 'react';

export function ResponsiveMenu(props: MenuProps & MobileMenuProps) {
  const isDesktop = useMediaQuery(screenGte.small);
  return isDesktop ? <Menu {...props} /> : <MobileMenu {...props} />;
}

type MobileMenuProps = {
  label: React.ReactNode;
  children: React.ReactNode;
  trigger?: (props: { open: () => void }) => JSX.Element;
  defaultOpen?: boolean;
};

export function MobileMenu({
  label,
  children,
  trigger,
  ...rest
}: MenuProps & MobileMenuProps) {
  return (
    <SelectionModal
      label={label}
      defaultOpen={rest.defaultOpen}
      trigger={
        trigger ||
        (({ open }) => (
          <IconButton
            {...rest}
            onClick={open}
            className={twMerge(
              'lg:hidden xxs:rounded  xxs:bg-dex-black-700 hover:bg-black-600 xxs:p-1 xxs:text-dex-white-secondary hover:text-dex-white',
              rest.className
            )}
          >
            <EllipsisVerticalIcon
              className={twMerge('h-6 w-6')}
              aria-hidden="true"
            />
          </IconButton>
        ))
      }
    >
      {children}
    </SelectionModal>
  );
}

export const ResponsiveMenuItem = ({
  ...props
}: React.PropsWithChildren<MenuItemProps>) => {
  const isDesktop = useMediaQuery(screenGte.small);
  const classElement =
    'lg:bg-dex-black-700 hover:lg:bg-dex-black-600 w-full xxs:px-3 xxs:py-4 lg:p-3 xxs:text-sm xxs:flex xxs:gap-2 xxs:items-center xxs:text-dex-white-secondary hover:text-dex-white border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-dex-black-700 xxs:border-solid lg:border-0';

  return isDesktop ? (
    props.href ? (
      <Menu.Item {...props} />
    ) : (
      <Menu.ButtonItem {...props} />
    )
  ) : props.href ? (
    <Link to={props.href} className={classElement} {...props} />
  ) : (
    <BaseButton type="button" className={classElement} {...props} />
  );
};

export function MobileMenuOption(
  props: React.ComponentProps<typeof BaseButton>
) {
  return (
    <BaseButton
      type="button"
      {...props}
      className={twMerge(
        'border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-solid xxs:border-dex-black-700',
        'w-full xxs:p-3 xxs:text-sm xxs:flex xxs:gap-2 xxs:items-center',
        'xxs:text-dex-white-secondary hover:text-dex-white',
        props.className
      )}
    />
  );
}
