import { useToggle } from 'modules/utils/useToggle';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import React from 'react';
import Button from '../button/button';
import { ReactComponent as ArrowLeftIcon } from 'assets/img/icons/arrow-left.svg';
import config from 'config';
import { BackButton } from 'modules/telegram/BackButton';
import { SubCardBox } from '../../../app/dex/components/cards';
import { FullScreenModal } from '../../../app/dex/components/modal/FullScreenModal';

type Props = {
  children: React.ReactNode;
  mobileLabel?: string;
  trigger?: React.ReactNode;
  renderTrigger?: (props: { open: () => void }) => JSX.Element;
  showDone?: boolean;
};

export function MobileExpandableCard({
  children,
  trigger,
  mobileLabel,
  renderTrigger,
  showDone,
}: Props) {
  const isDesktop = useMediaQuery(screenGte.large);
  const [isExpanded, { close, open }] = useToggle();

  if (isDesktop) {
    return <>{children}</>;
  }

  return (
    <>
      {trigger &&
        React.cloneElement(trigger as any, {
          onClick: (e) => {
            (trigger as any)?.props?.onClick?.(e);
            open();
          },
        })}
      {renderTrigger?.({ open })}

      {isExpanded && (
        <FullScreenModal
          backdropClassName="xxs:bg-transparent dark:bg-transparent"
          close={close}
        >
          <MobileExpandableCardHeader showDone={showDone} onClose={close}>
            {mobileLabel}
          </MobileExpandableCardHeader>
          {children}
        </FullScreenModal>
      )}
    </>
  );
}

export function MobileExpandCardTrigger({
  children,
  ...props
}: { children: React.ReactNode } & React.ComponentProps<typeof BaseButton>) {
  return (
    <SubCardBox
      {...props}
      size="xs"
      className={twMerge(
        'xxs:p-4 justify-between flex flex-row items-center xxs:text-dex-white-secondary xxs:text-base',
        props.className
      )}
      type="button"
    >
      {children}
      <ChevronRightIcon
        className="xxs:h-5 xxs:w-5 stroke-2 flex-shrink-0 xxs:text-dex-white-secondary"
        aria-hidden="true"
      />
    </SubCardBox>
  );
}

export function MobileExpandCardLink({
  children,
  ...props
}: { children: React.ReactNode } & React.ComponentProps<'a'>) {
  return (
    <SubCardBox
      as="a"
      {...props}
      size="xs"
      className={twMerge(
        'xxs:p-4 justify-between flex flex-row items-center xxs:text-base xxs:font-semibold xxs:text-dex-white-secondary',
        props.className
      )}
    >
      {children}
      <ChevronRightIcon
        className="xxs:h-5 xxs:w-5 xxs:text-dex-white-secondary stroke-2 flex-shrink-0"
        aria-hidden="true"
      />
    </SubCardBox>
  );
}

export function MobileExpandableCardHeader({
  children,
  onClose,
  showDone,
}: {
  children: React.ReactNode;
  onClose?: () => void;
  showDone?: boolean;
}) {
  if (config.isTelegramBrowser) {
    return <BackButton onClick={onClose} />;
  }
  return (
    <div className="xxs:text-base xxs:py-2 grid grid-cols-[min-content_1fr_min-content] xxs:gap-2 dark:bg-dex-black-800 sticky top-0 items-center z-1005 xxs:border-0 border-tailwind xxs:border-b xxs:border-black-700">
      <Button
        onClick={onClose}
        className="xxs:normal-case xxs:text-dex-white-secondary xxs:text-base justify-self-start"
      >
        <ArrowLeftIcon className="w-3 h-3 xxs:mr-1" /> Back
      </Button>
      <div className="text-center">{children}</div>
      <Button
        onClick={onClose}
        className={twMerge(
          'xxs:normal-case xxs:text-base justify-self-end',
          !showDone && 'xxs:invisible'
        )}
      >
        Done
      </Button>
    </div>
  );
}
