import { useContext, useEffect, useState } from 'react';
import { systemContext } from './core';

export interface BackButtonProps {
  onClick?: () => void;
}

let index = 1;

export const BackButton = ({ onClick }: BackButtonProps): null => {
  const [system, setSystem] = useContext(systemContext);
  const [buttonId] = useState(() => ++index);

  useEffect(() => {
    if ((system.BackButton?.id || 0) <= buttonId) {
      setSystem((state) => ({
        ...state,
        BackButton: {
          id: buttonId,
          onClick,
        },
      }));
    }
  }, [buttonId, system.BackButton?.id]);

  useEffect(() => {
    return () => {
      setSystem((state) => ({
        ...state,
        BackButton: null,
      }));
    };
  }, []);

  return null;
};
