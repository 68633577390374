import { formatUSDAmount } from './FormatNumber';
import React from 'react';

export function FormatTextDelta(input) {
  if (input < 0) {
    return (
      <span className="text-red-warning">{parseFloat(input).toFixed(1)}%</span>
    );
  } else if (input > 0) {
    return (
      <span className="text-green-success">
        +{parseFloat(input).toFixed(1)}%
      </span>
    );
  } else {
    return <span className="">0%</span>;
  }
}

export function FormatTextWithTag(input, tag) {
  if (input < 0) {
    return (
      <span className="text-red-warning">
        {formatUSDAmount(parseFloat(input))} {tag}
      </span>
    );
  } else {
    return (
      <span className="text-green-success">
        +{formatUSDAmount(parseFloat(input))} {tag}
      </span>
    );
  }
}

export function FormatTextDollarsDelta(input, quoteAsset) {
  if (input < 0) {
    return (
      <span className="text-red-warning">
        -{!quoteAsset && '$'}
        {formatUSDAmount(parseFloat(-input))} {quoteAsset}
      </span>
    );
  } else if (input > 0) {
    return (
      <span className="text-green-success">
        +{!quoteAsset && '$'}
        {formatUSDAmount(parseFloat(input))} {quoteAsset}
      </span>
    );
  } else {
    return (
      <span className="">
        {formatUSDAmount(parseFloat(input))} {quoteAsset}
      </span>
    );
  }
}
