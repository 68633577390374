import { FC, forwardRef, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface LabelProps {
  disabled?: boolean;
}

type ILabel = FC<
  LabelProps &
    React.DetailedHTMLProps<
      React.LabelHTMLAttributes<HTMLLabelElement>,
      HTMLLabelElement
    >
>;

type ICheckbox = React.ForwardRefExoticComponent<
  CheckboxProps & React.RefAttributes<HTMLInputElement>
> & {
  Label: ILabel;
};

type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, ...props }, ref) => (
    <input
      ref={ref}
      type="checkbox"
      className={twMerge(
        'xxs:w-5 xxs:h-5 cursor-pointer xxs:dex-text-white-secondary rounded focus:ring-blue-500 ring-offset-black-800 focus:ring-2 hover:bg-dex-black-500 xxs:bg-dex-black-600 hover:checked:bg-dex-black-600 checked:xxs:bg-dex-black-500 xxs:border-black-600 border-solid',
        'disabled:checked:bg-dex-black-700 disabled:cursor-not-allowed',
        className
      )}
      {...props}
    />
  )
) as ICheckbox;

const Label: ILabel = ({ children, disabled, className, ...rest }) => {
  return (
    <label
      style={{ opacity: '1' }}
      className={twMerge(
        'font-medium ml-1 truncate',
        disabled
          ? 'text-black-700 dark:text-white-800'
          : 'text-black-900 dark:text-white',
        className
      )}
      {...rest}
    >
      {children}
    </label>
  );
};

Checkbox.Label = Label;

export default Checkbox;
