import * as yup from 'yup';
import { UserOtpConfigResponse } from 'api/user';
import { Controller, useFormContext } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { FormValues } from './schema';
import config from '../../../../config';
import NumberInput from '../../components/inputs/number-input';
import { NotificationDex } from '../../components/alerts/notification';

const schema = yup.object({
  otp: yup
    .string()
    .required('Code is required')
    .length(6, 'Code should be 6 chars long'),
});

export type OtpFormValues = yup.InferType<typeof schema>;

interface ValidateWith2FaProps {
  error: string | undefined;
  otpConfig: UserOtpConfigResponse | undefined;
}

export const ValidateWith2Fa = ({ error, otpConfig }: ValidateWith2FaProps) => {
  const { control, setValue } = useFormContext<OtpFormValues>();

  const { setValue: setValueMainForm } = useFormContext<FormValues>();

  return (
    <>
      {otpConfig?.mechanism === 'app' ? (
        <div className="xxs:mb-4">
          Enter the 6 digits code of your Authenticator App.
        </div>
      ) : (
        <div className="xxs:mb-4">
          Enter the 6 digits code received on your email address.
        </div>
      )}
      <Controller
        control={control}
        name="otp"
        render={({ field, fieldState: { error: errore } }) => (
          <NumberInput
            type="text"
            {...field}
            onChange={(e) => {
              setValue('otp', e.currentTarget.value);
              setValueMainForm('otp', e.currentTarget.value);
            }}
            error={errore?.message}
          />
        )}
      />
      {error && (
        <NotificationDex type="error" errorMessage={error} className="xxs:my-2">
          An error occurred.
        </NotificationDex>
      )}{' '}
    </>
  );
};

export const Enable2FA = () => {
  return (
    <div className="text-center space-y-4">
      <ExclamationCircleIcon
        className="flex-shrink-0 h-16 w-16 align-center text-yellow-500"
        aria-hidden="true"
      />

      <div className="space-y-2">
        <div className="text-lg">Two Factor Authenticator Required.</div>
        {config.isTelegramBrowser ? (
          <div>
            {' '}
            To send funds to other wallets, you need first to activate your Two
            Factor Authenticator. You can do it on the dashboard{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.mizar.com/dashboard/settings/Account"
            >
              here
            </a>
          </div>
        ) : (
          <div className="text-sm">
            To send funds to other wallets, you need first to activate your Two
            Factor Authenticator. Activate it{' '}
            <Link to="/dashboard/settings/Account">here</Link>.
          </div>
        )}
      </div>
    </div>
  );
};
