import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { OpenPositionListItem } from 'api/dex-trade';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import Big from 'big.js';
import classNames from 'classnames';
import Table from 'modules/shared-components/data-display/table';
import { FC } from 'react';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import { Wrapper } from './PositionDetails';
import { DetailsTopInfo } from './TopInfo';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { NotificationDex } from '../../../components/alerts/notification';

interface Props {
  position: OpenPositionListItem;
}

export function BlockedTradeDetails({ position }: Props) {
  const { data } = useQuery({
    queryFn: () =>
      ContractService.getContract(position.chain, position.tokenAddress),
    queryKey: contractServiceKeys.getContract(
      position.chain,
      position.tokenAddress
    ),
  });
  const isLg = useMediaQuery(screenGte.large);
  const isMobile = !isLg;

  const checks = position.snipeEvent
    ? [
        {
          setting: 'Honeypot',
          contract: position.snipeEvent.honeypot ? 'Yes' : 'No',
          allowed: 'No',
          check: !position.snipeEvent.honeypot,
        },
        {
          setting: 'Buy Tax',
          contract: `${Big(position.snipeEvent.taxBuy).times(100).toFixed(0)}%`,
          allowed: `<${Big(position.maxBuyTax).times(100).toFixed(0)}%`,
          check: Big(position.maxBuyTax).gte(position.snipeEvent.taxBuy),
        },
        {
          setting: 'Sell Tax',
          contract: `${Big(position.snipeEvent.taxSell)
            .times(100)
            .toFixed(0)}%`,
          allowed: `<${Big(position.maxSellTax).times(100).toFixed(0)}%`,
          check: Big(position.maxSellTax).gte(position.snipeEvent.taxSell),
        },
        {
          setting: 'Liquidity',
          contract: `$${formatNumberWithSuffix(
            position.snipeEvent.totalLiquidityInUsd,
            { precision: isMobile ? 1 : 4, suffixes: allSuffixes }
          )}`,
          allowed: `$${formatNumberWithSuffix(position.minLiquidityInUsd, {
            precision: isMobile ? 1 : 4,
            suffixes: allSuffixes,
          })} - $${formatNumberWithSuffix(position.maxLiquidityInUsd, {
            precision: isMobile ? 1 : 4,
            suffixes: allSuffixes,
          })}`,
          check:
            Big(position.snipeEvent.totalLiquidityInUsd).gte(
              position.minLiquidityInUsd
            ) &&
            Big(position.snipeEvent.totalLiquidityInUsd).lte(
              position.maxLiquidityInUsd
            ),
        },
        ...(position.snipeEvent.marketCap
          ? [
              {
                setting: 'Market Cap',
                contract: `$${formatNumberWithSuffix(
                  Big(position.snipeEvent.marketCap),
                  { precision: isMobile ? 1 : 4, suffixes: allSuffixes }
                )}`,
                allowed: `$${formatNumberWithSuffix(
                  position.minMarketcapInUsd,
                  { precision: isMobile ? 1 : 4, suffixes: allSuffixes }
                )} - $${formatNumberWithSuffix(position.maxMarketcapInUsd, {
                  precision: isMobile ? 1 : 4,
                  suffixes: allSuffixes,
                })}`,
                check:
                  Big(position.snipeEvent.marketCap).gte(
                    position.minMarketcapInUsd
                  ) &&
                  Big(position.snipeEvent.marketCap).lte(
                    position.maxMarketcapInUsd
                  ),
              },
            ]
          : []),
      ]
    : [];

  return (
    <Wrapper>
      <DetailsTopInfo position={position} contract={data} />

      <NotificationDex type="error">
        Trade Blocked. Your trade has been blocked by the bot security settings.
      </NotificationDex>

      <div className="max-w-full grow h-full">
        <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
          <Table.Header
            columns={COLUMNS}
            className="xxs:dark:bg-dex-black-800 xxs:text-xs"
            rowClassName="xxs:px-1"
          />
          <tbody className="xxs:bg-dex-black-700">
            {checks.map(({ setting, contract, allowed, check }, index) => (
              <ChecksRow
                key={index}
                index={index}
                setting={setting}
                contract={contract}
                allowed={allowed}
                check={check}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </Wrapper>
  );
}

const COLUMNS = [
  {
    label: 'Settings',
    key: 'SETTING',
  },
  {
    label: 'Contract',
    key: 'CONTRACT',
  },
  {
    label: 'Allowed',
    key: 'ALLOWED',
  },
  {
    label: 'Check',
    key: 'CHECK',
  },
];

interface ChecksRowProps {
  index: number;
  setting: string;
  contract: string;
  allowed: string;
  check: boolean;
}

const ChecksRow: FC<ChecksRowProps> = ({
  check,
  setting,
  allowed,
  contract,
  index,
}) => (
  <tr
    className={classNames(
      index === 0
        ? 'xxs:border-gray-300 dark:border-black-800'
        : 'xxs:border-gray-200 dark:border-black-700',
      'border-t xxs:bg-dex-black-700'
    )}
  >
    <Table.Td className="dark:bg-black-800 xxs:text-xs md:text-sm xxs:px-1">
      {setting}
    </Table.Td>
    <Table.Td className="dark:bg-black-800 xxs:text-xs md:text-sm xxs:px-1">
      {contract}
    </Table.Td>
    <Table.Td className="dark:bg-black-800 xxs:text-xs md:text-sm xxs:px-1">
      {allowed}
    </Table.Td>
    <Table.Td className="dark:bg-black-800 xxs:text-xs md:text-sm xxs:px-1">
      {check ? (
        <div className="flex gap-1 items-center text-green-500">
          <CheckCircleIcon className="w-6 h-6" />{' '}
          <span className="xxs:hidden md:inline">Passed</span>
        </div>
      ) : (
        <div className="flex gap-1 items-center text-red-500">
          <XCircleIcon className="w-6 h-6" />{' '}
          <span className="xxs:hidden md:inline">Blocked</span>
        </div>
      )}
    </Table.Td>
  </tr>
);
