import { DropdownOptionItem } from '../dropdown';
import { twMerge } from 'tailwind-merge';

export type FilterDropdownProps<T extends string | number> = {
  label: string;
  options?: DropdownOptionItem<T>[];
  value: T[];
  onChange: (value: T[]) => void;
  onOpen?: () => void;
  icon?: React.ReactNode;
  isError?: boolean;
  search?: React.ReactNode;
  loading?: boolean;
  size?: string;
};

export function CountBadge(props: React.ComponentProps<'span'>) {
  return (
    <span
      {...props}
      className={twMerge(
        'xxs:bg-dex-black-600 xxs:py-0.5 xxs:px-2.5 xxs:text-xs xxs:text-dex-white rounded-xl text-nowrap',
        props.className
      )}
    />
  );
}
