/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, Fragment } from 'react';
import Table from 'modules/shared-components/data-display/table';
import type {
  DexTransaction,
  LimitOrder,
  OpenPositionListItem,
  StopLossEntry,
  TakeProfitEntry,
} from 'api/dex-trade';
import classNames from 'classnames';
import moment, { duration } from 'moment';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { fromWei } from 'modules/ethereum/utils';
import { formatNumberWithSuffix, formatUSDAmount } from 'utils/FormatNumber';
import { TxStatus } from '../columns';
import Big from 'big.js';
import {
  ArrowTopRightOnSquareIcon,
  ClockIcon,
  PlayCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { chainAsset, Chains, chainScan } from 'api/d-wallets';
import Button from 'modules/shared-components/button/button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import config from '../../../../config';
import { formatApiError } from 'helpers/api/apiErrors';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { isOrderWithRetryFailing, LimitOrderSide } from 'api/dex-trade';
import Tooltip from 'modules/shared-components/tooltip';
import { formatMomentDuration } from 'modules/utils/formatDuration';
import Tag from '../../components/alerts/Tags';

interface PositionDetailsTableProps {
  position: OpenPositionListItem;
}

export const COLUMNS = [
  {
    label: 'Transaction Type',
    key: 'Transaction Type',
  },
  {
    label: 'Amount',
    key: 'Amount',
  },
  {
    label: 'Price / MC',
    key: 'Price / MC',
  },
  {
    label: 'Time/Block',
    key: 'Time/Block',
  },
  {
    label: 'Fees/gas',
    key: 'Fees/gas',
  },
  {
    label: 'Status',
    key: 'Status',
  },
];

export interface PlannedOrderProps {
  priceInUsd: number | null;
  marketCap: number | null;
  deviation: string | undefined;
  name: string;
  amountIn: string | null;
  amountOut: string | null;
  type: string;
  tokenSymbol: string;
  id?: string;
  chain: Chains;
  retry: boolean;
  remainingBeforeExpirationInSeconds?: string | undefined;
}

const PlannedRow = ({
  amountIn,
  amountOut,
  type,
  tokenSymbol,
  priceInUsd,
  marketCap,
  deviation,
  chain,
  name,
  id,
  index,
  retry,
  remainingBeforeExpirationInSeconds,
}: PlannedOrderProps & { index: number }) => {
  const { addNewMessage } = useSnackBar();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: DexTradeService.cancelLimitOrder,
    onSuccess: () => {
      addNewMessage({
        type: 'success',
        title: 'Success',
        message: 'Your limit order has been successfully cancelled.',
      });
      void queryClient.invalidateQueries(dexTradeKeys.all());
    },
    onError: (err) => {
      if (config.isTelegramBrowser) {
        addNewMessage({
          message: formatApiError(
            err,
            'There was an error while cancelling your order. Try again.'
          ),
          title: 'Error',
          type: 'error',
        });
      } else {
        addNewMessage({
          type: 'error',
          title: 'Error',
          message: 'There was an error while cancelling your order. Try again.',
        });
      }
    },
  });

  return (
    <tr
      className={classNames(
        index === 0 ? '' : 'xxs:border-dex-black-700',
        'border-t xxs:bg-dex-black-800'
      )}
    >
      <Table.Td>
        {name}{' '}
        {retry ? (
          <Tooltip text="Auto-retry ON">
            <PlayCircleIcon className="text-green-500 xxs:w-4 xxs:h-4" />
          </Tooltip>
        ) : (
          <div />
        )}
      </Table.Td>
      <Table.Td>
        <div className="">
          {!amountIn
            ? '--'
            : type === 'buy'
            ? `${formatNumberWithSuffix(amountIn || 0)} ${chainAsset(chain)}`
            : `${formatNumberWithSuffix(amountIn || 0)} ${tokenSymbol}`}{' '}
        </div>
        <div className="">
          {!amountOut
            ? '--'
            : type === 'buy'
            ? `${formatNumberWithSuffix(amountOut || 0)} ${tokenSymbol}`
            : `${formatNumberWithSuffix(amountOut || 0)} ${chainAsset(
                chain
              )}`}{' '}
        </div>
      </Table.Td>
      <Table.Td>
        {priceInUsd || marketCap ? (
          <span>
            {' '}
            {priceInUsd ? (
              <div>
                P: ${formatNumberWithSuffix(priceInUsd)}{' '}
                <span
                  className={classNames(
                    'text-xs',
                    deviation && Big(deviation ?? 0).gt(0)
                      ? 'text-green-500'
                      : 'text-red-500'
                  )}
                >
                  {deviation ? (
                    <span>
                      {Big(deviation).gt(0) ? '+' : ''}
                      {Big(deviation).times(100).toFixed(1)}%
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            ) : (
              '--'
            )}
            {marketCap ? (
              <div>MC: ${formatNumberWithSuffix(marketCap)}</div>
            ) : (
              '--'
            )}
          </span>
        ) : (
          '--'
        )}
      </Table.Td>
      <Table.Td>{/* time */}</Table.Td>
      <Table.Td>{/* fees */}</Table.Td>
      <Table.Td>
        <div className="flex space-x-1 items-center">
          <Tag type="warning">Planned</Tag>
          {remainingBeforeExpirationInSeconds && (
            <Tooltip
              text={`This limit order will expire and get cancelled in ${formatMomentDuration(
                duration(remainingBeforeExpirationInSeconds, 'seconds')
              )}`}
            >
              <div className="bg-black-600 rounded xxs:p-1 xxs:text-xs flex space-x-0.5 items-center">
                <ClockIcon className="w-3 h-3" />
                <div>
                  {formatMomentDuration(
                    duration(remainingBeforeExpirationInSeconds, 'seconds')
                  )}
                </div>
              </div>
            </Tooltip>
          )}

          {id && (
            <Button
              loading={isLoading}
              onClick={() => {
                mutate(id);
              }}
              className="bg-red-500 text-white xxs:p-0.5"
            >
              <TrashIcon className="w-4 h-4" />
            </Button>
          )}
        </div>
      </Table.Td>
    </tr>
  );
};

const TakeProfitPlannedRow: FC<{
  name: string;
  index: number;
  level: TakeProfitEntry;
  chain: Chains;
  tokenSymbol: string;
}> = ({ name, index, level, tokenSymbol, chain }) => (
  <PlannedRow
    chain={chain}
    index={index}
    priceInUsd={level.priceInUsd}
    marketCap={level.marketCap}
    deviation={level.priceIncreasePercentage}
    name={name}
    amountIn={level.amountIn}
    amountOut={level.amountOut}
    type="sell"
    tokenSymbol={tokenSymbol}
    retry={level.retry}
  />
);

const PlannedLimitOrderRow: FC<{
  name: string;
  index: number;
  level: LimitOrder;
  tokenSymbol: string;
  chain: Chains;
}> = ({ name, index, level, tokenSymbol, chain }) => {
  return (
    <PlannedRow
      index={index}
      priceInUsd={level.priceInUsd}
      marketCap={level.marketCap}
      deviation={level.priceInUsd ? undefined : level.priceDeviationPercentage}
      chain={chain}
      id={level.id}
      remainingBeforeExpirationInSeconds={
        level.remainingBeforeExpirationInSeconds
      }
      name={name}
      amountIn={level.amountIn}
      amountOut={level.amountOut}
      type="buy"
      tokenSymbol={tokenSymbol}
      retry={level.retry}
    />
  );
};

const PlannedStopLoss: FC<{
  name: string;
  index: number;
  level: StopLossEntry;
  tokenSymbol: string;
  chain: Chains;
}> = ({ name, index, level, tokenSymbol, chain }) => (
  <PlannedRow
    index={index}
    priceInUsd={level.priceInUsd}
    chain={chain}
    marketCap={level.marketCap}
    deviation={level.stopLossTriggerDeviation}
    name={name}
    amountIn={level.amountIn}
    amountOut={level.amountOut}
    type="sell"
    tokenSymbol={tokenSymbol}
    retry={level.retry}
  />
);

export const PositionTransactionsTable: FC<PositionDetailsTableProps> = ({
  position,
}) => {
  const buyTxs = position.transactions?.filter((tx) => tx.side === 'BUY');
  const buyLimits = position.limitOrders.filter(
    (tx) => tx.side === LimitOrderSide.Buy
  );
  const sellTxs = position.transactions?.filter((tx) => tx.side === 'SELL');
  const sellLimits = position.limitOrders.filter(
    (tx) => tx.side === LimitOrderSide.Sell
  );
  const closeTxs = sellTxs.filter(
    (x) => !position.takeProfits.some((tp) => tp.transactionId === x.id)
  );
  const slTx = sellTxs.find(
    (x) => x.id === position.trailingStopLoss?.transactionId
  );

  return (
    <div className="max-w-full grow h-full">
      <Table>
        <Table.Header
          trClassName="xxs:dark:bg-dex-black-800 border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-600"
          rowClassName="xxs:p-1"
          columns={COLUMNS}
        />
        <tbody className="">
          {(!!buyTxs.length || !!buyLimits.length) && (
            <Fragment>
              <Table.RowGroup
                className="xxs:bg-dex-black-700 border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-600"
                colSpan={COLUMNS.length}
              >
                Buy
              </Table.RowGroup>
              {buyTxs.map((level, index) => {
                const limitLevel = buyLimits.find((x) => x.id === level.id);
                const isBuyWithRetryFailing = limitLevel
                  ? isOrderWithRetryFailing(level.status) && limitLevel.retry
                  : false;

                return isBuyWithRetryFailing && limitLevel ? (
                  <PlannedLimitOrderRow
                    chain={position.chain}
                    key={limitLevel.id}
                    level={limitLevel}
                    name={`Buy Limit`}
                    index={index}
                    tokenSymbol={position.tokenSymbol}
                  />
                ) : (
                  <LevelRow
                    key={level.id}
                    position={position}
                    level={level}
                    index={index}
                  />
                );
              })}
              {buyLimits.length
                ? buyLimits.map((level, index) => {
                    const limitLevel = buyTxs.find(
                      (x) => x.id === level.transactionId
                    );
                    return !limitLevel ? (
                      <PlannedLimitOrderRow
                        chain={position.chain}
                        key={level.id}
                        level={level}
                        name={`Buy Limit`}
                        index={index}
                        tokenSymbol={position.tokenSymbol}
                      />
                    ) : (
                      ''
                    );
                  })
                : ''}
            </Fragment>
          )}
          {(!!sellTxs.length || !!sellLimits.length) && (
            <Fragment>
              <Table.RowGroup
                className="xxs:bg-dex-black-700 border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-600"
                colSpan={COLUMNS.length}
              >
                Sell
              </Table.RowGroup>
              {closeTxs.map((level, index) => (
                <LevelRow
                  key={level.id}
                  position={position}
                  level={level}
                  index={index}
                />
              ))}
              {sellLimits.length
                ? sellLimits.map((level, index) => {
                    const limitLevel = sellTxs.find(
                      (x) => x.id === level.transactionId
                    );
                    return !limitLevel ? (
                      <PlannedLimitOrderRow
                        chain={position.chain}
                        key={level.id}
                        level={level}
                        name={`Sell Limit`}
                        index={index}
                        tokenSymbol={position.tokenSymbol}
                      />
                    ) : (
                      ''
                    );
                  })
                : ''}
            </Fragment>
          )}
          {!!position.takeProfits.length && (
            <Fragment>
              <Table.RowGroup
                className="xxs:bg-dex-black-700 border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-600"
                colSpan={COLUMNS.length}
              >
                Take profit
              </Table.RowGroup>
              {position.takeProfits.map((level, index) => {
                const tpLevel = sellTxs.find(
                  (x) => x.id === level.transactionId
                );

                const isTpWithRetryFailing = tpLevel
                  ? isOrderWithRetryFailing(tpLevel.status) && level.retry
                  : false;

                if (tpLevel && !isTpWithRetryFailing) {
                  return (
                    <LevelRow
                      key={level.id}
                      position={position}
                      name={`Take Profit`}
                      level={tpLevel}
                      index={index}
                    />
                  );
                }
                return (
                  <TakeProfitPlannedRow
                    key={level.id}
                    chain={position.chain}
                    level={level}
                    name={`Take Profit`}
                    index={index}
                    tokenSymbol={position.tokenSymbol}
                  />
                );
              })}
            </Fragment>
          )}
          {position.trailingStopLoss && (
            <Fragment>
              <Table.RowGroup
                className="xxs:bg-dex-black-700 border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-600"
                colSpan={COLUMNS.length}
              >
                Stop loss
              </Table.RowGroup>
              {slTx ? (
                <LevelRow
                  position={position}
                  name={`Stop Loss`}
                  level={slTx}
                  index={0}
                />
              ) : (
                <PlannedStopLoss
                  chain={position.chain}
                  level={position.trailingStopLoss}
                  name={`Stop Loss`}
                  index={0}
                  tokenSymbol={position.tokenSymbol}
                />
              )}
            </Fragment>
          )}
        </tbody>
      </Table>
    </div>
  );
};

interface LevelRowProps {
  position: OpenPositionListItem;
  level: OpenPositionListItem['transactions'][number];
  index: number;
  name?: string;
}

const LevelRow: FC<LevelRowProps> = ({ position, level, index, name }) => {
  return (
    <>
      <tr
        className={classNames(
          index === 0 ? '' : 'xxs:border-dex-black-700',
          'border-t xxs:bg-dex-black-800'
        )}
      >
        <Table.Td className="flex xxs:gap-1 items-center">
          <div className="flex flex-col">
            {name ||
              (level.type.includes('SNIPE')
                ? 'Buy'
                : level.type.includes('CLOSE')
                ? 'Sell'
                : '')}{' '}
            {level.transactionHash && (
              <EthAddress
                chain={position.chain}
                address={level.transactionHash}
                kind="tx"
              />
            )}
          </div>
        </Table.Td>
        <Table.Td>
          <div className="flex flex-col xxs:gap-0.5">
            <div>
              {level.side === 'BUY'
                ? `${formatNumberWithSuffix(level.amountIn)} ${chainAsset(
                    position.chain
                  )}`
                : level.amountOut
                ? `${formatNumberWithSuffix(level.amountIn)} ${
                    position.tokenSymbol
                  }`
                : '--'}{' '}
            </div>
            <div>
              {level.side === 'BUY'
                ? `${formatNumberWithSuffix(level.amountOut || 0)} ${
                    position.tokenSymbol
                  }`
                : level.amountIn
                ? `${formatNumberWithSuffix(level.amountOut || 0)} ${chainAsset(
                    position.chain
                  )}`
                : '--'}{' '}
            </div>
          </div>
        </Table.Td>
        <Table.Td>
          <div className="flex flex-col xxs:gap-0.5">
            {level.priceInUsd ? (
              <div>P: ${formatNumberWithSuffix(level.priceInUsd)}</div>
            ) : (
              '--'
            )}
            {level.marketCap && (
              <div>MC: ${formatNumberWithSuffix(level.marketCap)}</div>
            )}
          </div>
        </Table.Td>
        <Table.Td>
          {level.blockDatetime ? (
            <div className="flex flex-col xxs:gap-0.5">
              <div>
                {moment(level.blockDatetime).format('DD/MM/YY HH:mm:ss')}{' '}
              </div>
              <div>
                Block:{' '}
                <a
                  href={`https://${chainScan(position.chain)}/block/${
                    level.blockNumber
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {level.blockNumber}
                </a>
              </div>
            </div>
          ) : (
            <>--</>
          )}
        </Table.Td>
        <Table.Td>
          <div className="flex flex-col xxs:gap-0.5">
            {level.taxInEth && (
              <div>
                F: {formatNumberWithSuffix(level.taxInEth)}{' '}
                {chainAsset(position.chain)}
              </div>
            )}
            {level.gasFee ? (
              <div>
                G: {formatNumberWithSuffix(fromWei(level.gasFee.amountWei))}{' '}
                {chainAsset(position.chain)}
                ($
                {formatUSDAmount(level.gasFee.amountUsd)})
              </div>
            ) : (
              '--'
            )}
          </div>
        </Table.Td>
        <Table.Td>
          <TxStatus transaction={level} />
        </Table.Td>
      </tr>
      {level.failureReason && (
        <tr className="border-gray-200 xxs:bg-dex-black-800">
          <Table.Td
            className="  whitespace-normal pt-0 xxs:text-sm"
            colSpan={6}
          >
            <FailureReasonBox
              className="xxs:py-2 xxs:px-3 xxs:bg-dex-black-800 xxs:border-dex-black-600 xxs:border-solid xxs:border xxs:rounded overflow-x-scroll"
              level={level}
            />
          </Table.Td>
        </tr>
      )}
    </>
  );
};

function FailureReasonBox({
  level,
  ...props
}: { level: DexTransaction } & React.ComponentProps<'div'>) {
  return (
    <div {...props}>
      <div className="text-red-500 flex items-center">
        Failure reason{' '}
        <a
          href="https://docs.mizar.com/sniper-bot-manual/snipe-monitoring/transaction-errors"
          target="_blank"
          rel="noreferrer"
          className="ml-auto xxs:text-dex-white-secondary hover:text-dex-white"
        >
          Learn more <ArrowTopRightOnSquareIcon className="w-4 h-4" />
        </a>
      </div>{' '}
      <div>{level.failureReason}.</div>
    </div>
  );
}
