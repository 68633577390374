export const COLORS_DICTIONARY = {
  blue: 'hover:bg-blue-400 bg-blue-500 ',
  red: 'hover:bg-red-400 xxs:bg-red-500 ',
  yellow: 'hover:bg-yellow-400 xxs:bg-yellow-500 ',
  green: 'hover:bg-green-400 xxs:bg-green-500',
  gray: 'hover:bg-gray-400  xxs:bg-gray-500 ',
  neutral: 'xxs:bg-white-300 dark:bg-black-600',
  dexNeutral:
    'xxs:bg-dex-black-700 hover:bg-dex-black-600 xxs:text-dex-white-secondary hover:text-dex-white',
  // orange: 'xxs:bg-white-300 xxs:rounded dark:bg-black-700',
};
