import { BaseButton } from 'modules/shared-components/button/base-button';
import { twMerge } from 'tailwind-merge';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export function FilterButton({
  children,
  label,
  ...rest
}: React.ComponentProps<typeof BaseButton> & { label: string }) {
  return (
    <BaseButton
      {...rest}
      className={twMerge(
        'xxs:text-left w-full border-tailwind flex justify-between xxs:bg-dex-black-800 xxs:border-solid xxs:border-dex-black-700 xxs:px-3 xxs:py-2 xxs:h-10 xxs:rounded-md xxs:items-center xxs:gap-1 xxs:text-sm',
        rest.className
      )}
    >
      <span className="flex-grow shrink-0	xxs:text-dex-white-secondary">
        {label}
      </span>
      {children}
      <ChevronDownIcon
        className="xxs:h-4 xxs:w-4 xxs:text-dex-white-secondary"
        aria-hidden="true"
      />
    </BaseButton>
  );
}
