import {
  ChartBarSquareIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/outline';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { dexBotKeys, DexBotService } from 'api/services/DexBotService';
import { BotType, DexSniperBot } from 'api/dex-bot';
import { schema, VolatilityBotFormValues } from '../schema';
import Big from 'big.js';
import { fromWei, weiToGwei } from 'modules/ethereum/utils';
import { useToggle } from 'modules/utils/useToggle';
import { EditBotConfirmationModal } from './EditConfirmationModal';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { chainName, Chains } from 'api/d-wallets';
import {
  DeleteBotButton,
  PauseBotButton,
  ResumeBotButton,
} from '../../copy-trading/Edit/manage-bot';
import { BotSettings, TradingSettingsForm } from '../NewBot';
import { BotConfirmationModal } from '../BotConfirmationModal';
import { NotificationDex } from '../../../components/alerts/notification';
import { BOT_SIDEBAR } from '../../../components/layout/SIDEBAR_CONST';
import PageTemplate from '../../../components/layout';
import Tag from 'modules/shared-components/data-display/tag';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { LoaderDex } from '../../../components/alerts/Loader';

function botToFromValues(bot: DexSniperBot, isEdit: boolean): any {
  const trade = bot.position;
  const tp0 = trade.takeProfits[0] || trade.trailingStopLoss;
  const lo0 = trade.limitOrders[0];

  const autoRetryEnabled = !!(
    trade.takeProfits?.[0]?.retry ||
    trade.trailingStopLoss?.retry ||
    trade.limitOrders?.[0]?.retry
  );
  const autoRetry = {
    buy: !!trade.limitOrders?.[0]?.retry,
    takeProfit: !!trade.takeProfits?.[0]?.retry,
    stopLoss: !!trade.trailingStopLoss?.retry,
  };

  return {
    name: isEdit ? bot.name : bot.name + ' - copy',
    tradingSettings: {
      walletId: trade.vaultId,
      buyAmount: fromWei(trade.buyAmountInWei),
      maxBuyGasPriority: weiToGwei(trade.maxPriorityFeePerGasWei),
      slippage: Big(trade.maxSlippage).times(100).toNumber(),
      antiMev: trade.antiMev,
      chain: bot.chain ?? Chains.Ethereum,
      takeProfit: trade.takeProfits.map((tp) => ({
        id: tp.id,
        status: tp.status,
        threshold: Big(tp.priceIncreasePercentage).times(100).toNumber(),
        weight: Big(tp.amountToSellPercentage).times(100).toNumber(),
      })),
      takeProfitConfig: {
        maxSellGasPriority: tp0
          ? weiToGwei(tp0?.maxPriorityFeePerGasWei || '0')
          : 5,
        slippage: tp0 ? Big(tp0?.maxSlippage).times(100).toNumber() : 15,
        antiMev: tp0 ? tp0?.antiMev : false,
      },
      stopLoss: trade.trailingStopLoss
        ? {
            threshold: Big(trade.trailingStopLoss.initialStopLossPercentage)
              .abs()
              .times(100)
              .toNumber(),
            isTrailingEnabled:
              +trade.trailingStopLoss.trailingDeviationPercentage !== 1,
            deviation:
              +trade.trailingStopLoss.trailingDeviationPercentage === 1
                ? null
                : Big(trade.trailingStopLoss.trailingDeviationPercentage)
                    .times(100)
                    .toNumber(),
          }
        : null,
    },
    botSettings: {
      entries: {
        autoRetryEnabled,
        autoRetry,
        ...(isEdit && lo0
          ? {
              limitOrdersScale: trade.limitOrders.map((l) => ({
                id: l.id,
                amount: Big(fromWei(l.amount) ?? 0)
                  .div(fromWei(trade.buyAmountInWei) ?? 1)
                  .toNumber(),
              })),
            }
          : {}),
        priceChange: Big(bot.priceChange ?? 0).mul(-100),
        periodInMinutes: bot.periodInMinutes,
        isDcaActive: !!lo0,
        expirationInHours: lo0
          ? Big(lo0.expirationInHours ?? 0).toFixed(1)
          : 48,
        limitOrders: !lo0
          ? [
              {
                amount: 0.05,
                priceDeviationPercentage: 10,
                slippage: 10,
              },
            ]
          : trade.limitOrders.map((l) => ({
              id: l.id,
              amount: fromWei(l.amount),
              priceDeviationPercentage: Big(l.priceDeviationPercentage ?? 0)
                .times(-100)
                .toNumber(),
              slippage: Big(l.maxSlippage).mul(100).toNumber(),
            })),
        addresses: bot.addresses?.map((address) => {
          return { address };
        }),
      },
    },
  };
}

interface Props {
  id: string;
  isEdit: boolean;
}

export default function ManageVolatilityBot({ id, isEdit }: Props) {
  const {
    data: bot,
    isLoading,
    error,
    isFetching,
  } = useQuery({
    queryFn: () => DexBotService.getBot(id, BotType.Volatility),
    queryKey: dexBotKeys.getBot(id, BotType.Volatility),
  });

  if (isLoading || isFetching) {
    return (
      <PageTemplate
        classNameSidebarContainer="hidden lg:block"
        sidebar={BOT_SIDEBAR}
      >
        <div className="flex justify-center">
          <LoaderDex className="xxs:my-4" />
        </div>
      </PageTemplate>
    );
  }
  if (error) {
    return (
      <PageTemplate
        classNameSidebarContainer="hidden lg:block"
        sidebar={BOT_SIDEBAR}
      >
        {error && (
          <NotificationDex
            type="error"
            errorMessage={error}
            className="xxs:my-2"
          >
            An error occurred.
          </NotificationDex>
        )}{' '}
      </PageTemplate>
    );
  }

  return <Edit isEdit={isEdit} bot={bot!} />;
}

function Edit({ bot, isEdit }: { bot: DexSniperBot; isEdit: boolean }) {
  const [isOpen, { open: openConfirmation, close: closeConfirmation }] =
    useToggle();

  const defaultValues = botToFromValues(bot, isEdit);

  const form = useForm<VolatilityBotFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, getValues, watch } = form;

  const onSubmit = handleSubmit(() => {
    openConfirmation();
  });

  const isLarge = useMediaQuery(screenGte.large);

  return (
    <PageTemplate
      sidebar={BOT_SIDEBAR}
      classNameSidebarContainer="hidden lg:block"
      pages={
        isLarge
          ? [
              {
                href: '/dex/bots?activeTab=volatility-bot',
                name: 'Bots',
              },
              {
                href: `/dex/bots/volatility-bot/${bot.id}/${
                  isEdit ? 'edit' : 'duplicate'
                }`,
                name: `${isEdit ? 'Edit' : 'New'} ${watch('name')}`,
              },
            ]
          : [
              {
                href: '/dex/bots?activeTab=volatility-bot',
                name: 'Bots',
              },
              {
                href: '/dex/bots?activeTab=volatility-bot',
                name: 'Bots',
              },
            ]
      }
    >
      <div className="space-y-0 pb-16 lg:pb-0">
        <form
          autoComplete="off"
          onSubmit={(e) => {
            void onSubmit(e);
          }}
        >
          <FormProvider {...form}>
            <div className="xxs:space-y-2">
              <div className="xxs:space-y-2 xxs:p-2 lg:p-0">
                {isEdit && bot.insufficientBalance && (
                  <NotificationDex type="error">
                    Your wallet balance is running low! Please top up soon to
                    prevent your bot from being automatically paused.
                  </NotificationDex>
                )}
                <div className="flex space-x-2 items-center justify-between">
                  <div className="xxs:text-xl xxs:flex xxs:items-center xxs:gap-3 overflow-hidden ">
                    <ChartBarSquareIcon className="w-6 h-6 xxs:text-dex-white-secondary flex-shrink-0" />
                    <div className="truncate xxs:text-dex-white-secondary">
                      {watch('name')}
                    </div>
                    <Tag color="dexNeutral" className="h-auto">
                      <div className="flex space-x-1 items-center xxs:text-sm">
                        <div>
                          <ChainsIcon
                            imgName={
                              watch('tradingSettings.chain') ?? Chains.Base
                            }
                          />
                        </div>
                        <div>
                          {chainName(
                            watch('tradingSettings.chain') ?? Chains.Base
                          )}
                        </div>
                      </div>
                    </Tag>
                    {isEdit &&
                      (bot.status === 'ACTIVE' ? (
                        <div className="xxs:px-1 xxs:py-0.5 xxs:bg-green-500 xxs:rounded-sm font-normal xxs:text-sm max-w-max flex items-center xxs:gap-1">
                          <PlayCircleIcon className="w-5 h-5 text-white" />
                        </div>
                      ) : (
                        <div className="xxs:px-1 xxs:py-0.5 xxs:bg-yellow-500 xxs:text-sm font-normal xxs:rounded-sm max-w-max flex items-center xxs:gap-1">
                          <PauseCircleIcon className="w-5 h-5 text-white" />
                        </div>
                      ))}
                    <a
                      className="hidden lg:flex xxs:text-dex-white hover:text-dex-white"
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.mizar.com/sniper-bot-manual/bots/volatility-bot"
                    >
                      <Tag color="blue" className="h-auto">
                        Help Me
                      </Tag>
                    </a>
                  </div>

                  {isEdit && (
                    <div className="flex space-x-2 flex-shrink-0">
                      {bot.status === 'PAUSED' ? (
                        <ResumeBotButton
                          id={bot.id}
                          botType={BotType.Volatility}
                        />
                      ) : (
                        <PauseBotButton
                          id={bot.id}
                          botType={BotType.Volatility}
                        />
                      )}
                      <DeleteBotButton bot={bot} botType={BotType.Volatility} />
                    </div>
                  )}
                </div>
              </div>
              <div className="lg:space-y-2">
                <BotSettings />
                <TradingSettingsForm isEdit={isEdit} isDuplicate={!isEdit} />
              </div>

              <div className="xxs:px-2 lg:px-0">
                <PrimaryButton
                  className="xxs:col-span-2 lg:col-span-1 xxs:text-base lg:text-xs"
                  type="submit"
                  variant="primary"
                  size="large"
                >
                  SAVE
                </PrimaryButton>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
      {isOpen &&
        (isEdit ? (
          <EditBotConfirmationModal
            id={bot.id}
            bot={getValues()}
            previous={defaultValues}
            handleClose={closeConfirmation}
          />
        ) : (
          <BotConfirmationModal
            bot={getValues()}
            handleClose={closeConfirmation}
          />
        ))}
    </PageTemplate>
  );
}
