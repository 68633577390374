import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { IconButton } from '../button/IconButton';
import { Popup } from '../popup';
import { CopyContent } from '../tooltip/copy-content';
import { twMerge } from 'tailwind-merge';

export function CopyText({
  textToCopy,
  children,
  className,
}: {
  textToCopy: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <span
      className={className}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popup
        placement="top-start"
        trigger={
          <span>
            <CopyContent text={textToCopy}>{children}</CopyContent>
          </span>
        }
      >
        <div className="xxs:px-2 xxs:py-1 rounded dark:text-white-50 break-words xxs:text-xs items-center xxs:gap-1">
          <span>{textToCopy}</span>
          <div className="flex items-center gap-1">
            <CopyContent text={textToCopy}>
              <IconButton
                as="button"
                className={twMerge(
                  'xxs:p-1 bg-white-400 hover:bg-white-600 dark:hover:bg-black-600 dark:bg-black-700 xxs:rounded'
                )}
              >
                <DocumentDuplicateIcon className="xxs:text-white-900 dark:text-white-300 w-[20px] h-[20px]" />
              </IconButton>
            </CopyContent>
          </div>
        </div>
      </Popup>
    </span>
  );
}
