import { useParams } from 'react-router-dom';
import ManageVolatilityBot from './volatility/Edit/manage-bot';
import ManageBot from './copy-trading/Edit/manage-bot';
import { Tabs } from '../components/layout/SIDEBAR_CONST';

function DuplicateBotContainer() {
  const { tab: activeTab, id } = useParams<{ tab: Tabs; id: string }>();

  return activeTab === Tabs.VolatilityBot ? (
    <ManageVolatilityBot id={id} isEdit={false} />
  ) : (
    <ManageBot
      id={id}
      isEdit={false}
      isWalletCopyTrading={activeTab === Tabs.WalletCopyTrading}
    />
  );
}

export default DuplicateBotContainer;
