import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = { children: React.ReactNode } & React.ComponentProps<'div'>;

export function ActivteFiltersBar({ children, ...props }: Props) {
  const tagCount = React.Children.toArray(children)?.length;
  return tagCount > 0 ? (
    <div
      {...props}
      className={twMerge(
        'flex flex-wrap gap-1 w-full xxs:rounded xxs:p-1 lg:px-2 lg:py-1 border-tailwind xxs:border xxs:border-solid xxs:border-dex-black-700 hover:border-dex-black-600 xxs:items-center',
        props.className
      )}
    >
      <div className="hidden lg:block xxs:text-dex-white-secondary xxs:text-xs">
        Filters |
      </div>
      {children}
    </div>
  ) : null;
}
