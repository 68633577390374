import { forwardRef, SyntheticEvent, useState, CSSProperties } from 'react';
import TextInput from './text-input/text-input';

type InputProps = React.ComponentProps<typeof TextInput> & {
  name: string;
  max?: string | number;
  min?: string | number;
  error?: string;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  obligatory?: boolean;
  extraStyle?: CSSProperties;
  inputSize?: string;
  hideErrorMessage?: boolean;
};

const NOT_DECIMAL_PATTERN = /[^0-9.]/g;
const leaveOnlyFirstDot = (match: string, offset: number, all: string) =>
  all.indexOf('.') === offset ? '.' : '';

const NumberInput = forwardRef<HTMLInputElement, InputProps>(
  ({ max, min, error, onChange, obligatory, ...props }, ref) => {
    const [validationError, setValidationError] = useState('');

    const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
      const newValue =
        e.currentTarget.value === '.' || e.currentTarget.value === ','
          ? '0'
          : e.currentTarget.value
              .replace(',', '.')
              .replace(NOT_DECIMAL_PATTERN, '')
              .replace(/[.]/g, leaveOnlyFirstDot);

      const newFloatValue = parseFloat(
        e.currentTarget.value.replace(NOT_DECIMAL_PATTERN, '')
      );

      if (min && newFloatValue < parseFloat(min.toString())) {
        setValidationError(`Input need to be bigger or equal than ${min}.`);
      } else if (max && newFloatValue > parseFloat(max.toString())) {
        setValidationError(`Input need to be smaller or equal than ${max}.`);
      } else if (obligatory && !newFloatValue) {
        setValidationError(`Input cannot be empty.`);
      } else {
        setValidationError('');
      }

      const newEvent = {
        ...e,
        currentTarget: {
          ...e.currentTarget,
          value: newValue,
        },
        target: {
          ...e.target,
          value: newValue ? newValue : null,
        },
      };
      onChange(newEvent);
    };

    const mergedError = validationError || error;

    return (
      <TextInput
        {...props}
        ref={ref}
        inputMode="decimal"
        onChange={handleChange}
        error={mergedError}
      />
    );
  }
);

export default NumberInput;
