/* eslint-disable @typescript-eslint/no-unused-vars */
import { DexTradeStatus } from 'api/dex-trade';
import {} from 'viem';
import { twMerge } from 'tailwind-merge';
import { PendingSnipeDetails } from './PendingSnipeDetails';
import { ExecutedTradeDetails } from './ExecutedTradeDetails';
import { BlockedTradeDetails } from './BlockedTradeDetails';
import { useQuery } from '@tanstack/react-query';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import { duration } from 'moment';
import {
  refreshIntervalTime,
  useRefreshIntervalPersistedState,
} from '../../../side-panels/SnipesPanel';
import { NotificationDex } from '../../../components/alerts/notification';
import { LoaderDex } from '../../../components/alerts/Loader';
import { ErrorPageDex } from '../../../research/components/shared/Common';

export function PositionDetails({ id }: { id: string }) {
  const [refetchInterval] = useRefreshIntervalPersistedState();
  const {
    isLoading,
    data: position,
    error,
  } = useQuery({
    queryFn: () => DexTradeService.getPosition(id),
    queryKey: dexTradeKeys.getPosition(id),
    keepPreviousData: true,
    refetchInterval: duration(
      refreshIntervalTime(refetchInterval),
      'seconds'
    ).asMilliseconds(),
  });

  if (error) {
    return <ErrorPageDex />;
  } else if (isLoading || !position) {
    return (
      <div className="flex justify-center h-[500px] items-center">
        <LoaderDex />
      </div>
    );
  } else {
    return position.status === DexTradeStatus.cancelledByUserSettings ? (
      <BlockedTradeDetails position={position} />
    ) : !position.protocol ? (
      <PendingSnipeDetails position={position} />
    ) : (
      <ExecutedTradeDetails position={position} />
    );
  }
}

export function Wrapper(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={twMerge(
        'flex flex-col xxs:gap-2 xxs:bg-dex-black-900 lg:bg-dex-black-900 xxs:px-2 lg:p-2',
        props.className
      )}
    />
  );
}
