import { twMerge } from 'tailwind-merge';

interface FilterOptionProps {
  filterOptions: {
    label: string;
    onClick: () => void;
    condition: boolean;
    extraClasses: string;
  }[];
}

const QuickFilters = ({ filterOptions }: FilterOptionProps) => {
  return (
    <div className="flex space-x-1 items-center xxs:px-1 xxs:text-dex-white-secondary">
      <div>Quick Filters:</div>
      {filterOptions.map((filter, index) => (
        <div
          className={`${filter.extraClasses}  space-x-1 items-center`}
          key={index}
        >
          <button
            onClick={filter.onClick}
            className={twMerge(
              'hover:text-dex-white',
              filter.condition
                ? 'xxs:text-dex-white'
                : 'xxs:text-dex-white-secondary'
            )}
          >
            {filter.label}
          </button>
          {index !== filterOptions.length - 1 && (
            <div
              className={
                filter.extraClasses || (index < 2 ? '' : 'hidden lg:block')
              }
            >
              |
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default QuickFilters;
