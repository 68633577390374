import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../schema';
import {
  RowLabelledInput,
  RowLabel,
} from '../../../components/RowLabelledInput';
import { InfoBox } from './MaxSnipesNumber';
import NumberInput from '../../../components/inputs/number-input';
import Tooltip from 'modules/shared-components/tooltip';
import Checkbox from '../../../components/inputs/Checkbox';
import { SortDropdown } from '../../../components/dropdown/sort-dropdown';
import { NotificationDex } from '../../../components/alerts/notification';

export function CallsFilter() {
  const { control, watch, setValue } = useFormContext<FormValues>();

  const callsFilter = watch('botSettings.entries.callsFilter');

  return (
    <div>
      <div className="flex">
        <Tooltip
          text="Configure the minimum amount of calls in a specific time period that a
          token should get to be traded."
        >
          <div>
            <div className="flex items-center xxs:gap-1">
              <Checkbox
                className="lg:w-4 lg:h-4 xxs:w-5 xxs:h-5"
                checked={!!callsFilter}
                onChange={(checked) => {
                  if (checked) {
                    setValue('botSettings.entries.callsFilter', {
                      minNumberOfCalls: 1,
                      timePeriod: 1,
                    });
                  } else {
                    setValue('botSettings.entries.callsFilter', null);
                  }
                }}
              />
              <div className="xxs:text-sm xxs:text-dex-white-secondary hover:text-dex-white">
                Calls Filter
              </div>
            </div>
          </div>
        </Tooltip>
      </div>

      {callsFilter && (
        <div>
          <div className="xxs:my-1">
            <InfoBox storageKey="snipe-enough-calls">
              Snipe only tokens that got at least{' '}
              {callsFilter?.minNumberOfCalls || 0} call(s) from different
              Telegram channel(s){' '}
              {
                options.filter(
                  (o) =>
                    o.value ===
                    watch('botSettings.entries.callsFilter.timePeriod')
                )?.[0]?.label
              }
              .
            </InfoBox>
          </div>

          <RowLabelledInput
            label={
              <RowLabel
                label="Min. number of calls"
                tooltip="The minimum number of Telegram calls that a single token must have, in the selected time window, in order to be sniped by the bot. For instance, if you choose a minimum of 2 calls and a 24-hour time window, the bot will exclusively trade tokens that got at least 2 calls within the past 24 hours."
              />
            }
            className="xxs:py-1"
          >
            <Controller
              name="botSettings.entries.callsFilter.minNumberOfCalls"
              control={control}
              defaultValue={'' as any}
              render={({ field, fieldState: { error } }) => (
                <NumberInput
                  containerClassName="xxs:w-[108px]"
                  suffix={<>call(s)</>}
                  inputSize="small"
                  value={field.value}
                  onChange={(event) =>
                    field.onChange(+event.currentTarget.value)
                  }
                  error={error?.message}
                  name={''}
                />
              )}
            />
          </RowLabelledInput>
          <RowLabelledInput
            label={
              <RowLabel
                label="Time period"
                tooltip="The time window in which the Telegram Calls will be be monitored. For instance, if you choose a minimum of 2 calls and a 24-hour time window, the bot will exclusively trade tokens that got at least 2 calls within the past 24 hours."
              />
            }
            className="xxs:py-1"
          >
            <Controller
              name="botSettings.entries.callsFilter.timePeriod"
              control={control}
              defaultValue={'' as any}
              render={({ field, fieldState: { error } }) => (
                <div className="xxs:space-y-1">
                  <SortDropdown
                    {...field}
                    mainLabel="Select a Time period"
                    className="min-w-[180px]"
                    options={options}
                  />
                  {error && (
                    <NotificationDex type="error">
                      {error.message}
                    </NotificationDex>
                  )}
                </div>
              )}
            />
          </RowLabelledInput>
        </div>
      )}
    </div>
  );
}

const options = [
  {
    label: 'in the last 24 hours',
    value: 1,
  },
  {
    label: 'in the last 3 days',
    value: 3,
  },
  {
    label: 'in the last 7 days',
    value: 7,
  },
];
