import { Controller, useFormContext } from 'react-hook-form';
import {
  FormValues,
  buyAmountSchema,
  maxNumberOfSnipesSchema,
} from '../schema';
import { formatTokenAmount } from 'utils/FormatNumber';
import Big from 'big.js';
import createPersistedState from 'use-persisted-state';
import { chainAsset } from 'api/d-wallets';
import { NotificationDex } from '../../../components/alerts/notification';
import NumberInput from '../../../components/inputs/number-input';

export function MaxSnipesNumber() {
  const { control, watch } = useFormContext<FormValues>();

  const buyAmountFormValue = watch('tradingSettings.buyAmount');
  const buyAmount = buyAmountSchema.isValidSync(buyAmountFormValue)
    ? buyAmountSchema.cast(buyAmountFormValue)
    : 0;
  const maxNumberOfSnipesFormValue = watch('botSettings.maxNOfSnipes');
  const maxNumber = maxNumberOfSnipesSchema.isValidSync(
    maxNumberOfSnipesFormValue
  )
    ? maxNumberOfSnipesSchema.cast(maxNumberOfSnipesFormValue)
    : 0;

  return (
    <div>
      <Controller
        name={'botSettings.maxNOfSnipes'}
        defaultValue={'' as any}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <NumberInput
              {...field}
              className="xxs:mb-1"
              label="Max N. of Trades"
              extraLabel={
                <div className="flex space-x-1 items-center">
                  <div className="">
                    Min needed:{' '}
                    {buyAmount && maxNumber
                      ? formatTokenAmount(
                          Big(maxNumber)
                            .times(buyAmount)
                            .times(
                              watch('botSettings.entries.copyBuyMore')
                                ? watch('botSettings.entries.maxBuyMore') ?? 1
                                : 1
                            )
                            .toNumber()
                        )
                      : '--'}{' '}
                    {chainAsset(watch('tradingSettings.chain'))}
                  </div>
                </div>
              }
              tooltip="The maximum number of trades that can be opened by the bot at the same time."
              suffix={<>trades</>}
              error={error?.message}
            />
          );
        }}
      />
    </div>
  );
}

export function InfoBox({
  children,
  storageKey,
  type,
}: {
  children: React.ReactNode;
  storageKey: string;
  type?: 'error' | 'success' | 'warning' | 'info';
}) {
  const [isDismissed, setDismissed] = createPersistedState<boolean>(
    `infoBoxState-${storageKey}`
  )(false);

  if (isDismissed) {
    return null;
  }

  return (
    <NotificationDex
      type={type ? type : 'info'}
      size="xxSmall"
      onClose={() => setDismissed(true)}
    >
      {children}
    </NotificationDex>
  );
}
