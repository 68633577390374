import { ClockIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { TelegramCallDetailsInfo } from 'api/telegram-channels';
import { DurationCounter } from './columns';
import { ReactComponent as TelegramLogo } from 'assets/img/news/telegram.svg';
import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  TelegramChannelsKeys,
  TelegramChannelsService,
} from 'api/services/TelegramChannelsService';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';
import { Chains } from 'api/d-wallets';
import { LoaderDex } from '../../../components/alerts/Loader';
import { FullScreenModal } from '../../../components/modal/FullScreenModal';
import { NotificationDex } from '../../../components/alerts/notification';
import { Tabs } from '../../../components/layout/SIDEBAR_CONST';

type Props = {
  close: () => void;
  contract: string;
  chain: Chains;
};

export function CallsDetailsModal({ close, contract, chain }: Props) {
  const {
    data: options,
    error,
    isInitialLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryFn: ({ pageParam = { limit: 10, offset: 0 } }) =>
      TelegramChannelsService.getContractDetailedChannels({
        contractAddress: contract,
        chain,
        limit: pageParam.limit,
        offset: pageParam.offset,
      }),
    queryKey: TelegramChannelsKeys.getContractDetailedChannels(chain, contract),
    getNextPageParam: (lastPage) => ({
      offset: lastPage.pagination.offset + lastPage.pagination.limit,
      limit: 10,
    }),
  });

  const items = options?.pages?.flatMap((page) => page.data) || [];
  const pagination = options?.pages?.[0]?.pagination;

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        void fetchNextPage();
      }
    },
  });

  return (
    <FullScreenModal
      close={close}
      className="h-screen lg:h-[50vh] overflow-auto"
      backdropClassName="dark:bg-dex-black-800/80"
    >
      <div className="lg:px-8 ">
        <div className="max-w-[720px] mx-auto">
          {error && (
            <NotificationDex
              type="error"
              errorMessage={error}
              className="xxs:py-1 xxs:mb-3 xxs:pl-3 xxs:text-red-500"
            >
              Could not load options
            </NotificationDex>
          )}

          {!error && isInitialLoading ? (
            <div className="flex justify-center">
              <LoaderDex />
            </div>
          ) : (
            <>
              {pagination && (
                <div className="xxs:text-dex-white-secondary xxs:text-xs xxs:mb-1 xxs:ml-3 lg:ml-0 flex items-center xxs:gap-1">
                  Called by {pagination.total} Channels
                </div>
              )}

              {items.length > 0 ? (
                items.map((option) => (
                  <CallsResultItem key={option.channelLink} option={option} />
                ))
              ) : (
                <div className="xxs:py-1 xxs:mb-3 xxs:pl-3 xxs:text-red-500">
                  No channels found
                </div>
              )}

              {pagination && pagination?.total > items.length && (
                <div className="flex justify-center xxs:py-2">
                  <LoaderDex ref={ref} className="mx-auto" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </FullScreenModal>
  );
}

export function CallsResultItem({
  option,
  size,
}: {
  option: TelegramCallDetailsInfo;
  size?: string;
}) {
  const history = useHistory();
  return (
    <button
      onClick={() =>
        history.push({
          pathname: `/dex/research/${Tabs.TelegramChannels}/${option.channelId}/details`,
          state: {
            from: `${history.location.pathname}`,
            params: `${history.location.search}`,
          },
        })
      }
      className={twMerge(
        'flex items-center dark:text-white-50 w-full cursor-pointer',
        size === 'sm'
          ? 'bg-dex-black-800 hover:bg-dex-black-700 rounded'
          : 'hover:bg-dex-black-800'
      )}
    >
      <OptionColumn className={size === 'sm' ? 'xxs:p-2' : ''}>
        <div className="flex gap-1 items-center">
          <TelegramLogo
            width={size === 'sm' ? 12 : 20}
            height={size === 'sm' ? 12 : 20}
            className={twMerge(
              'xxs:fill-dex-white',
              size === 'sm' ? 'hidden' : 'block'
            )}
          />
          <div
            className={twMerge(
              'font-sm',
              size === 'sm' ? 'text-xs' : 'text-lg '
            )}
          >
            {option.channelName}
          </div>
        </div>
        <div className="flex gap-1 items-center xxs:text-dex-white-secondary">
          <ClockIcon className="w-4 h-4" />
          <div className="text-xs text-normal">
            <DurationCounter start={option.callDatetime} /> ago
          </div>
        </div>
      </OptionColumn>

      {size === 'sm' && (
        <OptionColumn className="xxs:p-1 flex justify-end xxs:text-xs">
          <a
            href={option.postLink}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-dex-white xxs:text-dex-white-secondary"
          >
            {option.postLink}
          </a>
        </OptionColumn>
      )}

      {size !== 'sm' && (
        <OptionColumn>
          <div className="text-sm truncate xxs:text-dex-white-secondary xxs:text-xs">
            Source:{' '}
            <a
              href={option.postLink}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-dex-white xxs:text-dex-white-secondary"
            >
              {option.postLink}
            </a>
          </div>
          <div className="text-sm truncate xxs:text-dex-white-secondary xxs:text-xs">
            Channel:{' '}
            <a
              href={option.channelLink}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-dex-white xxs:text-dex-white-secondary"
            >
              {option.channelLink}
            </a>
          </div>
        </OptionColumn>
      )}
    </button>
  );
}

const OptionColumn = (props: React.ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge('xxs:p-4 overflow-hidden w-full', props.className)}
    />
  );
};
