import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  BookOpenIcon,
  StarIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import mizarIcon from 'assets/img/logo/mizar_icon.svg';
import { getAssetsUrl } from 'utils/urlUtils';
import { formatWithoutDecimals } from 'utils/FormatNumber';
import { FormatTextDelta } from 'utils/FormatText';
import { Link, NavLink, useHistory } from 'react-router-dom';
import config from 'config';
import classNames from 'classnames';
import {
  cexMenu,
  cexNavigation,
  dexMenu,
  dexMobileNavigation,
  dexNavigation,
} from './config';
import ExpandableItem from './components/ExpandableItem';
import { Button } from 'modules/shared-components/button/button';
import CreditsOverview from './components/CreditsOverview';

import { useUserState } from 'modules/user/UserContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import UserService from 'api/services/UserService';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import { duration } from 'moment';
import { useStarLevel } from 'api/services/StarLevelService';
import { ToggleButton } from 'modules/shared-components/ToggleButton';
import {
  DexSwitchMode,
  useExchangeMode,
} from 'modules/DexSwitch/DexSwitchContext';
import { twMerge } from 'tailwind-merge';
import {
  GasInfo,
  handleChainChange,
  useChainPersistedState,
} from './components/GasInfo';
import { UserV1 } from 'api/user';
import { LinkAccountButton } from './components/LinkAccountButton';
import Div100vh from 'react-div-100vh';
import { PLANS } from '../../../app/dashboard/fee-manager-new/components/plansComponents/PLANS';
import { FeeService, feesInfoKeys } from 'api/services/FeeServiceNew';
import { StarLevelResponse } from 'api/star-level';
import Tooltip from 'modules/shared-components/tooltip';
import {
  marketplaceKeys,
  MarketplaceService,
} from 'api/services/MarketplaceService';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { Controller, useForm } from 'react-hook-form';
import { FormValues, schema } from '../../../app/dex/trade/schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { SearchAddress } from '../../../app/dex/research/components/Search';
import { Favourites } from '../../../app/dex/research/components/Favourites';
import { Chains } from 'api/d-wallets';
import { CHAINS_OPTIONS } from '../../../app/dex/side-panels/WalletsPanel';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { Tabs } from '../../../app/dex/components/layout/SIDEBAR_CONST';

export function SearchRow() {
  const isDesktop = useMediaQuery(screenGte.large);

  const [selectedChain, setSelectedChain] = useChainPersistedState(Chains.Base);

  const { user } = useUserState();
  const history = useHistory();
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const { control } = form;

  return (
    <Controller
      name="address"
      control={control}
      render={({ field }) => (
        <div className="flex xxs:gap-1 ">
          <SearchAddress
            className="relative"
            selected={field.value}
            onSelect={(chain, addr) => {
              if (selectedChain !== chain) {
                setSelectedChain(chain);
              }

              history.push({
                pathname: isDesktop
                  ? `/dex/snipe/new/${chain}/${addr}`
                  : `/dex/research/${chain}/${addr}`,
                state: {
                  from: history.location.pathname,
                },
              });
            }}
          />
          {user && (
            <Favourites
              onSelect={(chain, address) => {
                history.push({
                  pathname: isDesktop
                    ? `/dex/snipe/new/${chain}/${address}`
                    : `/dex/research/${chain}/${address}`,
                  state: {
                    from: history.location.pathname,
                  },
                });
              }}
            >
              {({ open }) => (
                <div
                  className="xxs:px-2 flex grow cursor-pointer rounded xxs:bg-dex-black-700 hover:bg-dex-black-600 justify-center items-center"
                  onClick={open}
                >
                  <StarIcon className="xxs:w-5 xxs:h-5" />
                </div>
              )}
            </Favourites>
          )}
        </div>
      )}
    />
  );
}

const AvatarImg = ({
  user,
  starLevel,
}: {
  user: UserV1 | undefined;
  starLevel: StarLevelResponse | undefined;
}) => {
  if (user && user.avatar_url) {
    return (
      <img
        className="rounded-circle xxs:h-10 xxs:w-10"
        src={user.avatar_url}
        alt="Mizar avatar"
      />
    );
  } else if (starLevel) {
    return (
      <img
        alt="User Star Level"
        className="rounded-circle xxs:h-10 xxs:w-10"
        src={getAssetsUrl() + '/static/img/stars/v2/' + starLevel.img + '.png'}
      />
    );
  } else {
    return (
      <img
        className="rounded-circle xxs:h-10 xxs:w-10"
        src={mizarIcon}
        alt="Mizar Icon"
      />
    );
  }
};
export default function TopNavbar() {
  const history = useHistory();
  const { user, logoutUser } = useUserState();
  const { data: starLevel } = useStarLevel();

  const { mode, setMode } = useExchangeMode();

  const { data: plan } = useQuery({
    queryKey: feesInfoKeys.getPlanInfo(),
    queryFn: () => FeeService.getPlanInfo(),
    staleTime: 10 * 60 * 1000,
    enabled: !!user,
  });

  const { data: traderLevel } = useQuery({
    queryKey: marketplaceKeys.getUserTraderLevel(),
    queryFn: () => MarketplaceService.getUserTraderLevel(),
    staleTime: duration('2', 'hour').asMilliseconds(),
    cacheTime: duration('2', 'hour').asMilliseconds(),
    enabled: !!user,
  });

  const activePlanId = plan ? plan.activePlanId : null;
  const activePlanDetail = PLANS.find((p) => p.id === activePlanId) ?? null;

  const { mutate: logout } = useMutation({
    mutationFn: UserService.logout,
    onSuccess: logoutUser,
  });

  const navItems = mode === DexSwitchMode.Cex ? cexNavigation : dexNavigation;
  const menu = mode === DexSwitchMode.Cex ? cexMenu : dexMenu;

  return (
    <Disclosure
      as="nav"
      className={twMerge(
        'xxs:fixed xxs:top-0 xxs:inset-x-0 z-30',
        mode === DexSwitchMode.Cex
          ? 'xxs:bg-black-800 '
          : 'xxs:bg-dex-black-800 border-tailwind border-x-0 border-t-0 xxs:border-dex-black-700 '
      )}
    >
      {({ open, close }) => (
        <>
          <div className="lg:mx-2">
            <div className="xxs:px-2 lg:px-0 relative flex items-center sm:justify-between h-16 xxs:w-full mx-auto">
              <div className="flex xxs:space-x-2">
                <div className="inset-y-0 flex items-center sm:hidden">
                  <Disclosure.Button
                    data-testid="mobile-nav-toggle"
                    className={twMerge(
                      mode === DexSwitchMode.Cex
                        ? 'inline-flex lg:hidden items-center justify-center xxs:p-2 rounded-md text-gray-400 hover:text-white hover:bg-black-700 focus:outline-none border-none'
                        : twMerge(
                            open ? 'bg-dex-black-700' : '',
                            'inline-flex lg:hidden items-center justify-center xxs:p-2 rounded-md xxs:text-dex-white-secondary hover:text-dex-white focus:outline-none border-none'
                          )
                    )}
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <a
                  className="xxs:ml-2 hidden lg:block"
                  href={'https://' + config.APPLICATION_DOMAIN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="h-8 w-auto"
                      src={mizarIcon}
                      alt="mizar_icon"
                    />
                  </div>
                </a>
                {user && !config.isTelegramBrowser && (
                  <ToggleButton
                    className="xxs:ml-3"
                    id="dex_switch"
                    mode={mode as DexSwitchMode}
                    options={[
                      {
                        name: 'DEX',
                        value: DexSwitchMode.Dex,
                      },
                      {
                        name: 'CEX',
                        value: DexSwitchMode.Cex,
                      },
                    ]}
                    onChange={(m: DexSwitchMode) => {
                      setMode(m);
                      if (m === DexSwitchMode.Dex) {
                        history.push('/dex');
                      }
                      if (m === DexSwitchMode.Cex) {
                        history.push('/');
                      }
                    }}
                    value={mode}
                  />
                )}
              </div>

              <div
                className={twMerge(
                  ' flex items-center justify-center lg:items-stretch lg:justify-start',
                  mode === DexSwitchMode.Cex ? 'flex-1' : ''
                )}
              >
                {(user || mode === DexSwitchMode.Dex) && (
                  <div className="hidden lg:block sm:ml-2">
                    <div className="flex xxs:space-x-1">
                      {navItems.map((item) => {
                        return (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            id={item.id}
                            exact={false}
                            strict={false}
                            className={classNames(
                              mode === DexSwitchMode.Cex
                                ? 'xxs:px-3 xxs:py-2 rounded-md text-xs font-medium xxs:flex xxs:flex-nowrap items-center text-white-900 dark:text-gray-300 hover:bg-white-200 dark:hover:bg-black-700 dark:hover:text-white'
                                : 'xxs:px-2 xl:px-3 xxs:py-1 xl:py-2 rounded-md text-xs font-medium xxs:flex xxs:flex-nowrap items-center xxs:text-dex-white-secondary hover:text-dex-white'
                            )}
                            activeClassName={classNames(
                              mode === DexSwitchMode.Cex
                                ? 'bg-white-300 dark:bg-black-900 text-white-900 dark:text-white'
                                : 'xxs:!text-dex-white xxs:bg-dex-black-700'
                            )}
                          >
                            {item.name}
                            {item.tag && (
                              <div className="xxs:ml-2">
                                <div className="xxs:bg-green-600 xxs:text-green-500 xxs:p-1 rounded">
                                  {item.tag}
                                </div>
                              </div>
                            )}
                          </NavLink>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {mode === DexSwitchMode.Dex && (
                <div className="xxs:hidden lg:flex lg:flex-1 justify-center">
                  <div className="xxs:w-full sm:w-2/3 xxl:w-1/2">
                    <SearchRow />
                  </div>
                </div>
              )}
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 space-x-2">
                {mode === DexSwitchMode.Cex && <PriceInfo mode={mode!} />}
                {mode === DexSwitchMode.Dex && !!user && <LinkAccountButton />}
                {mode === DexSwitchMode.Dex && <GasInfo />}
                {mode === DexSwitchMode.Cex && <CreditsOverview />}

                {!user && (
                  <Button
                    className="xxs:ml-2"
                    variant="primary"
                    as={Link}
                    to="/login"
                  >
                    Log In
                  </Button>
                )}
                {!!user && (
                  <Menu
                    as="div"
                    className="xxs:ml-3 relative z-1020"
                    id="account_star"
                  >
                    <div>
                      <Menu.Button className="border-none">
                        <span className="sr-only">Open user menu</span>
                        <div className="">
                          {starLevel ? (
                            <div className="flex space-x-1 items-center">
                              <AvatarImg
                                user={user as UserV1}
                                starLevel={starLevel}
                              />
                              {mode === DexSwitchMode.Cex &&
                              activePlanDetail ? (
                                <div className="hidden xl:block text-left space-y-0">
                                  <div className="text-xs">
                                    {activePlanDetail?.label ?? '--'}
                                  </div>

                                  {activePlanId !== 'pro' &&
                                  activePlanId !== 'institutional' ? (
                                    <Link
                                      to="/dashboard/fee-manager?activeTab=Plan"
                                      className="text-blue-500 hover:text-blue-600 text-[10px]"
                                    >
                                      Upgrade
                                    </Link>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          ) : (
                            <div className="h-10 w-10" />
                          )}
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className=" dark:bg-black-700 dark:text-white-50 fixed lg:absolute top-16 lg:top-12 lg:mt-1 left-0 lg:left-auto right-0 lg:w-64 h-screen lg:h-auto overflow-y-auto lg:overflow-y-auto lg:rounded-md shadow-lg pt-1 xxs:bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <div className="xxs:px-2 pt-1 flex justify-center">
                            {(user as UserV1) &&
                            'username' in user &&
                            user.username ? (
                              <div className="flex space-x-1 items-center">
                                {activePlanDetail &&
                                mode === DexSwitchMode.Cex ? (
                                  <div className="flex space-x-1 items-center text-xs">
                                    <Link
                                      to="/dashboard/fee-manager?activeTab=Plan"
                                      className="text-white"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Tooltip text="Your Mizar plan, used to get access to more advanced features. Click to learn more.">
                                        <div>
                                          {activePlanDetail?.label ?? '--'}
                                        </div>
                                      </Tooltip>
                                    </Link>
                                    <div>|</div>
                                    {starLevel ? (
                                      <Link
                                        to="/dashboard/staking"
                                        className="text-white"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Tooltip text="Your Star level, used to reduce fees. Click to learn more.">
                                          <div className="flex space-x-1 items-center">
                                            <img
                                              alt="User Star Level"
                                              className="rounded-circle h-4 w-4"
                                              src={
                                                getAssetsUrl() +
                                                '/static/img/stars/v2/' +
                                                starLevel.img +
                                                '.png'
                                              }
                                            />

                                            <div>{starLevel.level}</div>
                                          </div>
                                        </Tooltip>
                                      </Link>
                                    ) : (
                                      '--'
                                    )}
                                    <div>|</div>
                                    {traderLevel ? (
                                      <Link
                                        to="/dashboard/rewards-dashboard?activeTab=Performance"
                                        className="text-white"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Tooltip text="Your trader level, used to get access to more advanced features and rewards when you're copy-traded. Click to learn more.">
                                          <div className="flex space-x-1 items-center">
                                            <img
                                              alt={`trader-level-${traderLevel?.traderLevel.name.toLowerCase()}`}
                                              className="h-4 w-4"
                                              src={require(`assets/img/trader-levels/${traderLevel?.traderLevel.name.toLowerCase()}.png`)}
                                            />
                                            <div className="capitalize">
                                              {traderLevel?.traderLevel.name.toLowerCase()}
                                            </div>
                                          </div>
                                        </Tooltip>
                                      </Link>
                                    ) : (
                                      '--'
                                    )}
                                  </div>
                                ) : (
                                  <div>{user?.username}</div>
                                )}
                              </div>
                            ) : (
                              '--'
                            )}
                          </div>
                        </Menu.Item>

                        <div className="h-0.5 xxs:mt-2 bg-white-400 dark:bg-black-500" />
                        {menu.map((item) => {
                          return (
                            <Menu.Item key={item.name}>
                              <NavLink
                                to={item.href}
                                activeClassName="bg-black-100 dark:bg-black-800"
                                className={classNames(
                                  'block xxs:px-8 lg:px-4 xxs:py-5 lg:py-2 text-xs text-gray-700  dark:text-white-500 hover:dark:text-50 dark:hover:bg-black-800 hover:bg-black-100'
                                )}
                              >
                                {item.icon}
                                <span className="ml-2">{item.name}</span>
                              </NavLink>
                            </Menu.Item>
                          );
                        })}
                        <Menu.Item>
                          <button
                            onClick={() => logout()}
                            className=" w-100 xxs:px-8 lg:px-4 xxs:py-5 lg:py-2 text-xs text-gray-700 dark:hover:bg-black-800   dark:text-white-500 hover:dark:text-50 border-none hover:bg-black-100 text-left"
                          >
                            <ArrowLeftOnRectangleIcon className="h-5 xxs:mr-2" />{' '}
                            LOGOUT
                          </button>
                        </Menu.Item>

                        <div className="h-0.5 xxs:mt-2 bg-white-400 dark:bg-black-500" />

                        <Menu.Item>
                          {mode === DexSwitchMode.Dex ? (
                            <a
                              href="https://docs.mizar.com/sniper-bot-manual"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="hidden lg:block xxs:px-4 xxs:py-3 text-sm bg-blue-500  text-white border-none hover:bg-blue-600 text-left"
                            >
                              <BookOpenIcon className="h-5 w-5 xxs:mr-2" />{' '}
                              <span className="self-center">SNIPER MANUAL</span>
                            </a>
                          ) : (
                            <a
                              href="https://docs.mizar.com/c-mizar-manual/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="hidden lg:block xxs:px-4 xxs:py-3 text-sm bg-blue-500  text-white border-none hover:bg-blue-600 text-left"
                            >
                              <BookOpenIcon className="h-5 w-5 xxs:mr-2" />{' '}
                              <span className="self-center">DOCS</span>
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>

          {mode === DexSwitchMode.Cex ? (
            <Disclosure.Panel className="lg:hidden fixed top-16 w-full bg-white-200 dark:bg-black-900 z-1020 h-screen overflow-y-scroll">
              <div
                data-testid="mobile-sidebar"
                className="relative xxs:p-6 flex flex-column justify-between overflow-y-scroll"
                style={{ height: 'calc(100vh - 4rem)' }}
              >
                <div className="pb-[64px] space-y-4">
                  {navItems.map((item) => {
                    const isActive =
                      window.location.pathname.indexOf(item.href) >= 0;
                    if (item.subMenus)
                      return (
                        <ExpandableItem
                          key={item.id}
                          icon={item.icon}
                          name={item.name}
                          isActive={isActive}
                          closeNav={close}
                          subMenus={item.subMenus}
                          tag={item.tag}
                        />
                      );
                    return (
                      <Disclosure.Button
                        key={item.name}
                        as={NavLink}
                        to={item.href}
                        className={classNames(
                          'hover:bg-blue-100 dark:hover:bg-blue-900 text-gray-500 dark:text-gray-300',
                          'block xxs:px-3 xxs:py-6 rounded-md text-base font-medium dark:hover:text-white hover:text-black-800 dark:text-white xxs:inline-flex xxs:items-center xxs:flex-nowrap xxs:w-full'
                        )}
                        activeClassName="bg-blue-200 dark:bg-blue-800 xxs:text-black-900 xxs:dark:text-white"
                        aria-current={isActive ? 'page' : undefined}
                      >
                        <span className="text-blue-600 mr-2">{item.icon}</span>{' '}
                        {item.name}
                        {item.tag && (
                          <div className="xxs:ml-2 xxs:flex items-center">
                            <div className="xxs:ml-2">
                              <div className="xxs:bg-green-600 xxs:text-green-500 xxs:p-1 rounded">
                                {(item as { tag?: string }).tag}
                              </div>
                            </div>
                          </div>
                        )}
                      </Disclosure.Button>
                    );
                  })}
                </div>
                <nav className="flex justify-center space-x-4 py-8 bottom-0 left-0 fixed xxs:bg-white w-full dark:bg-black-800">
                  <a
                    href="https://docs.mizar.com/c-mizar-manual/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-white-900 hover:text-white-700 dark:text-white-600 dark:hover:text-white-700"
                  >
                    DOCS
                  </a>
                  <span
                    className="inline-block border-l border-tailwind border-gray-300"
                    aria-hidden="true"
                  />
                  <a
                    href="https://discord.gg/gM4mAYMeWG"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-white-900 hover:text-white-700 dark:text-white-600 dark:hover:text-white-700"
                  >
                    LIVE SUPPORT
                  </a>
                  <span
                    className="inline-block border-tailwind border-l border-gray-300"
                    aria-hidden="true"
                  />
                  <a
                    href="https://twitter.com/mizar_com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-white-900 hover:text-white-700 dark:text-white-600 dark:hover:text-white-700"
                  >
                    TWITTER
                  </a>
                </nav>
              </div>
            </Disclosure.Panel>
          ) : (
            <DexMenuMobile />
          )}
        </>
      )}
    </Disclosure>
  );
}

export function ChainSwitchMobile() {
  const [selectedChain, setSelectedChain] = useChainPersistedState(Chains.Base);
  const history = useHistory();
  return (
    <div className="flex items-center xxs:space-x-2 xxs:px-4" id="chains">
      {CHAINS_OPTIONS.map((chain) => {
        return (
          <Button
            onClick={() => {
              handleChainChange({
                chainValue: chain.value,
                setSelectedChain,
                history,
              });
            }}
            key={chain.value}
            className={twMerge(
              selectedChain === chain.value
                ? 'bg-dex-black-700'
                : 'bg-dex-black-800',
              'xxs:w-full xxs:p-2 rounded '
            )}
          >
            <div className="flex xxs:space-x-1">
              <div className="">
                <ChainsIcon imgName={chain.value} />
              </div>

              <div className="xxs:text-xs">{chain.label}</div>
            </div>
          </Button>
        );
      })}
    </div>
  );
}

export function GeneralLinksMobile() {
  return (
    <div className="grid grid-cols-2 xxs:px-4">
      {config.isTelegramBrowser ? (
        <Button
          className="m-0 normal-case flex flex-col justify-center"
          as={NavLink}
          to={`/dex/alpha-program/${Tabs.Leaderboard}`}
          type="button"
        >
          <div className="xxs:flex justify-center xxs:space-x-2 items-center xxs:text-dex-white-secondary xxs:text-base">
            <div>Alpha</div>
            <div className="xxs:bg-green-600 xxs:text-green-500 xxs:p-1 rounded">
              NEW
            </div>
          </div>
        </Button>
      ) : (
        <Disclosure.Button
          className="m-0 normal-case flex flex-col justify-center"
          as={NavLink}
          to={`/dex/alpha-program/${Tabs.Leaderboard}`}
          type="button"
        >
          <div className="xxs:flex justify-center xxs:space-x-2 items-center xxs:text-dex-white-secondary xxs:text-base">
            <div>Alpha</div>
            <div className="xxs:bg-green-600 xxs:text-green-500 xxs:p-1 rounded">
              NEW
            </div>
          </div>
        </Disclosure.Button>
      )}
      <Button
        as="a"
        href="https://docs.mizar.com/sniper-bot-manual"
        target="_blank"
        rel="noreferrer"
      >
        <div className="xxs:flex normal-case justify-center xxs:space-x-2 items-center xxs:text-dex-white-secondary xxs:text-base">
          <BookOpenIcon className="xxs:w-7 xxs:h-7" />
          <div>Docs</div>
        </div>
      </Button>
    </div>
  );
}

export function DexMenuMobile() {
  return (
    <Disclosure.Panel className="lg:hidden flex  w-full xxs:bg-dex-black-800 z-100">
      <Div100vh className="flex flex-col xxs:space-y-4 w-full xxs:py-4">
        <ChainSwitchMobile />
        <div className="xxs:gap-4 xxs:px-4 grid my-auto w-full grid-cols-2 relative">
          {dexMobileNavigation.map((item) => {
            const isActive = window.location.pathname.indexOf(item.href) >= 0;
            return (
              <Disclosure.Button
                key={item.href}
                as={NavLink}
                className={twMerge(
                  'rounded-md border-tailwind xxs:border-dex-black-700 xxs:p-4 flex flex-col items-center justify-center xxs:text-2xl xxs:font-semibold ',
                  isActive && 'xxs:bg-dex-black-700 '
                )}
                to={item.href}
              >
                <div
                  className={twMerge(
                    isActive
                      ? 'xxs:text-dex-white '
                      : 'xxs:text-dex-white-secondary ',
                    'space-y-1 text-center'
                  )}
                >
                  {item.icon}
                  <div className="text-sm">{item.name}</div>
                </div>
              </Disclosure.Button>
            );
          })}
        </div>
        <div className="xxs:pb-20">
          <GeneralLinksMobile />
        </div>
      </Div100vh>
    </Disclosure.Panel>
  );
}

function PriceInfo({ mode }: { mode: DexSwitchMode }) {
  const { data: prices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    refetchInterval: duration('1', 'minute').asMilliseconds(),
  });

  return (
    <>
      {mode === DexSwitchMode.Cex && (
        <div className="font-sm py-1 mb-0 align-items-center xxs:mx-2 hidden xl:block">
          <img
            src={getAssetsUrl() + '/static/img/cryptocurrencies/icons/btc.png'}
            className="xxs:mr-2 align-center h-5"
            alt=""
          />
          ${prices?.btc_price ? formatWithoutDecimals(prices.btc_price) : '--'}{' '}
          <span className="  xxs:ml-1   ">
            {prices?.btc_price_delta
              ? FormatTextDelta(prices.btc_price_delta)
              : ''}
          </span>
        </div>
      )}

      <div
        className={twMerge(
          'font-sm py-1 mb-0 align-items-center xxs:ml-2 hidden',
          mode === DexSwitchMode.Cex ? '2xl:block' : 'xl:block'
        )}
      >
        <img
          src={getAssetsUrl() + '/static/img/cryptocurrencies/icons/eth.png'}
          className="xxs:mr-2 align-center h-5"
          alt=""
        />
        ${prices?.eth_price ? formatWithoutDecimals(prices.eth_price) : '--'}{' '}
        <span className="  xxs:ml-1   ">
          {prices?.eth_price_delta
            ? FormatTextDelta(prices.eth_price_delta)
            : ''}
        </span>
      </div>
    </>
  );
}

/*

function ThemeSwitcher() {
  const [theme, setTheme] = useThemeState();

  function switchTheme() {
    if (theme === 'light' || theme === undefined) {
      document.body.setAttribute('theme', 'dark');
      document.body.classList.add('dark');
      setTheme('dark');
    } else {
      document.body.setAttribute('theme', 'light');
      document.body.classList.remove('dark');
      setTheme('light');
    }
  }

  return (
    <Menu.Item>
      {theme === 'light' || theme === undefined ? (
        <button
          className="w-100 xxs:mt-4 lg:mt-0 xxs:px-8 lg:px-4 xxs:py-5 lg:py-2 text-xs text-gray-700 dark:text-white-500 dark:hover:bg-black-800 hover:dark:text-50 border-none hover:bg-black-100 text-left"
          onClick={() => switchTheme()}
        >
          <MoonIcon className="h-5 xxs:mr-2" />
          <span>DARK MODE</span>
        </button>
      ) : (
        <button
          className="w-100 xxs:mt-4 lg:mt-0 xxs:px-8 lg:px-4 xxs:py-5 lg:py-2 text-xs text-gray-700  dark:hover:bg-black-800 dark:text-white-500 hover:dark:text-50 border-none hover:bg-black-100 text-left"
          onClick={() => switchTheme()}
        >
          <SunIcon className="h-5 xxs:mr-2" />
          <span>LIGHT MODE</span>
        </button>
      )}
    </Menu.Item>
  );
}
*/
