import { Link, LinkProps, NavLink, useHistory } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { SidebarItemProps } from './SIDEBAR_CONST';
import { isPathIncluded } from './sidebar';
import { ChainSwitchMobile, GeneralLinksMobile } from 'modules/layouts/TopBar';
import Div100vh from 'react-div-100vh';
import { dexMobileNavigation } from 'modules/layouts/TopBar/config';
import Button from 'modules/shared-components/button/button';

export default function MobileDexNavPage() {
  return (
    <Div100vh className="flex flex-col xxs:space-y-4 w-full xxs:py-4 z-50">
      <ChainSwitchMobile />
      <div className="xxs:gap-4 xxs:px-4 grid my-auto w-full grid-cols-2 relative">
        {dexMobileNavigation.map((item) => {
          const isActive = window.location.pathname.indexOf(item.href) >= 0;
          return (
            <Button
              key={item.href}
              as={NavLink}
              className={twMerge(
                'rounded-md xxs:border-solid border-tailwind xxs:border-dex-black-700 xxs:p-4 flex flex-col items-center justify-center xxs:text-2xl xxs:font-semibold ',
                isActive && 'xxs:bg-dex-black-700 '
              )}
              to={item.href}
            >
              <div
                className={twMerge(
                  isActive
                    ? 'xxs:text-dex-white '
                    : 'xxs:text-dex-white-secondary ',
                  'space-y-1 text-center'
                )}
              >
                {item.icon}
                <div className="text-sm">{item.name}</div>
              </div>
            </Button>
          );
        })}
      </div>
      <div className="xxs:pb-8">
        <GeneralLinksMobile />
      </div>
    </Div100vh>
  );
}

export const MobileFooterResearch = ({
  config,
}: {
  config: SidebarItemProps[];
}) => {
  const history = useHistory();
  const url = history.location.pathname;
  const gridCols = config?.length;

  return (
    <div className="fixed lg:hidden bottom-0 right-0 left-0 z-10 bg-dex-black-800 border-tailwind border-b-0 border-x-0 border-t-1 border-dex-black-700 ">
      <div className={`grid grid-cols-${gridCols}`}>
        {config.map((item, index) => (
          <button
            key={index}
            onClick={() => (item.to ? history.push(item.to) : {})}
            className={twMerge(
              'flex justify-center border-tailwind border-l-0 border-y-0 border-black-700 xxs:py-4',
              isPathIncluded(item.to, url)
                ? 'xxs:bg-dex-black-700 xxs:text-dex-white'
                : 'xxs:text-dex-white-secondary',
              index === config.length - 1 ? '' : 'lg:pb-6'
            )}
          >
            {item.icon}
          </button>
        ))}
      </div>
    </div>
  );
};

export function NavItem(props: LinkProps & { icon: React.ReactNode }) {
  return (
    <Link
      {...props}
      className={twMerge(
        'xxs:p-2 flex flex-col items-center justify-center dark:text-black-50 xxs:text-xl xxs:font-semibold xxs:border-black-700 xxs:border-solid xxs:border',
        props.className
      )}
    >
      {props.icon}
      {props.children}
    </Link>
  );
}
