import { useNetwork } from 'wagmi';
import { ChainSelector } from 'modules/ethereum/components/ChainSelector';
import { useDepositState } from './Context';

type Props = {
  children: React.ReactNode;
};
export function ChainGuard({ children }: Props) {
  const [{ chainId }] = useDepositState();
  const { chains, chain } = useNetwork();

  if (chain?.id === Number(chainId)) {
    return <>{children}</>;
  }

  const requiredChain = chains.find((x) => x.id === chainId);

  if (!requiredChain) return null;

  return <ChainSelector chain={requiredChain} />;
}
