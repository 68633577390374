import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Listbox, ListboxOptionProps } from '@headlessui/react';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useMemo } from 'react';
import { SelectListOption, Option } from './select-option';
import { INPUT_CLASS_NAMES } from 'modules/shared-components/input/text-input/styled-input';
import { twMerge } from 'tailwind-merge';

export type Props<T extends string | number> = {
  options: Option<T>[];
  value: T | null;
  onChange: (value: Option<T>) => void;
  className?: string;
  placeholder?: string;
  isError?: boolean;
  label?: React.ReactNode;
  extraLabel?: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  error?: string;
  preSelect?: boolean;
  displayValue?: (value: Option<T>) => React.ReactNode;
  inputSize?: 'small' | 'large';
} & Omit<ListboxOptionProps<'div', string>, 'onChange' | 'value'>;

const BaseSelect = <T extends string | number>(
  {
    className,
    options,
    placeholder,
    label,
    onChange,
    value: selected,
    disabled,
    isLoading,
    isError,
    error,
    preSelect,
    extraLabel,
    displayValue = (option) => option.label,
    inputSize = 'large',
    ...rest
  }: Props<T>,
  ref: any
) => {
  const selectedOption = useMemo(
    () => options.find((option) => option.value === selected),
    [selected, options]
  );
  const handleChange = (selectedId: any) =>
    onChange(options.find((option) => option.value === selectedId)!);

  useEffect(() => {
    if (preSelect && !selected && options.length === 1) {
      onChange(options[0]);
    }
  }, [preSelect, selected, options]);

  return (
    <Listbox
      {...rest}
      as="div"
      className={className}
      value={selected || null}
      onChange={handleChange}
      disabled={disabled || isLoading}
      ref={ref}
    >
      <div className="xxs:flex justify-between">
        {label && (
          <Listbox.Label className="block text-sm font-medium text-white-800 dark:text-black-50 xxs:mb-1">
            {label}
          </Listbox.Label>
        )}
        {extraLabel}
      </div>
      <div className="relative rounded-md xxs:shadow-sm dark:shadow-none">
        <Listbox.Button
          className={twMerge(
            'overflow-hidden whitespace-nowrap xxs:flex xxs:gap-2 xxs:items-center xxs:bg-white dark:bg-transparent font-normal xxs:border xxs:border-solid border-white-600 dark:border-black-400 xxs:py-1 xxs:px-3 xxs:pr-8 text-left text-white-900 dark:text-black-50 xxs:text-sm',
            inputSize === 'small' ? 'xxs:py-1 xxs:px-2' : 'xxs:py-2 xxs:px-3',
            INPUT_CLASS_NAMES.regular,
            !selectedOption?.label && 'text-gray-500 dark:text-gray-500',
            selectedOption?.icon && 'xxs:py-1.5',
            className
          )}
        >
          {isLoading ? (
            <div className="spinner-border text-blue-500 h-4 w-4 border-2" />
          ) : selectedOption?.label ? (
            <span className="overflow-hidden flex space-x-1 items-center">
              <div>{selectedOption?.icon}</div>{' '}
              <div>{displayValue(selectedOption)}</div>
            </span>
          ) : options.length ? (
            <span className="text-xs xxs:py-0.5 xxs:text-black-100 ">
              {placeholder ? placeholder : 'Select...'}
            </span>
          ) : isError ? (
            <span className="text-red-warning text-xs xxs:py-0.5 ">
              An error occurred...
            </span>
          ) : (
            <span className="text-xs xxs:py-0.5 xxs:text-black-100">
              {placeholder ? placeholder : 'Select...'}
            </span>
          )}
          <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md xxs:px-3 border-none focus:outline-none">
            <ChevronUpDownIcon
              className={classNames(
                'h-4 w-4  ',
                disabled ? 'xxs:text-black-200' : 'xxs:text-black-100'
              )}
              aria-hidden="true"
            />
          </div>
        </Listbox.Button>
        <Listbox.Options className="list-none xxs:pl-0 xxs:border xxs:border-solid absolute z-40 xxs:mt-1 max-h-56 w-full overflow-auto rounded-md xxs:bg-white-50 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-black-600 dark:border-black-500 min-w-max">
          {options && options.length > 0 ? (
            options.map((option) => (
              <SelectListOption
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                icon={option.icon}
                label={option.label}
              />
            ))
          ) : (
            <div className="xxs:p-2">No data available...</div>
          )}
        </Listbox.Options>
      </div>
      {error && <p className="xxs:mt-1 text-sm text-red-warning">{error}</p>}
    </Listbox>
  );
};

export const Select = forwardRef(BaseSelect) as typeof BaseSelect;
