import { OpenPositionListItem } from 'api/dex-trade';
import Big from 'big.js';
import StatisticsCard from 'modules/shared-components/data-display/statistics-card';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import Tooltip from 'modules/shared-components/tooltip';
import { chainAsset } from 'api/d-wallets';
import { performanceStyle } from '../../../research/components/Wallets/columns';

interface CardsProps {
  position: OpenPositionListItem;
}

export const StatsCards = ({ position }: CardsProps) => {
  const quoteAsset = chainAsset(position.chain);
  return (
    <div className="flex flex-col lg:flex-row space-y-1 lg:space-y-0 lg:gap-0 lg:space-x-1">
      <StatisticsCard title="Holdings">
        <div className="flex flex-col">
          <StatisticsCard.Description>
            <span className="text-xs font-normal flex space-x-0.5 items-center">
              <Tooltip
                text={`The amount of ${position.tokenName} bought along with initial value in ${quoteAsset}.`}
              >
                <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                  Initial
                </div>
              </Tooltip>
              <div>:</div>
            </span>
            {position.totalBuyAmountInEth ? (
              <>
                {formatNumberWithSuffix(Big(position.totalBuyAmountInEth || 0))}{' '}
                {quoteAsset}
                <p className="text-xs font-semibold m-0 p-0">
                  {formatNumberWithSuffix(
                    Big(position.totalAmountTokenBought || 0)
                  )}{' '}
                  {position.tokenSymbol}
                </p>
              </>
            ) : (
              '--'
            )}
          </StatisticsCard.Description>
          <StatisticsCard.Description>
            <span className="text-xs font-normal flex space-x-0.5 items-center">
              <Tooltip
                text={`The current amount of ${position.tokenName} held and its actual value in ${quoteAsset}.`}
              >
                <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                  Open
                </div>
              </Tooltip>
              <div>:</div>
            </span>
            {position.unrealisedProfitAndLoss?.valueInEth ? (
              <>
                {formatNumberWithSuffix(
                  Big(position.unrealisedProfitAndLoss?.valueInEth || 0)
                )}{' '}
                {quoteAsset}
                <p className="text-xs font-semibold m-0 p-0">
                  {formatNumberWithSuffix(
                    Big(position.totalAmountTokenRemaining || 0)
                  )}{' '}
                  {position.tokenSymbol}
                </p>
              </>
            ) : (
              '--'
            )}
          </StatisticsCard.Description>
        </div>
      </StatisticsCard>

      <StatisticsCard
        title="Unrealized PnL"
        tooltip={
          position.netUnrealisedProfitAndLoss?.pnlAmountInEth
            ? `Gross PnL: ${Big(position.unrealisedProfitAndLoss?.pnlPct || 0)
                .mul(100)
                .toFixed(1)}% | Sell Tax -${Big(position.sellTax || 0)
                .mul(100)
                .toFixed(1)}% | Est. Price Impact: -${Big(
                position.priceImpact || 0
              )
                .mul(100)
                .toFixed(1)}%`
            : ''
        }
      >
        <div className="flex items-center justify-center">
          <StatisticsCard.Description>
            {position.netUnrealisedProfitAndLoss?.pnlAmountInEth &&
            !Big(position.netUnrealisedProfitAndLoss.pnlAmountInEth || 0).eq(
              0
            ) ? (
              <div className="xxs:flex xxs:space-x-1 items-end">
                <div className="">
                  {performanceStyle(
                    Big(
                      position.netUnrealisedProfitAndLoss?.pnlAmountInEth ?? 0
                    ).toString(),
                    'test-sm lg:text-lg',
                    quoteAsset
                  )}
                </div>
                <div className="">
                  {performanceStyle(
                    Big(position.netUnrealisedProfitAndLoss?.pnlPct ?? 0)
                      .mul(100)
                      .toString(),
                    'text-xs font-semibold m-0 xxs:pb-1',
                    '%'
                  )}
                </div>
              </div>
            ) : (
              '--'
            )}
          </StatisticsCard.Description>
        </div>
      </StatisticsCard>

      <StatisticsCard
        title="Realized PnL"
        tooltip="The current amount of realized Profit and Loss. Gas Fees and Mizar fees are not included."
      >
        <StatisticsCard.Description>
          {position.realisedProfitAndLoss?.pnlAmountInEth &&
          !Big(position.realisedProfitAndLoss?.pnlAmountInEth || 0).eq(0) ? (
            <div className="xxs:flex xxs:space-x-1 items-end">
              <div className="">
                {performanceStyle(
                  Big(
                    position.realisedProfitAndLoss?.pnlAmountInEth ?? 0
                  ).toString(),
                  'test-sm lg:text-lg',
                  quoteAsset
                )}
              </div>
              <div className="">
                {performanceStyle(
                  Big(position.realisedProfitAndLoss?.pnlPct ?? 0)
                    .mul(100)
                    .toString(),
                  'text-xs font-semibold m-0 xxs:pb-1',
                  '%'
                )}
              </div>
            </div>
          ) : (
            '--'
          )}
        </StatisticsCard.Description>
      </StatisticsCard>
    </div>
  );
};
