import { useDepositState } from './Context';
import {
  SubmittedTransactionHash,
  TxPendingOnchain,
} from 'modules/ethereum/components/WaitingForTx';
import { useWaitForTransaction } from 'wagmi';
import { NotificationDex } from '../../components/alerts/notification';
import CustomModal from '../../components/modal/CustomModal';

export function DepositTxPending() {
  const [state] = useDepositState();
  const { isSuccess, isLoading, error } = useWaitForTransaction({
    hash: state.depositTxHash!,
  });

  return (
    <>
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Tokens sent
        </CustomModal.Title>

        <CustomModal.Content>
          <SubmittedTransactionHash txHash={state.depositTxHash!} />
          {isLoading && <TxPendingOnchain />}
          {isSuccess && (
            <NotificationDex type="success">
              Transaction complete. Your balance should update shortly.
            </NotificationDex>
          )}
          {error && (
            <NotificationDex type="error" errorMessage={error}>
              An error occurred.
            </NotificationDex>
          )}
        </CustomModal.Content>
      </CustomModal.Body>
    </>
  );
}
