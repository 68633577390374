import { Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import { SnackBarContext } from './context';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { without } from 'lodash';
import { IconButton } from 'modules/shared-components/button/IconButton';

const SnackBar = () => {
  const { messages, setMessages } = useContext(SnackBarContext);
  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex flex-col justify-end items-end xxs:px-4 xxs:py-6 sm:items-start sm:p-6 z-1050 xxs:bottom-0 xxs:gap-2"
      >
        {messages.map((message) => (
          <div
            key={message.id || message.message}
            className="flex w-full flex-col items-center space-y-4 sm:items-end z-30"
          >
            <Transition
              show
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm xxs:overflow-hidden xxs:rounded-lg xxs:bg-white dark:bg-black-800 xxs:shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="xxs:p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {message.type === 'success' && (
                        <CheckCircleIcon
                          className="h-6 w-6 text-green-400"
                          aria-hidden="true"
                        />
                      )}
                      {message.type === 'error' && (
                        <ExclamationCircleIcon
                          className="h-6 w-6 xxs:text-red-warning"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="xxs:ml-3 w-0 flex-1 xxs:pt-0.5">
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {message.title}
                      </p>
                      <p className="xxs:mt-1 text-sm text-gray-500 dark:text-gray-400">
                        {message.message}
                      </p>
                    </div>
                    <div className="xxs:ml-4 flex flex-shrink-0">
                      <IconButton
                        as="button"
                        onClick={() => {
                          setMessages((mess) => without(mess, message));
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        ))}
      </div>
    </>
  );
};

export default SnackBar;
