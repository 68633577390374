/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EntityId,
  IChartingLibraryWidget,
} from 'modules/tw-chart/charting_library';
import { useChartEffect } from 'modules/smart-trade-terminal/helpers/use-chart-effect';
import { DexOrderStatus, OpenPositionListItem } from 'api/dex-trade';
import Big from 'big.js';
import { useState } from 'react';

export function useDrawEntries(
  chart: IChartingLibraryWidget | null,
  position: OpenPositionListItem | null
) {
  const [boughtMarkerIds, setBoughtMarkerIds] = useState<EntityId[] | []>([]);
  const [soldMarkerIds, setSoldMarkerIds] = useState<EntityId[] | []>([]);

  useChartEffect(
    chart,
    (widget) => {
      if (!position || !position.transactions) return;

      const successBuyTxs = position.transactions.filter(
        (x) => x.side === 'BUY' && x.status === DexOrderStatus.confirmedSuccess
      );

      successBuyTxs.forEach((tx, index) => {
        if (tx.blockDatetime && tx.priceInUsd) {
          const markerId = widget.activeChart().createShape(
            {
              price: Big(tx.priceInUsd).round(15).toNumber(),
              time: Math.round(new Date(tx.blockDatetime).getTime() / 1000),
              channel: 'open',
            },
            {
              disableSave: true,
              lock: true,
              shape: 'arrow_up',
              zOrder: 'top',
              overrides: {
                color: '#00CF46',
                linecolor: '#00CF46',
                backgroundColor: '#00CF46',
              },
            }
          );
          if (markerId) {
            setBoughtMarkerIds((prevIds) => [...prevIds, markerId]);
          }
        }
      });

      const successSaleTxs = position.transactions.filter(
        (x) =>
          x.type.includes('CLOSE_POSITION') &&
          x.side === 'SELL' &&
          x.status === DexOrderStatus.confirmedSuccess
      );

      successSaleTxs.forEach((tx, index) => {
        if (tx.blockDatetime && tx.priceInUsd) {
          const markerId = widget.activeChart().createShape(
            {
              price: Big(tx.priceInUsd).round(15).toNumber(),
              time: Math.round(new Date(tx.blockDatetime).getTime() / 1000),
              channel: 'open',
            },
            {
              disableSave: true,
              shape: 'arrow_down',
              lock: true,
              zOrder: 'top',
              overrides: {
                color: '#E10000',
                linecolor: '#E10000',
                backgroundColor: '#E10000',
              },
            }
          );
          if (markerId) {
            setSoldMarkerIds((prevIds) => [...prevIds, markerId]);
          }
        }
      });

      return () => {
        boughtMarkerIds.forEach((id) => widget.activeChart().removeEntity(id));
        soldMarkerIds.forEach((id) => widget.activeChart().removeEntity(id));
      };
    },
    [chart, position?.id]
  );
}
