import {
  ContactUsParams,
  SetupOtpResponse,
  CreatePasswordSignupParams,
  GetCurrentUserResponse,
  GetUserOtpConfigResponse,
  OnBoardingStatusProps,
  Permissions,
  PermissionsResponse,
  SignUpWithEmailParams,
  SignUpWithGoogleParams,
  UserHasNeverTraded,
  OtpMechanism,
  SetupOtpRequest,
  EnableOtpRequest,
  LoginRequest,
  GoogleLoginRequest,
  LoginResponse,
  GenerateCSRFResponse,
  VerifyOtpResponse,
  GoogleLoginResponse,
  PostPrimaryProductInterestBody,
  LoginWithTelegramRequest,
  UserSession,
} from 'api/user';
import { api } from 'helpers/api/apiCore';
import convertObjectToFormData from 'modules/utils/convertObjectToFormData';

type ResetPasswordRequestValues = {
  email_address: string;
  recaptcha_token: string;
};

type ChangeUserProfileParams = {
  avatar_img?: File | string;
  avatar_url?: File | string;
  username: string;
  bio: string | undefined;
  delete_avatar_img?: boolean;
};

type ResetPasswordValues = {
  password: string;
  confirm_password: string;
  token: string;
  recaptcha_token: string;
};

type ChangeTwoFactorAuthValues = {
  two_factor_authentication: boolean;
  two_factors_token: string;
};

export const SERVICE_KEY = 'user';

export const userServiceKeys = {
  getCurrent: () => [SERVICE_KEY, 'current'],
  getNotificationsPermissions: () => [SERVICE_KEY, 'notification-permissions'],
  generateCSRFToken: () => [SERVICE_KEY, 'csrf-token'],
  getOtpConfig: () => [SERVICE_KEY, 'otp'],
  getUserSessions: () => [SERVICE_KEY, 'sessions'],
  validateJWTToken: (token: string) => [
    SERVICE_KEY,
    'validate-jwt-token',
    token,
  ],
};

const UserService = {
  async generateCSRFToken() {
    const response = await api.post<{}, GenerateCSRFResponse>(
      '/api/generate-csrf-token'
    );

    return response.data.message;
  },
  async login(params: LoginRequest) {
    const response = await api.create<LoginResponse>(
      '/api/login/',
      convertObjectToFormData(params)
    );

    return response.data;
  },
  async loginWithTelegram(params: LoginWithTelegramRequest) {
    const response = await api.post<LoginWithTelegramRequest, LoginResponse>(
      '/api/v1/telegram/login',
      params
    );

    return response.data;
  },
  async linkTelegramAccount(params: LoginWithTelegramRequest) {
    const response = await api.post<LoginWithTelegramRequest, void>(
      '/api/v1/link-telegram-account',
      params
    );

    return response.data;
  },
  async logout() {
    await api.post('/api/logout/');
  },
  async validate2FA({
    email_address,
    otp,
  }: {
    email_address: string;
    otp: string;
  }) {
    const result = await api.create<VerifyOtpResponse>(
      '/api/v1/otp/verification',
      convertObjectToFormData({
        email_address,
        otp,
      })
    );

    return result.data;
  },
  async loginWithGoogle(params: GoogleLoginRequest) {
    const response = await api.create<GoogleLoginResponse>(
      '/api/v1/login-with-google',
      convertObjectToFormData(params)
    );

    return response.data;
  },
  async getCurrent() {
    const response = await api.get<GetCurrentUserResponse>('/api/v1/user');
    return response.data.data;
  },
  async getUserSessions() {
    const response = await api.get<UserSession[]>('/api/v1/user/sessions');
    return response.data;
  },
  async deleteUserSession(uuid: string) {
    const response = await api.delete<GetCurrentUserResponse>(
      `/api/v1/user/sessions/${uuid}`
    );
    return response.data.data;
  },
  async getOtpConfig() {
    const response = await api.get<GetUserOtpConfigResponse>('/api/otp/user');
    return response.data.data;
  },
  async setupOtp(mechanism: OtpMechanism) {
    const response = await api.post<SetupOtpRequest, SetupOtpResponse>(
      '/api/otp/setup/create',
      {
        mechanism,
      }
    );
    return response.data.data;
  },
  async sendOtpCodeViaMail() {
    await api.post('/api/otp/generate');
  },
  async enableOtp(otp: string) {
    await api.post<EnableOtpRequest>('/api/otp/setup/enable', {
      otp,
    });
  },
  async disableOtp(otp: string) {
    await api.post<EnableOtpRequest>('/api/otp/setup/delete', {
      otp,
    });
  },

  changeUserProfile: async (params: ChangeUserProfileParams) => {
    const result = await api.create<{ data: ChangeUserProfileParams }>(
      '/api/v2/settings/update-user-info',
      convertObjectToFormData(params)
    );

    return result.data;
  },

  async requestPasswordReset(values: ResetPasswordRequestValues) {
    const response = await api.create(
      '/api/request-password-reset/',
      convertObjectToFormData(values)
    );
    return response.data;
  },
  async resetPassword(values: ResetPasswordValues) {
    const response = await api.create(
      '/api/reset-password',
      convertObjectToFormData(values)
    );
    return response.data;
  },
  async send2FATokenViaEmail(values: { recaptcha_token: string }) {
    const response = await api.create(
      '/api/v1/2fa',
      convertObjectToFormData(values)
    );
    return response.data;
  },
  async changeTwoFactorAuth(values: ChangeTwoFactorAuthValues) {
    const response = await api.create(
      '/api/change-two-factor-auth',
      convertObjectToFormData(values)
    );
    return response.data;
  },
  async getNotificationsPermissions() {
    const response = await api.get<PermissionsResponse>(
      '/api/notifications/permissions'
    );
    return response.data;
  },
  async getUserOnBoardingStatus() {
    const response = await api.get<OnBoardingStatusProps>(
      '/api/v2/console/onboarding/status'
    );
    return response.data;
  },
  async getUserHasNeverTraded() {
    const response = await api.get<UserHasNeverTraded>(
      '/api/v2/console/user-has-never-traded'
    );
    return response.data;
  },
  async requestOnBoardingPrize() {
    const response = await api.post(`/api/v2/console/onboarding/reward`, {});
    return response.data;
  },
  async updateNotificationsPermissions(values: { permissions: [Permissions] }) {
    const response = await api.updatePatchJson<{ permissions: [Permissions] }>(
      '/api/notifications/permissions',
      values
    );
    return response.data;
  },
  async validateJWTToken(token: string) {
    return api.create(
      '/api/validate-sign-up-jwt',
      convertObjectToFormData({
        token,
      })
    );
  },
  async createPasswordSignup(params: CreatePasswordSignupParams) {
    const result = await api.create(
      '/api/create-password-for-sign-up/',
      convertObjectToFormData(params)
    );
    return result.data;
  },
  async signUpWithEmail(params: SignUpWithEmailParams) {
    const result = await api.create(
      '/api/sign-up/',
      convertObjectToFormData({
        ...params,
        referral_code: params.referral_code || '',
      })
    );
    return result.data;
  },
  async signUpWithGoogle(params: SignUpWithGoogleParams) {
    const result = await api.create(
      '/api/v1/sign-up-with-google',
      convertObjectToFormData({
        ...params,
        referral_code: params.referral_code || '',
      })
    );
    return result.data;
  },
  async contactUs(params: ContactUsParams) {
    const result = await api.create<{ message: string }>(
      '/api/contact-us/',
      convertObjectToFormData(params)
    );
    return result.data;
  },
  async setPrimaryIntrest(primaryProductInterest: 'cex' | 'dex') {
    await api.post<PostPrimaryProductInterestBody>(
      '/api/v1/primary-product-interest',
      {
        primaryProductInterest,
      }
    );
  },
};

export default UserService;
