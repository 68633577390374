import { cloneElement, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import StyledLabel from './inputs/text-input/styled-label';

export function RowLabel(props: React.ComponentProps<typeof StyledLabel>) {
  return (
    <StyledLabel
      className={twMerge(
        'w-full grow xxs:text-sm xxs:text-dex-white-secondary hover:text-dex-white',
        props.className
      )}
      {...props}
    />
  );
}

export function RowLabelledInput({
  label,
  children,
  className,
}: {
  children: JSX.Element;
  label: JSX.Element;
  className?: string;
}) {
  const id = useState(() => Math.random().toString());
  return (
    <div className={twMerge('flex justify-between items-center', className)}>
      {cloneElement(label, {
        name: id,
      })}
      {cloneElement(children, {
        id,
      })}
    </div>
  );
}

export function RowLabelledCheckBox({
  label,
  children,
  className,
  openBox,
  setOpenBox,
}: {
  children: JSX.Element;
  label: JSX.Element;
  className?: string;
  openBox?: boolean;
  setOpenBox?: (() => void) | undefined;
}) {
  const id = useState(() => Math.random().toString());
  const Body = (
    <div>
      {cloneElement(label, {
        name: id,
      })}
    </div>
  );
  return (
    <div
      className={twMerge(
        'flex gap-x-1 items-center  xxs:text-dex-white-secondary hover:text-dex-white',
        className
      )}
    >
      {cloneElement(children, {
        id,
      })}
      {setOpenBox ? (
        <button className="m-0 p-0 w-full" onClick={setOpenBox} type="button">
          <div className="flex justify-between items-center w-full">
            {Body}
            {openBox ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </div>
        </button>
      ) : (
        Body
      )}
    </div>
  );
}
