import {
  ReferralHistoryResponse,
  ReferralLinkResponse,
  ReferralStatsResponse,
} from 'api/referrals';
import { api } from 'helpers/api/apiCore';
import { Chains } from 'api/d-wallets';
import { toQueryString } from 'modules/routing/query-string';

const SERVICE_KEY = 'referral';

export const referralServiceKeys = {
  getReferralLink: () => [SERVICE_KEY, 'link'],
  getReferralHistory: () => [SERVICE_KEY, 'history'],
  getDexReferralHistory: (params: { chain: Chains; referralType: string }) => [
    SERVICE_KEY,
    'dex-history',
    params.referralType,
    params.chain,
  ],
  getReferralStats: () => [SERVICE_KEY, 'stats'],
  getDexReferralStats: (params: { chain: Chains; referralType: string }) => [
    SERVICE_KEY,
    'dex-stats',
    params.referralType,
    params.chain,
  ],
};

const ReferralService = {
  getReferralLink: async () => {
    const response = await api.get<ReferralLinkResponse>(
      '/dashboard/api/referral-link'
    );
    return response.data;
  },
  getReferralStats: async () => {
    const response = await api.get<ReferralStatsResponse>(
      '/dashboard/api/referral-stats'
    );
    return response.data;
  },
  getDexReferralStats: async (params: {
    chain: Chains;
    referralType: string;
  }) => {
    const response = await api.get<ReferralStatsResponse>(
      `/api/v1/dex/reward/referral/stats?${toQueryString(params)}`
    );
    return response.data;
  },
  getReferralHistory: async () => {
    const response = await api.get<ReferralHistoryResponse>(
      '/dashboard/api/referral-rewards'
    );
    return response.data;
  },
  getDexReferralHistory: async (params: {
    chain: Chains;
    referralType: string;
  }) => {
    const response = await api.get<ReferralHistoryResponse>(
      `/api/v1/dex/reward/referral/history?${toQueryString(params)}`
    );
    return response.data;
  },
};

export default ReferralService;
