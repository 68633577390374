import { DexTransaction, OpenPositionListItem } from 'api/dex-trade';
import { formatMomentDuration } from 'modules/utils/formatDuration';
import { duration } from 'moment';

export function formatHoldingTime(position: OpenPositionListItem) {
  return position.holdingTimeSeconds
    ? formatMomentDuration(
        duration(position.holdingTimeSeconds || 0, 'seconds')
      )
    : '--';
}

export function getSnipeTx(position: DexTransaction[]) {
  return position.find((x) => x.type.includes('SNIPE_SWAP'));
}
export function getSellTx(position: DexTransaction[]) {
  return position.find(
    (x) => x.type.includes('CLOSE_POSITION') && x.side == 'SELL'
  );
}
