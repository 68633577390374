import {
  ChartBarSquareIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { VolatilityBotFormValues, schema, VolatilityPeriod } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Tag from 'modules/shared-components/data-display/tag';
import { useToggle } from 'modules/utils/useToggle';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { VolatilitySettings } from './sections/VolatilitySettings';
import { BOT_DEFAULT_SETTINGS } from '../copy-trading/BOT_DEFAULT_SETTINGS';
import { SelectDWallet } from '../copy-trading/Sections/SelectDWallet';
import { BuyAmount } from './sections/BuyAmount';
import { TakeProfit } from '../copy-trading/Sections/TakeProfit';
import { BotConfirmationModal } from './BotConfirmationModal';
import { BOT_SIDEBAR, Tabs } from '../../components/layout/SIDEBAR_CONST';
import PageTemplate from '../../components/layout';
import TextInput from '../../components/inputs/text-input/text-input';
import {
  chainMaxBuyGasPriority,
  chainMaxSellGasPriority,
  chainName,
  Chains,
} from 'api/d-wallets';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { useEffect } from 'react';
import { useChainPersistedState } from 'modules/layouts/TopBar/components/GasInfo';

const defaultValues: any = {
  tradingSettings: BOT_DEFAULT_SETTINGS,
  botSettings: {
    entries: {
      periodInMinutes: VolatilityPeriod.Fifteen,
      autoRetryEnabled: true,
      autoRetry: {
        buy: false,
        takeProfit: true,
        stopLoss: false,
      },
      priceChange: 10,
      isDcaActive: false,
      limitOrders: [
        {
          amount: 0.05,
          priceDeviationPercentage: 10,
          slippage: 10,
        },
      ],
      addresses: [{ address: '' }],
    },
  },
};

function NewBot() {
  const [
    isConfirmationOpen,
    { open: openConfirmation, close: closeConfirmation },
  ] = useToggle();

  const form = useForm<VolatilityBotFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, getValues, watch, setValue } = form;

  const [selectedChain] = useChainPersistedState(Chains.Base);

  useEffect(() => {
    if (watch('tradingSettings.chain') !== selectedChain) {
      if (!watch('tradingSettings.isMaxBuyGasPriorityEdited')) {
        setValue(
          'tradingSettings.maxBuyGasPriority',
          chainMaxBuyGasPriority(selectedChain as Chains)
        );
      }
      if (
        !watch('tradingSettings.takeProfitConfig.isMaxSellGasPriorityEdited')
      ) {
        setValue(
          'tradingSettings.takeProfitConfig.maxSellGasPriority',
          chainMaxSellGasPriority(selectedChain as Chains)
        );
      }
      setValue('tradingSettings.walletId', undefined as any);
      setValue('tradingSettings.chain', selectedChain as Chains);
    }
  }, [selectedChain]);

  const onSubmit = handleSubmit(() => {
    openConfirmation();
  });

  const isLarge = useMediaQuery(screenGte.large);

  return (
    <PageTemplate
      sidebar={BOT_SIDEBAR}
      classNameSidebarContainer="hidden lg:block"
      pages={
        isLarge
          ? [
              {
                href: `/dex/bots/${Tabs.VolatilityBot}`,
                name: 'Bots',
              },
              {
                href: `/dex/bots/${Tabs.VolatilityBot}`,
                name: 'New Volatility Bot',
              },
            ]
          : [
              {
                href: `/dex/bots/${Tabs.VolatilityBot}`,
                name: 'Bots',
              },
              {
                href: `/dex/bots/${Tabs.VolatilityBot}`,
                name: 'Bots',
              },
            ]
      }
    >
      <div className="space-y-0 pb-16 lg:pb-0">
        <form
          autoComplete="off"
          onSubmit={(e) => {
            void onSubmit(e);
          }}
        >
          <FormProvider {...form}>
            <div className="xxs:space-y-2 xxs:py-2">
              <div className="xxs:px-2 xxs:text-base lg:text-xl xxs:font-semibold xxs:flex xxs:items-center xxs:gap-2 lg:gap-3 overflow-hidden ">
                <ChartBarSquareIcon className="w-6 h-6 xxs:text-dex-white-secondary flex-shrink-0" />
                <div className="truncate xxs:text-dex-white-secondary">
                  New Volatility Bot
                </div>
                <Tag color="dexNeutral" className="h-auto">
                  <div className="flex space-x-1 items-center">
                    <div>
                      <ChainsIcon
                        imgName={watch('tradingSettings.chain') ?? Chains.Base}
                      />
                    </div>
                    <div>
                      {chainName(watch('tradingSettings.chain') ?? Chains.Base)}
                    </div>
                  </div>
                </Tag>
                <Tag color="dexNeutral" className="h-auto">
                  NEW
                </Tag>
                <a
                  className="lg:flex xxs:text-dex-white hover:text-dex-white hidden"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.mizar.com/sniper-bot-manual/bots/volatility-bot"
                >
                  <Tag color="blue" className="h-auto">
                    Help Me
                  </Tag>
                </a>
              </div>

              <div className="lg:space-y-2">
                <BotSettings />

                <div className="space-y-2">
                  <TradingSettingsForm />

                  <div className="xxs:mx-2 lg:mx-0">
                    <PrimaryButton
                      className="mx-0 xxs:text-base lg:text-xs"
                      size="large"
                      type="submit"
                      variant="primary"
                    >
                      save
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
      {isConfirmationOpen && (
        <BotConfirmationModal
          bot={getValues()}
          handleClose={closeConfirmation}
        />
      )}
    </PageTemplate>
  );
}

export const BotSettings = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext<VolatilityBotFormValues>();

  return (
    <div className="xxs:space-y-2">
      <MobileExpandableCard
        mobileLabel="Bot Name"
        renderTrigger={({ open }) => (
          <MobileExpandCardTrigger onClick={open}>
            {errors?.name ? (
              <div className="flex space-x-1 items-center text-sm xxs:text-red-500">
                <ExclamationCircleIcon className=" xxs:w-6 h-6" />
                <div>Missing Information</div>
              </div>
            ) : (
              <div className="flex justify-between items-center text-sm">
                <div className="flex space-x-1 items-center xxs:text-dex-white-secondary">
                  <div>Bot:</div>
                  {watch('name') ? (
                    <div className="xxs:text-dex-white max-w-[70px] truncate">
                      {watch('name')}
                    </div>
                  ) : (
                    <div className="xxs:text-dex-white-secondary">--</div>
                  )}
                </div>
              </div>
            )}
          </MobileExpandCardTrigger>
        )}
      >
        <div className="xxs:space-y-2 xxs:p-2 lg:p-0">
          <BotName />
        </div>
      </MobileExpandableCard>
    </div>
  );
};

export function BotName() {
  const { control } = useFormContext<VolatilityBotFormValues>();

  return (
    <Controller
      control={control}
      name="name"
      render={({ field, fieldState: { error: fieldError } }) => (
        <TextInput
          {...field}
          type="text"
          label="Bot Name"
          placeholder="This is my volatility bot"
          error={fieldError?.message}
        />
      )}
    />
  );
}

export function TradingSettingsForm({
  isEdit,
  isDuplicate,
}: {
  isEdit?: boolean;
  isDuplicate?: boolean;
}) {
  return (
    <>
      <div className="flex flex-col lg:flex-row lg:gap-2">
        <div className="w-full flex flex-col lg:gap-2 ">
          <SelectDWallet />
          <BuyAmount />
          <TakeProfit />
        </div>
        <div className="w-full flex flex-col xxs:gap-1">
          <VolatilitySettings isEdit={isEdit} isDuplicate={isDuplicate} />
        </div>
      </div>
    </>
  );
}

export default NewBot;
