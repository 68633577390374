import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../schema';
import {
  RowLabelledInput,
  RowLabel,
} from '../../../components/RowLabelledInput';
import NumberInput from '../../../components/inputs/number-input';
import Tooltip from 'modules/shared-components/tooltip';
import Checkbox from '../../../components/inputs/Checkbox';

export function CooldownPeriod() {
  const { control, watch, setValue } = useFormContext<FormValues>();

  const coolDownFilter = watch('botSettings.entries.cooldownPeriod');

  return (
    <div>
      <div className="flex">
        <Tooltip text="Add a cool-down period to prevent the same token to be sniped multiple times after the first trade is executed.">
          <div>
            <div className="flex items-center xxs:gap-1">
              <Checkbox
                className="lg:w-4 lg:h-4 xxs:w-5 xxs:h-5"
                checked={!!coolDownFilter}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setValue('botSettings.entries.cooldownPeriod', {
                      periodInHours: 24,
                    });
                  } else {
                    setValue('botSettings.entries.cooldownPeriod', null);
                  }
                }}
              />
              <div className="xxs:text-sm xxs:text-dex-white-secondary hover:text-dex-white">
                Cool-down Period
              </div>
            </div>
          </div>
        </Tooltip>
      </div>

      {coolDownFilter ? (
        <>
          <RowLabelledInput
            label={
              <RowLabel
                label="Cool-down Period"
                tooltip="Number of hours to wait after a position has been closed before opening a new one."
              />
            }
            className="xxs:py-1"
          >
            <Controller
              name="botSettings.entries.cooldownPeriod.periodInHours"
              control={control}
              defaultValue={'' as any}
              render={({ field, fieldState: { error } }) => (
                <NumberInput
                  {...field}
                  containerClassName="max-w-[108px]"
                  inputSize="small"
                  suffix={<>hours</>}
                  error={error?.message}
                />
              )}
            />
          </RowLabelledInput>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
