import { useQuery } from '@tanstack/react-query';
import { LoadingComponent } from './LoadingComponent';
import {
  formatTokenAmount,
  formatTokenAmountCompact,
} from 'utils/FormatNumber';
import {
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/DWalletService';
import Button from 'modules/shared-components/button/button';
import { DWalletActions, WalletDetails } from '../wallets/DWalletDetails';
import {
  chainAsset,
  chainAssetImg,
  ChainAssets,
  chainName,
  Chains,
  DWalletDetails,
  minimumSafeAmount,
} from 'api/d-wallets';
import { CreateDWalletModal } from '../wallets/CreateDWalletModal';
import { useToggle } from 'modules/utils/useToggle';
import {
  CreditCardIcon,
  ExclamationTriangleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { usePageParams } from '../wallets/usePageParams';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { useUserState } from 'modules/user/UserContext';
import { Link } from 'react-router-dom';
import { DepositModal } from '../wallets/DepositModal';
import Big from 'big.js';
import { DepositEthereumProvider } from '../wallets/DepositModal/DepositWagmiProvider';
import Tooltip from 'modules/shared-components/tooltip';

import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { useChainPersistedState } from 'modules/layouts/TopBar/components/GasInfo';
import { FullScreenModal } from '../components/modal/FullScreenModal';

export function WalletsPanel() {
  const [selectedChain] = useChainPersistedState(Chains.Base);

  const { user } = useUserState();
  const { isInitialLoading, data, error } = useQuery({
    queryFn: () => MultiChainDWalletService.getWallets(selectedChain as Chains),
    queryKey: multiChainDWalletServiceKeys.getWallets(selectedChain),
    keepPreviousData: true,
    enabled: !!user,
  });

  const [isCreateOpen, { open, close }] = useToggle();

  const [{ walletAddress }, setAddress] = usePageParams();
  const isLargeScreen = useMediaQuery(screenGte.large);

  if (!user) {
    return (
      <div className="">
        <div className="flex justify-between items-center pb-1">
          <div className="flex space-x-2 items-center px-1 xxs:text-dex-white-secondary">
            <div>Wallets</div>
          </div>
        </div>
        <div className="flex overflow-y-auto  no-scrollbar max-h-[155px] overflow-y-scroll ">
          <Link
            className="text-blue-500 hover:text-blue-600 xxs:px-2 font-bold"
            to="/login"
          >
            Login or sign-up
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex justify-between items-center pb-1">
        <div className="flex space-x-1 items-center px-1">
          {data && data?.length > 0 && (
            <div className="flex items-center space-x-1 xxs:text-dex-white-secondary">
              <CreditCardIcon className="h-4 w-4" />
            </div>
          )}
          <div className="text-sm xxs:text-dex-white-secondary">
            Wallets {data?.length ? <span>({data.length})</span> : ''}
          </div>
        </div>

        <div className="flex xxs:space-x-1 items-center">
          <div className="">
            <Button
              variant="dexNeutral"
              className="m-0 xxs:p-1.5 xxs:text-dex-white-secondary hover:text-dex-white"
              onClick={open}
            >
              <PlusIcon className="h-4 w-4 " />
              <span className="sr-only">Create a new wallet</span>
            </Button>
          </div>
        </div>
      </div>
      {error && <div className="text-red-500 pl-1">Could not load...</div>}
      <div className="divide-x-0 divide-solid xxs:divide-dex-black-800 divide-y overflow-y-auto  no-scrollbar max-h-[155px] overflow-y-scroll ">
        {isInitialLoading ? (
          <LoadingComponent />
        ) : data?.length === 0 ? (
          <div className="text-sm flex space-y-6 flex-col p-1">
            <div className="text-xs text-black-100 ">
              Auto-generated wallets for automating your trades on{' '}
              {chainName(selectedChain)} chain.
            </div>
            <Button variant="primary" onClick={open}>
              NEW WALLET
            </Button>
          </div>
        ) : (
          !!data?.length &&
          data.map((wallet: DWalletDetails) => {
            return (
              <button
                type="button"
                onClick={() =>
                  setAddress({
                    walletAddress: wallet.address,
                  })
                }
                key={wallet.id + '_' + wallet.chain}
                className="m-0 xxs:bg-dex-black-900 hover:bg-dex-black-800 xxs:p-2 w-full xxs:text-white-50 xxs:text-xs flex xxs:gap-2 items-center"
              >
                <WalletComponent
                  selectedChain={selectedChain}
                  wallet={wallet}
                />
                <div className="w-1/3 flex justify-end">
                  <DWalletActions chain={wallet.chain} wallet={wallet} />
                </div>
              </button>
            );
          })
        )}
      </div>
      {isCreateOpen && (
        <CreateDWalletModal selectedChain={selectedChain} handleClose={close} />
      )}
      {walletAddress && isLargeScreen && (
        <FullScreenModal close={() => setAddress({})}>
          <WalletDetails />
        </FullScreenModal>
      )}
    </div>
  );
}

export const WalletComponent = ({
  wallet,
  hideDeposit,
  selectedChain,
}: {
  wallet: DWalletDetails;
  hideDeposit?: boolean;
  selectedChain: Chains;
}) => {
  return (
    <div className="space-y-0 w-2/3 text-left xxs:text-dex-white-secondary  hover:text-dex-white">
      <div className="truncate ">{wallet.label}</div>

      <div className="flex space-x-1 items-center">
        <img
          className="h-4 w-4"
          alt="token icon"
          src={chainAssetImg(wallet.chain)}
        />

        <div className="xxs:text-dex-white">
          {formatTokenAmount(Big(wallet.totalEthBalance ?? 0).toNumber())}
        </div>
        <div className="text-[10px] xxs:text-dex-white">
          $
          {formatTokenAmountCompact(
            Big(wallet.totalUsdBalance ?? 0).toNumber()
          )}
        </div>
        {Big(wallet.chainBalance).eq(0) && !hideDeposit && (
          <OpenDepositButton selectedChain={selectedChain} wallet={wallet} />
        )}
        {Big(wallet.chainBalance).gt(0) &&
          Big(wallet.chainBalance).lt(minimumSafeAmount(wallet.chain)) && (
            <Tooltip
              text={`Warning! Balance below ${minimumSafeAmount(
                wallet.chain
              )} ${chainAsset(
                wallet.chain
              )}. This could cause unexpected errors while managing your positions. Deposit ${chainAsset(
                wallet.chain
              )} to prevent future issues.`}
            >
              <ExclamationTriangleIcon className="text-red-500 w-4 h-4" />
            </Tooltip>
          )}
      </div>
    </div>
  );
};

function OpenDepositButton({
  wallet,
  selectedChain,
}: {
  wallet: DWalletDetails;
  selectedChain: Chains;
}) {
  const [isDepositOpen, { open, close }] = useToggle();
  return (
    <div
      className="xxs:flex xxs:gap-2 justify-end z-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        type="button"
        className="xxs:px-1 xxs:py-0.5 "
        variant="neutral"
        onClick={open}
      >
        <span className="xxs:text-[10px]">Deposit</span>
      </Button>
      {isDepositOpen && (
        <DepositEthereumProvider>
          {' '}
          <DepositModal
            selectedChain={selectedChain}
            handleClose={close}
            wallet={wallet}
          />
        </DepositEthereumProvider>
      )}
    </div>
  );
}

export const CHAINS_OPTIONS = [
  {
    value: Chains.Ethereum,
    label: Chains.Ethereum.toUpperCase(),
    icon: <ChainsIcon imgName={Chains.Ethereum} />,
  },
  {
    value: Chains.Base,
    label: Chains.Base.toUpperCase(),
    icon: <ChainsIcon imgName={Chains.Base} />,
  },
  {
    value: Chains.BinanceChain,
    label: ChainAssets.BinanceChain,
    icon: <ChainsIcon imgName={Chains.BinanceChain} />,
  },
];
