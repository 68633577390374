import { FC, Fragment, useRef, ComponentProps } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { ModalContextProvider, useModal } from '../CustomModal/ModalContext';
import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from 'modules/shared-components/button/IconButton';

interface CustomModalProps {
  showModal: boolean;
  handleClose: () => void;
  className?: string;
  children: React.ReactNode;
  backdropClassName?: string;
  initialFocus?: React.MutableRefObject<any>;
}

type IFullPageModal = FC<CustomModalProps> & {
  Title: FC<ComponentProps<typeof Title>>;
  Body: FC<ComponentProps<'div'>>;
  Content: FC<ComponentProps<'div'>>;
  Footer: FC;
};

export const FullPageModal: IFullPageModal = ({
  showModal,
  handleClose,
  children,
  className,
  backdropClassName,
  initialFocus,
}) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root appear show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[50]"
        initialFocus={initialFocus || cancelButtonRef}
        onClose={handleClose}
      >
        <div className="fixed inset-0 z-[50] overflow-y-auto">
          <div
            className={twMerge(
              'flex min-h-full justify-center xxs:p-0 text-center xxs:bg-white dark:bg-black-700',
              backdropClassName
            )}
          >
            <Dialog.Panel
              className={twMerge(
                'flex flex-col relative transform overflow-auto xxs:bg-white dark:bg-black-700 text-left transition-all xxs:w-full',
                className
              )}
            >
              <ModalContextProvider close={handleClose}>
                {children}
              </ModalContextProvider>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface TitleProps {
  textColor?: string;
  bgColor?: string;
  className?: string;
  Icon?: typeof ExclamationCircleIcon | null;
}

const Title: FC<TitleProps> = ({
  textColor,
  bgColor,
  children,
  className,
  Icon,
}) => (
  <Dialog.Title
    as="h3"
    className={twMerge(
      'text-lg font-medium leading-6 text-black-900 dark:text-white text-left flex justify-start items-center xxs:gap-2',
      className
    )}
  >
    {Icon && (
      <div
        className={classNames(
          'flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0',
          bgColor
        )}
      >
        <Icon className={classNames('h-8 w-8', textColor)} aria-hidden="true" />
      </div>
    )}

    {children}
  </Dialog.Title>
);

const Body: FC<ComponentProps<'div'>> = ({ children, ...props }) => (
  <div
    {...props}
    className={twMerge('flex-1 xxs:p-4 xxs:pb-4', props.className)}
  >
    <div className="sm:flex sm:items-start">
      <div className="mt-1 sm:mt-0 sm:text-left xxs:flex-1 w-full">
        {children}
      </div>
    </div>
  </div>
);

const Content: FC<ComponentProps<'div'>> = (props) => (
  <div
    {...props}
    className={twMerge(
      'xxs:mt-2 xxs:text-sm xxs:text-black-500 dark:text-white-100 xxs:text-left',
      props.className
    )}
  />
);

const Footer: FC = ({ children }) => (
  <div className="xxs:px-4 xxs:py-3 flex xxs:flex-col gap-2 sm:px-6">
    {children}
  </div>
);

FullPageModal.Title = Title;
FullPageModal.Body = Body;
FullPageModal.Content = Content;
FullPageModal.Footer = Footer;

export function FullPageModalHeader({
  children,
}: {
  children: React.ReactNode;
}) {
  const { close } = useModal();

  return (
    <div className="xxs:p-3 flex xxs:gap-2 xxs:bg-white dark:bg-black-900 sticky top-0 items-center justify-between z-1005">
      <div className="xxs:text-sm">{children}</div>
      <IconButton
        onClick={close}
        className="xxs:rounded dark:bg-black-700 shrink-0 xxs:p-1"
      >
        <XMarkIcon className="w-6" />
      </IconButton>
    </div>
  );
}
