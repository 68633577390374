import { Combobox } from '@headlessui/react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import {
  DropdownOption,
  Options,
  DropdownOptionItem,
  DropdownButton,
  useDropdownPosition,
} from '../dropdown';
import { twMerge } from 'tailwind-merge';

export function CountBadge(props: React.ComponentProps<'span'>) {
  return (
    <span
      {...props}
      className={twMerge(
        'xxs:bg-white-600 dark:bg-black-200 xxs:py-0.5 xxs:px-2.5 xxs:text-xs xxs:leading-4 xxs:font-medium xxs:text-gray-800 rounded-xl text-nowrap',
        props.className
      )}
    />
  );
}

export type FilterDropdownProps<T extends string | number> = {
  label: string;
  options?: DropdownOptionItem<T>[];
  value: T[];
  onChange: (value: T[]) => void;
  onOpen?: () => void;
  icon?: React.ReactNode;
  isError?: boolean;
  search?: React.ReactNode;
  loading?: boolean;
  size?: string;
};

export function FilterDropdown<T extends string | number>({
  label,
  options,
  value = [],
  onChange,
  isError,
  search,
  onOpen,
  loading,
  size,
}: FilterDropdownProps<T>) {
  const { setReferenceElement, setPopperElement, attributes, styles } =
    useDropdownPosition();

  return (
    <Combobox
      as="div"
      value={value}
      onChange={(newValue: T[]) => {
        onChange(newValue);
      }}
      ref={setReferenceElement}
      className={twMerge(
        'relative xxs:flex-shrink-0',
        size === 'xs' ? 'xxs:flex' : ''
      )}
      multiple
    >
      <DropdownButton
        className={size === 'xs' ? 'xxs:h-8' : ''}
        as={Combobox.Button}
        onClick={onOpen}
      >
        <span
          className={twMerge(
            'xxs:flex-grow xxs:text-white',
            size === 'xs' ? 'text-xs' : ' xxs:text-sm'
          )}
        >
          {label}
        </span>

        {!!value.length && (
          <CountBadge
            className={size === 'xs' ? 'xxs:px-1.5 xxs:py-0 text-xs ' : ''}
          >
            <div className={size === 'xs' ? 'text-xs' : ''}>{value.length}</div>
          </CountBadge>
        )}
      </DropdownButton>

      <Options
        className={size === 'xs' ? 'text-xs' : ''}
        as={Combobox.Options}
        {...attributes.popper}
        ref={setPopperElement}
        style={styles.popper}
      >
        {search}
        {loading && (
          <Combobox.Option
            value={null}
            disabled
            className="xxs:py-2 xxs:px-2 flex justify-center"
          >
            <ArrowPathIcon
              className={classNames('h-5 w-5 text-gray-400 animate-spin')}
              aria-hidden="true"
            />
          </Combobox.Option>
        )}
        {isError && (
          <Combobox.Option
            value={null}
            disabled
            className="xxs:py-2 xxs:pl-3 xxs:pr-10 text-red-warning"
          >
            Could not load options
          </Combobox.Option>
        )}
        {options?.length === 0 && (
          <Combobox.Option
            value={null}
            disabled
            className="xxs:py-2 xxs:pl-3 xxs:pr-10 text-white-800"
          >
            No results found
          </Combobox.Option>
        )}
        {options?.map((option, index) => (
          <DropdownOption
            size={size}
            as={Combobox.Option}
            key={option.value}
            option={option}
            index={index}
          />
        ))}
      </Options>
    </Combobox>
  );
}
