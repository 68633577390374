import etherScanImg from 'assets/img/chains/etherscan.png';
import baseScanImg from 'assets/img/chains/basescan.png';
import bscScanImg from 'assets/img/chains/bscscan.png';
import EthIcon from 'assets/img/icons/eth-white.svg';
import BnbIcon from 'assets/img/chains/bnb-grey.svg';

export function chainIsOnWebacy(chain: Chains | undefined) {
  if (!chain) {
    return 0;
  }
  if (chain === Chains.Ethereum || chain === Chains.Base) {
    return true;
  } else {
    return false;
  }
}

export function minimumSafeAmount(chain: Chains | undefined) {
  if (!chain) {
    return 0;
  }
  if (chain === Chains.Ethereum) {
    return ChainsSafeValues.Ethereum;
  } else if (chain === Chains.BinanceChain) {
    return ChainsSafeValues.BinanceChain;
  } else {
    return ChainsSafeValues.Base;
  }
}

export function chainHasMemPool(chain: Chains | undefined) {
  if (chain === Chains.Ethereum) {
    return true;
  } else {
    return false;
  }
}

export function chainMinimumPayout(chain: Chains | undefined) {
  if (!chain) {
    return 0;
  } else {
    return ChainsPayouts.EthereumBase;
  }
}

export function chainMaxWithdraw(chain: Chains | undefined) {
  if (!chain) {
    return 0;
  } else {
    return ChainsMaxWithdraw.EthereumBase;
  }
}

export function chainProcessingFee(chain: Chains | undefined) {
  if (!chain) {
    return 0;
  } else if (chain === Chains.Ethereum) {
    return ChainsProcessingFee.Ethereum;
  } else {
    return ChainsProcessingFee.Base;
  }
}

export function chainAsset(chain: Chains | undefined) {
  if (chain === Chains.BinanceChain) {
    return ChainAssets.BinanceChain;
  } else {
    return chain ? ChainAssets.EthereumBase : '';
  }
}

export function dexToolLink(chain: Chains | undefined) {
  if (chain === Chains.Ethereum || !chain) {
    return DexToolBase.Ethereum;
  } else if (chain === Chains.BinanceChain) {
    return DexToolBase.BinanceChain;
  } else {
    return DexToolBase.Base;
  }
}

export function dexName(protocol: Protocols) {
  if (protocol === Protocols.UniswapV3) {
    return ProtocolsNames.UniswapV3;
  } else if (protocol === Protocols.UniswapV2) {
    return ProtocolsNames.UniswapV2;
  } else if (protocol === Protocols.PancakeSwapV2) {
    return ProtocolsNames.PancakeSwapV2;
  } else if (protocol === Protocols.PancakeSwapV3) {
    return ProtocolsNames.PancakeSwapV3;
  } else if (protocol === Protocols.SushiSwapV2) {
    return ProtocolsNames.SushiSwapV2;
  } else {
    return protocol ? ProtocolsNames.SushiSwapV3 : '';
  }
}

export function chainName(chain: Chains) {
  if (chain === Chains.Ethereum) {
    return ChainNames.Ethereum;
  } else if (chain === Chains.BinanceChain) {
    return ChainNames.BinanceChain;
  } else {
    return ChainNames.Base;
  }
}

export function chainScan(chain: Chains) {
  if (chain === Chains.Ethereum) {
    return ChainScans.Ethereum;
  } else if (chain === Chains.BinanceChain) {
    return ChainScans.BinanceChain;
  } else {
    return ChainScans.Base;
  }
}

export function chainAssetImg(chain: Chains | undefined) {
  if (!chain) {
    return EthIcon;
  }
  if (chain === Chains.Ethereum || chain === Chains.Base) {
    return EthIcon;
  } else {
    return BnbIcon;
  }
}

export function chainScanImg(chain: Chains | undefined) {
  if (!chain) {
    return etherScanImg;
  }
  if (chain === Chains.Ethereum) {
    return etherScanImg;
  } else if (chain === Chains.BinanceChain) {
    return bscScanImg;
  } else {
    return baseScanImg;
  }
}

export function chainMaxBuyGasPriority(chain: Chains | undefined) {
  if (!chain) {
    return 5;
  }
  if (chain === Chains.Ethereum) {
    return 5;
  } else if (chain === Chains.BinanceChain) {
    return 0.1;
  } else {
    return 0.05;
  }
}

export function chainMaxSellGasPriority(chain: Chains | undefined) {
  if (!chain) {
    return 2;
  }
  if (chain === Chains.Ethereum) {
    return 2;
  } else if (chain === Chains.BinanceChain) {
    return 0.1;
  } else {
    return 0.05;
  }
}

export const CHAINS = ['eth', 'base', 'bsc'];

export enum ChainsPayouts {
  EthereumBase = 0.015,
  BinanceChain = 0.1,
}

export enum ChainsProcessingFee {
  Ethereum = 0.005,
  Base = 0.0005,
  BinanceChain = 0.01,
}

export enum ChainsMaxWithdraw {
  EthereumBase = 5,
  BinanceChain = 30,
}

export enum Chains {
  Ethereum = 'eth',
  Base = 'base',
  BinanceChain = 'bsc',
}
export enum DexToolBase {
  Ethereum = 'ether',
  Base = 'base',
  BinanceChain = 'bsc',
}

export enum Protocols {
  UniswapV2 = 'UNISWAP_V2',
  UniswapV3 = 'UNISWAP_V3',
  PancakeSwapV2 = 'PANCAKESWAP_V2',
  PancakeSwapV3 = 'PANCAKESWAP_V3',
  SushiSwapV2 = 'SUSHISWAP_V2',
}

export enum ChainScans {
  Ethereum = 'etherscan.io',
  Base = 'basescan.org',
  BinanceChain = 'bscscan.com',
}

export enum ProtocolsNames {
  UniswapV2 = 'Uniswap V2.0',
  UniswapV3 = 'Uniswap V3.0',
  PancakeSwapV2 = 'Pancakeswap V2.0',
  PancakeSwapV3 = 'Pancakeswap V3.0',
  SushiSwapV2 = 'Sushiswap V2.0',
  SushiSwapV3 = 'Sushiswap V3.0',
}

export enum ChainNames {
  Ethereum = 'Ethereum',
  Base = 'Base',
  BinanceChain = 'BNB',
}

export enum ChainAssets {
  EthereumBase = 'ETH',
  BinanceChain = 'BNB',
}

export enum ChainsSafeValues {
  Ethereum = 0.025,
  Base = 0.01,
  BinanceChain = 0.1,
}

export type GetDWalletsResponse = {
  data: DWalletDetails[];
};

export type GetDWalletDetailsResponse = {
  data: DWalletDetails;
};

export type DWalletDetails = {
  id: string;
  address: string;
  label: string;
  createdDatetime?: string;
  chainBalance: number;
  totalEthBalance: string;
  totalUsdBalance: string;
  chain: Chains;
  tokenBalances?: TokenBalance[];
};

export type TokenBalance = {
  name: string;
  chain: Chains;
  symbol: string;
  price: string;
  address: string;
  amount: number;
  decimals: number;
  hasIcon: boolean;
};

export type CreateDWalletBody = {
  label: string;
  chain_type: string;
};
export type EditDWalletBody = {
  label: string;
};

export type CreatedWalletResponseDetail = {
  id: string;
  label: string;
  address: string;
  chain: Chains;
  chainBalance: string;
  createdDatetime: string;
  decryptedMnemonic: string;
  encryptedMnemonic: ArrayBuffer;
  decryptedPrivateKey: string;
  encryptedPrivateKey: ArrayBuffer;
  mnemonicIV: Uint8Array;
  privateKeyIV: Uint8Array;
  deleted: boolean;
  tokenBalances: any[];
};
export type CreateDWalletResponse = {
  data: CreatedWalletResponseDetail[];
};

export type WithdrawBody = {
  tokenAddress: string;
  amount: number;
  chain: string;
  vaultAddress: string;
  destinationAddress: string;
};

export type InitiateWithdrawParams = {
  chain: string;
  from_address: string;
  to_address: string;
  token_address?: string;
  amount: string;
};

export type BridgeParams = {
  address: string;
  srcChain: string;
  dstChain: string;
  srcChainTokenAmount: number;
};

export type CompleteWithdrawParams = {
  transfer_id: string;
  chain: string;
  from_address: string;
  to_address: string;
  token_address?: string;
  amount: string;
  otp: string;
};

export type InitiateBridge = {
  userId: number;
  srcVaultId: string;
  dstVaultId: string;
  srcChain: Chains;
  dstChain: Chains;
  srcVaultAddress: string;
  dstVaultAddress: string;
  srcTransactionHash?: string | null;
  dstTransactionHash?: string | null;
  srcAmount: number;
  dstAmount: number;
  gasAmount: number;
  feeAmount: number;
  state: string;
};
export type GetInitiateWithdraw = {
  id: string;
  createdDatetime: string;
  updatedDatetime: string;
  userId: number;
  vaultId: string;
  chain: string;
  fromAddress: string;
  toAddress: string;
  tokenAddress: string | null;
  assetName: string;
  assetSymbol: string;
  assetDecimals: number;
  transactionHash: string | null;
  amount: string;
  isChainAsset: boolean;
  isComplete: boolean;
};

export type EstimateWithdraw = {
  data: string;
};
