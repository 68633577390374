import { CSSProperties, forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

export const INPUT_CLASS_NAMES = {
  regular:
    'block w-full rounded-md xxs:bg-inherit xxs:text-dex-white border-solid xxs:border-dex-black-700 focus:border-blue-500 focus:ring-blue-500 disabled:cursor-not-allowed disabled:text-dex-white-secondary focus:text-dex-white',
  error:
    'block w-full rounded-md border-solid border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-white-300 dark:disabled:bg-black-600 disabled:text-gray-500 dark:bg-black-800 dark:border-red-500 dark:focus:border-red-700 dark:text-red-600 dark:placeholder-red-900',
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  className?: string;
  hasError?: boolean;
  hasSuffix?: boolean;
  hasPrefix?: boolean;
  disabled?: boolean;
  extraStyle?: CSSProperties;
  inputSize?: 'xs' | 'small' | 'large';
}

const StyledInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      className,
      hasError,
      extraStyle,
      hasSuffix,
      hasPrefix,
      disabled,
      children,
      inputSize = 'large',
      ...props
    },
    ref
  ) => (
    <div className="relative rounded-md shadow-sm">
      <input
        key={name}
        ref={ref}
        type="text"
        id={name}
        style={extraStyle ? extraStyle : {}}
        name={name}
        data-testid={name}
        aria-describedby={hasSuffix ? `${name}-suffix` : undefined}
        aria-invalid={hasError ? 'true' : 'false'}
        disabled={disabled}
        {...props}
        className={classNames(
          hasError ? INPUT_CLASS_NAMES.error : INPUT_CLASS_NAMES.regular,
          inputSize === 'small' || inputSize === 'xs' ? 'xxs:py-1' : '',
          hasPrefix
            ? 'xxs:pl-8'
            : inputSize === 'small' || inputSize === 'xs'
            ? 'xxs:pl-2'
            : '',
          hasSuffix
            ? 'xxs:pr-8'
            : inputSize === 'small' || inputSize === 'xs'
            ? 'xxs:pr-2'
            : '',
          inputSize === 'xs' ? 'xs:text-sm lg:text-xs' : 'xxs:text-sm',
          className
        )}
      />
      {children}
    </div>
  )
);

export default StyledInput;
