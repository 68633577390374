import {
  chainMaxBuyGasPriority,
  chainMaxSellGasPriority,
  Chains,
} from 'api/d-wallets';

export const TG_BOT_DEFAULT_SETTINGS = (() => {
  const savedChain = localStorage.getItem('d-mizar-chain');
  const chain = savedChain ? (JSON.parse(savedChain) as Chains) : Chains.Base;
  return {
    launchedFilter: true,
    chain,
    notLaunchedFilter: false,
    antiMev: true,
    bribe: 0,
    backupBribe: 0,
    blockOneRetry: false,
    maxBuyGasPriority: chainMaxBuyGasPriority(chain),
    slippage: 15,
    safetyMeasures: {
      autoRetryEnabled: false,
      autoRetry: {
        buy: false,
        takeProfit: false,
        stopLoss: false,
      },
      liquidityRange: false,
      marketCapRange: false,
      taxProtectionEnabled: false,
      gasLimit: 400000,
      gasLimitEnabled: true,

      liquidity: {
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
      },
      marketCap: {
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
      },
      taxProtection: {
        buy: 0,
        sell: 0,
      },
      honeypotProtection: true,
    },

    takeProfit: [
      {
        threshold: 50,
        weight: 50,
      },
      {
        threshold: 150,
        weight: 50,
      },
    ],
    takeProfitConfig: {
      slippage: 15,
      maxSellGasPriority: chainMaxSellGasPriority(chain),
      antiMev: true,
    },
    stopLoss: null,
  };
})();

export const BOT_DEFAULT_SETTINGS = (() => {
  const savedChain = localStorage.getItem('d-mizar-chain');
  const chain = savedChain ? (JSON.parse(savedChain) as Chains) : Chains.Base;

  return {
    maxBuyGasPriority: chainMaxBuyGasPriority(chain),
    antiMev: true,
    chain,
    gasLimit: 400000,
    safetyMeasures: {
      autoRetryEnabled: false,
      autoRetry: {
        buy: false,
        takeProfit: false,
        stopLoss: false,
      },
      liquidityRange: false,
      marketCapRange: false,
      taxProtectionEnabled: false,
      gasLimit: 400000,
      gasLimitEnabled: true,
      liquidity: {
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
      },
      marketCap: {
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
      },
      taxProtection: {
        buy: 0,
        sell: 0,
      },
      honeypotProtection: true,
    },
    slippage: 10,
    takeProfit: [
      {
        threshold: 15,
        weight: 100,
      },
    ],
    takeProfitConfig: {
      slippage: 10,
      maxSellGasPriority: chainMaxSellGasPriority(chain),
      antiMev: true,
    },
    stopLoss: null,
  };
})();
