import { HotTokensRowInfo } from 'api/contracts';
import Tooltip from 'modules/shared-components/tooltip';
import Big from 'big.js';
import {
  formatNumberWithSuffix,
  formatWithoutDecimals,
} from 'utils/FormatNumber';
import { twMerge } from 'tailwind-merge';
import { performanceStyle } from '../../research/components/Wallets/columns';
import {
  StatsCard,
  StatsCardTitle,
  StatsCardValue,
} from '../../research/components/StatsCard';
import {
  isTokenSuspicious,
  SuspiciousLabelAlert,
} from '../../research/components/Wallets/labels';

interface Props {
  stats: HotTokensRowInfo | undefined;
}

export const SmartStatsComponent = ({ stats }: Props) => {
  const statsData = [
    {
      key: 'suspiciousBuy',
      title: 'Suspicious Buys',
      tooltip:
        'The percentage of wallets that bought in the last 24 hours, compared to all buying wallets, flagged as suspicious or dangerous.',
      value:
        stats &&
        stats?.totalBuy &&
        +stats?.suspiciousBuy + stats?.rugAddictedBuy + stats?.scammerBuy >
          0 ? (
          <div className="flex">
            <SuspiciousLabelAlert
              alertItems={isTokenSuspicious({
                suspiciousBuy: stats.suspiciousBuy,
                rugAddictedBuy: stats.rugAddictedBuy,
                scammerBuy: stats.scammerBuy,
                freshBuy: stats.freshBuy,
                totalBuy: stats.totalBuy,
                skip: true,
              })}
            >
              <div
                className={twMerge(
                  'flex items-center space-x-1 ',
                  Big(
                    +stats.suspiciousBuy +
                      stats.rugAddictedBuy +
                      stats.scammerBuy
                  )
                    .div(stats.totalBuy ?? 1)
                    .gt(0.2) && 'text-red-500'
                )}
              >
                <div>
                  {' '}
                  {Big(
                    +stats.suspiciousBuy +
                      stats.rugAddictedBuy +
                      stats.scammerBuy
                  )
                    .div(stats.totalBuy ?? 1)
                    .mul(100)
                    .toFixed(2)}
                  %
                </div>
                <div className="xxs:text-xs">
                  {formatWithoutDecimals(
                    +stats.suspiciousBuy +
                      stats.rugAddictedBuy +
                      stats.scammerBuy ?? 0
                  )}{' '}
                  wallet(s)
                </div>
              </div>
            </SuspiciousLabelAlert>
          </div>
        ) : (
          <>--</>
        ),
    },
    {
      key: 'newBuys',
      title: 'New Buys',
      tooltip:
        'The percentage of fresh new wallets that bought in the last 24 hours, compared to all buying wallets.',
      value: stats?.totalBuy ? (
        <div
          className={twMerge(
            'flex items-center space-x-1',
            Big(stats.freshBuy ?? 0)
              .div(stats.totalBuy ?? 1)
              .gt(0.2) && 'text-red-500'
          )}
        >
          <div>
            {' '}
            {Big(stats.freshBuy ?? 0)
              .div(stats.totalBuy ?? 1)
              .mul(100)
              .toFixed(2)}
            %
          </div>
          <div className="xxs:text-xs">
            {formatWithoutDecimals(stats.freshBuy)} new
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'BuySellRatio',
      title: 'Buy/Sell Ratio',
      tooltip:
        'The percentage of number of all wallets that bought compared to all wallets that sold, in the last 24 hours.',
      value:
        stats?.totalBuy && stats?.totalSells ? (
          <div className="flex items-center space-x-1  ">
            <div>
              {' '}
              {Big(stats.totalBuy ?? 0)
                .div(stats.totalSells ?? 1)
                .mul(100)
                .toFixed(2)}
              %
            </div>
          </div>
        ) : (
          <>--</>
        ),
    },
    {
      key: 'netFlow',
      title: 'Net Flow',
      tooltip:
        'The net amount of money in and out from smart wallets and all wallets, in the last 24h.',
      value: stats?.netInflow ? (
        <div className="flex items-center space-x-1 text-sm">
          <div className=" font-bold">
            {performanceStyle(stats.netInflowTop, 'xxs:text-lg')}
          </div>
          <div>/</div>
          <div>{performanceStyle(stats.netInflow, 'xxs:text-xs')}</div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'buyVolume',
      title: 'Buy Volume',
      tooltip:
        'The amount of buy volume from smart wallets and all wallets, in the last 24h.',
      value: stats?.buyVolumeTop ? (
        <div className="flex items-center space-x-1">
          <div
            className={twMerge(
              'w-2 h-2 rounded-full xxs:p-1',
              `xxs:bg-green-500`
            )}
          />
          <div className={twMerge(' font-bold')}>
            $
            {formatNumberWithSuffix(stats.buyVolumeTop, {
              precision: 2,
            })}
          </div>
          <div className=" xxs:text-xs">
            /$
            {formatNumberWithSuffix(stats.buyVolume, {
              precision: 2,
            })}
          </div>
        </div>
      ) : (
        <div className="font-bold">--</div>
      ),
    },
    {
      key: 'sellVolume',
      title: 'Sell Volume',
      tooltip:
        'The amount of sell volume from smart wallets and all wallets, in the last 24h.',
      value: stats?.sellVolumeTop ? (
        <div className="flex items-center space-x-1">
          <div
            className={twMerge(
              'w-2 h-2 rounded-full xxs:p-1',
              `xxs:bg-red-500`
            )}
          />
          <div className={twMerge(' font-bold')}>
            $
            {formatNumberWithSuffix(stats.sellVolumeTop, {
              precision: 2,
            })}
          </div>
          <div className=" xxs:text-xs">
            /$
            {formatNumberWithSuffix(stats.sellVolume, {
              precision: 2,
            })}
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'buyWallets',
      title: 'Buy Wallets',
      tooltip:
        'The amount of smart wallets and all wallets that bought in the last 24h.',
      value: stats?.totalBuy ? (
        <div className="flex items-center space-x-1">
          <div
            className={twMerge(
              'w-2 h-2 rounded-full xxs:p-1',
              `xxs:bg-green-500`
            )}
          />
          <div className={twMerge(' font-bold')}>
            {formatWithoutDecimals(stats.totalBuyTop)}
          </div>
          <div className=" xxs:text-xs">
            /{formatWithoutDecimals(stats.totalBuy)}
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'sellWallets',
      title: 'Sell Wallets',
      tooltip:
        'The amount of smart wallets and all wallets that sold in the last 24h.',
      value: stats?.totalSells ? (
        <div className="flex items-center space-x-1">
          <div
            className={twMerge(
              'w-2 h-2 rounded-full xxs:p-1',
              `xxs:bg-red-500`
            )}
          />
          <div className={twMerge('  font-bold')}>
            {formatWithoutDecimals(stats.totalSellsTop)}
          </div>
          <div className=" xxs:text-xs">
            /{formatWithoutDecimals(stats.totalSells)}
          </div>
        </div>
      ) : (
        <>--</>
      ),
    },
    {
      key: 'volatilityIndex',
      title: 'Volatility Index',
      tooltip:
        'The volatility index is calculated using the coefficient of variation.',
      value: stats?.volatilityIndex ? (
        <div className="flex">
          {formatNumberWithSuffix(Big(stats.volatilityIndex ?? 0).mul(100), {
            precision: 2,
          })}
        </div>
      ) : (
        <>--</>
      ),
    },
  ];

  if (!statsData) {
    return (
      <div className="xxs:p-2 overflow-y-scroll mt-1 mb-0 ">
        <div className="flex flex-col w-full lg:w-full h-full xxs:gap-0 lg:gap-1 ">
          <div className="xxs:bg-dex-black-700 rounded-md xxs:p-2 w-full grid grid-cols-3 xxs:gap-1">
            <></>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="xxs:p-2 overflow-y-scroll mt-1 mb-0 ">
      <div className="flex flex-col w-full lg:w-full h-full xxs:gap-0 lg:gap-1 ">
        <div className="w-full lg:grid lg:grid-cols-3 xxs:space-y-2 lg:space-y-0 lg:gap-1">
          <>
            {statsData.map((stat) => (
              <StatsCard key={stat.key} className="xxs:rounded-md ">
                <StatsCardValue className="xxs:text-lg xxs:font-semibold xxs:bg-transparent">
                  <div className="">{stat.value}</div>
                </StatsCardValue>
                <StatsCardTitle>
                  <div className="flex space-x-1 items-center xxs:text-dex-white-secondary hover:text-dex-white">
                    <Tooltip text={stat.tooltip}>
                      <div>{stat.title}</div>
                    </Tooltip>
                  </div>
                </StatsCardTitle>
              </StatsCard>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};
