import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatWagmiError, toOnchainTokenAmount } from 'modules/ethereum/utils';
import {
  Address,
  useAccount,
  usePrepareSendTransaction,
  useSendTransaction,
} from 'wagmi';
import { useDepositState } from './Context';
import { DepositSteps, useDepositStepper } from './DepositStepper';
import { valueAsSafeNumber } from 'utils/FormatNumber';
import { chainAsset, Chains, DWalletDetails } from 'api/d-wallets';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { bsc } from 'wagmi/chains';
import { NotificationDex } from '../../components/alerts/notification';
import CustomModal from '../../components/modal/CustomModal';
import NumberInput from '../../components/inputs/number-input';
import TextInput from '../../components/inputs/text-input/text-input';
const yupPositiveNumber = yup
  .number()
  .positive('Should be more then 0')
  .typeError('Number type accepted only')
  .required('Required');

function getSchema() {
  return yup.object({
    amount: yupPositiveNumber,
  });
}

type FormValues = yup.InferType<ReturnType<typeof getSchema>>;

export function SelectAmount() {
  const [{ wallet, chainId }, setState] = useDepositState();
  const schema = getSchema();
  const { handleSubmit, register, formState, watch } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const { address } = useAccount();

  const [, setStep] = useDepositStepper();
  const amount = watch('amount');

  const { config, error: prepareError } = useDeposit(
    schema.isValidSync({ amount }) ? amount : 0,
    chainId!,
    wallet!
  );

  const {
    sendTransaction,
    error: writeError,
    isLoading: isWriting,
  } = useSendTransaction({
    ...config,
    onSuccess: ({ hash }) => {
      setState((s) => ({ ...s, depositTxHash: hash }));
      setStep(DepositSteps.DepositTxPending);
    },
  });

  const onSubmit = handleSubmit(() => {
    sendTransaction?.();
  });

  const displayError = writeError || prepareError;

  return (
    <form
      className="contents"
      onSubmit={(e) => {
        void onSubmit(e);
      }}
    >
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Deposit Tokens
        </CustomModal.Title>

        <CustomModal.Content>
          <>
            <p className="xxs:mb-4 xxs:text-dex-white-secondary ">
              Select the amount of{' '}
              {chainAsset(
                chainId === bsc.id ? Chains.BinanceChain : Chains.Ethereum
              )}{' '}
              you want to deposit into your wallet.
            </p>

            <TextInput
              name="From"
              label={'From'}
              disabled
              value={address}
              className="xxs:mb-4"
            />
            <NumberInput
              label="Amount"
              {...register('amount', {
                setValueAs: valueAsSafeNumber,
              })}
              suffix={chainAsset(
                chainId === bsc.id ? Chains.BinanceChain : Chains.Ethereum
              )}
              error={formState.errors?.amount?.message}
            />
          </>

          {displayError && (
            <NotificationDex type="error" className="xxs:my-4">
              {formatWagmiError(displayError)}
            </NotificationDex>
          )}
        </CustomModal.Content>
      </CustomModal.Body>
      <CustomModal.Footer>
        <PrimaryButton
          type="submit"
          disabled={schema.isValidSync({ amount }) && !sendTransaction}
          loading={isWriting}
        >
          Deposit
        </PrimaryButton>
      </CustomModal.Footer>
    </form>
  );
}

function useDeposit(amount: number, chainId: number, wallet: DWalletDetails) {
  return usePrepareSendTransaction({
    enabled: !!amount,
    chainId,
    to: wallet.address as Address,
    value: toOnchainTokenAmount(amount || 0, 18),
  });
}
