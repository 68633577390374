import { useToggle } from 'modules/utils/useToggle';
import React, { Children, ReactNode, cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';
import config from 'config';
import { BackButton } from 'modules/telegram/BackButton';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { FullScreenModal } from './FullScreenModal';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/img/icons/arrow-left.svg';
import Button from 'modules/shared-components/button/button';

export type SelectionModalProps = {
  trigger: ({
    close,
    open,
  }: {
    close: () => void;
    open: () => void;
  }) => JSX.Element;
  children: ReactNode;
  defaultOpen?: boolean;
  label: ReactNode;
};

export function SelectionModal({
  trigger,
  label,
  children,
  defaultOpen,
}: SelectionModalProps) {
  const [isOpen, { close, open }] = useToggle(defaultOpen);

  return (
    <>
      {trigger({ close, open })}
      {isOpen && (
        <FullScreenModal
          close={close}
          backdropClassName="xxs:bg-transparent dark:bg-transparent"
        >
          <MobileSelectHeader close={close} label={label} />
          <SelectionModalOptions close={close}>
            {children}
          </SelectionModalOptions>
        </FullScreenModal>
      )}
    </>
  );
}

const optionsVariants = {
  divided: 'divide xxs:divide-dex-black-700 xxs:divide-y xxs:divide-solid',
  default: '',
};

export function SelectionModalOptions({
  kind = 'default',
  close,
  ...props
}: React.ComponentProps<'div'> & {
  kind?: keyof typeof optionsVariants;
  close: () => void;
}) {
  return (
    <div
      {...props}
      className={twMerge('', optionsVariants[kind], props.className)}
    >
      {Children.toArray(props.children).map((c: any) => {
        return cloneElement(c, {
          onClick: (e: any) => {
            close();
            c.props.onClick?.(e);
          },
        });
      })}
    </div>
  );
}

export function MobileSelectHeader({
  label,
  close,
}: {
  label?: ReactNode;
  close: () => void;
}) {
  if (config.isTelegramBrowser) {
    return <BackButton onClick={close} />;
  }

  return (
    <div className="xxs:text-base xxs:py-2 grid grid-cols-3 lg:dark:bg-dex-black-800 sticky top-0 items-center z-1005 xxs:border-0 border-tailwind xxs:border-b xxs:border-black-700">
      <Button
        onClick={close}
        className="xxs:normal-case xxs:text-dex-white-secondary xxs:text-base justify-self-start"
      >
        <ArrowLeftIcon className="w-3 h-3 xxs:mr-1" /> Back
      </Button>
      <div className="text-center">{label ?? ''}</div>
    </div>
  );
}

export function SelectionModalMobileItem(
  props: React.ComponentProps<typeof BaseButton>
) {
  return (
    <BaseButton
      type="button"
      {...props}
      className={twMerge(
        'w-full xxs:p-3 xxs:text-sm xxs:flex xxs:gap-2 xxs:items-center',
        props.className
      )}
    />
  );
}
