import {
  MagnifyingGlassIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

type SearchProps = {
  query: string;
  setQuery: (val: string) => void;
  isLoading?: boolean;
  size?: string;
};

export function ComboboxSearch({
  query,
  setQuery,
  isLoading,
  size,
}: SearchProps) {
  const [id] = useState(() => Math.random().toString());
  return (
    <div className="xxs:flex xxs:p-2 xxs:mb-1 xxs:sticky xxs:top-0 xxs:z-10 xxs:bg-white dark:bg-black-800 xxs:w-full ">
      <label htmlFor={id} className="sr-only">
        Search
      </label>
      <div className="relative xxs:w-full xxs:rounded-lg">
        <div
          className={twMerge(
            'xxs:pointer-events-none absolute inset-y-0 left-0 xxs:flex xxs:items-center',
            size === 'xs' ? ' xxs:pl-2' : ' xxs:pl-3'
          )}
        >
          <MagnifyingGlassIcon
            className={twMerge(
              size === 'xs' ? ' h-4 w-4' : ' h-5 w-5 ',
              'text-gray-400'
            )}
            aria-hidden="true"
          />
        </div>
        <input
          id={id}
          className={twMerge(
            size === 'xs'
              ? 'xxs:text-xs xxs:p-1  xxs:pl-8 '
              : 'xxs:text-sm xxs:p-2  xxs:pl-10 ',
            'xxs:block xxs:w-full border-none xxs:rounded-lg xxs:leading-tight xxs:bg-white-200 dark:bg-black-600 text-black-900 dark:text-white-50 focus:outline-none placeholder-gray-400'
          )}
          placeholder="Search"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          autoComplete="off"
          autoFocus
        />
        <div
          className={twMerge(
            'xxs:pointer-events-none absolute inset-y-0 right-0 xxs:flex xxs:items-center'
          )}
        >
          <ArrowPathIcon
            className={twMerge(
              'text-gray-400 animate-spin',
              !isLoading && 'hidden',
              size === 'xs' ? 'h-4 w-4' : 'h-5 w-5 '
            )}
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
}
