import config from 'config';
import { Control, Controller } from 'react-hook-form';
import Reaptcha from 'reaptcha';
import { twMerge } from 'tailwind-merge';

interface Props {
  control: Control<any, any>;
  className?: string;
}

const RecaptchaField = ({ control, className }: Props) => {
  if (!config.RECAPTCHA_SITE_KEY) {
    return null;
  }

  return (
    <Controller
      name="captcha"
      control={control}
      render={({
        field: { onChange, ref, ...rest },
        fieldState: { error },
      }) => {
        return (
          <div className="xxs:mb-6">
            <Reaptcha
              className={twMerge(
                'flex justify-center xxs:mt-10 h-[78px]',
                className
              )}
              sitekey={config.RECAPTCHA_SITE_KEY}
              {...rest}
              ref={(_ref) => {
                ref({
                  focus: () => {
                    _ref?.container?.focus();
                  },
                });
              }}
              onVerify={onChange}
              onExpire={() => {
                onChange(undefined);
              }}
            />
            {error && (
              <div className="xxs:text-red-500 xxs:text-xs w-[304px] mx-auto">
                {error.message}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default RecaptchaField;
