import { Controller, useFormContext } from 'react-hook-form';
import { useCurrencyConvert } from 'modules/currencies';
import { useQuery } from '@tanstack/react-query';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/DWalletService';
import {
  blockchainInfoKeys,
  BlockchainInfoService,
} from 'api/services/BlockchainInfoService';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import { duration } from 'moment';
import { MapBalances } from '../DWalletDetails';
import { formatTokenAmount } from 'utils/FormatNumber';
import EthIcon from '../../../../assets/img/icons/eth-dark.png';
import BnbIcon from '../../../../assets/img/icons/bnbIcon.png';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Big from 'big.js';
import { gweiToEther, weiToGwei } from 'modules/ethereum/utils';
import { chainAsset, Chains } from 'api/d-wallets';
import { FormValues } from './schema';
import Button from 'modules/shared-components/button/button';
import { EXTENDED_DEPOSIT_CHAINS_CONFIG } from '../DepositModal/DepositWagmiProvider';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import NumberInput from '../../components/inputs/number-input';
import { SortDropdown } from '../../components/dropdown/sort-dropdown';

interface SendFundsContentProps {
  setIsWalletSet: (arg0: boolean) => void;
  walletAddress: string;
}

export const chainsOptions = EXTENDED_DEPOSIT_CHAINS_CONFIG.map((chain) => {
  return {
    value: chain.key,
    label: chain.name,
    icon: <ChainsIcon imgName={chain.key} />,
  };
});

const SendFundsContent = ({
  setIsWalletSet,
  walletAddress,
}: SendFundsContentProps) => {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<FormValues>();
  const convert = useCurrencyConvert();
  const inputValues = watch();
  const toAddress = inputValues.toAddress;
  const selectedTokenAddress = inputValues.tokenAddress;
  const tokenSymbol = inputValues.tokenSymbol;
  const chain = inputValues.chain;

  const {
    isLoading,
    data: wallet,
    error: errorWallet,
  } = useQuery({
    queryFn: () =>
      DWalletService.getWalletWithAddress(chain as Chains, walletAddress),
    queryKey: dWalletServiceKeys.getWalletWithAddress(
      chain as Chains,
      walletAddress
    ),
    enabled: !!(chain && walletAddress),
    keepPreviousData: true,
  });

  const { data: toWalletData } = useQuery({
    queryFn: () =>
      DWalletService.getWalletWithAddress(chain as Chains, toAddress as string),
    queryKey: dWalletServiceKeys.getWalletWithAddress(
      chain as Chains,
      toAddress as string
    ),
    enabled: !!(chain && toAddress),
  });

  const params = {
    chain: inputValues.chain?.toLowerCase(),
    from_address: inputValues.fromAddress,
    to_address: inputValues.toAddress ?? '',
    ...(inputValues.tokenAddress === 'eth' || inputValues.tokenAddress === 'bnb'
      ? {}
      : { token_address: inputValues.tokenAddress }),
    amount: inputValues.amount?.toString(),
  };

  const { data: estimatedGasAmount, error: errorEstimatedGasAmount } = useQuery(
    {
      queryFn: () => DWalletService.estimateWithdraw({ params }),
      queryKey: dWalletServiceKeys.estimateWithdraw({
        params,
      }),
      enabled: !!inputValues.amount,
      keepPreviousData: true,
    }
  );

  const { data: gasInfo } = useQuery({
    queryKey: blockchainInfoKeys.getGasInfoWithChain(
      (inputValues.chain?.toLowerCase() as Chains) ?? Chains.Ethereum
    ),
    queryFn: () =>
      BlockchainInfoService.getGasInfoWithChain(
        (inputValues.chain?.toLowerCase() as Chains) ?? Chains.Ethereum
      ),
    refetchInterval: 1000 * 15,
    staleTime: 1000 * 15,
    cacheTime: 1000 * 15,
    keepPreviousData: true,
  });

  const { data: prices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    refetchInterval: duration('10', 'seconds').asMilliseconds(),
    keepPreviousData: true,
  });

  const balances =
    !isLoading && !errorWallet && wallet
      ? MapBalances(wallet, convert, chain)
      : [];

  const options = balances
    ? balances.map((b) => ({
        value:
          (chain === Chains.Base || chain === Chains.Ethereum) &&
          b.symbol === 'ETH'
            ? 'eth'
            : b.symbol === 'BNB'
            ? 'bnb'
            : b.address,
        label: `${b.name} | ${formatTokenAmount(b.amount ?? 0)} ${b.symbol}`,
        icon:
          b.symbol === 'ETH' ? (
            <div className="xxs:pr-3 xxs:pl-2">
              <img className="h-5 w-auto" alt="eth icon" src={EthIcon} />
            </div>
          ) : b.symbol === 'BNB' ? (
            <div className="xxs:pr-3 xxs:pl-2">
              <img className="h-5 w-auto" alt="bnb icon" src={BnbIcon} />
            </div>
          ) : (
            <TokenIconWithChain
              chain={b.chain}
              tokenName={b.name}
              className="h-[25px] w-[25px] xxs:mr-2"
              address={b.address}
              symbol={b.symbol || undefined}
            />
          ),
      }))
    : [];

  return (
    <div className="xxs:space-y-4">
      <p className="xxs:mb-4 xxs:text-base dark:text-white-50 ">
        Send your funds to another wallet.
      </p>
      <div className="xxs:text-white-secondary xxs:bg-dex-black-700 rounded xxs:p-2">
        <div className="flex items-center w-full">
          <div className="space-y-1 w-full">
            {toWalletData && (
              <div className="flex space-x-1 items-center">
                <div className="max-w-[230px] truncate">
                  <span className="font-bold">To:</span> {toWalletData.label}
                </div>
                <div>|</div>
                <img
                  className="h-4 w-auto"
                  alt="eth icon"
                  src={chain === Chains.BinanceChain ? BnbIcon : EthIcon}
                />
                <div className="font-bold">
                  {formatTokenAmount(toWalletData.chainBalance)}
                </div>
              </div>
            )}
            <div>
              Address:{' '}
              <EthAddress
                address={toAddress || ''}
                kind="account"
                chain={chain as Chains}
              />
            </div>
          </div>
          <Button
            type="button"
            onClick={() => {
              setIsWalletSet(false);
              setValue('toAddress', undefined as any);
            }}
            className="m-0"
          >
            <XMarkIcon className="h-6 w-6 xxs:text-dex-white-secondary hover:text-dex-white" />
          </Button>
        </div>
      </div>
      <Controller
        name="tokenAddress"
        control={control}
        render={({ field }) =>
          errorWallet ? (
            <div className="text-red-500">
              An error occurred. Reload the page and try again.
            </div>
          ) : (
            <SortDropdown
              mainLabel="Token"
              {...field}
              options={options}
              isLoading={isLoading}
              onChange={(option) => {
                const tA = option;
                const tName = balances
                  ? tA === 'eth'
                    ? 'Ether'
                    : tA === 'bnb'
                    ? 'Binance Coin'
                    : balances?.find((b) => b.address === tA)?.name || ''
                  : '';
                const tSymbol = balances
                  ? tA === 'eth'
                    ? chainAsset(Chains.Ethereum)
                    : tA === 'bnb'
                    ? chainAsset(Chains.BinanceChain)
                    : balances?.find((b) => b.address === tA)?.symbol || ''
                  : '';
                const tMaxBalance = balances
                  ? tA === 'eth'
                    ? balances?.find((b) => b.symbol === 'ETH')?.amount
                    : tA === 'bnb'
                    ? balances?.find((b) => b.symbol === 'BNB')?.amount
                    : balances?.find((b) => b.address === tA)?.amount || 0
                  : 0;

                field.onChange(tA);
                void setValue('amount', 0);
                void setValue('tokenSymbol', tSymbol);
                void setValue('tokenName', tName);
                void setValue('maxBalance', tMaxBalance?.toString());

                trigger()
                  .then(() => {})
                  .catch((e) => {
                    console.error('Error', e);
                  });
              }}
            />
          )
        }
      />
      {errors && errors.tokenAddress?.message && (
        <div className="text-xs text-red-500">
          {errors.tokenAddress?.message}
        </div>
      )}
      <Controller
        name="chain"
        control={control}
        render={({ field }) => (
          <SortDropdown
            {...field}
            disabled={true}
            mainLabel="Chain"
            options={chainsOptions}
          />
        )}
      />

      <Controller
        control={control}
        name="amount"
        render={({ field, fieldState: { error: errore } }) => (
          <NumberInput
            label={'Amount'}
            type="text"
            {...field}
            onChange={(e) => {
              setValue('amount', e?.currentTarget?.value as unknown as number);
              trigger()
                .then(() => {})
                .catch((er) => {
                  console.error('Error', er);
                });
            }}
            extraLabel={
              <Button
                type="button"
                disabled={!selectedTokenAddress}
                onClick={() => {
                  const selectedTokenAmount =
                    selectedTokenAddress &&
                    balances &&
                    selectedTokenAddress === 'eth'
                      ? balances?.find((b) => b.symbol === 'ETH')?.amount
                      : selectedTokenAddress === 'bnb'
                      ? balances?.find((b) => b.symbol === 'BNB')?.amount
                      : balances?.find(
                          (b) =>
                            b.address.toLowerCase() ===
                            selectedTokenAddress?.toLowerCase()
                        )?.amount;

                  setValue('amount', selectedTokenAmount ?? 0);
                  trigger()
                    .then(() => {})
                    .catch((e) => {
                      console.error('Error', e);
                    });
                }}
                className="m-0 xxs:text-dex-white-secondary hover:text-dex-white p-0 normal-case bg-transparent"
              >
                Max
              </Button>
            }
            suffix={
              <span className="max-w-[100px] truncate">{tokenSymbol}</span>
            }
            error={errore?.message}
          />
        )}
      />

      {errorEstimatedGasAmount ? (
        <div className="text-red-500">
          This transaction will most likely fail. Review your settings.
        </div>
      ) : (
        gasInfo &&
        prices &&
        estimatedGasAmount && (
          <div className="dark:bg-dex-black-700 xxs:px-2 xxs:py-1 ml-auto lg:mt-auto flex space-x-1">
            <div> Estimated Gas Fee:</div>
            <div className="xxs:font-semibold">
              {Big(weiToGwei(estimatedGasAmount ?? 0)).toFixed(1)} gwei ($
              {Big(gweiToEther(weiToGwei(estimatedGasAmount ?? 0)))
                .times(
                  chain === Chains.BinanceChain
                    ? prices?.bnb_price
                    : prices?.eth_price ?? 0
                )
                .toFixed(2)}
              )
            </div>
          </div>
        )
      )}
    </div>
  );
};
export default SendFundsContent;
