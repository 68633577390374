import { styled } from '@slicknode/stylemapper';
import { FullPageNotification } from 'modules/bots-performance/components/full-page-notification';
import mizarIcon from '../../../../../assets/img/logo/mizar_icon.svg';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { ResponsiveTableColumn } from 'modules/shared-components/data-display/table/types';
import { PoolRowInfo } from 'api/contracts';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorBoundary from 'components/error-boundaries';
import Table from 'modules/shared-components/data-display/table';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { Chains } from 'api/d-wallets';
import { FullPageNotificationDex } from '../../../fullPageNotification';
import { thDynamicClass } from '../SnipingOpportunitiesCard';

export const ListItem = styled(
  'li',
  'xxs:flex xxs:justify-between xxs:items-center xxs:gap-2 xxs:text-sm'
);
export const ListItemLabel = styled(
  'div',
  'dark:text-black-100 whitespace-nowrap py-0.5 flex items-center xxs:gap-1'
);
export const ListItemValue = styled(
  'div',
  'dark:text-white whitespace-nowrap truncate xxs:overflow-y-visible py-0.5'
);
export const EmptyPage = ({ title }: { title?: string }) => (
  <FullPageNotification
    height="85vh"
    icon={<img alt="mizar-icon" src={mizarIcon} height="100" />}
    title={title ?? 'No tokens found'}
    subTitle="It looks like there is nothing matching filters."
    footer={true}
  />
);

export const EmptyPageDex = ({
  title,
  subTitle,
  height,
}: {
  title?: string;
  height?: string;
  subTitle?: React.ReactNode | string;
}) => (
  <FullPageNotificationDex
    height={height ?? '85vh'}
    title={title ?? 'No tokens found'}
    subTitle={subTitle ?? 'It looks like there is nothing matching filters.'}
    footer={false}
  />
);

export const ErrorPage = () => (
  <FullPageNotification
    height="85vh"
    icon={
      <XCircleIcon
        className="flex-shrink-0 h-28 w-28 align-center text-red-500"
        aria-hidden="true"
      />
    }
    title="Oops, something has gone wrong here"
    subTitle="There was an error while loading this page. Try to reload it again in 30 seconds or
                    contact us for support."
    href="/"
    hrefTitle="Go back home"
    footer={true}
  />
);

export const ErrorPageDex = ({ height }: { height?: string }) => (
  <FullPageNotificationDex
    height={height ?? '85vh'}
    icon={
      <XCircleIcon
        className="flex-shrink-0 h-12 w-12 align-center text-red-500"
        aria-hidden="true"
      />
    }
    title="Oops, something has gone wrong here"
    subTitle="There was an error while loading this page. Try to reload it again in 30 seconds or
                    contact us for support."
  />
);

interface CallsProps {
  columnsDef: ResponsiveTableColumn<PoolRowInfo>[];
  data: PoolRowInfo;
}

export const PoolRow: FC<CallsProps> = ({ data, columnsDef }) => {
  const history = useHistory();
  const isDesktop = useMediaQuery(screenGte.large);
  return (
    <tr
      onClick={() =>
        history.push({
          pathname: isDesktop
            ? `/dex/snipe/new/${data.chain?.key ?? Chains.Ethereum}/${
                data.tokenAddress
              }`
            : `/dex/research/${data.chain?.key ?? Chains.Ethereum}/${
                data.tokenAddress
              }`,
          state: {
            from: `${history.location.pathname}${history.location.search}`,
          },
        })
      }
      className="border-t border-dex-black-700 lg:dark:bg-dex-black-800 cursor-pointer items-center hover:dark:lg:bg-dex-black-700 lg:grid lg:grid-cols-9"
    >
      <ErrorBoundary>
        {columnsDef.map((column) => {
          const Column = column.component;
          if (!Column) return;
          return (
            <Table.Td key={column.key} className={thDynamicClass(column.key)}>
              <Column data={data} />
            </Table.Td>
          );
        })}
      </ErrorBoundary>
    </tr>
  );
};
