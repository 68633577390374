import {
  CreditCardIcon,
  ExclamationCircleIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import {
  Controller,
  DeepPartial,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { BotKind, FormValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Tag from 'modules/shared-components/data-display/tag';
import { BuyAmount } from './Sections/BuyAmount';
import { SelectDWallet } from './Sections/SelectDWallet';
import { TakeProfit } from './Sections/TakeProfit';
import { SafetyMeasures } from './Sections/SafetyMeasures';
import { MaxSnipesNumber } from './Sections/MaxSnipesNumber';
import { ChannelsSection } from './Sections/Channels';
import { CallsFilter } from './Sections/CallsFilter';
import { useToggle } from 'modules/utils/useToggle';
import { BotConfirmationModal } from './BotConfirmationModal';
import { CooldownPeriod } from './Sections/CooldownPeriod';
import { WalletToCopy } from './Sections/WalletCopyTradingSettings';
import { useEffect } from 'react';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import {
  BOT_DEFAULT_SETTINGS,
  TG_BOT_DEFAULT_SETTINGS,
} from './BOT_DEFAULT_SETTINGS';
import {
  chainMaxBuyGasPriority,
  chainMaxSellGasPriority,
  chainName,
  Chains,
} from 'api/d-wallets';
import { DexCard } from '../../components/cards';
import TextInput from '../../components/inputs/text-input/text-input';
import { BOT_SIDEBAR, Tabs } from '../../components/layout/SIDEBAR_CONST';
import PageTemplate from '../../components/layout';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { useChainPersistedState } from 'modules/layouts/TopBar/components/GasInfo';
import { useLocation } from 'react-router-dom';

const telegramCopyTradingDefaultValues: DeepPartial<FormValues> = {
  tradingSettings: TG_BOT_DEFAULT_SETTINGS,
  type: BotKind.TelegramCopyTrading,
  botSettings: {
    maxNOfSnipes: 10,
    entries: {
      callsFilter: null,
      channels: undefined,
      cooldownPeriod: { periodInHours: 48 },
    },
  },
};

const walletCopyTradingDefaultValues: any = {
  tradingSettings: BOT_DEFAULT_SETTINGS,
  type: BotKind.WalletCopyTrading,
  botSettings: {
    maxNOfSnipes: 10,
    entries: {
      walletAddressToCopy: '',
      copySellSwaps: true,
      isFirstBuy: true,
      copyBuyAmount: false,
      copyBuyAmountPercentage: 50,
      copyBuyMore: false,
      maxBuyMore: 0,
      orderType: 'market',
    },
  },
};

type Props = {
  isWalletCopyTrading?: boolean;
  preSelectedChannel: string | undefined;
  preSelectedAddress: string | undefined;
  preSelectedChain: Chains | undefined;
};

export const BotSettings = ({
  isWalletCopyTrading,
}: {
  isWalletCopyTrading: boolean;
}) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();

  const isError =
    (errors && errors.name) ||
    (errors?.botSettings && 'maxNOfSnipes' in errors.botSettings) ||
    (errors?.botSettings?.entries &&
      ('copySellSwaps' in errors.botSettings.entries ||
        'cooldownPeriod' in errors.botSettings.entries ||
        'periodInHours' in errors.botSettings.entries ||
        'walletAddressToCopy' in errors.botSettings.entries ||
        'channels' in errors.botSettings.entries));

  return (
    <div className="space-y-2">
      <MobileExpandableCard
        mobileLabel="Main Settings"
        renderTrigger={({ open }) => (
          <MobileExpandCardTrigger onClick={open}>
            {isError ? (
              <div className="flex space-x-1 items-center text-sm xxs:text-red-500">
                <ExclamationCircleIcon className="x xxs:w-6 h-6" />
                <div>Missing Information</div>
              </div>
            ) : (
              <div className="flex justify-between items-center text-sm xxs:text-dex-white-secondary">
                <div className="flex space-x-1 items-center">
                  <div>Bot:</div>
                  {watch('name') ? (
                    <div className="xxs:text-dex-white max-w-[70px] truncate">
                      {watch('name')}
                    </div>
                  ) : (
                    <div className="xxs:text-dex-white-secondary">--</div>
                  )}
                  <div>|</div>
                  <div>
                    Max Trades:{' '}
                    <span className="xxs:text-dex-white">
                      {watch('botSettings.maxNOfSnipes') ?? (
                        <div className="xxs:text-dex-white-secondary">--</div>
                      )}
                    </span>
                  </div>
                  <div>|</div>
                  {isWalletCopyTrading ? (
                    <div>
                      Wallet:{' '}
                      <span className="">
                        {watch('botSettings.entries.walletAddressToCopy') ? (
                          <EthAddress
                            address={watch(
                              'botSettings.entries.walletAddressToCopy'
                            )}
                            className="text-white xxs:font-semibold"
                          />
                        ) : (
                          '--'
                        )}
                      </span>
                    </div>
                  ) : (
                    <div>
                      Traders:{' '}
                      <span className="xxs:text-dex-white">
                        {watch('botSettings.entries.channels')?.length > 0 ? (
                          watch('botSettings.entries.channels')?.length
                        ) : (
                          <span className="xxs:text-dex-white-secondary">
                            --
                          </span>
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </MobileExpandCardTrigger>
        )}
      >
        <div className="xxs:space-y-2 xxs:p-2 lg:p-0">
          <BotName />

          <DexCard className="xxs:p-0 lg:p-2">
            <MaxSnipesNumber />
            {!isWalletCopyTrading ? <ChannelsSection /> : <WalletToCopy />}
          </DexCard>
        </div>
      </MobileExpandableCard>
    </div>
  );
};

export function CreateBot({
  isWalletCopyTrading = false,
  preSelectedChannel,
  preSelectedAddress,
  preSelectedChain,
}: Props) {
  const [
    isConfirmationOpen,
    { open: openConfirmation, close: closeConfirmation },
  ] = useToggle();

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: !isWalletCopyTrading
      ? telegramCopyTradingDefaultValues
      : walletCopyTradingDefaultValues,
  });

  const location = useLocation();
  const { state } = location as { state?: { from?: string } };
  const from = state?.from;

  const { handleSubmit, watch, getValues, setValue } = form;

  const [selectedChain] = useChainPersistedState(Chains.Base);

  useEffect(() => {
    if (watch('tradingSettings.chain') !== selectedChain) {
      if (!watch('tradingSettings.isMaxBuyGasPriorityEdited')) {
        setValue(
          'tradingSettings.maxBuyGasPriority',
          chainMaxBuyGasPriority(selectedChain as Chains)
        );
      }
      if (
        !watch('tradingSettings.takeProfitConfig.isMaxSellGasPriorityEdited')
      ) {
        setValue(
          'tradingSettings.takeProfitConfig.maxSellGasPriority',
          chainMaxSellGasPriority(selectedChain as Chains)
        );
      }
      setValue('tradingSettings.walletId', undefined as any);
      setValue('tradingSettings.chain', selectedChain as Chains);
    }
  }, [selectedChain]);

  useEffect(() => {
    if (!isWalletCopyTrading && preSelectedChannel) {
      setValue('botSettings.entries.channels', [preSelectedChannel]);
    }
    if (isWalletCopyTrading && preSelectedAddress && preSelectedChain) {
      setValue('botSettings.entries.walletAddressToCopy', preSelectedAddress);
      setValue('tradingSettings.chain', preSelectedChain);
      setValue(
        'tradingSettings.maxBuyGasPriority',
        chainMaxBuyGasPriority(preSelectedChain)
      );
      setValue(
        'tradingSettings.takeProfitConfig.maxSellGasPriority',
        chainMaxSellGasPriority(preSelectedChain)
      );
    }
  }, [preSelectedChannel, preSelectedAddress, preSelectedChain]);

  const onSubmit = handleSubmit(() => {
    openConfirmation();
  });

  return (
    <PageTemplate
      sidebar={BOT_SIDEBAR}
      classNameSidebarContainer="hidden lg:block"
      pages={[
        {
          href: `${
            from ?? !isWalletCopyTrading
              ? `/dex/bots/${Tabs.TgCopyTrading}`
              : `/dex/bots/${Tabs.WalletCopyTrading}`
          }`,

          name: 'Bots',
        },
        {
          href: '/dex/bots/new',
          name: isWalletCopyTrading ? 'New Wallet Bot' : 'New Telegram Bot',
        },
      ]}
    >
      <div className="space-y-0 pb-16 lg:pb-0">
        <form
          autoComplete="off"
          onSubmit={(e) => {
            void onSubmit(e);
          }}
        >
          <FormProvider {...form}>
            <div className="xxs:space-y-2 xxs:py-2">
              <div className="xxs:px-2 lg:px-0 xxs:text-base lg:text-xl xxs:flex xxs:items-center xxs:gap-3 overflow-hidden ">
                {isWalletCopyTrading ? (
                  <CreditCardIcon className="w-6 h-6  xxs:text-dex-white-secondary flex-shrink-0" />
                ) : (
                  <PhoneIcon className="w-6 h-6  xxs:text-dex-white-secondary flex-shrink-0" />
                )}
                <div className="truncate xxs:text-dex-white-secondary">
                  {watch('name') ||
                    `New ${isWalletCopyTrading ? 'Wallet' : 'Telegram'} Bot`}
                </div>
                <Tag color="dexNeutral" className="h-auto">
                  <div className="flex space-x-1 items-center">
                    <div>
                      <ChainsIcon
                        imgName={watch('tradingSettings.chain') ?? Chains.Base}
                      />
                    </div>
                    <div>
                      {chainName(watch('tradingSettings.chain') ?? Chains.Base)}
                    </div>
                  </div>
                </Tag>
                <Tag color="dexNeutral" className="h-auto">
                  NEW
                </Tag>
                <a
                  className=" xxs:text-dex-white hover:text-dex-white hidden lg:flex"
                  target="_blank"
                  rel="noreferrer"
                  href={
                    !isWalletCopyTrading
                      ? 'https://docs.mizar.com/sniper-bot-manual/bots/telegram-bot'
                      : 'https://docs.mizar.com/sniper-bot-manual/bots/wallet-bot'
                  }
                >
                  <Tag color="blue" className="h-auto ">
                    Help Me
                  </Tag>
                </a>
              </div>

              <div className="xxs:space-y-0 lg:space-y-2">
                <BotSettings
                  isWalletCopyTrading={isWalletCopyTrading ?? false}
                />

                <div className="space-y-2">
                  <TradingSettingsForm
                    isWalletCopyTrading={isWalletCopyTrading}
                  />

                  <div className="xxs:px-2 lg:px-0">
                    <PrimaryButton
                      className="mx-0 xxs:text-base lg:text-xs"
                      size="large"
                      type="submit"
                      variant="primary"
                    >
                      SAVE
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
      {isConfirmationOpen && (
        <BotConfirmationModal
          handleClose={() => closeConfirmation()}
          bot={getValues()}
        />
      )}
    </PageTemplate>
  );
}

export function BotName() {
  const { control } = useFormContext<FormValues>();

  return (
    <Controller
      control={control}
      name="name"
      render={({ field, fieldState: { error: fieldError } }) => (
        <TextInput
          {...field}
          type="text"
          label="Bot Name"
          error={fieldError?.message}
        />
      )}
    />
  );
}

export function TradingSettingsForm({
  isWalletCopyTrading,
}: {
  isWalletCopyTrading: boolean;
}) {
  return (
    <>
      <div className="flex flex-col lg:flex-row lg:gap-2">
        <div className="w-full flex flex-col lg:gap-2">
          <SelectDWallet />
          <BuyAmount />
          <TakeProfit />
        </div>
        <div className="w-full flex flex-col xxs:gap-1">
          <SafetyMeasures isWalletCopyTrading={isWalletCopyTrading} />
        </div>
      </div>
    </>
  );
}

export function TelegramBotSettingsForm() {
  return (
    <DexCard>
      <div className="hidden lg:block font-bold text-sm xxs:text-dex-white-secondary">
        Advanced Settings
      </div>

      <div className="flex flex-col xxs:space-y-1">
        <CooldownPeriod />
        <CallsFilter />
      </div>
    </DexCard>
  );
}
