import { XMarkIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export type FilterTagProps = {
  label: string;
  icon?: JSX.Element | null;
  onRemove?: () => void;
  className?: string;
};

export function FilterTag({
  label,
  icon,
  onRemove,
  className,
}: FilterTagProps) {
  return (
    <span
      className={twMerge(
        'py-0.5 inline-flex items-center xxs:px-1 xxs:bg-white-100 dark:bg-black-600 xxs:rounded-sm xxs:gap-2',
        className
      )}
    >
      {icon && React.cloneElement(icon, { className: 'xxs:w-3.5 xxs:h-3.5' })}
      <div className="text-nowrap xxs:text-xs xxs:font-medium xxs:leading-4 max-w-[140px] truncate">
        {label}
      </div>
      {onRemove ? (
        <button
          type="button"
          onClick={() => onRemove()}
          className="border-0 flex-shrink-0 rounded-full inline-flex items-center justify-center text-mizar-white-800 dark:text-white-200 hover:bg-mizar-white-700 dark:hover:bg-black-700 hover:text-mizar-white-700 dark:hover:text-white-300 focus:outline-none focus:bg-mizar-blue-500 focus:text-white"
        >
          <span className="sr-only">Remove filter {label}</span>
          <XMarkIcon width={12} height={12} stroke="currentColor" />
        </button>
      ) : (
        ''
      )}
    </span>
  );
}
