import { useQueryParams } from 'modules/routing/useQueryParams';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { isEmpty } from 'lodash/fp';
import { toQueryObject } from 'modules/routing/query-string';

const PAGE_SIZE = 20;
export type Periods = '1' | '3' | '7' | '30';

const schema = {
  limit: yup.number().default(PAGE_SIZE),
  offset: yup.number().default(0),
  sort: yup.string().default('-total_number_of_calls'),
  timePeriodInDays: yup.string<Periods>().required().default('30'),
  minRugPulls: yup.number(),
  maxRugPulls: yup.number(),
  minNumberOfCalls: yup.number(),
  maxNumberOfCalls: yup.number(),
  minWinnersVsLosers: yup.number(),
  maxWinnersVsLosers: yup.number(),
  search: yup.string(),
};

export function usePageParams() {
  return useQueryParams(schema);
}

export type TelegramChannelsFilterState = yup.TypeFromShape<typeof schema, any>;

export const CACHE_KEY_TG_CHANNELS = 'telegramChannelsFilters-v4';

export const TELEGRAM_CHANNELS_DEFAULT_FILTERS = {
  limit: PAGE_SIZE,
  activeTab: 'telegram-channels',
  offset: 0,
  sort: '-total_number_of_calls',
  timePeriodInDays: '30',
  maxRugPulls: 0.05,
  minNumberOfCalls: 5,
  minWinnersVsLosers: 0.8,
};

export function useTelegramChannelsSearchFilters() {
  const [filtersState, setFilters] = usePageParams();

  const setFiltersWithCache = useFiltersWithCache(
    setFilters,
    TELEGRAM_CHANNELS_DEFAULT_FILTERS as Partial<TelegramChannelsFilterState>,
    (queryObj) => isEmpty(queryObj)
  );

  const goToPage = (page: number) => {
    setFilters({
      ...filtersState,
      offset: (page - 1) * filtersState.limit,
    });
  };

  const setSort = (key: string) => {
    let newSortKey;
    if (filtersState.sort?.includes(key)) {
      if (filtersState.sort?.charAt(0) === '-') {
        newSortKey = key;
      } else {
        newSortKey = `-${key}`;
      }
    }
    setFiltersWithCache({
      ...filtersState,
      offset: 0,
      sort: newSortKey || key,
    });
  };

  return { filtersState, setFilters: setFiltersWithCache, goToPage, setSort };
}

function useFiltersWithCache(
  setFilters: (filters: TelegramChannelsFilterState) => void,
  defaults: Partial<TelegramChannelsFilterState> = {},
  shouldApplyCache: (filters: TelegramChannelsFilterState) => boolean = () =>
    true
) {
  const location = useLocation();

  useEffect(() => {
    if (
      shouldApplyCache(
        toQueryObject(location.search) as unknown as TelegramChannelsFilterState
      )
    ) {
      const fromCache = window.localStorage.getItem(CACHE_KEY_TG_CHANNELS);

      setFilters(
        fromCache
          ? { ...JSON.parse(fromCache), limit: PAGE_SIZE, offset: 0 }
          : defaults
      );
    }
  }, [location.search]);

  return function cacheAndSetFilters(filters: TelegramChannelsFilterState) {
    window.localStorage.setItem(CACHE_KEY_TG_CHANNELS, JSON.stringify(filters));
    setFilters(filters);
  };
}
