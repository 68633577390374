import AlphaImg from 'assets/img/news/alpha-program.png';
import CustomModal from '../components/modal/CustomModal';

interface Props {
  handleClose: () => void;
}

export function DiscordIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3434_6006)">
        <path
          d="M3.77995 21.196H18.039L17.358 18.991L18.987 20.389L20.48 21.727L23.2 24V2.475C23.132 1.137 21.98 0 20.552 0L3.78495 0.003C2.35795 0.003 1.19995 1.142 1.19995 2.48V18.72C1.19995 20.131 2.35595 21.196 3.77995 21.196V21.196ZM14.328 5.683L14.295 5.695L14.307 5.683H14.328ZM6.69695 6.952C8.52995 5.618 10.229 5.682 10.229 5.682L10.366 5.817C8.12295 6.352 7.10595 7.354 7.10595 7.354C7.10595 7.354 7.37795 7.221 7.85295 7.018C10.874 5.83 14.173 5.916 17.227 7.42C17.227 7.42 16.208 6.483 14.103 5.883L14.289 5.7C14.58 5.701 16.12 5.755 17.768 6.96C17.768 6.96 19.612 10.11 19.612 13.98C19.551 13.906 18.468 15.646 15.681 15.706C15.681 15.706 15.209 15.172 14.873 14.706C16.503 14.238 17.113 13.302 17.113 13.302C16.578 13.639 16.09 13.839 15.694 14.039C15.085 14.307 14.475 14.439 13.866 14.574C10.982 15.042 9.36295 14.259 7.83295 13.638L7.30995 13.372C7.30995 13.372 7.91895 14.308 9.48395 14.776C9.07295 15.245 8.66595 15.778 8.66595 15.778C5.87995 15.712 4.86395 13.972 4.86395 13.972C4.86395 10.096 6.69695 6.952 6.69695 6.952V6.952Z"
          fill="currentColor"
        />
        <path
          d="M14.508 12.7707C15.219 12.7707 15.798 12.1707 15.798 11.4307C15.798 10.6957 15.222 10.0957 14.508 10.0957V10.0987C13.8 10.0987 13.22 10.6967 13.218 11.4367C13.218 12.1707 13.797 12.7707 14.508 12.7707Z"
          fill="currentColor"
        />
        <path
          d="M9.8901 12.7707C10.6011 12.7707 11.1801 12.1707 11.1801 11.4307C11.1801 10.6957 10.6051 10.0957 9.8941 10.0957L9.8901 10.0987C9.1791 10.0987 8.6001 10.6967 8.6001 11.4367C8.6001 12.1707 9.1791 12.7707 9.8901 12.7707V12.7707Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3434_6006">
          <rect
            width="24"
            height="24"
            fill="currentColor"
            transform="translate(0.199951)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export const AlphaProgramModal = ({ handleClose }: Props) => {
  return (
    <CustomModal
      title="Mizar Alpha Program"
      size="xLarge"
      showModal
      handleClose={handleClose}
    >
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Mizar Alpha Program
        </CustomModal.Title>
        <CustomModal.Content>
          <div className="space-y-4 text-center flex flex-col items-center justify-center vh-50 xxs:py-8">
            <div className="text-2xl font-bold xxs:text-dex-white">
              Looking for Alpha?
            </div>
            <div className="text-base font-medium xxs:text-dex-white-secondary">
              Join the{' '}
              <a
                onClick={handleClose}
                href="https://discord.com/invite/gM4mAYMeWG"
                target="_blank"
                rel="noreferrer"
                className="xxs:text-blue-500 hover:text-blue-600"
              >
                💻main-defi-chat
              </a>{' '}
              on our official Discord channel to find top Alpha!
            </div>

            <div className="text-base font-medium xxs:text-dex-white-secondary">
              Since the program launch in November, our community has generated
              over $500,000 in net profit! Don’t miss out.
            </div>

            <div className="min-h-[380px]">
              <img className="w-3/4" src={AlphaImg} />
            </div>

            <div className="text-base font-medium xxs:text-dex-white-secondary">
              Looking to boost your earnings? Share Alpha with our community and
              earn thousands of dollars every week!
            </div>

            <a
              className="text-white magic-button rounded-md xxs:p-2"
              onClick={handleClose}
              target="_blank"
              rel="noreferrer"
              href="https://discord.com/invite/gM4mAYMeWG"
            >
              <div className="flex space-x-2 items-center text-lg">
                <DiscordIcon />
                <div> JOIN NOW</div>
              </div>
            </a>
          </div>
        </CustomModal.Content>
      </CustomModal.Body>
    </CustomModal>
  );
};
