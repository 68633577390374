import {
  BotKind,
  FormValues,
  isWalletCopyTradingType,
  OrderType,
  TelegramBotSettingsSchema,
  TelegramCopyTradingSettingsSchema,
  WalletBotSettingsSchema,
  WalletCopyTradingSettingsSchema,
} from './schema';
import { Button } from 'modules/shared-components/button/button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  formatNumberWithSuffix,
  formatTokenAmount,
  formatWithoutDecimals,
} from 'utils/FormatNumber';
import { parseEther, parseGwei } from 'viem';
import Big from 'big.js';
import { useHistory } from 'react-router-dom';
import { ReactNode } from 'react';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrow-right.svg';
import { twMerge } from 'tailwind-merge';
import { percentageToTimesIncrease } from 'modules/utils/number';
import { percentToRatio } from 'utils/number';
import { dexBotKeys, DexBotService } from 'api/services/DexBotService';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { SniperBotInput } from 'api/dex-bot';
import { isNotNil } from 'modules/utils/isNotNil';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import config from '../../../../config';
import { TgConfirmationDialog } from 'modules/telegram/ConfirmationDailog';
import { chainAsset, chainHasMemPool, chainName, Chains } from 'api/d-wallets';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { NotificationDex } from '../../components/alerts/notification';
import CustomModal from '../../components/modal/CustomModal';
import { Tabs } from '../../components/layout/SIDEBAR_CONST';

interface Props {
  bot: FormValues;
  handleClose: () => void;
}

export const DataRow = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div className="flex justify-between items-center xxs:gap-5">
    <div className="xxs:mb-0 xxs:text-dex-white-secondary xxs:text-sm">
      {label}
    </div>
    <div className="dark:text-white xxs:text-sm truncate">{children}</div>
  </div>
);

export const BotConfirmationModal = ({ bot, handleClose }: Props) => {
  const { addNewMessage } = useSnackBar();
  const queryClient = useQueryClient();
  const isWalletCopyTrading = bot.type === BotKind.WalletCopyTrading;

  const history = useHistory();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: () =>
      DexBotService.create(formValuesToDexBot(bot), isWalletCopyTrading),
    onSuccess: () => {
      void queryClient.invalidateQueries(dexBotKeys.all());
      addNewMessage({
        title: 'Success',
        message: 'Bot created successfully',
        type: 'success',
      });
      history.push(
        isWalletCopyTrading
          ? `/dex/bots/${Tabs.WalletCopyTrading}`
          : `/dex/bots/${Tabs.TgCopyTrading}`
      );
    },
  });

  if (config.isTelegramBrowser) {
    return (
      <TgConfirmationDialog onClose={handleClose} onConfirmed={() => mutate()}>
        Do you want to create this bot?
      </TgConfirmationDialog>
    );
  }

  return (
    <CustomModal
      title="Confirm New Bot"
      size="large"
      showModal
      handleClose={handleClose}
    >
      <CustomModal.Body className="xxs:p-4 hidden lg:flex">
        <CustomModal.Title className="xxs:text-3xl font-semibold xxs:leading-5 xxs:mb-4">
          Confirm New Bot
        </CustomModal.Title>
        <CustomModal.Content>
          <BotSummary bot={bot} />
          <NotificationDex type="info" className="xxs:my-2 xxs:mx-0">
            {bot.type === BotKind.TelegramCopyTrading ? (
              <span>
                By confirming, you will automatically trade all the tokens
                called in the selected Telegram channels that meet your
                predefined filters and safety criteria
              </span>
            ) : (
              <span>
                By confirming, you will automatically trade all the tokens
                traded by the copied wallet that meet your predefined filters
                and safety criteria
              </span>
            )}
          </NotificationDex>
          {error && (
            <NotificationDex
              type="error"
              errorMessage={error}
              className="xxs:my-2"
            >
              An error occurred.
            </NotificationDex>
          )}{' '}
        </CustomModal.Content>
      </CustomModal.Body>

      <CustomModal.Footer>
        <PrimaryButton
          type="button"
          className="xxs:text-base lg:text-xs"
          loading={isLoading}
          onClick={() => mutate()}
        >
          save
        </PrimaryButton>
        <Button
          className="xxs:text-base lg:text-xs"
          type="button"
          variant="dexNeutral"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

function buyAmountWallet(
  newTradingSettings: WalletCopyTradingSettingsSchema,
  previousTradingSettings: WalletCopyTradingSettingsSchema
) {
  return (
    <>
      <DataRow label="Trade Launched Tokens">Yes</DataRow>
      <div className="bg-dex-black-700 xxs:p-2 rounded xxs:space-y-2">
        <DataRow label="Gas Priority">
          <PositionChange
            before={
              previousTradingSettings &&
              !Big(newTradingSettings.maxBuyGasPriority).eq(
                previousTradingSettings.maxBuyGasPriority as number
              ) && <>{previousTradingSettings.maxBuyGasPriority} gwei</>
            }
            after={<>{newTradingSettings.maxBuyGasPriority} gwei</>}
          />
        </DataRow>
        <DataRow label="Slippage">
          <PositionChange
            before={
              previousTradingSettings &&
              !Big(newTradingSettings.slippage).eq(
                previousTradingSettings.slippage as number
              ) && <>{previousTradingSettings.slippage}%</>
            }
            after={<>{newTradingSettings.slippage}%</>}
          />
        </DataRow>
        {chainHasMemPool(newTradingSettings.chain) && (
          <DataRow label="MEV Protection">
            <PositionChange
              before={
                previousTradingSettings &&
                !(
                  newTradingSettings.antiMev === previousTradingSettings.antiMev
                ) && (
                  <>
                    {previousTradingSettings.antiMev ? 'Enabled' : 'Disabled'}
                  </>
                )
              }
              after={<>{newTradingSettings.antiMev ? 'Enabled' : 'Disabled'}</>}
            />
          </DataRow>
        )}
      </div>
      <DataRow label="Trade not Launched Tokens">Not Supported</DataRow>
    </>
  );
}

function buyAmountTelegram(
  newTradingSettings: TelegramCopyTradingSettingsSchema,
  previousTradingSettings: TelegramCopyTradingSettingsSchema
) {
  return (
    <>
      {!chainHasMemPool(newTradingSettings.chain) && (
        <>
          <div className="xxs:space-y-2">
            {newTradingSettings.launchedFilter ? (
              <DataRow label="Trade Launched Tokens">Yes</DataRow>
            ) : (
              <DataRow label="Trade Launched Tokens">No</DataRow>
            )}
            {newTradingSettings.notLaunchedFilter ? (
              <DataRow label="Trade Not Launched Tokens">Yes</DataRow>
            ) : (
              <DataRow label="Trade Not Launched Tokens">No</DataRow>
            )}
            <DataRow label="Gas Priority">
              <PositionChange
                before={
                  previousTradingSettings &&
                  !Big(newTradingSettings.maxBuyGasPriority as number).eq(
                    previousTradingSettings.maxBuyGasPriority as string
                  ) && <>{previousTradingSettings.maxBuyGasPriority} gwei</>
                }
                after={<>{newTradingSettings.maxBuyGasPriority} gwei</>}
              />
            </DataRow>
            <DataRow label="Slippage">
              <PositionChange
                before={
                  previousTradingSettings &&
                  !Big(newTradingSettings.slippage as number).eq(
                    previousTradingSettings.slippage as number
                  ) && <>{previousTradingSettings.slippage}%</>
                }
                after={<>{newTradingSettings.slippage}%</>}
              />
            </DataRow>
          </div>
        </>
      )}
      {chainHasMemPool(newTradingSettings.chain) &&
        (newTradingSettings.launchedFilter ? (
          <>
            <DataRow label="Trade Launched Tokens">Yes</DataRow>
            <div className="bg-dex-black-700 xxs:p-2 rounded xxs:space-y-2">
              <DataRow label="Gas Priority">
                <PositionChange
                  before={
                    previousTradingSettings &&
                    !Big(newTradingSettings.maxBuyGasPriority as number).eq(
                      previousTradingSettings.maxBuyGasPriority as string
                    ) && <>{previousTradingSettings.maxBuyGasPriority} gwei</>
                  }
                  after={<>{newTradingSettings.maxBuyGasPriority} gwei</>}
                />
              </DataRow>
              <DataRow label="Slippage">
                <PositionChange
                  before={
                    previousTradingSettings &&
                    !Big(newTradingSettings.slippage as number).eq(
                      previousTradingSettings.slippage as number
                    ) && <>{previousTradingSettings.slippage}%</>
                  }
                  after={<>{newTradingSettings.slippage}%</>}
                />
              </DataRow>
              <DataRow label="MEV Protection">
                <PositionChange
                  before={
                    previousTradingSettings &&
                    !(
                      newTradingSettings.antiMev ===
                      previousTradingSettings.antiMev
                    ) && (
                      <>
                        {previousTradingSettings.antiMev
                          ? 'Enabled'
                          : 'Disabled'}
                      </>
                    )
                  }
                  after={
                    <>{newTradingSettings.antiMev ? 'Enabled' : 'Disabled'}</>
                  }
                />
              </DataRow>
            </div>
          </>
        ) : (
          <DataRow label="Trade Launched Tokens">No</DataRow>
        ))}

      {chainHasMemPool(newTradingSettings.chain) &&
        (newTradingSettings.notLaunchedFilter ? (
          <>
            <DataRow label="Trade not Launched Tokens">Yes</DataRow>
            <div className="bg-dex-black-700 xxs:p-2 rounded xxs:space-y-2">
              <DataRow label="Bribing Fee">
                <PositionChange
                  before={
                    previousTradingSettings &&
                    !Big(newTradingSettings.bribe as number).eq(
                      (previousTradingSettings.bribe as number) ?? 0
                    ) && (
                      <>
                        {previousTradingSettings.bribe}{' '}
                        {chainAsset(newTradingSettings.chain)}
                      </>
                    )
                  }
                  after={
                    <>
                      {newTradingSettings.bribe}{' '}
                      {chainAsset(newTradingSettings.chain)}
                    </>
                  }
                />
              </DataRow>
              <DataRow label="Auto-retry at Block 1">
                <PositionChange
                  before={
                    previousTradingSettings &&
                    !(
                      newTradingSettings.blockOneRetry ===
                      previousTradingSettings.blockOneRetry
                    ) && (
                      <>
                        {previousTradingSettings.blockOneRetry
                          ? 'Enabled'
                          : 'Disabled'}
                      </>
                    )
                  }
                  after={
                    <>
                      {newTradingSettings.blockOneRetry
                        ? 'Enabled'
                        : 'Disabled'}
                    </>
                  }
                />
              </DataRow>
              {newTradingSettings.blockOneRetry && (
                <div className="xxs:p-2 bg-black-500 rounded space-y-2">
                  <DataRow label="Retry Bribing Fee">
                    <PositionChange
                      before={
                        previousTradingSettings &&
                        !Big(newTradingSettings.backupBribe as number).eq(
                          previousTradingSettings.backupBribe === undefined
                            ? 0
                            : (previousTradingSettings.backupBribe as number)
                        ) && (
                          <>
                            {previousTradingSettings.backupBribe ?? '--'}{' '}
                            {chainAsset(newTradingSettings.chain)}
                          </>
                        )
                      }
                      after={
                        <>
                          {newTradingSettings.backupBribe ?? '--'}{' '}
                          {chainAsset(newTradingSettings.chain)}
                        </>
                      }
                    />
                  </DataRow>
                  <DataRow label="Slippage">
                    <PositionChange
                      before={
                        previousTradingSettings &&
                        !Big(newTradingSettings.slippage as number).eq(
                          previousTradingSettings.slippage === undefined
                            ? 0
                            : (previousTradingSettings.slippage as number)
                        ) && <>{previousTradingSettings.slippage}%</>
                      }
                      after={<>{newTradingSettings.slippage}%</>}
                    />
                  </DataRow>
                </div>
              )}
            </div>
          </>
        ) : (
          <DataRow label="Trade not Launched Tokens">No</DataRow>
        ))}
    </>
  );
}

export function BotSummary({
  bot,
  previous,
}: {
  bot: FormValues;
  previous?: FormValues;
}) {
  return (
    <div className="flex flex-col xxs:gap-2">
      <p className="xxs:text-sm xxs:font-semibold dark:text-white xxs:mb-0">
        Copy-trading Settings
      </p>

      <DataRow label="Chain">
        <PositionChange
          before={
            previous &&
            previous.tradingSettings?.chain &&
            previous.tradingSettings?.chain !== bot.tradingSettings?.chain && (
              <>
                <div className="flex space-x-1 items-center">
                  <ChainsIcon
                    imgName={previous.tradingSettings?.chain as Chains}
                  />
                  <div>
                    {chainName(previous.tradingSettings?.chain as Chains)}
                  </div>
                </div>
              </>
            )
          }
          after={
            <>
              {bot.tradingSettings?.chain && (
                <>
                  <div className="flex space-x-1 items-center">
                    {bot.tradingSettings?.chain && (
                      <ChainsIcon
                        imgName={bot.tradingSettings?.chain as Chains}
                      />
                    )}
                    <div>{chainName(bot.tradingSettings?.chain as Chains)}</div>
                  </div>
                </>
              )}
            </>
          }
        />
      </DataRow>

      <DataRow label="Max N. of Trades">
        <PositionChange
          before={
            previous &&
            previous.botSettings.maxNOfSnipes !==
              bot.botSettings.maxNOfSnipes && (
              <>{previous.botSettings.maxNOfSnipes}</>
            )
          }
          after={<> {bot.botSettings.maxNOfSnipes}</>}
        />
      </DataRow>
      {!isWalletCopyTradingType(bot.type, bot.botSettings.entries) ? (
        <>
          <DataRow label="Followed Channels">
            <PositionChange
              before={
                previous &&
                !isWalletCopyTradingType(
                  previous.type,
                  previous.botSettings.entries
                ) &&
                previous.botSettings.entries.channels &&
                previous.botSettings.entries.channels?.length !==
                  bot.botSettings.entries.channels?.length && (
                  <>
                    {previous.botSettings.entries.channels &&
                    previous.botSettings.entries.channels?.length > 0
                      ? previous.botSettings.entries.channels?.length
                      : 'All'}
                  </>
                )
              }
              after={
                <>
                  {' '}
                  {bot.botSettings.entries.channels &&
                  bot.botSettings.entries.channels?.length > 0
                    ? bot.botSettings.entries.channels?.length
                    : 'All'}
                </>
              }
            />
          </DataRow>
          {(bot.botSettings.entries.callsFilter || previous?.botSettings) && (
            <>
              {' '}
              {bot.botSettings.entries.callsFilter?.minNumberOfCalls && (
                <DataRow label="Min number of calls">
                  <PositionChange
                    before={
                      previous &&
                      !isWalletCopyTradingType(
                        previous.type,
                        previous.botSettings.entries
                      ) &&
                      previous.botSettings.entries.callsFilter
                        ?.minNumberOfCalls !==
                        bot.botSettings.entries.callsFilter
                          ?.minNumberOfCalls && (
                        <>
                          {previous.botSettings.entries.callsFilter
                            ?.minNumberOfCalls || 1}
                        </>
                      )
                    }
                    after={
                      <>
                        {' '}
                        {bot.botSettings.entries.callsFilter
                          ?.minNumberOfCalls || 1}
                      </>
                    }
                  />
                </DataRow>
              )}
              {bot.botSettings.entries.callsFilter?.timePeriod && (
                <DataRow label="Time period">
                  <PositionChange
                    before={
                      previous &&
                      !isWalletCopyTradingType(
                        previous.type,
                        previous.botSettings.entries
                      ) &&
                      previous.botSettings.entries.callsFilter?.timePeriod !==
                        bot.botSettings.entries.callsFilter?.timePeriod && (
                        <>
                          {previous.botSettings.entries.callsFilter
                            ?.timePeriod || 0}{' '}
                          day(s)
                        </>
                      )
                    }
                    after={
                      <>
                        {' '}
                        {bot.botSettings.entries.callsFilter?.timePeriod ||
                          0}{' '}
                        day(s)
                      </>
                    }
                  />
                </DataRow>
              )}
            </>
          )}

          {bot.botSettings.entries.cooldownPeriod && (
            <DataRow label="Calls cool-down period">
              <PositionChange
                before={
                  previous &&
                  !isWalletCopyTradingType(
                    previous.type,
                    previous.botSettings.entries
                  ) &&
                  previous.botSettings.entries.cooldownPeriod?.periodInHours !==
                    bot.botSettings.entries.cooldownPeriod?.periodInHours && (
                    <>
                      {previous.botSettings.entries.cooldownPeriod
                        ?.periodInHours || 0}{' '}
                      hour(s)
                    </>
                  )
                }
                after={
                  bot.botSettings.entries.cooldownPeriod ? (
                    <>
                      {' '}
                      {
                        bot.botSettings.entries.cooldownPeriod.periodInHours
                      }{' '}
                      hour(s)
                    </>
                  ) : (
                    <>--</>
                  )
                }
              />
            </DataRow>
          )}
        </>
      ) : (
        <>
          <DataRow label="Copy-trade wallet">
            <PositionChange
              before={
                previous &&
                isWalletCopyTradingType(
                  previous.type,
                  previous.botSettings.entries
                ) &&
                previous.botSettings.entries.walletAddressToCopy !==
                  bot.botSettings.entries.walletAddressToCopy && (
                  <>{previous.botSettings.entries.walletAddressToCopy}</>
                )
              }
              after={<> {bot.botSettings.entries.walletAddressToCopy}</>}
            />
          </DataRow>
          <DataRow label="Copy Sell">
            <PositionChange
              before={
                previous &&
                isWalletCopyTradingType(
                  previous.type,
                  previous.botSettings.entries
                ) &&
                previous.botSettings.entries.copySellSwaps !==
                  bot.botSettings.entries.copySellSwaps && (
                  <>
                    {' '}
                    <>
                      {previous.botSettings.entries.copySellSwaps
                        ? 'Enabled'
                        : 'Disabled'}
                    </>
                  </>
                )
              }
              after={
                <>
                  {bot.botSettings.entries.copySellSwaps
                    ? 'Enabled'
                    : 'Disabled'}
                </>
              }
            />
          </DataRow>
          <DataRow label="Order Type">
            <PositionChange
              before={
                previous &&
                isWalletCopyTradingType(
                  previous.type,
                  previous.botSettings.entries
                ) &&
                previous.botSettings.entries.orderType !==
                  bot.botSettings.entries.orderType && (
                  <>
                    <>
                      {previous.botSettings.entries.orderType ===
                      OrderType.Market
                        ? 'Market'
                        : 'Limit'}
                    </>
                  </>
                )
              }
              after={
                <>
                  {bot.botSettings.entries.orderType === OrderType.Market
                    ? 'Market'
                    : 'Limit'}
                </>
              }
            />
          </DataRow>
          {bot.botSettings.entries.orderType === OrderType.Limit && (
            <DataRow label="Expiration">
              <PositionChange
                before={
                  previous &&
                  isWalletCopyTradingType(
                    previous.type,
                    previous.botSettings.entries
                  ) &&
                  previous.botSettings.entries.copyLimitExpirationInHours !==
                    bot.botSettings.entries.copyLimitExpirationInHours && (
                    <>
                      <>
                        {
                          previous.botSettings.entries
                            .copyLimitExpirationInHours
                        }{' '}
                        hr
                      </>
                    </>
                  )
                }
                after={
                  <>{bot.botSettings.entries.copyLimitExpirationInHours} hr</>
                }
              />
            </DataRow>
          )}
          <DataRow label="First-Time Buy Only">
            <PositionChange
              before={
                previous &&
                isWalletCopyTradingType(
                  previous.type,
                  previous.botSettings.entries
                ) &&
                previous.botSettings.entries.isFirstBuy !==
                  bot.botSettings.entries.isFirstBuy && (
                  <>
                    {' '}
                    <>
                      {previous.botSettings.entries.isFirstBuy
                        ? 'Enabled'
                        : 'Disabled'}
                    </>
                  </>
                )
              }
              after={
                <>
                  {bot.botSettings.entries.isFirstBuy ? 'Enabled' : 'Disabled'}
                </>
              }
            />
          </DataRow>
          <DataRow label="Copy Buy Amount">
            <PositionChange
              before={
                previous &&
                isWalletCopyTradingType(
                  previous.type,
                  previous.botSettings.entries
                ) &&
                previous.botSettings.entries.copyBuyAmount &&
                previous.botSettings.entries.copyBuyAmountPercentage !==
                  bot.botSettings.entries.copyBuyAmountPercentage && (
                  <>
                    <>
                      {!previous.botSettings.entries.copyBuyAmount ? (
                        'Disabled'
                      ) : (
                        <span>
                          {Big(
                            previous.botSettings.entries
                              .copyBuyAmountPercentage ?? 0
                          ).toFixed(0)}{' '}
                          %
                        </span>
                      )}
                    </>
                  </>
                )
              }
              after={
                <>
                  {' '}
                  {!bot.botSettings.entries.copyBuyAmount ? (
                    'Disabled'
                  ) : (
                    <span>
                      {Big(
                        bot.botSettings.entries.copyBuyAmountPercentage ?? 0
                      ).toFixed(0)}{' '}
                      %
                    </span>
                  )}
                </>
              }
            />
          </DataRow>
          <DataRow label="Copy Buy More">
            <PositionChange
              before={
                previous &&
                isWalletCopyTradingType(
                  previous.type,
                  previous.botSettings.entries
                ) &&
                previous.botSettings.entries.maxBuyMore !==
                  bot.botSettings.entries.maxBuyMore && (
                  <>
                    <>
                      {!previous.botSettings.entries.copyBuyMore ? (
                        'Disabled'
                      ) : (
                        <span>
                          {previous.botSettings.entries.maxBuyMore} buys
                        </span>
                      )}
                    </>
                  </>
                )
              }
              after={
                <>
                  {' '}
                  {!bot.botSettings.entries.copyBuyMore ? (
                    'Disabled'
                  ) : (
                    <span>{bot.botSettings.entries.maxBuyMore} buys</span>
                  )}
                </>
              }
            />
          </DataRow>
        </>
      )}

      <Divider />
      <p className="xxs:text-sm xxs:font-semibold dark:text-white xxs:mb-0">
        Buy Settings
      </p>
      <DataRow label="Buy amount">
        <PositionChange
          before={
            previous?.tradingSettings &&
            !Big(bot.tradingSettings.buyAmount).eq(
              previous.tradingSettings.buyAmount
            ) && (
              <>
                {formatTokenAmount(previous.tradingSettings.buyAmount)}{' '}
                {chainAsset(previous.tradingSettings.chain)}
              </>
            )
          }
          after={
            <>
              {' '}
              {formatTokenAmount(bot.tradingSettings.buyAmount)}{' '}
              {chainAsset(bot.tradingSettings.chain)}
            </>
          }
        />
      </DataRow>
      {isWalletCopyTradingType(bot.type, bot.botSettings.entries)
        ? buyAmountWallet(
            bot.tradingSettings as WalletCopyTradingSettingsSchema,
            previous?.tradingSettings as WalletCopyTradingSettingsSchema
          )
        : buyAmountTelegram(
            bot.tradingSettings as TelegramCopyTradingSettingsSchema,
            previous?.tradingSettings as TelegramCopyTradingSettingsSchema
          )}

      <Divider />

      <p className="xxs:text-sm xxs:font-semibold dark:text-white xxs:mb-0">
        Sell Settings
      </p>

      {!bot.tradingSettings.takeProfit?.length ? (
        <DataRow label="Take Profit">Disabled</DataRow>
      ) : (
        ''
      )}

      {bot.tradingSettings.takeProfit.map((takeProfit, index) => {
        const previousTp = previous?.tradingSettings.takeProfit.find(
          (x) => x.id === takeProfit.id
        );

        return (
          <DataRow label={index === 0 ? `Take Profit` : ''} key={index}>
            <PositionChange
              before={
                previous && !previousTp ? (
                  <>--</>
                ) : (
                  previousTp &&
                  !(
                    Big(takeProfit.threshold).eq(previousTp.threshold) &&
                    Big(takeProfit.weight).eq(previousTp.weight)
                  ) && (
                    <>
                      +{percentageToTimesIncrease(previousTp.threshold)}X (
                      {previousTp.weight}%)
                    </>
                  )
                )
              }
              after={
                <>
                  +{percentageToTimesIncrease(takeProfit.threshold)}X (
                  {takeProfit.weight}%)
                </>
              }
            />
          </DataRow>
        );
      })}

      {previous?.tradingSettings.takeProfit
        .filter(
          (x) =>
            !bot.tradingSettings.takeProfit.find((curr) => curr.id === x.id)
        )
        .map((takeProfit, index) => {
          return (
            <DataRow label={index === 0 ? `Removed TP` : ''} key={index}>
              <span className="xxs:text-yellow-500 line-through">
                +{percentageToTimesIncrease(takeProfit.threshold)}X (
                {takeProfit.weight}%)
              </span>
            </DataRow>
          );
        })}

      {!bot.tradingSettings.stopLoss ? (
        <DataRow label="Stop Loss">Disabled</DataRow>
      ) : (
        ''
      )}
      {(!!bot.tradingSettings.stopLoss ||
        !!previous?.tradingSettings.stopLoss) && (
        <>
          <p className="xxs:text-sm xxs:font-semibold dark:text-black-100 xxs:mb-0">
            Stop loss
          </p>

          <DataRow label="Stop Loss Threshold">
            <PositionChange
              before={
                previous &&
                !Big(previous.tradingSettings.stopLoss?.threshold || 1).eq(
                  bot.tradingSettings.stopLoss?.threshold || 1
                ) &&
                (isNotNil(previous.tradingSettings.stopLoss?.threshold) ? (
                  <>{previous.tradingSettings.stopLoss?.threshold}%</>
                ) : (
                  <>--</>
                ))
              }
              after={
                bot.tradingSettings.stopLoss?.threshold ? (
                  <>{bot.tradingSettings.stopLoss.threshold}%</>
                ) : (
                  <>--</>
                )
              }
            />
          </DataRow>

          {(bot.tradingSettings.stopLoss?.deviation ||
            previous?.tradingSettings.stopLoss?.deviation) && (
            <DataRow label="Stop Loss Trailing Deviation">
              <PositionChange
                before={
                  previous &&
                  !Big(bot.tradingSettings.stopLoss?.deviation || 1).eq(
                    previous.tradingSettings.stopLoss?.deviation || 1
                  ) &&
                  (previous.tradingSettings.stopLoss?.deviation ? (
                    <>{previous.tradingSettings.stopLoss.deviation}%</>
                  ) : (
                    <>--</>
                  ))
                }
                after={
                  bot.tradingSettings.stopLoss?.deviation ? (
                    <>{bot.tradingSettings.stopLoss.deviation}%</>
                  ) : (
                    <>--</>
                  )
                }
              />
            </DataRow>
          )}
        </>
      )}

      {(!!bot.tradingSettings.takeProfit.length ||
        !!previous?.tradingSettings.takeProfit.length ||
        !!bot.tradingSettings.stopLoss ||
        !!previous?.tradingSettings.stopLoss) && (
        <>
          <DataRow label="Slippage">
            <PositionChange
              before={
                previous &&
                !Big(bot.tradingSettings.takeProfitConfig.slippage).eq(
                  previous.tradingSettings.takeProfitConfig.slippage
                ) && <>{previous.tradingSettings.takeProfitConfig.slippage}%</>
              }
              after={<>{bot.tradingSettings.takeProfitConfig.slippage}%</>}
            />
          </DataRow>

          <DataRow label="Gas Priority">
            <PositionChange
              before={
                previous &&
                !Big(
                  bot.tradingSettings.takeProfitConfig.maxSellGasPriority
                ).eq(
                  previous.tradingSettings.takeProfitConfig.maxSellGasPriority
                ) && (
                  <>
                    {
                      previous.tradingSettings.takeProfitConfig
                        .maxSellGasPriority
                    }{' '}
                    gwei
                  </>
                )
              }
              after={
                <>
                  {bot.tradingSettings.takeProfitConfig.maxSellGasPriority} gwei
                </>
              }
            />
          </DataRow>
          {chainHasMemPool(bot.tradingSettings.chain) && (
            <DataRow label="MEV Protection">
              <PositionChange
                before={
                  previous &&
                  !(
                    bot.tradingSettings.takeProfitConfig.antiMev ===
                    previous.tradingSettings.takeProfitConfig.antiMev
                  ) && (
                    <>
                      {previous.tradingSettings.takeProfitConfig.antiMev
                        ? 'Enabled'
                        : 'Disabled'}
                    </>
                  )
                }
                after={
                  <>
                    {bot.tradingSettings.takeProfitConfig.antiMev
                      ? 'Enabled'
                      : 'Disabled'}
                  </>
                }
              />
            </DataRow>
          )}
        </>
      )}
      <Divider />

      <p className="xxs:text-sm xxs:font-semibold dark:text-white xxs:mb-0">
        Safety Settings
      </p>
      <DataRow label="Min-Max Liquidity">
        <PositionChange
          before={
            previous &&
            !(
              Big(bot.tradingSettings.safetyMeasures.liquidity.min).eq(
                previous.tradingSettings.safetyMeasures.liquidity.min
              ) &&
              Big(bot.tradingSettings.safetyMeasures.liquidity.max).eq(
                previous.tradingSettings.safetyMeasures.liquidity.max
              )
            ) && (
              <>
                $
                {formatNumberWithSuffix(
                  previous.tradingSettings.safetyMeasures.liquidity.min
                )}{' '}
                - $
                {formatNumberWithSuffix(
                  previous.tradingSettings.safetyMeasures.liquidity.max
                )}
              </>
            )
          }
          after={
            Big(bot.tradingSettings.safetyMeasures.liquidity.min).gt(0) ||
            Big(bot.tradingSettings.safetyMeasures.liquidity.max).lt(
              Number.MAX_SAFE_INTEGER
            ) ? (
              <>
                $
                {formatNumberWithSuffix(
                  bot.tradingSettings.safetyMeasures.liquidity.min
                )}{' '}
                - $
                {formatNumberWithSuffix(
                  bot.tradingSettings.safetyMeasures.liquidity.max
                )}
              </>
            ) : (
              'Disabled'
            )
          }
        />
      </DataRow>
      <DataRow label="Min-Max Market Cap">
        <PositionChange
          before={
            previous &&
            !(
              Big(bot.tradingSettings.safetyMeasures.marketCap.min).eq(
                previous.tradingSettings.safetyMeasures.marketCap.min
              ) &&
              Big(bot.tradingSettings.safetyMeasures.marketCap.max).eq(
                previous.tradingSettings.safetyMeasures.marketCap.max
              )
            ) && (
              <>
                $
                {formatNumberWithSuffix(
                  previous.tradingSettings.safetyMeasures.marketCap.min
                )}{' '}
                - $
                {formatNumberWithSuffix(
                  previous.tradingSettings.safetyMeasures.marketCap.max
                )}
              </>
            )
          }
          after={
            Big(bot.tradingSettings.safetyMeasures.marketCap.min).gt(0) ||
            Big(bot.tradingSettings.safetyMeasures.marketCap.max).lt(
              Number.MAX_SAFE_INTEGER
            ) ? (
              <>
                $
                {formatNumberWithSuffix(
                  bot.tradingSettings.safetyMeasures.marketCap.min
                )}{' '}
                - $
                {formatNumberWithSuffix(
                  bot.tradingSettings.safetyMeasures.marketCap.max
                )}
              </>
            ) : (
              'Disabled'
            )
          }
        />
      </DataRow>

      <DataRow label="Tax Protection">
        <PositionChange
          before={
            previous &&
            !(
              Big(bot.tradingSettings.safetyMeasures.taxProtection.buy).eq(
                previous.tradingSettings.safetyMeasures.taxProtection.buy
              ) &&
              Big(bot.tradingSettings.safetyMeasures.taxProtection.sell).eq(
                previous.tradingSettings.safetyMeasures.taxProtection.sell
              )
            ) && (
              <>
                Buy: {previous.tradingSettings.safetyMeasures.taxProtection.buy}
                % - Sell:{' '}
                {previous.tradingSettings.safetyMeasures.taxProtection.sell}%
              </>
            )
          }
          after={
            Big(bot.tradingSettings.safetyMeasures.taxProtection.buy).lt(100) ||
            Big(bot.tradingSettings.safetyMeasures.taxProtection.sell).lt(
              100
            ) ? (
              <>
                Buy: {bot.tradingSettings.safetyMeasures.taxProtection.buy}% -{' '}
                Sell: {bot.tradingSettings.safetyMeasures.taxProtection.sell}%
              </>
            ) : (
              'Disabled'
            )
          }
        />
      </DataRow>

      <DataRow label="Gas Limit">
        <PositionChange
          before={
            previous &&
            !(
              Big(
                bot.tradingSettings.safetyMeasures.gasLimit?.toString() ?? '0'
              ).eq(
                previous.tradingSettings.safetyMeasures.gasLimit?.toString() ??
                  '0'
              ) &&
              Big(
                bot.tradingSettings.safetyMeasures.gasLimit?.toString() ?? '0'
              ).eq(
                previous.tradingSettings.safetyMeasures.gasLimit?.toString() ??
                  '0'
              )
            ) && (
              <>
                {formatWithoutDecimals(
                  (previous.tradingSettings.safetyMeasures
                    .gasLimit as number) ?? 0
                )}
                wei
              </>
            )
          }
          after={
            bot.tradingSettings.safetyMeasures.gasLimitEnabled ? (
              <>
                {formatWithoutDecimals(
                  (bot.tradingSettings.safetyMeasures.gasLimit as number) ?? 0
                )}{' '}
                wei
              </>
            ) : (
              'Disabled'
            )
          }
        />
      </DataRow>

      <DataRow label="Honeypot protection">
        <PositionChange
          before={
            previous &&
            !(
              bot.tradingSettings.safetyMeasures.honeypotProtection ===
              previous.tradingSettings.safetyMeasures.honeypotProtection
            ) && (
              <>
                {previous.tradingSettings.safetyMeasures.honeypotProtection
                  ? 'Enabled'
                  : 'Disabled'}
              </>
            )
          }
          after={
            <>
              {bot.tradingSettings.safetyMeasures.honeypotProtection
                ? 'Enabled'
                : 'Disabled'}
            </>
          }
        />
      </DataRow>
      {bot.tradingSettings.safetyMeasures.autoRetryEnabled ? (
        <DataRow label="Auto-Retry">Enabled</DataRow>
      ) : (
        ''
      )}
    </div>
  );
}

export function PositionChange({
  before,
  after,
}: {
  before: ReactNode;
  after: ReactNode;
}) {
  return (
    <div
      className={twMerge(
        'ml-auto whitespace-nowrap flex items-center gap-x-2',
        before && 'xxs:text-yellow-500'
      )}
    >
      {before} {before && <ArrowRight className="align-baseline" />} {after}
    </div>
  );
}

export function Divider() {
  return <div className="h-[1px] dark:bg-dex-black-700 xxs:my-2" />;
}

export function formValuesToDexBot(values: FormValues): SniperBotInput {
  const position = {
    vaultId: values.tradingSettings.walletId,
    buyAmountInWei: parseEther(
      values.tradingSettings.buyAmount.toString()
    ).toString(),
    chain: values.tradingSettings?.chain,
    antiMev: chainHasMemPool(values.tradingSettings?.chain)
      ? (values.tradingSettings.antiMev as boolean) ?? false
      : false,
    honeypotProtection:
      values.tradingSettings.safetyMeasures.honeypotProtection,
    maxBuyTax: percentToRatio(
      values.tradingSettings.safetyMeasures.taxProtection.buy || 0
    ),
    maxSellTax: percentToRatio(
      values.tradingSettings.safetyMeasures.taxProtection.sell || 0
    ),
    maxLiquidityInUsd: values.tradingSettings.safetyMeasures.liquidity.max || 0,
    minLiquidityInUsd: values.tradingSettings.safetyMeasures.liquidity.min || 0,
    maxMarketcapInUsd: values.tradingSettings.safetyMeasures.marketCap.max || 0,
    minMarketcapInUsd: values.tradingSettings.safetyMeasures.marketCap.min || 0,
    ...(values.tradingSettings?.safetyMeasures.gasLimitEnabled
      ? { gasLimit: values.tradingSettings.safetyMeasures.gasLimit as number }
      : {}),
    maxPriorityFeePerGasWei: parseGwei(
      values.tradingSettings.maxBuyGasPriority?.toString() ?? '0'
    ).toString(),
    maxSlippage: percentToRatio(
      (values.tradingSettings.slippage as number) ?? 0
    ),
    takeProfits: values.tradingSettings.takeProfit.map(
      ({ id, threshold, weight }) => ({
        id,
        retry: values.tradingSettings?.safetyMeasures.autoRetryEnabled
          ? values.tradingSettings?.safetyMeasures.autoRetry.takeProfit
          : false,
        antiMev: chainHasMemPool(values.tradingSettings?.chain)
          ? (values.tradingSettings?.takeProfitConfig.antiMev as boolean) ??
            false
          : false,
        priceIncreasePercentage: percentToRatio(threshold).toString(),
        amountToSellPercentage: percentToRatio(weight).toString(),
        maxPriorityFeePerGasWei: parseGwei(
          values.tradingSettings.takeProfitConfig.maxSellGasPriority.toString()
        ).toString(),
        maxSlippage: percentToRatio(
          values.tradingSettings.takeProfitConfig.slippage
        ).toString(),
      })
    ),
    trailingStopLoss: values.tradingSettings.stopLoss
      ? {
          amountToSellPercentage: '1',
          retry: values.tradingSettings?.safetyMeasures.autoRetryEnabled
            ? values.tradingSettings?.safetyMeasures.autoRetry.stopLoss
            : false,
          antiMev: chainHasMemPool(values.tradingSettings?.chain)
            ? (values.tradingSettings?.takeProfitConfig.antiMev as boolean) ??
              false
            : false,
          initialStopLossPercentage: (-percentToRatio(
            values.tradingSettings.stopLoss.threshold
          )).toString(),
          trailingDeviationPercentage: percentToRatio(
            values.tradingSettings.stopLoss.deviation || 100
          ).toString(),
          maxPriorityFeePerGasWei: parseGwei(
            values.tradingSettings.takeProfitConfig.maxSellGasPriority.toString()
          ).toString(),
          maxSlippage: percentToRatio(
            values.tradingSettings.takeProfitConfig.slippage
          ).toString(),
        }
      : null,
  };

  const { botSettings } = values;

  function telegramCopyTrading(
    name: string,
    tradingSettings: TelegramCopyTradingSettingsSchema,
    botSettingsForm: TelegramBotSettingsSchema
  ) {
    return {
      name,
      isSnipingPostLaunch: tradingSettings.launchedFilter,
      isSnipingPreLaunch: tradingSettings.notLaunchedFilter,
      maxNumActivePositions: botSettingsForm.maxNOfSnipes,
      cooldownInHours: botSettingsForm.entries.cooldownPeriod?.periodInHours,
      telegramCallsPerTimePeriodFilter: botSettingsForm.entries.callsFilter
        ? {
            numberOfCalls: botSettingsForm.entries.callsFilter.minNumberOfCalls,
            timePeriodWindowInDays:
              botSettingsForm.entries.callsFilter.timePeriod,
          }
        : null,
      telegramChannelFilter:
        botSettingsForm.entries.channels &&
        botSettingsForm.entries.channels?.length > 0
          ? {
              subscribed_channels_ids: botSettingsForm.entries.channels,
            }
          : null,
      position: {
        ...position,
        ...(chainHasMemPool(tradingSettings.chain)
          ? {
              bribe: parseEther(
                tradingSettings.bribe?.toString() ?? '0'
              ).toString(),
            }
          : {}),
        ...(chainHasMemPool(tradingSettings.chain)
          ? { blockOneRetry: tradingSettings.blockOneRetry }
          : {}),
        ...(chainHasMemPool(tradingSettings.chain) &&
        tradingSettings.blockOneRetry
          ? {
              backupBribe: parseEther(
                tradingSettings.backupBribe?.toString() ?? '0'
              ).toString(),
            }
          : {}),
      },
      vaultId: tradingSettings.walletId,
    };
  }

  function walletCopyTrading(
    name: string,
    tradingSettings: WalletCopyTradingSettingsSchema,
    botSettingsForm: WalletBotSettingsSchema
  ) {
    return {
      name,
      maxNumActivePositions: botSettingsForm.maxNOfSnipes,
      walletAddressToCopy: botSettingsForm.entries.walletAddressToCopy,
      copySellSwaps: botSettingsForm.entries.copySellSwaps,
      copySellSizing: botSettingsForm.entries.copySellSwaps,
      copyLimit: botSettingsForm.entries.orderType === OrderType.Limit,
      ...(botSettingsForm.entries.copyBuyAmount
        ? {
            copyBuyAmountPercentage: Big(
              botSettingsForm.entries.copyBuyAmountPercentage ?? 0
            ).div(100),
          }
        : {}),
      copyBuyMore: botSettingsForm.entries.copyBuyMore,
      maxBuyMore: botSettingsForm.entries.copyBuyMore
        ? botSettingsForm.entries.maxBuyMore
        : 0,
      openOnlyIfFirst: botSettingsForm.entries.isFirstBuy,
      copyLimitExpirationInHours:
        botSettingsForm.entries.orderType === OrderType.Limit
          ? botSettingsForm.entries.copyLimitExpirationInHours
          : 0,
      position,
      vaultId: tradingSettings.walletId,
      cooldownInHours: undefined,
      isSnipingPostLaunch: true,
      isSnipingPreLaunch: true,
      telegramCallsPerTimePeriodFilter: null,
      telegramChannelFilter: null,
    };
  }

  if (!isWalletCopyTradingType(values.type, botSettings.entries)) {
    return telegramCopyTrading(
      values.name || '',
      values.tradingSettings as TelegramCopyTradingSettingsSchema,
      botSettings as TelegramBotSettingsSchema
    );
  } else {
    return walletCopyTrading(
      values.name || '',
      values.tradingSettings as WalletCopyTradingSettingsSchema,
      botSettings as WalletBotSettingsSchema
    );
  }
}
