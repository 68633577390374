import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../schema';
import {
  RowLabel,
  RowLabelledInput,
} from '../../../components/RowLabelledInput';
import Tooltip from 'modules/shared-components/tooltip';
import { useState } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { chainHasMemPool } from 'api/d-wallets';
import NumberInput from '../../../components/inputs/number-input';
import Checkbox from '../../../components/inputs/Checkbox';

export function SellSettings() {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [openBox, setOpenBox] = useState(false);
  const chain = watch('tradingSettings.chain');

  const isError =
    errors &&
    (errors.tradingSettings?.takeProfitConfig?.slippage ||
      errors.tradingSettings?.takeProfitConfig?.maxSellGasPriority ||
      errors.tradingSettings?.takeProfitConfig?.antiMev);
  return (
    <>
      <button
        type="button"
        onClick={() => setOpenBox(!openBox)}
        className={twMerge(
          ' m-0 p-0 font-normal normal-case justify-start',
          isError ? 'text-red-500' : ''
        )}
      >
        <div className="flex justify-between items-center xxs:text-dex-white-secondary hover:text-dex-white">
          <div className="flex space-x-1 items-center">
            {isError && (
              <ExclamationCircleIcon className="xxs:text-red-500 xxs:w-4 h-4" />
            )}
            <Tooltip text="Set-up sell gas priority, slippage, and MEV protection.">
              <div className="xxs:text-sm">
                Gas, Slippage
                {chainHasMemPool(chain) && <span>, and MEV protection</span>}
              </div>
            </Tooltip>
          </div>
          <div>
            {openBox ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </div>
        </div>
      </button>

      {openBox && (
        <div className="">
          <RowLabelledInput
            label={
              <RowLabel
                label="Slippage"
                tooltip="The slippage threshold for executing your sell transaction."
              />
            }
            className="xxs:py-1"
          >
            <Controller
              name={'tradingSettings.takeProfitConfig.slippage'}
              defaultValue={'' as any}
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <NumberInput
                    {...field}
                    id="tp-slippage"
                    containerClassName="xxs:w-[108px]"
                    inputSize="small"
                    suffix={<>%</>}
                    error={error?.message}
                  />
                );
              }}
            />
          </RowLabelledInput>

          <RowLabelledInput
            label={
              <RowLabel
                label="Gas Priority"
                tooltip="The bot automatically adjusts your transaction gas to the average gas price and add the chosen gas priority on top of it, when executing the sell transaction."
              />
            }
            className="xxs:py-1"
          >
            <Controller
              name="tradingSettings.takeProfitConfig.maxSellGasPriority"
              control={control}
              defaultValue={'' as any}
              render={({ field, fieldState: { error } }) => (
                <NumberInput
                  containerClassName="xxs:w-[108px]"
                  inputSize="small"
                  suffix={<>gwei</>}
                  {...field}
                  error={error?.message}
                  onChange={(event) => {
                    setValue(
                      'tradingSettings.takeProfitConfig.isMaxSellGasPriorityEdited',
                      true
                    );
                    field.onChange(event.currentTarget.value);
                  }}
                />
              )}
            />
          </RowLabelledInput>
          {chainHasMemPool(chain) && (
            <RowLabelledInput
              label={
                <RowLabel
                  label="MEV Protection"
                  tooltip="When active, the sell transaction will be executed as a private transaction. This will avoid your transaction to be front-run or sandwiched."
                />
              }
              className="xxs:py-1"
            >
              <Controller
                name={'tradingSettings.takeProfitConfig.antiMev'}
                defaultValue={'' as any}
                control={control}
                render={({ field }) => {
                  return (
                    <div className="flex items-center xxs:w-[108px] xxs:gap-2">
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        id="tradingSettings.takeProfitConfig.antiMev"
                      />
                      <Checkbox.Label htmlFor="anti-mev">
                        Enabled
                      </Checkbox.Label>
                    </div>
                  );
                }}
              />
            </RowLabelledInput>
          )}
        </div>
      )}
    </>
  );
}
