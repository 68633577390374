import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

type ModalControl = { close: () => void };
type ModalContextState = ModalControl;

export const ModalContext = createContext({} as ModalContextState);

export const ModalContextProvider = ({
  children,
  close,
}: PropsWithChildren<ModalControl>) => {
  return (
    <ModalContext.Provider value={useMemo(() => ({ close }), [close])}>
      {children}
    </ModalContext.Provider>
  );
};

export function useModal() {
  return useContext(ModalContext);
}
