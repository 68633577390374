import {
  DexTradeTemplate,
  LimitOrdersTemplateSettings,
  TakeProfitEntry,
  TemplateSettings,
  UpdateTemplate,
} from 'api/dex-trade';
import { useFormContext } from 'react-hook-form';
import createPersistedState from 'use-persisted-state';
import {
  EntryKind,
  FormValues,
  LimitBuyAmountSchema,
  MarketBuyAmountSchema,
  OrderKind,
  schema,
  SnipeAmountSchema,
} from '../../schema';
import { BaseDropdown } from 'modules/shared-components/dropdown/BaseDropdown';
import { Combobox } from '@headlessui/react';
import { DropdownOption } from 'modules/shared-components/dropdown';
import Big from 'big.js';
import { fromWei, weiToGwei } from 'modules/ethereum/utils';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { twMerge } from 'tailwind-merge';
import {
  AdjustmentsVerticalIcon,
  DocumentArrowDownIcon,
  FolderPlusIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { mapformValuesToTemplate, SaveTemplateAsModal } from './SaveAsModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import { groupBy, isEqual } from 'lodash/fp';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { DeleteTemplateModal } from './DeleteTemplateModal';
import { RenameTemplateModal } from './RenameTemplateModal';

import React, { useState } from 'react';
import { chainHasMemPool, chainName, Chains } from 'api/d-wallets';
import Tooltip from 'modules/shared-components/tooltip';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { NotificationDex } from '../../../components/alerts/notification';
import {
  ResponsiveMenu,
  ResponsiveMenuItem,
} from '../../../components/dropdown/menu/ResponsiveMenu';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';

type Action = 'rename' | 'saveAs' | 'delete';

export function TemplatesBar({
  templates,
  chain,
}: {
  templates: DexTradeTemplate[];
  chain: Chains;
}) {
  const [activeAction, setActiveAction] = useState<Action>();
  const { getValues, watch, trigger } = useFormContext<FormValues>();
  const state = watch();
  const [activeTemplateId, setActive] = useLastUsedTemplate(templates[0].id);
  const activeTemplate = templates.find((x) => x.id === activeTemplateId);
  const isDesktop = useMediaQuery(screenGte.small);

  async function saveAsCheck() {
    const isFormValid = await trigger();
    if (isFormValid) {
      setActiveAction('saveAs');
    }
  }

  return (
    <div className="xxs:space-y-2 lg:space-y-1 flex xxs:flex-col lg:flex-column-reverse">
      {activeTemplate &&
        !activeTemplate.isMizarTemplate &&
        chain !== activeTemplate.chain && (
          <NotificationDex
            size={isDesktop ? 'xSmall' : 'default'}
            type="warning"
            className="xxs:mx-2 lg:mx-0"
          >
            The selected settings are for {chainName(activeTemplate.chain)}{' '}
            chain. Review them.
          </NotificationDex>
        )}
      <div className="space-x-1 flex xxs:px-1 lg:px-0">
        <div className="xxs:space-x-1 items-center flex grow">
          <Tooltip text="Use Mizar trading pre-sets or create your own to trade faster.">
            <div>
              <AdjustmentsVerticalIcon className="w-6 h-6" />
            </div>
          </Tooltip>
          <TemplateSelector
            templates={templates}
            activeTemplateId={activeTemplateId}
            setActive={setActive}
            chain={chain}
          />
        </div>
        <div className="flex xxs:space-x-1 xxs:px-1 lg:px-0">
          {activeTemplate && !activeTemplate.isMizarTemplate && (
            <div className="hidden lg:flex xxs:gap-1  xxs:text-dex-white-secondary">
              <Tooltip text="Edit current settings.">
                <TagButton
                  className="xxs:h-full"
                  onClick={() => setActiveAction('rename')}
                >
                  <PencilIcon className="xxs:w-4 xxs:h-4" />
                </TagButton>
              </Tooltip>

              <Tooltip text="Delete current settings.">
                <TagButton
                  className="xxs:h-full"
                  onClick={() => setActiveAction('delete')}
                >
                  <TrashIcon className="xxs:w-4 xxs:h-4" />
                </TagButton>
              </Tooltip>
            </div>
          )}
          <div className="hidden lg:flex  xxs:text-dex-white-secondary">
            <Tooltip text="Save as new settings.">
              <TagButton
                className="xxs:h-full"
                onClick={() => {
                  void saveAsCheck();
                }}
              >
                <FolderPlusIcon className="xxs:w-4 xxs:h-4" />
              </TagButton>
            </Tooltip>
          </div>

          {activeTemplate && !activeTemplate.isMizarTemplate && (
            <Save activeTemplate={activeTemplate} chain={chain} />
          )}

          {((activeTemplate &&
            activeTemplate.isMizarTemplate &&
            schema.isValidSync(state)) ||
            (activeTemplate && !activeTemplate.isMizarTemplate)) && (
            <div className="xxs:flex xxs:space-x-1 items-center lg:hidden">
              <div
                className="xxs:flex xxs:gap-2 justify-end z-auto"
                onClick={(e) => e.stopPropagation()}
              >
                <ResponsiveMenu
                  dropdownPlacement="bottom-end"
                  color="dexNeutral"
                  className="xxs:p-1"
                  label="Settings Actions"
                >
                  <ResponsiveMenuItem
                    onClick={() => {
                      void saveAsCheck();
                    }}
                  >
                    <DocumentArrowDownIcon className="xxs:w-5 xxs:h-5" /> Save
                    as
                  </ResponsiveMenuItem>
                  {activeTemplate && !activeTemplate.isMizarTemplate && (
                    <>
                      <ResponsiveMenuItem
                        onClick={() => setActiveAction('rename')}
                      >
                        <PencilIcon className="xxs:w-5 xxs:h-5" /> Rename
                      </ResponsiveMenuItem>

                      <ResponsiveMenuItem
                        onClick={() => setActiveAction('delete')}
                      >
                        <TrashIcon className="xxs:w-5 xxs:h-5" /> Delete
                      </ResponsiveMenuItem>
                    </>
                  )}
                </ResponsiveMenu>
              </div>
            </div>
          )}

          {activeAction === 'saveAs' && (
            <SaveTemplateAsModal
              handleClose={() => setActiveAction(undefined)}
              settings={getValues()}
            />
          )}
          {activeAction === 'rename' && activeTemplate && (
            <RenameTemplateModal
              settings={activeTemplate}
              handleClose={() => setActiveAction(undefined)}
            />
          )}
          {activeAction === 'delete' && activeTemplate && (
            <DeleteTemplateModal
              settings={activeTemplate}
              handleClose={() => setActiveAction(undefined)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function Save({
  activeTemplate,
  chain,
}: {
  activeTemplate: DexTradeTemplate;
  chain: Chains;
}) {
  const { save } = useSave(activeTemplate, chain);
  const { trigger } = useFormContext<FormValues>();

  async function saveCheck() {
    const isFormValid = await trigger();
    if (isFormValid) {
      save();
    }
  }

  return (
    <Tooltip text="Save current settings.">
      <TagButton
        className="xxs:h-full xxs:text-dex-white-secondary hover:text-dex-white"
        onClick={() => void saveCheck()}
      >
        <DocumentArrowDownIcon className="xxs:w-4 xxs:h-4" />
      </TagButton>
    </Tooltip>
  );
}

function useSave(activeTemplate: DexTradeTemplate, chain: Chains) {
  const { watch } = useFormContext<FormValues>();
  const { addNewMessage } = useSnackBar();

  const state = watch();

  const hasChanges = schema.isValidSync(state)
    ? !isEqual(
        templateToDefaults(
          state.entryKind,
          {
            position: mapformValuesToTemplate(state),
            wallets: state.wallets || [],
            chain: state.chain,
          },
          state.chain
        ),
        templateToDefaults(state.entryKind, activeTemplate, state.chain)
      )
    : false;

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: (params: UpdateTemplate) =>
      DexTradeService.updateTemplate(activeTemplate.id, params),
    onSuccess: async () => {
      addNewMessage({
        message: 'Template saved',
        title: 'Success',
        type: 'success',
      });
      await queryClient.invalidateQueries(dexTradeKeys.getTemplates());
    },
    onError: () => {
      addNewMessage({
        message: 'Could not save template',
        title: 'Error',
        type: 'error',
      });
    },
  });

  function save() {
    const mappedPosition = mapformValuesToTemplate(state);

    mutate({
      isDefault: false,
      name: activeTemplate.name,
      position: mappedPosition,
      chain,
      wallets: state.wallets || [],
    });
  }

  return {
    save,
    isValid: schema.isValidSync(state),
    isLoading,
    hasChanges,
  };
}

export const useLastUsedTemplate = createPersistedState<string>(
  'last-trade-template'
);

export function TemplateSelector({
  activeTemplateId,
  setActive,
  templates,
  chain,
}: {
  templates: DexTradeTemplate[];
  activeTemplateId: string;
  setActive: (id: string) => void;
  chain: Chains;
}) {
  const activeTemplate = templates.find((x) => x.id === activeTemplateId);

  const { reset, getValues } = useFormContext<FormValues>();

  const { mizarTemplates = [], customTemplates = [] } = groupBy(
    (x) => (x.isMizarTemplate ? 'mizarTemplates' : 'customTemplates'),
    templates
  );
  return (
    <BaseDropdown
      className="xxs:order-[-1] lg:order-none xxs:mr-auto lg:mr-0 flex grow  xxs:text-dex-white-secondary"
      trigger={
        <Combobox.Button
          as={TagButton}
          className="overflow-hidden w-full xxs:h-8 lg:h-7"
        >
          <div className=" truncate xxs:text-xs max-w-[100px]">
            {activeTemplate?.name || '--'}
          </div>
        </Combobox.Button>
      }
      value={activeTemplateId}
      onChange={(value) => {
        setActive(value);
        const state = getValues();
        const template = templates.find((x) => x.id === value);

        reset({
          address: state.address,
          entryKind: state.entryKind,
          ...templateToDefaults(
            state.entryKind,
            {
              position: template!.position,
              wallets: template!.wallets,
              chain: template!.chain,
            },
            chain
          ),
        });
      }}
    >
      {customTemplates?.map((option, index) => (
        <DropdownOption
          size="xs"
          as={Combobox.Option}
          key={option.id}
          option={{
            label: option.name,
            value: option.id,
            icon: option.chain ? <ChainsIcon imgName={option.chain} /> : <></>,
          }}
          index={index}
        />
      ))}
      {!!customTemplates.length && <Divider />}
      <div className="xxs:ml-2 xxs:font-normal dark:text-white-700 xxs:text-xs">
        Mizar templates
      </div>
      {mizarTemplates?.map((option, index) => (
        <DropdownOption
          size="xs"
          as={Combobox.Option}
          key={option.id}
          option={{
            label: option.name,
            value: option.id,
          }}
          index={index}
        />
      ))}
    </BaseDropdown>
  );
}

export function Divider() {
  return <div className="h-[1px] dark:bg-dex-black-600 xxs:my-2" />;
}

export function templateToDefaults(
  entryKind: EntryKind,
  template: { position: TemplateSettings } & { wallets: string[] | [] } & {
    chain: Chains;
  },
  chain: Chains
): Omit<FormValues, 'address' | 'entryKind'> {
  const positionSettings = template.position;

  const autoRetryEnabled = !!(
    positionSettings.takeProfits?.[0]?.retry ||
    positionSettings.trailingStopLoss?.retry ||
    positionSettings.limitOrders?.[0]?.retry
  );
  const autoRetry = {
    buy: !!positionSettings.limitOrders?.[0]?.retry,
    takeProfit: !!positionSettings.takeProfits?.[0]?.retry,
    stopLoss: !!positionSettings.trailingStopLoss?.retry,
  };

  const firstTp = positionSettings.takeProfits[0] as
    | TakeProfitEntry
    | undefined;

  function snipeOrderMapping(): SnipeAmountSchema {
    return {
      amount: positionSettings.buyAmountInWei
        ? fromWei(positionSettings.buyAmountInWei ?? 0)
        : ('' as any),
      bribe: fromWei(positionSettings.bribe ?? 0),
      blockOneRetry: positionSettings.blockOneRetry || false,
      ...(positionSettings.blockOneRetry
        ? {
            slippage: Big(positionSettings.maxSlippage ?? 0)
              .times(100)
              .toNumber(),
            backupBribe: fromWei(positionSettings.backupBribe ?? 0),
          }
        : {}),
    };
  }

  function marketBuyOrderMapping(): MarketBuyAmountSchema {
    return {
      amount: positionSettings.buyAmountInWei
        ? fromWei(positionSettings.buyAmountInWei)
        : ('' as any),
      maxBuyGasPriority: weiToGwei(
        positionSettings.maxPriorityFeePerGasWei ?? 0
      ),
      slippage: Big(positionSettings.maxSlippage).times(100).toNumber(),
      antiMev: positionSettings.antiMev || false,
    };
  }

  function limitBuyOrderMapping(): LimitBuyAmountSchema {
    const firstLimitOrder =
      positionSettings.limitOrders &&
      (positionSettings.limitOrders[0] as
        | LimitOrdersTemplateSettings
        | undefined);

    return {
      orders: positionSettings.limitOrders
        ? positionSettings.limitOrders.map((limit) => ({
            price: null as any,
            priceDeviationPercentage: Big(-limit.priceDeviationPercentage)
              .times(100)
              .toNumber(),
            amount: fromWei(limit.amount),
          }))
        : [],
      antiMev: firstLimitOrder ? firstLimitOrder.antiMev : false,
      maxBuyGasPriority: firstLimitOrder
        ? weiToGwei(firstLimitOrder.maxPriorityFeePerGasWei)
        : Big(0).toNumber(),
      slippage: firstLimitOrder
        ? Big(firstLimitOrder.maxSlippage).times(100).toNumber()
        : Big(0).toNumber(),
      expirationInHours: firstLimitOrder
        ? Big(firstLimitOrder.expirationInHours).toNumber()
        : Big(0).toNumber(),
    };
  }

  return {
    chain,
    wallets:
      chain === template.chain && template.wallets ? template.wallets : [],
    buyOrder:
      entryKind === EntryKind.Snipe
        ? {
            kind: chainHasMemPool(chain)
              ? OrderKind.Snipe
              : OrderKind.SnipeWithoutMemPool,
            entries: chainHasMemPool(chain)
              ? snipeOrderMapping()
              : marketBuyOrderMapping(),
          }
        : positionSettings.limitOrders &&
          positionSettings.limitOrders.length > 0
        ? {
            kind: OrderKind.Limit,
            entries: limitBuyOrderMapping(),
          }
        : {
            kind: OrderKind.Market,
            entries: marketBuyOrderMapping(),
          },
    safetyMeasures:
      entryKind === EntryKind.Buy
        ? {
            ...buyAlwaysSafetyConfig,
            gasLimitEnabled: !!positionSettings.gasLimit,
            gasLimit: positionSettings.gasLimit,
            autoRetryEnabled,
            autoRetry,
          }
        : {
            marketCapRange:
              Big(positionSettings.minMarketcapInUsd).gt(0) ||
              Big(positionSettings.maxMarketcapInUsd).lt(
                Number.MAX_SAFE_INTEGER
              ),
            autoRetryEnabled,
            autoRetry,
            gasLimitEnabled: !!positionSettings.gasLimit,
            gasLimit: positionSettings.gasLimit,
            liquidityRange:
              Big(positionSettings.minLiquidityInUsd).gt(0) ||
              Big(positionSettings.maxLiquidityInUsd).lt(
                Number.MAX_SAFE_INTEGER
              ),
            taxProtectionEnabled:
              Big(positionSettings.maxBuyTax).lt(1) ||
              Big(positionSettings.maxSellTax).lt(1),
            honeypotProtection: positionSettings.honeypotProtection,
            liquidity: {
              min: Big(positionSettings.minLiquidityInUsd).toNumber(),
              max: Big(positionSettings.maxLiquidityInUsd).toNumber(),
            },
            marketCap: {
              min: Big(positionSettings.minMarketcapInUsd).toNumber(),
              max: Big(positionSettings.maxMarketcapInUsd).toNumber(),
            },
            taxProtection: {
              buy: Big(positionSettings.maxBuyTax).times(100).toNumber(),
              sell: Big(positionSettings.maxSellTax).times(100).toNumber(),
            },
          },
    takeProfit: positionSettings.takeProfits.map((tp) => ({
      weight: Big(tp.amountToSellPercentage).times(100).toNumber(),
      threshold: Big(tp.priceIncreasePercentage).times(100).toNumber(),
    })),
    stopLoss: positionSettings.trailingStopLoss
      ? {
          threshold: Big(
            positionSettings.trailingStopLoss.initialStopLossPercentage
          )
            .abs()
            .times(100)
            .toNumber(),
          isTrailingEnabled: !Big(
            positionSettings.trailingStopLoss.trailingDeviationPercentage || 1
          ).eq(1),
          deviation: Big(
            positionSettings.trailingStopLoss.trailingDeviationPercentage || 1
          ).eq(1)
            ? null
            : Big(
                positionSettings.trailingStopLoss.trailingDeviationPercentage ||
                  1
              )
                .times(100)
                .toNumber(),
        }
      : null,
    takeProfitConfig: {
      maxSellGasPriority: firstTp
        ? weiToGwei(firstTp.maxPriorityFeePerGasWei)
        : 1,
      slippage: firstTp ? Big(firstTp.maxSlippage).times(100).toNumber() : 15,
      antiMev: firstTp
        ? firstTp.antiMev
        : positionSettings.trailingStopLoss
        ? positionSettings.trailingStopLoss.antiMev
        : false,
    },
  };
}

const buyAlwaysSafetyConfig = {
  honeypotProtection: false,
  marketCapRange: false,
  taxProtectionEnabled: false,
  liquidityRange: false,
  liquidity: {
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
  },
  marketCap: {
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
  },
  taxProtection: {
    buy: 100,
    sell: 100,
  },
};

function TagButton(props: React.ComponentProps<typeof BaseButton>) {
  return (
    <BaseButton
      {...props}
      type="button"
      className={twMerge(
        'leading-none bg-dex-black-700 hover:text-dex-white hover:bg-black-600 disabled:dark:text-dex-black-600 disabled:cursor-default xxs:items-center xxs:rounded xxs:px-2 xxs:py-1 xxs:text-sm xxs:flex xxs:gap-1',
        props.className
      )}
    />
  );
}
