import { useQuery } from '@tanstack/react-query';
import {
  blockchainInfoKeys,
  BlockchainInfoService,
} from 'api/services/BlockchainInfoService';
import Big from 'big.js';
import { weiToGwei } from 'modules/ethereum/utils';
import { CubeTransparentIcon } from '@heroicons/react/24/outline';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import { formatWithoutDecimals } from 'utils/FormatNumber';
import GasIcon from '../../../../assets/img/icons/gas-icon.svg';
import createPersistedState from 'use-persisted-state';
import { chainAsset, chainAssetImg, ChainAssets, Chains } from 'api/d-wallets';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { CHAINS_OPTIONS } from '../../../../app/dex/side-panels/WalletsPanel';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { SortDropdown } from '../../../../app/dex/components/dropdown/sort-dropdown';

export const useChainPersistedState =
  createPersistedState<Chains>('d-mizar-chain');

const extractChainFromPath = (path: string) => {
  if (path.startsWith('/dex/snipe/new')) {
    const segments = path.split('/');
    return segments[3] as Chains;
  }
  return null;
};

export const handleChainChange = ({
  chainValue,
  setSelectedChain,
  history,
}: {
  chainValue: Chains;
  setSelectedChain: (arg0: Chains | ((prev: Chains) => Chains)) => void;
  history: History;
}) => {
  const location = history.location.pathname;
  const chainFromParams = extractChainFromPath(location) as Chains | undefined;

  setSelectedChain((prev: Chains) => {
    if (prev === chainValue) {
      return prev;
    }
    return chainValue;
  });

  if (chainFromParams && chainFromParams !== chainValue) {
    if (chainValue === Chains.Ethereum) {
      const defaultContract = '0x6982508145454Ce325dDbE47a25d4ec3d2311933';
      history.push(`/dex/snipe/new/${chainValue}/${defaultContract}`);
    } else if (chainValue === Chains.Base) {
      const defaultContract = '0xB1a03EdA10342529bBF8EB700a06C60441fEf25d';
      history.push(`/dex/snipe/new/${chainValue}/${defaultContract}`);
    } else {
      const defaultContract = '0x6eB6e8974264BeE01c160F1770a38f8e6dE1a3B1';
      history.push(`/dex/snipe/new/${chainValue}/${defaultContract}`);
    }
  }
};

export const GasInfo = () => {
  const [selectedChain, setSelectedChain] = useChainPersistedState(Chains.Base);
  const history = useHistory();
  const refetchTime = selectedChain === Chains.Ethereum ? 15 : 4;

  const { data: gasInfo } = useQuery({
    queryKey: blockchainInfoKeys.getGasInfoWithChain(selectedChain),
    queryFn: () => BlockchainInfoService.getGasInfoWithChain(selectedChain),
    refetchInterval: 1000 * refetchTime,
    staleTime: 1000 * refetchTime,
    cacheTime: 1000 * refetchTime,
    keepPreviousData: true,
  });

  const { data: prices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    refetchInterval: 1000 * refetchTime,
    staleTime: 1000 * refetchTime,
    cacheTime: 1000 * refetchTime,
    keepPreviousData: true,
  });

  const price =
    chainAsset(selectedChain) === ChainAssets.EthereumBase
      ? prices?.eth_price
      : prices?.bnb_price;

  return (
    <div className="">
      <div className="space-x-3 flex items-center">
        <div className="hidden lg:flex items-center xxs:space-x-1 " id="chains">
          <SortDropdown
            className="xxs:p-2 xxs:text-sm h-[38px]"
            mainLabel="Chain"
            onChange={(value) => {
              handleChainChange({
                chainValue: value,
                setSelectedChain,
                history,
              });
            }}
            value={
              CHAINS_OPTIONS.filter((c) => c.value === selectedChain)?.[0]
                ?.value ?? Chains.Base
            }
            options={CHAINS_OPTIONS}
          />
        </div>

        <div className="flex space-x-1 items-center justify-end  xl:min-w-[230px] ">
          <div className="bg-dex-black-700 xxs:p-2 rounded lg:hidden">
            <div className="flex xxs:space-x-1">
              <div className="">
                <ChainsIcon
                  className="xxs:h-5 xxs:w-5"
                  imgName={selectedChain}
                />
              </div>
            </div>
          </div>
          <div className="lg:space-x-1 hidden lg:flex xxs:flex-col lg:flex-row items-center justify-end xxs:text-dex-white-secondary">
            <CubeTransparentIcon className="w-4 h-4 hidden xl:inline-block" />
            <div className="hidden xl:inline-block xxs:text-dex-white">
              {gasInfo ? gasInfo.blockNumber : '--'}
            </div>
            <div className="hidden xl:inline-block">|</div>
            <div className="flex xxs:w-full lg:w-auto xxs:space-x-1 items-center justify-between lg:justify-start">
              <img className="h-4 w-4" alt="gas icon" src={GasIcon} />
              <div className="xxs:text-dex-white">
                {gasInfo?.gasPrice ? (
                  Big(weiToGwei(gasInfo.gasPrice)).toFixed(4)
                ) : (
                  <span className="xxs:text-dex-white-secondary">--</span>
                )}
              </div>
            </div>

            <div className="hidden lg:block">|</div>
            <div className="flex xxs:w-full lg:w-auto items-center space-x-1 justify-between lg:justify-start">
              <img
                className="h-4 w-4"
                alt="token icon"
                src={chainAssetImg(selectedChain)}
              />
              <div className="xxs:text-dex-white">
                ${price ? formatWithoutDecimals(price) : ' --'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
