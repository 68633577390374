import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type StepperContextState<T> = [T, Dispatch<SetStateAction<T>>];

export const StepperContext = createContext([] as unknown);

export const StepperContextProvider = <T,>({
  children,
  initialStep,
}: PropsWithChildren<{ initialStep: T }>) => {
  return (
    <StepperContext.Provider value={useState(initialStep)}>
      {children}
    </StepperContext.Provider>
  );
};

export function useStepperState<T>() {
  return useContext(StepperContext) as StepperContextState<T>;
}
