import { GrayButton } from '../button/color-button';

export function Pagination({ goToPage, activePage }) {
  const hasPreviousPage = activePage.offset > 0;
  const hasNextPage = activePage.total > activePage.offset + activePage.limit;
  const page = activePage.offset / activePage.limit + 1;
  const lastPage = Math.ceil(activePage.total / activePage.limit);

  return (
    <div className="flex justify-between items-center">
      <div className="">
        Page <span>{page}</span> of <span>{lastPage}</span>
      </div>
      <div className="">
        <div className="space-x-1">
          <GrayButton
            size="small"
            disabled={!hasPreviousPage}
            onClick={() => goToPage(1)}
          >
            {'<<'}
          </GrayButton>
          <GrayButton
            size="small"
            disabled={!hasPreviousPage}
            onClick={() => goToPage(page - 1)}
          >
            BACK
          </GrayButton>
          <GrayButton
            size="small"
            disabled={!hasNextPage}
            onClick={() => goToPage(page + 1)}
          >
            NEXT
          </GrayButton>
          <GrayButton
            size="small"
            disabled={!hasNextPage}
            onClick={() => goToPage(lastPage)}
          >
            {'>>'}
          </GrayButton>
        </div>
      </div>
    </div>
  );
}
