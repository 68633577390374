export function validateLabel(label: string) {
  const labelRegex = /^[a-zA-Z0-9\s-_.,]{2,127}$/;

  if (!labelRegex.test(label)) {
    throw new Error(
      "Text must only contain letters (a-z, A-Z), spaces, numbers (0-9), and the special characters '-', ',' and '/'. It must be between 2 and 127 characters long."
    );
  }

  return true;
}
