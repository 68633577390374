import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/DWalletService';
import {
  blockchainInfoKeys,
  BlockchainInfoService,
} from 'api/services/BlockchainInfoService';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import { duration } from 'moment';
import { formatTokenAmount } from 'utils/FormatNumber';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import Big from 'big.js';
import { fromWei, gweiToEther, weiToGwei } from 'modules/ethereum/utils';
import { chainAsset, chainName, Chains } from 'api/d-wallets';
import { FormValues } from './schema';
import Button from 'modules/shared-components/button/button';
import { chainsOptions } from '../WithdrawModal/SendFundsSettings';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import NumberInput from '../../components/inputs/number-input';
import { SortDropdown } from '../../components/dropdown/sort-dropdown';

const BridgeFundsContent = () => {
  const { control, watch, setValue, trigger } = useFormContext<FormValues>();

  const inputValues = watch();
  const address = inputValues.address;
  const srcChain = inputValues.srcChain;
  const dstChain = inputValues.dstChain;

  const { isLoading: myWalletIsLoading, data: myWallet } = useQuery({
    queryFn: () => DWalletService.getWalletWithAddress(srcChain, address),
    queryKey: dWalletServiceKeys.getWalletWithAddress(srcChain, address),
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data) {
        setValue('maxBalance', Big(data.chainBalance).toString());
      }
    },
  });

  const { data: bridgeEstimation, error: bridgeEstimationError } = useQuery({
    queryFn: () => DWalletService.estimateBridge(inputValues),
    queryKey: dWalletServiceKeys.estimateBridge(inputValues),
    enabled: inputValues.srcChainTokenAmount > 0,
  });

  const { data: gasInfo } = useQuery({
    queryKey: blockchainInfoKeys.getGasInfoWithChain(srcChain),
    queryFn: () => BlockchainInfoService.getGasInfoWithChain(srcChain),
    refetchInterval: 1000 * 15,
    enabled: !!srcChain,
    staleTime: 1000 * 15,
    cacheTime: 1000 * 15,
    keepPreviousData: true,
  });

  const { data: prices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    refetchInterval: duration('10', 'seconds').asMilliseconds(),
    keepPreviousData: true,
  });

  return (
    <div className="xxs:space-y-4">
      <p className="xxs:mb-4 xxs:text-dex-white-secondary">
        Bridge your funds to another chain.
      </p>
      <div className="xxs:text-white-secondary xxs:bg-dex-black-700 rounded xxs:p-2">
        <div className="flex items-center w-full">
          <div className="space-y-1 w-full">
            {myWallet && (
              <div className="flex space-x-1 items-center">
                <div className="max-w-[330px] truncate">
                  <span className="font-bold">Wallet:</span> {myWallet.label}
                </div>
                <ChainsIcon imgName={srcChain} />
                <div>|</div>
                {myWalletIsLoading ? (
                  <div className="font-bold">--</div>
                ) : (
                  <div className="font-bold">
                    {formatTokenAmount(myWallet.chainBalance)}{' '}
                    {chainAsset(srcChain)}
                  </div>
                )}
              </div>
            )}
            <div>
              Address:{' '}
              <EthAddress
                address={address || ''}
                kind="account"
                chain={srcChain as Chains}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-2 items-center">
        <div className="w-2/5">
          <Controller
            name="srcChain"
            control={control}
            render={({ field }) => (
              <SortDropdown
                mainLabel="From Chain"
                {...field}
                options={chainsOptions}
                onChange={(option) => {
                  if (option === dstChain) {
                    setValue('dstChain', srcChain);
                  }
                  field.onChange(option);
                }}
              />
            )}
          />
        </div>

        <div className="flex justify-center items-center w-1/5 ">
          <Button
            type="button"
            onClick={() => {
              setValue('srcChain', dstChain);
              setValue('dstChain', srcChain);
            }}
          >
            <ArrowsRightLeftIcon className="h-5 w-5" />
          </Button>
        </div>

        <div className="w-2/5">
          <Controller
            name="dstChain"
            control={control}
            render={({ field }) => (
              <SortDropdown
                mainLabel="To Chain"
                {...field}
                options={chainsOptions}
                onChange={(option) => {
                  if (option === srcChain) {
                    setValue('srcChain', dstChain);
                  }
                  field.onChange(option);
                }}
              />
            )}
          />
        </div>
      </div>

      <Controller
        control={control}
        name="srcChainTokenAmount"
        render={({ field, fieldState: { error: errore } }) => (
          <NumberInput
            label={'Amount'}
            type="text"
            {...field}
            onChange={(e) => {
              setValue(
                'srcChainTokenAmount',
                e?.currentTarget?.value as unknown as number
              );
              trigger()
                .then(() => {})
                .catch((er) => {
                  console.error('Error', er);
                });
            }}
            extraLabel={
              <Button
                type="button"
                onClick={() => {
                  setValue(
                    'srcChainTokenAmount',
                    Math.floor(
                      parseFloat(Big(myWallet?.chainBalance ?? 0).toString()) *
                        1e18
                    ) / 1e18
                  );
                  trigger()
                    .then(() => {})
                    .catch((e) => {
                      console.error('Error', e);
                    });
                }}
                className="m-0 text-dex-white-secondary hover:text-dex-white p-0 normal-case bg-transparent"
              >
                Max
              </Button>
            }
            suffix={
              <span className="max-w-[100px] truncate">
                {chainAsset(srcChain)}
              </span>
            }
            error={errore?.message}
          />
        )}
      />

      {bridgeEstimation && (
        <div className="text-xs">
          You'll receive{' '}
          {formatTokenAmount(
            fromWei(Big(bridgeEstimation.dstAmount || 0).toNumber())
          )}{' '}
          {chainAsset(dstChain)} on {chainName(dstChain)}
        </div>
      )}

      {bridgeEstimationError ? (
        <div className="text-red-500">
          This transaction will most likely fail. Review your settings.
        </div>
      ) : (
        gasInfo &&
        prices &&
        bridgeEstimation && (
          <div className="dark:bg-dex-black-600 xxs:px-2 xxs:py-1 ml-auto lg:mt-auto flex space-x-1">
            <div>Estimated Gas Fee:</div>
            <div className="xxs:font-semibold">
              {Big(
                weiToGwei(
                  Big(bridgeEstimation.gasAmount ?? 0).plus(
                    bridgeEstimation.feeAmount ?? 0
                  ) ?? 0
                )
              ).toFixed(1)}{' '}
              gwei ($
              {Big(
                gweiToEther(
                  weiToGwei(
                    Big(bridgeEstimation.gasAmount ?? 0).plus(
                      bridgeEstimation.feeAmount ?? 0
                    ) ?? 0
                  )
                )
              )
                .times(
                  /*chain === Chains.BinanceChain
                                                                                                              ? prices?.bnb_price
                                                                                                              : */
                  prices?.eth_price ?? 0
                )
                .toFixed(2)}
              )
            </div>
          </div>
        )
      )}
    </div>
  );
};
export default BridgeFundsContent;
