/* eslint-disable @typescript-eslint/no-unused-vars */
import { ResponsiveTableColumn } from 'modules/shared-components/data-display/table/types';
import { useState } from 'react';
import {
  DexTradeStatus,
  DexTransaction,
  OpenPositionListItem,
  SnipePositionStatus,
} from 'api/dex-trade';
import { fromWei } from 'modules/ethereum/utils';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import { CancelPosition } from './CancelPosition';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { twMerge } from 'tailwind-merge';
import {
  ArrowPathRoundedSquareIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  MinusCircleIcon,
  PencilIcon,
  PlayCircleIcon,
  PlusCircleIcon,
  ShareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useToggle } from 'modules/utils/useToggle';
import { ShareTradeModal } from '../components/ShareTrade/ShareTradeModal';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { ExchangeIconStretch } from 'modules/shared-components/exchange/exchange-icon';
import { formatHoldingTime } from 'modules/dTrade/utils';
import Big from 'big.js';
import { ClosePosition } from './ClosePosition';

import { AddFunds } from './AddFunds';
import { COLORS_DICTIONARY } from 'modules/shared-components/colorsDictionary';
import { ReactivatePosition } from './ReactivatePosition';
import { MergePositions } from './MergePositions';
import { useHistory } from 'react-router-dom';
import {
  ResponsiveMenu,
  ResponsiveMenuItem,
} from '../components/dropdown/menu/ResponsiveMenu';
import Tag from '../components/alerts/Tags';

export const columns: ResponsiveTableColumn<OpenPositionListItem>[] = [
  {
    label: <>Pair</>,
    key: 'PAIR',
    component: ({ data }) =>
      !data.protocol ? (
        <ActiveSnipePair data={data} />
      ) : (
        <ExecutedSnipePair data={data} />
      ),
  },
  {
    label: <>Performance</>,
    key: 'Performance',
    component: ({ data }) => <SnipePerformance data={data} />,
    showOnMobile: false,
  },
  {
    label: <>PnL</>,
    key: 'PnL',
    component: ({ data }) => <Pnl data={data} />,
    showOnMobile: true,
  },
  {
    label: <>Price</>,
    key: 'Price',
    component: ({ data }) => {
      return (
        <>
          {data.averageBuyPriceInUsd && (
            <div>B: ${formatNumberWithSuffix(data.averageBuyPriceInUsd)}</div>
          )}
          {data.averageSellPriceInUsd ? (
            <div>S: ${formatNumberWithSuffix(data.averageSellPriceInUsd)}</div>
          ) : (
            data.currentTokenPriceInUsd && (
              <div>
                C: ${formatNumberWithSuffix(data.currentTokenPriceInUsd)}
              </div>
            )
          )}

          {!data.averageBuyPriceInUsd && !data.currentTokenPriceInUsd && (
            <div>--</div>
          )}
        </>
      );
    },
    showOnMobile: true,
  },
  {
    label: <>Holding Time</>,
    key: 'Holding Time',
    component: ({ data }) => <div>{formatHoldingTime(data)}</div>,
    showOnMobile: true,
  },
  {
    label: <></>,
    key: 'action',
    component: ({ data }) => <ActionsMenu position={data} />,
  },
];

function ActiveSnipePair({ data }: { data: OpenPositionListItem }) {
  return (
    <div className="flex flex-col xxs:gap-2">
      <div className="flex items-center xxs:gap-2">
        <span>
          {data.tokenSymbol}
          {data.quoteAsset ? `/${data.quoteAsset.symbol}` : ''}
        </span>{' '}
        <SnipeStatus position={data} />
      </div>
      <div className="flex items-center xxs:gap-2 dark:text-black-100">
        <EthAddress address={data.tokenAddress} precision={2} kind="token" />
      </div>
    </div>
  );
}

function ExecutedSnipePair({ data }: { data: OpenPositionListItem }) {
  const snipeTx = data.transactions.find((x) => x.type.includes('SNIPE_SWAP'));

  return (
    <div className="flex flex-col xxs:gap-2">
      <div className="flex items-center xxs:gap-2">
        <TokenIconWithChain
          chain={data.chain}
          tokenName={data.tokenName}
          symbol={data.tokenSymbol}
          className="w-6 h-6"
          address={data.tokenAddress}
        />
        <span>
          {data.tokenSymbol}/{data.quoteAsset!.symbol}
        </span>
        <SnipeStatus position={data} />
      </div>
      {snipeTx && (
        <div className="flex items-center xxs:gap-2 dark:text-white">
          <ExchangeIconStretch
            imgName={data.protocol!.protocol}
            className="w-6 h-6"
          />
          {snipeTx.transactionHash && (
            <>
              <span>
                {data.protocol!.protocol.includes('2') ? 'v2.0' : 'v3.0'} |{' '}
                <EthAddress
                  address={snipeTx.transactionHash}
                  kind="tx"
                  precision={2}
                />
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
}

function SnipePerformance({ data }: { data: OpenPositionListItem }) {
  return (
    <div className="flex flex-col xxs:gap-0.5 xxs:text-xs uppercase">
      <div>
        Initial: {formatNumberWithSuffix(fromWei(data.buyAmountInWei))} ETH
      </div>
      {data.unrealisedProfitAndLoss?.valueInEth && (
        <div>
          Worth:{' '}
          {formatNumberWithSuffix(data.unrealisedProfitAndLoss.valueInEth)} ETH
        </div>
      )}
      {data.realisedProfitAndLoss?.valueInEth && (
        <div>
          Final: {formatNumberWithSuffix(data.realisedProfitAndLoss.valueInEth)}{' '}
          ETH
        </div>
      )}
    </div>
  );
}

export function SnipeStatus({
  position,
  ...props
}: {
  position: OpenPositionListItem;
} & Partial<React.ComponentProps<typeof Tag>>): JSX.Element {
  const status =
    position.status === DexTradeStatus.sniped &&
    Big(position.totalAmountTokenRemaining || 0).eq(0) &&
    Big(position.totalAmountTokenBought || 0).gt(0)
      ? DexTradeStatus.closedInProgress
      : position.status;

  switch (status) {
    case DexTradeStatus.new:
      return (
        <Tag {...props} type="warning">
          PENDING
        </Tag>
      );
    case DexTradeStatus.snipeInProgress:
      return (
        <Tag {...props} type="success">
          SNIPED <ClockIcon className="w-4 h-4" />
        </Tag>
      );
    case DexTradeStatus.sniped:
      return (
        <Tag {...props} type="success">
          SNIPED
        </Tag>
      );
    case DexTradeStatus.cancelledByUserSettings:
      return (
        <Tag {...props} type="error">
          BLOCKED
        </Tag>
      );
    case DexTradeStatus.cancelled:
      return (
        <Tag {...props} type="warning">
          CANCELLED
        </Tag>
      );
    case DexTradeStatus.cancelledInProgress:
    case DexTradeStatus.cancelledByUserInProgress:
      return (
        <Tag {...props} type="info">
          CANCELLED <ClockIcon className="w-4 h-4" />
        </Tag>
      );

    case DexTradeStatus.cancelledByUser:
      return (
        <Tag {...props} type="warning">
          CANCELLED
        </Tag>
      );

    case DexTradeStatus.failedToSnipe:
      return (
        <Tag {...props} type="error">
          FAILED
        </Tag>
      );

    case DexTradeStatus.closed:
    case DexTradeStatus.closedByUser:
      return (
        <Tag {...props} type="success">
          CLOSED
        </Tag>
      );
    case DexTradeStatus.closedInProgress:
    case DexTradeStatus.closedByUserInProgress:
      return (
        <Tag {...props} type="info">
          CLOSED <ClockIcon className="w-4 h-4" />
        </Tag>
      );
    default:
      return <></>;
  }
}

export function TxStatus({
  transaction,
  ...props
}: {
  transaction: DexTransaction;
} & Partial<React.ComponentProps<typeof Tag>>): JSX.Element {
  switch (transaction.status) {
    case 'NEW':
    case 'SIGNED':
    case 'STUCK_IN_MEMPOOL':
    case 'PENDING':
      return (
        <Tag title={`${transaction.status}`} {...props} type="warning">
          Pending
        </Tag>
      );
    case 'CONFIRMED_SUCCESS_IN_PROGRESS':
      return (
        <Tag {...props} type="success">
          Executed <ClockIcon className="w-4 h-4" />
        </Tag>
      );
    case 'CONFIRMED_SUCCESS':
      return (
        <Tag {...props} type="success">
          Executed
        </Tag>
      );
    case 'CONFIRMED_CANCELLED_IN_PROGRESS':
      return (
        <Tag {...props} type="error">
          Cancelled <ClockIcon className="w-4 h-4" />
        </Tag>
      );
    case 'CONFIRMED_CANCELLED':
      return (
        <Tag {...props} type="error">
          Cancelled
        </Tag>
      );
    case 'CONFIRMED_EXECUTION_REVERTED_IN_PROGRESS':
      return (
        <Tag {...props} type="error">
          Reverted <ClockIcon className="w-4 h-4" />
        </Tag>
      );
    case 'CONFIRMED_EXECUTION_REVERTED':
      return (
        <Tag {...props} type="error">
          Reverted
        </Tag>
      );
    case 'FAILED_TO_BUILD':
    case 'FAILED_TO_SEND':
    case 'FAILED_TO_SNIPE':
      return (
        <Tag
          title={`${transaction.status} - ${transaction.failureReason}`}
          {...props}
          type="error"
        >
          Failed
        </Tag>
      );
    case 'TIMED_OUT':
      return (
        <Tag {...props} type="error">
          Timed out
        </Tag>
      );

    default:
      return <Tag type="info">Unknown</Tag>;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Pnl({ data }: { data: OpenPositionListItem }) {
  return data.totalProfitAndLoss?.valueInEth ? (
    <div
      className={twMerge(
        'xxs:text-lg',
        Big(data.totalProfitAndLoss?.pnlPct).gte(0)
          ? 'xxs:text-green-500'
          : 'xxs:text-red-500'
      )}
    >
      {Big(data.totalProfitAndLoss?.pnlPct).gt(0) ? '+' : ''}
      {Big(data.totalProfitAndLoss?.pnlAmountInEth).toFixed(4)}{' '}
      <span className="xxs:text-xs">{data.quoteAsset?.symbol}</span> (
      {Big(data.totalProfitAndLoss?.pnlPct).gt(0) ? '+' : ''}
      {Big(data.totalProfitAndLoss?.pnlPct)
        .times(100)
        .toFixed(2, Big.roundDown)}
      %)
    </div>
  ) : (
    <div>--</div>
  );
}

export type Action =
  | 'cancel'
  | 'edit'
  | 'close'
  | 'add-funds'
  | 'merge'
  | 'reactivate';
export const ActionsMenu = ({
  position,
  color,
}: {
  position: OpenPositionListItem;
  color?: keyof typeof COLORS_DICTIONARY;
}) => {
  const [activeAction, setActiveAction] = useState<Action>();
  const [isShareOpen, { close, open }] = useToggle();
  const history = useHistory();

  return (
    <div
      className="xxs:flex xxs:gap-2 justify-end z-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <ResponsiveMenu
        color={color ?? 'dexNeutral'}
        label="Actions"
        dropdownPlacement="bottom-end"
      >
        {SnipePositionStatus[position.status]?.canSell && (
          <ResponsiveMenuItem onClick={() => setActiveAction('close')}>
            <MinusCircleIcon className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4" />{' '}
            <span className="lg:text-xs xxs:text-base">Sell</span>
          </ResponsiveMenuItem>
        )}

        {SnipePositionStatus[position.status]?.canBuy && (
          <ResponsiveMenuItem onClick={() => setActiveAction('add-funds')}>
            <PlusCircleIcon className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4" />{' '}
            <span className="lg:text-xs xxs:text-base">Buy More</span>
          </ResponsiveMenuItem>
        )}

        {SnipePositionStatus[position.status]?.canEdit && (
          <ResponsiveMenuItem
            onClick={() =>
              history.push({
                pathname: `/dex/snipe/${position.chain}/${position.id}/edit`,
                state: {
                  sourceUrl: `${history.location.pathname}${history.location.search}`,
                },
              })
            }
          >
            <PencilIcon className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4" />{' '}
            <span className="lg:text-xs xxs:text-base">Edit</span>
          </ResponsiveMenuItem>
        )}

        {SnipePositionStatus[position.status]?.canCancel && (
          <ResponsiveMenuItem onClick={() => setActiveAction('cancel')}>
            <TrashIcon className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4" />{' '}
            <span className="lg:text-xs xxs:text-base">Cancel</span>
          </ResponsiveMenuItem>
        )}

        {SnipePositionStatus[position.status]?.canReactivate && (
          <ResponsiveMenuItem onClick={() => setActiveAction('reactivate')}>
            <PlayCircleIcon className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4" />{' '}
            <span className="lg:text-xs xxs:text-base">Reactivate</span>
          </ResponsiveMenuItem>
        )}
        {SnipePositionStatus[position.status]?.canMerge && (
          <ResponsiveMenuItem onClick={() => setActiveAction('merge')}>
            <ArrowPathRoundedSquareIcon className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4" />{' '}
            <span className="lg:text-xs xxs:text-base">Merge</span>
          </ResponsiveMenuItem>
        )}

        {SnipePositionStatus[position.status]?.canDuplicate && (
          <ResponsiveMenuItem
            onClick={() =>
              history.push({
                pathname: `/dex/snipe/${position.chain}/${position.id}/copy`,
                state: {
                  sourceUrl: `${history.location.pathname}${history.location.search}`,
                },
              })
            }
          >
            <DocumentDuplicateIcon className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4" />{' '}
            <span className="lg:text-xs xxs:text-base">Duplicate</span>
          </ResponsiveMenuItem>
        )}

        {SnipePositionStatus[position.status]?.canShare && (
          <ResponsiveMenuItem onClick={open}>
            <ShareIcon className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4" />{' '}
            <span className="lg:text-xs xxs:text-base">Share</span>
          </ResponsiveMenuItem>
        )}
      </ResponsiveMenu>
      {activeAction === 'cancel' && (
        <CancelPosition
          positionId={position.id}
          onCancel={() => setActiveAction(undefined)}
        />
      )}
      {activeAction === 'add-funds' && (
        <AddFunds
          position={position}
          onCancel={() => setActiveAction(undefined)}
        />
      )}
      {activeAction === 'close' && (
        <ClosePosition
          position={position}
          onCancel={() => setActiveAction(undefined)}
        />
      )}
      {activeAction === 'reactivate' && (
        <ReactivatePosition
          positionId={position.id}
          onCancel={() => setActiveAction(undefined)}
        />
      )}

      {activeAction === 'merge' && (
        <MergePositions
          position={position}
          onCancel={() => setActiveAction(undefined)}
        />
      )}
      {isShareOpen && <ShareTradeModal position={position} onClose={close} />}
    </div>
  );
};
