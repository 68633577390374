import type { Combobox, Listbox } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export const Options = React.forwardRef<
  HTMLUListElement,
  {
    as: (typeof Listbox)['Options'] | (typeof Combobox)['Options'];
  } & React.ComponentProps<'ul'>
>(({ as, ...rest }, ref) => {
  const Component = as;

  return (
    <Component
      {...rest}
      ref={ref}
      style={{
        minWidth: 182,
        ...rest.style,
      }}
      className={classNames(
        'border-tailwind xxs:border-solid xxs:border-dex-black-600 list-unstyled xxs:bg-dex-black-700 max-h-56 rounded-md xxs:pb-2 text-base ring-1 ring-black ring-opacity-5 overflow-y-auto overflow-x-hidden focus:outline-none xxs:text-sm xxs:w-full md:w-auto xxs:z-50',
        rest.className
      )}
    />
  );
});

export type DropdownOptionItem<T> = {
  value: T;
  label: string;
  icon?: React.ReactNode;
  size?: string;
};

export function DropdownOption<T extends string | number | boolean>({
  option,
  index,
  as,
  size,
}: {
  option: DropdownOptionItem<T>;
  index: number;
  as: (typeof Listbox)['Option'] | (typeof Combobox)['Option'];
  size?: string;
}) {
  const Component = as;

  return (
    <Component
      value={option.value}
      className={({ active }) =>
        classNames(
          'relative select-none cursor-pointer xxs:py-2 xxs:pl-3 xxs:pr-10 xxs:min-w-max',
          active ? 'xxs:bg-dex-black-600' : 'xxs:text-dex-white',
          index === 0 && 'xxs:mt-2',
          size ? 'text-xs' : ''
        )
      }
    >
      {({ active, selected }) => (
        <div
          className={
            active ? 'xxs:text-dex-white' : 'xxs:text-dex-white-secondary'
          }
        >
          <div className="flex items-center">
            {option.icon}
            <span
              className={classNames(
                'xxs:ml-3 max-w-[150px] truncate',
                selected && 'font-semibold'
              )}
            >
              {option.label}
            </span>
          </div>
          {selected && (
            <span
              className={classNames(
                'absolute inset-y-0 right-0 flex items-center xxs:pr-4'
              )}
            >
              <CheckIcon className="h-4 w-4" aria-hidden="true" />
            </span>
          )}
        </div>
      )}
    </Component>
  );
}

export function DropdownButton({
  children,
  as,
  className,
  disabled,
  ...props
}: {
  children: React.ReactNode;
  as: (typeof Listbox)['Button'] | (typeof Combobox)['Button'] | string;
  className?: string;
  disabled?: boolean;
} & React.ComponentPropsWithoutRef<'button'>) {
  const Component = as;

  return (
    <Component
      {...props}
      className={twMerge(
        'xxs:bg-dex-black-700 xxs:px-3 h-10 xxs:rounded-md xxs:flex xxs:items-center xxs:gap-1 xxs:font-normal xxs:w-full xxs:text-left xxs:text-white xxs:text-base',
        className,
        disabled ? 'xxs:text-black-200 cursor-not-allowed' : ''
      )}
    >
      {children}

      <ChevronDownIcon
        className={twMerge(
          'xxs:h-4 xxs:w-4',
          disabled ? 'xxs:text-dex-white-dark' : 'xxs:text-dex-white-secondary'
        )}
        aria-hidden="true"
      />
    </Component>
  );
}

export * from './use-dropdown-position';
