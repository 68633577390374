import { PaginatedList } from './common';
import { Chains } from 'api/d-wallets';

export type AlphaCallsResponse = PaginatedList<AlphaCallsRow>;
export type HistoryResponse = PaginatedList<LeaderboardRow>;

export type LeaderboardRow = {
  season: number;
  year: number;
  calendarWeek: number;
  alphaCalled: number;
  realizedPnlQuote: string;
  realizedPnlUSD: string;
  prizeInQuote: string;
  prizeInUSD: string;
  gold: UserDetails;
  silver: UserDetails;
  bronze: UserDetails;
};

export type LeaderboardEntry = {
  realizedPnlQuote: string;
  realizedPnlUSD: string;
  totalCallers: number;
  numberOfUniqueCalls: number;
  usersResults: UserResults[];
};

export type UserResults = {
  position: 1 | 2 | 3 | 4 | 5;
  user: UserDetails;
  realizedPnlQuote: string;
  realizedPnlUSD: string;
  prizeInQuote: string | null;
  prizeInUSD: string | null;
  numberOfAlpha: number | null;
  realizedPnlPerAlpha: number | null;
};

export enum AlphaRoles {
  AlphaMaster = 'Alpha Master',
  AlphaInternationalMaster = 'Alpha International Master',
  AlphaGrandMaster = 'Alpha Grand Master',
}

export type AlphaLeaderboardParams = {
  chain: Chains;
  season: string;
  isTopTen?: boolean;
};

export type AlphaHistoryParams = {
  chain: Chains;
  limit: number;
  offset: number;
};

export interface PrizeLevel {
  level: number; // The level of the prize
  minPnlQuote: number; // The maximum PnL in ETH for this level
  prize: number; // Total prize amount
  first: number;
  second: number;
  third: number;
  fourth: number | null; // Prize for the fourth place (nullable)
  fifth: number | null; // Prize for the fifth place (nullable)
}

export type AlphaCallsParams = {
  chain: Chains;
  limit?: number;
  offset?: number;
  address?: string;
  userName?: string;
  season: string;
  isMaster?: boolean;
  isTopTen?: boolean;
};
export type AlphaCallsRow = {
  address: string;
  chain: Chains;
  symbol: string;
  season: number;
  callTime: string;
  user: UserDetails;
  comment: string;
  messageLink: string;
  nOfLikes: number;
  hasUserLiked: boolean;
  hotTokenStats: HotTokensStats;
  twentyfourHoursVolume: string;
  oneHourVolume: string;
  price: string;
  priceChange: string;
  liquidity: string;
  marketcap: string;
  poolCreatedAt: string;
  status: string;
  pnlInQuote: string;
  pnlInUSD: string;
};

export type UserDetails = {
  img: string;
  userName: string;
  userId: string;
  isAlphaMaster: boolean;
  isAlphaInternationalMaster: boolean;
  isAlphaGrandMaster: boolean;
};

type HotTokensStats = {
  suspiciousBuy: number;
  rugAddictedBuy: number;
  scammerBuy: number;
  freshBuy: number;
  totalBuyTop: number;
  totalSellsTop: number;
  totalBuy: number;
  buyVolumeTop: string;
  buyVolume: string;
  totalSells: number;
  sellVolume: string;
  sellVolumeTop: string;
  netInflowTop: string;
  netInflow: string;
  volatilityIndex: string;
};
