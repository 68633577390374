// Define the ApiError interface with the correct structure
export interface ApiError extends Error {
  errors?: FieldError[]; // errors is optional as not all errors might have this field
}

type FieldError = {
  loc: string[];
  msg: string;
};

// A function to format the structured error message
function formatStructuredError(error: ApiError): string {
  // Safely access `error.errors`, using a type guard to ensure `errors` exists
  if (error.errors) {
    return error.errors
      .map((e: FieldError) => (typeof e === 'string' ? e : e.msg))
      .join(', ');
  }
  return '';
}

// Check if an object is an ApiError
function isApiError(error: unknown): error is ApiError {
  return typeof error === 'object' && error !== null && 'message' in error;
}

// Format the API error into a string, including a fallback message
export function formatApiError(error: unknown, fallbackMsg?: string): string {
  if (isApiError(error)) {
    return error.message + ': ' + formatStructuredError(error);
  }

  if (typeof error === 'string') {
    return error;
  }

  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof (error as { message: string }).message === 'string'
  ) {
    return (error as { message: string }).message;
  }

  if (fallbackMsg) {
    return fallbackMsg;
  }

  return 'Something went wrong.';
}

// Standardize errors by checking if they are transport layer errors
export const standarizeError = (error: ApiError): FieldError[] => {
  try {
    const isTransportLayerError =
      typeof error === 'string' ||
      error instanceof String ||
      error === undefined;

    // Ensure that the `msg` field is always a string
    const errorsList = isTransportLayerError
      ? [{ loc: ['body', '__root__'], msg: String(error) }] // Explicitly convert to string
      : error.errors?.map((e: FieldError) => ({
          loc: e.loc ?? ['body', '__root__'], // Ensure location is defined
          msg: String(e.msg), // Ensure msg is a string
        })) || []; // Fallback to an empty array if `error.errors` is undefined

    return errorsList;
  } catch (e: any) {
    return [{ loc: ['body', '__root__'], msg: e.message }];
  }
};
