import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = React.ComponentProps<'div'>;

export const LoaderDex = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={twMerge(
          'spinner-border xxs:text-dex-white-secondary xxs:w-4 xxs:h-4',
          props.className
        )}
        role="status"
      />
    );
  }
);
