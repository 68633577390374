import {
  TVChartContainer,
  useTvChart,
} from 'modules/tw-chart/TVChartContainer';
import {
  createOptions,
  useCoingeckoLogo,
  useDexTvChartIntervalPersistedState,
} from '../../components/Chart/DexChart';
import { ContractInfo } from 'api/contracts';
import { ResolutionString } from 'modules/tw-chart/charting_library';
import { DexOrderStatus, OpenPositionListItem } from 'api/dex-trade';
import Big from 'big.js';
import { useEffect } from 'react';
import { useDrawEntries } from './useDrawEntries';

type Props = {
  contract: ContractInfo;
  position: OpenPositionListItem;
  poolAddress: string | undefined;
};

function minValuePeriod(minTime: number) {
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - minTime;

  let timeframe: string;
  if (timeDiff < 30 * 24 * 60 * 60 * 1000) {
    const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
    timeframe = `${daysDiff}D`;
  } else {
    const monthsDiff = Math.floor(timeDiff / (30 * 24 * 60 * 60 * 1000));
    timeframe = `${monthsDiff}M`;
  }

  return timeframe;
}

export function DexPositionChart({ contract, position, poolAddress }: Props) {
  const [chart, onChange] = useTvChart();

  const [dexTvChartInterval, setDexTvChartInterval] =
    useDexTvChartIntervalPersistedState('15' as unknown as ResolutionString);

  useEffect(() => {
    if (chart) {
      const minTime = Math.min(
        ...position.transactions
          .filter(
            (tx) =>
              tx.status === DexOrderStatus.confirmedSuccess && tx.blockDatetime
          )
          .map((tx) =>
            new Date(
              new Date(tx.blockDatetime as string).getTime() -
                24 * 60 * 60 * 1000
            ).getTime()
          )
      );

      const interval = setInterval(() => {
        if (chart.chart) {
          chart.chart().setResolution(dexTvChartInterval);
          if (minTime) {
            chart.chart().setTimeFrame({
              val: { type: 'period-back', value: minValuePeriod(minTime) },
              res: dexTvChartInterval,
            });
          }

          clearInterval(interval);

          chart
            .chart()
            .onIntervalChanged()
            .subscribe(null, (newInterval) => {
              setDexTvChartInterval(newInterval);
            });
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, [chart]);

  useCoingeckoLogo(chart, position.chain, poolAddress ?? position.poolAddress);
  useDrawEntries(chart, position);

  return (
    <TVChartContainer
      className="xxs:h-[300px] md:h-[400px] xxs:rounded-lg"
      optionsFactory={(theme, defaults) =>
        createOptions(
          theme,
          {
            ...defaults,
            interval: dexTvChartInterval as ResolutionString,
          },
          position.averageBuyPriceInUsd
            ? Big(position.averageBuyPriceInUsd).toNumber()
            : contract.liquidityInfo.activePools[0]?.priceDollar || undefined,
          contract.generalInfo.chain.key
        )
      }
      onWidgetChange={onChange}
      symbol={`${contract.generalInfo.symbol || 'Token'}:${
        contract.generalInfo.address
      }:${poolAddress ?? position.poolAddress}`}
    />
  );
}
