import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import {
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/DWalletService';
import { FormValues } from '../schema';
import { formatTokenAmount } from 'utils/FormatNumber';
import { Link } from 'react-router-dom';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

import Button from 'modules/shared-components/button/button';
import { chainAsset, chainAssetImg } from 'api/d-wallets';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { VolatilityBotFormValues } from '../../volatility/schema';
import { DexCard } from '../../../components/cards';
import { SortDropdown } from '../../../components/dropdown/sort-dropdown';
import { NotificationDex } from '../../../components/alerts/notification';
import { MobileOption } from 'modules/shared-components/forms/select/select-option';

export function SelectDWallet() {
  type CombinedFormValues = VolatilityBotFormValues | FormValues;
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CombinedFormValues>();
  const chain = watch('tradingSettings.chain');
  const { isLoading, data, error } = useQuery({
    queryFn: () => MultiChainDWalletService.getWallets(chain),
    queryKey: multiChainDWalletServiceKeys.getWallets(chain),
    enabled: !!chain,
    keepPreviousData: true,
  });

  const isError =
    errors?.tradingSettings && 'walletId' in errors.tradingSettings;

  const selectedWallet = watch('tradingSettings.walletId')
    ? data?.find((wallet) => wallet.id === watch('tradingSettings.walletId'))
    : null;

  return (
    <MobileExpandableCard
      mobileLabel="Select Wallet"
      trigger={
        <MobileExpandCardTrigger>
          {error ? (
            <div className="text-red-500">Error Loading Wallets</div>
          ) : data?.length == 0 ? (
            <div className="space-y-1 text-sm">
              {!isError ? (
                <div className="flex space-x-1 items-center text-sm xxs:text-dex-white-secondary">
                  <div>Create a wallet first</div>
                </div>
              ) : (
                <div className="flex space-x-1 items-center text-sm xxs:text-red-500 ">
                  <ExclamationCircleIcon className="xxs:w-6 h-6" />
                  <div>Create a wallet first</div>
                </div>
              )}{' '}
            </div>
          ) : isError ? (
            <div className="flex space-x-1 items-center text-sm xxs:text-red-500">
              <ExclamationCircleIcon className=" xxs:w-6 h-6" />
              <div>Missing Information</div>
            </div>
          ) : (
            <div className="flex space-x-1 items-center text-sm xxs:text-dex-white-secondary">
              <div>Wallet:</div>
              {selectedWallet ? (
                <div className="items-center flex space-x-1 xxs:text-dex-white">
                  <img
                    src={chainAssetImg(chain)}
                    width={10}
                    alt="chain asset logo"
                  />
                  <div className="">
                    {formatTokenAmount(selectedWallet.chainBalance)}
                  </div>
                  <div className="truncate max-w-[140px] xxs:text-dex-white-secondary">
                    {' '}
                    | {selectedWallet.label}
                  </div>
                </div>
              ) : (
                <div className="xxs:text-dex-white-secondary">--</div>
              )}
            </div>
          )}
        </MobileExpandCardTrigger>
      }
    >
      <div className="lg:hidden">
        {error ? (
          <div className="text-red-500">Error Loading Wallets</div>
        ) : data?.length === 0 ? (
          <div className="flex">
            <Button
              className="w-full m-2"
              variant="primary"
              as={Link}
              to="/dex/wallets"
            >
              CREATE WALLET
            </Button>
          </div>
        ) : (
          (data || []).map((option) => (
            <MobileOption
              key={option.id}
              value={option.id}
              label={
                <div className="flex items-center justify-between w-full overflow-hidden xxs:gap-8">
                  <div className="flex flex-col w-[200px]">
                    <div className="xxs:text-base truncate xxs:text-dex-white-secondary">
                      {option.label}
                    </div>
                    <div className="xxs:text-xs">
                      <EthAddress address={option.address} chain={chain} />
                    </div>
                  </div>
                  <div className="mr-auto xxs:text-base truncate xxs:font-semibold flex space-x-2 items-center">
                    <img
                      src={chainAssetImg(chain)}
                      width={10}
                      alt="Token logo"
                    />
                    <div className="xxs:text-dex-white">
                      {formatTokenAmount(option.chainBalance)}
                    </div>
                  </div>
                  {selectedWallet?.id === option.id && (
                    <CheckIcon className="h-5 w-5 xxs:text-dex-white" />
                  )}
                </div>
              }
              onClick={() => {
                setValue('tradingSettings.walletId', option.id);
              }}
            />
          ))
        )}
      </div>

      <DexCard size="xs" className="xxs:hidden lg:flex">
        <div className="font-bold text-sm xxs:text-dex-white-secondary">
          Wallet
        </div>
        <Controller
          name="tradingSettings.walletId"
          control={control}
          render={({ field }) => (
            <div className="xxs:space-y-1">
              <SortDropdown
                {...field}
                mainLabel="Select a wallet..."
                isLoading={isLoading}
                options={
                  data?.map((wallet) => ({
                    label: `${wallet.label} - ${formatTokenAmount(
                      wallet.chainBalance
                    )} ${chainAsset(chain)}`,
                    value: wallet.id,
                  })) || []
                }
              />
              {error ||
                (errors?.tradingSettings?.walletId && (
                  <NotificationDex type="error" errorMessage={error}>
                    {errors?.tradingSettings?.walletId
                      ? errors.tradingSettings?.walletId?.message
                      : ''}
                  </NotificationDex>
                ))}
            </div>
          )}
        />
      </DexCard>
    </MobileExpandableCard>
  );
}
