import { api } from 'helpers/api/apiCore';
import { toQueryString } from 'modules/routing/query-string';
import {
  TelegramChannelResponse,
  TelegramCallDetailsResponse,
} from 'api/telegram-channels';
import { Chains } from 'api/d-wallets';

const SERVICE_KEY = 'telegram-channels';

export const TelegramChannelsKeys = {
  all: () => [SERVICE_KEY],
  getChannels: (search: string) => [SERVICE_KEY, 'channels', search],
  getAllChannels: () => [SERVICE_KEY, 'all-channels'],
  getContractDetailedChannels: (chain: Chains, address: string) => [
    SERVICE_KEY,
    'detailed-channels',
    address,
    chain,
  ],
};

export const TelegramChannelsService = {
  async getAllChannels() {
    const response = await api.get<TelegramChannelResponse>(
      `/api/v2/contract-research/channels?search=&limit=1000`
    );

    return response.data;
  },
  async getChannels(params: { search: string; limit: number; offset: number }) {
    const response = await api.get<TelegramChannelResponse>(
      `/api/v2/contract-research/channels?${toQueryString(params)}`
    );

    return response.data;
  },
  async getContractDetailedChannels(params: {
    contractAddress: string;
    chain: Chains;
    limit: number;
    offset: number;
  }) {
    const response = await api.get<TelegramCallDetailsResponse>(
      `/api/v2/contract-research/call-details?${toQueryString(params)}`
    );
    return response.data;
  },
};
