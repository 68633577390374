import React from 'react';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { twMerge } from 'tailwind-merge';
import { chainAsset, Chains } from 'api/d-wallets';

export function SeeAllLink(props: React.ComponentProps<typeof Link>) {
  return (
    <Link
      {...props}
      className="xxs:py-1 xxs:text-xs flex items-center justify-center xxs:gap-1 xxs:text-black dark:hover:text-white xxs:hover:text-black dark:text-white xxs:bg-white-300 hover:bg-white-500 dark:bg-black-600 w-full"
    >
      <MagnifyingGlassIcon className="w-4 h-4" /> See all
    </Link>
  );
}

export function TokenName({
  baseAsset,
  quoteAsset,
}: {
  baseAsset?: string;
  quoteAsset?: string;
}) {
  return (
    <div className="flex">
      {' '}
      <span className="truncate max-w-[60px]">{baseAsset}</span>/
      <span className="xxs:text-xs dark:text-white-700">{quoteAsset}</span>
    </div>
  );
}

export function TokenNameWithChain({
  baseAsset,
  chain,
  className,
}: {
  baseAsset?: string;
  chain?: Chains;
  className?: string;
}) {
  return (
    <div className="flex items-end">
      {' '}
      <span className="truncate max-w-[60px]">{baseAsset}</span>/
      <span
        className={twMerge(
          'xxs:text-xs xxs:text-dex-white-secondary mb-[3px]',
          className
        )}
      >
        W{chainAsset(chain)?.toUpperCase()}
      </span>
    </div>
  );
}

export function PanelButton(
  props: React.ComponentProps<typeof BaseButton> & { isActive?: boolean }
) {
  return (
    <BaseButton
      {...props}
      type="button"
      className={twMerge(
        'xxs:text-sm xxs:px-2 text-center xxs:rounded xxs:bg-white-300 hover:bg-white-500 dark:bg-inherit disabled:text-white-800',
        props.isActive && 'xxs:bg-white-500 dark:bg-black-600',
        props.className
      )}
      onClick={(ev) => {
        if (!props.isActive) {
          props.onClick?.(ev);
        }
      }}
    />
  );
}
