import { Listbox } from '@headlessui/react';
import React from 'react';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { twMerge } from 'tailwind-merge';
import { DropdownOptionItem } from 'modules/shared-components/dropdown';
import { useDropdownPosition } from './use-dropdown-position';
import { DropdownButton, DropdownOption, Options } from './index';
import { ResponsiveMenu, ResponsiveMenuItem } from './menu/ResponsiveMenu';

export type SortDropdownProps<T extends string | number | boolean> = {
  options: DropdownOptionItem<T>[];
  value: T;
  onChange: (value: T) => void;
  icon?: React.ReactNode;
  search?: React.ReactNode;
  mainLabel?: string;
  className?: string;
  classContainer?: string;
  size?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

export function SortDropdown<T extends string | number | boolean>(
  props: SortDropdownProps<T>
) {
  const isDesktop = useMediaQuery(screenGte.small);

  return isDesktop ? (
    <DesktopSortDropdown {...props} />
  ) : (
    <MobileSortDropdown {...props} />
  );
}

export function MobileSortDropdown<T extends string | number | boolean>({
  options,
  value,
  onChange,
  mainLabel,
  className,
  disabled,
  isLoading,
}: SortDropdownProps<T>) {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <div
      className="xxs:flex xxs:gap-2 justify-end z-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <ResponsiveMenu
        trigger={({ open }) => (
          <DropdownButton
            as="div"
            disabled={disabled}
            onClick={!disabled ? open : () => {}}
            className={twMerge(
              'flex w-full justify-between rounded-md xxs:bg-inherit xxs:text-dex-white border-tailwind border-solid xxs:border-dex-black-700 disabled:cursor-not-allowed disabled:text-dex-white-secondary',
              className ?? ''
            )}
          >
            <div className="xxs:flex-grow grow text-base normal-case	">
              {isLoading ? (
                <div
                  className={twMerge(
                    'xxs:text-dex-white-secondary xxs:text-sm'
                  )}
                >
                  Loading...
                </div>
              ) : selectedOption ? (
                <div
                  className={twMerge('flex space-x-1 items-center xxs:text-sm')}
                >
                  {mainLabel ? (
                    ''
                  ) : (
                    <div className="xxs:text-dex-white-secondary xxs:text-sm">
                      Sort by
                    </div>
                  )}{' '}
                  {selectedOption?.icon ? (
                    <div>{selectedOption?.icon}</div>
                  ) : (
                    ''
                  )}
                  <div className="xxs:text-dex-white xxs:text-sm">
                    {selectedOption?.label}
                  </div>
                </div>
              ) : (
                <div
                  className={twMerge(
                    'xxs:text-dex-white-secondary xxs:text-sm'
                  )}
                >
                  {mainLabel ? mainLabel : 'Sort'}
                </div>
              )}
            </div>
          </DropdownButton>
        )}
        label={mainLabel}
      >
        {options.map((x) => (
          <ResponsiveMenuItem key={x.label} onClick={() => onChange(x.value)}>
            {x.label}
          </ResponsiveMenuItem>
        ))}
      </ResponsiveMenu>
    </div>
  );
}

export function DesktopSortDropdown<T extends string | number | boolean>({
  options,
  value,
  onChange,
  mainLabel,
  className,
  classContainer,
  size,
  disabled,
  isLoading,
}: SortDropdownProps<T>) {
  const selectedOption = options.find((option) => option.value === value);
  const { setReferenceElement, setPopperElement, attributes, styles } =
    useDropdownPosition();

  return (
    <Listbox
      disabled={disabled || isLoading}
      ref={setReferenceElement}
      as="div"
      value={value}
      onChange={onChange}
      className={twMerge(
        'relative xxs:flex-shrink-0',
        classContainer ? classContainer : ''
      )}
    >
      <DropdownButton
        disabled={disabled}
        as={Listbox.Button}
        className={twMerge(
          'block w-full rounded-md xxs:bg-inherit xxs:text-dex-white border-tailwind border-solid xxs:border-dex-black-700 disabled:cursor-not-allowed disabled:text-dex-white-secondary',
          className ?? ''
        )}
      >
        <span className="xxs:flex-grow text-sm">
          {isLoading ? (
            <div
              className={twMerge(
                size === 'xs' ? 'text-xs' : '',
                'xxs:text-dex-white-secondary'
              )}
            >
              Loading...
            </div>
          ) : selectedOption ? (
            <div
              className={twMerge(
                'flex space-x-1 items-center',
                size === 'xs' ? 'text-xs' : ''
              )}
            >
              {mainLabel ? (
                ''
              ) : (
                <div className="xxs:text-dex-white-secondary">Sort by</div>
              )}{' '}
              {selectedOption?.icon ? <div>{selectedOption?.icon}</div> : ''}
              <div className="xxs:text-dex-white">{selectedOption?.label}</div>
            </div>
          ) : (
            <div
              className={twMerge(
                size === 'xs' ? 'text-xs' : '',
                'xxs:text-dex-white-secondary'
              )}
            >
              {mainLabel ? mainLabel : 'Sort'}
            </div>
          )}
        </span>
      </DropdownButton>

      <Options
        as={Listbox.Options}
        {...attributes.popper}
        ref={setPopperElement}
        style={styles.popper}
      >
        {options.map((option, index) => (
          <DropdownOption
            size={size}
            as={Listbox.Option}
            key={option.label}
            index={index}
            option={option}
          />
        ))}
      </Options>
    </Listbox>
  );
}
