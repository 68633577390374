import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { TableColumn } from '../types';
import { twMerge } from 'tailwind-merge';

interface HeaderProps {
  columns?: TableColumn<any>[];
  sortBy?: TableColumn<any>['key'] | TableColumn<any>['sortableKey'];
  onClickColumn?: (
    key: TableColumn<any>['key'],
    sortableKey: TableColumn<any>['sortableKey']
  ) => void;
  isSortAscending?: boolean;
  expandable?: boolean;
  className?: string;
  rowClassName?: string;
  trClassName?: string;
  thDynamicClass?: (arg0: string) => string;
}

const Header: FC<HeaderProps> = ({
  columns,
  sortBy,
  onClickColumn,
  isSortAscending,
  children,
  expandable,
  className,
  rowClassName,
  trClassName,
  thDynamicClass,
}) => (
  <thead
    className={twMerge(
      'md:bg-white  xxs:text-sm  md:dark:bg-black-700 items-center',
      className
    )}
  >
    <tr className={trClassName}>
      {expandable && <th />}
      {columns?.map((column) => {
        if (!column.label) {
          return <></>;
        } else
          return (
            <th
              scope="col"
              key={column.key}
              className={twMerge(
                'xxs:px-2 py-2 text-left xxs:text-black-100 truncate select-none',
                rowClassName,
                column.className,
                thDynamicClass ? thDynamicClass(column.key) : ''
              )}
            >
              {(() => {
                if (!column.sortableKey) {
                  return column.label;
                }
                if (column.key === sortBy || column.sortableKey === sortBy) {
                  return (
                    <a
                      onClick={() =>
                        onClickColumn?.(column.key, column.sortableKey)
                      }
                      className="group inline-flex items-center cursor-pointer"
                    >
                      {column.label}
                      <span className="xxs:ml-1 flex-none rounded dark:text-white-100">
                        {isSortAscending ? (
                          <ChevronUpIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </a>
                  );
                }
                return (
                  <a
                    onClick={() =>
                      onClickColumn?.(column.key, column.sortableKey)
                    }
                    className="group inline-flex cursor-pointer"
                  >
                    {column.label}
                    <span className="xxs:ml-1 flex-none rounded text-opacity-0 text-gray-400 group-hover:text-opacity-100">
                      <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  </a>
                );
              })()}
            </th>
          );
      })}
      {children}
    </tr>
  </thead>
);

export default Header;
