import { useToggle } from 'modules/utils/useToggle';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { VolumeFilterModal } from './VolumeFilterModal';
import { McFilterModal } from './McFilterModal';
import { LiquidityFilterModal } from './LiquidityFilterModal';
import { useHotTokensSearchFilters } from './usePageParams';
import { FilterButton } from '../../../components/filters/filterButton';

export function McRangeFilter() {
  const [isOpen, { open, close }] = useToggle();
  const { filtersState } = useHotTokensSearchFilters();

  return (
    <>
      <FilterButton label="MC Range" onClick={() => open()}>
        {!!(filtersState.minMc || filtersState.maxMc) && (
          <FunnelIcon className="w-3 h-3 xxs:text-dex-white" />
        )}
      </FilterButton>
      {isOpen && <McFilterModal handleClose={close} />}
    </>
  );
}

export function LiquidityFilter() {
  const [isOpen, { open, close }] = useToggle();
  const { filtersState } = useHotTokensSearchFilters();
  return (
    <>
      <FilterButton label="Liquidity Range" onClick={() => open()}>
        {!!(filtersState.minLiquidity || filtersState.maxLiquidity) && (
          <FunnelIcon className="w-3 h-3 xxs:text-dex-white" />
        )}
      </FilterButton>
      {isOpen && <LiquidityFilterModal handleClose={close} />}
    </>
  );
}

export function VolumeRangeFilter() {
  const [isOpen, { open, close }] = useToggle();
  const { filtersState } = useHotTokensSearchFilters();
  return (
    <>
      <FilterButton label="Volume Range" onClick={() => open()}>
        {!!(filtersState.minVolume || filtersState.maxVolume) && (
          <FunnelIcon className="w-3 h-3 xxs:text-dex-white" />
        )}
      </FilterButton>
      {isOpen && (
        <VolumeFilterModal period={filtersState.period} handleClose={close} />
      )}
    </>
  );
}
