import { Chains } from 'api/d-wallets';
import Header from 'modules/shared-components/data-display/table/header';
import Table from 'modules/shared-components/data-display/table';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { desktopColumns } from './columns';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  LeaderboardService,
  leaderboardServiceKeys,
} from 'api/services/LeaderboardService';
import {
  EmptyPageDex,
  ErrorPageDex,
} from '../../../research/components/shared/Common';
import { duration } from 'moment';
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import ErrorBoundary from 'components/error-boundaries';

function History({ chain }: { chain: Chains }) {
  const isLargeScreen = useMediaQuery(screenGte.large);
  const columns = desktopColumns(chain);
  const listRef = useRef<HTMLTableElement>(null);
  const params = { chain };

  const {
    data,
    error,
    isInitialLoading: isLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryFn: ({ pageParam = { limit: 20, offset: 0 } }) => {
      return LeaderboardService.getHistory({
        ...params,
        limit: pageParam.limit,
        offset: pageParam.offset,
      });
    },
    queryKey: leaderboardServiceKeys.getHistory(params),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => ({
      offset: lastPage.pagination.offset + lastPage.pagination.limit,
      limit: 10,
    }),
    refetchInterval: duration(120, 'seconds').asMilliseconds(),
  });

  const items = data?.pages?.flatMap((page) => page.data) || [];
  const dataLength = items.length;
  const pagination = data?.pages?.[0]?.pagination;

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        void fetchNextPage();
      }
    },
  });

  if (error) {
    return <ErrorPageDex />;
  }

  return (
    <div className="overflow-x-auto">
      <div className="w-full lg:min-w-[1300px] ">
        {!isLargeScreen && <div ref={listRef} />}
        <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
          <Header
            trClassName={`items-center lg:grid grid-cols-10  lg:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700`}
            columns={columns}
            rowClassName="xxs:p-1"
            className="lg:dark:bg-dex-black-800 xxs:text-xs lg:grid"
          />
          <tbody className="lg:lg:block lg:h-terminal overflow-y-auto">
            {isLoading ? (
              [...Array(10).keys()]
                .map((i) => i + 1)
                .map((index) => (
                  <Table.LoadingRow
                    key={index}
                    elementClassName="xxs:dark:bg-dex-black-700"
                    className={`lg:grid xxs:grid-cols-10 lg:dark:bg-dex-black-800`}
                    columns={columns}
                  />
                ))
            ) : items?.length === 0 ? (
              <EmptyPageDex
                height={isLargeScreen ? '100px' : '50vh'}
                title="No History Found"
              />
            ) : (
              <>
                {items?.map(
                  (item, index) =>
                    pagination && (
                      <tr
                        key={index}
                        className={`border-t border-dex-black-700 lg:dark:bg-dex-black-800  items-center  lg:grid xxs:grid-cols-10 `}
                      >
                        <ErrorBoundary>
                          {columns.map((column) => {
                            const Column = column.component;
                            if (!Column) return null;
                            return (
                              <Table.Td key={column.key}>
                                <Column data={item} />
                              </Table.Td>
                            );
                          })}
                        </ErrorBoundary>
                      </tr>
                    )
                )}
                {pagination && pagination.total > dataLength && (
                  <Table.LoadingRow
                    ref={ref}
                    elementClassName="xxs:dark:bg-dex-black-700"
                    className={`lg:grid lg:grid-cols-10  lg:dark:bg-dex-black-800`}
                    columns={columns}
                  />
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default History;
