// import { MainButton } from '@vkruglikov/react-telegram-web-app';
import config from 'config';
import Button from './button';
import { MainButton } from 'modules/telegram/MainButton';
import { useRef } from 'react';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  children: string;
  className?: string;
  type?: 'button' | 'submit';
  form?: string;
} & React.ComponentProps<typeof Button>;

export function PrimaryButton({
  onClick,
  disabled,
  loading,
  children,
  className,
  type = 'submit',
  form,
  ...props
}: Props) {
  const tgButtonRef = useRef<HTMLButtonElement>(null);

  if (config.isTelegramBrowser) {
    return (
      <>
        <button
          ref={tgButtonRef}
          className="hidden"
          disabled={disabled}
          form={form}
        ></button>
        <MainButton
          disabled={disabled}
          progress={loading}
          text={children}
          onClick={() => {
            if (type === 'submit') {
              tgButtonRef.current?.click();
            }
            onClick?.();
          }}
        />
      </>
    );
  }

  return (
    <Button
      type={type}
      onClick={onClick}
      variant="primary"
      disabled={disabled}
      loading={loading}
      className={className}
      {...props}
    >
      {children}
    </Button>
  );
}
