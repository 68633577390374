import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Fragment, PropsWithChildren, useState } from 'react';
import { IconButton } from '../button/IconButton';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  sort?: React.ReactNode;
  isCondensed?: boolean;
  icon?: JSX.Element;
}

export function SelectFiltersBar({
  children,
  isCondensed,
  sort,
  icon,
  ...rest
}: PropsWithChildren<Props>) {
  const [open, setOpen] = useState(false);
  return (
    <div
      {...rest}
      className={twMerge(
        'xxs:flex',
        sort && 'xxs:justify-between',
        rest.className
      )}
    >
      <div className="text-nowrap">{sort}</div>

      {!isCondensed && (
        <div
          className={classNames(
            'hidden lg:flex flex-col lg:flex-row lg:space-x-2',
            sort && 'lg:justify-end'
          )}
        >
          {children}
        </div>
      )}
      {children ? (
        <div className={twMerge('xxs:flex ', !isCondensed ? 'lg:hidden' : '')}>
          <IconButton
            onClick={() => setOpen(true)}
            className="xxs:border xxs:border-solid xxs:rounded-md xxs:text-black-300 dark:text-white-50 dark:border-black-700 dark:bg-black-800 "
          >
            {icon ? (
              icon
            ) : (
              <div className="xxs:p-1">
                <FunnelIcon aria-hidden="true" width={24} height={24} />
              </div>
            )}
          </IconButton>
        </div>
      ) : (
        ''
      )}
      {children ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className={twMerge(
              'relative z-50',
              !isCondensed ? 'lg:hidden' : ''
            )}
            onClose={setOpen}
          >
            <div className={twMerge('fixed inset-0 top-0')} />
            <div className="fixed top-16 inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div
                  className={twMerge(
                    'pointer-events-none fixed inset-y-0 right-0 flex max-w-full top-0',
                    isCondensed ? 'max-w-[750px]' : 'max-w-full'
                  )}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen ">
                      <div className="flex h-full flex-col overflow-y-scroll xxs:bg-white dark:bg-black-800 py-6 shadow-xl">
                        <div className="xxs:px-3">
                          <Dialog.Title className="text-xl font-medium text-gray-900 dark:text-white xxs:flex xxs:items-center xxs:gap-2">
                            <div className="xxs:border xxs:border-solid xxs:rounded-md  dark:border-black-700 dark:bg-black-800 xxs:w-10 xxs:h-10 xxs:flex xxs:text-black-300 dark:text-white-50">
                              <FunnelIcon
                                className="xxs:m-auto"
                                aria-hidden="true"
                                width={24}
                                height={24}
                              />
                            </div>
                            Filters
                            <IconButton
                              className="ml-auto xxs:rounded-md xxs:text-black-100"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                width={20}
                                height={20}
                                aria-hidden="true"
                              />
                            </IconButton>
                          </Dialog.Title>
                        </div>
                        <div className="relative mt-6 flex-1 xxs:px-3">
                          <div className="absolute inset-0 xxs:px-3">
                            <div
                              className="h-full flex flex-col gap-2"
                              aria-hidden="true"
                            >
                              {children}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        ''
      )}
    </div>
  );
}
