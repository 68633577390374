import { BaseButton } from 'modules/shared-components/button/base-button';
import { twMerge } from 'tailwind-merge';

export function PresetButton(props: React.ComponentProps<typeof BaseButton>) {
  return (
    <BaseButton
      type="button"
      {...props}
      className={twMerge(
        'xxs:rounded-lg xxs:text-center xxs:text-dex-white-secondary hover:text-dex-white hover:bg-dex-black-600 xxs:font-semibold xxs:text-lg xxs:bg-dex-black-700 xxs:p-4 flex flex-col items-center justify-center',
        props.className
      )}
    />
  );
}
export function PresetButtonTitle(props: React.ComponentProps<'p'>) {
  return (
    <div
      {...props}
      className={twMerge('xxs:mb-0 xxs:text-dex-white', props.className)}
    />
  );
}
export function PresetButtonDescription(props: React.ComponentProps<'p'>) {
  return (
    <div
      {...props}
      className={twMerge(
        'xxs:mb-0 xxs:text-sm xxs:font-normal',
        props.className
      )}
    />
  );
}
