import { useContext, useEffect, useState } from 'react';
import { systemContext } from './core';

export interface MainButtonProps {
  text?: string;
  progress?: boolean;
  disable?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  color?: string;
  textColor?: string;
}

let index = 1;

export const MainButton = ({
  text = 'CONTINUE',
  progress = false,
  disabled = false,
  color,
  textColor,
  onClick,
}: MainButtonProps): null => {
  const [system, setSystem] = useContext(systemContext);
  const [buttonId] = useState(() => ++index);

  useEffect(() => {
    if ((system.MainButton?.id || 0) <= buttonId) {
      setSystem((state) => ({
        ...state,
        MainButton: {
          id: buttonId,
          isActive: !disabled,
          text,
          color,
          textColor,
          onClick,
          isProgress: progress,
        },
      }));
    }
  }, [buttonId, text, disabled, system.MainButton?.id, progress]);

  useEffect(() => {
    return () => {
      setSystem((state) => ({
        ...state,
        MainButton: null,
      }));
    };
  }, []);
  return null;
};
