import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DexBotService, dexBotKeys } from 'api/services/DexBotService';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import Button from 'modules/shared-components/button/button';
import { BotType } from 'api/dex-bot';
import CustomModal from '../modal/CustomModal';
import { NotificationDex } from '../alerts/notification';

type Props = {
  handleClose: () => void;
  id: string;
  botType: BotType;
};

export function ResumeDexBotModal({ handleClose, id, botType }: Props) {
  const { addNewMessage } = useSnackBar();

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    mutationFn: () => DexBotService.resumeBot(id, botType),
    onSuccess: async () => {
      await queryClient.invalidateQueries(dexBotKeys.all());
      addNewMessage({
        message: 'Bot resumed successfully',
        title: 'Success',
        type: 'success',
      });
      handleClose();
    },
  });

  return (
    <CustomModal title="Resume Bot" handleClose={handleClose} showModal>
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Resume Bot
        </CustomModal.Title>

        <CustomModal.Content>
          <div className="flex flex-col xxs:gap-2">
            <div>
              <p>By resuming this bot, you will:</p>
              <ul>
                <li>Open new trades based on Telegram calls</li>
                <li>
                  Keep managing the open trades (Take Profit, Anti-rug and more)
                </li>
              </ul>
            </div>
          </div>
          {error && (
            <NotificationDex
              type="error"
              errorMessage={error}
              className="xxs:my-2"
            >
              An error occurred.
            </NotificationDex>
          )}{' '}
        </CustomModal.Content>
      </CustomModal.Body>
      <CustomModal.Footer>
        <PrimaryButton
          loading={isLoading}
          className="xxs:text-base lg:text-xs"
          onClick={() => {
            mutate();
          }}
        >
          Resume
        </PrimaryButton>
        <Button
          type="button"
          variant="dexNeutral"
          className="xxs:text-base lg:text-xs"
          onClick={() => handleClose()}
        >
          CANCEL
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
}
