import * as yup from 'yup';
import {
  stopLossSchema,
  takeProfitConfigSchema,
  takeProfitSchema,
} from '../../trade/schema';
import { Chains } from 'api/d-wallets';
import { isValidAddress } from 'helpers/api/web3Utils';
import { HotTokensSortKeys } from 'api/contracts';
import {
  HotTokensPeriods,
  TokenLaunchPeriods,
} from '../../research/components/Wallets/usePageParams';
import { InferType } from 'yup';
import { validateLabel } from '../../components/inputs/text-input/text-validation';

export enum VolatilityPeriod {
  One = 1,
  Five = 5,
  Fifteen = 15,
  Thirty = 30,
  Sixty = 60,
}

export const dcaOrderSchema = yup
  .array(
    yup.object({
      priceDeviationPercentage: yup
        .number()
        .typeError('Required')
        .min(0.01, 'Must be at least 0.01%')
        .max(100, 'Max is 100%')
        .required('Required'),
      slippage: yup.number().required('Required'),
      amount: yup
        .number()
        .typeError('Required')
        .min(0.001, 'Must be greater than 0.001')
        .required('Required'),
    })
  )
  .min(1, 'At least 1 DCA order must be set')
  .max(5, 'Max 5 DCA orders')
  .required('At least 1 DCA order must be set');

export const botSettingsSchema = yup.object({
  autoRetryEnabled: yup.boolean().required(),
  autoRetry: yup
    .object({
      buy: yup.boolean().required(),
      takeProfit: yup.boolean().required(),
      stopLoss: yup.boolean().required(),
    })
    .required(),
  periodInMinutes: yup
    .number()
    .oneOf([...(Object.values(VolatilityPeriod) as number[])])
    .typeError('Required')
    .required('Required'),

  priceChange: yup
    .number()
    .min(0.01, 'Must be above 0.01%')
    .max(70, 'Must be below 70%')
    .typeError('Required')
    .required('Required'),

  limitOrdersScale: yup.array(
    yup.object({ amount: yup.number(), id: yup.string() })
  ),

  isDcaActive: yup.boolean().default(false).required('Required'),

  limitOrders: yup
    .array(
      yup.object({
        id: yup.string().optional(),
        priceDeviationPercentage: yup
          .number()
          .min(0.01, 'Must be at least 0.01%')
          .max(100, 'Max is 100%')
          .required('Required'),
        slippage: yup.number().required('Required'),
        amount: yup
          .number()
          .typeError('Required')
          .min(0.001, 'Must be greater than 0.001')
          .required('Required'),
      })
    )
    .min(1, 'At least 1 DCA order must be set')
    .max(5, 'Max 5 DCA orders')
    .required('At least 1 DCA order must be set'),

  expirationInHours: yup.mixed().when('isDcaActive', (isDcaActive, schema) => {
    return isDcaActive
      ? yup
          .number()
          .typeError('Required')
          .default(48)
          .min(0, 'Must be greater than 0 hr')
          .max(720, 'Must be less than 720 hr')
          .required('Expiration time is required when DCA is active')
      : schema.nullable().notRequired();
  }),

  addresses: yup
    .array(
      yup.object({
        address: yup
          .string()
          .required('Token address is needed')
          .test('address-type', 'Invalid address', (value) => {
            return !value ? true : isValidAddress(value);
          }),
      })
    )
    .min(1, 'At least a token contract needs to be selected')
    .max(20, "You can't select more than 20 tokens.")
    .required('At least a token needs to be selected'),
});

export type DcaOrderType = InferType<typeof dcaOrderSchema>;

export const volatilityBotSettingsSchema = yup
  .object({
    entries: botSettingsSchema,
  })
  .required();

const tradingSettingsSchema = yup
  .object({
    chain: yup
      .string()
      .oneOf([...Object.values(Chains)])
      .required('Required'),
    walletId: yup.string().required('Required'),
    buyAmount: yup
      .number()
      .min(0.001, 'Min is 0.001')
      .typeError('Required')
      .required('Required'),
    slippage: yup.number().required('Required'),
    antiMev: yup.boolean().default(false).required(),
    isMaxBuyGasPriorityEdited: yup.boolean(),
    maxBuyGasPriority: yup
      .number()
      .min(0.01, 'Must be above 0.01 gwei')
      .required('Required'),
    takeProfit: takeProfitSchema,
    takeProfitConfig: takeProfitConfigSchema,
    stopLoss: stopLossSchema,
  })
  .required();

export type TradingSettingsSchema = yup.InferType<typeof tradingSettingsSchema>;

export const schema = yup.object({
  name: yup
    .string()
    .typeError('Required')
    .required('Required')
    .test(
      'is-valid-label',
      'Invalid label. Text can contain only letters, spaces, numbers, and -,.. It cannot be longer than 127 characters.',
      (value) => {
        try {
          return validateLabel(value || '');
        } catch {
          return false;
        }
      }
    ),
  tradingSettings: tradingSettingsSchema,
  botSettings: volatilityBotSettingsSchema,
});

export type VolatilityBotFormValues = yup.InferType<typeof schema>;

export enum PreSets {
  Volatility = 'volatility',
  TradingVolume = 'tradingVolume',
  SmartMoney = 'smartMoney',
  Price = 'price',
}

export const PreSetFilters: Record<
  PreSets,
  {
    sort: string;
    period: HotTokensPeriods;
    minLiquidity: number;
    maxLiquidity: number;
    minVolume: number;
    poolCreatedAt: TokenLaunchPeriods;
  }
> = {
  [PreSets.TradingVolume]: {
    sort: `-${HotTokensSortKeys.Volume}`,
    period: HotTokensPeriods.SixHours,
    minLiquidity: 20000,
    maxLiquidity: 1000000,
    minVolume: 10000,
    poolCreatedAt: TokenLaunchPeriods.Day,
  },
  [PreSets.Volatility]: {
    sort: `-${HotTokensSortKeys.VolatilityIndex}`,
    period: HotTokensPeriods.SixHours,
    minLiquidity: 20000,
    maxLiquidity: 1000000,
    minVolume: 10000,
    poolCreatedAt: TokenLaunchPeriods.Day,
  },
  [PreSets.SmartMoney]: {
    sort: `-${HotTokensSortKeys.BuyVolumeTop}`,
    period: HotTokensPeriods.SixHours,
    minLiquidity: 20000,
    maxLiquidity: 1000000,
    minVolume: 10000,
    poolCreatedAt: TokenLaunchPeriods.Day,
  },
  [PreSets.Price]: {
    sort: `-${HotTokensSortKeys.PriceChange}`,
    period: HotTokensPeriods.SixHours,
    minLiquidity: 20000,
    minVolume: 10000,
    maxLiquidity: 1000000,
    poolCreatedAt: TokenLaunchPeriods.Day,
  },
};
