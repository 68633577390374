import { useQueryParams } from 'modules/routing/useQueryParams';
import * as yup from 'yup';

export function usePageParams() {
  return useQueryParams({
    walletId: yup.string(),
    isCreateOpen: yup.boolean(),
    selectionDefaultOpen: yup.boolean(),
    walletAddress: yup.string(),
  });
}
