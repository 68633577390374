import {
  BridgeParams,
  Chains,
  CompleteWithdrawParams,
  CreateDWalletBody,
  CreateDWalletResponse,
  EditDWalletBody,
  EstimateWithdraw,
  GetDWalletDetailsResponse,
  GetDWalletsResponse,
  GetInitiateWithdraw,
  InitiateBridge,
  InitiateWithdrawParams,
  WithdrawBody,
} from 'api/d-wallets';
import { api } from 'helpers/api/apiCore';
import { sortBy } from 'lodash/fp';

const SERVICE_KEY = 'wallets';

export const dWalletServiceKeys = {
  all: () => [SERVICE_KEY],
  getWallets: () => [SERVICE_KEY],
  estimateWithdraw: ({ params }: { params: InitiateWithdrawParams }) => [
    SERVICE_KEY,
    'estimate',
    params,
  ],
  getWallet: (id: string) => [SERVICE_KEY, id],
  getWalletWithoutTokens: (id: string) => [SERVICE_KEY, id, 'no-tokens'],
  getWalletWithAddress: (chain: Chains, address: string) => [
    SERVICE_KEY,
    chain,
    address,
  ],
  getTokenBalance: (vaultId: string, address: string) => [
    SERVICE_KEY,
    vaultId,
    address,
  ],
  estimateBridge: (params: BridgeParams) => [SERVICE_KEY, 'bridge', params],
};

export const DWalletService = {
  async getWallet(id: string) {
    const response = await api.get<GetDWalletDetailsResponse>(
      `/async/api/v1/d-vault/${id}`
      //`/api/v1/d-vault/${id}`
    );

    return response.data.data;
  },

  async getWalletWithoutTokens(id: string) {
    const response = await api.get<GetDWalletDetailsResponse>(
      `/async/api/v1/d-vault/${id}?include_token_balances=false`
      //`/api/v1/d-vault/${id}?include_token_balances=false`
    );

    return response.data.data;
  },

  async getTokenBalance(vaultId: string, address: string) {
    const response = await api.get<{
      balance: string;
    }>(
      `/async/api/v2/d-trade/balance/wallet/${vaultId}/token-address/${address}`
      //`/api/v2/d-trade/balance/wallet/${vaultId}/token-address/${address}`
    );

    return response.data;
  },

  async getWalletWithAddress(chain: Chains, address: string) {
    const response = await api.get<GetDWalletDetailsResponse>(
      `/async/api/v1/d-vault/${chain}/${address}`
      //`/api/v1/d-vault/${chain}/${address}`
    );

    return response.data.data;
  },

  async getWallets() {
    const response = await api.get<GetDWalletsResponse>(
      `/async/api/v1/d-vault?include_token_balances=false`
      //`/api/v1/d-vault?include_token_balances=false`
    );

    return sortBy((x) => -x.chainBalance, response.data.data);
  },

  async estimateBridge(params: BridgeParams) {
    const response = await api.post<BridgeParams, { data: InitiateBridge }>(
      `/async/api/v1/d-vault/bridge/estimate`,
      //`/api/v1/d-vault/bridge/estimate`,
      params
    );

    return response.data.data;
  },

  async initiateBridge({ params }: { params: BridgeParams }) {
    const response = await api.post<BridgeParams, { data: InitiateBridge }>(
      `/async/api/v1/d-vault/bridge/initiate`,
      //`/api/v1/d-vault/bridge/initiate`,
      params
    );

    return response.data.data;
  },

  async initiateWithdraw({ params }: { params: InitiateWithdrawParams }) {
    const response = await api.post<
      InitiateWithdrawParams,
      { data: GetInitiateWithdraw }
    >(
      `/async/api/v1/d-vault/transfer/initiate`,
      //`/api/v1/d-vault/transfer/initiate`,
      params
    );

    return response.data.data;
  },

  async estimateWithdraw({ params }: { params: InitiateWithdrawParams }) {
    const response = await api.post<InitiateWithdrawParams, EstimateWithdraw>(
      `/async/api/v1/d-vault/transfer/estimate`,
      //`/api/v1/d-vault/transfer/estimate`,
      params
    );

    return response.data.data;
  },

  async completeWithdraw({ params }: { params: CompleteWithdrawParams }) {
    const response = await api.post<
      CompleteWithdrawParams,
      { data: GetInitiateWithdraw }
    >(
      `/async/api/v1/d-vault/transfer/complete`,
      //`/api/v1/d-vault/transfer/complete`,
      params
    );

    return response.data.data;
  },

  async withdraw(body: WithdrawBody) {
    const response = await api.post<WithdrawBody>(
      `/async/api/v2/vaults/withdraw`,
      //`/api/v2/vaults/withdraw`,
      body
    );

    return response.data;
  },
};
const MULTI_CHAIN_SERVICE_KEY = 'multi-chain-wallets';

export const multiChainDWalletServiceKeys = {
  all: () => [MULTI_CHAIN_SERVICE_KEY],
  getWallets: (chainType: string) => [MULTI_CHAIN_SERVICE_KEY, chainType],
  estimateWithdraw: ({
    id,
    params,
  }: {
    id: string;
    params: InitiateWithdrawParams;
  }) => [MULTI_CHAIN_SERVICE_KEY, 'estimate', id, params],
};

export const DWalletKeyService = {
  walletCryptoKey: null as CryptoKey | null,

  async getWalletCryptoKey(): Promise<CryptoKey> {
    if (!this.walletCryptoKey) {
      this.walletCryptoKey = await crypto.subtle.generateKey(
        { name: 'AES-GCM', length: 256 },
        true,
        ['encrypt', 'decrypt']
      );
    }

    return this.walletCryptoKey;
  },
};

async function securePrivateKey(data: string, key: CryptoKey) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(data);

  const iv = crypto.getRandomValues(new Uint8Array(12));

  const encryptedKey = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    keyData
  );

  return { encryptedKey, iv };
}

function clearSensitiveData(data: any) {
  if (typeof data === 'string') {
    data = '';
  } else if (data instanceof ArrayBuffer) {
    new Uint8Array(data).fill(0); // Zero out memory
  }
}

export const MultiChainDWalletService = {
  async getWallets(chainType: Chains) {
    const response = await api.get<GetDWalletsResponse>(
      `/async/api/v1/d-vaults?include_token_balances=false&chain=${chainType}`
      //`/api/v1/d-vaults?include_token_balances=false&chain=${chainType}`
    );

    return sortBy((x) => -x.chainBalance, response.data.data);
  },

  async createDWallet(body: CreateDWalletBody) {
    const response = await api.post<CreateDWalletBody, CreateDWalletResponse>(
      `/async/api/v1/d-vaults`,
      //`/api/v1/d-vaults`,
      body
    );

    const key = await DWalletKeyService.getWalletCryptoKey();

    return Promise.all(
      response.data.data.map(async (wallet) => {
        const mnemonicEncryption = await securePrivateKey(
          wallet.decryptedMnemonic,
          key
        );
        clearSensitiveData(wallet.decryptedMnemonic);

        const privateKeyEncryption = await securePrivateKey(
          wallet.decryptedPrivateKey,
          key
        );
        clearSensitiveData(wallet.decryptedPrivateKey);

        return {
          ...wallet,
          decryptedMnemonic: 'encrypted',
          decryptedPrivateKey: 'encrypted',
          encryptedMnemonic: mnemonicEncryption.encryptedKey,
          mnemonicIV: mnemonicEncryption.iv,
          encryptedPrivateKey: privateKeyEncryption.encryptedKey,
          privateKeyIV: privateKeyEncryption.iv,
        };
      })
    );
  },

  async editDWallet(address: string, body: EditDWalletBody) {
    const response = await api.update<{}, EditDWalletBody>(
      `/async/api/v1/d-vaults/${address}`,
      //`/api/v1/d-vaults/${address}`,
      body
    );

    return response.data;
  },

  async delete(address: string) {
    const response = await api.delete(
      `/async/api/v1/d-vaults/${address}`
      //`/api/v1/d-vaults/${address}`
    );

    return response.data;
  },
};
