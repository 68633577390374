import { useEffect } from 'react';

type Props = {
  children: string;
  onConfirmed?: () => void;
  onClose?: () => void;
};

export function TgConfirmationDialog({
  children,
  onConfirmed,
  onClose,
}: Props) {
  useEffect(() => {
    window.Telegram?.WebApp?.showConfirm?.(children, (isConfirmed) => {
      if (isConfirmed) {
        onConfirmed?.();
      } else {
        onClose?.();
      }
    });
  }, []);

  return <></>;
}
