import type { Placement } from '@popperjs/core';
import classNames from 'classnames';
import React, { ReactHTMLElement, useState } from 'react';
import { usePopper } from 'react-popper';

interface PopupProps {
  placement?: Placement;
  className?: string;
  children: React.ReactElement | React.ReactElement[];
  trigger: React.ReactElement;
}

export const Popup = ({
  children,
  trigger,
  placement = 'top',
  className,
}: PopupProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [display, setDisplay] = useState(false);

  const { update, styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      strategy: 'fixed',
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    }
  );

  return React.isValidElement(trigger) ? (
    <span
      onMouseLeave={() => {
        setDisplay(false);
        void update?.();
      }}
    >
      {React.cloneElement(trigger as ReactHTMLElement<HTMLSpanElement>, {
        ref: setReferenceElement,
        onMouseEnter: () => {
          setDisplay(true);
          void update?.();
        },
        className: classNames(
          (trigger as ReactHTMLElement<HTMLSpanElement>).props.className,
          className
        ),
      })}

      {display && (
        <PopupContent
          className="xxs:bg-white xxs:p-0 rounded drop-shadow dark:bg-black-800 whitespace-normal xxs:text-xs xxs:text-left xxs:leading-4"
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 1031 }}
          {...attributes.popper}
        >
          {children}
          <div ref={setArrowElement} style={styles.arrow} />
        </PopupContent>
      )}
    </span>
  ) : null;
};

export const PopupContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick?.(e);
      }}
      className={classNames(
        'xxs:bg-white xxs:text-black-600 rounded drop-shadow dark:bg-black-800 xxs:max-w-lg',
        props.className
      )}
    />
  );
});
