import Button from 'modules/shared-components/button/button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { valueAsSafeNumber } from 'utils/FormatNumber';
import { usePoolSearchFilters } from '../../usePageParams';
import {
  PresetButton,
  PresetButtonTitle,
  PresetButtonDescription,
} from './PresetButton';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import NumberInput from '../../../components/inputs/number-input';
import CustomModal from '../../../components/modal/CustomModal';

type Props = {
  handleClose: () => void;
};
export const schema = yup.object({
  min: yup.number().min(0),
  max: yup.number().min(0),
});

type FormState = yup.InferType<typeof schema>;

export function LiquidityFilterModal({ handleClose }: Props) {
  const { filtersState, setFilters } = usePoolSearchFilters();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      min: filtersState.minLiquidity,
      max: filtersState.maxLiquidity,
    },
  });

  return (
    <CustomModal
      title="Total Liquidity Range"
      handleClose={handleClose}
      showModal
    >
      <form
        autoComplete="off"
        className="contents"
        onSubmit={(e) => {
          void handleSubmit((form) => {
            setFilters({
              ...filtersState,
              minLiquidity: form.min || undefined,
              maxLiquidity: form.max || undefined,
            });
            handleClose();
          })(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4">
          <CustomModal.Title className="hidden lg:flex">
            Total Liquidity Range
          </CustomModal.Title>

          <CustomModal.Content>
            <div className="xxs:space-y-3">
              <p className="xxs:text-sm xxs:mb-0">
                The Liquidity Range filter allows you to focus on tokens within
                a specific range of total liquidity (calculated as the sum of
                the token and quote asset in USD within the primary pool). This
                feature helps you exclude tokens with either excessively high or
                very low liquidity. A liquidity level of $50k is generally
                considered the lowest safety threshold.
              </p>
              <div className="xxs:space-y-1">
                <p className="xxs:text-sm xxs:mb-0">Set a Range</p>
                <div className="lg:grid lg:grid-cols-3 xxs:space-y-1 lg:space-y-0 xxs:gap-1 xxs:mb-4">
                  <PresetButton
                    className="w-full"
                    onClick={() => {
                      setValue('min', undefined);
                      setValue('max', 100_000);
                    }}
                  >
                    <PresetButtonTitle>🐟 Micro</PresetButtonTitle>
                    <PresetButtonDescription>
                      Below $100k
                    </PresetButtonDescription>
                  </PresetButton>
                  <PresetButton
                    className="w-full"
                    onClick={() => {
                      setValue('min', 100_000);
                      setValue('max', 1_000_000);
                    }}
                  >
                    <PresetButtonTitle>🦐 Medium</PresetButtonTitle>
                    <PresetButtonDescription>
                      $100k to $1M
                    </PresetButtonDescription>
                  </PresetButton>
                  <PresetButton
                    className="w-full"
                    onClick={() => {
                      setValue('min', 1_000_000);
                      setValue('max', undefined);
                    }}
                  >
                    <PresetButtonTitle>🐳 Large</PresetButtonTitle>
                    <PresetButtonDescription>Above $1M</PresetButtonDescription>
                  </PresetButton>
                </div>
                <p className="xxs:mb-1">Custom</p>
                <div className="flex xxs:gap-1">
                  <NumberInput
                    label="Min"
                    prefix="$"
                    {...register('min', {
                      setValueAs: valueAsSafeNumber,
                    })}
                    error={errors.min?.message}
                  />
                  <NumberInput
                    label="Max"
                    prefix="$"
                    {...register('max', {
                      setValueAs: valueAsSafeNumber,
                    })}
                    error={errors.max?.message}
                  />
                </div>
              </div>
            </div>
          </CustomModal.Content>
        </CustomModal.Body>
        <CustomModal.Footer>
          <PrimaryButton className="xxs:text-base lg:text-xs" type="submit">
            SAVE
          </PrimaryButton>
          <Button
            className="xxs:text-base lg:text-xs"
            type="button"
            variant="dexNeutral"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
}
