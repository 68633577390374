import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../schema';
import { VolatilityBotFormValues } from '../../volatility/schema';
import NumberInput from '../../../components/inputs/number-input';
import Checkbox from '../../../components/inputs/Checkbox';
import Tooltip from 'modules/shared-components/tooltip';

export function StopLoss() {
  type CombinedFormValues = VolatilityBotFormValues | FormValues;
  const { watch, setValue, control } = useFormContext<CombinedFormValues>();

  const sl = watch('tradingSettings.stopLoss');

  return (
    <div className="xxs:space-y-1">
      <div className="flex">
        <Tooltip text="Auto-sell your tokens when they hit the stop loss price threshold. Add stop-loss trailing to move your stop loss to breakeven automatically.">
          <div>
            <div className="flex items-center xxs:gap-1">
              <Checkbox
                className="lg:w-4 lg:h-4 xxs:w-5 xxs:h-5"
                checked={!!sl}
                onChange={(e) => {
                  const checked = e.currentTarget.checked;
                  if (
                    !checked &&
                    (watch('botSettings.entries.autoRetryEnabled') ||
                      watch('tradingSettings.safetyMeasures.autoRetryEnabled'))
                  ) {
                    setValue(
                      'tradingSettings.safetyMeasures.autoRetry.stopLoss',
                      false
                    );
                    setValue('botSettings.entries.autoRetry.stopLoss', false);
                  }

                  setValue(
                    'tradingSettings.stopLoss',
                    checked
                      ? {
                          deviation: 50,
                          isTrailingEnabled: true,
                          threshold: 50,
                        }
                      : null,
                    {
                      shouldDirty: true,
                    }
                  );
                }}
              />
              <div className="xxs:text-sm xxs:text-dex-white-secondary hover:text-dex-white">
                Stop Loss
              </div>
            </div>
          </div>
        </Tooltip>
      </div>

      {sl ? (
        <>
          <Controller
            name={`tradingSettings.stopLoss.threshold`}
            defaultValue={'' as any}
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <NumberInput
                  {...field}
                  prefix={'-'}
                  suffix={<>%</>}
                  inputSize="small"
                  extraLabel={
                    <div className="flex flex-nowrap">
                      <Checkbox
                        id="trailing"
                        checked={watch(
                          'tradingSettings.stopLoss.isTrailingEnabled'
                        )}
                        onChange={(ev) => {
                          setValue(
                            'tradingSettings.stopLoss.isTrailingEnabled',
                            ev.target.checked
                          );
                          setValue(
                            'tradingSettings.stopLoss.deviation',
                            ev.target.checked ? 50 : null
                          );
                        }}
                      />
                      <Checkbox.Label htmlFor="trailing">
                        Trailing
                      </Checkbox.Label>
                    </div>
                  }
                  label="Threshold"
                  error={error?.message}
                />
              );
            }}
          />

          {watch('tradingSettings.stopLoss.isTrailingEnabled') && (
            <Controller
              name={`tradingSettings.stopLoss.deviation`}
              defaultValue={'' as any}
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <NumberInput
                    {...(field as any)}
                    prefix={'+'}
                    label="Deviation"
                    inputSize="small"
                    suffix={<>%</>}
                    error={error?.message}
                  />
                );
              }}
            />
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
}
