import { useToggle } from 'modules/utils/useToggle';
import { useTelegramChannelsSearchFilters } from '../usePageParams';
import { NumberOfCallsFiltersModal } from './NumberOfCallsFiltersModal';
import { WinnersVsLosersFiltersModal } from './WinnersVsLosersFiltersModal';
import { NumberOfRugPullsFiltersModal } from './NumberOfRugPullsFiltersModal';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { FilterButton } from '../../../components/filters/filterButton';

export function NumberOfRugPullsFilter() {
  const [isOpen, { open, close }] = useToggle();
  const { filtersState } = useTelegramChannelsSearchFilters();

  return (
    <>
      <FilterButton className="w-full" label="Rug-pull" onClick={() => open()}>
        {!!(filtersState.minRugPulls || filtersState.maxRugPulls) && (
          <FunnelIcon className="w-4 h-4 text-black-100" />
        )}
      </FilterButton>
      {isOpen && <NumberOfRugPullsFiltersModal handleClose={close} />}
    </>
  );
}

export function NumberOfCallsFilter() {
  const [isOpen, { open, close }] = useToggle();
  const { filtersState } = useTelegramChannelsSearchFilters();

  return (
    <>
      <FilterButton className="w-full" label="Calls" onClick={() => open()}>
        {!!(filtersState.minNumberOfCalls || filtersState.maxNumberOfCalls) && (
          <FunnelIcon className="w-4 h-4 text-black-100" />
        )}
      </FilterButton>
      {isOpen && <NumberOfCallsFiltersModal handleClose={close} />}
    </>
  );
}

export function WinnersVsLosersFilter() {
  const [isOpen, { open, close }] = useToggle();
  const { filtersState } = useTelegramChannelsSearchFilters();

  return (
    <>
      <FilterButton className="w-full" label="Hit Ratio" onClick={() => open()}>
        {!!(
          filtersState.minWinnersVsLosers || filtersState.maxWinnersVsLosers
        ) && <FunnelIcon className="w-4 h-4 text-black-100" />}
      </FilterButton>
      {isOpen && <WinnersVsLosersFiltersModal handleClose={close} />}
    </>
  );
}
