import React from 'react';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { twMerge } from 'tailwind-merge';

export function DexPanelButton(
  props: React.ComponentProps<typeof BaseButton> & { isActive?: boolean }
) {
  return (
    <BaseButton
      {...props}
      type="button"
      className={twMerge(
        'xxs:text-sm xxs:px-2 hover:text-dex-white text-center xxs:rounded xxs:text-dex-white-dark xxs:bg-dex-black-800 hover:bg-dex-black-700 disabled:text-dex-white-secondary disabled:cursor-not-allowed',
        props.isActive && 'xxs:bg-dex-black-700 xxs:text-dex-white',
        props.className
      )}
      onClick={(ev) => {
        if (!props.isActive) {
          props.onClick?.(ev);
        }
      }}
    />
  );
}
