import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import { useMediaQuery, screenGte } from 'modules/media/use-media-query';
import { DexPositionChart } from '../PositionChart';
import { PositionTransactionsTable } from '../PositionDetailsTable';
import { PositionTransactionsList } from '../PositionDetailsList';
import { Wrapper } from './PositionDetails';
import { DetailsTopInfo } from './TopInfo';
import { DexTradeStatus, OpenPositionListItem } from 'api/dex-trade';
import { useQuery } from '@tanstack/react-query';
import { StatsCards } from './StatsCards';
import { TokenPoolInfoHeader } from '../../../research/components/TokenCard/TokenCardHeader';
import { Stats } from '../../../trade/components/StatsCards';
import Big from 'big.js';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import { NotificationDex } from '../../../components/alerts/notification';
import { LoaderDex } from '../../../components/alerts/Loader';
import { TokenInfoMobileBody } from '../../../research/components/TokenCard';
import { ErrorPageDex } from '../../../research/components/shared/Common';
import { DexChart } from '../../../components/Chart/DexChart';

interface Props {
  position: OpenPositionListItem;
}

export function ExecutedTradeDetails({ position }: Props) {
  const isLarge = useMediaQuery(screenGte.large);
  const { data, isLoading, error } = useQuery({
    queryFn: () =>
      ContractService.getContract(position.chain, position.tokenAddress),
    queryKey: contractServiceKeys.getContract(
      position.chain,
      position.tokenAddress
    ),
  });

  const selectedPool =
    data && data?.liquidityInfo?.activePools?.length >= 1
      ? data.liquidityInfo.activePools[0]
      : undefined;

  if (isLoading) {
    return (
      <div className="flex h-screen justify-center items-center">
        <LoaderDex />
      </div>
    );
  }

  if (error) {
    return <ErrorPageDex />;
  }

  const isClosePending =
    position.status === DexTradeStatus.sniped &&
    Big(position.totalAmountTokenRemaining || 0).eq(0) &&
    Big(position.totalAmountTokenBought || 0).gt(0);

  return (
    <Wrapper className="xxs:space-y-2 lg:space-y-1">
      <DetailsTopInfo
        selectedPool={selectedPool}
        position={position}
        contract={data}
      />

      {isClosePending && (
        <NotificationDex type="success" className="xxs:my-2 xxs:mx-0">
          The position is fully closed. Awaiting for final confirmation, it'll
          take approximately 12 blocks.
        </NotificationDex>
      )}
      {position.status == DexTradeStatus.cancelledByUser && (
        <NotificationDex type="warning" className="xxs:my-2 xxs:mx-0">
          This position has been manually cancelled.{' '}
          {position.totalAmountTokenRemaining && (
            <span>
              {formatNumberWithSuffix(position.totalAmountTokenRemaining!)}{' '}
              {position.tokenSymbol} unsold.
            </span>
          )}
        </NotificationDex>
      )}
      {position.totalProfitAndLoss && <StatsCards position={position} />}
      {selectedPool && data && isLarge && (
        <TokenPoolInfoHeader contract={data} selectedPool={selectedPool} />
      )}

      {data && isLarge && selectedPool && (
        <div className="grid lg:grid-cols-[1fr_100px] gap-1">
          <DexPositionChart
            poolAddress={selectedPool ? selectedPool.poolAddress : undefined}
            position={position}
            contract={data}
          />

          <div className="xxs:hidden lg:grid xxs:grid-cols-2 lg:grid-cols-1 xxs:gap-1">
            <Stats contract={data} />
          </div>
        </div>
      )}

      <div className="space-y-2">
        {isLarge ? (
          <PositionTransactionsTable position={position} />
        ) : (
          <PositionTransactionsList position={position} />
        )}
      </div>
      {!isLarge && data && (
        <div className="xxs:space-y-2">
          <div className="xxs:text-xs xxs:mb-2  xxs:text-dex-white-secondary">
            Token Info
          </div>
          {selectedPool && (
            <div className="xxs:h-[350px]">
              <DexPositionChart
                poolAddress={
                  selectedPool ? selectedPool.poolAddress : undefined
                }
                position={position}
                contract={data}
              />
            </div>
          )}

          <TokenInfoMobileBody hideChart={true} contract={data} />
        </div>
      )}
    </Wrapper>
  );
}
