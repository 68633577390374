import {
  DWalletService,
  dWalletServiceKeys,
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/DWalletService';
import { usePageParams } from './usePageParams';
import { useQuery } from '@tanstack/react-query';
import { Button } from 'modules/shared-components/button/button';
import moment from 'moment';
import {
  formatTokenAmount,
  formatUSDAmount,
  formatWithoutDecimals,
} from 'utils/FormatNumber';
import Table, { Body } from 'modules/shared-components/data-display/table';
import { tokenColumn } from './tokenColumns';
import { useToggle } from 'modules/utils/useToggle';
import { DepositModal } from './DepositModal';
import {
  chainAsset,
  Chains,
  DWalletDetails,
  minimumSafeAmount,
  TokenBalance,
} from 'api/d-wallets';
import { DepositEthereumProvider } from './DepositModal/DepositWagmiProvider';
import { WithdrawModal } from './WithdrawModal/WithdrawModal';
import { DeleteDWalletModal } from './DeleteDWalletModal/DeleteDWalletModal';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { useState } from 'react';
import { EditDWalletModal } from './EditVaultModal';
import { IconButton } from 'modules/shared-components/button/IconButton';
import {
  ArrowDownTrayIcon,
  ArrowsRightLeftIcon,
  ArrowsUpDownIcon,
  ArrowUpTrayIcon,
  CogIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { CreateDWalletModal } from './CreateDWalletModal';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { useCurrencyConvert } from 'modules/currencies';
import Big from 'big.js';
import { WarningLowBalanceMessage } from 'modules/shared-components/notification/walletLowBalance';
import { BridgeModal } from './BridgeModal/BridgeModal';
import { NotificationDex } from '../components/alerts/notification';
import {
  ResponsiveMenu,
  ResponsiveMenuItem,
} from '../components/dropdown/menu/ResponsiveMenu';
import { useChainPersistedState } from 'modules/layouts/TopBar/components/GasInfo';
import { Title } from '../components/cards';
import { FullPageNotificationDex } from '../fullPageNotification';

export const MapBalances = (
  wallet: DWalletDetails,
  convert: (value: number | undefined, pair: string) => number | undefined,
  chain?: Chains
): TokenBalance[] => {
  const chainType = chain ?? Chains.Ethereum;

  const chainBalance = Big(wallet.chainBalance).gt(0)
    ? [
        {
          address: '',
          chain: chain ?? Chains.Ethereum,
          amount: +wallet.chainBalance,
          symbol: chainAsset(chainType).toUpperCase(),
          decimals: 18,
          name:
            chainType === Chains.Ethereum || chainType === Chains.Base
              ? 'Ether'
              : 'Binance Coin',
          price: (
            convert(
              1,
              chainType === Chains.Ethereum || chainType === Chains.Base
                ? 'ETH/USD'
                : 'BNB/USD'
            ) || 0
          ).toString(),
          hasIcon: true,
        },
      ]
    : [];
  return wallet.tokenBalances ? [...chainBalance, ...wallet.tokenBalances] : [];
};

export function WalletDetails({ isDashboard }: { isDashboard?: boolean }) {
  const [{ walletAddress }] = usePageParams();
  const [selectedChain] = useChainPersistedState(Chains.Base);
  const walletChain = selectedChain;
  const isLargeScreen = useMediaQuery(screenGte.large);
  const [, setActiveAction] = useState<Action>();

  const column = tokenColumn(walletChain);
  const convert = useCurrencyConvert();
  const {
    isLoading,
    data: wallet,
    error,
  } = useQuery({
    queryFn: () =>
      DWalletService.getWalletWithAddress(walletChain, walletAddress as string),
    queryKey: dWalletServiceKeys.getWalletWithAddress(
      walletChain,
      walletAddress as string
    ),
    enabled: !!(walletChain && walletAddress),
    keepPreviousData: true,
  });

  if (isLoading) {
    return <BalancesLoading />;
  }

  if (error || !wallet) {
    return (
      <div className="xxs:space-y-2  ">
        <div className="flex justify-end xxs:px-2">
          <MobileDWalletSelector
            onCreated={() => {}}
            selectedChain={walletChain}
          />
        </div>

        <NotificationDex
          className="xxs:mx-2 lg:mx-0"
          errorMessage={error}
          type="error"
        >
          Wallet not found.
        </NotificationDex>
      </div>
    );
  }

  const balances = wallet ? MapBalances(wallet, convert, walletChain) : [];

  return (
    <div
      className={twMerge(
        !isDashboard
          ? 'xxs:p-2 lg:p-0'
          : 'h-[700px] bg-dex-black-800 xxs:p-2 rounded '
      )}
    >
      <Title className="dark:text-black-50  flex xxs:flex-col md:flex-row justify-between items-start xxs:gap-2 xxs:mb-0">
        <div className="w-full">
          <div className="w-full justify-between items-center xxs:gap-2 flex">
            <h5 className="xxs:my-0 xxs:text-xl md:text-3xl xxs:font-semibold truncate">
              {wallet.label}
            </h5>
            <div className="xxs:flex xxs:gap-2">
              <div className="lg:hidden">
                <MobileDWalletSelector
                  selectedChain={wallet.chain}
                  onCreated={() => setActiveAction('deposit')}
                />
              </div>

              <DWalletActions
                chain={wallet.chain}
                className="xxs:p-2"
                wallet={wallet}
              />
            </div>
          </div>
          <div className="flex xxs:flex-col md:flex-row xxs:gap-2 xxs:text-sm dark:text-black-100 xxs:font-normal">
            <div className="items-center xxs:gap-1 xxs:hidden sm:flex xxs:text-dex-white-secondary">
              Created:{' '}
              {moment(wallet.createdDatetime).format('DD/MM/YYYY HH:mm')}
            </div>
            <span className="xxs:hidden md:block xxs:text-dex-white-secondary">
              |
            </span>
            <div className="flex items-center xxs:gap-1 xxs:text-dex-white-secondary">
              Address:{' '}
              <EthAddress
                chain={wallet.chain}
                kind="account"
                address={wallet.address}
              />
            </div>
          </div>
        </div>
      </Title>

      {!!balances.length && (
        <div>
          {wallet.chainBalance &&
            Big(wallet.chainBalance).lt(minimumSafeAmount(wallet.chain)) && (
              <div className="xxs:my-2">
                <NotificationDex className="xxs:mx-0" type="warning">
                  <WarningLowBalanceMessage chain={wallet.chain} />
                </NotificationDex>
              </div>
            )}
          <div className="flex items-center space-x-3">
            <div className="xxs:text-3xl xxs:font-semibold pb-1 xxs:text-dex-white">
              {formatTokenAmount(Big(wallet.totalEthBalance ?? 0).toNumber())}{' '}
              {chainAsset(wallet.chain)}
            </div>
            <div className="xxs:text-xl xxs:text-dex-white-secondary">
              ${formatUSDAmount(Big(wallet.totalUsdBalance ?? 0).toNumber())}
            </div>
          </div>
        </div>
      )}
      {balances.length === 0 && (
        <div className="lg:h-[400px]">
          <FullPageNotificationDex
            title="Your wallet looks empty"
            subTitle="It looks like you don't have any tokens in your wallet."
          />
        </div>
      )}
      {!!balances.length && (
        <div className="max-h-[530px] overflow-y-scroll xxs:py-2">
          {isLargeScreen && (
            <Table>
              <Table.Header
                trClassName="dark:bg-dex-black-800 dark:border-dex-black-700 px-2 dark:text-dex-white-secondary"
                rowClassName=" xxs:text-dex-white-secondary"
                columns={column}
              />
              <Body
                columns={column}
                data={balances.map((balance, index) => ({
                  ...balance,
                  id: `${index}`,
                }))}
                className="xxs:dark:bg-dex-black-800 xxs:dark:border-dex-black-700 px-2"
              />
            </Table>
          )}
          {!isLargeScreen && (
            <Table className="">
              <Table.Header
                columns={column.filter((x) => x.showOnMobile)}
                className="bg-transparent xxs:p-2"
                rowClassName="px-2 font-normal"
              />
              <Body
                columns={column.filter((x) => x.showOnMobile)}
                data={balances.map((balance, index) => ({
                  ...balance,
                  id: `${index}`,
                }))}
                className="xxs:dark:bg-transparent"
                cellClassName="px-2"
              />
            </Table>
          )}
        </div>
      )}
    </div>
  );
}

export const BalancesLoading = () => {
  const isLargeScreen = useMediaQuery(screenGte.large);
  const column = tokenColumn(Chains.Ethereum);

  return (
    <div className="xxs:bg-dex-black-800 flex flex-col h-[550px] animate-pulse xxs:p-2">
      <div className="flex flex-col">
        <div className="flex justify-between xxs:text-2xl md:text-3xl font-medium text-black-800 dark:text-white-200 md:mb-3">
          <div className="h-4 rounded-full xxs:bg-dex-black-700  xxs:w-24 md:w-96 mb-0" />
          <div className="flex xxs:space-y-2">
            <div className="xxs:block md:hidden h-4 rounded-md xxs:bg-dex-black-700 xxs:w-8 ml-auto" />
            <div className="xxs:block md:hidden h-4  rounded-md xxs:bg-dex-black-700  xxs:w-8 ml-auto" />
          </div>
        </div>
        <div className="text-xl lg:text-3xl font-medium text-black-800 dark:text-white-200 xxs:mb-2.5 ">
          <div className="h-4 xxs:bg-dex-black-700 rounded-full w-48 mb-0" />
        </div>
        <div className="text-3xl lg:text-3xl font-medium text-black-800 dark:text-white-200 xxs:mb-2">
          <div className="h-6 rounded-full xxs:bg-dex-black-700 xxs:w-full md:w-24 mb-0" />
        </div>
        <div className="xxs:flex md:hidden justify-between text-2xl lg:text-3xl font-medium xxs:mb-2 text-black-800 dark:text-white-200">
          <div className="h-6 rounded-full xxs:bg-dex-black-700 xxs:w-24 md:w-96 mb-0" />
        </div>
      </div>
      <Table className="">
        <Table.Header
          trClassName=" xxs:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
          columns={column.filter((x) => isLargeScreen || x.showOnMobile)}
          {...(!isLargeScreen && {
            className: 'bg-transparent xxs:p-0',
            rowClassName: 'px-0 font-normal',
          })}
        />
        <Body<any>
          columns={column.filter((x) => isLargeScreen || x.showOnMobile)}
          data={undefined}
          {...(isLargeScreen
            ? {
                elementClassName: 'xxs:dark:bg-dex-black-700',
                className: 'md:dark:bg-dex-black-800 px-0 ',
              }
            : {
                className: 'xxs:dark:bg-transparent',
                cellClassName: 'px-0',
              })}
        />
      </Table>
    </div>
  );
};

type Action = 'withdraw' | 'delete' | 'edit' | 'deposit' | 'bridge';

export function DWalletActions({
  wallet,
  className,
  chain,
}: {
  wallet: DWalletDetails;
  className?: string;
  chain: Chains;
}) {
  const [activeAction, setActiveAction] = useState<Action>();

  const handleClose = () => setActiveAction(undefined);

  return (
    <div
      className="xxs:flex xxs:gap-2 justify-end z-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <ResponsiveMenu
        dropdownPlacement="bottom-end"
        color="dexNeutral"
        className={twMerge('xxs:p-1', className)}
        label="Wallet Actions"
      >
        <ResponsiveMenuItem onClick={() => setActiveAction('deposit')}>
          <ArrowDownTrayIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Deposit</span>
        </ResponsiveMenuItem>

        <ResponsiveMenuItem onClick={() => setActiveAction('bridge')}>
          <ArrowsRightLeftIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Bridge</span>
        </ResponsiveMenuItem>

        <ResponsiveMenuItem onClick={() => setActiveAction('withdraw')}>
          <ArrowUpTrayIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Send</span>
        </ResponsiveMenuItem>

        <ResponsiveMenuItem onClick={() => setActiveAction('edit')}>
          <CogIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Edit</span>
        </ResponsiveMenuItem>
        <ResponsiveMenuItem
          onClick={() => {
            setActiveAction('delete');
          }}
        >
          <TrashIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Delete</span>
        </ResponsiveMenuItem>
      </ResponsiveMenu>

      {activeAction === 'bridge' && (
        <BridgeModal chain={chain} handleClose={handleClose} wallet={wallet} />
      )}
      {activeAction === 'withdraw' && (
        <WithdrawModal
          chain={chain}
          handleClose={handleClose}
          wallet={wallet}
        />
      )}
      {activeAction === 'delete' && (
        <DeleteDWalletModal handleClose={handleClose} wallet={wallet} />
      )}
      {activeAction === 'edit' && (
        <EditDWalletModal handleClose={handleClose} wallet={wallet} />
      )}
      {activeAction === 'deposit' && (
        <DepositEthereumProvider>
          <DepositModal
            selectedChain={chain}
            handleClose={handleClose}
            wallet={wallet}
          />
        </DepositEthereumProvider>
      )}
    </div>
  );
}

export function MobileDWalletSelector({
  onCreated,
  selectedChain,
  isError,
}: {
  onCreated: () => void;
  selectedChain: Chains;
  isError?: boolean;
}) {
  const [isCreateOpen, { open: openCreateModal, close: closeCreateModal }] =
    useToggle();
  const [{ selectionDefaultOpen }, setPage] = usePageParams();
  const { data } = useQuery({
    queryFn: () => MultiChainDWalletService.getWallets(selectedChain),
    queryKey: multiChainDWalletServiceKeys.getWallets(selectedChain),
  });

  return (
    <>
      {data?.length === 1 && (
        <IconButton variant="dexNeutral" onClick={openCreateModal}>
          <PlusIcon className="w-6" />
        </IconButton>
      )}
      {data?.length !== 1 && !isError && (
        <ResponsiveMenu
          dropdownPlacement="bottom-end"
          color="dexNeutral"
          className="xxs:p-1"
          label="My Wallets"
          defaultOpen={selectionDefaultOpen}
          trigger={({ open }) => (
            <IconButton variant="dexNeutral" onClick={open}>
              <ArrowsUpDownIcon className="w-6" />
            </IconButton>
          )}
        >
          <div className="xxs:p-2">
            <Button
              onClick={openCreateModal}
              variant="primary"
              className="w-full"
            >
              ADD NEW WALLET
            </Button>
          </div>
          {data?.map((option) => (
            <ResponsiveMenuItem
              key={option.id}
              onClick={() => {
                setPage({
                  walletAddress: option.address,
                });
              }}
            >
              <div className="flex w-full justify-between">
                <div className="max-w-[200px] truncate">{option.label}</div>
                <div className="xxs:text-dex-white">
                  {formatTokenAmount(
                    Big(option.totalEthBalance ?? 0).toNumber()
                  )}{' '}
                  {chainAsset(option.chain)} | $
                  {formatWithoutDecimals(
                    Big(option.totalUsdBalance ?? 0).toNumber()
                  )}
                </div>
              </div>
            </ResponsiveMenuItem>
          ))}
        </ResponsiveMenu>
      )}

      {isCreateOpen && (
        <CreateDWalletModal
          onCreated={(id) => {
            setPage({ walletId: id });
            onCreated();
          }}
          selectedChain={selectedChain}
          handleClose={() => {
            closeCreateModal();
          }}
        />
      )}
    </>
  );
}
