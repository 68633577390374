import { GetHotTokensParams } from 'api/contracts';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import { duration } from 'moment';
import Header from 'modules/shared-components/data-display/table/header';
import Table from 'modules/shared-components/data-display/table';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { TableRow } from './TableRow';
import { useRef } from 'react';
import { useHotTokensSearchFilters } from './usePageParams';
import { HotTokensFiltersBar } from './HotTokensFilters';
import { EmptyPageDex, ErrorPageDex } from '../shared/Common';
import { desktopColumns, mobileColumns } from './columns';
import { useChainPersistedState } from 'modules/layouts/TopBar/components/GasInfo';
import { Chains } from 'api/d-wallets';

export function thDynamicClass(s: string) {
  return s === 'PAIR' ? 'col-span-1' : '';
}

export function HotTokens() {
  const listRef = useRef<HTMLTableElement>(null);
  const { filtersState, setSort } = useHotTokensSearchFilters();
  const [selectedChain] = useChainPersistedState(Chains.Base);

  const isSortAscending = !filtersState.sort?.includes('-');

  const params: GetHotTokensParams = {
    sort: filtersState.sort,
    address: filtersState.hotTokenAddress,
    chain: selectedChain,
    period: filtersState.period,
    minMc: filtersState.minMc,
    maxMc: filtersState.maxMc,
    minVolume: filtersState.minVolume,
    maxVolume: filtersState.maxVolume,
    minLiquidity: filtersState.minLiquidity,
    maxLiquidity: filtersState.maxLiquidity,
    poolCreatedAt: filtersState.poolCreatedAt,
  };

  const { data, error, isLoading } = useQuery({
    queryKey: contractServiceKeys.getHotTokens(params),
    queryFn: () => ContractService.getHotTokens(params),
    keepPreviousData: true,
    refetchInterval: duration(12, 'seconds').asMilliseconds(),
  });

  const isLargeScreen = useMediaQuery(screenGte.large);

  const columns = isLargeScreen
    ? desktopColumns(filtersState.period)
    : mobileColumns();

  return (
    <div className="space-y-2">
      <div className="xxs:px-2 lg:px-0">
        <HotTokensFiltersBar />
      </div>

      {error ? (
        <ErrorPageDex />
      ) : (
        <div className="overflow-x-auto">
          <div className="w-full  lg:min-w-[1300px] ">
            {!isLargeScreen && <div ref={listRef} />}
            <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
              <Header
                trClassName="lg:grid lg:grid-cols-9 lg:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
                columns={columns}
                thDynamicClass={thDynamicClass}
                rowClassName="xxs:p-1"
                isSortAscending={isSortAscending}
                sortBy={filtersState.sort?.replace('-', '')}
                className="lg:dark:bg-dex-black-800 xxs:text-xs lg:grid"
                onClickColumn={(_, sortableKey) => {
                  if (sortableKey && !isLoading) setSort(sortableKey);
                }}
              />
              <tbody className="lg:lg:block lg:h-terminal overflow-y-auto">
                {isLoading ? (
                  [...Array(10).keys()]
                    .map((i) => i + 1)
                    .map((index) => (
                      <Table.LoadingRow
                        thDynamicClass={thDynamicClass}
                        key={index}
                        elementClassName="xxs:dark:bg-dex-black-700"
                        className="lg:grid lg:grid-cols-9 lg:dark:bg-dex-black-800"
                        columns={columns}
                      />
                    ))
                ) : data?.length === 0 ? (
                  <EmptyPageDex
                    height={isLargeScreen ? '100px' : '50vh'}
                    title="No Hot Tokens Found"
                  />
                ) : (
                  data?.map((item) => (
                    <TableRow
                      key={item.poolId + '_' + item.chain}
                      data={item}
                      columnsDef={columns}
                    />
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}
