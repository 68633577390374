import {
  ContractInfo,
  GetHotTokensParams,
  HotTokensSortKeys,
  PoolInfo,
} from 'api/contracts';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import { AddToFavouritesButton } from '../../../components';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { ContractAlerts } from '../../../components/ContractAlerts';
import { StackedIcons } from '../../../components/ContractIcons';
import { ExchangeIconStretch } from 'modules/shared-components/exchange/exchange-icon';
import { AssetIconStretch } from 'modules/shared-components/asset/asset-icon';
import { ResponsiveSelect } from 'modules/shared-components/forms/select/ResponsiveSelect';
import { useUserState } from 'modules/user/UserContext';
import Big from 'big.js';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as DextoolsIcon } from 'assets/img/brands/dextools.svg';
import dexScreenerImg from 'assets/img/brands/dexscreener.svg';
import { ReactComponent as TwitterIcon } from 'assets/img/brands/twitter.svg';
import { StatsCard, StatsCardTitle, StatsCardValue } from '../StatsCard';
import {
  chainIsOnWebacy,
  Chains,
  chainScan,
  chainScanImg,
  dexToolLink,
} from 'api/d-wallets';
import { useQuery } from '@tanstack/react-query';
import {
  candleStickKeys,
  CandleStickService,
} from 'api/services/CandleSticksService';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import { duration } from 'moment';
import { HotTokensPeriods } from '../Wallets/usePageParams';
import WebacyIcon from '../../../../../assets/img/brands/webacy.jpg';
import Button from 'modules/shared-components/button/button';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { WhitelistButton } from '../SnipingOpportunitiesCard/columns';
import { useToggle } from 'modules/utils/useToggle';

type Props = {
  contract: ContractInfo;
  selectedPool?: PoolInfo;
  setPoolAddress: (address: string) => void;
};

export function TokenCardHeader({
  contract,
  selectedPool,
  setPoolAddress,
}: Props) {
  return (
    <div className="xxs:flex xxs:flex-col xxs:gap-1 md:gap-0">
      <div className=" dark:text-black-50  xxs:flex xxs:flex-col md:flex-row xxs:gap-2 md:gap-0 justify-between xxs:mb-0">
        <div className="xxs:block md:hidden">
          <ContractAlerts contract={contract} />
        </div>
        <div className="overflow-x-hidden">
          <TokenNameHeader contract={contract} selectedPool={selectedPool} />
          <TokenInfoHeader contract={contract} selectedPool={selectedPool} />
        </div>
        {selectedPool && (
          <ResponsiveSelect<string>
            className="sm:min-w-[300px]"
            value={selectedPool.poolAddress || null}
            modalLabel={'Select Pool'}
            onChange={(option) => {
              setPoolAddress(option.value);
            }}
            options={contract.liquidityInfo.activePools.map((pool) => ({
              label: (
                <span>
                  {pool.pair} - L $
                  {formatNumberWithSuffix(pool.poolLiquidity || 0)}
                </span>
              ),
              value: pool.poolAddress,
              icon: (
                <StackedIcons>
                  <AssetIconStretch
                    className="h-[25px]"
                    imgName={
                      pool.pair
                        .split('/')
                        .find((x) => x !== contract.generalInfo.symbol)!
                    }
                  />
                  <ExchangeIconStretch
                    className="h-[25px]"
                    imgName={pool.dex.key}
                  />
                </StackedIcons>
              ),
            }))}
          />
        )}
      </div>

      <TokenPoolInfoHeader contract={contract} selectedPool={selectedPool} />
    </div>
  );
}

export function TokenPoolInfoHeader({
  contract,
  selectedPool,
}: {
  contract: ContractInfo;
  selectedPool: PoolInfo | undefined;
}) {
  const params: GetHotTokensParams = {
    sort: HotTokensSortKeys.TotalBuyTop,
    address: contract.generalInfo.address,
    chain: contract.generalInfo.chain.key,
    period: HotTokensPeriods.TwentyFourHour,
  };

  const { data: poolsInfoFromBe, error: poolsInfoErrorFromBe } = useQuery({
    queryKey: contractServiceKeys.getHotTokens(params),
    queryFn: () => ContractService.getHotTokens(params),
    keepPreviousData: true,
    refetchInterval: duration(12, 'seconds').asMilliseconds(),
  });

  const { data: poolsInfoFromCg } = useQuery({
    queryFn: () =>
      CandleStickService.getPoolStats(
        contract.generalInfo.chain.key,
        selectedPool ? selectedPool.poolAddress : ''
      ),
    queryKey: candleStickKeys.getPoolStats(
      contract.generalInfo.chain.key,
      selectedPool ? selectedPool.poolAddress : ''
    ),
    enabled: !!selectedPool && !!poolsInfoErrorFromBe,
  });

  const poolPriceData =
    poolsInfoFromBe && poolsInfoFromBe.length > 0
      ? {
          change24hPct: Big(poolsInfoFromBe[0].priceChange ?? 0).mul(100),
          volume24h: poolsInfoFromBe[0].volume,
        }
      : poolsInfoFromCg
      ? {
          change24hPct: poolsInfoFromCg.change24hPct,
          volume24h: poolsInfoFromCg.volume24h,
        }
      : undefined;

  return (
    <>
      <div className="flex xxs:gap-2 items-center xxs:mt-1">
        {selectedPool?.priceDollar && (
          <div className="xxs:text-lg xxs:leading-6 xxs:hidden lg:block">
            <span className=" xxs:font-semibold">
              ${formatNumberWithSuffix(selectedPool.priceDollar)}
            </span>{' '}
            {poolPriceData && (
              <span
                className={twMerge(
                  'xxs:text-xs xxs:font-normal',
                  Big(poolPriceData.change24hPct).gt(0)
                    ? 'text-green-500'
                    : 'text-red-500'
                )}
              >
                {Big(poolPriceData.change24hPct).gt(0) ? '+' : ''}
                {Big(poolPriceData.change24hPct).toFixed(2)}%
              </span>
            )}
          </div>
        )}
        {poolPriceData?.volume24h && (
          <div className="xxs:hidden lg:block">
            <span className="xxs:text-xs xxs:text-dex-white-secondary">
              24h vol:{' '}
            </span>
            <span className="xxs:text-lg xxs:font-semibold">
              $
              {formatNumberWithSuffix(poolPriceData?.volume24h, {
                precision: 0,
                suffixes: allSuffixes,
              })}
            </span>
          </div>
        )}
        {selectedPool?.poolLiquidity && (
          <div className="xxs:hidden lg:block">
            <span className="xxs:text-xs xxs:text-dex-white-secondary">
              Liq:{' '}
            </span>
            <span className="xxs:text-lg xxs:font-semibold">
              $
              {formatNumberWithSuffix(selectedPool?.poolLiquidity, {
                precision: 0,
                suffixes: allSuffixes,
              })}
            </span>
          </div>
        )}
        {contract.generalInfo.totalMarketCap && (
          <div className="xxs:hidden lg:block">
            <span className="xxs:text-xs xxs:text-dex-white-secondary">
              M.Cap:{' '}
            </span>
            <span className="xxs:text-lg xxs:font-semibold">
              {formatNumberWithSuffix(contract.generalInfo.totalMarketCap, {
                precision: 0,
                suffixes: allSuffixes,
              })}
            </span>
          </div>
        )}
        {selectedPool?.liquidityMarketCapRatio && (
          <div className="xxs:hidden lg:block">
            <span className="xxs:text-xs xxs:text-dex-white-secondary">
              Liq/M. Cap:{' '}
            </span>
            <span className="xxs:text-lg xxs:font-semibold">
              {Big(selectedPool?.liquidityMarketCapRatio).times(100).toFixed(1)}
              %
            </span>
          </div>
        )}
      </div>
      <div className="xxs:grid grid-cols-2 lg:hidden xxs:gap-1 items-center">
        {selectedPool?.priceDollar && (
          <StatsCard className="">
            <StatsCardValue>
              <span className="xxs:text-lg  xxs:font-semibold">
                ${formatNumberWithSuffix(selectedPool.priceDollar)}
              </span>{' '}
              {poolPriceData && (
                <span
                  className={twMerge(
                    'xxs:text-xs xxs:font-normal',
                    Big(poolPriceData.change24hPct).gt(0)
                      ? 'text-green-500'
                      : 'text-red-500'
                  )}
                >
                  {Big(poolPriceData.change24hPct).gt(0) ? '+' : ''}
                  {Big(poolPriceData.change24hPct).toFixed(2)}%
                </span>
              )}
            </StatsCardValue>
            <StatsCardTitle>Price</StatsCardTitle>
          </StatsCard>
        )}
        {poolPriceData?.volume24h && (
          <StatsCard className="">
            <StatsCardValue className="xxs:text-lg xxs:font-semibold">
              $
              {formatNumberWithSuffix(poolPriceData?.volume24h, {
                precision: 0,
                suffixes: allSuffixes,
              })}
            </StatsCardValue>
            <StatsCardTitle>24h vol</StatsCardTitle>
          </StatsCard>
        )}
        {selectedPool?.poolLiquidity && (
          <StatsCard className="">
            <StatsCardValue className="xxs:text-lg xxs:font-semibold">
              $
              {formatNumberWithSuffix(selectedPool?.poolLiquidity, {
                precision: 0,
                suffixes: allSuffixes,
              })}
            </StatsCardValue>
            <StatsCardTitle>Liq</StatsCardTitle>
          </StatsCard>
        )}
        {contract.generalInfo.totalMarketCap && (
          <StatsCard className="">
            <StatsCardValue className="xxs:text-lg xxs:font-semibold">
              {formatNumberWithSuffix(contract.generalInfo.totalMarketCap, {
                precision: 0,
                suffixes: allSuffixes,
              })}
            </StatsCardValue>
            <StatsCardTitle>M.Cap</StatsCardTitle>
          </StatsCard>
        )}
      </div>
    </>
  );
}

export function TokenNameHeader({
  contract,
  selectedPool,
}: {
  contract: ContractInfo;
  selectedPool: PoolInfo | undefined;
}) {
  const { user } = useUserState();
  const [openModal, { open, close }] = useToggle();

  return (
    <span className="xxs:flex xxs:gap-2 items-center">
      <div className="relative">
        <TokenIconWithChain
          chain={contract.generalInfo.chain.key}
          protocol={selectedPool?.dex?.key}
          tokenName={contract.generalInfo.name ?? '?'}
          className="h-[25px] w-[25px]"
          address={contract.generalInfo.address}
          symbol={contract.generalInfo.symbol || undefined}
        />
        {selectedPool && (
          <ExchangeIconStretch
            imgName={selectedPool.dex.key}
            className="w-2.5 h-2.5 absolute -top-0.5 -right-0.5"
          />
        )}
      </div>
      <div className="truncate">
        {' '}
        <span className=" whitespace-nowrap max-w-md xxs:text-3xl xxs:font-semibold">
          {contract.generalInfo.name}{' '}
        </span>
        <span className="whitespace-nowrap xxs:w-[120px] max-w-xs md:w-auto  xxs:text-xl xxs:text-dex-white-secondary xxs:font-semibold">
          {contract.generalInfo.symbol}
        </span>
      </div>
      {user && (
        <AddToFavouritesButton
          chain={contract.generalInfo.chain.key}
          contractAddress={contract.generalInfo.address}
          className="xxs:w-8 xxs:h-8 flex-shrink-0"
        />
      )}
      {user && (
        <Button
          type="button"
          onClick={open}
          className="xxs:p-1"
          variant="dexNeutral"
        >
          <ChartBarSquareIcon className="xxs:w-5 xxs:h-5" />
        </Button>
      )}
      {openModal && (
        <WhitelistButton
          close={close}
          symbol={contract.generalInfo.symbol ?? ''}
          tokenAddress={contract.generalInfo.address}
        />
      )}
      <div className="xxs:hidden md:block">
        <ContractAlerts contract={contract} />
      </div>
    </span>
  );
}

export function TokenInfoHeader({
  contract,
  selectedPool,
}: {
  contract: ContractInfo;
  selectedPool: PoolInfo | undefined;
}) {
  return (
    <div className="flex xxs:gap-2 items-center">
      {contract.generalInfo.address && (
        <div className="xxs:text-sm xxs:text-dex-white-secondary xxs:font-normal flex items-center xxs:gap-1">
          Token:{' '}
          <EthAddress
            chain={contract.generalInfo.chain.key}
            address={contract.generalInfo.address}
            kind="token"
          />
        </div>
      )}
      {selectedPool?.poolAddress && (
        <div className="xxs:text-sm xxs:text-dex-white-secondary xxs:font-normal flex items-center xxs:gap-1">
          Pair:{' '}
          <EthAddress
            chain={contract.generalInfo.chain.key}
            address={selectedPool.poolAddress}
          />
        </div>
      )}
      <ExternalInfo
        chain={contract.generalInfo.chain.key}
        contract={contract}
        selectedPool={selectedPool}
      />
    </div>
  );
}

export function ExternalInfo({
  selectedPool,
  contract,
  chain,
}: {
  contract: ContractInfo | undefined;
  selectedPool: PoolInfo | undefined;
  chain: Chains;
}) {
  return (
    <div className="flex xxs:gap-1 text-[1rem] leading-none">
      {selectedPool && (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://dexscreener.com/${
            chain === Chains.Ethereum ? 'ethereum' : chain
          }/${selectedPool.poolAddress}`}
        >
          <img
            src={dexScreenerImg}
            alt="dexscreener logo"
            className="w-4 h-4"
          />
        </a>
      )}
      {selectedPool && (
        <a
          className="hidden lg:block"
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.dextools.io/app/${dexToolLink(
            chain
          )}/pair-explorer/${selectedPool.poolAddress}`}
        >
          <DextoolsIcon className="w-4 h-4" />
        </a>
      )}

      {selectedPool && (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://${chainScan(chain)}/address/${
            selectedPool?.poolAddress
          }`}
        >
          <img src={chainScanImg(chain)} alt="scan img" className="w-4 h-4" />
        </a>
      )}

      {contract && (
        <a
          rel="noopener noreferrer"
          className="hidden lg:block"
          target="_blank"
          href={`https://twitter.com/search?q=$${contract.generalInfo.address}`}
        >
          <TwitterIcon className="w-4 h-4 xxs:bg-black dark:bg-white rounded-full p-[3px] dark:fill-black fill-white" />
        </a>
      )}

      {contract && chainIsOnWebacy(contract?.generalInfo.chain.key) && (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://dapp.webacy.com/dyor/address/${contract?.generalInfo.address}?chain=${contract?.generalInfo.chain.key}`}
        >
          <img src={WebacyIcon} className="w-4 h-4" />
        </a>
      )}
    </div>
  );
}
