import { Chain, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi';
import { Button } from 'modules/shared-components/button/button';
import CustomModal from 'modules/shared-components/modal/CustomModal';
import { Notification } from 'modules/shared-components/notification/notification';

interface Props {
  chain: Chain;
}

export function ChainSelector({ chain: requiredChain }: Props) {
  const { error, isLoading, switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();

  return (
    <>
      <CustomModal.Body>
        <CustomModal.Title className="xxs:text-3xl">
          Change network
        </CustomModal.Title>

        <CustomModal.Content className="flex flex-col xxs:gap-4 xxs:mb-6 xxs:text-base dark:text-white-50">
          <span>{`You are connected to ${
            chain?.name || ''
          } network. Please connect to the 
          ${requiredChain.name} network to continue.`}</span>
          {error && (
            <Notification type="error">
              Could not switch chain, you might need to switch to{' '}
              {requiredChain.name} manually in your wallet before continuing to
              next step.
            </Notification>
            // Due to https://github.com/MetaMask/metamask-extension/issues/18509
            // <Notification type="error">{formatWagmiError(error)}</Notification>
          )}
        </CustomModal.Content>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button
          variant="primary"
          loading={isLoading}
          onClick={() => switchNetwork?.(requiredChain.id)}
        >
          Connect to {requiredChain.name}
        </Button>
        <Button variant="outline" onClick={() => disconnect()}>
          Back
        </Button>
      </CustomModal.Footer>
    </>
  );
}
