import { PoolStatus } from 'api/contracts';
import { useQueryParams } from 'modules/routing/useQueryParams';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { isEmpty, omit } from 'lodash/fp';
import { toQueryObject } from 'modules/routing/query-string';

const PAGE_SIZE = 20;
const schema = {
  limit: yup.number().default(PAGE_SIZE),
  offset: yup.number().default(0),
  status: yup.array(yup.string<PoolStatus>().required()),
  sort: yup.string().default('-createdAt'),
  minLiquidity: yup.number(),
  maxLiquidity: yup.number(),
  minMarketcap: yup.number(),
  maxMarketcap: yup.number(),
  activeTab: yup.string().required().default('new-pools'),
};

export function usePageParams() {
  return useQueryParams(schema);
}

export type PoolFilterState = yup.TypeFromShape<typeof schema, any>;

export const CACHE_KEY_NEW_POOLS = 'poolFilters-v4';

export function usePoolSearchFilters() {
  const [filtersState, setFilters] = usePageParams();
  const setFiltersWithCache = useFiltersWithCache(
    setFilters,
    {
      limit: PAGE_SIZE,
      offset: 0,
      sort: '-createdAt',
      status: ['SNIPEABLE'],
    },
    (queryObj) => isEmpty(omit(['searchDefaultOpen'], queryObj))
  );

  const goToPage = (page: number) => {
    setFilters({
      ...filtersState,
      offset: (page - 1) * filtersState.limit,
    });
  };

  const setSort = (key: string) => {
    let newSortKey;
    if (filtersState.sort?.includes(key)) {
      if (filtersState.sort?.charAt(0) === '-') {
        newSortKey = key;
      } else {
        newSortKey = `-${key}`;
      }
    }
    setFiltersWithCache({
      ...filtersState,
      offset: 0,
      sort: newSortKey || key,
    });
  };

  return { filtersState, setFilters: setFiltersWithCache, goToPage, setSort };
}

function useFiltersWithCache(
  setFilters: (filters: PoolFilterState) => void,
  defaults: Partial<PoolFilterState> = {},
  shouldApplyCache: (filters: PoolFilterState) => boolean = () => true
) {
  const location = useLocation();

  useEffect(() => {
    if (
      shouldApplyCache(
        toQueryObject(location.search) as unknown as PoolFilterState
      )
    ) {
      const fromCache = window.localStorage.getItem(CACHE_KEY_NEW_POOLS);

      setFilters(
        fromCache
          ? { ...JSON.parse(fromCache), limit: PAGE_SIZE, offset: 0 }
          : defaults
      );
    }
  }, [location.search]);

  return function cacheAndSetFilters(filters: PoolFilterState) {
    window.localStorage.setItem(CACHE_KEY_NEW_POOLS, JSON.stringify(filters));
    setFilters(filters);
  };
}
