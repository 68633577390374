import { ContractInfo } from 'api/contracts';

import { ListItem, ListItemLabel, ListItemValue } from '../../shared/Common';
import {
  MobileExpandCardTrigger,
  MobileExpandableCard,
} from 'modules/shared-components/MobileExpandableCard';
import {
  ExpandableCardContent,
  SubCardTitle,
} from '../../../../components/cards';
import Tooltip from 'modules/shared-components/tooltip';
import Big from 'big.js';
import { calculateLimits } from '../../../../components/TokenTradeInfoHeader';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import moment from 'moment';

type Props = { contract: ContractInfo } & React.ComponentProps<
  typeof SubCardTitle
>;

export function ContractSettings({ contract }: Props) {
  if (contract.methodsInfo.methods.length === 0) {
    return null;
  }

  return (
    <MobileExpandableCard
      mobileLabel="Contract Settings"
      trigger={
        <MobileExpandCardTrigger>
          <div>Contract Settings</div>
        </MobileExpandCardTrigger>
      }
    >
      <div className="xxs:p-2">
        <ExpandableCardContent className="xxs:space-y-2">
          <ListItem>
            <ListItemLabel>
              <Tooltip text="The tax applied to any buy / sell orders">
                <div className="xxs:text-dex-white-secondary">Taxes</div>
              </Tooltip>
            </ListItemLabel>
            <ListItemValue>
              {contract.tradingInfo.buyTax || contract.tradingInfo.sellTax ? (
                <div className="font-bold">
                  B:{' '}
                  {contract.tradingInfo.buyTax ? (
                    <span>
                      {Big(contract.tradingInfo.buyTax).times(100).toFixed(1)}%
                    </span>
                  ) : (
                    <span>--</span>
                  )}{' '}
                  | S:{' '}
                  {contract.tradingInfo.sellTax ? (
                    <span
                      className={
                        Big(contract.tradingInfo.sellTax).gt(0.1)
                          ? 'text-red-500'
                          : ''
                      }
                    >
                      {Big(contract.tradingInfo.sellTax).times(100).toFixed(1)}%
                    </span>
                  ) : (
                    <span>--</span>
                  )}
                </div>
              ) : (
                <>--</>
              )}
            </ListItemValue>
          </ListItem>
          <ListItem>
            <ListItemLabel>
              <Tooltip text="The maximum amount of tokens a wallet address can buy / sell / hold">
                <div className="xxs:text-dex-white-secondary"> Max Limits</div>
              </Tooltip>
            </ListItemLabel>
            <ListItemValue>
              {contract.tradingInfo.maxBuy ||
              contract.tradingInfo.maxSell ||
              contract.tradingInfo.maxWallet ? (
                <div className="font-bold">
                  B{' '}
                  {calculateLimits({
                    totalSupply: contract.generalInfo.totalSupply,
                    max: contract.tradingInfo.maxBuy,
                  })}{' '}
                  | S{' '}
                  {calculateLimits({
                    totalSupply: contract.generalInfo.totalSupply,
                    max: contract.tradingInfo.maxSell,
                  })}{' '}
                  | W{' '}
                  {calculateLimits({
                    totalSupply: contract.generalInfo.totalSupply,
                    max: contract.tradingInfo.maxWallet,
                  })}{' '}
                </div>
              ) : (
                <div className="font-bold">--</div>
              )}
            </ListItemValue>
          </ListItem>
          <ListItem>
            <ListItemLabel>
              <Tooltip text="The maximum amount of tokens a wallet address can buy / sell / hold">
                <div className="xxs:text-dex-white-secondary">
                  {' '}
                  Total supply
                </div>
              </Tooltip>
            </ListItemLabel>
            <ListItemValue>
              {contract.generalInfo.totalSupply ? (
                <div className="font-bold">
                  {formatNumberWithSuffix(contract.generalInfo.totalSupply)}{' '}
                  {contract.generalInfo.symbol}
                </div>
              ) : (
                <>--</>
              )}
            </ListItemValue>
          </ListItem>
          <ListItem>
            <ListItemLabel>
              <Tooltip text="The decimals of the contract">
                <div className="xxs:text-dex-white-secondary">Decimals</div>
              </Tooltip>
            </ListItemLabel>
            <ListItemValue>
              <div className="font-bold">
                {contract.generalInfo.decimals ?? '--'}
              </div>
            </ListItemValue>
          </ListItem>
          <ListItem>
            <ListItemLabel>
              <Tooltip text="The deployment date and time of the contract">
                <div className="xxs:text-dex-white-secondary">
                  Creation date
                </div>
              </Tooltip>
            </ListItemLabel>
            <ListItemValue>
              {contract.generalInfo.creationDatetime ? (
                <span className="font-bold">
                  {moment(contract.generalInfo.creationDatetime).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}
                </span>
              ) : (
                <span>--</span>
              )}
            </ListItemValue>
          </ListItem>
        </ExpandableCardContent>
      </div>
    </MobileExpandableCard>
  );
}
