import Checkbox from 'modules/shared-components/input/Checkbox';

export default function CheckboxInput({
  name,
  label,
  description = '',
  ...props
}) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <Checkbox
          id={name}
          aria-describedby={`${name}-description`}
          name={name}
          {...props}
        />
      </div>
      {label && (
        <div className="ml-3 text-sm">
          <label
            htmlFor={name}
            className="font-medium text-gray-700 dark:text-white-400"
          >
            {label}
          </label>
          <p id={`${name}-description`} className="text-gray-500">
            {description}
          </p>
        </div>
      )}
    </div>
  );
}
