/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableColumn } from 'modules/shared-components/data-display/table/types';
import {
  formatNumberWithSuffix,
  formatWithoutDecimals,
} from 'utils/FormatNumber';
import { WalletRowInfo, WalletsTableSortKeys } from 'api/contracts';
import {
  formatDuration,
  formatMomentDuration,
} from 'modules/utils/formatDuration';
import {
  ArrowsRightLeftIcon,
  ClockIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { twMerge } from 'tailwind-merge';
import Big from 'big.js';
import { chainAsset } from 'api/d-wallets';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { PercentageChangeWithMaxValue } from '../TelegramCalls/columns';
import { duration } from 'moment';
import Tooltip from 'modules/shared-components/tooltip';
import { labelIcon, labelText, TokenLabels } from './labels';
import { WalletPeriods } from './usePageParams';

export function performanceStyle(
  pnl: string,
  className?: string,
  asset?: string
) {
  return (
    <div className={twMerge('xxs:text-xs', className)}>
      <span>
        <span
          className={twMerge(
            Big(pnl).gt(0)
              ? 'xxs:text-green-500'
              : Big(pnl).lt(0)
              ? 'xxs:text-red-500'
              : ''
          )}
        >
          {' '}
          {Big(pnl).gt(0) ? '+' : Big(pnl).lt(0) ? '-' : ''}
          {!asset ? '$' : ''}
          {formatNumberWithSuffix(Big(pnl ?? 0).gt(0) ? +pnl : -pnl, {
            precision: 2,
          })}
          {asset ? <span> {asset}</span> : ''}{' '}
        </span>
      </span>
    </div>
  );
}

export const walletLabels = (
  data: Partial<WalletRowInfo>,
  period: WalletPeriods
) => {
  return (
    <div className="flex space-x-1 items-center text-xs">
      {data.walletLabels?.map((label) => {
        return label ? (
          <Tooltip text={labelText(label, period)}>
            <div>{labelIcon(label)}</div>
          </Tooltip>
        ) : null;
      })}
    </div>
  );
};

export const desktopColumns = (
  period: WalletPeriods
): TableColumn<WalletRowInfo>[] => {
  return [
    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The wallet address, chain, and main labels">
            <div>Address</div>
          </Tooltip>
        </div>
      ),
      key: 'ADDRESS',
      component: ({ data }) => (
        <div className="flex items-center xxs:gap-2">
          <div className="relative">
            <ChainsIcon imgName={data.chain} />
          </div>
          <div className="max-w-[120px] overflow-hidden truncate">
            <EthAddress
              className="text-xs"
              address={data.address}
              precision={3}
              kind="account"
              chain={data.chain}
            />
          </div>
          {walletLabels(data, period)}
        </div>
      ),
    },
    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The current wallet balance in quote asset.">
            <div>Balance</div>
          </Tooltip>
        </div>
      ),
      key: 'BALANCE',
      sortableKey: WalletsTableSortKeys.Balance,
      component: ({ data }) => (
        <div className="text-xs">
          {formatNumberWithSuffix(Big(data.balance ?? 0).toNumber())}{' '}
          {chainAsset(data.chain)}
        </div>
      ),
    },
    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The total value of tokens swapped in the last 30 days, expressed in USD.">
            <div>Volume</div>
          </Tooltip>
        </div>
      ),
      key: 'VOLUME',
      sortableKey: WalletsTableSortKeys.Volume,
      component: ({ data }) => (
        <div className="text-xs">
          ${formatNumberWithSuffix(Big(data.volume ?? 0).toNumber())}{' '}
        </div>
      ),
    },
    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The total amount of unique tokens categorized by the swap type (snipe, early ape, trade).">
            <div>Tokens</div>
          </Tooltip>
        </div>
      ),
      key: 'UNIQUE_TOKENS',
      sortableKey: WalletsTableSortKeys.UniqueTokens,
      component: ({ data }) => TokenLabels(data, period),
    },

    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The total profit and loss of the wallet in the last 30 days (realized + unrealized PnL).">
            <div>PnL</div>
          </Tooltip>
        </div>
      ),
      key: 'PNL',
      sortableKey: WalletsTableSortKeys.PnL,
      component: ({ data }) => (
        <div className="xxs:text-dex-white-secondary">
          {performanceStyle(data.pnl)}
        </div>
      ),
    },

    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The total returns of the wallet in the last 30 days, calculated as the P&L in the quote asset (e.g., ETH) divided by the maximum value of tokens held (in ETH) during the period.">
            <div>Returns</div>
          </Tooltip>
        </div>
      ),
      key: 'RETURNS',
      sortableKey: WalletsTableSortKeys.Returns,
      component: ({ data }) => (
        <div className="xxs:text-dex-white-secondary">
          <PercentageChangeWithMaxValue
            className="xxs:text-xs"
            maxValue={999}
            change={Big(data.returns ?? 0)}
          />
        </div>
      ),
    },

    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The total number of wins vs the amount of unique tokens swapped.">
            <div>Wins</div>
          </Tooltip>
        </div>
      ),
      key: 'N_OF_WINS',
      sortableKey: WalletsTableSortKeys.NOfWins,
      component: ({ data }) => (
        <div className="xxs:text-xs">
          {formatWithoutDecimals(data.nOfWins)}/
          {formatWithoutDecimals(data.uniqueTokens)}
        </div>
      ),
    },
    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The total number of rug-pulls.">
            <div>Rugs</div>
          </Tooltip>
        </div>
      ),
      key: 'RUGS',
      sortableKey: WalletsTableSortKeys.Scams,
      component: ({ data }) => (
        <div className="xxs:text-xs">
          {data.honeypots ? (
            <Tooltip
              text={`Number of scams: tokens purchased that were scams or performed a rug-pull, in the last ${period} days.`}
            >
              <div>
                🚨 {formatWithoutDecimals(Big(data.honeypots).toNumber())}
              </div>
            </Tooltip>
          ) : (
            <div>--</div>
          )}
        </div>
      ),
    },

    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The average holding time for each coin is calculated as the duration between the first purchase and the point at which more than 90% of the maximum holding has been sold.">
            <div>Holding Time</div>
          </Tooltip>
        </div>
      ),
      key: 'HOLDING_TIME',
      sortableKey: WalletsTableSortKeys.HoldingTime,
      component: ({ data }) => (
        <div className="text-xs">
          {data.avgHoldingTime
            ? formatMomentDuration(
                duration(data.avgHoldingTime ?? 0, 'seconds')
              )
            : '--'}
        </div>
      ),
    },
    {
      label: (
        <div className="hover:text-dex-white xxs:text-dex-white-secondary">
          <Tooltip text="The last time the wallet has swapped a token. This information is important to understand the activity of the wallet.">
            <div>Last Swap</div>
          </Tooltip>
        </div>
      ),
      key: 'LAST_SWAP',
      sortableKey: WalletsTableSortKeys.LastSwap,
      component: ({ data }) => (
        <div className="flex lg:flex-col space-x-2 lg:space-x-0 lg:space-y-1 items-center ">
          <div className="self-start flex space-x-1 items-center text-xs">
            <ClockIcon className="h-4 w-4" />
            <div>{formatDuration(data.lastSwap ?? '0')}</div>
          </div>
        </div>
      ),
    },
  ];
};

export const mobileColumns = (
  period: WalletPeriods
): TableColumn<WalletRowInfo>[] => {
  return [
    {
      key: 'WALLETS',
      component: ({ data }) => (
        <div className="flex justify-between items-center">
          <div className="space-y-1">
            <div className="flex items-center space-x-1">
              <div className="xxs:text-dex-white-secondary">
                <div className="flex items-center xxs:gap-2">
                  <div className="relative">
                    <ChainsIcon imgName={data.chain} />
                  </div>
                  <div className="max-w-[120px] overflow-hidden truncate">
                    <EthAddress
                      address={data.address}
                      precision={3}
                      kind="account"
                      chain={data.chain}
                    />
                  </div>
                </div>
              </div>
              {walletLabels(data, period)}
            </div>
            <div className="flex space-x-1 items-center">
              <WalletIcon className="w-4 h-4" />
              <div className="text-xs">
                {formatNumberWithSuffix(Big(data.balance ?? 0).toNumber())}{' '}
                {chainAsset(data.chain)}
              </div>
              <ArrowsRightLeftIcon className="w-4 h-4" />
              <div className="text-xs">
                ${formatNumberWithSuffix(Big(data.volume ?? 0).toNumber())}{' '}
              </div>
              {data.honeypots ? (
                <Tooltip
                  text={`Number of scams: tokens purchased that were scams or performed a rug-pull, in the last ${period} days.`}
                >
                  <div className="text-xs">
                    🚨 {formatWithoutDecimals(Big(data.honeypots).toNumber())}
                  </div>
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="">
            <div className="flex space-x-1 items-center">
              <div className=" text-white-700">
                {performanceStyle(data.pnl, 'font-bold xxs:text-lg')}
              </div>
              <PercentageChangeWithMaxValue
                className="xxs:text-xs xxs:pt-1"
                maxValue={999}
                change={Big(data.returns ?? 0)}
              />
            </div>
            <div className="justify-end flex">{TokenLabels(data, period)}</div>
          </div>
        </div>
      ),
    },
  ];
};
