import { WagmiConfig, configureChains, createConfig, Chain } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import config from 'config';
import { base, bsc } from 'viem/chains';
import { Chains } from 'api/d-wallets';

// eslint-disable-next-line @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;

type ExtendedChain = Chain & { key: Chains };

export const EXTENDED_DEPOSIT_CHAINS_CONFIG: ExtendedChain[] = [
  { ...mainnet, key: Chains.Ethereum },
  { ...base, key: Chains.Base },
  { ...bsc, key: Chains.BinanceChain },
];

export const DEPOSIT_CHAINS_CONFIG: Chain[] = [base, mainnet, bsc];

const { chains, publicClient } = configureChains(DEPOSIT_CHAINS_CONFIG, [
  publicProvider(),
]);

type Props = { children: React.ReactNode };

export const wagmiConfig = createConfig({
  autoConnect: false,
  publicClient,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: (detectedName) =>
          typeof detectedName === 'string'
            ? detectedName
            : detectedName.join(', '),
        shimDisconnect: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Mizar',
        darkMode: true,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: config.walletConnectProjectId,
        showQrModal: true,
      },
    }),
  ],
});

export function DepositEthereumProvider({ children }: Props) {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
}
