import { PaginatedList, PaginatedListWithStatus } from './common';
import { TelegramCallDetailsInfo } from './telegram-channels';
import { Chains, Protocols } from 'api/d-wallets';
import {
  WalletPeriods,
  HotTokensPeriods,
} from '../app/dex/research/components/Wallets/usePageParams';

export type ContractSearchResponse = PaginatedList<ContractSearchResult>;
export type HotContractSearchResponse = {
  data: HotContractSearchResult[];
};
export type PreviousSearches = {
  data: ContractSearchResult[];
};
export type FavouriteContractsResponse = PaginatedList<ContractSearchResult>;

export type HotContractSearchResult = {
  address: string;
  priceChange: string;
  name: string;
  symbol: string;
  chain: Chain;
  marketCap: number;
  liquidity: number;
  position: number;
  previousPosition: number;
};

export type ContractSearchResult = {
  address: string;
  name: string;
  symbol: string;
  chain: Chain;
  primaryDex: Dex | null;
  marketCap: number | null;
  price: number | null;
  liquidity: number | null;
  hasIcon: boolean;
  isSnipeable: boolean;
  tokenBaseAddress: string | null;
  tokenBaseReserves: string | null;
  tokenBaseSymbol: string | null;
  tokenQuoteAddress: string | null;
  tokenQuoteReserves: string | null;
  tokenQuoteSymbol: string | null;
  liquidityPoolCreationDatetime: string | null;
};

export interface ContractInfo {
  generalInfo: ContractGeneralInfo;
  liquidityInfo: ContractLiquidityInfo;
  tradingInfo: ContractTradingInfo;
  ownerInfo: ContractOwnerInfo;
  methodsInfo: { methods: MethodInfo[] };
  status: PoolStatus;
}

export type CallsInfo = {
  details: TelegramCallDetailsInfo[];
  numberOfCalls: number;
};

type MethodInfo = {
  name: string;
  id: string;
};
type Chain = { id: number; key: Chains; name: string };
type Dex = { id: number; key: Protocols; name: string };

export interface ContractGeneralInfo {
  chain: Chain;
  address: string;
  name: string | null;
  symbol: string | null;
  decimals: number | null;
  creationDatetime: Date | null;
  totalSupply: number | null;
  totalMarketCap: number | null;
  isVerified: boolean;
  hasIcon: boolean;
}

export interface ContractLiquidityInfo {
  activePools: PoolInfo[];
  totalPoolsCount: number;
}

export interface DexProps {
  id: number;
  key: Protocols;
  name: string;
}

export interface ChainProps {
  id: number;
  key: Chains;
  name: string;
}

export interface PoolInfo {
  dex: DexProps;
  pair: string;
  priceDollar: number | null;
  priceNative: number | null;
  poolAddress: string;
  creationDatetime: string;
  // USD
  poolLiquidity: number | null;
  liquidityMarketCapRatio: number | null;
  fee: number | null;

  // reservesToken0: number | null;
  // reservesToken1: number | null;
  token0Symbol: string;
  token1Symbol: string;
  tokenBaseContract: string;
  tokenQuoteContract: string;
  tokenBaseReserves: number | null;
  tokenQuoteReserves: number | null;
}

export interface ContractTradingInfo {
  buyTax: number | null;
  sellTax: number | null;
  // In tokens
  maxBuy: number | null;
  maxSell: number | null;
  maxWallet: number | null;
  honeypot: boolean | null;
  isSnipeable: boolean | null;
}

export type ContractInfoV2 = {
  id: string;
  status: string;
  chain: {
    key: Chains;
    name: string;
    id: number;
  };
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  totalSupply: string;
  taxBuy: string;
  taxSell: string;
  maxBuy: string;
  maxSell: string;
  maxWallet: string;
  blockNumber: number;
  txHash: string;
  creationDatetime: string;
  creator: string;
  isVerified: boolean;
  isTradingAllowed: boolean;
  proxy: boolean;
};

export interface ContractOwnerInfo {
  ownerAddress: string | null;
  // In tokens
  ownerBalance: number | null;
  ownerPercent: number | null;
  creatorAddress: string | null;
  // In tokens
  creatorBalance: number | null;
  creatorPercent: number | null;
}

export type PoolPriceData = {
  volume24h: string;
  change24hPct: string;
};

export type PoolRowInfo = {
  token0Symbol: string;
  dex: DexProps;
  chain: ChainProps;
  token1Symbol: string;
  creationDatetime: string;
  price: number | null;
  currentPrice: number | null;
  listingPrice: number | null;
  listingMarketcap: number | null;
  currentMarketcap: number | null;
  marketcap: number | null;
  totalLiquidityUsd: number | null;
  totalLiquidityUsdAtLaunch: number | null;
  ethLiquidity: number | null;
  contractSecurity: ContractSecurity;
  status: PoolStatus;
  tokenAddress: string;
  poolAddress: string;
  hasIcon: boolean;
  creationTransactionHash: string;
  numberOfCalls?: number | null;
  lastCall?: string | null;
};

export enum WalletType {
  Sniper = 'sniper',
  Early = 'early',
  Trader = 'trader',
}

export enum SwapType {
  Snipe = 'snipe',
  Early = 'early_trade',
  Trade = 'trade',
}

export enum WalletTradingStyle {
  DayTrader = 'dayTrader',
  Swinger = 'swinger',
  Holder = 'holder',
}

export enum WalletKeys {
  Scammer = 'scammer',
  Suspicious = 'suspicious',
  TopCopy = 'top_copy',
  ScamAddicted = 'rug_addicted',
  Trader = 'top_trader_max',
  EarlyApe = 'top_early_ape_max',
  TopSniper = 'top_sniper_max',
}

export enum WalletMarkets {
  OneMillion = '1m',
  TenMillions = '10m',
  HundredMillions = '100m',
  OneBillion = '1b',
  Max = 'max',
}

export type WalletRowInfo = {
  address: string;
  chain: Chains;
  balance: string;
  volume: string;
  traderType: WalletType;
  returns: string;
  pnl: string;
  realizedPnl: string;
  unrealizedPnl: string;
  totalSwaps: number;
  totalBuys: number;
  totalSells: number;
  uniqueTokens: number;
  nOfWins: number;
  realizedPnl1b: string;
  realizedPnl1m: string;
  realizedPnl10m: string;
  realizedPnl100m: string;
  realizedPnlMax: string;
  nTokensBought1b: string;
  nTokensBought1m: string;
  nTokensBought10m: string;
  nTokensBought100m: string;
  nTokensBoughtMax: string;
  volume1b: string;
  volume1m: string;
  volume10m: string;
  volume100m: string;
  volumeMax: string;
  trades: number;
  earlyTrades: number;
  snipes: number;
  honeypots: number;
  avgHoldingTime: number | null;
  firstSwap: string | null;
  lastSwap: string | null;
  walletLabels: WalletKeys[];
};

export type HotTokensRowInfo = {
  suspiciousBuy: number;
  rugAddictedBuy: number;
  scammerBuy: number;
  freshBuy: number;
  chain: Chains;
  poolCreatedAt: string;
  dex: Protocols;
  quote: string;
  base: string;
  status: string;
  tokenAddress: string;
  poolId: string;
  poolAddress: string;
  totalBuyTop: number;
  totalSellsTop: number;
  totalBuy: number;
  buyVolumeTop: string;
  buyVolume: string;
  totalSells: number;
  sellVolume: string;
  sellVolumeTop: string;
  totalHolders: number;
  volume: string;
  topSniperBuy: number;
  topEarlyApeBuy: number;
  topTraderBuy: number;
  topSniperSell: number;
  topEarlyApeSell: number;
  topTraderSell: number;
  totalTopHolder: number;
  topSniperHolder: number;
  topEarlyApeHolder: number;
  topTraderHolder: number;
  totalBadHolder: number;
  riskyWalletHolder: number;
  suspiciousWalletHolder: number;
  currentPrice: string;
  currentLiquidity: string;
  currentMarketCap: string;
  priceChange: string;
  weightedAverageBuyPrice: string | null;
  weightedAverageSellPrice: string | null;
  weightedAverageBuyPriceTop: string | null;
  weightedAverageSellPriceTop: string | null;
  netInflowTop: string;
  netInflow: string;
  volatilityIndex: string;
};

export type SwapsDetails = {
  makerAddress: string;
  transactionHash: string;
  swapDirection: 'buy' | 'sell';
  chain: Chains;
  tokenAddress: string;
  tokenBaseSymbol: string;
  tokenQuoteSymbol: string;
  dex: Protocols;
  tokenAmount: string;
  quoteAmount: string;
  price: string | null;
  timestamp: string;
  blockZeroBlockOne: boolean;
  tokenSymbol: string;
};
export type SmartSwapsWalletInfo = {
  address: string;
  chain: Chains;
  traderType: WalletType;
  walletLabels: WalletKeys[];
};

export type SmartSwapsDetails = {
  makerAddress: string;
  poolTotalLiquidityInUsd: string;
  tokenUsdMarketcap: string;
  transactionHash: string;
  swapDirection: 'buy' | 'sell';
  chain: Chains;
  tokenAddress: string;
  tokenBaseSymbol: string;
  tokenQuoteSymbol: string;
  dex: Protocols;
  tokenAmount: string;
  quoteAmount: string;
  price: string | null;
  timestamp: string;
  blockZeroBlockOne: boolean;
  tokenSymbol: string;
  wallet: SmartSwapsWalletInfo;
};

export enum SwapsStatus {
  Open = 'open',
  Closed = 'closed',
}

export type WalletSwapsDetails = {
  makerAddress: string;
  poolId: string;
  status: SwapsStatus;
  tradeType: SwapType;
  chain: Chains;
  holdings: string;
  lastSwap: string;
  tradedTokenId: string;
  tokenAddress: string;
  tokenBaseSymbol: string;
  tokenQuoteSymbol: string;
  dex: Protocols;
  nBuys: number;
  nSells: number;
  buyTokenAmount: string;
  sellTokenAmount: string;
  buyQuoteAmount: string;
  sellQuoteAmount: string;
  avgEntryPrice: string | null;
  avgExitPrice: string | null;
  realizedPnl: string;
  unrealizedPnl: string;
  realizedReturns: string;
  unrealizedReturns: string;
  pnl: string;
  returns: string;
  firstBuyTimestamp: string;
  lastSellTimestamp: string | null;
  tokenAgeFirstBuy: number;
  honeypot: boolean;
  blockZeroBlockOne: boolean;
  tokenSymbol: string;
};

export type WalletSwapsDetailsStatus = {
  status: 'completed' | 'in_progress' | 'scheduled';
  lastUpdateTime: string;
};

export type PoolStatus = 'RUG_PULL' | 'SNIPEABLE' | 'NOT_SNIPEABLE';

export type GetNewPoolsResponse = PaginatedList<PoolRowInfo>;
export type GetWalletsResponse = WalletRowInfo[];
export type GetWalletsResponseOld = WalletRowInfo[];
export type GetHotTokensResponse = WalletRowInfo[];
export type GetWalletDetailsResponse = PaginatedListWithStatus<
  WalletSwapsDetails,
  WalletSwapsDetailsStatus
>;

interface TimeBasedData extends Partial<Record<HotTokensPeriods, string>> {}

interface MainInfo {
  liquidity: string;
  launchDate: string;
  marketCap: string;
}

export interface GetTokenMainStats {
  priceChange: TimeBasedData;
  volume: TimeBasedData;
  buyTx: TimeBasedData;
  sellTx: TimeBasedData;
  main: MainInfo;
}

export type TelegramChannelsRowInfo = {
  id: string;
  calls: TelegramChannelDetailsRowInfo[];
  description: string | null;
  createdAt: string;
  updatedAt: string;
  imgUrl: string | null;
  telegramTradingChannelName: string;
  telegramTradingChannelLink: string;
  timePeriodInDays: number;
  rugPullRatio: string;
  numberOfWins: number | null;
  numberOfLosses: number | null;
  numberWinsOverLossesAndRugPulls: number | null;
  numberOfRugPulls: number | null;
  maxHighestReturn: number | null;
  minLowestReturn: number | null;
  hitRate: number | null;
  totalNumberOfCalls: number | null;
};

export type GetTelegramChannelsResponse =
  PaginatedList<TelegramChannelsRowInfo>;

export type TelegramChannelDetailsRowInfo = {
  id: string;
  createdAt: string;
  updatedAt: string;
  messageId: string;
  messageDate: string;
  telegramTradingChannelId: string;
  telegramTradingChannelPostLink: string;
  chain: Chains;
  token0Symbol: string | null;
  token1Symbol: string | null;
  hasImage: boolean;
  contractAddress: string;
  contractTelegramLink: string;
  poolAddress: string;
  protocol: Protocols;
  performanceTimePeriodInDays: number | null;
  approximatePriceAtMessageDate: number | null;
  lowestPriceWithinPeriod: number | null;
  highestPriceWithinPeriod: number | null;
  lastPriceWithinPeriod: number | null;
  highestReturnWithinPeriod: number | null;
  lowestReturnWithinPeriod: number | null;
  lastReturnWithinPeriod: number | null;
  isRugged: boolean;
  isPerformanceStatsFinal: boolean;
};

export type GetTelegramChannelDetailsResponse =
  PaginatedList<TelegramChannelDetailsRowInfo>;

export type GetNewPoolsParams = {
  status?: PoolStatus[];
  minLiquidity?: number;
  maxLiquidity?: number;
  minMarketcap?: number;
  maxMarketcap?: number;
  limit?: number;
  offset?: number;
  sort: string;
  chain: Chains;
};

export type GetNewCallsParams = {
  status?: PoolStatus[];
  channelId?: string[] | [];
  minLiquidity?: number;
  maxLiquidity?: number;
  minMarketcap?: number;
  maxMarketcap?: number;
  limit?: number;
  offset?: number;
  sort: string;
  chain: Chains;
};

export type GetSwapsParams = {
  fromDatetime?: string;
  tradedTokenId?: string;
  makerAddress: string;
  chain?: Chains;
};

export type GetWalletsParams = {
  sort: string;
  address?: string;
  chain: Chains;
  period: WalletPeriods;
  minBalance?: number;
  maxBalance?: number;
  minVolume?: number;
  maxVolume?: number;
  minPnl?: number;
  maxPnl?: number;
  minReturns?: number;
  maxReturns?: number;
  tradingStyle?: WalletKeys;
};

export type GetHotTokensParams = {
  sort: string;
  address?: string;
  chain: Chains;
  period: HotTokensPeriods;
  minMc?: number;
  maxMc?: number;
  minVolume?: number;
  maxVolume?: number;
  minLiquidity?: number;
  maxLiquidity?: number;
  poolCreatedAt?: number;
};

export type GetSmartSwapsParams = {
  status?: 'buy' | 'sell';
  walletAddress?: string;
  address?: string;
  chain: Chains;
  minMc?: number;
  maxMc?: number;
  minVolume?: number;
  maxVolume?: number;
  minLiquidity?: number;
  maxLiquidity?: number;
  poolCreatedAt?: number;
};

export type GetWalletDetailsParams = {
  limit: number;
  offset: number;
  sort: string;
  address?: string;
  chain: Chains;
  status?: SwapsStatus;
  search?: string;
};

export type GetTelegramChannelsParams = {
  limit?: number;
  offset?: number;
  sort: string;
  timePeriodInDays: string;
  minNumberOfCalls?: number;
  maxNumberOfCalls?: number;
  minRugPulls?: number;
  maxRugPulls?: number;
  minWinnersVsLosers?: number;
  maxWinnersVsLosers?: number;
  search?: string;
  traderId?: string;
};

export type GetTelegramChannelDetailsParams = {
  limit?: number;
  offset?: number;
  sort: string;
};

export type ContractSecurity = {
  honeypot: boolean | null;
  buyTax: number | null;
  sellTax: number | null;
  highTaxes: boolean | null;
  contractVerified: boolean | null;
  contractRenounced: boolean | null;
  lowLiquidity: boolean | null;
  highLiquidity: boolean | null;
};

export enum PoolsTableSortKeys {
  Liquidity = 'total_liquidity_in_usd',
  Marketcap = 'token_marketcap',
  CreatedAt = 'created_at',
}

export enum TelegramCallsTableSortKeys {
  Liquidity = 'pool_total_liquidity_in_usd',
  Marketcap = 'token_marketcap',
  CreatedAt = 'pool_created_at',
  LastCall = 'message_date',
  NumberOfCalls = 'number_of_calls',
}

export enum WalletsTableSortKeys {
  Balance = 'balance',
  Volume = 'volume',
  Returns = 'returns',
  PnL = 'pnl',
  TotalSwaps = 'total_swaps',
  UniqueTokens = 'unique_tokens',
  Snipes = 'snipes',
  EarlyTrades = 'early_trades',
  Trades = 'trades',
  Scams = 'honeypots',
  HoldingTime = 'avgHoldingTime',
  LastSwap = 'last_swap',
  NOfWins = 'n_of_wins',
}

export enum HotTokensSortKeys {
  PriceChange = 'price_change',
  MarketCap = 'current_marketcap',
  Liquidity = 'current_liquidity',
  TotalBuyTop = 'total_buy_top',
  TotalSellsTop = 'total_sells_top',
  TotalBuy = 'total_buy',
  TotalSells = 'total_sells',
  BuyVolumeTop = 'buy_volume_top',
  SellVolumeTop = 'sell_volume_top',
  Volume = 'volume',
  TotalTopHolder = 'total_top_holder',
  TotalBadHolder = 'total_bad_holder',
  SmartFlow = 'net_inflow_top',
  VolatilityIndex = 'volatility_index',
}

export enum WalletsSwapsDetailsTableSortKeys {
  Pnl = 'pnl',
  Returns = 'returns',
  FirstBuy = 'first_buy_timestamp',
  LastSell = 'last_sell_timestamp',
  LastSwap = 'last_swap',
}

export enum TelegramChannelsTableSortKeys {
  WinsVsLoss = 'hit_rate',
  Calls = 'total_number_of_calls',
  Wins = 'number_of_wins',
  Loss = 'number_of_losses',
  RugPulls = 'number_of_rug_pulls',
  HighestWins = 'max_highest_return',
  HighestLosses = 'min_lowest_return',
}

export enum TelegramChannelDetailsTableSortKeys {
  CreatedAt = 'created_at',
  Highest = 'highest_return_within_period',
  Lowest = 'lowest_return_within_period',
  Last = 'last_return_within_period',
}
