import { ReactComponent as MemamaskIcon } from 'assets/img/wallets/metamask.svg';
import { ReactComponent as CoinbaseIcon } from 'assets/img/wallets/coinbase.svg';
import { ReactComponent as WalletConnectIcon } from 'assets/img/wallets/walletconnect.svg';
import { WalletIcon as WalletIconFallback } from '@heroicons/react/24/outline';
import React from 'react';

export function WalletIcon({
  name,
  ...props
}: { name: string } & Omit<React.SVGProps<SVGSVGElement>, 'ref'>) {
  switch (name) {
    case 'MetaMask':
      return <MemamaskIcon {...props} />;
    case 'Coinbase Wallet':
      return <CoinbaseIcon {...props} />;
    case 'WalletConnect':
      return <WalletConnectIcon {...props} />;
    default:
      return <WalletIconFallback {...props} />;
  }
}
