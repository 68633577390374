import { FC, Fragment, ComponentProps } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { ModalContextProvider, useModal } from './ModalContext';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from 'modules/shared-components/button/IconButton';
import config from 'config';
import { BackButton } from 'modules/telegram/BackButton';
import { MobileExpandableCardHeader } from 'modules/shared-components/MobileExpandableCard';

interface CustomModalProps {
  size?: keyof typeof SIZE;
  showModal: boolean;
  handleClose: () => void;
  className?: string;
  title: string;
}

const SIZE = {
  normal: 'sm:max-w-lg',
  large: 'sm:max-w-2xl',
  xLarge: 'sm:max-w-4xl',
  xxLarge: 'sm:max-w-6xl',
};

type IModal = FC<CustomModalProps> & {
  Title: FC<ComponentProps<typeof Title>>;
  Body: FC<ComponentProps<'div'>>;
  Content: FC<ComponentProps<'div'>>;
  Footer: FC;
};

const CustomModal: IModal = ({
  showModal,
  size = 'normal',
  handleClose,
  children,
  className,
  title,
}) => {
  return (
    <Transition.Root appear show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[50]"
        onClose={() => {
          handleClose?.();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 xxs:bg-dex-black-900/80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[50] overflow-y-auto">
          <div className="flex xxs:min-h-full xxs:flex-col lg:flex-row justify-center xxs:p-0 sm:p-4 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  'lg:border-tailwind lg:border-solid lg:border-dex-black-600 relative transform overflow-hidden lg:rounded-lg xxs:bg-dex-black-900 text-left transition-all sm:my-8 flex-grow xxs:w-full xxs:h-full xxs:flex xxs:flex-col lg:h-auto',
                  SIZE[size],
                  className
                )}
              >
                {title && (
                  <div className="xxs:lg:hidden">
                    <MobileExpandableCardHeader onClose={handleClose}>
                      <div className="xxs:text-dex-white">{title}</div>
                    </MobileExpandableCardHeader>
                  </div>
                )}
                <ModalContextProvider close={handleClose}>
                  {children}
                </ModalContextProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface TitleProps {
  className?: string;
  disableClose?: boolean;
}

const Title: FC<TitleProps> = ({ children, className, disableClose }) => {
  const { close } = useModal();
  return (
    <Dialog.Title
      as="h3"
      className={twMerge(
        'xxs:text-lg font-medium xxs:text-dex-white text-left flex justify-start items-center xxs:gap-2',
        className
      )}
    >
      {children}
      {!disableClose &&
        (config.isTelegramBrowser ? (
          <BackButton onClick={close} />
        ) : (
          <IconButton
            onClick={close}
            className="shrink-0 ml-auto xxs:text-dex-white-secondary hover:text-dex-white"
          >
            <XMarkIcon className="w-6" />
          </IconButton>
        ))}
    </Dialog.Title>
  );
};

const Body: FC<ComponentProps<'div'>> = ({ children, ...props }) => (
  <div
    {...props}
    className={twMerge(
      'xxs:bg-dex-black-800 xxs:p-4 flex-grow xxs:rounded',
      props.className
    )}
  >
    <div className="sm:flex sm:items-start">
      <div className="text-center sm:text-left xxs:flex-1 w-full">
        {children}
      </div>
    </div>
  </div>
);

const Content: FC<ComponentProps<'div'>> = (props) => (
  <div
    {...props}
    className={twMerge(
      'xxs:mt-2 xxs:text-sm xxs:text-dex-white-secondary xxs:text-left xxs:space-y-2',
      props.className
    )}
  />
);

const Footer: FC = ({ children }) => {
  return (
    <div
      className={twMerge(
        'xxs:bg-dex-black-800 border-tailwind xxs:border-dex-black-700 xxs:border-x-none xxs:border-b-none xxs:px-4 xxs:py-4 flex xxs:flex-col sm:flex-row-reverse xxs:gap-2 sm:px-2',
        config.isTelegramBrowser && 'hidden'
      )}
    >
      {children}
    </div>
  );
};

CustomModal.Title = Title;
CustomModal.Body = Body;
CustomModal.Content = Content;
CustomModal.Footer = Footer;

export default CustomModal;
