import { useLocation, useParams } from 'react-router-dom';
import NewBot from './volatility/NewBot';
import { Chains } from 'api/d-wallets';
import { CreateBot } from './copy-trading/CreateBot';
import { Tabs } from '../components/layout/SIDEBAR_CONST';

interface LocationState {
  params?: {
    address?: string;
    chain?: Chains;
    channel?: string;
  };
}

function CreateNewBotContainer() {
  const { tab: activeTab } = useParams<{ tab: Tabs }>();
  const location = useLocation();
  const { state } = location as { state?: LocationState };
  const params = state?.params;

  return activeTab === Tabs.VolatilityBot ? (
    <NewBot />
  ) : (
    <CreateBot
      key={activeTab}
      isWalletCopyTrading={activeTab === Tabs.WalletCopyTrading}
      preSelectedChannel={params?.channel}
      preSelectedAddress={params?.address}
      preSelectedChain={params?.chain}
    />
  );
}

export default CreateNewBotContainer;
