import { useState } from 'react';
import { ComboboxSearch } from './combobox-search';
import { FilterDropdown, FilterDropdownProps } from './filter-dropdown';

export function FilterSearchDropdown<T extends string | number>({
  options,
  onChange,
  size,
  ...rest
}: Omit<FilterDropdownProps<T>, 'query' | 'setQuery' | 'isLoading'>) {
  const [query, setQuery] = useState('');

  const availableOptions =
    query === ''
      ? options
      : (options || []).filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        );

  return (
    <FilterDropdown
      size={size}
      {...rest}
      onChange={(val) => {
        onChange(val);
        setQuery('');
      }}
      search={<ComboboxSearch size={size} query={query} setQuery={setQuery} />}
      options={availableOptions}
    />
  );
}
