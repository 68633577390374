import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { AlertBgs, AlertColors } from './notification';

type TagProps = {
  type: keyof typeof AlertColors;
  condensed?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

const Tag: FC<TagProps> = ({
  type = 'info',
  children,
  condensed = false,
  ...rest
}) => (
  <span
    {...rest}
    className={twMerge(
      `inline-flex items-center xxs:rounded xxs:px-1 xxs:py-0.5 font-medium`,
      type !== 'info'
        ? `bg-${AlertBgs[type as keyof typeof AlertColors]} xxs:text-${
            AlertColors[type as keyof typeof AlertColors]
          } `
        : 'bg-dex-black-700',
      condensed ? 'xxs:text-xs' : 'lg:text-sm',
      rest.className
    )}
  >
    {children}
  </span>
);

export default Tag;
