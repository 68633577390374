import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = { children: React.ReactNode } & React.ComponentProps<'div'>;

export function ActivteFiltersBar({ children, ...props }: Props) {
  const tagCount = React.Children.toArray(children)?.length;
  return tagCount > 0 ? (
    <div
      {...props}
      className={twMerge(
        'flex flex-wrap gap-1 w-full xxs:rounded xxs:p-1 lg:px-2 lg:py-1 border-tailwind xxs:border xxs:border-solid xxs:border-black-700 dark:text-white-200 shadow-xs xxs:items-center',
        props.className
      )}
    >
      <div className="hidden lg:block text-white-900 dark:text-black-50 xxs:text-xs">
        Filters |
      </div>
      {children}
    </div>
  ) : null;
}
