import { useEffect, useState } from 'react';
import ErrorBoundary from 'components/error-boundaries';
import Tooltip from 'modules/shared-components/tooltip';
import { AlphaRoles } from 'api/leaderboard';
import AlphaMaster from '../../../../assets/img/icons/alpha-master-img.svg';
import InternationalAlphaMaster from '../../../../assets/img/icons/international-alpha-master.svg';
import GrandAlphaMaster from '../../../../assets/img/icons/grand-alpha-master.svg';

interface Props {
  imageUrl: string;
  name: string;
  isAlphaMaster: boolean;
  isAlphaInternationalMaster: boolean;
  isAlphaGrandMaster: boolean;
}

export function DiscordImgComponent({
  imageUrl,
  name,
  isAlphaMaster,
  isAlphaInternationalMaster,
  isAlphaGrandMaster,
}: Props) {
  const [imageExists, setImageExists] = useState(false);

  const checkImage = (url: string) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  useEffect(() => {
    void checkImage(imageUrl).then((exists) => {
      if (exists) {
        setImageExists(true);
      } else {
        setImageExists(false);
      }
    });
  }, [imageUrl]);

  return (
    <ErrorBoundary>
      <Tooltip
        text={
          isAlphaMaster || isAlphaInternationalMaster || isAlphaGrandMaster
            ? `This trader is a ${
                isAlphaGrandMaster
                  ? AlphaRoles.AlphaGrandMaster
                  : isAlphaInternationalMaster
                  ? AlphaRoles.AlphaInternationalMaster
                  : AlphaRoles.AlphaMaster
              }`
            : ''
        }
      >
        <div className="relative ">
          {!imageExists ? (
            <div className="w-6 h-6 xxs:rounded-full dark:bg-dex-black-700 xxs:border-solid xxs:border dark:border-dex-black-600 p-[1px] flex items-center justify-center text-[14px] leading-none">
              {!name ? '?' : name?.trim()?.[0]}
            </div>
          ) : (
            <img
              alt={`discord logo ${name}`}
              className="xxs:rounded-full dark:bg-white p-[1px] w-6 h-6 "
              src={imageUrl}
            />
          )}
          {(isAlphaMaster ||
            isAlphaGrandMaster ||
            isAlphaInternationalMaster) && (
            <img
              src={
                isAlphaGrandMaster
                  ? GrandAlphaMaster
                  : isAlphaInternationalMaster
                  ? InternationalAlphaMaster
                  : AlphaMaster
              }
              className="w-4 h-4 -top-2 -right-1.5 absolute"
            />
          )}
        </div>
      </Tooltip>
    </ErrorBoundary>
  );
}
