import {
  HeaderInfoResponse,
  StrategyPerformance,
} from 'api/marketplace-performance';
import { ReactComponent as MizarLogo } from 'assets/img/logo/mizar-typo.svg';
import Horizontal from 'assets/img/share/horizontal.png';
import Vertical from 'assets/img/share/vertical.png';
import { twMerge } from 'tailwind-merge';
import Big from 'big.js';
import Tag from 'modules/shared-components/data-display/tag';
import { ForwardedRef, forwardRef } from 'react';
import QRCode from 'react-qr-code';

export const SCALE_CLASSES_VERICAL = [
  'xxs:scale-[0.65]',
  'sm:scale-75',
  'md:scale-100',
];
export const SCALE_CLASSES_HORIZONTAL = [
  'xxs:scale-[0.45]',
  'sm:scale-75',
  'md:scale-100',
];

interface Props {
  strategyPerformance: StrategyPerformance;
  info: HeaderInfoResponse;
  horizontal?: boolean;
  referralCode?: string;
  shareUrl?: string;
}

const ShareImage = forwardRef(
  (
    {
      strategyPerformance,
      info,
      horizontal = false,
      referralCode,
      shareUrl,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const last30DaysReturn =
      strategyPerformance.mainPerformance?.last30DaysReturn || 0;
    return (
      <div className="overflow-visible xxs:rounded relative flex justify-center xxs:max-h-[400px] md:max-h-none">
        <div
          ref={ref}
          className={twMerge(
            'flex flex-col',
            horizontal ? SCALE_CLASSES_HORIZONTAL : SCALE_CLASSES_VERICAL,
            horizontal
              ? 'xxs:w-[800px] xxs:min-w-[800px]'
              : 'xxs:w-[450px] xxs:min-w-[450px]'
          )}
        >
          <div
            className={twMerge(
              'xxs:p-10 flex flex-col xxs:pb-[82px]',
              horizontal ? 'xxs:pt-[72px]' : 'xxs:pt-[64px]'
            )}
            style={{
              backgroundImage: `url(${horizontal ? Horizontal : Vertical})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
            }}
          >
            <MizarLogo width={horizontal ? 200 : 112} />
            <div
              className={twMerge(
                'xxs:mt-[64px] flex',
                horizontal ? 'flex-row xxs:gap-[64px]' : 'flex-col xxs:gap-10'
              )}
            >
              <div className="flex flex-col xxs:gap-4 xxs:max-w-[250px]">
                <div className="flex items-center xxs:gap-3">
                  <span className="xxs:text-3xl text-semibold xxs:text-black-50 truncate">
                    {info.traderName.replaceAll('@', '')}
                  </span>
                </div>
                <span className="xxs:text-xl text-semibold xxs:text-black-50">
                  {info.name}
                </span>
                <div className="flex xxs:gap-2">
                  <Tag>{info.botInfo.market.toUpperCase()}</Tag>
                  <Tag>{info.botInfo.side.toUpperCase()}</Tag>
                </div>
              </div>
              <div className="flex flex-col">
                <p className="xxs:mb-1 xxs:text-sm text-white">30D PnL</p>
                <div
                  className={twMerge(
                    'font-semibold text-white',
                    horizontal ? 'text-6xl' : 'text-4xl',
                    last30DaysReturn > 0 && 'text-green-success',
                    last30DaysReturn < 0 && 'text-red-warning'
                  )}
                >
                  {last30DaysReturn > 0 && '+'}
                  {Big(last30DaysReturn).mul(100).toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
          <div className="xxs:px-6 xxs:py-3 xxs:bg-black-900 flex justify-between xxs:gap-5 items-center">
            <span className="xxs:text-black-100 xxs:text-sm">
              Sign Up with referral code {referralCode} and receive a 5%
              discount on volume fees.
            </span>
            {shareUrl && (
              <div className="min-w-[76px] xxs:p-0.5 xxs:bg-white">
                <QRCode value={shareUrl} size={72} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

ShareImage.displayName = 'ShareImage';

export default ShareImage;
