import { Button } from './button';
import classNames from 'classnames';

export function GrayButton(props) {
  return (
    <Button
      {...props}
      variant={'primary'}
      className={classNames(props.className)}
    />
  );
}
