import { useStepperState } from 'modules/shared-components/stepper/StepperContext';

export enum DepositSteps {
  SelectChain = 'SelectChain',
  SelectAmount = 'selectAmount',
  DepositTxPending = 'depositTxPending',
}

export function useDepositStepper() {
  return useStepperState<DepositSteps>();
}
