import { useQueryParams } from 'modules/routing/useQueryParams';
import * as yup from 'yup';
import { DexOrderStatus, DexTradeStatus } from 'api/dex-trade';

export const orderStatesStatuses = {
  error: [
    DexOrderStatus.failedToSnipe,
    DexOrderStatus.failedToBuild,
    DexOrderStatus.failedToSend,
    DexOrderStatus.stuckInMempool,
    DexOrderStatus.timedOut,
    DexOrderStatus.confirmedExecutionRevertedInProgress,
    DexOrderStatus.confirmedExecutionReverted,
  ],
};
export const positionStatesStatuses = {
  active: [
    DexTradeStatus.new,
    DexTradeStatus.snipeInProgress,
    DexTradeStatus.sniped,
    DexTradeStatus.cancelledByUserInProgress,
    DexTradeStatus.cancelledInProgress,
  ] as DexTradeStatus[],
  history: [
    DexTradeStatus.closedInProgress,
    DexTradeStatus.closedByUserInProgress,
    DexTradeStatus.cancelledByUser,
    DexTradeStatus.cancelledByUserSettings,
    DexTradeStatus.cancelled,
    DexTradeStatus.closed,
    DexTradeStatus.closedByUser,
    DexTradeStatus.failedToSnipe,
  ] as DexTradeStatus[],
};

export const openPositionsStateSchema = {
  botId: yup.string(),
  tokenAddress: yup.string(),
};

export type FilterState = yup.TypeFromShape<
  typeof openPositionsStateSchema,
  any
>;

export default function usePositionsState() {
  const [filtersState, setFilters] = useQueryParams(openPositionsStateSchema);
  return { filtersState, setFilters };
}
