import { useUserState } from 'modules/user/UserContext';
import {
  DexOrderStatus,
  DexTradeStatus,
  GetDexOpenPositionsParams,
  LimitOrderSide,
  OpenPositionListItem as ItemData,
} from 'api/dex-trade';
import { useInfiniteQuery } from '@tanstack/react-query';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import { duration } from 'moment';
import { useInView } from 'react-intersection-observer';
import Button from 'modules/shared-components/button/button';
import { Link, useHistory } from 'react-router-dom';
import { LoadingComponent } from './LoadingComponent';
import { twMerge } from 'tailwind-merge';
import Big from 'big.js';
import { ActionsMenu, SnipeStatus } from '../trade-monitor/columns';
import { TokenNameWithChain } from './SharedComponentsPanel';
import { useToggle } from 'modules/utils/useToggle';
import {
  ChartBarSquareIcon,
  ClockIcon,
  CreditCardIcon,
  ExclamationTriangleIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { DurationCounter } from '../research/components/TelegramCalls/columns';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { chainAsset } from 'api/d-wallets';
import Tooltip from 'modules/shared-components/tooltip';
import { formatHoldingTime } from 'modules/dTrade/utils';
import createPersistedState from 'use-persisted-state';
import { FullScreenModal } from '../components/modal/FullScreenModal';
import { PositionDetails } from '../trade-monitor/components/Details/PositionDetails';
import { LoaderDex } from '../components/alerts/Loader';
import {
  orderStatesStatuses,
  positionStatesStatuses,
} from '../trade-monitor/usePageState';
import { Tabs } from '../components/layout/SIDEBAR_CONST';
import PageTemplate from '../components/layout';
import { NotificationDex } from '../components/alerts/notification';
import { performanceStyle } from '../research/components/Wallets/columns';

export const useRefreshIntervalPersistedState = createPersistedState<
  string | undefined
>('mizar-active-snipes-refresh');
const REFRESH_TIME = 12;
const REFRESH_TIME_FAST = 2;

export const refreshIntervalTime = (state: string | undefined) => {
  const tenSecondsAgo = new Date().getTime() - 10 * 1000;
  return state
    ? new Date(state).getTime() > tenSecondsAgo
      ? REFRESH_TIME_FAST
      : REFRESH_TIME
    : REFRESH_TIME;
};

export function SnipesPanel({ params }: { params: GetDexOpenPositionsParams }) {
  const { user } = useUserState();
  const isDesktop = useMediaQuery(screenGte.large);

  const [refetchInterval] = useRefreshIntervalPersistedState();

  const {
    data,
    error: error,
    isInitialLoading: isLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryFn: ({ pageParam = { limit: 20, offset: 0 } }) => {
      return DexTradeService.getPositions({
        ...params,
        limit: pageParam.limit,
        offset: pageParam.offset,
      });
    },
    queryKey: dexTradeKeys.getPositions(params),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => ({
      offset: lastPage.pagination.offset + lastPage.pagination.limit,
      limit: 10,
    }),
    enabled: !!user,
    refetchInterval: duration(
      refreshIntervalTime(refetchInterval),
      'seconds'
    ).asMilliseconds(),
  });

  const items = data?.pages?.flatMap((page) => page.data) || [];
  const pagination = data?.pages?.[0]?.pagination;

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        void fetchNextPage();
      }
    },
  });

  if (!user) {
    return (
      <div>
        <div className="lg:hidden">
          <PageTemplate>
            <div className="flex flex-col justify-center items-center py-24 xxs:mx-4 space-y-2">
              <div className="text-sm font-bold">
                Login or signup to manage your snipes.
              </div>
              <Button
                variant="primary"
                as={Link}
                to="/login"
                className=" xxs:px-12 "
                type="button"
              >
                LOGIN
              </Button>
            </div>
          </PageTemplate>
        </div>
        <div className="hidden lg:block">
          <div className="divide-x-0 divide-solid dark:divide-black-700 xxs:divide-white-700 xxs:h-[600px] lg:h-auto divide-y overflow-y-auto no-scrollbar overflow-y-scroll">
            <Link
              className="text-blue-500 hover:text-blue-600 xxs:px-2 font-bold xxs:text-xs xxs:pt-1"
              to="/login"
            >
              Login or sign-up
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="divide-x-0 divide-solid dark:divide-black-700 xxs:divide-white-700 xxs:h-[600px] lg:h-auto divide-y overflow-y-auto no-scrollbar overflow-y-scroll">
        <div className="py-1 text-sm absolute bottom-50 flex items-center justify-center w-full flex-col space-y-1">
          {error && (
            <NotificationDex type="error" errorMessage={error}>
              An error occurred
            </NotificationDex>
          )}
        </div>
      </div>
    );
  }

  if (pagination?.total === 0) {
    return (
      <div className="divide-x-0 divide-solid xxs:divide-dex-black-700 xxs:h-[600px] lg:h-auto divide-y overflow-y-auto no-scrollbar overflow-y-scroll">
        <div className="py-1 text-sm absolute bottom-50 flex items-center justify-center w-full flex-col space-y-1">
          <div className="xxs:text-dex-white-secondary">No trades found</div>

          {!isDesktop && (
            <>
              <Button
                as={Link}
                variant="primary"
                to="/dex/research?searchDefaultOpen=true"
              >
                Snipe
              </Button>
              {params.status?.includes(DexTradeStatus.new) && (
                <Button as={Link} to={`/dex/trade-monitoring/${Tabs.History}`}>
                  Check history
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="divide-x-0 divide-solid xxs:divide-dex-black-700 divide-y overflow-y-auto no-scrollbar overflow-y-scroll">
        {items.map((position) => {
          return (
            <SnipesList
              isHistory={params.status === positionStatesStatuses.history}
              key={position.id}
              position={position}
            />
          );
        })}
        {pagination && pagination?.total > items.length && (
          <div className="flex justify-center xxs:py-2">
            <LoaderDex ref={ref} className="mx-auto" />
          </div>
        )}
      </div>
    </>
  );
}

export function SnipesList({
  position,
  isMerge,
  className,
  isHistory,
}: {
  position: ItemData;
  isMerge?: boolean;
  className?: string;
  isHistory?: boolean;
}) {
  const [isOpen, { open, close }] = useToggle();
  const isDesktop = useMediaQuery(screenGte.large);
  const history = useHistory();
  const status = position.status;
  const pnl =
    position.status === DexTradeStatus.closed ||
    position.status === DexTradeStatus.closedByUser ||
    position.status === DexTradeStatus.closedInProgress
      ? position.realisedProfitAndLoss
      : position.netUnrealisedProfitAndLoss;

  const buyTxs = position?.transactions?.filter(
    (tx) =>
      tx.side === LimitOrderSide.Buy &&
      tx.status !== DexOrderStatus.confirmedCancelled
  );

  const failedDCA = position?.limitOrders?.filter((limit) =>
    buyTxs.find(
      (buyTx) =>
        buyTx.id === limit.transactionId &&
        orderStatesStatuses.error.includes(buyTx.status)
    )
  );

  const sellTxs = position?.transactions?.filter(
    (tx) => tx.side === LimitOrderSide.Sell
  );

  const failedTps = position?.takeProfits?.filter((tp) =>
    sellTxs.find(
      (x) =>
        x.id === tp.transactionId &&
        orderStatesStatuses.error.includes(x.status)
    )
  );

  const failedStopLoss = sellTxs?.filter(
    (x) =>
      x.id === position.trailingStopLoss?.transactionId &&
      orderStatesStatuses.error.includes(x.status)
  );

  const failedTpsNumber = failedTps ? failedTps.length : 0;
  const failedDCANumber = failedDCA ? failedDCA.length : 0;
  const failedStopLossNumber = failedStopLoss ? failedStopLoss.length : 0;
  const somethingFailed =
    positionStatesStatuses.active.includes(status) &&
    failedDCANumber + failedTpsNumber + failedStopLossNumber > 0;

  const tooltipText = somethingFailed && (
    <div className="xxs:space-y-0.5">
      <div>Orders with errors</div>
      <ul className="xxs:mx-0">
        {failedDCANumber > 0 ? (
          <li className="xxs:mx-0">DCA: {failedDCANumber} failed</li>
        ) : (
          ''
        )}
        {failedTpsNumber > 0 ? (
          <li className="xxs:mx-0">TP: {failedTpsNumber} failed</li>
        ) : (
          ''
        )}
        {failedStopLossNumber > 0 ? (
          <li className="xxs:mx-0">SL failed</li>
        ) : (
          ''
        )}
      </ul>
    </div>
  );

  return (
    <>
      <button
        type="button"
        onClick={() => {
          if (isDesktop) {
            open();
          } else {
            history.push({
              pathname: `/dex/trade-monitoring/${
                isHistory ? Tabs.History : Tabs.OpenTrades
              }/${position.id}`,
              state: {
                from: `${history.location.pathname}${history.location.search}`,
              },
            });
          }
        }}
        className={twMerge(
          'text-left m-0 xxs:bg-dex-black-900 hover:bg-dex-black-800 xxs:p-2 w-full dark:text-dex-white xxs:text-xs flex xxs:gap-2 items-center',
          className
        )}
      >
        <div className="grow truncate">
          <div className="flex space-x-2 items-center">
            {position && (
              <TokenIconWithChain
                className="h-[25px] w-[25px]"
                tokenName={position.tokenName}
                address={position.tokenAddress}
                symbol={position.tokenSymbol}
                protocol={position.protocol?.protocol}
                chain={position.chain}
              />
            )}
            <div className="">
              <TokenNameWithChain
                className="mb-0"
                baseAsset={position.tokenSymbol}
                chain={position.chain}
              />
              <div className="flex text-xs space-x-0.5 items-center xxs:text-dex-white-secondary">
                <ClockIcon className="w-3 h-3" />
                <div className="">
                  {position.holdingTimeSeconds ? (
                    formatHoldingTime(position)
                  ) : position.createdAt ? (
                    <DurationCounter start={position.createdAt} />
                  ) : (
                    '--'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shrink-0">
          <div className="flex flex-col items-end">
            {pnl?.pnlPct ? (
              <>
                <div className="flex space-x-1 items-center">
                  {somethingFailed && (
                    <Tooltip text={tooltipText}>
                      <ExclamationTriangleIcon className="text-red-500 w-4 h-4" />
                    </Tooltip>
                  )}
                  {position.volatilityBotId && (
                    <div className="pb-0.5">
                      <Tooltip text="Volatility bot snipe">
                        <div>
                          <ChartBarSquareIcon className="w-3 h-3 " />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {position.copyTradingBotId && (
                    <div className="pb-0.5">
                      <Tooltip text="Wallet copy-trading">
                        <div>
                          <CreditCardIcon className="w-3 h-3 " />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {position.telegramSnipingCallEvent && (
                    <div className="pb-0.5">
                      <Tooltip text="Telegram copy-trading">
                        <div>
                          <PhoneIcon className="w-3 h-3 " />
                        </div>
                      </Tooltip>
                    </div>
                  )}

                  <div>
                    {performanceStyle(
                      Big(pnl?.pnlPct ?? 0)
                        .mul(100)
                        .toString(),
                      'xxs:text-sm',
                      '%'
                    )}
                  </div>
                </div>

                <div className="xxs:text-xs">
                  <div>
                    {performanceStyle(
                      Big(pnl?.pnlAmountInEth ?? 0).toString(),
                      '',
                      chainAsset(position.chain)
                    )}
                  </div>
                </div>
              </>
            ) : (
              <SnipeStatus condensed position={position} />
            )}
          </div>
        </div>
        {status !== DexTradeStatus.closedInProgress && !isMerge && (
          <ActionsMenu position={position} />
        )}
      </button>
      {isOpen && (
        <FullScreenModal close={close}>
          <PositionDetails id={position.id} />
        </FullScreenModal>
      )}
    </>
  );
}
