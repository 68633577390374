import { Chains } from 'api/d-wallets';
import { PreSetFilters, PreSets } from '../schema';
import { PresetButton } from '../../../components/PresetButton';
import {
  PresetButtonTitle,
  PresetButtonDescription,
} from '../../../research/components/SnipingOpportunitiesCard/PresetButton';
import { GetHotTokensParams, HotTokensRowInfo } from 'api/contracts';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/ContractService';
import { useState } from 'react';
import { NotificationDex } from '../../../components/alerts/notification';
import CustomModal from '../../../components/modal/CustomModal';
import { LoaderDex } from '../../../components/alerts/Loader';

type Props = {
  handleClose: () => void;
  chain: Chains;
  replace: (arg0: { address: string }[]) => void;
};

function usePreSet(
  preSet: PreSets | undefined,
  handleClose: () => void,
  chain: Chains,
  replace: (arg0: { address: string }[]) => void
) {
  const preSetFilter = preSet ? PreSetFilters[preSet] : null;

  const params: GetHotTokensParams | null = preSetFilter
    ? {
        sort: preSetFilter.sort,
        chain,
        period: preSetFilter.period,
        minLiquidity: preSetFilter.minLiquidity,
        maxLiquidity: preSetFilter.maxLiquidity,
        minVolume: preSetFilter.minVolume,
        poolCreatedAt: preSetFilter.poolCreatedAt,
      }
    : null;

  const { isLoading, isError } = useQuery({
    queryKey: contractServiceKeys.getHotTokens(params as GetHotTokensParams),
    queryFn: () => ContractService.getHotTokens(params as GetHotTokensParams),
    enabled: !!preSet,
    onSuccess: (data: HotTokensRowInfo[]) => {
      const MINIMUM_ALERT_TRIGGER = 0.2;

      const filterAddresses = data
        .filter((row) => {
          const denominator = row.totalBuy ?? 1;
          const suspiciousRatio = row.suspiciousBuy / denominator || 0;
          const rugAddictedRatio = row.rugAddictedBuy / denominator || 0;
          const scammerRatio = row.scammerBuy / denominator || 0;
          const freshRatio = row.freshBuy / denominator || 0;

          return (
            row.totalBuyTop > 10 &&
            suspiciousRatio < MINIMUM_ALERT_TRIGGER &&
            rugAddictedRatio < MINIMUM_ALERT_TRIGGER &&
            scammerRatio < MINIMUM_ALERT_TRIGGER &&
            freshRatio < MINIMUM_ALERT_TRIGGER
          );
        })
        .map((row) => row)
        .slice(0, 10);

      const addresses: { address: string }[] =
        filterAddresses?.length > 0
          ? filterAddresses.map((d) => {
              return { address: d.tokenAddress };
            })
          : [];

      replace(addresses);
      handleClose();
    },
  });

  if (!preSet) {
    return { isLoading: false, isError: false };
  } else {
    return { isLoading, isError };
  }
}

const sets = [
  {
    preSet: PreSets.Volatility,
    title: '🎢 Volatility',
    description: 'Hot tokens ranked by 24h volatility index',
  },
  {
    preSet: PreSets.TradingVolume,
    title: '📊 Trading Volume',
    description: 'Hot tokens ranked by 24h trading volume',
  },
  {
    preSet: PreSets.SmartMoney,
    title: '🧠 Smart Money',
    description: 'Hot tokens ranked by 24h smart money net inflow',
  },
  {
    preSet: PreSets.Price,
    title: '💸 Price Change',
    description: 'Tokens ranked by 24h largest price increase',
  },
];

export function PreSetModal({ handleClose, chain, replace }: Props) {
  const [preSet, setPreSet] = useState<PreSets | undefined>();
  const { isError, isLoading } = usePreSet(preSet, handleClose, chain, replace);

  return (
    <CustomModal
      title="Tokens Smart Pre-Sets"
      handleClose={handleClose}
      showModal
    >
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Tokens Smart Pre-Sets
        </CustomModal.Title>

        <CustomModal.Content>
          <p className="xxs:text-sm">
            Whitelist your tokens using Mizar smart pre-sets
          </p>
          {isError && (
            <NotificationDex errorMessage={isError} type="error">
              An error occurred, try again.
            </NotificationDex>
          )}
          <div className="flex flex-col xxs:space-y-2 xxs:mb-4 w-full">
            {sets.map((set) => {
              return (
                <PresetButton
                  key={set.preSet}
                  className="xxs:py-2"
                  onClick={() => setPreSet(set.preSet)}
                >
                  {isLoading && set.preSet === preSet ? (
                    <div className="flex justify-center">
                      <LoaderDex className="xxs:my-4" />
                    </div>
                  ) : (
                    <div className=" xxs:space-y-1">
                      <PresetButtonDescription>
                        {set.title}
                      </PresetButtonDescription>
                      <PresetButtonTitle>{set.description}</PresetButtonTitle>
                    </div>
                  )}
                </PresetButton>
              );
            })}
          </div>
        </CustomModal.Content>
      </CustomModal.Body>
    </CustomModal>
  );
}
