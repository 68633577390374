import { XMarkIcon } from '@heroicons/react/24/outline';
import { useToggle } from 'modules/utils/useToggle';
import { Children, ReactNode, cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconButton } from '../button/IconButton';
import { FullPageModal } from './FullPage';
import { useModal } from './CustomModal/ModalContext';
import { BaseButton } from '../button/base-button';
import config from 'config';
import { BackButton } from 'modules/telegram/BackButton';

export type SelectionModalProps = {
  trigger: ({
    close,
    open,
  }: {
    close: () => void;
    open: () => void;
  }) => JSX.Element;
  children: ReactNode;
  label: ReactNode;
  defaultOpen?: boolean;
};

export function SelectionModal({
  trigger,
  label,
  children,
  defaultOpen,
}: SelectionModalProps) {
  const [isOpen, { close, open }] = useToggle(defaultOpen);

  return (
    <>
      {trigger({ close, open })}
      {isOpen && (
        <FullPageModal
          showModal
          handleClose={close}
          className="dark:text-white-50 dark:bg-black-900 h-screen overflow-auto"
          backdropClassName="xxs:bg-transparent dark:bg-transparent"
        >
          <MobileSelectHeader label={label} />

          {children}
        </FullPageModal>
      )}
    </>
  );
}

const optionsVariants = {
  divided: 'divide xxs:divide-black-600 xxs:divide-y xxs:divide-solid',
  default: '',
};

export function SelectionModalOptions({
  kind = 'default',
  noClose,
  ...props
}: React.ComponentProps<'div'> & { kind?: keyof typeof optionsVariants } & {
  noClose?: boolean;
}) {
  const { close } = useModal();

  return (
    <div
      {...props}
      className={twMerge('', optionsVariants[kind], props.className)}
    >
      {Children.toArray(props.children).map((c: any) => {
        return cloneElement(c, {
          onClick: (e: any) => {
            if (!noClose) {
              close();
            }
            c.props.onClick?.(e);
          },
        });
      })}
    </div>
  );
}

export function MobileSelectHeader({ label }: { label: ReactNode }) {
  const { close } = useModal();

  if (config.isTelegramBrowser) {
    return <BackButton onClick={close} />;
  }

  return (
    <div className="xxs:p-3 flex justify-between xxs:gap-2 dark:bg-black-900 sticky top-0 items-center z-1005 xxs:text-sm xxs:leading-5 xxs:border-0 xxs:border-solid xxs:border-b xxs:border-black-600">
      {label}
      <IconButton
        onClick={close}
        className="xxs:rounded dark:bg-black-700 shrink-0 xxs:p-1"
      >
        <XMarkIcon className="w-6" />
      </IconButton>
    </div>
  );
}

export function SelectionModalMobileItem(
  props: React.ComponentProps<typeof BaseButton>
) {
  return (
    <BaseButton
      type="button"
      {...props}
      className={twMerge(
        'w-full xxs:p-3 xxs:text-sm xxs:flex xxs:gap-2 xxs:items-center',
        props.className
      )}
    />
  );
}
