import { twMerge } from 'tailwind-merge';

export function StatsCard(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={twMerge(
        'xxs:bg-dex-black-700 xxs:text-dex-white xxs:flex xxs:flex-col xxs:p-1 justify-center items-center text-center',
        props.className
      )}
    />
  );
}
export function StatsCardTitle(props: React.ComponentProps<'div'>) {
  return (
    <div {...props} className="xxs:text-sm xxs:text-dex-white-secondary" />
  );
}
export function StatsCardValue(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={twMerge('xxs:text-lg xxs:font-semibold', props.className)}
    />
  );
}
