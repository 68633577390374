import { BaseButton } from 'modules/shared-components/button/base-button';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export function PresetButton(
  props: ComponentProps<typeof BaseButton> & { selected?: boolean }
) {
  return (
    <BaseButton
      type="button"
      {...props}
      className={twMerge(
        'xxs:text-center xxs:px-1 xxs:py-0.5 xxs:text-sm dark:xxs:text-dex-white-secondary dark:hover:text-dex-white dark:bg-dex-black-700 hover:dark:bg-dex-black-600 w-full xxs:rounded-sm xxs:dark:disabled:bg-black-700 dark:disabled:cursor-not-allowed',
        props.selected && 'dark:bg-black-300 dark:hover:bg-black-400',
        props.className
      )}
    />
  );
}
