import * as yup from 'yup';
import { useQueryParams } from 'modules/routing/useQueryParams';
import { getSeasonForCurrentCalendarWeek } from '../seasons';

const schema = {
  season: yup.string().default(getSeasonForCurrentCalendarWeek()).required(),
  address: yup.string(),
  userName: yup.string(),
  isMaster: yup.boolean(),
};

export type AlphaCallsFilterState = yup.TypeFromShape<typeof schema, any>;

export function usePageParams() {
  return useQueryParams(schema);
}

export function useAlphaCallsFilters() {
  const [filtersState, setFilters] = usePageParams();

  return { filtersState, setFilters };
}
