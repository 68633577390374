import { ContractInfo } from 'api/contracts';
import {
  commonOptions,
  TVChartContainer,
  useTvChart,
} from 'modules/tw-chart/TVChartContainer';
import { createDatafeed } from './datafeed';
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  Overrides,
  ResolutionString,
  ThemeName,
} from 'modules/tw-chart/charting_library';
import coingecoDarkIcon from 'assets/img/geckoterminal_dark.png';
import coingecoLightIcon from 'assets/img/geckoterminal_light.png';
import { useTheme } from 'modules/media/theme';
import { useChartEffect } from 'modules/smart-trade-terminal/helpers/use-chart-effect';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { Chains } from 'api/d-wallets';
import { useEffect } from 'react';
import createPersistedState from 'use-persisted-state';

type Props = {
  contract: ContractInfo;
  poolAddress: string;
  chartHeight?: number;
};

const dexTvChartIntervalKey = 'dex-tv-chart-interval';

export const useDexTvChartIntervalPersistedState =
  createPersistedState<ResolutionString>(dexTvChartIntervalKey);

export function DexChart({ contract, poolAddress, chartHeight: h }: Props) {
  const [chart, onChange] = useTvChart();
  const [dexTvChartInterval, setDexTvChartInterval] =
    useDexTvChartIntervalPersistedState('15' as unknown as ResolutionString);

  const chartHeight = Math.round(h ?? 350);

  useEffect(() => {
    if (chart) {
      // The chart widget might not have initialized completely
      const interval = setInterval(() => {
        if (chart.chart) {
          chart.chart().setResolution(dexTvChartInterval);
          clearInterval(interval);

          chart
            .chart()
            .onIntervalChanged()
            .subscribe(null, (newInterval) => {
              setDexTvChartInterval(newInterval);
            });
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, [chart]);

  useCoingeckoLogo(chart, contract.generalInfo.chain.key, poolAddress);

  return (
    <TVChartContainer
      className={`h-[${chartHeight}px]`}
      optionsFactory={(theme, defaults) =>
        createOptions(
          theme,
          defaults,
          contract.liquidityInfo.activePools[0].priceDollar || undefined,
          contract.generalInfo.chain.key
        )
      }
      onWidgetChange={onChange}
      symbol={`${contract.generalInfo.symbol || 'Token'}:${
        contract.generalInfo.address
      }:${poolAddress}`}
    />
  );
}

export function useCoingeckoLogo(
  chart: IChartingLibraryWidget | null,
  chain: Chains,
  poolAddress?: string | null
) {
  const theme = useTheme();
  const isLarge = useMediaQuery(screenGte.large);

  useChartEffect(
    chart,
    (widget) => {
      if (isLarge) {
        const button = widget.createButton({
          useTradingViewStyle: false,
          align: 'left',
        });
        button.innerHTML = `
      <div style="display: flex; align-items: center; gap: 8px; font-size: 12px">Powered by <img style="height: 16px" src="${
        theme === 'dark' ? coingecoDarkIcon : coingecoLightIcon
      }" />
      </div>`;

        button.onclick = () => {
          window.open(
            poolAddress
              ? `https://www.geckoterminal.com/${
                  chain === Chains.Ethereum
                    ? 'eth'
                    : chain === Chains.BinanceChain
                    ? 'bsc'
                    : 'base'
                }/pools/${poolAddress}?utm_source=mizar&utm_medium=referral&utm_campaign=mizar`
              : 'https://www.geckoterminal.com?utm_source=mizar&utm_medium=referral&utm_campaign=mizar'
          );
        };
        button.style.cursor = 'pointer';

        return () => {
          button.remove();
        };
      }
    },
    [chart, isLarge, poolAddress]
  );
}

export const createOptions = (
  theme: ThemeName,
  defaults: Partial<ChartingLibraryWidgetOptions>,
  referencePrice: number | undefined,
  chain: Chains
) => {
  return {
    ...commonOptions,
    interval: '15',
    datafeed: createDatafeed(chain, referencePrice),
    custom_css_url: '/chart-overrides-dex.css',
    enabled_features: ['snapshot_trading_drawings'],
    toolbar_bg: '#121212FF',

    overrides: createOverrides(),
    studies_overrides: {
      'volume.volume.color.0': 'rgba(240, 72, 102, 0.29)',
      'volume.volume.color.1': 'rgba(136, 214, 147, 0.29)',
    },
    loading_screen: {
      backgroundColor: '#121212FF',
      foregroundColor: '#3392E8FF',
    },
    custom_font_family: "'Open Sans'",
    time_frames: [
      { text: '1D', resolution: '15', description: '1 Day', title: '1D' },
      { text: '5D', resolution: '60', description: '5 Days', title: '5D' },
      { text: '1M', resolution: '60', description: '1 Month', title: '1M' },
      { text: '3M', resolution: '1D', description: '3 Months', title: '3M' },
      { text: '6M', resolution: '1D', description: '6 Months', title: '6M' },
    ],
    ...defaults,
    theme,
  } as ChartingLibraryWidgetOptions;
};

function createOverrides() {
  return {
    'paneProperties.backgroundType': 'solid',
    'paneProperties.background': 'rgba(18, 18, 18, 1)', // Opaque background
    'paneProperties.vertGridProperties.color': 'rgba(34, 36, 39, 1)',
    'paneProperties.horzGridProperties.color': 'rgba(34, 36, 39, 1)',
    'paneProperties.crossHairProperties.color': 'rgba(34, 36, 39, 1)',

    'paneProperties.legendProperties.backgroundTransparency': 0,
    'scalesProperties.axisLineToolLabelBackgroundColorActive':
      'rgba(51, 146, 232, 1)',
    'scalesProperties.axisLineToolLabelBackgroundColorCommon':
      'rgba(51, 146, 232, 1)',
    'scalesProperties.lineColor': 'rgba(18, 18, 18, 1)',
    'scalesProperties.textColor': 'rgba(245, 245, 245, 1)',
    'scalesProperties.fontSize': 12,
    'mainSeriesProperties.candleStyle.upColor': 'rgba(136, 214, 147, 0.68)',
    'mainSeriesProperties.candleStyle.drawBorder': false,
    'mainSeriesProperties.candleStyle.wickUpColor': 'rgba(136, 214, 147, 0.68)',
    'mainSeriesProperties.candleStyle.downColor': 'rgba(240, 72, 102, 0.68)',
    'mainSeriesProperties.candleStyle.wickDownColor':
      'rgba(240, 72, 102, 0.68)',
  } as Overrides;
}
