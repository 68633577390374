import { useState } from 'react';
import { usePopper } from 'react-popper';

export function useDropdownPosition() {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return {
    styles,
    attributes,
    setReferenceElement,
    setPopperElement,
  };
}
