import {
  CreditCardIcon,
  CubeTransparentIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { ContractInfo, PoolInfo } from 'api/contracts';
import {
  DexOrderStatus,
  DexTradeStatus,
  OpenPositionListItem,
} from 'api/dex-trade';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/DWalletService';
import { getSnipeTx } from 'modules/dTrade/utils';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { formatDuration } from 'modules/utils/formatDuration';
import { formatNumberWithSuffix, formatTokenAmount } from 'utils/FormatNumber';
import { ActionsMenu, SnipeStatus } from '../../columns';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { CopyText } from 'modules/shared-components/copy';
import { formatUUID } from 'utils/uuid';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { ExternalInfo } from '../../../research/components/TokenCard/TokenCardHeader';
import Big from 'big.js';
import Tooltip from 'modules/shared-components/tooltip';
import GasIcon from '../../../../../assets/img/icons/gas-icon.svg';
import { WarningLowBalanceMessage } from 'modules/shared-components/notification/walletLowBalance';
import { TokenNameWithChain } from '../../../side-panels/SharedComponentsPanel';
import { chainAsset, chainScan, minimumSafeAmount } from 'api/d-wallets';
import { dexBotKeys, DexBotService } from 'api/services/DexBotService';
import { BotType } from 'api/dex-bot';
import { NotificationDex } from '../../../components/alerts/notification';
import Tag from '../../../components/alerts/Tags';

export function DetailsTopInfo({
  position,
  contract,
  selectedPool,
}: {
  position: OpenPositionListItem;
  contract: ContractInfo | undefined;
  selectedPool?: PoolInfo | undefined;
}) {
  const { data: wallet } = useQuery({
    queryFn: () => DWalletService.getWallet(position.vaultId),
    queryKey: dWalletServiceKeys.getWallet(position.vaultId),
  });

  const snipeTx = getSnipeTx(position.transactions);

  const status =
    position.status === DexTradeStatus.sniped &&
    Big(position.totalAmountTokenRemaining || 0).eq(0) &&
    Big(position.totalAmountTokenBought || 0).gt(0)
      ? DexTradeStatus.closedInProgress
      : position.status;

  const positionIsActive =
    (status === DexTradeStatus.sniped ||
      getSnipeTx(position.transactions)?.status ===
        DexOrderStatus.confirmedSuccess ||
      getSnipeTx(position.transactions)?.status ===
        DexOrderStatus.confirmedSuccessInProgress) &&
    status !== DexTradeStatus.closedInProgress;

  const { data: bot } = useQuery({
    queryFn: () =>
      DexBotService.getBot(position.copyTradingBotId as string, BotType.Wallet),
    queryKey: dexBotKeys.getBot(
      position.copyTradingBotId as string,
      BotType.Wallet
    ),
    enabled: !!(position && position.copyTradingBotId),
  });

  const { data: volatilityBot } = useQuery({
    queryFn: () =>
      DexBotService.getBot(
        position.volatilityBotId as string,
        BotType.Volatility
      ),
    queryKey: dexBotKeys.getBot(
      position.volatilityBotId as string,
      BotType.Volatility
    ),
    enabled: !!(position && position.volatilityBotId),
  });

  const copyTradingWalletAddress = bot ? bot.walletAddressToCopy : null;
  const copyTradingBotName = bot ? bot.name : null;
  const volatilityBotName = volatilityBot ? volatilityBot.name : null;

  return (
    <div className="flex flex-col xxs:gap-2 lg:gap-1">
      <div className="lg:flex lg:justify-between lg:items-center">
        <div className="xxs:flex xxs:flex-col lg:flex-row lg:items-center xxs:gap-2">
          <div className="flex justify-between items-center w-full lg:w-auto">
            <div className="flex items-center xxs:gap-2 w-full lg:w-auto">
              <div className="relative">
                {contract && (
                  <TokenIconWithChain
                    className="h-[25px] w-[25px]"
                    tokenName={contract.generalInfo.name ?? 'Unknown'}
                    address={contract.generalInfo.address}
                    symbol={contract.generalInfo.symbol || undefined}
                    chain={contract.generalInfo.chain.key}
                    protocol={selectedPool?.dex.key}
                  />
                )}
              </div>
              {contract && (
                <div className="truncate flex items-baseline xxs:gap-1 w-full ">
                  {' '}
                  <span className=" whitespace-nowrap xxs:max-w-10 lg:max-w-md xxs:text-3xl xxs:font-semibold overflow-hidden">
                    {contract.generalInfo.name}{' '}
                  </span>
                  <span className="whitespace-nowrap xxs:w-[120px] max-w-xs md:w-auto items-center xxs:text-xl dark:text-black-100 xxs:font-semibold">
                    {contract.generalInfo.symbol && (
                      <TokenNameWithChain
                        baseAsset={contract.generalInfo.symbol}
                        chain={contract.generalInfo.chain.key}
                      />
                    )}
                  </span>
                </div>
              )}
              <div className="ml-auto lg:hidden">
                {status !== DexTradeStatus.closedInProgress && (
                  <ActionsMenu position={position} color="neutral" />
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center lg:gap-2 hidden lg:block">
            {position.snipeEvent && (
              <div className="flex space-x-2 items-center">
                <CubeTransparentIcon className="w-6 h-6 xxs:text-dex-white-secondary" />
                <div className="whitespace-nowrap xxs:text-dex-white-secondary">
                  First tradable block:{' '}
                  <a
                    className="xxs:text-dex-white"
                    href={`https://${chainScan(position.chain)}/block/${
                      position.snipeEvent.blockNumber
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {snipeTx?.type === 'UNISWAP_V2_BLOCK_ZERO_SNIPE_SWAP'
                      ? position.snipeEvent.blockNumber + 1
                      : position.snipeEvent.blockNumber}
                  </a>
                </div>
                {positionIsActive && (
                  <a
                    href={`https://${chainScan(position.chain)}/block/${
                      position.snipeEvent.blockNumber
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tag className="xxs:text-xs" type="success">
                      <span className="xxs:text-dex-white-secondary">
                        {' '}
                        Executed at block{' '}
                        <span className="xxs:text-dex-white">
                          {snipeTx?.blockNumber}
                        </span>
                      </span>
                    </Tag>
                  </a>
                )}
              </div>
            )}
            {!positionIsActive && (
              <div className="flex space-x-2 items-center">
                <SnipeStatus
                  position={position}
                  className="xxs:text-xs lg:text-sm"
                />
                {position.createdAt && (
                  <div className="whitespace-nowrap	xxs:text-dex-white-secondary">
                    since {formatDuration(position.createdAt)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="hidden lg:block">
          {status !== DexTradeStatus.closedInProgress && (
            <ActionsMenu position={position} color="dexNeutral" />
          )}
        </div>
      </div>

      <div className="flex xxs:gap-2 items-center">
        <div className="flex flex-row items-center xxs:text-xs lg:text-sm xxs:gap-2">
          <div className="flex xxs:gap-1 items-center xxs:text-dex-white-secondary">
            Id:{' '}
            <CopyText textToCopy={position.id}>
              <BaseButton
                type="button"
                className="xxs:text-dex-white-secondary hover:xxs:text-dex-white"
              >
                {formatUUID(position.id)}
              </BaseButton>
            </CopyText>
          </div>
          <div className="flex xxs:gap-1 lg:items-center xxs:text-dex-white-secondary">
            Token:{' '}
            <EthAddress
              address={position.tokenAddress}
              chain={position.chain}
              kind="token"
              precision={2}
            />
          </div>
          {position.poolAddress && (
            <div className="flex xxs:gap-1 items-center xxs:text-dex-white-secondary">
              Pair:{' '}
              <EthAddress
                address={position.poolAddress}
                chain={position.chain}
                kind="pool"
                precision={2}
              />
            </div>
          )}
          {selectedPool && (
            <ExternalInfo
              chain={position.chain}
              selectedPool={selectedPool}
              contract={contract}
            />
          )}
        </div>
      </div>

      <div className="xxs:flex xxs:flex-col lg:flex-row xxs:gap-1 lg:items-center">
        <div className="xxs:flex gap-1 items-center">
          <div className="flex gap-1 items-center">
            <CreditCardIcon className="w-4 h-4 xxs:text-dex-white-secondary" />{' '}
            {wallet && (
              <div className="flex space-x-1 items-center">
                <div className="xxs:text-dex-white-secondary">Wallet:</div>
                <ChainsIcon imgName={position.chain} />
                <div className="truncate max-w-[120px]">{wallet.label}</div>
                <div className="xxs:px-1.5 xxs:py-0.5 xxs:text-xs xxs:text-dex-white xxs:bg-dex-black-700 xxs:rounded-sm flex-shrink-0">
                  {formatTokenAmount(+wallet.chainBalance)}{' '}
                  {chainAsset(position.chain)}
                </div>
              </div>
            )}
          </div>
          <div className="flex gap-1 items-center">
            {position.totalPaidGasFeesInEth ? (
              <>
                <span className=" xxs:text-dex-white-secondary"> | </span>
                <Tooltip
                  text={`The gas fees paid to execute the transaction(s). ${
                    Big(position.effectiveBribeInEth).gt(0)
                      ? `This includes ${Big(
                          position.effectiveBribeInEth
                        ).toFixed(3)} ${chainAsset(
                          position.chain
                        )} of bribing fee.`
                      : ``
                  }`}
                >
                  <div className="flex space-x-0.5 items-center">
                    <div className="flex space-x-0.5 items-center xxs:text-dex-white-secondary">
                      <img className="h-4 w-4 " alt="gas icon" src={GasIcon} />{' '}
                      <div>Paid gas:</div>
                    </div>
                    <div className="xxs:text-dex-white">
                      {formatNumberWithSuffix(
                        Big(position.totalPaidGasFeesInEth).toNumber()
                      )}{' '}
                      {chainAsset(position.chain)}{' '}
                    </div>
                  </div>
                </Tooltip>
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        {copyTradingWalletAddress && (
          <div className="flex lg:space-x-1 items-center">
            <div className="hidden lg:block">|</div>
            <div className="truncate flex space-x-1 items-center">
              <CreditCardIcon className="w-4 h-4 xxs:text-dex-white-secondary" />
              <div className="xxs:text-dex-white-secondary">Copied from:</div>
              <EthAddress
                address={copyTradingWalletAddress}
                chain={contract?.generalInfo.chain.key}
                kind="account"
              />
              <div className="xxs:text-dex-white-secondary">|</div>
              {copyTradingBotName && (
                <div className="truncate flex space-x-1 items-center">
                  <div className="xxs:text-dex-white-secondary">Bot:</div>
                  <Tooltip text={copyTradingBotName}>
                    <div className="max-w-[120px] truncate xxs:text-dex-white">
                      {copyTradingBotName}
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        )}

        {volatilityBotName && (
          <div className="truncate flex space-x-1 items-center">
            <div className="hidden lg:block xxs:text-dex-white-secondary">
              |
            </div>
            <div className="xxs:text-dex-white-secondary">Bot:</div>
            <Tooltip text={volatilityBotName}>
              <div className="max-w-[120px] truncate xxs:text-dex-white">
                {volatilityBotName}
              </div>
            </Tooltip>
          </div>
        )}

        {position.telegramSnipingCallEvent && (
          <div className="xxs:flex space-x-1 items-center">
            <div className="hidden lg:block xxs:text-dex-white-secondary">
              |
            </div>
            <div className="truncate flex space-x-1 items-center">
              <PhoneIcon className="w-4 h-4 xxs:text-dex-white-secondary" />
              <div className="xxs:text-dex-white-secondary"> Called by:</div>
              <a
                href={
                  position.telegramSnipingCallEvent
                    .telegramTradingChannelPostLink
                }
                target="_blank"
                className="xxs:text-dex-white"
                rel="noreferrer noopener"
              >
                {position.telegramSnipingCallEvent.telegramTradingChannelName}
              </a>
            </div>
          </div>
        )}
      </div>

      {wallet &&
      wallet.chainBalance &&
      Big(wallet.chainBalance).lt(minimumSafeAmount(position.chain)) &&
      (position.status === DexTradeStatus.sniped ||
        position.status === DexTradeStatus.snipeInProgress) ? (
        <div className="xxs:my-1 lg:my-2">
          <NotificationDex className="xxs:mx-0" type="warning">
            <WarningLowBalanceMessage chain={position.chain} />
          </NotificationDex>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
