import { forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import StyledLabel from './styled-label';
import StyledInput from './styled-input';
import { twMerge } from 'tailwind-merge';
import Tooltip from 'modules/shared-components/tooltip';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  name: string;
  label?: string;
  className?: string;
  containerClassName?: string;
  tooltip?: string;
  description?: string;
  hideErrorMessage?: boolean;
  error?: string;
  /**
   * @deprecated use suffix instead
   */
  _suffix?: string;
  suffix?: string | JSX.Element;
  prefix?: string | JSX.Element;
  prefixTooltip?: string;
  extraLabel?: string | JSX.Element;
  disabled?: boolean;
  isLabelHidden?: boolean;
  inputSize?: 'xs' | 'small' | 'large';
}

/**
 * Disabled style to review
 * Dark regular to review
 * Dark disabled to review
 * Dark error style to review
 * Disabled error style to review
 */

const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      label,
      className,
      tooltip,
      description,
      error,
      _suffix,
      prefix,
      prefixTooltip,
      disabled,
      isLabelHidden,
      extraLabel,
      suffix,
      containerClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div className={twMerge('w-full relative', containerClassName)}>
        {(label || extraLabel) && (
          <div className="flex justify-between xxs:mb-1 whitespace-nowrap xxs:gap-2 items-end">
            {label && (
              <StyledLabel
                name={name}
                label={label}
                tooltip={tooltip}
                inputSize={props.inputSize}
                isLabelHidden={isLabelHidden}
              />
            )}
            {extraLabel && (
              <div
                className={twMerge(
                  'xxs:text-dex-white-secondary',
                  props.inputSize === 'xs'
                    ? 'xxs:text-sm lg:text-xs'
                    : 'xxs:text-xs'
                )}
                id={`${name}-extra-label`}
              >
                {extraLabel}
              </div>
            )}
          </div>
        )}
        <StyledInput
          ref={ref}
          name={name}
          inputSize={props.inputSize}
          className={className}
          hasError={!!error}
          hasSuffix={!!_suffix || !!suffix}
          hasPrefix={!!prefix}
          disabled={disabled}
          aria-label={label}
          {...props}
        >
          {(_suffix || error) && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center xxs:pr-3">
              <span
                className={classNames(
                  props.inputSize === 'xs'
                    ? 'xxs:text-sm lg:text-xs'
                    : 'xxs:text-sm',
                  disabled
                    ? 'xxs:text-white-700 xxs:text-dex-white-dark'
                    : ' xxs:text-dex-white-secondary'
                )}
                id={`${name}-suffix`}
              >
                {_suffix}
              </span>
            </div>
          )}
          {suffix && (
            <div
              className={twMerge(
                props.inputSize === 'xs'
                  ? 'xxs:text-sm lg:text-xs'
                  : 'xxs:text-sm',
                'absolute inset-y-0 right-0 xxs:pl-1 xxs:pr-3 flex items-center z-10  xxs:text-dex-white-secondary'
              )}
            >
              {suffix}
            </div>
          )}
          {prefix && (
            <div
              className={twMerge(
                'flex space-x-1 items-center',
                props.inputSize === 'xs' ? 'xxs:text-xs' : 'xxs:text-sm',
                'pointer-events-none absolute inset-y-0 left-0 flex items-center xxs:pl-3  xxs:text-dex-white-secondary'
              )}
            >
              <div className="">{prefix}</div>
              {prefixTooltip && <Tooltip.Info text={prefixTooltip} />}
            </div>
          )}
        </StyledInput>
        {!props.hideErrorMessage && error && (
          <p
            className="xxs:mt-1 xxs:mb-0 xxs:text-xs xxs:text-red-500 w-max"
            id={`${name}-error`}
          >
            {error}
          </p>
        )}
        {description && !error && (
          <p
            className="xxs:mt-1 xxs:mb-0 xxs:text-xs xxs:text-dex-white-secondary"
            id={`${name}-description`}
          >
            {description}
          </p>
        )}
      </div>
    );
  }
);

export default TextInput;
