import { Switch } from '@headlessui/react';
import { ComponentProps, FC } from 'react';
import { twMerge } from 'tailwind-merge';

type ToggleProps = {
  onChange: (value: boolean) => void;
  value?: boolean;
  condensed?: boolean;
  disabled?: boolean;
} & Pick<ComponentProps<'div'>, 'className' | 'aria-label'>;

const Toggle: FC<ToggleProps> = ({
  value: isChecked,
  condensed,
  onChange,
  disabled,
  ...rest
}) => (
  <Switch
    {...rest}
    checked={isChecked ?? false}
    onChange={!disabled ? onChange : () => {}}
    className={twMerge(
      'flex items-center',
      !disabled &&
        'focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-1',
      isChecked ? 'bg-blue-600' : 'bg-gray-200',
      'relative inline-flex flex-shrink-0 cursor-pointer rounded-full xxs:border-transparent xxs:border-solid xxs:border-1 border-transparent transition-colors duration-200 ease-in-out ',
      condensed ? 'xxs:h-5 xxs:w-8' : 'xxs:h-5 xxs:w-11',
      rest.className
    )}
  >
    <div
      aria-hidden="true"
      className={twMerge(
        !condensed && isChecked ? 'xxs:translate-x-6' : 'translate-x-0',
        condensed && isChecked ? 'xxs:translate-x-6' : 'translate-x-0',
        'pointer-events-none inline-block xxs:h-4 xxs:w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        condensed && 'xxs:h-4 xxs:w-4'
      )}
    />
  </Switch>
);

export default Toggle;
