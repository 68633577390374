import { PropsWithChildren, useEffect, useState } from 'react';
import { Breadcrumbs, Page } from 'modules/app-shell/breadcrumbs';
import { twMerge } from 'tailwind-merge';

import config from 'config';
import Sidebar from './sidebar';

import { useUserState } from 'modules/user/UserContext';
import {
  DexTradeStatus,
  GetDexOpenPositionsParams,
  OpenPositionListItem,
} from 'api/dex-trade';

import { useQuery } from '@tanstack/react-query';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import { duration } from 'moment';
import {
  refreshIntervalTime,
  useRefreshIntervalPersistedState,
} from '../../side-panels/SnipesPanel';

import createPersistedState from 'use-persisted-state';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import Big from 'big.js';
import { chainAsset, Chains } from 'api/d-wallets';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import { SidebarConfigProps } from './SIDEBAR_CONST';
import { HotContracts } from '../../research/components/HotContracts';
import Button from 'modules/shared-components/button/button';
import {
  ArrowTopRightOnSquareIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { ExplainerModal } from '../../research/components/ExplainerModal';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';

type Props = {
  pages?: Page[];
  sidebar?: SidebarConfigProps;
  toggle?: boolean;
  mainInfo?: MainTitleProps[];
  className?: string;
  button?: React.ReactNode;
  buttonContainerClass?: string;
  classNameSidebarContainer?: string;
};

interface ClosedPositionProps {
  positionId: string;
  symbol: string;
  pnl: string;
  chain: Chains;
}

const usePersistedState = createPersistedState<{
  closePositionsAlert: ClosedPositionProps[];
}>('closed-positions-alert');
const MAX_CLOSED_POSITIONS = 50;

interface MainTitleProps {
  activeTab: string;
  name: string;
  icon: JSX.Element;
  description: JSX.Element;
  tutorials?: {
    title: string;
    link: string;
  }[];
}

const PageTitle = ({ mainInfo }: { mainInfo: MainTitleProps[] }) => {
  const [openExplainer, setOpenExplainer] = useState(false);

  const open = () => setOpenExplainer(true);
  const close = () => setOpenExplainer(false);

  return (
    <div>
      {mainInfo.map((info, index) => (
        <div key={index}>
          <Button onClick={open} className="xxs:m-0 xxs:p-0">
            <div className="">
              <div className="normal-case flex space-x-1 text-sm items-center lg:p-1 pb-0 lg:p-0 xxs:text-dex-white-secondary hover:text-dex-white">
                {info.icon}
                <div className="xxs:text-dex-white">{info.name}</div>
                <QuestionMarkCircleIcon className="h-5 w-5" />
              </div>
            </div>
          </Button>

          {openExplainer && (
            <ExplainerModal handleClose={close} title={info.name}>
              {info.description}
              {info.tutorials && (
                <div className="lg:flex xxs:space-y-2 lg:space-y-0 lg:space-x-3 items-center">
                  {info.tutorials.map((tutorial, tutorialIndex) => (
                    <a
                      key={tutorialIndex}
                      className="xxs:text-dex-white hover:text-dex-white block"
                      href={tutorial.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="flex xxs:space-x-1 items-center">
                        <div>{tutorial.title}</div>
                        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                      </div>
                    </a>
                  ))}
                </div>
              )}
            </ExplainerModal>
          )}
        </div>
      ))}
    </div>
  );
};

const PageTemplate = ({
  pages,
  children,
  sidebar,
  className,
  mainInfo,
  button,
  buttonContainerClass,
  classNameSidebarContainer,
}: PropsWithChildren<Props>) => {
  const { user } = useUserState();
  const isDesktop = useMediaQuery(screenGte.small);
  const params: GetDexOpenPositionsParams = {
    sort: `-updatedAt`,
    status: [DexTradeStatus.closedInProgress],
    limit: 50,
    offset: 0,
  };

  const [closedPositions, setClosedPositions] = usePersistedState();

  const [refetchInterval] = useRefreshIntervalPersistedState();

  const { data } = useQuery({
    queryKey: dexTradeKeys.getPositions(params),
    queryFn: () => DexTradeService.getPositions(params),
    keepPreviousData: true,
    enabled: !!user && !config.isTelegramBrowser,
    refetchInterval: duration(
      refreshIntervalTime(refetchInterval),
      'seconds'
    ).asMilliseconds(),
  });

  const items: OpenPositionListItem[] | [] =
    data && !config.isTelegramBrowser ? data.data : [];
  const { addNewMessage } = useSnackBar();

  useEffect(() => {
    if (items && !config.isTelegramBrowser) {
      const mappedItems: ClosedPositionProps[] = items.map((item) => ({
        positionId: item.id,
        symbol: item.tokenSymbol,
        pnl: item.realisedProfitAndLoss?.pnlAmountInEth || '0',
        chain: item.chain,
      }));

      const newClosedPositions: ClosedPositionProps[] = closedPositions
        ? mappedItems.filter(
            (item) =>
              !closedPositions.closePositionsAlert?.some(
                (closedPosition) =>
                  closedPosition.positionId === item.positionId
              )
          )
        : mappedItems;

      if (newClosedPositions.length > 0) {
        newClosedPositions.forEach((position, index) => {
          setTimeout(() => {
            addNewMessage({
              type: Big(position.pnl ?? 0).gte(0) ? 'success' : 'error',
              title: `${position.symbol} Closed`,
              message: `${position.symbol} closed with ${
                Big(position.pnl ?? 0).gte(0) ? '+' : '-'
              }${formatNumberWithSuffix(position.pnl)} ${chainAsset(
                position.chain
              )} ${Big(position.pnl ?? 0).gte(0) ? 'profit' : 'loss'}`,
            });
          }, index * 2000);
        });

        const updatedClosedPositions = [
          ...(closedPositions?.closePositionsAlert || []),
          ...newClosedPositions,
        ];

        setClosedPositions({
          closePositionsAlert: updatedClosedPositions.slice(
            -MAX_CLOSED_POSITIONS
          ),
        });
      }
    }
  }, [items, closedPositions, setClosedPositions]);

  return (
    <div className="xxs:min-h-full">
      {sidebar && (
        <div className={classNameSidebarContainer}>
          <Sidebar sidebar={sidebar.items} />
        </div>
      )}
      <div
        className={twMerge(
          sidebar ? 'lg:ml-[48px] transition-all' : 'lg:px-0 2xl:px-0 mx-auto',
          'xxs:space-y-1',
          className
        )}
      >
        <div
          className={twMerge(
            'flex justify-between items-center xxs:px-2 lg:px-0',
            !config.isTelegramBrowser && 'xxs:mb-2'
          )}
        >
          {pages ? <Breadcrumbs pages={pages} /> : ''}
        </div>

        {isDesktop && <HotContracts />}
        {mainInfo && (
          <div
            className={twMerge(
              'lg:flex lg:justify-between xxs:space-y-1 lg:space-y-0 lg:items-center xxs:px-2 lg:px-0',
              buttonContainerClass
            )}
          >
            <PageTitle mainInfo={mainInfo} />
            {button ?? ''}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default PageTemplate;
