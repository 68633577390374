import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { MobileSelect } from './mobile-select';
import { Select, Props } from './select';
import { forwardRef } from 'react';

function InnerResponsiveSelect<T extends string | number>(
  props: Props<T> & Partial<{ modalLabel: React.ReactNode }>,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  const isDesktop = useMediaQuery(screenGte.small);
  return isDesktop ? (
    <Select {...props} {...{ ref }} />
  ) : (
    <MobileSelect {...props} {...{ ref }} />
  );
}

export const ResponsiveSelect = forwardRef(
  InnerResponsiveSelect
) as typeof InnerResponsiveSelect;
