import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Tooltip from 'modules/shared-components/tooltip';

interface StatisticsCardProps {
  title: string;
  tooltip?: string;
}

type IStatisticsCard = FC<StatisticsCardProps> & {
  Description: FC<React.ComponentProps<'dd'>>;
};

const StatisticsCard: IStatisticsCard = ({ children, title, tooltip }) => (
  <StatisticsCardContainer>
    <StatisticsCardTitle>
      <div className="flex space-x-0.5 items-center">
        <Tooltip text={tooltip ?? ''}>
          <div className="xxs:text-dex-white-secondary hover:text-dex-white">
            {title}
          </div>
        </Tooltip>
      </div>
    </StatisticsCardTitle>
    <div className="flex flex-wrap gap-1 justify-between">{children}</div>
  </StatisticsCardContainer>
);

StatisticsCard.Description = (props) => (
  <dd
    {...props}
    className={twMerge(
      'test-sm lg:text-lg m-0 sm:text-xl xxs:font-semibold tracking-tight xxs:text-gray-700 dark:text-dex-white flex inline-flex gap-1 items-baseline',
      props.className
    )}
  />
);

export function StatisticsCardContainer(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={twMerge(
        'w-full overflow-hidden rounded xxs:bg-dex-black-800 xxs:p-2',
        props.className
      )}
    />
  );
}

export function StatisticsCardTitle(props: React.ComponentProps<'dt'>) {
  return (
    <dt
      className={twMerge(
        'truncate xxs:text-dex-white-secondary xxs:text-xs',
        props.className
      )}
    >
      {props.children}
    </dt>
  );
}

export default StatisticsCard;
