import { PaginatedList } from 'api/common';
import {
  BotType,
  DexSniperBot,
  GetDexBotsParams,
  GetWhitelistedTokensParams,
  SniperBotInput,
  VolatilityBotInput,
  WhitelistedTokenData,
} from 'api/dex-bot';
import { api } from 'helpers/api/apiCore';
import { toQueryString } from 'modules/routing/query-string';
import { Chains } from 'api/d-wallets';

const SERVICE_KEY = 'dex-bots';

export const dexBotKeys = {
  all: () => [SERVICE_KEY],
  getBots: (params: GetDexBotsParams) => [SERVICE_KEY, params],
  getWhitelistedTokens: (params: GetWhitelistedTokensParams) => [
    SERVICE_KEY,
    'whitelisted-tokens',
    params,
  ],
  getBot: (id: string, botType: BotType) => [SERVICE_KEY, id, botType],
};

const walletCopyTradingUrl = 'copy-trading-bots';
const telegramCopyTradingUrl = 'telegram-sniper-bots';
const volatilityBotTradingUrl = 'volatility-bots';

export const DexBotService = {
  async create(bot: SniperBotInput, isWalletCopyTrading: boolean) {
    const url = isWalletCopyTrading
      ? walletCopyTradingUrl
      : telegramCopyTradingUrl;
    const response = await api.post<SniperBotInput, DexSniperBot>(
      `/async/api/v2/d-trade/${url}`,
      //`/api/v2/d-trade/${url}`,
      bot
    );

    return response.data;
  },

  async createVolatilityBot(bot: VolatilityBotInput) {
    const response = await api.post<VolatilityBotInput, DexSniperBot>(
      `/async/api/v2/d-trade/volatility-bots`,
      //`/api/v2/d-trade/volatility-bots`,
      bot
    );

    return response.data;
  },

  async edit(id: string, bot: SniperBotInput, isWalletCopyTrading: boolean) {
    const url = isWalletCopyTrading
      ? walletCopyTradingUrl
      : telegramCopyTradingUrl;
    const response = await api.update<SniperBotInput, DexSniperBot>(
      `/async/api/v2/d-trade/${url}/${id}`,
      //`/api/v2/d-trade/${url}/${id}`,
      bot
    );

    return response.data;
  },

  async editVolatilityBot(id: string, bot: VolatilityBotInput) {
    const response = await api.update<VolatilityBotInput, DexSniperBot>(
      `/async/api/v2/d-trade/volatility-bots/${id}`,
      // `/api/v2/d-trade/volatility-bots/${id}`,
      bot
    );

    return response.data;
  },

  async removeTokenFromVolatilityBot({
    botId,
    address,
    chain,
  }: {
    botId: string;
    address: string;
    chain: Chains;
  }) {
    const response = await api.post(
      `/async/api/v2/d-trade/remove-token-from-bot/${botId}/${chain}/${address}`
      // `/api/v2/d-trade/remove-token-from-bot/${botId}/${chain}/${address}`
    );

    return response.data;
  },

  async addTokenToVolatilityBot({
    botId,
    address,
    chain,
  }: {
    botId: string;
    address: string;
    chain: Chains;
  }) {
    const response = await api.post(
      `/async/api/v2/d-trade/add-token-to-bot/${botId}/${chain}/${address}`
      // `/api/v2/d-trade/add-token-to-bot/${botId}/${chain}/${address}`
    );

    return response.data;
  },

  async getBots(params: GetDexBotsParams) {
    const url =
      params.botType === BotType.Wallet
        ? walletCopyTradingUrl
        : params.botType === BotType.Volatility
        ? volatilityBotTradingUrl
        : telegramCopyTradingUrl;
    const response = await api.get<PaginatedList<DexSniperBot>>(
      `/async/api/v2/d-trade/${url}?${toQueryString(params, {
        arrayFormat: 'bracket',
      })}`
      //  `/api/v2/d-trade/${url}?${toQueryString(params, {arrayFormat: 'bracket'})}`
    );

    return response.data;
  },

  async getWhitelistedTokens(params: GetWhitelistedTokensParams) {
    const response = await api.get<PaginatedList<WhitelistedTokenData>>(
      `/async/api/v2/d-trade/whitelisted-tokens-volatility-bot?${toQueryString(
        params
      )}`
      // `/api/v2/d-trade/whitelisted-tokens-volatility-bot?${toQueryString(params)}`
    );

    return response.data;
    /*return {
          pagination: {
            limit: 20,
            offset: 0,
            total: 20,
          },
          data: [
            {
              status: 'ACTIVE',
              base: 'ETH',
              quote: 'USDT',
              tokenAddress: '0xabc123def456ghi789jkl012mnop345qrs678',
              chain: Chains.Ethereum,
              dex: Protocols.UniswapV3,
              currentLiquidity: '1500000',
              currentMarketCap: '50000000',
              oneHourVolume: '100000',
              sixHoursVolume: '500000',
              TwentyFourHoursVolume: 2000000,
              bots: [
                { name: 'ProfitBot', botId: 'bot123', status: 'ACTIVE' },
                { name: 'Pro21fitBot', botId: 'bot1213', status: 'ACTIVE' },
                { name: 'WhaleHunter', botId: 'bot456', status: 'INACTIVE' },
              ],
              suspiciousBuy: 5,
              rugAddictedBuy: 1,
              scammerBuy: 2,
              freshBuy: 15,
              totalBuy: '23',
            },
            {
              status: 'RUG_PULL',
              base: 'BSC',
              quote: 'BNB',
              tokenAddress: '0x987zyx654wvu321tsr098qpo567lmn234jkl345',
              chain: Chains.BinanceChain,
              dex: Protocols.PancakeSwapV2,
              currentLiquidity: '200000',
              currentMarketCap: '3000000',
              oneHourVolume: '5000',
              sixHoursVolume: '15000',
              TwentyFourHoursVolume: 100000,
              bots: [{ name: 'AlphaBot', botId: 'bot789', status: 'INACTIVE' }],
              suspiciousBuy: 2,
              rugAddictedBuy: 0,
              scammerBuy: 3,
              freshBuy: 10,
              totalBuy: '15',
            },
            {
              status: 'INACTIVE',
              base: 'BASE',
              quote: 'USDC',
              tokenAddress: '0x456uvw987xyz654cba123onm987poi654jkl098',
              chain: Chains.Base,
              dex: Protocols.UniswapV2,
              currentLiquidity: '50000',
              currentMarketCap: '1200000',
              oneHourVolume: '2000',
              sixHoursVolume: '7000',
              TwentyFourHoursVolume: 30000,
              bots: [
                { name: 'ScoutBot', botId: 'bot234', status: 'ACTIVE' },
                { name: 'TrackerBot', botId: 'bot567', status: 'ACTIVE' },
              ],
              suspiciousBuy: 1,
              rugAddictedBuy: 1,
              scammerBuy: 0,
              freshBuy: 5,
              totalBuy: '7',
            },
          ],*/
    /* };*/
  },

  async getBot(id: string, botType: BotType) {
    const url =
      botType === BotType.Wallet
        ? walletCopyTradingUrl
        : botType === BotType.Volatility
        ? volatilityBotTradingUrl
        : telegramCopyTradingUrl;
    const response = await api.get<DexSniperBot>(
      `/async/api/v2/d-trade/${url}/${id}`
      //`/api/v2/d-trade/${url}/${id}`
    );

    return response.data;
  },

  async pauseBot(id: string, botType: BotType) {
    const url =
      botType === BotType.Wallet
        ? walletCopyTradingUrl
        : botType === BotType.Volatility
        ? volatilityBotTradingUrl
        : telegramCopyTradingUrl;
    const update: Pick<DexSniperBot, 'status'> = {
      status: 'PAUSED',
    };
    const response = await api.updatePatch(
      `/async/api/v2/d-trade/${url}/${id}`,
      //`/api/v2/d-trade/${url}/${id}`,
      update
    );

    return response.data;
  },
  async resumeBot(id: string, botType: BotType) {
    const url =
      botType === BotType.Wallet
        ? walletCopyTradingUrl
        : botType === BotType.Volatility
        ? volatilityBotTradingUrl
        : telegramCopyTradingUrl;
    const update: Pick<DexSniperBot, 'status'> = {
      status: 'ACTIVE',
    };
    const response = await api.updatePatch(
      `/async/api/v2/d-trade/${url}/${id}`,
      //`/api/v2/d-trade/${url}/${id}`,
      update
    );

    return response.data;
  },
  async deleteBot(id: string, botType: BotType) {
    const url =
      botType === BotType.Wallet
        ? walletCopyTradingUrl
        : botType === BotType.Volatility
        ? volatilityBotTradingUrl
        : telegramCopyTradingUrl;
    const response = await api.delete(
      `/async/api/v2/d-trade/${url}/${id}`
      // `/api/v2/d-trade/${url}/${id}`
    );

    return response.data;
  },
};
