import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { dexTradeKeys, DexTradeService } from 'api/services/DexTradeService';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { Button } from 'modules/shared-components/button/button';
import { FC, useState } from 'react';
import {
  SnipesList,
  useRefreshIntervalPersistedState,
} from '../side-panels/SnipesPanel';
import { DexTradeStatus, OpenPositionListItem } from 'api/dex-trade';
import Checkbox from 'modules/shared-components/input/Checkbox';
import CustomModal from '../components/modal/CustomModal';
import { NotificationDex } from '../components/alerts/notification';
import { LoaderDex } from '../components/alerts/Loader';

interface ReactivatePositionsProps {
  onCancel: () => void;
  position: OpenPositionListItem;
}

export const MergePositions: FC<ReactivatePositionsProps> = ({
  onCancel,
  position,
}) => {
  const { addNewMessage } = useSnackBar();
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const [, setRefetchInterval] = useRefreshIntervalPersistedState();

  const handleCheckboxClick = (id: string) => {
    setSelectedPositions((prevSelectedPositions) => {
      if (prevSelectedPositions.includes(id)) {
        return prevSelectedPositions.filter((positionId) => positionId !== id);
      } else {
        return [...prevSelectedPositions, id];
      }
    });
  };

  const { mutate, isLoading, error } = useMutation({
    mutationFn: () =>
      DexTradeService.merge(position.id, { positionIds: selectedPositions }),
    onSuccess: () => {
      addNewMessage({
        type: 'success',
        title: 'Positions Merge Success',
        message: 'Positions merge request has been accepted and in progress.',
      });
      setRefetchInterval(new Date().toISOString());
      void queryClient.invalidateQueries(dexTradeKeys.all());
      onCancel();
    },
  });

  const params = {
    limit: 20,
    offset: 0,
    status: [DexTradeStatus.sniped, DexTradeStatus.snipeInProgress],
    chain: position.chain,
    tokenAddress: position.tokenAddress,
    vaultId: [position.vaultId],
  };

  const {
    isLoading: isLoadingPositions,
    data: page,
    error: errorPositions,
  } = useQuery({
    queryFn: () => DexTradeService.getPositions(params),
    queryKey: dexTradeKeys.getPositions(params),
  });

  const positions = page ? page.data : [];

  return (
    <CustomModal title="Merge Trade" showModal handleClose={onCancel}>
      <CustomModal.Body>
        <CustomModal.Title className="hidden lg:flex">
          Merge Trade
        </CustomModal.Title>
        <CustomModal.Content>
          <div className="flex flex-col xxs:gap-2">
            <div>
              <p>Merge all similar trades into a single one.</p>
              <p>Select the trades to merge:</p>
              {isLoadingPositions ? (
                <LoaderDex />
              ) : errorPositions ? (
                <NotificationDex className="xxs:mx-0" type="error">
                  There was an error while loading the trades. Reload the page
                  and try again.
                </NotificationDex>
              ) : positions?.filter((p) => p.id !== position.id).length ===
                0 ? (
                <NotificationDex className="xxs:mx-0" type="info">
                  No trades to merge found.
                </NotificationDex>
              ) : (
                positions
                  ?.filter((p) => p.id !== position.id)
                  .map((p, index) => {
                    return (
                      <div
                        className="flex xxs:pl-2 space-x-4 items-center xxs:bg-dex-black-700 hover:bg-dex-black-600"
                        key={index}
                      >
                        <div className="xxs:pr-2">
                          <Checkbox
                            onClick={() => handleCheckboxClick(p.id)}
                            checked={selectedPositions.includes(p.id)}
                            key={p.id}
                          />
                        </div>
                        <SnipesList
                          className="xxs:bg-dex-black-700 hover:bg-dex-black-600 "
                          isMerge={true}
                          key={p.id}
                          position={p}
                        />
                      </div>
                    );
                  })
              )}
            </div>
          </div>
        </CustomModal.Content>
        {error && (
          <NotificationDex
            className="xxs:mx-0"
            type="error"
            errorMessage={error}
          >
            An error occurred.
          </NotificationDex>
        )}
      </CustomModal.Body>
      <CustomModal.Footer>
        <PrimaryButton
          className="xxs:text-base lg:text-xs"
          type="button"
          loading={isLoading}
          disabled={selectedPositions.length === 0}
          onClick={() => {
            mutate();
          }}
        >
          Confirm
        </PrimaryButton>
        <Button
          className="xxs:text-base lg:text-xs"
          type="button"
          variant="dexNeutral"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};
