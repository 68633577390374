import { Button } from 'modules/shared-components/button/button';
import { useNetwork } from 'wagmi';
import TextInput from '../../../app/dex/components/inputs/text-input/text-input';
import { LoaderDex } from '../../../app/dex/components/alerts/Loader';

export function SubmittedTransactionHash({ txHash }: { txHash: string }) {
  const { chain } = useNetwork();
  const blockExplorer = chain?.blockExplorers?.default;

  return (
    <>
      <p className="xxs:mb-2 xxs:text-dex-white-secondary ">
        The transaction has been successfully submitted.
      </p>
      <TextInput
        name="Transaction Hash"
        label="Transaction Hash"
        value={txHash ? txHash.slice(0, 6) + '...' + txHash.slice(-6) : ''}
        readOnly
        suffix={
          blockExplorer ? (
            <Button
              as="a"
              href={`${stripTrailingSlash(blockExplorer.url)}/tx/${txHash}`}
              target="_blank"
              rel="noopener"
              className="xxs:ml-auto shrink-0 text-white"
            >
              Scan
            </Button>
          ) : (
            <div />
          )
        }
      />
    </>
  );
}

export function TxPendingOnchain() {
  return (
    <div>
      <div className="xxs:text-dex-white-secondary xxs:mb-2">Status:</div>
      <div className="flex items-center xxs:gap-2">
        <div className="">Waiting for transaction to be confirmed</div>{' '}
        <LoaderDex />
      </div>
    </div>
  );
}

const stripTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};
