import * as yup from 'yup';
import Big from 'big.js';
import { Chains } from 'api/d-wallets';

export function getSchema() {
  return yup.object({
    otp: yup.string(),
    tokenName: yup.string().required('Required'),
    tokenSymbol: yup.string().required('Required'),
    tokenAddress: yup.string().required('Required'),
    maxBalance: yup.string(),
    transferId: yup.string(),
    transactionHash: yup.string().nullable(),
    chain: yup.string().oneOf(Object.values(Chains)).required('Required'),
    fromAddress: yup.string().required('Required'),
    toAddress: yup.string(),
    amount: yup
      .number()
      .min(0.00001, 'Should be greater than 0.00001')
      .test(
        'isGreaterThanAvailable',
        'Cannot be greater than available',
        (value, context) => {
          const maxBalance = context.parent.maxBalance as string | undefined;
          return !Big(value ?? 0).gt(maxBalance ?? 0);
        }
      )
      .typeError('Number type accepted only')
      .required('Required'),
  });
}

export type FormValues = yup.InferType<ReturnType<typeof getSchema>>;
