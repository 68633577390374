import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { formatTokenAmount } from 'utils/FormatNumber';
import Big from 'big.js';
import Tooltip from 'modules/shared-components/tooltip';
import {
  chainAsset,
  chainName,
  chainScan,
  InitiateBridge,
} from 'api/d-wallets';
import { FormValues } from './schema';
import { useFormContext } from 'react-hook-form';
import { fromWei } from 'modules/ethereum/utils';

const TxInfo = ({ data }: { data: InitiateBridge }) => {
  const { watch } = useFormContext<FormValues>();

  const inputValues = watch();
  const address = inputValues.address;

  const txInfoMapped = [
    {
      title: 'Wallet Address',
      value: (
        <EthAddress
          address={address || ''}
          chain={data.srcChain}
          kind="account"
        />
      ),
      tooltip: 'The wallet address where the funds are bridged.',
    },
    {
      title: 'From Chain',
      value: (
        <div className="">
          {formatTokenAmount(fromWei(Big(data.srcAmount || 0).toNumber()))}{' '}
          {chainAsset(data.srcChain)} on {chainName(data.srcChain)}
        </div>
      ),
      tooltip: 'The chain from which the tokens will be bridged.',
    },
    {
      title: 'To Chain',
      value: (
        <div className="">
          {formatTokenAmount(fromWei(Big(data.dstAmount || 0).toNumber()))}{' '}
          {chainAsset(data.dstChain)} on {chainName(data.dstChain)}
        </div>
      ),
      tooltip: 'The chain where the tokens will be bridged.',
    },

    {
      title: 'Status',
      value: (
        <a
          className="text-dex-white-secondary hover:text-dex-white"
          href={`https://${chainScan(data.srcChain)}/tx/${
            data.srcTransactionHash
          }`}
          rel="noreferrer"
          target="_blank"
        >
          View on block explorer
        </a>
      ),
      tooltip: 'The status of the transaction on the source blockchain.',
    },

    {
      title: 'Transaction ID',
      value: (
        <EthAddress
          address={data.srcTransactionHash || ''}
          chain={data.srcChain}
          kind="tx"
        />
      ),
      tooltip: 'The transaction id on the source blockchain.',
    },
  ];

  return (
    <div className="space-y-4">
      <div>Your bridge has been successfully initiated.</div>

      <div className="xxs:space-y-2">
        {txInfoMapped.map((d, index) => (
          <div className="" key={'tx_info_' + index}>
            <div className="flex justify-between items-center">
              <Tooltip text={d.tooltip}>
                <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                  {d.title}
                </div>
              </Tooltip>
              <div className="text-sm font-medium">{d.value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TxInfo;
